<clr-modal
  class="modal-project"
  [clrModalOpen]="modalIsOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <h3 class="modal-title">
    <span *ngIf="modal.isSave">
      {{ 'application.commons.buttons.new' | translate }}
    </span>
    <span *ngIf="modal.isEdit">
      {{ 'application.commons.buttons.edit' | translate }}
    </span>
    {{ 'qp.project.info.project' | translate }}
  </h3>

  <div class="modal-body">
    <form
      [formGroup]="formGroup"
      clrForm
    >
      <pn-input
        labelKey="application.commons.text.code"
        formControlName="id"
      ></pn-input>

      <pn-input
        labelKey="application.commons.text.name"
        formControlName="name"
      ></pn-input>

      <pn-input
        labelKey="application.commons.text.customer"
        formControlName="customer"
      ></pn-input>

      <pn-date-picker
        label="PO Date"
        formControlName="poDate"
      ></pn-date-picker>

      <pn-input
        labelKey="PO Number"
        formControlName="poNumber"
      ></pn-input>

      <pn-input
        labelKey="owner.email"
        formControlName="ownerEmail"
      ></pn-input>

      <pn-input
        labelKey="owner.name"
        formControlName="ownerName"
      ></pn-input>

      <ng-container *ngIf="modal.isEdit">
        <pn-input
          labelKey="databook.last.modified.by"
          formControlName="lastModifiedBy"
        ></pn-input>

        <pn-input
          labelKey="databook.last.modification.date"
          formControlName="lastModifiedDate"
        ></pn-input>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      [clrLoading]="coreService.isLoading()"
      type="button"
      class="btn btn-primary"
      [disabled]="formGroup.invalid"
      (click)="saveProject()"
    >
      {{
        'application.commons.buttons.' + modal.isEdit
          ? 'save'
          : ('add' | translate)
      }}
    </button>
  </div>
</clr-modal>
