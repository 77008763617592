import { Component } from '@angular/core';
import { ILoadingCellRendererAngularComp } from 'ag-grid-angular';
import { ILoadingCellRendererParams } from 'ag-grid-community';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loading-cell-renderer',
  template: `
    <div class="loading-container">
      <div
        class="loading-text"
        *ngIf="!params.errorMessage"
      >
        {{ params.loadingMessage }} <span class="loader__dot">.</span
        ><span class="loader__dot">.</span><span class="loader__dot">.</span>
      </div>
      <div
        class="error-message-text"
        *ngIf="params.errorMessage"
      >
        {{ params.loadingMessage }}
      </div>
    </div>
  `,
  styles: [
    `
      .loading-container {
        display: flex;
        align-items: center;
        margin-left: 20px;
      }

      .loading-spinner {
        width: 18px;
        height: 18px;
        border: 4px solid #bec5ad;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        margin-right: 10px; /* Adjust spacing between spinner and text */
      }

      .loading-text {
        font-size: 13px;
        font-weight: semi-bold;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .error-message-text {
        font-size: 13px;
        font-weight: semi-bold;
        padding-top: 12px;
        padding-bottom: 12px;
        color: #bf4040;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes blink {
        50% {
          color: transparent;
        }
      }
      .loader__dot {
        animation: 1s blink infinite;
      }
      .loader__dot:nth-child(2) {
        animation-delay: 250ms;
      }
      .loader__dot:nth-child(3) {
        animation-delay: 500ms;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf],
})
export class CustomLoadingCellRenderer
  implements ILoadingCellRendererAngularComp
{
  rowsToDisplay: any[] = new Array(3);
  public params!: ILoadingCellRendererParams & {
    loadingMessage: string;
    errorMessage: boolean;
  };

  agInit(
    params: ILoadingCellRendererParams & {
      loadingMessage: string;
      errorMessage: boolean;
    },
  ): void {
    this.params = params;
  }
}
