<vic-base-page page="dataExplorer-dimensions-sepia">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isDimensionsVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO <DATA></DATA></div>
        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="125"
            *ngIf="shouldShowColumn('manufacturer_pipe')"
            >{{ 'data.book.manufacture.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('val_id')"
            >{{ 'Val id' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="90"
            *ngIf="shouldShowColumn('Pipe')"
            >{{ 'data.book.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Customer_PO')"
            >{{ 'data.book.customer.po' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('Pipe_Heat')"
            >{{ 'data.book.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="90"
            *ngIf="shouldShowColumn('Mill')"
            >{{ 'data.book.mill' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Order')"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('Mill_Item')"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('OD_nominal_(mm)')"
            >{{ 'data.book.od.nominal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('WT_nominal_(mm)')"
            >{{ 'data.book.wt.nominal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('Length_(m)')"
            >{{ 'data.book.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Lot')"
            >{{ 'data.book.lot' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="95"
            *ngIf="shouldShowColumn('Certificate')"
            >{{ 'data.book.certificate' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="125"
            *ngIf="shouldShowColumn('Manufacture_Loc')"
            >{{ 'data.book.manufacture.loc' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('Weight_Bare_(kg)')"
            >{{ 'data.book.weight.bare' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="185"
            *ngIf="shouldShowColumn('Straigthness_p_End_A_(mm)')"
            >{{ 'data.book.straigthness.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="185"
            *ngIf="shouldShowColumn('Straigthness_p_End_B_(mm)')"
            >{{ 'data.book.straigthness.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="185"
            *ngIf="shouldShowColumn('Straigthness_pipe_Body_(mm)')"
            >{{ 'data.book.straigthness.pipe.body' | translate }}</clr-dg-column
          >

          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_OoR_p_End_A_(mm)')"
            >{{ 'data.book.od.oor.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_A_Max_Plan_1(mm)')"
            >{{ 'data.book.od.p.end.a.max.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_A_Min_Plan_1(mm)')"
            >{{ 'data.book.od.p.end.a.min.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_A_Max_Plan_2(mm)')"
            >{{ 'data.book.od.p.end.a.max.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_A_Min_Plan_2(mm)')"
            >{{ 'data.book.od.p.end.a.min.plan.2' | translate }}</clr-dg-column
          >

          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_OoR_p_End_B_(mm)')"
            >{{ 'data.book.od.oor.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_B_Max_Plan_1(mm)')"
            >{{ 'data.book.od.p.end.b.max.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_B_Min_Plan_1(mm)')"
            >{{ 'data.book.od.p.end.b.min.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_B_Max_Plan_2(mm)')"
            >{{ 'data.book.od.p.end.b.max.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_pipe_End_B_Min_Plan_2(mm)')"
            >{{ 'data.book.od.p.end.b.min.plan.2' | translate }}</clr-dg-column
          >

          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_OoR_p_End_A_(mm)')"
            >{{ 'data.book.id.oor.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_A_Avg_Plan_1_(mm)')"
            >{{
              'data.book.id.pipe.end.a.avg.plan.1' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_A_Max_Plan_1_(mm)')"
            >{{
              'data.book.id.pipe.end.a.max.plan.1' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_A_Min_Plan_1_(mm)')"
            >{{
              'data.book.id.pipe.end.a.min.plan.1' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_A_Avg_Plan_2_(mm)')"
            >{{
              'data.book.id.pipe.end.a.avg.plan.2' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_A_Max_Plan_2_(mm)')"
            >{{
              'data.book.id.pipe.end.a.max.plan.2' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_A_Min_Plan_2_(mm)')"
            >{{
              'data.book.id.pipe.end.a.min.plan.2' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_OoR_p_End_B_(mm)')"
            >{{ 'data.book.id.oor.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_B_Avg_Plan_1_(mm)')"
            >{{
              'data.book.id.pipe.end.b.avg.plan.1' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_B_Max_Plan_1_(mm)')"
            >{{
              'data.book.id.pipe.end.b.max.plan.1' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_B_Min_Plan_1_(mm)')"
            >{{
              'data.book.id.pipe.end.b.min.plan.1' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_B_Avg_Plan_2_(mm)')"
            >{{
              'data.book.id.pipe.end.b.avg.plan.2' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_B_Max_Plan_2_(mm)')"
            >{{
              'data.book.id.pipe.end.b.max.plan.2' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('ID_pipe_End_B_Min_Plan_2_(mm)')"
            >{{
              'data.book.id.pipe.end.b.min.plan.2' | translate
            }}</clr-dg-column
          >

          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_1_p_End_A_Plan_1(mm)')"
            >{{ 'data.book.wt.1.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_2_p_End_A_Plan_1(mm)')"
            >{{ 'data.book.wt.2.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_3_p_End_A_Plan_1(mm)')"
            >{{ 'data.book.wt.3.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_4_p_End_A_Plan_1(mm)')"
            >{{ 'data.book.wt.4.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_A_Plan_1_min(mm)')"
            >{{ 'data.book.wt.min.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_A_Plan_1_avg(mm)')"
            >{{ 'data.book.wt.avg.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_A_Plan_1_max(mm)')"
            >{{ 'data.book.wt.max.p.end.a.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_1_p_End_A_Plan_2(mm)')"
            >{{ 'data.book.wt.1.p.end.a.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_2_p_End_A_Plan_2(mm)')"
            >{{ 'data.book.wt.2.p.end.a.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_3_p_End_A_Plan_2(mm)')"
            >{{ 'data.book.wt.3.p.end.a.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_4_p_End_A_Plan_2(mm)')"
            >{{ 'data.book.wt.4.p.end.a.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_A_Plan_2_min(mm)')"
            >{{ 'data.book.wt.min.p.end.a.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_A_Plan_2_avg(mm)')"
            >{{ 'data.book.wt.avg.p.end.a.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_A_Plan_2_max(mm)')"
            >{{ 'data.book.wt.max.p.end.a.plan.2' | translate }}</clr-dg-column
          >

          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_1_p_End_B_Plan_1(mm)')"
            >{{ 'data.book.wt.1.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_2_p_End_B_Plan_1(mm)')"
            >{{ 'data.book.wt.2.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_3_p_End_B_Plan_1(mm)')"
            >{{ 'data.book.wt.3.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_4_p_End_B_Plan_1(mm)')"
            >{{ 'data.book.wt.4.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_B_Plan_1_min(mm)')"
            >{{ 'data.book.wt.min.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_B_Plan_1_avg(mm)')"
            >{{ 'data.book.wt.avg.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_B_Plan_1_max(mm)')"
            >{{ 'data.book.wt.max.p.end.b.plan.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_1_p_End_B_Plan_2(mm)')"
            >{{ 'data.book.wt.1.p.end.b.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_2_p_End_B_Plan_2(mm)')"
            >{{ 'data.book.wt.2.p.end.b.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_3_p_End_B_Plan_2(mm)')"
            >{{ 'data.book.wt.3.p.end.b.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_4_p_End_B_Plan_2(mm)')"
            >{{ 'data.book.wt.4.p.end.b.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_B_Plan_2_min(mm)')"
            >{{ 'data.book.wt.min.p.end.b.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_B_Plan_2_avg(mm)')"
            >{{ 'data.book.wt.avg.p.end.b.plan.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_p_End_B_Plan_2_max(mm)')"
            >{{ 'data.book.wt.max.p.end.b.plan.2' | translate }}</clr-dg-column
          >

          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_avg_Body_(mm)')"
            >{{ 'data.book.od.avg.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_max_Body_(mm)')"
            >{{ 'data.book.od.max.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_min_Body_(mm)')"
            >{{ 'data.book.od.min.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('OD_body_OoR_(mm)')"
            >{{ 'data.book.od.body.oor' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_min_body')"
            >{{ 'data.book.wt.min.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_avg_body')"
            >{{ 'data.book.wt.avg.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('WT_max_body')"
            >{{ 'data.book.wt.max.body' | translate }}</clr-dg-column
          >
          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('manufacturer_pipe')">{{
              data['manufacturer_pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('val_id')">{{
              data['val_id']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe')">{{
              data['Pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Customer_PO')">{{
              data['Customer_PO']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill')">{{
              data['Mill']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Order')">{{
              data['Mill_Order']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_nominal_(mm)')">{{
              data['OD_nominal_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_nominal_(mm)')">{{
              data['WT_nominal_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Length_(m)')">{{
              data['Length_(m)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lot')">{{
              data['Lot']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Certificate')">{{
              data['Certificate']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacture_Loc')">{{
              data['Manufacture_Loc']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Weight_Bare_(kg)')">{{
              data['Weight_Bare_(kg)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('Straigthness_p_End_A_(mm)')"
              >{{ data['Straigthness_p_End_A_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Straigthness_p_End_B_(mm)')"
              >{{ data['Straigthness_p_End_B_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Straigthness_pipe_Body_(mm)')"
              >{{ data['Straigthness_pipe_Body_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('OD_OoR_p_End_A_(mm)')">{{
              data['OD_OoR_p_End_A_(mm)']
            }}</clr-dg-cell>

            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_A_Max_Plan_1(mm)')"
              >{{ data['OD_pipe_End_A_Max_Plan_1(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_A_Min_Plan_1(mm)')"
              >{{ data['OD_pipe_End_A_Min_Plan_1(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_A_Max_Plan_2(mm)')"
              >{{ data['OD_pipe_End_A_Max_Plan_2(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_A_Min_Plan_2(mm)')"
              >{{ data['OD_pipe_End_A_Min_Plan_2(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('OD_OoR_p_End_B_(mm)')">{{
              data['OD_OoR_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_B_Max_Plan_1(mm)')"
              >{{ data['OD_pipe_End_B_Max_Plan_1(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_B_Min_Plan_1(mm)')"
              >{{ data['OD_pipe_End_B_Min_Plan_1(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_B_Max_Plan_2(mm)')"
              >{{ data['OD_pipe_End_B_Max_Plan_2(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('OD_pipe_End_B_Min_Plan_2(mm)')"
              >{{ data['OD_pipe_End_B_Min_Plan_2(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('ID_OoR_p_End_A_(mm)')">{{
              data['ID_OoR_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_A_Avg_Plan_1_(mm)')"
              >{{ data['ID_pipe_End_A_Avg_Plan_1_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_A_Max_Plan_1_(mm)')"
              >{{ data['ID_pipe_End_A_Max_Plan_1_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_A_Min_Plan_1_(mm)')"
              >{{ data['ID_pipe_End_A_Min_Plan_1_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_A_Avg_Plan_2_(mm)')"
              >{{ data['ID_pipe_End_A_Avg_Plan_2_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_A_Max_Plan_2_(mm)')"
              >{{ data['ID_pipe_End_A_Max_Plan_2_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_A_Min_Plan_2_(mm)')"
              >{{ data['ID_pipe_End_A_Min_Plan_2_(mm)'] }}</clr-dg-cell
            >

            <clr-dg-cell *ngIf="shouldShowColumn('ID_OoR_p_End_B_(mm)')">{{
              data['ID_OoR_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_B_Avg_Plan_1_(mm)')"
              >{{ data['ID_pipe_End_B_Avg_Plan_1_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_B_Max_Plan_1_(mm)')"
              >{{ data['ID_pipe_End_B_Max_Plan_1_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_B_Min_Plan_1_(mm)')"
              >{{ data['ID_pipe_End_B_Min_Plan_1_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_B_Avg_Plan_2_(mm)')"
              >{{ data['ID_pipe_End_B_Avg_Plan_2_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_B_Max_Plan_2_(mm)')"
              >{{ data['ID_pipe_End_B_Max_Plan_2_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('ID_pipe_End_B_Min_Plan_2_(mm)')"
              >{{ data['ID_pipe_End_B_Min_Plan_2_(mm)'] }}</clr-dg-cell
            >

            <clr-dg-cell *ngIf="shouldShowColumn('WT_1_p_End_A_Plan_1(mm)')">{{
              data['WT_1_p_End_A_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_2_p_End_A_Plan_1(mm)')">{{
              data['WT_2_p_End_A_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_3_p_End_A_Plan_1(mm)')">{{
              data['WT_3_p_End_A_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_4_p_End_A_Plan_1(mm)')">{{
              data['WT_4_p_End_A_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_A_Plan_1_min(mm)')"
              >{{ data['WT_p_End_A_Plan_1_min(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_A_Plan_1_avg(mm)')"
              >{{ data['WT_p_End_A_Plan_1_avg(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_A_Plan_1_max(mm)')"
              >{{ data['WT_p_End_A_Plan_1_max(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('WT_1_p_End_A_Plan_2(mm)')">{{
              data['WT_1_p_End_A_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_2_p_End_A_Plan_2(mm)')">{{
              data['WT_2_p_End_A_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_3_p_End_A_Plan_2(mm)')">{{
              data['WT_3_p_End_A_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_4_p_End_A_Plan_2(mm)')">{{
              data['WT_4_p_End_A_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_A_Plan_2_min(mm)')"
              >{{ data['WT_p_End_A_Plan_2_min(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_A_Plan_2_avg(mm)')"
              >{{ data['WT_p_End_A_Plan_2_avg(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_A_Plan_2_max(mm)')"
              >{{ data['WT_p_End_A_Plan_2_max(mm)'] }}</clr-dg-cell
            >

            <clr-dg-cell *ngIf="shouldShowColumn('WT_1_p_End_B_Plan_1(mm)')">{{
              data['WT_1_p_End_B_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_2_p_End_B_Plan_1(mm)')">{{
              data['WT_2_p_End_B_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_3_p_End_B_Plan_1(mm)')">{{
              data['WT_3_p_End_B_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_4_p_End_B_Plan_1(mm)')">{{
              data['WT_4_p_End_B_Plan_1(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_B_Plan_1_min(mm)')"
              >{{ data['WT_p_End_B_Plan_1_min(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_B_Plan_1_avg(mm)')"
              >{{ data['WT_p_End_B_Plan_1_avg(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_B_Plan_1_max(mm)')"
              >{{ data['WT_p_End_B_Plan_1_max(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('WT_1_p_End_B_Plan_2(mm)')">{{
              data['WT_1_p_End_B_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_2_p_End_B_Plan_2(mm)')">{{
              data['WT_2_p_End_B_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_3_p_End_B_Plan_2(mm)')">{{
              data['WT_3_p_End_B_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_4_p_End_B_Plan_2(mm)')">{{
              data['WT_4_p_End_B_Plan_2(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_B_Plan_2_min(mm)')"
              >{{ data['WT_p_End_B_Plan_2_min(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_B_Plan_2_avg(mm)')"
              >{{ data['WT_p_End_B_Plan_2_avg(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('WT_p_End_B_Plan_2_max(mm)')"
              >{{ data['WT_p_End_B_Plan_2_max(mm)'] }}</clr-dg-cell
            >

            <clr-dg-cell *ngIf="shouldShowColumn('OD_avg_Body_(mm)')">{{
              data['OD_avg_Body_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_max_Body_(mm)')">{{
              data['OD_max_Body_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_min_Body_(mm)')">{{
              data['OD_min_Body_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_body_OoR_(mm)')">{{
              data['OD_body_OoR_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_min_body')">{{
              data['WT_min_body']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_avg_body')">{{
              data['WT_avg_body']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_max_body')">{{
              data['WT_max_body']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
