<clr-modal
  class="modal-project"
  [clrModalOpen]="modalIsOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div
      *ngIf="modal.confirmArchive"
      class="clr-row"
    >
      <div class="clr-col-12">
        <b>{{ 'confirm.archive.selection' | translate }}</b>
      </div>
    </div>

    <div *ngIf="modal.archiveByText">
      <div class="clr-row">
        <div class="clr-col-12">
          {{ 'confirm.archive.type.project.name' | translate }}
          <strong *ngIf="project">({{ project['name'] }})</strong>
        </div>
        <div class="clr-col-12">
          <form
            [formGroup]="form"
            clrForm
            class="project-form"
          >
            <input-form-control
              label="application.commons.text.name"
              name="name"
              formControlName="name"
              [required]="true"
              [form]="form"
            ></input-form-control>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      *ngIf="modal.confirmArchive"
      type="button"
      class="btn btn-outline"
      (click)="confirmArchive()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>

    <button
      *ngIf="modal.archiveByText"
      [disabled]="!form.valid || isEqual()"
      type="button"
      class="btn btn-outline"
      (click)="archive()"
    >
      {{ 'confirm.exclusion.archive' | translate }}
    </button>
  </div>
</clr-modal>
