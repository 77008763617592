import { twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName:
    'PELM End A (S1: 30 mm for non-machined and machined items ; S2: 80 mm for non-machined and 130 mm for machined items)',
  headerClass: 'custom-header-2',
  children: [
    {
      headerName: 'ID - M1',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m1_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m1_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',

          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m1_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m1_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M2',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m2_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m2_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',

          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m2_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m2_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M3',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m3_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m3_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m3_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m3_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M4',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m4_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m4_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m4_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m4_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M5',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m5_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m5_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m5_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m5_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M6',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m6_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m6_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m6_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m6_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M7',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m7_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m7_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m7_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m7_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - M8',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m8_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m8_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_m8_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_m8_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - Min',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_id_min'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_id_min'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_id_min'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_id_min'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'ID - Avg',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_avg_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_avg_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_avg_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_avg_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - Max',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_max_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_max_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_max_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_max_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'ID - OOR',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_oor_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_oor_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_id_oor_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_oor_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'OD - M1',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m1'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m1'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m1'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m1'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M2',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m2'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m2'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m2'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m2'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M3',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m3'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m3'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m3'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m3'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M4',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m4'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m4'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m4'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m4'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M5',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m5'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m5'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m5'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m5'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M6',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m6'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m6'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m6'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m6'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M7',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m7'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m7'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m7'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m7'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - M8',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m8'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m8'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_m8'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_m8'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - Min',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_min'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_min'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_min'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_min'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - Avg',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_avg'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_avg'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_avg'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_avg'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - Max',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_max'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_max'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_od_max'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_od_max'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'OD - OOR',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_od_oor_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_od_oor_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_od_oor_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_od_oor_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'WT - M1',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m1'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m1'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m1'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m1'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M2',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m2'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m2'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m2'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m2'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M3',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m3'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m3'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m3'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m3'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M4',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m4'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m4'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m4'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m4'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M5',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m5'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m5'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m5'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m5'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M6',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m6'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m6'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m6'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m6'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M7',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m7'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m7'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m7'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m7'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M8',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m8'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m8'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m8'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m8'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M9',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m9'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m9'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m9'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m9'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M10',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m10'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m10'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m10'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m10'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M11',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m11'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m11'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m11'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m11'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M12',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m12'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m12'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m12'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m12'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M13',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m13'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m13'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m13'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m13'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M14',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m14'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m14'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m14'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m14'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M15',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m15'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m15'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m15'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m15'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - M16',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m16'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m16'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_m16'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_m16'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - Min',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_min'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_min'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_min'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_min'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - Avg',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_wt_avg_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_wt_avg_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_wt_avg_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_wt_avg_end_a_section_2']],
        },
      ],
    },
    {
      headerName: 'WT - Max',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_max'] + ' - End A - Section 1'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_max'] + ' - End A - Section 1'
            ],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[
                COLUMNS['laser_flex_line_wt_max'] + ' - End A - Section 2'
              ],
            ),
          valueGetter: (params: any) =>
            params.data[
              COLUMNS['laser_flex_line_wt_max'] + ' - End A - Section 2'
            ],
        },
      ],
    },
    {
      headerName: 'WT - Ecc',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Section1',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_wt_ecc_end_a_section_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_wt_ecc_end_a_section_1']],
        },
        {
          field: 'Section2',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_wt_ecc_end_a_section_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_wt_ecc_end_a_section_2']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Roughness',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_pup_pieces_roughness_end_a']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_pup_pieces_roughness_end_a']],
        },
        {
          field: 'Squareness',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_pup_pieces_squareness_end_a']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_pup_pieces_squareness_end_a']],
        },
        {
          field: 'Taper Angle',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_pup_pieces_taper_angle_end_a']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_pup_pieces_taper_angle_end_a']],
        },
      ],
    },
  ],
};
