import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Location, registerLocaleData } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigHandler } from './handlers/config.handler';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Cookies from 'js-cookie';

import localeEn from '@angular/common/locales/en';
import localePt from '@angular/common/locales/pt';
import * as pako from 'pako';
import { Buffer } from 'buffer';
import moment from 'moment';
import { USER_STORE, UserStore } from '../mobx-stores';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private config: ConfigHandler,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    let langToCheck = this.getCookie('_currentLanguage');
    if (!langToCheck) {
      langToCheck = navigator.language;
    }

    let lang = ['en', 'pt-BR'].find((_lang: string) => _lang === langToCheck);
    if (!lang) {
      lang = 'en';
    }
    this.registerLocale(lang);
    translateService.setDefaultLang(lang);
  }
  currentUser: any;
  settings: any = {};
  public currentUrl = '';

  /**
   * LOADING ------------------------------------------------------------------------------------------
   */

  private loading = false;

  private registerLocale(lang) {
    if (lang === 'en') {
      registerLocaleData(localeEn);
    } else {
      registerLocaleData(localePt);
    }

    // pref_language_vic
    // 24 horas
    this.setCookie('_currentLanguage', lang, 86400);
  }

  public async getAuthToken(): Promise<string> {
    const cookie = this.getCookie('_ssoToken');
    return cookie;
  }

  public async getUserDetail(): Promise<any> {
    // console.log('getUserDetail');

    const options = {};
    const response = await this.callHttpPost(
      this.config.environment.endpoints.security + '/user/detail',
      null,
      options,
    );
    return response;
  }

  public async getUserVerify(token): Promise<any> {
    const options = { verifyToken: token };
    const response = await this.callHttpPost(
      this.config.environment.endpoints.security + '/user/verify',
      null,
      options,
    );
    return response;
  }

  public async loadVefifyToken(verifyToken): Promise<void> {
    const response = await this.getUserVerify(verifyToken);
    const expiresValue = new Date(new Date().getTime() + 60 * 60 * 1000); // expires in 60 minutes
    Cookies.set('_ssoToken', response.data, {
      expires: expiresValue,
      domain: this.config.environment.cookie_domain,
      path: '/',
    });
  }

  public async loadUserDetails(): Promise<boolean> {
    if (!this.currentUser) {
      const response = await this.getUserDetail();
      this.currentUser = response.user;
      this.settings = response.rootSettings;
    }
    return true;
  }

  public async reloadUserDetails(): Promise<void> {
    const response = await this.getUserDetail();
    this.currentUser = response.user;
    this.settings = response.rootSettings;
  }

  public getRouter(): Router {
    return this.router;
  }

  public getLocation(): Location {
    return this.location;
  }

  public getLanguage(): string {
    let lang = this.getCookie('_currentLanguage');
    if (!lang) {
      lang = this.translateService.currentLang
        ? this.translateService.currentLang
        : this.translateService.defaultLang;
    }
    return lang;
  }

  public getFormat = () =>
    this.getLanguage() !== 'pt-BR'
      ? 'MM/DD/YYYY HH:mm:ss'
      : 'DD/MM/YYYY HH:mm:ss';

  public getFormatNoHours = () =>
    this.getLanguage() !== 'pt-BR' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  public formatDateNoHours = (date: string) => {
    if (!date) {
      return '';
    }
    const formattedDate = moment(date);

    if (!formattedDate.isValid()) {
      return '';
    }

    return formattedDate.format(this.getFormatNoHours());
  };

  public formatDateMsec = (date: number) => {
    if (!date) {
      return '';
    }
    try {
      date = Number(date);
    } catch (e) {
      return '';
    }
    const formattedDate = moment(date);
    if (!formattedDate.isValid()) {
      return '';
    }
    return formattedDate.format(this.getFormat());
  };

  public formatDate = (date: string) => {
    if (!date) {
      return '';
    }
    const formattedDate = moment(date);

    if (!formattedDate.isValid()) {
      return '';
    }

    return formattedDate.format(this.getFormat());
  };

  public formatDateGmt = (date: string) => {
    const formatted = this.formatDate(date);
    const hours = moment(date).utcOffset() / 60;
    return (
      formatted +
      ' (GMT ' +
      Math.trunc(hours) +
      (hours % 1 === 0 ? ':00)' : ':30)')
    );
  };

  public formatDateTzn = (date: string) => {
    const formatted = this.formatDate(date);
    return (
      formatted + ' (' + Intl.DateTimeFormat().resolvedOptions().timeZone + ')'
    );
  };

  public async getRouterParams(): Promise<Params> {
    return new Promise((resolve, reject) => {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        resolve(params);
      });
    });
  }

  public getConfig() {
    return {
      currentUser: this.currentUser,
      applicationSettings: this.settings,
      environment: this.config.environment,
    };
  }

  public getRoleSettings(roleID) {
    const roleResult = { role: null };
    this.findRoleByID(roleResult, this.settings, roleID);
    return roleResult.role;
  }

  private findRoleByID = (roleResult, settings, roleID) => {
    for (const role of settings) {
      if (roleResult.role !== null) {
        break;
      } else {
        if (role.id === roleID) {
          roleResult.role = role;
        } else if (role.settings && role.settings.length > 0) {
          this.findRoleByID(roleResult, role.settings, roleID);
        }
      }
    }
  };

  /**
   * TRANSLATE ------------------------------------------------------------------------------------------
   */

  public switchLanguage(language: string) {
    this.translateService.use(language);
    this.translateService.reloadLang(language);
    this.registerLocale(language);
  }

  public translate(key: string | Array<string>, interpolateParams?: any): any {
    return this.translateService.instant(key, interpolateParams);
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public showLoading() {
    this.loading = true;
  }

  public hideLoading() {
    this.loading = false;
  }

  /**
   * ALERTS ------------------------------------------------------------------------------------------
   */

  /**
   * @description
   * Show success monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertSuccessTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertSuccess(translated, optionsToast);
  }

  /**
   * @description
   * Show success monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertSuccess(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.closeButton = true;
    options.positionClass = 'toast-bottom-right';
    return this.toastr.success(message, '', options).onTap;
  }

  /**
   * @description
   * Show info monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertInfoTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertInfo(translated, optionsToast);
  }

  /**
   * @description
   * Show info monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertInfo(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.positionClass = 'toast-bottom-right';
    return this.toastr.info(message, '', options).onTap;
  }

  /**
   * @description
   * Show danger monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertDangerTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertDanger(translated, optionsToast);
  }

  /**
   * @description
   * Show danger monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertDanger(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.positionClass = 'toast-bottom-right';
    return this.toastr.warning(message, '', options).onTap;
  }

  /**
   * @description
   * Show error monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertErrorTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertError(translated, optionsToast);
  }

  /**
   * @description
   * Show error monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertError(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.positionClass = 'toast-bottom-right';
    return this.toastr.error(message, '', options).onTap;
  }

  showUserMessage(message: string) {
    this.toastr.show(message, '', {
      toastClass: 'toast-user-message ngx-toastr',
      closeButton: true,
      disableTimeOut: true,
      enableHtml: true,
    });
  }

  /**
   * ERRORS -----------------------------------------------------------------------------------------
   */

  public async processError(error: any, showAlert = true): Promise<string> {
    this.hideLoading();

    const serviceUnavailableMessage =
      'Service is temporally not available. Please verify your internet connection and try again!';

    console.error('Proccess Error', error);
    let message = null;
    if (!error) {
      message = serviceUnavailableMessage;
    } else if (error.message) {
      message = error.message;
    } else if (error.errorMessage) {
      message = error.errorMessage;
    } else if (error._body) {
      try {
        const bodyError = JSON.parse(error._body);
        if (bodyError.message) {
          message = bodyError.message;
        } else if (bodyError.errorMessage) {
          message = bodyError.errorMessage;
        } else {
          message = serviceUnavailableMessage;
        }
      } catch (error) {
        message = serviceUnavailableMessage;
      }
    } else if (Array.isArray(error) && error.length && error.length > 0) {
      message = error[0];
    } else {
      message = serviceUnavailableMessage;
    }

    if (message) {
      if (message.indexOf('read ECONNRESET') !== -1) {
        message =
          'Internet connection problem! Verify your internet connection. Its looks very slow or a firewall could be blocking.';
      }
      if (
        message.indexOf('Invalid Security Token') !== -1 ||
        message.indexOf('Unauthorized') !== -1
      ) {
        window.location.href =
          this.config.environment.links.login +
          '/logout?app=' +
          this.config.applicationId;
      } else if (message.indexOf('Token Expired') !== -1) {
        message = 'Session Expired!';
        this.showAlertInfo(message);
        window.location.href =
          this.config.environment.links.login +
          '/refresh?app=' +
          this.config.applicationId;
      } else if (showAlert) {
        this.showAlertError(message);
      }
    }
    return message;
  }

  public logout() {
    window.location.href =
      this.config.environment.links.login +
      '/logout?app=' +
      this.config.applicationId;
  }

  public registryMessageLog(functionality: string, message: string) {
    const user = this.currentUser;
    if (user && functionality) {
      this.registryLog(user, functionality, message);
    }
  }

  // HTTP SERVICES
  public async callHttpGet(endpoint: string, options: any = {}): Promise<any> {
    const headers = await this.getHeader(options);
    return new Promise((resolve, reject) => {
      this.http.get(endpoint, { headers: new HttpHeaders(headers) }).subscribe(
        (response: any) => {
          const data = response;

          console.log(' ----------  RESPONSE   ----------   ');
          console.log(data);

          let _body = data.body ? data.body : data;
          if (options.decompress) {
            const buffer = Buffer.from(_body, 'base64');
            const inflated = pako.inflate(buffer);
            _body = JSON.parse(this.utf8ArrayToStr(inflated));
          }

          const result = _body; // {data: _body, settings: data.settings };

          if (!this.config.environment.production) {
            // console.log(' ----------  RESPONSE   ----------   ');
            // console.log(result);
            // console.log(' --------------------------------   ');
          }

          resolve(result);
        },
        (err) => {
          err.error.status = err.status ? err.status : 500;
          reject(err.error);
        },
      );
    });
  }

  public async callHttpPost(
    endpoint: string,
    params: any = {},
    options: any = {},
  ): Promise<any> {
    // options.decompress = false;
    const headers = await this.getHeader(options);
    return new Promise((resolve, reject) => {
      if (!params) {
        params = {};
      }

      if (options.prepareParams === undefined || options.prepareParams) {
        params = this.prepareParams(params);
      }

      this.http
        .post(endpoint, params, { headers: new HttpHeaders(headers) })
        .subscribe(
          (response: any) => {
            const data = response;

            let _body = data.body ? data.body : data;

            if (options.decompress) {
              const buffer = Buffer.from(_body, 'base64');
              const inflated = pako.inflate(buffer);
              _body = JSON.parse(this.utf8ArrayToStr(inflated));
            }
            let result = _body; // {data: _body, settings: data.settings };

            if (this.isStringJSON(result)) {
              result = JSON.parse(result);
            }

            if (!this.config.environment.production) {
              // console.log(' ----------  RESPONSE   ----------   ');
              // console.log(result);
              // console.log(' --------------------------------   ');
            }
            resolve(result);
          },
          (err) => {
            if (err.status === 0) {
              window.location.href =
                this.config.environment.links.login +
                '/login?app=' +
                this.config.applicationId;
            } else {
              err.error.status = err.status ? err.status : 500;
              reject(err.error);
            }
          },
        );
    });
  }

  public async typedCallHttpPost<T>(
    endpoint: string,
    params: any = {},
    options: any = {},
  ): Promise<T> {
    options.decompress = false;
    const headers = await this.getHeader(options);
    return new Promise((resolve, reject) => {
      if (!params) {
        params = {};
      }

      if (options.prepareParams === undefined || options.prepareParams) {
        params = this.prepareParams(params);
      }

      // console.log('OOOOOOO', endpoint, params, { headers: new HttpHeaders(headers) });

      this.http
        .post(endpoint, params, { headers: new HttpHeaders(headers) })
        .subscribe(
          (response: any) => {
            const data = response;

            let _body = data.body ? data.body : data;

            if (options.decompress) {
              const buffer = Buffer.from(_body, 'base64');
              const inflated = pako.inflate(buffer);
              _body = JSON.parse(this.utf8ArrayToStr(inflated));
            }
            let result = _body; // {data: _body, settings: data.settings };

            if (this.isStringJSON(result)) {
              result = JSON.parse(result);
            }

            if (!this.config.environment.production) {
              // console.log(' ----------  RESPONSE   ----------   ');
              // console.log(result);
              // console.log(' --------------------------------   ');
            }
            resolve(result as T);
          },
          (err) => {
            err.error.status = err.status ? err.status : 500;
            reject(err.error);
          },
        );
    });
  }

  isStringJSON(text) {
    if (this.isString(text)) {
      try {
        const json = JSON.parse(text);
        return typeof json === 'object';
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  isString(variable) {
    return variable instanceof String || typeof variable === 'string';
  }

  // HTTP SERVICES
  public async callSimpleHttpGet(
    endpoint: string,
    options: any = {},
    header: any = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(endpoint, header).subscribe(
        (response: any) => {
          const data = response;

          let _body = data.body ? data.body : data;
          if (options.decompress) {
            try {
              const buffer = Buffer.from(_body, 'base64');
              const inflated = pako.inflate(buffer);
              _body = JSON.parse(this.utf8ArrayToStr(inflated));
            } catch (err) {
              console.log('err ==> ', err);
            }
          }

          let result = _body; // {data: _body, settings: data.settings };

          if (this.isStringJSON(result)) {
            result = JSON.parse(result);
          }

          if (!this.config.environment.production) {
            // console.log(' ----------  RESPONSE   ----------   ');
            // console.log(result);
            // console.log(' --------------------------------   ');
          }

          resolve(result);
        },
        (err) => {
          err.error.status = err.status ? err.status : 500;
          reject(err.error);
        },
      );
    });
  }

  public async getHeader(options: any = {}): Promise<any> {
    options.decompress = options.decompress ? options.decompress : false;
    options.userAuthorization =
      options.userAuthorization !== undefined
        ? options.userAuthorization
        : true;
    options.userAuthorizationType =
      options.userAuthorizationType !== undefined
        ? options.userAuthorizationType
        : 'custom';
    const idToken = await this.getAuthToken();

    const headers: any = {
      'Content-Type': 'application/json',
      'Accept-Language': this.translateService.currentLang
        ? this.translateService.currentLang
        : this.translateService.getDefaultLang(),
    };

    const mockTest = this.getCookie('mockTest');
    if (mockTest) {
      headers.mockTest = mockTest;
    }

    if (options.userAuthorization && idToken && idToken !== '') {
      headers.Authorization = options.verifyToken
        ? options.verifyToken
        : idToken;
      if (options.userAuthorizationType === 'custom') {
        const application = {
          id: this.config.applicationId,
        };
        headers['VLR-Authorization'] = btoa(JSON.stringify(application));
      }
    }

    return headers;
  }

  public utf8ArrayToStr(array) {
    let out;
    let i;
    let c;
    let char2;
    let char3;

    out = '';
    const len = array.length;
    i = 0;
    while (i < len) {
      c = array[i++];
      // tslint:disable-next-line:no-bitwise
      const comparator = c >> 4;
      switch (comparator) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c);
          break;
        case 12:
        case 13:
          // 110x xxxx   10xx xxxx
          char2 = array[i++];
          // tslint:disable-next-line:no-bitwise
          out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
          break;
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = array[i++];
          char3 = array[i++];
          out += String.fromCharCode(
            // tslint:disable-next-line:no-bitwise
            ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
          );
          break;
      }
    }
    return out;
  }

  public prepareParams(params: any) {
    const body = params ? params : {};
    if (!this.config.environment.production) {
      // console.log(' ----------  REQUEST   ----------   ');
      // console.log(body);
      // console.log(' --------------------------------   ');
    }
    return JSON.stringify(body);
  }

  public registryLog(user: any, functionality: string, message: string) {
    if (this.config.environment.endpoints.accessCtrl) {
      const params = {
        type: 'access',
        user: {
          userId: user.id,
          name: user.name,
          email: user.email,
        },
        functionality,
        application: this.config.applicationId.toLowerCase(),
        message,
      };

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');

      this.http
        .post(this.config.environment.endpoints.accessCtrl, params, {
          headers,
        })
        .subscribe(
          (response) => {},
          (error) => {},
        );
    }
  }

  // COOKIE SERVIC3
  public getCookie(name): string {
    return Cookies.get(name);
  }

  public removeCookie(name) {
    Cookies.remove(name, {
      domain: this.config.environment.cookie_domain,
      path: '/',
    });
  }

  public setCookie(name, value, maxAge) {
    Cookies.set(name, value, {
      expires: maxAge / 86400,
      domain: this.config.environment.cookie_domain,
      path: '/',
    });
  }
}
