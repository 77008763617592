import { Component, Inject } from '@angular/core';
import {
  FileOrFolder,
  TREEVIEW_STORE,
  TreeviewStore,
} from '@mobx-stores/treeview.store';
import { DataBookBaseComponent } from 'src/app/pages/databook-base.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '@components/pdf/pdf.service';
import { CoreService } from '@core/core.service';
import { PROJECT_STORE, ProjectsStore } from '@mobx-stores/projects.store';
import { USER_STORE, UserStore } from '@mobx-stores/user.store';
import { DataBookService } from '@services/databook.service';
import { DataBookState } from '@services/databook.state';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor } from '@angular/common';
import { FormatBytesPipe } from './format-bytes.pipe';

@Component({
  selector: 'treeview-dropdown-list-component',
  templateUrl: './treeview-dropdown-list-component.component.html',
  styleUrl: './treeview-dropdown-list-component.component.scss',
  standalone: true,
  imports: [NgIf, NgFor, TranslateModule, FormatBytesPipe],
})
export class TreeviewDropdownListComponentComponent extends DataBookBaseComponent {
  open = true;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public httpClient: HttpClient,
    public pdfService: PdfService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  openDropdown() {
    this.open = this.treeviewStore.selectedObjects.length > 0 && !this.open;
  }

  removeFromSelection(item: FileOrFolder) {
    this.treeviewStore.removeSelectedObject(item);
  }
}
