export class PermissionMatriceItemDto {
  projectId: number;
  projectName: string;
  productId: number;
  productName: string;
  featureId: number;
  featureName: string;
  permissionId: number;
  permissionName: string;
  productFeatureId: number;
}

export class PermissionMatricePermissionDto {
  id: number;
  name: string;
  productFeatureId: number;
  active = false;
}

export class PermissionMatriceFeaturesDto {
  id: number;
  name: string;
  productFeatureId: number;
  permissions: PermissionMatricePermissionDto[];
}

export class PermissionMatriceProductsDto {
  id: number;
  name: string;
  features: PermissionMatriceFeaturesDto[];
}

export class PermissionMatriceProjectsDto {
  id: number;
  name: string;
  products: PermissionMatriceProductsDto[];
}
