import { DataExplorerCellRendererHighlightedComponent } from '../../components/data-explorer-cell-renderer-highlighted/data-explorer-cell-renderer-highlighted.component';
import { ColDefDataExplorer } from '../../data-explorer';

/**
 * There is a lot of files that defines colums types field values and children.
 * We could hard write and import CellRendererWithHighlightComponent in each column definition,
 * But I prefered automaticaly insert the component via this function for now
 *
 * @param columns
 * @returns
 */
export function addCellRenderedComponentOnEachColumnDef(
  columns: ColDefDataExplorer[],
  reducer?: (column: ColDefDataExplorer) => ColDefDataExplorer,
) {
  return columns.map((column) => {
    if (column.children && column.children.length > 0) {
      addCellRenderedComponentOnEachColumnDef(column.children, reducer);
    }

    const isAField = column['field'];
    if (isAField) {
      column.cellRendererSelector = (params) => ({
        component: DataExplorerCellRendererHighlightedComponent,
        params,
      });
    }
    return reducer ? reducer(column) : column;
  });
}
