import { HttpClient } from '@angular/common/http';
import '@angular/common/locales/global/pt';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'ag-grid-enterprise';

import { configure } from 'mobx';

export function HttpLoaderFactory(http: HttpClient) {
  // Quick fix since there is a probleme of cache regarding the i18n files
  // No cache, download on every page loading
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v=' + Date.now(),
  );
}

configure({ isolateGlobalState: true });
