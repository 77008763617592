<vic-base-page page="data-analysis">
  <div class="header-actions container"></div>

  <div class="page-body-container">
    <a
      class="btn-link"
      id="downloadFiles"
      [hidden]="true"
    ></a>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="dashboard-container">
      <div
        class="dashboard-item clr-col-12"
        (click)="goToTestType('statistics')"
      >
        <img
          id="card-icon"
          src="assets/img/svg/statistics-qp.svg"
        />
        <div id="card-text">
          <p class="session-title">
            {{ 'databook.data.analysis.statistics' | translate }}
          </p>
        </div>
      </div>

      <div
        class="dashboard-item clr-col-12"
        (click)="goToTestType('boxplot')"
      >
        <img
          id="card-icon"
          src="assets/img/svg/tendency-qp.svg"
        />
        <div id="card-text">
          <p class="session-title">
            {{ 'databook.data.analysis.boxplot' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>
