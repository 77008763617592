import { IServerSideGetRowsParams } from 'ag-grid-community';
import { DataExplorerRow } from '../data-explorer';

export function supplyToGrid(
  params: IServerSideGetRowsParams,
  dataExplorerRowList: DataExplorerRow[],
) {
  return params.success({
    rowData: dataExplorerRowList,
    rowCount: dataExplorerRowList.length,
  });
}
