<div class="filter-column-container">
  <label class="label-small">{{
    'databook.filter.column.range' | translate
  }}</label>

  <select
    class="select-filter"
    [(ngModel)]="selectedColumn"
    (change)="onChange()"
  >
    <option
      *ngFor="let column of columns"
      [ngValue]="column"
    >
      <label class="label-small">{{ column.description }}</label>
    </option>
  </select>

  <div
    class="slider-container"
    *ngIf="selectedColumn?.name && !selectedColumn.string"
  >
    <div class="slider-item">
      <label class="slider-value-label">Min ({{ selectedColumn.min }}): </label>
      <input
        type="number"
        class="range-slider"
        min="selectedColumn.min"
        max="selectedColumn.maxSelected"
        step="0.001"
        (change)="onChangeMinSlider()"
        [(ngModel)]="selectedColumn.minSelected"
      />
    </div>
    <div class="slider-item">
      <label class="slider-value-label">Max ({{ selectedColumn.max }}): </label>
      <input
        type="number"
        class="range-slider"
        max="selectedColumn.max"
        min="selectedColumn.minSelected"
        step="0.001"
        (change)="onChangeMaxSlider()"
        [(ngModel)]="selectedColumn.maxSelected"
      />
    </div>
  </div>

  <div
    class="slider-container"
    *ngIf="selectedColumn?.name && selectedColumn.string"
  >
    <div class="slider-item">
      <label class="slider-value-label"
        >{{ 'dataExplorer.filter.value.string' | translate }}:
      </label>
      <input
        type="text"
        (keyup)="onChange()"
        [(ngModel)]="selectedColumn.stringFilter"
      />
    </div>
  </div>

  <div class="slider-container">
    <div
      class="slider-item"
      *ngIf="showPipe"
    >
      <label
        class="slider-value-label"
        *ngIf="dataBookState.product !== 'DOUBLE_JOINT'"
        >{{ 'plp.filter.value.pipe' | translate }}:
      </label>
      <label
        class="slider-value-label"
        *ngIf="dataBookState.product === 'DOUBLE_JOINT'"
        >DJ Number:
      </label>
      <input
        type="text"
        (keyup)="onChange()"
        [(ngModel)]="pipeFilter"
      />
    </div>

    <div
      class="slider-item"
      *ngIf="
        dataBookState.product !== 'DOUBLE_JOINT' &&
        dataBookState.product !== 'COATING'
      "
    >
      <label class="slider-value-label"
        >{{ 'plp.filter.value.heat' | translate }}:
      </label>
      <input
        type="text"
        (keyup)="onChange()"
        [(ngModel)]="heatFilter"
      />
    </div>
  </div>
</div>
