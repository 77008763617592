<div class="clr-row">
  <clr-datagrid
    [id]="id"
    class="materials-table"
    [(clrDgSelected)]="selectedDocument"
    (clrDgSelectedChange)="handleSelectedDocument($event)"
    [clrDgPreserveSelection]
  >
    <clr-dg-column *ngFor="let head of tableHeads">
      <button
        *ngIf="!!head?.title"
        type="button"
        class="btn btn-outline button-secondary tab-button no-box-shadow"
        (click)="sort(head.field)"
        [ngClass]="selectedColumn !== head.field ? 'no-border' : ''"
      >
        {{ head.title | translate }}
        <clr-icon
          shape="center-text"
          size="30"
          class="dark-color"
        ></clr-icon>
      </button>
    </clr-dg-column>

    <clr-dg-row
      *clrDgItems="let doc of documents"
      [clrDgItem]="doc"
      [clrDgSelectable]="
        !this.selectedDocumentStatus ||
        doc.action === this.selectedDocumentStatus
      "
    >
      <clr-dg-cell [ngClass]="'idName' + type">
        <div [innerHTML]="doc.name"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idProjectName' + type">
        <div [innerHTML]="doc.project_name"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idProductName' + type">
        <div [innerHTML]="doc.product_name"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idDocumentName' + type">
        <div [innerHTML]="doc.document_description"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idDocumentRevision' + type">
        <div [innerHTML]="doc.document_revision"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idStatus' + type">
        <div [innerHTML]="doc.action"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idCustom' + type">
        <div [innerHTML]="doc.custom"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idDocumentCode' + type">
        <div [innerHTML]="doc.document_code"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idStartedBy' + type">
        <div [innerHTML]="doc.started_by"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idCreatedOn' + type">
        <div [innerHTML]="doc.created_on"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idShakeHolder' + type">
        <div [innerHTML]="doc.shakeholder"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idDocDeadLine' + type">
        <div [innerHTML]="doc.deadline"></div>
      </clr-dg-cell>
      <clr-dg-cell [ngClass]="'idComment' + type">
        <div [innerHTML]="doc.comment"></div>
      </clr-dg-cell>
      <clr-dg-cell>
        <button
          type="button"
          [id]="'dropViewDocIdButtonPending' + actionButtonOverflowId"
          class="btn btn-icon"
          aria-label="home"
          (click)="viewDocument(doc)"
        >
          <clr-icon shape="eye"></clr-icon>
        </button>
      </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer class="dg-footer">
      <clr-dg-pagination
        #paginationTaskDetails
        [clrDgPageSize]="10"
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            margin-right: 1vw;
          "
        >
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
            {{
              'application.commons.grid.footer.pagination.page.size' | translate
            }}
          </clr-dg-page-size>

          <div>
            {{ paginationTaskDetails.firstItem + 1 }} -
            {{ paginationTaskDetails.lastItem + 1 }}
            {{ 'application.commons.grid.footer.pagination.of' | translate }}
            {{ paginationTaskDetails.totalItems }}
            {{
              'application.commons.grid.footer.pagination.records' | translate
            }}
          </div>
        </div>
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
