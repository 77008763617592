import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { CoreService } from '../core.service';
import { ConfigHandler } from '../handlers/config.handler';
import { DataBookState } from '../../services/databook.state';
import { removeCookie, setCookie } from '../../components/utils';
import { USER_STORE, UserStore } from '../../mobx-stores';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard {
  constructor(
    private dataBookState: DataBookState,
    private config: ConfigHandler,
    private coreService: CoreService,
    protected router: Router,
    @Inject(USER_STORE) private userStore: UserStore,
  ) {}

  setUrlString() {
    const params = window.location.search;
    if (params && params.length) {
      setCookie(
        'savedUrlWithParameters',
        window.location.href,
        0.05,
        this.config.environment.cookie_domain,
      );
    }
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (document.getElementById('app-start-loading')) {
      document.getElementById('app-start-loading').style.display = 'none';
    }

    // console.log('----------------CAN ACTIVATE--------------------');

    const url = state.url.trim();

    // Load user in session. If no user or session expired try to refresh token;
    const query = new URLSearchParams(window.location.search);
    const verifyToken = query.get('token');
    const authToken = await this.userStore.getAuthToken();

    /*console.log({
      query,
      verifyToken,
      authToken
    });*/

    if (authToken) {
      if (verifyToken) {
        window.location.replace(window.location.origin);
        return false;
      } else {
        try {
          await this.userStore.loadUserDetails();
          // console.log('------------------------------------  REMOVEU COOKIE ');
          // console.log('------------------------------------  USER', this.userStore?.user.email);
        } catch (err) {
          if (err.status === 402) {
            window.location.replace(
              this.config.environment.links.login +
                '/waiting-access?app=' +
                this.config.applicationId,
            );
            return false;
          } else if (err.status === 403) {
            this.setUrlString();
            window.location.replace(
              this.config.environment.links.login +
                '/request?app=' +
                this.config.applicationId,
            );
            return false;
          } else if (err.status === 401) {
            this.setUrlString();
            window.location.replace(
              this.config.environment.links.login +
                '/refresh?app=' +
                this.config.applicationId,
            );
            return false;
          } else {
            this.coreService.showAlertError(err.message);
          }
        }
      }
    } else {
      if (verifyToken) {
        try {
          await this.userStore.loadVefifyToken(verifyToken);
          window.location.replace(window.location.origin);
        } catch (err) {
          if (err.status === 402) {
            window.location.replace(
              this.config.environment.links.login +
                '/waiting-access?app=' +
                this.config.applicationId,
            );
            return false;
          } else if (err.status === 403) {
            window.location.replace(
              this.config.environment.links.login +
                '/request?app=' +
                this.config.applicationId,
            );
            return false;
          } else if (err.status === 401) {
            window.location.replace(
              this.config.environment.links.login +
                '/refresh?app=' +
                this.config.applicationId,
            );
            return false;
          } else {
            this.coreService.showAlertError(err.message);
          }
        }
      } else {
        window.location.replace(
          this.config.environment.links.login +
            '/refresh?app=' +
            this.config.applicationId,
        );
        return false;
      }
    }
    this.coreService.currentUrl = url;
    return true;
  }
}
