<div
  class="progress flash progress-fade"
  [class.flash-danger]="error"
>
  <progress
    [value]="progress"
    [max]="100"
    data-displayval="0%"
  >
    >
  </progress>
</div>
