import { DataBookService } from '../../services/databook.service';
import { Project } from '../project.class';
import { Product } from '../product.class';
import { Features } from '../features.class';
import { PermissionDTO } from '../permission.dto';
import {
  PermissionMatriceItemDto,
  PermissionMatriceProjectsDto,
} from '../permission-matrice-item.dto';

export const getAllProjectsForUserPermissions = async (
  dataBookService: DataBookService,
): Promise<Project[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getAllProjectsForUserPermissions',
    args: {},
  };
  return await dataBookService.newLambda<Project[]>(params);
};

export const projectGetProductsForUserPermissions = async (
  dataBookService: DataBookService,
  projectId: number,
): Promise<Product[]> => {
  const params: { functionName; args: any } = {
    functionName: 'projectGetProductsForUserPermissions',
    args: {
      projectId,
    },
  };
  return await dataBookService.newLambda<Product[]>(params);
};

export const productGetFeaturesForUserPermissions = async (
  dataBookService: DataBookService,
  productId: number,
): Promise<Features[]> => {
  const params: { functionName; args: any } = {
    functionName: 'productGetFeaturesForUserPermissions',
    args: {
      productId,
    },
  };
  return await dataBookService.newLambda<Features[]>(params);
};

export const getPermissions = async (
  dataBookService: DataBookService,
  featureId: number,
): Promise<PermissionDTO[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getPermissions',
    args: {
      featureId,
    },
  };
  return await dataBookService.newLambda<PermissionDTO[]>(params);
};

export const getAllPermissions = async (
  dataBookService: DataBookService,
): Promise<PermissionMatriceProjectsDto[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getAllPermissions',
    args: {},
  };
  return await dataBookService.newLambda<PermissionMatriceProjectsDto[]>(
    params,
  );
};

export const getAllPermissionsForUser = async (
  dataBookService: DataBookService,
  userId: number,
): Promise<PermissionMatriceProjectsDto[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getAllPermissionsForUser',
    args: {
      userId,
    },
  };
  return await dataBookService.newLambda<PermissionMatriceProjectsDto[]>(
    params,
  );
};

export const savePermissionForUser = async (
  dataBookService: DataBookService,
  userId: number,
  permissionMatrice: PermissionMatriceProjectsDto[],
): Promise<PermissionMatriceProjectsDto[]> => {
  const params: { functionName; args: any } = {
    functionName: 'savePermissionForUser',
    args: {
      userId,
      permissionMatrice,
    },
  };
  return await dataBookService.newLambda<PermissionMatriceProjectsDto[]>(
    params,
  );
};
