import { Project } from '../project.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findProjectsWithIds = async (
  dataBookService: DataBookService,
  oldIds: string[],
  newIds: number[],
): Promise<Project[]> => {
  const params: { functionName; args: any } = {
    functionName: 'findProjectsWithIds',
    args: {
      oldIds,
      newIds,
    },
  };
  return await dataBookService.newLambda<Project[]>(params);
};
