<pn-field-container
  [labelKey]="labelKey"
  [label]="label"
  [control]="control"
>
  <div class="clr-input-wrapper">
    <input
      type="text"
      class="clr-input"
      [formControl]="control"
    />
  </div>
</pn-field-container>
