import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const removeFeatureFromProductFromFeatureName = async (
  dataBookService: DataBookService,
  productId: number,
  featureName: string,
): Promise<Product> => {
  const params: { functionName: string; args: any } = {
    functionName: 'removeFeatureFromProductFromFeatureName',
    args: {
      productId,
      featureName,
    },
  };
  return await dataBookService.newLambda<Product>(params);
};
