export enum FeaturesEnum {
  databook = 'databook',
  dataExplorer = 'dataExplorer',
  dataAnalysis = 'dataAnalysis',
  eDoc = 'eDoc',
  materials = 'materials',
  tpiDigital = 'tpiDigital',
}

const featuresLabels = new Map<FeaturesEnum, string>([
  [FeaturesEnum.databook, 'Databook'],
  [FeaturesEnum.dataExplorer, 'Data Explorer'],
  [FeaturesEnum.dataAnalysis, 'Data Analysis'],
  [FeaturesEnum.eDoc, 'E-DOC'],
  [FeaturesEnum.materials, 'Materials'],
  [FeaturesEnum.tpiDigital, 'TPI Digital'],
]);

export const getFeatureLabel = (feature: FeaturesEnum) => {
  const str: string = featuresLabels.get(feature);
  return str;
};
