<vic-base-page page="statistics">
  <div class="header-actions container"></div>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>

    <div class="val-card clr-col-12">
      <div class="clr-row">
        <div class="clr-col-12 clr-col-xl-6 toolbar">
          <div class="item-toolbar">
            <label class="label-small">{{
              'plp.data.analysis.test.x' | translate
            }}</label>
            &nbsp;&nbsp;
            <select
              class="select-filter"
              [(ngModel)]="selectedTest"
              #keyTestX
              (change)="testeChange(keyTestX)"
            >
              <option
                *ngFor="let test of tests"
                [ngValue]="test.description"
              >
                <label class="label-small">{{
                  test.description | translate
                }}</label>
              </option>
            </select>
          </div>

          <div class="item-toolbar">
            <label class="label-small">{{
              'plp.data.analysis.property.x' | translate
            }}</label>
            &nbsp;&nbsp;
            <select
              class="select-filter"
              [(ngModel)]="selectedColumn"
              #keyPropX
              (change)="propertyChange(keyPropX)"
              style="width: 250px"
            >
              <option
                *ngFor="let prop of properties"
                [ngValue]="prop.name"
              >
                <label class="label-small">{{ prop.description }}</label>
              </option>
            </select>
          </div>
        </div>

        <div
          *ngIf="selectedColumn"
          class="clr-col-12 clr-col-xl-6 toolbar"
          style="margin-top: 0.4rem"
        >
          <div class="item-toolbar">
            <label
              class="label-small"
              style="margin-right: 0.6rem"
              >{{ 'databook.minimum' | translate }}</label
            >
            <input
              type="number"
              class="range-slider"
              (change)="generateGraphicsGtag()"
              [(ngModel)]="minimum"
            />
          </div>

          <div class="item-toolbar">
            <label
              class="label-small"
              style="margin-right: 0.6rem"
              >{{ 'databook.maximum' | translate }}</label
            >
            <input
              type="number"
              class="range-slider"
              (change)="generateGraphicsGtag()"
              [(ngModel)]="maximum"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectedColumn"
      class="clr-row clr-col-12 clr-justify-content-around"
    >
      <div
        class="clr-row clr-col-12 clr-col-md-9"
        style="overflow-y: hidden"
      >
        <div class="clr-col-3">
          <databook-filter>
            <div *ngFor="let filter of filters">
              <databook-filter-column
                (change)="generateGraphicsGtag()"
                [label]="filter.title.description"
                [columns]="filter.columns"
              >
              </databook-filter-column>
            </div>
          </databook-filter>
        </div>
        <div class="clr-col-9">
          <div class="val-card">
            <div *ngIf="!errorTest">
              <div class="clr-row clr-justify-content-end">
                <databook-info
                  [title]="
                    'databook.data.analysis.statistics.info.title' | translate
                  "
                  [body]="
                    'databook.data.analysis.statistics.info.description'
                      | translate
                  "
                  [position]="'right-top'"
                ></databook-info>
                <button
                  type="button"
                  class="btn btn-icon btn-icon-card"
                  aria-label="editing"
                  (click)="toggleShowTool()"
                >
                  <clr-icon shape="cog"></clr-icon>
                </button>
              </div>
              <div id="chart"></div>
            </div>
            <span
              *ngIf="errorTest"
              class="clr-subtext"
              >{{ errorTest }}</span
            >
          </div>
        </div>
      </div>

      <databook-tool
        *ngIf="showTool"
        class="clr-col-3"
        [settingsChart]="settings"
        (onChangeTools)="onChangeTools()"
      ></databook-tool>
      <div
        *ngIf="!showTool"
        class="clr-col-12 clr-col-md-3"
      >
        <div class="val-card clr-col-12">
          <label class="session-title">{{
            'plp.data.analysis.index.capacity' | translate
          }}</label>
          <div class="clr-row">
            <clr-datagrid class="val-datagrid">
              <clr-dg-column [style.width.px]="100">{{
                'plp.data.analysis.index' | translate
              }}</clr-dg-column>
              <clr-dg-column [style.width.px]="100">{{
                'plp.data.analysis.value' | translate
              }}</clr-dg-column>

              <clr-dg-row>
                <clr-dg-cell>{{
                  'databook.sample.size' | translate
                }}</clr-dg-cell>
                <clr-dg-cell>
                  <label *ngIf="sampleSize">{{
                    sampleSize | number: '1.0-0'
                  }}</label>
                  <label *ngIf="!sampleSize">{{
                    'databook.invalid.values' | translate
                  }}</label>
                </clr-dg-cell>
              </clr-dg-row>

              <clr-dg-row>
                <clr-dg-cell>{{ 'databook.mean' | translate }}</clr-dg-cell>
                <clr-dg-cell>
                  <label *ngIf="mean">{{ mean | number: '1.2-2' }}</label>
                  <label *ngIf="!mean">{{
                    'databook.invalid.values' | translate
                  }}</label>
                </clr-dg-cell>
              </clr-dg-row>

              <clr-dg-row>
                <clr-dg-cell>{{
                  'databook.deviation' | translate
                }}</clr-dg-cell>
                <clr-dg-cell>
                  <label *ngIf="deviation">{{
                    deviation | number: '1.2-2'
                  }}</label>
                  <label *ngIf="!deviation">{{
                    'databook.invalid.values' | translate
                  }}</label>
                </clr-dg-cell>
              </clr-dg-row>

              <clr-dg-row>
                <clr-dg-cell> CPK </clr-dg-cell>
                <clr-dg-cell>
                  <label *ngIf="hasLimitValues">{{
                    cpk | number: '1.2-2'
                  }}</label>
                  <label *ngIf="!hasLimitValues">{{
                    'databook.wait.limits' | translate
                  }}</label>
                </clr-dg-cell>
              </clr-dg-row>
              <!-- 
              <clr-dg-row>
                <clr-dg-cell> CPKC </clr-dg-cell>
                <clr-dg-cell>
                  <label *ngIf="hasLimitValues">{{cpk | number:'1.2-2'}}</label>
                  <label *ngIf="!hasLimitValues">{{'databook.wait.limits' | translate}}</label>
                </clr-dg-cell>
              </clr-dg-row> -->
            </clr-datagrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>
