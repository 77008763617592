<vic-base-page page="dataExplorer-chemical">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isChemicalVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Order')"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Mill_Item')"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Pipe_Heat')"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Specimen')"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Sample_Num')"
            >{{ 'data.book.sample.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Antimony_(Sb)')"
            >{{ 'data.book.antimony' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Arsenic_(As)')"
            >{{ 'data.book.arsenic' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Bismuth_(Bi)')"
            >{{ 'data.book.bismuth' | translate }}</clr-dg-column
          >

          <!-- AQUI -->
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Strontium_(Sr)')"
            >{{ 'data.book.strontium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Hydrogen_(H)')"
            >{{ 'data.book.hydrogen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Oxygen_(O)')"
            >{{ 'data.book.oxygen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Selenium_(Se)')"
            >{{ 'data.book.selenium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Tellurium_(Te)')"
            >{{ 'data.book.tellurium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Zinc_(Zn)')"
            >{{ 'data.book.zinc' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Zirconium_(Zr)')"
            >{{ 'data.book.zirconium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('Carbon_equivalent_2_(CE)')"
            >{{ 'data.book.carbon.equivalent.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('Carbon_equivalent_3_(CE)')"
            >{{ 'data.book.carbon.equivalent.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('Carbon_equivalent_4_(CE)')"
            >{{ 'data.book.carbon.equivalent.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('Carbon_equivalent_5_(CE)')"
            >{{ 'data.book.carbon.equivalent.5' | translate }}</clr-dg-column
          >
          <!-- ATE -->

          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Carbon_equivalent_(CE)')"
            >{{ 'data.book.carbon.equivalent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Carbon_(C)')"
            >{{ 'data.book.carbon' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Cobalt_(Co)')"
            >{{ 'data.book.cobalt' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Manganese_(Mn)')"
            >{{ 'data.book.manganese' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Magnesium_(Mg)')"
            >{{ 'data.book.magnesium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Silicon_(Si)')"
            >{{ 'data.book.silicon' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Aluminium_total_(Al)')"
            >{{ 'data.book.aluminium.total' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Phosporus_(P)')"
            >{{ 'data.book.phosporus' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Sulphur_(S)')"
            >{{ 'data.book.sulphur' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Lead_(Pb)')"
            >{{ 'data.book.lead' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Niobium_(Nb)')"
            >{{ 'data.book.niobium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Vanadium_(V)')"
            >{{ 'data.book.vanadium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Nickel_(Ni)')"
            >{{ 'data.book.nickel' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Chromium_(Cr)')"
            >{{ 'data.book.chromium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Copper_(Cu)')"
            >{{ 'data.book.copper' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Boron_(B)')"
            >{{ 'data.book.boron' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="105"
            *ngIf="shouldShowColumn('Molybdenum_(Mo)')"
            >{{ 'data.book.molybdenum' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Titanium_(Ti)')"
            >{{ 'data.book.titanium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Nitrogen_(N)')"
            >{{ 'data.book.nitrogen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Pcm_value')"
            >{{ 'data.book.pcm.value' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Calcium_(Ca)')"
            >{{ 'data.book.calcium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Nb+V+Ti')"
            >{{ 'data.book.nb.v.ti' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Al/N')"
            >{{ 'data.book.al.n' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Ca/s')"
            >{{ 'data.book.ca.s' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Cev')"
            >{{ 'data.book.cev' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Tantalum_(Ta)')"
            >{{ 'data.book.tantalum' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Tin_(Sn)')"
            >{{ 'data.book.tin' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Tungsten_(W)')"
            >{{ 'data.book.tungsten' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Standard')"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Qualification')"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
            >{{ 'data.book.manufacturers.pipe' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Order')">{{
              data['Mill_Order']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Specimen')">{{
              data['Specimen']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Sample_Num')">{{
              data['Sample_Num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Antimony_(Sb)')">{{
              data['Antimony_(Sb)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Arsenic_(As)')">{{
              data['Arsenic_(As)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Bismuth_(Bi)')">{{
              data['Bismuth_(Bi)']
            }}</clr-dg-cell>

            <!-- AQUI -->
            <clr-dg-cell *ngIf="shouldShowColumn('Strontium_(Sr)')">{{
              data['Strontium_(Sr)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Hydrogen_(H)')">{{
              data['Hydrogen_(H)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Oxygen_(O)')">{{
              data['Oxygen_(O)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Selenium_(Se)')">{{
              data['Selenium_(Se)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Tellurium_(Te)')">{{
              data['Tellurium_(Te)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Zinc_(Zn)')">{{
              data['Zinc_(Zn)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Zirconium_(Zr)')">{{
              data['Zirconium_(Zr)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Carbon_equivalent_2_(CE)')">{{
              data['Carbon_equivalent_2_(CE)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Carbon_equivalent_3_(CE)')">{{
              data['Carbon_equivalent_3_(CE)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Carbon_equivalent_4_(CE)')">{{
              data['Carbon_equivalent_4_(CE)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Carbon_equivalent_5_(CE)')">{{
              data['Carbon_equivalent_5_(CE)']
            }}</clr-dg-cell>
            <!-- ATE -->

            <clr-dg-cell *ngIf="shouldShowColumn('Carbon_equivalent_(CE)')">{{
              data['Carbon_equivalent_(CE)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Carbon_(C)')">{{
              data['Carbon_(C)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cobalt_(Co)')">{{
              data['Cobalt_(Co)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manganese_(Mn)')">{{
              data['Manganese_(Mn)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Magnesium_(Mg)')">{{
              data['Magnesium_(Mg)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Silicon_(Si)')">{{
              data['Silicon_(Si)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Aluminium_total_(Al)')">{{
              data['Aluminium_total_(Al)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Phosporus_(P)')">{{
              data['Phosporus_(P)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Sulphur_(S)')">{{
              data['Sulphur_(S)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lead_(Pb)')">{{
              data['Lead_(Pb)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Niobium_(Nb)')">{{
              data['Niobium_(Nb)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Vanadium_(V)')">{{
              data['Vanadium_(V)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Nickel_(Ni)')">{{
              data['Nickel_(Ni)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Chromium_(Cr)')">{{
              data['Chromium_(Cr)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Copper_(Cu)')">{{
              data['Copper_(Cu)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Boron_(B)')">{{
              data['Boron_(B)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Molybdenum_(Mo)')">{{
              data['Molybdenum_(Mo)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Titanium_(Ti)')">{{
              data['Titanium_(Ti)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Nitrogen_(N)')">{{
              data['Nitrogen_(N)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pcm_value')">{{
              data['Pcm_value']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Calcium_(Ca)')">{{
              data['Calcium_(Ca)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Nb+V+Ti')">{{
              data['Nb+V+Ti']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Al/N')">{{
              data['Al/N']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ca/s')">{{
              data['Ca/s']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cev')">{{
              data['Cev']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Tantalum_(Ta)')">{{
              data['Tantalum_(Ta)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Tin_(Sn)')">{{
              data['Tin_(Sn)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Tungsten_(W)')">{{
              data['Tungsten_(W)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Standard')">{{
              data['Standard']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Qualification')">{{
              data['Qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
