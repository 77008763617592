import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '@components/pdf/pdf.service';
import { CoreService } from '@core/core.service';
import { PROJECT_STORE, ProjectsStore } from '@mobx-stores/projects.store';
import { TREEVIEW_STORE, TreeviewStore } from '@mobx-stores/treeview.store';
import { USER_STORE, UserStore } from '@mobx-stores/user.store';
import { DataBookService } from '@services/databook.service';
import { DataBookState } from '@services/databook.state';
import { DataBookBaseComponent } from '../../databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle, NgIf } from '@angular/common';

@Component({
  selector: 'treeview-options',
  templateUrl: './treeview-options.component.html',
  styleUrls: ['./treeview-options.component.scss'],
  standalone: true,
  imports: [NgStyle, NgIf, TranslateModule],
})
export class TreeviewOptionsComponent
  extends DataBookBaseComponent
  implements OnChanges
{
  // @Input()
  // public treeviewStore: TreeviewStore;

  @Input()
  public canDownload: boolean;

  @Input()
  public canUpload: boolean;

  @Input()
  public canRename: boolean;

  @Input()
  public canRemove: boolean;

  @Output() deleteItem = new EventEmitter();

  @ViewChild('pipeNavigatorContextMenu', { static: true })
  pipeNavigatorContextMenu: ElementRef;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public httpClient: HttpClient,
    public pdfService: PdfService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.treeviewStore.contextMenuDomElement) {
      this.treeviewStore.contextMenuDomElement = this.pipeNavigatorContextMenu;
    }
  }
}
