<div class="clr-row-col12 breadcrumb-container">
  <div
    class="breadcrumb-item"
    *ngFor="let crumb of breadcrumb"
  >
    <label
      *ngIf="crumb.link === undefined"
      class="current-breadcrumb-label"
      >{{ breadcrumbCurrent }}</label
    >

    <label
      *ngIf="crumb.link !== undefined"
      class="breadcrumb-tip"
      >{{ crumb.type }}</label
    >
    <div
      *ngIf="crumb.link !== undefined"
      class="breadcrumb-link"
      (click)="redirect(crumb.link)"
    >
      <label class="breadcrumb-label">{{ crumb.name }}</label>
      <img
        *ngIf="crumb.hasArrow"
        class="breadcrumb-arrow"
        src="assets/img/svg/breadcrumbs-arrow.svg"
      />
    </div>
  </div>
</div>
