import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'LOT IDENTIFICATION',
  headerClass: 'custom-header-pinned',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      children: [
        {
          field: 'databook.dataexplorer.column.title.heat',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['heat']],
        },
        {
          field: 'databook.dataexplorer.column.title.htl',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['htl']],
        },
      ],
    },
  ],
};
