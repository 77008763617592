import { Directive, OnInit, OnDestroy } from '@angular/core';
import { CoreService } from '../core.service';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
  protected logEvents = {
    PAGE_IN: 'Page In',
    PAGE_OUT: 'Page Out',
    APPLY_SEARCH: 'Apply Search',
    CLEAR_FILTER: 'Clear Filter',
    APPLY_FILTER: 'Apply Filter',
    EXCEL_EXPORT: 'Excel Export',
    PDF_EXPORT: 'PDF Export',
    SAVE: 'Save',
    UPDATE: 'Update',
    NEW: 'New',
    EDIT: 'Edit',
    DELETE: 'Delete',
    DOWNLOAD: 'Download',
  };

  currentUser: any = {};
  applicationSettings: Array<any> = [];
  environment: any = {};

  constructor(protected coreService: CoreService) {
    const config = this.coreService.getConfig();
    this.currentUser = config.currentUser;
    this.applicationSettings = config.applicationSettings;
    this.environment = config.environment;
  }

  async ngOnInit() {
    this.onPageInit();
  }

  async onPageInit() {}

  async ngOnDestroy() {
    // this.registryLog(this.logEvents.PAGE_OUT);
    this.coreService.hideLoading();
    this.onPageDestroy();
  }

  async onPageDestroy() {}

  registryLog(message: string) {
    let currentFunctionality = this.coreService.getRouter().url;
    if (currentFunctionality.indexOf('?') !== -1) {
      currentFunctionality = currentFunctionality.substring(
        0,
        currentFunctionality.indexOf('?'),
      );
    }
    this.coreService.registryMessageLog(currentFunctionality, message);
  }
}
