<vic-base-page page="material-mgmt">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="onNewClick()"
      *ngIf="canEdit()"
    >
      <clr-icon shape="plus"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.new' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container">
    <div *ngIf="!isScopSupplyVisible()">{{ unauthorized() }}</div>
    <databook-breadcrumb> </databook-breadcrumb>

    <div class="val-card clr-col-12">
      <div
        class="title-container"
        *ngIf="canEdit()"
      >
        <label class="session-title">{{
          'databook.material.your.selection' | translate
        }}</label>

        <div style="display: flex; align-content: flex-end">
          <clr-toggle-container style="margin-top: 0.75rem">
            <clr-toggle-wrapper>
              <input
                type="checkbox"
                clrToggle
                [(ngModel)]="oldDataExplorer"
                (change)="updateOldDataExplorer()"
              />
              <label> {{ 'got.to.old.data.explorer' | translate }}</label>
            </clr-toggle-wrapper>
          </clr-toggle-container>
        </div>
      </div>

      <div class="clr-row">
        <clr-datagrid class="val-datagrid">
          <clr-dg-column [style.width.px]="200">{{
            'scope.type' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="350">{{
            'scope.description' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="200">{{
            'scope.service' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="200">{{
            'scope.od.nominal' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="200">{{
            'scope.wt.nominal' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="200">{{
            'scope.grade' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="200">{{
            'scope.end_finishing' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="200">{{
            'scope.quantity' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="50">{{
            'scope.unit' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="80">{{
            'scope.mill.order' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="140">{{
            'scope.mill.item.order' | translate
          }}</clr-dg-column>

          <clr-dg-column [style.width.px]="180">{{
            'scope.customer.order' | translate
          }}</clr-dg-column>
          <clr-dg-column [style.width.px]="240">{{
            'scope.customer.item.order' | translate
          }}</clr-dg-column>

          <clr-dg-row
            *clrDgItems="let data of items"
            [clrDgItem]="data"
          >
            <clr-dg-action-overflow *ngIf="canEdit()">
              <button
                class="action-item"
                (click)="onEditClick(data)"
              >
                {{ 'application.commons.buttons.edit' | translate }}
              </button>

              <button
                class="action-item"
                (click)="onDeleteClickConfirm(data)"
              >
                {{ 'application.commons.buttons.remove' | translate }}
              </button>
            </clr-dg-action-overflow>

            <clr-dg-cell>{{ data.material_type }}</clr-dg-cell>
            <clr-dg-cell>{{ data.description }}</clr-dg-cell>
            <clr-dg-cell>{{ data.service ? 'Sour' : 'No sour' }}</clr-dg-cell>
            <clr-dg-cell>{{ data.od }}</clr-dg-cell>
            <clr-dg-cell>{{ data.wt }}</clr-dg-cell>
            <clr-dg-cell>{{ data.grade }}</clr-dg-cell>
            <clr-dg-cell>{{ data.end_finishing }}</clr-dg-cell>
            <clr-dg-cell>{{ data.quantity }}</clr-dg-cell>
            <clr-dg-cell>{{ data.unit | translate }}</clr-dg-cell>
            <clr-dg-cell>{{ data.material_order }}</clr-dg-cell>
            <clr-dg-cell>{{ data.ordered_item }}</clr-dg-cell>
            <clr-dg-cell>{{ data.customer_order }}</clr-dg-cell>
            <clr-dg-cell>{{ data.customer_order_item }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="true"
  [clrModalSize]="'lg'"
  [clrModalClosable]="false"
  *ngIf="action !== 'none'"
  [clrModalStaticBackdrop]="true"
>
  <h3
    class="modal-title"
    *ngIf="action === 'new'"
  >
    {{ 'material.new.material' | translate }}
  </h3>
  <h3
    class="modal-title"
    *ngIf="action === 'edit'"
  >
    {{ 'application.commons.text.edit' | translate }}
  </h3>
  <div class="modal-body">
    <form
      clrForm
      class="vic-form-group"
      [formGroup]="scopeForm"
      (ngSubmit)="onSaveClick()"
    >
      <clr-select-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.type' | translate
        }}</label>
        <select
          class="clr-col-12 clr-col-md-9"
          clrSelect
          name="type"
          formControlName="type"
        >
          <option value="Production">
            {{ 'scope.type.production' | translate }}
          </option>
          <option value="WAG">{{ 'scope.type.wag' | translate }}</option>
          <option value="PLP">
            {{ 'scope.type.dataExplorer' | translate }}
          </option>
          <option value="Liner">{{ 'scope.type.liner' | translate }}</option>
          <option value="Coil">{{ 'scope.type.coil' | translate }}</option>
          <option value="MPB">{{ 'scope.type.MPB' | translate }}</option>
          <option value="Test Pipe">
            {{ 'scope.type.TestPipe' | translate }}
          </option>
          <option value="Riser">Riser</option>
          <option value="Flow Size">Flow Size</option>
          <option value="10 a">10 a</option>
          <option value="10 b">10 b</option>
          <option value="20 a">20 a</option>
          <option value="20 b">20 b</option>
          <option value="30 a">30 a</option>
          <option value="30 b">30 b</option>
          <option value="40 a">40 a</option>
        </select>

        <clr-control-error>
          <span *ngIf="type.invalid && type.touched">
            {{ 'scope.type.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-select-container>

      <clr-input-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.description' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="description"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="description.invalid && description.touched">
            {{ 'scope.description.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-radio-container id="radioId">
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.service' | translate
        }}</label>

        <clr-radio-wrapper id="idRadio1">
          <input
            type="radio"
            clrRadio
            name="service"
            required
            value="true"
            formControlName="service"
          />
          <label>Sour</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper id="idRadio2">
          <input
            type="radio"
            clrRadio
            name="service"
            required
            value="false"
            formControlName="service"
          />
          <label>No sour</label>
        </clr-radio-wrapper>
        <clr-control-error>
          <span *ngIf="service.invalid && service.touched">
            {{ 'scope.service.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-radio-container>

      <clr-input-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.od.nominal' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="od"
          type="number"
          maxlength="10"
        />
        <clr-control-error>
          <span *ngIf="od.invalid && od.touched">
            {{ 'scope.od.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.wt.nominal' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="wt"
          type="number"
          maxlength="10"
        />
        <clr-control-error>
          <span *ngIf="wt.invalid && wt.touched">
            {{ 'scope.wt.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.grade' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="grade"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="grade.invalid && grade.touched">
            {{ 'scope.grade.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.end_finishing' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="endFinishing"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="endFinishing.invalid && endFinishing.touched">
            {{ 'scope.end_finishing.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.quantity' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="quantity"
          type="number"
          maxlength="10"
        />
        <clr-control-error>
          <span *ngIf="quantity.invalid && quantity.touched">
            {{ 'scope.quantity.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-select-container>
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.unit' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="quantity"
          type="number"
          maxlength="10"
        />

        <select
          clrSelect
          name="unit"
          formControlName="unit"
        >
          <option value="meter">{{ 'scope.meter' | translate }}</option>
          <option value="ton">{{ 'scope.ton' | translate }}</option>
          <option value="fts">{{ 'scope.foot' | translate }}</option>
        </select>

        <clr-control-error>
          <span *ngIf="unit.invalid && unit.touched">
            {{ 'scope.unit.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-select-container>

      <clr-input-container style="height: 25px">
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.mill.order' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="order"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="order.invalid && order.touched">
            {{ 'scope.order.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container style="height: 25px">
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.mill.item.order' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="itemOrder"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="itemOrder.invalid && itemOrder.touched">
            {{ 'scope.item.order.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container style="height: 25px">
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.customer.order' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="customerOrder"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="customerOrder.invalid && customerOrder.touched">
            {{ 'scope.customer.order.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container style="height: 25px">
        <label class="clr-col-12 clr-col-md-3">{{
          'scope.customer.item.order' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-9"
          formControlName="customerItemOrder"
          type="text"
          maxlength="200"
        />
        <clr-control-error>
          <span *ngIf="customerItemOrder.invalid && customerItemOrder.touched">
            {{ 'scope.customer.item.order.invalid' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>

      <clr-input-container
        style="height: 25px"
        *ngIf="id.value"
      >
        <label class="clr-col-12 clr-col-md-3">{{
          'databook.last.modified.by' | translate
        }}</label>
        <input
          [readonly]="true"
          clrInput
          [disabled]="true"
          class="clr-col-12 clr-col-md-9"
          formControlName="lastUser"
          type="text"
          maxlength="200"
        />
      </clr-input-container>

      <clr-input-container
        style="height: 25px"
        *ngIf="id.value"
      >
        <label class="clr-col-12 clr-col-md-3">{{
          'databook.last.modification.date' | translate
        }}</label>
        <input
          [readonly]="true"
          clrInput
          [disabled]="true"
          class="clr-col-12 clr-col-md-9"
          formControlName="lastDate"
          type="text"
          maxlength="200"
        />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="onCancelClick()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSaveClick()"
      [disabled]="!scopeForm.valid"
    >
      {{ 'application.commons.buttons.save' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionConfirmRemove !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ 'confirm.exclusion' | translate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelRemove()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      (click)="onDeleteClick()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>
