import { TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const addFolderIntoFolder = async (
  dataBookService: DataBookService,
  parentFolderId: number,
  name: string,
  phase?: boolean,
  fixed?: boolean,
  description?: string,
  oldDynamoId?: string,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'addFolderIntoFolder',
    args: {
      parentFolderId,
      name,
      phase,
      fixed,
      description,
      oldDynamoId,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};
