export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '--';

  const k = 1024;
  const fractionDigits = decimals < 0 ? 0 : decimals;
  const sizeNames = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const value = parseFloat(
    (bytes / Math.pow(k, sizeIndex)).toFixed(fractionDigits).replace('.', ','),
  );

  return `${value} ${sizeNames[sizeIndex]}`;
}

export function formatBytesInMb(value: number): string {
  return `${(value / 1024 / 1024).toFixed(2).replace('.', ',')} Mb`;
}