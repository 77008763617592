<clr-modal
  [clrModalOpen]="isModalOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ getEmailMessage() }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelAddEmail()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="confirmEmail()"
      *ngIf="type.create"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      id="idConfirmEmailNotInserted"
      (click)="confirmEditEmail()"
      *ngIf="type.update"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>
