import { TreeviewFileItem } from '../treeview-item.class';
import { DataBookService } from '../../services/databook.service';

export const updateFilePosition = async (
  dataBookService: DataBookService,
  folder_id,
  id,
  position,
  down,
): Promise<any | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'updateFilePosition',
    args: {
      folder_id,
      id,
      position,
      down,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};
