import { twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'Pup Pieces',
  headerClass: 'custom-header-2',
  cellClass: 'customCellDataExplorer',
  children: [
    {
      field: 'Length',
      headerName: 'Length',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber('', params.data[COLUMNS['laser_pup_pieces_length']]),
      valueGetter: (params) => params.data[COLUMNS['laser_pup_pieces_length']],
    },
    {
      field: 'File Name',
      headerName: 'File Name',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_file_name']],
      width: 150,
    },
    {
      field: 'Roughness - End A',
      headerName: 'Roughness - End A',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[COLUMNS['laser_pup_pieces_roughness'] + ' - End A'],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_roughness'] + ' - End A'],
    },
    {
      field: 'Roughness - End b',
      headerName: 'Roughness - End b',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[COLUMNS['laser_pup_pieces_roughness'] + ' - End b'],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_roughness'] + ' - End b'],
    },
    {
      field: 'Squareness - End A',
      headerName: 'Squareness - End A',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[COLUMNS['laser_pup_pieces_squareness'] + ' - End A'],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_squareness'] + ' - End A'],
    },
    {
      field: 'Squareness - End b',
      headerName: 'Squareness - End b',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[COLUMNS['laser_pup_pieces_squareness'] + ' - End b'],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_squareness'] + ' - End b'],
    },
    {
      field: 'ID - End A - Section 1',
      headerName: 'ID - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - End A - Section 1'],
    },
    {
      field: 'ID - End A - Section 2',
      headerName: 'ID - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - End A - Section 2'],
    },
    {
      field: 'ID - End B - Section 1',
      headerName: 'ID - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - End B - Section 1'],
    },
    {
      field: 'ID - End B - Section 2',
      headerName: 'ID - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - End B - Section 2'],
    },
    {
      field: 'END - End A - Section 1',
      headerName: 'END - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ END - End A - Section 1'],
      width: 150,
    },
    {
      field: 'END - End A - Section 2',
      headerName: 'END - End A - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ END - End A - Section 2'],
      width: 150,
    },
    {
      field: 'END - End B - Section 1',
      headerName: 'END - End B - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ END - End B - Section 1'],
      width: 150,
    },
    {
      field: 'END - End B - Section 2',
      headerName: 'END - End B - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ END - End B - Section 2'],
      width: 150,
    },
    {
      field: 'SCT - End A - Section 1',
      headerName: 'SCT - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ SCT - End A - Section 1'],
      width: 150,
    },
    {
      field: 'SCT - End A - Section 2',
      headerName: 'SCT - End A - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ SCT - End A - Section 2'],
      width: 150,
    },
    {
      field: 'SCT - End B - Section 1',
      headerName: 'SCT - End B - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ SCT - End B - Section 1'],
      width: 150,
    },
    {
      field: 'SCT - End B - Section 2',
      headerName: 'SCT - End B - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ SCT - End B - Section 2'],
      width: 150,
    },
    {
      field: 'Visual Status',
      headerName: 'Visual Inspection Status',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Visual Inspection'],
      width: 150,
    },
    {
      field: 'Grade - End A - Section 1',
      headerName: 'Grade - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Grade - End A - Section 1'],
      width: 150,
    },
    {
      field: 'Grade - End A - Section 2',
      headerName: 'Grade - End A - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Grade - End A - Section 2'],
      width: 150,
    },
    {
      field: 'Grade - End B - Section 1',
      headerName: 'Grade - End B - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Grade - End B - Section 1'],
      width: 150,
    },
    {
      field: 'Grade - End B - Section 2',
      headerName: 'Grade - End B - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Grade - End B - Section 2'],
      width: 150,
    },
    {
      field: 'ID - M1 - End A - Section 1',
      headerName: 'ID - M1 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M1 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M1 - End A - Section 1'],
    },
    {
      field: 'ID - M1 - End A - Section 2',
      headerName: 'ID - M1 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M1 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M1 - End A - Section 2'],
    },
    {
      field: 'ID - M1 - End B - Section 1',
      headerName: 'ID - M1 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M1 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M1 - End B - Section 1'],
    },
    {
      field: 'ID - M1 - End B - Section 2',
      headerName: 'ID - M1 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M1 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M1 - End B - Section 2'],
    },
    {
      field: 'ID - M2 - End A - Section 1',
      headerName: 'ID - M2 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M2 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M2 - End A - Section 1'],
    },
    {
      field: 'ID - M2 - End A - Section 2',
      headerName: 'ID - M2 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M2 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M2 - End A - Section 2'],
    },
    {
      field: 'ID - M2 - End B - Section 1',
      headerName: 'ID - M2 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M2 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M2 - End B - Section 1'],
    },
    {
      field: 'ID - M2 - End B - Section 2',
      headerName: 'ID - M2 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M2 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M2 - End B - Section 2'],
    },
    {
      field: 'ID - M3 - End A - Section 1',
      headerName: 'ID - M3 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M3 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M3 - End A - Section 1'],
    },
    {
      field: 'ID - M3 - End A - Section 2',
      headerName: 'ID - M3 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M3 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M3 - End A - Section 2'],
    },

    {
      field: 'ID - M3 - End B - Section 1',
      headerName: 'ID - M3 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M3 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M3 - End B - Section 1'],
    },
    {
      field: 'ID - M3 - End B - Section 2',
      headerName: 'ID - M3 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M3 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M3 - End B - Section 2'],
    },
    {
      field: 'ID - M4 - End A - Section 1',
      headerName: 'ID - M4 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M4 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M4 - End A - Section 1'],
    },
    {
      field: 'ID - M4 - End A - Section 2',
      headerName: 'ID - M4 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M4 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M4 - End A - Section 2'],
    },
    {
      field: 'ID - M4 - End B - Section 1',
      headerName: 'ID - M4 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M4 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M4 - End B - Section 1'],
    },
    {
      field: 'ID - M4 - End B - Section 2',
      headerName: 'ID - M4 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - M4 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - M4 - End B - Section 2'],
    },
    {
      field: 'ID - M5 - End A - Section 1',
      headerName: 'ID - M5 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m5'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m5'] + ' - End A - Section 1'],
    },
    {
      field: 'ID - M5 - End A - Section 2',
      headerName: 'ID - M5 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m5'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m5'] + ' - End A - Section 2'],
    },
    {
      field: 'ID - M5 - End B - Section 1',
      headerName: 'ID - M5 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m5'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m5'] + ' - End B - Section 1'],
    },
    {
      field: 'ID - M5 - End B - Section 2',
      headerName: 'ID - M5 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m5'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m5'] + ' - End B - Section 2'],
    },
    {
      field: 'ID - M6 - End A - Section 1',
      headerName: 'ID - M6 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m6'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m6'] + ' - End A - Section 1'],
    },
    {
      field: 'ID - M6 - End A - Section 2',
      headerName: 'ID - M6 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m6'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m6'] + ' - End A - Section 2'],
    },
    {
      field: 'ID - M6 - End B - Section 1',
      headerName: 'ID - M6 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m6'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m6'] + ' - End B - Section 1'],
    },
    {
      field: 'ID - M6 - End B - Section 2',
      headerName: 'ID - M6 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m6'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m6'] + ' - End B - Section 2'],
    },
    {
      field: 'ID - M7 - End A - Section 1',
      headerName: 'ID - M7 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m7'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m7'] + ' - End A - Section 1'],
    },
    {
      field: 'ID - M7 - End A - Section 2',
      headerName: 'ID - M7 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m7'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m7'] + ' - End A - Section 2'],
    },
    {
      field: 'ID - M7 - End B - Section 1',
      headerName: 'ID - M7 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m7'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m7'] + ' - End B - Section 1'],
    },
    {
      field: 'ID - M7 - End B - Section 2',
      headerName: 'ID - M7 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m7'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m7'] + ' - End B - Section 2'],
    },
    {
      field: 'ID - M8 - End A - Section 1',
      headerName: 'ID - M8 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m8'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m8'] + ' - End A - Section 1'],
    },
    {
      field: 'ID - M8 - End A - Section 2',
      headerName: 'ID - M8 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m8'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m8'] + ' - End A - Section 2'],
    },
    {
      field: 'ID - M8 - End B - Section 1',
      headerName: 'ID - M8 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m8'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m8'] + ' - End B - Section 1'],
    },
    {
      field: 'ID - M8 - End B - Section 2',
      headerName: 'ID - M8 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_id_m8'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_id_m8'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M1 - End A - Section 1',
      headerName: 'OD - M1 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m1'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m1'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M1 - End A - Section 2',
      headerName: 'OD - M1 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m1'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m1'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M1 - End B - Section 1',
      headerName: 'OD - M1 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m1'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m1'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M1 - End B - Section 2',
      headerName: 'OD - M1 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m1'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m1'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M2 - End A - Section 1',
      headerName: 'OD - M2 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m2'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m2'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M2 - End A - Section 2',
      headerName: 'OD - M2 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m2'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m2'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M2 - End B - Section 1',
      headerName: 'OD - M2 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m2'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m2'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M2 - End B - Section 2',
      headerName: 'OD - M2 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m2'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m2'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M3 - End A - Section 1',
      headerName: 'OD - M3 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m3'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m3'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M3 - End A - Section 2',
      headerName: 'OD - M3 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m3'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m3'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M3 - End B - Section 1',
      headerName: 'OD - M3 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m3'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m3'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M3 - End B - Section 2',
      headerName: 'OD - M3 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m3'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m3'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M4 - End A - Section 1',
      headerName: 'OD - M4 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m4'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m4'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M4 - End A - Section 2',
      headerName: 'OD - M4 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m4'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m4'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M4 - End B - Section 1',
      headerName: 'OD - M4 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m4'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m4'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M4 - End B - Section 2',
      headerName: 'OD - M4 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m4'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m4'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M5 - End A - Section 1',
      headerName: 'OD - M5 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m5'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m5'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M5 - End A - Section 2',
      headerName: 'OD - M5 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m5'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m5'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M5 - End B - Section 1',
      headerName: 'OD - M5 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m5'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m5'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M5 - End B - Section 2',
      headerName: 'OD - M5 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m5'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m5'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M6 - End A - Section 1',
      headerName: 'OD - M6 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m6'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m6'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M6 - End A - Section 2',
      headerName: 'OD - M6 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m6'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m6'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M6 - End B - Section 1',
      headerName: 'OD - M6 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m6'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m6'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M6 - End B - Section 2',
      headerName: 'OD - M6 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m6'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m6'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M7 - End A - Section 1',
      headerName: 'OD - M7 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m7'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m7'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M7 - End A - Section 2',
      headerName: 'OD - M7 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m7'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m7'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M7 - End B - Section 1',
      headerName: 'OD - M7 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m7'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m7'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M7 - End B - Section 2',
      headerName: 'OD - M7 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m7'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m7'] + ' - End B - Section 2'],
    },
    {
      field: 'OD - M8 - End A - Section 1',
      headerName: 'OD - M8 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m8'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m8'] + ' - End A - Section 1'],
    },
    {
      field: 'OD - M8 - End A - Section 2',
      headerName: 'OD - M8 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m8'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m8'] + ' - End A - Section 2'],
    },
    {
      field: 'OD - M8 - End B - Section 1',
      headerName: 'OD - M8 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m8'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m8'] + ' - End B - Section 1'],
    },
    {
      field: 'OD - M8 - End B - Section 2',
      headerName: 'OD - M8 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_od_m8'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_od_m8'] + ' - End B - Section 2'],
    },
    {
      field: 'WT - M1 - End A - Section 1',
      headerName: 'WT - M1 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m1'] + ' - End A - Section 1'],
    },
    {
      field: 'WT - M1 - End A - Section 2',
      headerName: 'WT - M1 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m1'] + ' - End A - Section 2'],
    },
    {
      field: 'WT - M1 - End B - Section 1',
      headerName: 'WT - M1 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m1'] + ' - End B - Section 1'],
    },
    {
      field: 'WT - M1 - End B - Section 2',
      headerName: 'WT - M1 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m1'] + ' - End B - Section 2'],
    },
    {
      field: 'WT - M2 - End A - Section 1',
      headerName: 'WT - M2 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m2'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m2'] + ' - End A - Section 1'],
    },
    {
      field: 'WT - M2 - End A - Section 2',
      headerName: 'WT - M2 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m2'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m2'] + ' - End A - Section 2'],
    },
    {
      field: 'WT - M2 - End B - Section 1',
      headerName: 'WT - M2 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m2'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m2'] + ' - End B - Section 1'],
    },
    {
      field: 'WT - M2 - End B - Section 2',
      headerName: 'WT - M2 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m2'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m2'] + ' - End B - Section 2'],
    },
    {
      field: 'WT - M3 - End A - Section 1',
      headerName: 'WT - M3 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m3'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m3'] + ' - End A - Section 1'],
    },
    {
      field: 'WT - M3 - End A - Section 2',
      headerName: 'WT - M3 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m3'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m3'] + ' - End A - Section 2'],
    },
    {
      field: 'WT - M3 - End B - Section 1',
      headerName: 'WT - M3 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m3'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m3'] + ' - End B - Section 1'],
    },
    {
      field: 'WT - M3 - End B - Section 2',
      headerName: 'WT - M3 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m3'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m3'] + ' - End B - Section 2'],
    },
    {
      field: 'WT - M4 - End A - Section 1',
      headerName: 'WT - M4 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m4'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m4'] + ' - End A - Section 1'],
    },
    {
      field: 'WT - M4 - End A - Section 2',
      headerName: 'WT - M4 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m4'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m4'] + ' - End A - Section 2'],
    },
    {
      field: 'WT - M4 - End B - Section 1',
      headerName: 'WT - M4 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m4'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m4'] + ' - End B - Section 1'],
    },
    {
      field: 'WT - M4 - End B - Section 2',
      headerName: 'WT - M4 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m4'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m4'] + ' - End B - Section 2'],
    },
    {
      field: 'WT - M5 - End A - Section 1',
      headerName: 'WT - M5 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m5'] + ' - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m5'] + ' - End A - Section 1'],
    },
    {
      field: 'WT - M5 - End A - Section 2',
      headerName: 'WT - M5 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m5'] + ' - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m5'] + ' - End A - Section 2'],
    },
    {
      field: 'WT - M5 - End B - Section 1',
      headerName: 'WT - M5 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m5'] + ' - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m5'] + ' - End B - Section 1'],
    },
    {
      field: 'WT - M5 - End B - Section 2',
      headerName: 'WT - M5 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m5'] + ' - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[COLUMNS['laser_pup_pieces_wt_m5'] + ' - End B - Section 2'],
    },
    {
      field: 'WT - M6 - End A - Section 1',
      headerName: 'WT - M6 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M6 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M6 - End A - Section 1'],
    },
    {
      field: 'WT - M6 - End A - Section 2',
      headerName: 'WT - M6 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M6 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M6 - End A - Section 2'],
    },
    {
      field: 'WT - M6 - End B - Section 1',
      headerName: 'WT - M6 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M6 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M6 - End B - Section 1'],
    },
    {
      field: 'WT - M6 - End B - Section 2',
      headerName: 'WT - M6 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M6 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M6 - End B - Section 2'],
    },
    {
      field: 'WT - M7 - End A - Section 1',
      headerName: 'WT - M7 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M7 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M7 - End A - Section 1'],
    },
    {
      field: 'WT - M7 - End A - Section 2',
      headerName: 'WT - M7 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M7 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M7 - End A - Section 2'],
    },
    {
      field: 'WT - M7 - End B - Section 1',
      headerName: 'WT - M7 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M7 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M7 - End B - Section 1'],
    },
    {
      field: 'WT - M7 - End B - Section 2',
      headerName: 'WT - M7 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M7 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M7 - End B - Section 2'],
    },
    {
      field: 'WT - M8 - End A - Section 1',
      headerName: 'WT - M8 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M8 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M8 - End A - Section 1'],
    },
    {
      field: 'WT - M8 - End A - Section 2',
      headerName: 'WT - M8 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M8 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M8 - End A - Section 2'],
    },
    {
      field: 'WT - M8 - End B - Section 1',
      headerName: 'WT - M8 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M8 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M8 - End B - Section 1'],
    },
    {
      field: 'WT - M8 - End B - Section 2',
      headerName: 'WT - M8 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M8 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M8 - End B - Section 2'],
    },
    {
      field: 'WT - M9 - End A - Section 1',
      headerName: 'WT - M9 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M9 - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M9 - End A - Section 1'],
    },
    {
      field: 'WT - M9 - End A - Section 2',
      headerName: 'WT - M9 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M9 - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M9 - End A - Section 2'],
    },
    {
      field: 'WT - M9 - End B - Section 1',
      headerName: 'WT - M9 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M9 - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M9 - End B - Section 1'],
    },
    {
      field: 'WT - M9 - End B - Section 2',
      headerName: 'WT - M9 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - M9 - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - M9 - End B - Section 2'],
    },
    {
      field: 'WT - Avg - End A - Section 1',
      headerName: 'WT - Avg - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Avg - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Avg - End A - Section 1'],
    },
    {
      field: 'WT - Avg - End A - Section 2',
      headerName: 'WT - Avg - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Avg - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Avg - End A - Section 2'],
    },
    {
      field: 'WT - Avg - End B - Section 1',
      headerName: 'WT - Avg - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Avg - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Avg - End B - Section 1'],
    },
    {
      field: 'WT - Avg - End B - Section 2',
      headerName: 'WT - Avg - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Avg - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Avg - End B - Section 2'],
    },
    {
      field: 'WT - ECC - End A - Section 1',
      headerName: 'WT - ECC - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - ECC - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - ECC - End A - Section 1'],
    },
    {
      field: 'WT - ECC - End A - Section 2',
      headerName: 'WT - ECC - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - ECC - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - ECC - End A - Section 2'],
    },
    {
      field: 'WT - ECC - End B - Section 1',
      headerName: 'WT - ECC - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - ECC - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - ECC - End B - Section 1'],
    },
    {
      field: 'WT - ECC - End B - Section 2',
      headerName: 'WT - ECC - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - ECC - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - ECC - End B - Section 2'],
    },
    {
      field: 'WT - M10 - End A - Section 1',
      headerName: 'WT - M10 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M10 - End A - Section 2',
      headerName: 'WT - M10 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M10 - End B - Section 1',
      headerName: 'WT - M10 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End B - Section 1'
        ],
    },
    {
      field: 'WT - M10 - End B - Section 2',
      headerName: 'WT - M10 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '0 - End B - Section 2'
        ],
    },
    {
      field: 'WT - M11 - End A - Section 1',
      headerName: 'WT - M11 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M11 - End A - Section 2',
      headerName: 'WT - M11 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M11 - End B - Section 1',
      headerName: 'WT - M11 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End B - Section 1'
        ],
    },

    {
      field: 'WT - M11 - End B - Section 2',
      headerName: 'WT - M11 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '1 - End B - Section 2'
        ],
    },
    {
      field: 'WT - M12 - End A - Section 1',
      headerName: 'WT - M12 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M12 - End A - Section 2',
      headerName: 'WT - M12 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M12 - End B - Section 1',
      headerName: 'WT - M12 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End B - Section 1'
        ],
    },
    {
      field: 'WT - M12 - End B - Section 2',
      headerName: 'WT - M12 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '2 - End B - Section 2'
        ],
    },
    {
      field: 'WT - M13 - End A - Section 1',
      headerName: 'WT - M13 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M13 - End A - Section 2',
      headerName: 'WT - M13 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M13 - End B - Section 1',
      headerName: 'WT - M13 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End B - Section 1'
        ],
    },
    {
      field: 'WT - M13 - End B - Section 2',
      headerName: 'WT - M13 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '3 - End B - Section 2'
        ],
    },
    {
      field: 'WT - M14 - End A - Section 1',
      headerName: 'WT - M14 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M14 - End A - Section 2',
      headerName: 'WT - M14 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M14 - End B - Section 1',
      headerName: 'WT - M14 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End B - Section 1'
        ],
    },
    {
      field: 'WT - M14 - End B - Section 2',
      headerName: 'WT - M14 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '4 - End B - Section 2'
        ],
    },
    {
      field: 'WT - M15 - End A - Section 1',
      headerName: 'WT - M15 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M15 - End A - Section 2',
      headerName: 'WT - M15 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M15 - End B - Section 1',
      headerName: 'WT - M15 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End B - Section 1'
        ],
    },
    {
      field: 'WT - M15 - End B - Section 2',
      headerName: 'WT - M15 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '5 - End B - Section 2'
        ],
    },
    {
      field: 'WT - M16 - End A - Section 1',
      headerName: 'WT - M16 - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End A - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End A - Section 1'
        ],
    },
    {
      field: 'WT - M16 - End A - Section 2',
      headerName: 'WT - M16 - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End A - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End A - Section 2'
        ],
    },
    {
      field: 'WT - M16 - End B - Section 1',
      headerName: 'WT - M16 - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End B - Section 1'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End B - Section 1'
        ],
    },
    {
      field: 'WT - M16 - End B - Section 2',
      headerName: 'WT - M16 - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data[
            COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End B - Section 2'
          ],
        ),
      valueGetter: (params) =>
        params.data[
          COLUMNS['laser_pup_pieces_wt_m1'] + '6 - End B - Section 2'
        ],
    },
    {
      field: 'WT - Max - End A - Section 1',
      headerName: 'WT - Max - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Max - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Max - End A - Section 1'],
    },
    {
      field: 'WT - Max - End A - Section 2',
      headerName: 'WT - Max - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Max - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Max - End A - Section 2'],
    },
    {
      field: 'WT - Max - End B - Section 1',
      headerName: 'WT - Max - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Max - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Max - End B - Section 1'],
    },
    {
      field: 'WT - Max - End B - Section 2',
      headerName: 'WT - Max - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Max - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Max - End B - Section 2'],
    },
    {
      field: 'WT - Min - End A - Section 1',
      headerName: 'WT - Min - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Min - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Min - End A - Section 1'],
    },
    {
      field: 'WT - Min - End A - Section 2',
      headerName: 'WT - Min - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Min - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Min - End A - Section 2'],
    },
    {
      field: 'WT - Min - End B - Section 1',
      headerName: 'WT - Min - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Min - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Min - End B - Section 1'],
    },
    {
      field: 'WT - Min - End B - Section 2',
      headerName: 'WT - Min - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ WT - Min - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT - Min - End B - Section 2'],
    },
    {
      field: 'ID - Avg  - End A - Section 1',
      headerName: 'ID - Avg  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Avg  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Avg  - End A - Section 1'],
    },
    {
      field: 'ID - Avg  - End A - Section 2',
      headerName: 'ID - Avg  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Avg  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Avg  - End A - Section 2'],
    },
    {
      field: 'ID - Avg  - End B - Section 1',
      headerName: 'ID - Avg  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Avg  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Avg  - End B - Section 1'],
    },
    {
      field: 'ID - Avg  - End B - Section 2',
      headerName: 'ID - Avg  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Avg  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Avg  - End B - Section 2'],
    },
    {
      field: 'ID - Max  - End A - Section 1',
      headerName: 'ID - Max  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Max  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Max  - End A - Section 1'],
    },
    {
      field: 'ID - Max  - End A - Section 2',
      headerName: 'ID - Max  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Max  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Max  - End A - Section 2'],
    },
    {
      field: 'ID - Max  - End B - Section 1',
      headerName: 'ID - Max  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Max  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Max  - End B - Section 1'],
    },
    {
      field: 'ID - Max  - End B - Section 2',
      headerName: 'ID - Max  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Max  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Max  - End B - Section 2'],
    },
    {
      field: 'ID - Min  - End A - Section 1',
      headerName: 'ID - Min  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Min  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Min  - End A - Section 1'],
    },
    {
      field: 'ID - Min  - End A - Section 2',
      headerName: 'ID - Min  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Min  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Min  - End A - Section 2'],
    },
    {
      field: 'ID - Min  - End B - Section 1',
      headerName: 'ID - Min  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Min  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Min  - End B - Section 1'],
    },
    {
      field: 'ID - Min  - End B - Section 2',
      headerName: 'ID - Min  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - Min  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - Min  - End B - Section 2'],
    },
    {
      field: 'ID - OOR  - End A - Section 1',
      headerName: 'ID - OOR  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - OOR  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - OOR  - End A - Section 1'],
    },
    {
      field: 'ID - OOR  - End A - Section 2',
      headerName: 'ID - OOR  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - OOR  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - OOR  - End A - Section 2'],
    },
    {
      field: 'ID - OOR  - End B - Section 1',
      headerName: 'ID - OOR  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - OOR  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - OOR  - End B - Section 1'],
    },
    {
      field: 'ID - OOR  - End B - Section 2',
      headerName: 'ID - OOR  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ ID - OOR  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID - OOR  - End B - Section 2'],
    },
    {
      field: 'OD - Avg  - End A - Section 1',
      headerName: 'OD - Avg  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Avg  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Avg  - End A - Section 1'],
    },
    {
      field: 'OD - Avg  - End A - Section 2',
      headerName: 'OD - Avg  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Avg  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Avg  - End A - Section 2'],
    },
    {
      field: 'OD - Avg  - End B - Section 1',
      headerName: 'OD - Avg  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Avg  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Avg  - End B - Section 1'],
    },
    {
      field: 'OD - Avg  - End B - Section 2',
      headerName: 'OD - Avg  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Avg  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Avg  - End B - Section 2'],
    },
    {
      field: 'OD - Max  - End A - Section 1',
      headerName: 'OD - Max  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Max  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Max  - End A - Section 1'],
    },
    {
      field: 'OD - Max  - End A - Section 2',
      headerName: 'OD - Max  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Max  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Max  - End A - Section 2'],
    },
    {
      field: 'OD - Max  - End B - Section 1',
      headerName: 'OD - Max  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Max  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Max  - End B - Section 1'],
    },
    {
      field: 'OD - Max  - End B - Section 2',
      headerName: 'OD - Max  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Max  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Max  - End B - Section 2'],
    },
    {
      field: 'OD - Min  - End A - Section 1',
      headerName: 'OD - Min  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Min  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Min  - End A - Section 1'],
    },
    {
      field: 'OD - Min  - End A - Section 2',
      headerName: 'OD - Min  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Min  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Min  - End A - Section 2'],
    },
    {
      field: 'OD - Min  - End B - Section 1',
      headerName: 'OD - Min  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Min  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Min  - End B - Section 1'],
    },
    {
      field: 'OD - Min  - End B - Section 2',
      headerName: 'OD - Min  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - Min  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - Min  - End B - Section 2'],
    },
    {
      field: 'OD - OOR  - End A - Section 1',
      headerName: 'OD - OOR  - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - OOR  - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - OOR  - End A - Section 1'],
    },
    {
      field: 'OD - OOR  - End A - Section 2',
      headerName: 'OD - OOR  - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - OOR  - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - OOR  - End A - Section 2'],
    },
    {
      field: 'OD - OOR  - End B - Section 1',
      headerName: 'OD - OOR  - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - OOR  - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - OOR  - End B - Section 1'],
    },
    {
      field: 'OD - OOR  - End B - Section 2',
      headerName: 'OD - OOR  - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ OD - OOR  - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD - OOR  - End B - Section 2'],
    },
    {
      field: 'Minimum ID - End A - Section 1',
      headerName: 'Minimum ID - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum ID - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum ID - End A - Section 1'],
    },
    {
      field: 'Minimum ID - End A - Section 2',
      headerName: 'Minimum ID - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum ID - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum ID - End A - Section 2'],
    },
    {
      field: 'Minimum ID - End B - Section 1',
      headerName: 'Minimum ID - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum ID - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum ID - End B - Section 1'],
    },
    {
      field: 'Minimum ID - End B - Section 2',
      headerName: 'Minimum ID - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum ID - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum ID - End B - Section 2'],
    },
    {
      field: 'Nominal OD - End A - Section 1',
      headerName: 'Nominal OD - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal OD - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal OD - End A - Section 1'],
    },
    {
      field: 'Nominal OD - End A - Section 2',
      headerName: 'Nominal OD - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal OD - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal OD - End A - Section 2'],
    },
    {
      field: 'Nominal OD - End B - Section 1',
      headerName: 'Nominal OD - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal OD - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal OD - End B - Section 1'],
    },
    {
      field: 'Nominal OD - End B - Section 2',
      headerName: 'Nominal OD - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal OD - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal OD - End B - Section 2'],
    },
    {
      field: 'Nominal WT - End A - Section 1',
      headerName: 'Nominal WT - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal WT - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal WT - End A - Section 1'],
    },
    {
      field: 'Nominal WT - End A - Section 2',
      headerName: 'Nominal WT - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal WT - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal WT - End A - Section 2'],
    },
    {
      field: 'Nominal WT - End B - Section 1',
      headerName: 'Nominal WT - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal WT - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal WT - End B - Section 1'],
    },
    {
      field: 'Nominal WT - End B - Section 2',
      headerName: 'Nominal WT - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Nominal WT - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Nominal WT - End B - Section 2'],
    },
    {
      field: 'Maximum  ID - End A - Section 1',
      headerName: 'Maximum  ID - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  ID - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  ID - End A - Section 1'],
    },
    {
      field: 'Maximum  ID - End A - Section 2',
      headerName: 'Maximum  ID - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  ID - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  ID - End A - Section 2'],
    },
    {
      field: 'Maximum  ID - End B - Section 1',
      headerName: 'Maximum  ID - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  ID - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  ID - End B - Section 1'],
    },
    {
      field: 'Maximum  ID - End B - Section 2',
      headerName: 'Maximum  ID - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  ID - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  ID - End B - Section 2'],
    },
    {
      field: 'Maximum  OD - End A - Section 1',
      headerName: 'Maximum  OD - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  OD - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  OD - End A - Section 1'],
    },
    {
      field: 'Maximum  OD - End A - Section 2',
      headerName: 'Maximum  OD - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  OD - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  OD - End A - Section 2'],
    },
    {
      field: 'Maximum  OD - End B - Section 1',
      headerName: 'Maximum  OD - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  OD - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  OD - End B - Section 1'],
    },
    {
      field: 'Maximum  OD - End B - Section 2',
      headerName: 'Maximum  OD - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  OD - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  OD - End B - Section 2'],
    },
    {
      field: 'Maximum  WT - End A - Section 1',
      headerName: 'Maximum  WT - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  WT - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  WT - End A - Section 1'],
    },
    {
      field: 'Maximum  WT - End A - Section 2',
      headerName: 'Maximum  WT - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  WT - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  WT - End A - Section 2'],
    },
    {
      field: 'Maximum  WT - End B - Section 1',
      headerName: 'Maximum  WT - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  WT - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  WT - End B - Section 1'],
    },
    {
      field: 'Maximum  WT - End B - Section 2',
      headerName: 'Maximum  WT - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Maximum  WT - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Maximum  WT - End B - Section 2'],
    },
    {
      field: 'Minimum  OD - End A - Section 1',
      headerName: 'Minimum  OD - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  OD - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  OD - End A - Section 1'],
    },
    {
      field: 'Minimum  OD - End A - Section 2',
      headerName: 'Minimum  OD - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  OD - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  OD - End A - Section 2'],
    },
    {
      field: 'Minimum  OD - End B - Section 1',
      headerName: 'Minimum  OD - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  OD - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  OD - End B - Section 1'],
    },
    {
      field: 'Minimum  OD - End B - Section 2',
      headerName: 'Minimum  OD - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  OD - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  OD - End B - Section 2'],
    },
    {
      field: 'Minimum  WT - End A - Section 1',
      headerName: 'Minimum  WT - End A - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  WT - End A - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  WT - End A - Section 1'],
    },
    {
      field: 'Minimum  WT - End A - Section 2',
      headerName: 'Minimum  WT - End A - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  WT - End A - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  WT - End A - Section 2'],
    },
    {
      field: 'Minimum  WT - End B - Section 1',
      headerName: 'Minimum  WT - End B - Section 1',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  WT - End B - Section 1'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  WT - End B - Section 1'],
    },
    {
      field: 'Minimum  WT - End B - Section 2',
      headerName: 'Minimum  WT - End B - Section 2',
      headerClass: 'custom-header-2',
      sortable: true,
      cellClass: 'customCellDataExplorerTwoDigits',
      digits: 2,
      symbol: '',
      valueFormatter: (params) =>
        twoDigitsNumber(
          '',
          params.data['Laser - Pup Pieces _ Minimum  WT - End B - Section 2'],
        ),
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ Minimum  WT - End B - Section 2'],
    },
    {
      field: 'ID tolerances - End A - Section 1',
      headerName: 'ID tolerances - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID tolerances - End A - Section 1'],
      width: 150,
    },
    {
      field: 'ID tolerances - End A - Section 2',
      headerName: 'ID tolerances - End A - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID tolerances - End A - Section 2'],
      width: 150,
    },
    {
      field: 'ID tolerances - End B - Section 1',
      headerName: 'ID tolerances - End B - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID tolerances - End B - Section 1'],
      width: 150,
    },
    {
      field: 'ID tolerances - End B - Section 2',
      headerName: 'ID tolerances - End B - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ ID tolerances - End B - Section 2'],
      width: 150,
    },
    {
      field: 'OD tolerances - End A - Section 1',
      headerName: 'OD tolerances - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD tolerances - End A - Section 1'],
      width: 150,
    },
    {
      field: 'OD tolerances - End A - Section 2',
      headerName: 'OD tolerances - End A - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD tolerances - End A - Section 2'],
      width: 150,
    },
    {
      field: 'OD tolerances - End B - Section 1',
      headerName: 'OD tolerances - End B - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD tolerances - End B - Section 1'],
      width: 150,
    },
    {
      field: 'OD tolerances - End B - Section 2',
      headerName: 'OD tolerances - End B - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ OD tolerances - End B - Section 2'],
      width: 150,
    },
    {
      field: 'WT tolerances - End A - Section 1',
      headerName: 'WT tolerances - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT tolerances - End A - Section 1'],
      width: 150,
    },
    {
      field: 'WT tolerances - End A - Section 2',
      headerName: 'WT tolerances - End A - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT tolerances - End A - Section 2'],
      width: 150,
    },
    {
      field: 'WT tolerances - End B - Section 1',
      headerName: 'WT tolerances - End B - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT tolerances - End B - Section 1'],
      width: 150,
    },
    {
      field: 'WT tolerances - End B - Section 2',
      headerName: 'WT tolerances - End B - Section 2',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data['Laser - Pup Pieces _ WT tolerances - End B - Section 2'],
      width: 150,
    },
    {
      field: 'Project - Number - End A - Section 1',
      headerName: 'Project - Number - End A - Section 1',
      headerClass: 'custom-header-2',
      cellClass: 'customCellDataExplorer',
      sortable: true,
      valueGetter: (params) =>
        params.data[
          'Laser - Pup Pieces _ Project - Number - End A - Section 1'
        ],
      width: 150,
    },
  ],
};
