import { Material } from '../material.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findMaterialFromId = async (
  dataBookService: DataBookService,
  id: number,
): Promise<Material | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findMaterialFromId',
    args: {
      id,
    },
  };
  return await dataBookService.newLambda<Material | undefined>(params);
};
