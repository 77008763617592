import { TreeviewFileItem, TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const getFileById = async (
  dataBookService: DataBookService,
  fileId: number,
): Promise<TreeviewFileItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'getFileById',
    args: {
      fileId,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};

export const getFolderById = async (
  dataBookService: DataBookService,
  folderId: number,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'getFolderById',
    args: {
      folderId,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};

export const treeviewItemGetPath = async (
  dataBookService: DataBookService,
  id: number,
  type: 'file' | 'folder',
): Promise<number[]> => {
  const params: { functionName; args: any } = {
    functionName: 'treeviewItemGetPath',
    args: {
      id,
      type,
    },
  };
  return await dataBookService.newLambda<number[]>(params);
};

export const getFileBySearchName = async (
  dataBookService: DataBookService,
  searchName: string,
  productId: number,
): Promise<TreeviewFileItem[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getFileBySearchName',
    args: {
      searchName,
      productId,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem[] | undefined>(
    params,
  );
};
