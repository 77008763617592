import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findProductFromNameAndProjectId = async (
  dataBookService: DataBookService,
  projectId: number,
  name: string,
): Promise<Product | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findProductFromNameAndProjectId',
    args: {
      projectId,
      name,
    },
  };
  return await dataBookService.newLambda<Product | undefined>(params);
};

export const findProductFromNameAndProjectName = async (
  dataBookService: DataBookService,
  projectName: string,
  name: string,
): Promise<Product | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findProductFromNameAndProjectName',
    args: {
      projectName,
      name,
    },
  };
  return await dataBookService.newLambda<Product | undefined>(params);
};

export const findProductFromNameAndProjectNameLowercase = async (
  dataBookService: DataBookService,
  projectName: string,
  productName: string,
): Promise<Product | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findProductFromNameAndProjectNameLowercase',
    args: {
      projectName,
      productName,
    },
  };
  return await dataBookService.newLambda<Product | undefined>(params);
};
