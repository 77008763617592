<clr-modal
  [clrModalOpen]="isModalOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="title-container">
      <label
        class="session-title"
        *ngIf="type?.create"
        >{{ title.newStep }}
      </label>
      <label
        class="session-title"
        *ngIf="type?.update"
      >
        {{ title.editStep }}
      </label>
      <label
        class="session-title"
        *ngIf="type?.delete"
      >
        {{ title.deleteWorkflow }}
      </label>
    </div>

    <div *ngIf="!type.delete">
      <form
        *ngIf="form"
        class="materials-table"
        clrForm
        [formGroup]="form"
        style="margin-left: 0px"
      >
        <input-form-control
          label="application.commons.text.mail"
          name="mail"
          formControlName="mail"
          [size]="25"
          [form]="form"
          id="emailNewStepId"
        ></input-form-control>

        <input-form-control
          label="databook.new.tpi.digital.maximum.duration.for.signature.in.day(s)"
          type="number"
          name="delayToSign"
          formControlName="delayToSign"
          [size]="25"
          [form]="form"
          (keydown)="keydownOnlyNumbers($event)"
          id="delayNewStepId"
        ></input-form-control>

        <clr-radio-container>
          <label>
            {{ 'databook.new.tpi.digital.edit.isMandatory' | translate }}
          </label>
          <clr-radio-wrapper>
            <input
              type="radio"
              clrRadio
              value="true"
              name="mandatory"
              formControlName="mandatory"
              id="mandatoryStepId"
            />
            <label>
              <clr-icon
                shape="check"
                class="is-success"
                size="20"
              ></clr-icon>
            </label>
          </clr-radio-wrapper>
          <clr-radio-wrapper>
            <input
              type="radio"
              clrRadio
              value="false"
              name="mandatory"
              formControlName="mandatory"
              id="notMandatoryStepId"
            />
            <label>
              <clr-icon
                shape="times"
                class="is-error"
                size="20"
              ></clr-icon>
            </label>
          </clr-radio-wrapper>
          <clr-control-error>Error</clr-control-error>
        </clr-radio-container>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline"
        (click)="closeModal()"
        id="closStepWfButtonId"
      >
        CLOSE
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="type.create"
        (click)="addNewStep()"
        id="saveStepWfButtonId"
      >
        {{ 'application.commons.buttons.save' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="type.update"
        (click)="editStep()"
        id="editStepWfButtonId"
      >
        {{ 'application.commons.text.edit' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        *ngIf="type.delete"
        (click)="deleteStepInWorkflowModal()"
        id="deleteStepWfButtonId"
      >
        {{ 'confirm.exclusion.delete' | translate }}
      </button>
    </div>
  </div>
</clr-modal>
