import { zeroDigitsNumber } from './column-utils';

export default {
  headerName: 'PRODUCT IMPACT LONGITUDINAL',
  headerClass: 'custom-header-4',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'Longitudinal HTL',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ HTL'],
        },
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ Sampling Side'],
        },
        {
          field: 'Shear Area 1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data['Impact - Longitudinal _ Shear Area 1'],
            ),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ Shear Area 1'],
        },
        {
          field: 'Shear Area 2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data['Impact - Longitudinal _ Shear Area 2'],
            ),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ Shear Area 2'],
        },
        {
          field: 'Shear Area 3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data['Impact - Longitudinal _ Shear Area 3'],
            ),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ Shear Area 3'],
        },
        {
          field: 'Shear Area Average',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data['Impact - Longitudinal _ Shear Area Average'],
            ),
          valueGetter: (params: any) => {
            return params.data['Impact - Longitudinal _ Shear Area Average'];
          },
        },
        {
          field: 'AE1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Impact - Longitudinal _ AE 1']),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ AE 1'],
        },
        {
          field: 'AE2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Impact - Longitudinal _ AE 2']),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ AE 2'],
        },
        {
          field: 'AE3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Impact - Longitudinal _ AE 3']),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ AE 3'],
        },
        {
          field: 'Average AE',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data['Impact - Longitudinal _ Average AE'],
            ),
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ Average AE'],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-4',
      columnGroupShow: 'open',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Impact - Longitudinal _ File Name'],
        },
      ],
    },
  ],
};
