import Cookies from 'js-cookie';
import { breadcrumbPagesEnum } from '../enums/breadcrumbPages.enum';
export function mountPathTree(selected, tree) {
  let path = '/';
  let i = 1;
  for (const p of selected.path) {
    if (i === selected.path.length - 1) {
      path = path + tree.treeModel.getNodeById(p).data.name;
    } else if (i < selected.path.length) {
      path = path + tree.treeModel.getNodeById(p).data.name + '/';
    }
    i++;
  }

  if (!path.includes('/' + selected.data.name) && path !== '/') {
    path = path + '/' + selected.data.name;
  } else if (path === '/') {
    path = '/' + selected.data.name;
  }
  return path;
}

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// COOKIE SERVICE
export function getCookie(name): string {
  return Cookies.get(name);
}

export function removeCookie(name, cookieDomain) {
  Cookies.remove(name, { domain: cookieDomain, path: '/' });
}

export function setCookie(name, value, maxAge, cookieDomain) {
  Cookies.set(name, value, {
    expires: maxAge,
    domain: cookieDomain,
    path: '/',
  });
}

// CONTROL STRING LENGTH IN TABLES
export const showColumnFieldString = (name: string, length: number): string => {
  if (name?.length > length) {
    return `${name.substring(0, length - 1)}(...)`;
  }

  return name;
};

export const showColumnField = (name: string, length: number): boolean =>
  name?.length > length;

export const selectBreadcrumb = (breadcrumb: string): string => {
  if (breadcrumb === 'data-analysis') {
    return 'dashboard.data.analysis';
  }
  if (breadcrumb === 'data-explorer') {
    return 'dashboard.data.explorer';
  }
  if (breadcrumb === 'NEW TPI DIGITAL FLOW MGMT') {
    return 'dashboard.data.uplos';
  }
};
export const hasUndefinedLink = (array) => {
  return array.some((obj) => obj.link.includes('undefined'));
};
export const removeUndefinedLink = (breadCrumb) => {
  return breadCrumb.filter((obj) => !obj.link.includes('undefined'));
};
export const updateHasArrow = (breadcrumb) => {
  for (let i = 0; i < breadcrumb.length - 1; i++) {
    breadcrumb[i].hasArrow = true;
  }
  breadcrumb[breadcrumb.length - 1].hasArrow = false;
};
export const isADefinedBreadcrumb = (breadcrumb: string): boolean => {
  return Object.values(breadcrumbPagesEnum).includes(
    breadcrumb.toUpperCase() as breadcrumbPagesEnum,
  );
};
