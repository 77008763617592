import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataBookBaseComponent } from 'src/app/pages/databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ClrIconModule } from '@clr/angular';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'databook-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [NgIf, ClrIconModule, FormsModule, NgClass, TranslateModule],
})
export class DatabookSearchComponent extends DataBookBaseComponent {
  searchText = '';

  @Input()
  minLength = 0;

  @Input()
  whichPage: string;

  @Output()
  searchProject: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  searchAdviseVisible = false;

  async onSearchChange() {
    if (this.searchText.length < this.minLength) {
      this.searchProject.emit('');
      return;
    }
    this.searchProject.emit(this.searchText);
  }

  onClearClick() {
    this.searchText = '';
    this.onSearchChange();
  }
}
