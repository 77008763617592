<div *mobxAutorun>
  <div *ngIf="!folder">
    <div
      *ngFor="let folder of sortedTree"
      [ngClass]="folder.getClassNames()"
      class="treeview-folder"
    >
      <div>
        <div
          style="display: inline-block"
          [ngClass]="getFolderClassNames(folder)"
          (contextmenu)="treeviewStore.openContextMenu($event, folder)"
          [id]="folder.domId"
          draggable="true"
          (dragstart)="onDragStart($event, folder)"
          (click)="onFolderClick($event, folder)"
          (dragover)="onDragOver($event, folder)"
          (drop)="onDrop($event, folder)"
        >
          <img src="{{folder.icon}}" />
          <span *ngIf="!folder?.renaming">{{folder?.name}}</span>
        </div>
        <input
          class="treeview-text-field"
          *ngIf="folder?.renaming || folder?.isBeingCreating"
          (click)="preventClick($event)"
          (keyup)="folder.folderRenameHandler($event)"
          (keydown)="folder.folderRenameHandler($event)"
          (change)="folder.folderRenameHandler($event)"
          ngModel="{{folder.renamingFieldValue}}"
        />

        <img
          *ngIf="folder?.renaming"
          src="{{treeviewStore.cancel}}"
          (click)="cancelEditing(folder)"
        />

        <img
          *ngIf="folder?.renaming && folder?.renamingFieldValue?.length > 2"
          src="{{treeviewStore.confirm}}"
          (click)="confirmEditing(folder)"
        />
        <!--                FIXME: commented by lotfi dont delete -->
        <!--        <img-->
        <!--          *ngIf="folder?.isBeingCreating "-->
        <!--          class="cancel-add-folder-button"-->
        <!--          src="{{treeviewStore.cancel}}"-->
        <!--          (click)="cancelCreating()"-->
        <!--        />-->
        <!--        <img-->
        <!--          *ngIf="folder?.isBeingCreating && folder?.renamingFieldValue?.length > 2"-->
        <!--          class="confirm-add-folder-button"-->
        <!--          src="{{treeviewStore.addFolder}}"-->
        <!--          (click)="confirmCreating(folder)"-->
        <!--        />-->
      </div>

      <div *ngIf="folder?.isOpen">
        <div
          *ngIf="folder?.folders?.length > 0"
          class="folder-childrens"
        >
          <treeview-item
            *ngFor="let folder of folder?.foldersSorted"
            [folder]="folder"
          ></treeview-item>
        </div>

        <div
          *ngFor="let file of folder?.filesSorted"
          class="treeview-folder-files"
        >
          <div
            [id]="file?.domId"
            [ngClass]="getFileClassNames(file)"
            (contextmenu)="treeviewStore.openContextMenu($event, file)"
            class="draggable file-clickable-zone"
            draggable="true"
            (dragstart)="onDragStart($event, file)"
            (click)="onFileClick($event, file)"
          >
            <img src="{{file?.icon}}" />
            <span
              *ngIf="!file?.renaming"
              style="text-wrap: balance"
              >{{file.description}}
              ({{coreService.formatDateMsec(file.datetime)}})</span
            >
            <input
              class="treeview-text-field"
              *ngIf="file?.renaming"
              (click)="preventClick($event)"
              (change)="file.folderRenameHandler($event)"
              (keyup)="file.folderRenameHandler($event)"
              (keydown)="file.folderRenameHandler($event)"
              ngModel="{{file.renamingFieldValue}}"
            />
            <img
              *ngIf="file?.renaming"
              src="{{treeviewStore.cancel}}"
              (click)="cancelEditing(file)"
            />
            <img
              *ngIf="file?.renaming && file?.renamingFieldValue?.length > 2"
              src="{{treeviewStore.confirm}}"
              (click)="confirmEditing(file)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="folder">
    <div
      [ngClass]="folder.getClassNames()"
      class="treeview-folder"
    >
      <div>
        <div>
          <div
            style="display: inline-block"
            [ngClass]="getFolderClassNames(folder)"
            [id]="folder?.domId"
            (contextmenu)="treeviewStore.openContextMenu($event, folder)"
            draggable="true"
            (dragstart)="onDragStart($event, folder)"
            (click)="onFolderClick($event, folder)"
            (dragover)="onDragOver($event, folder)"
            (drop)="onDrop($event, folder)"
          >
            <img src="{{folder.icon}}" />
            <span *ngIf="!folder?.renaming">{{folder.name}}</span>
          </div>
          <input
            class="treeview-text-field"
            *ngIf="folder?.renaming || folder?.isBeingCreating"
            (click)="preventClick($event)"
            (keyup)="folder.folderRenameHandler($event)"
            (keydown)="folder.folderRenameHandler($event)"
            (change)="folder.folderRenameHandler($event)"
            ngModel="{{folder.renamingFieldValue}}"
          />

          <img
            *ngIf="folder?.renaming"
            src="{{treeviewStore.cancel}}"
            (click)="cancelEditing(folder)"
          />
          <img
            *ngIf="folder?.renaming && folder?.renamingFieldValue?.length > 2"
            src="{{treeviewStore.confirm}}"
            (click)="confirmEditing(folder)"
          />

          <img
            *ngIf="folder?.isBeingCreating"
            src="{{treeviewStore.cancel}}"
            (click)="cancelCreating()"
          />
          <img
            *ngIf="folder?.isBeingCreating && folder?.renamingFieldValue?.length > 2"
            src="{{treeviewStore.addFolder}}"
            (click)="confirmCreating(folder)"
          />
        </div>
      </div>
      <div *ngIf="folder?.isOpen">
        <div
          *ngIf="folder?.folders?.length > 0"
          class="folder-childrens"
        >
          <treeview-item
            *ngFor="let folder of folder.foldersSorted"
            [folder]="folder"
          ></treeview-item>
        </div>
        <div
          *ngFor="let file of folder.filesSorted"
          class="treeview-folder-files"
        >
          <div
            [id]="file.domId"
            [ngClass]="getFileClassNames(file)"
            (contextmenu)="treeviewStore.openContextMenu($event, file)"
            class="draggable"
            draggable="true"
            (dragstart)="onDragStart($event, file)"
            (click)="onFileClick($event, file)"
          >
            <img src="{{file.icon}}" />
            <span *ngIf="!file?.renaming"
              >{{file.description}}
              ({{coreService.formatDateMsec(file.datetime)}})</span
            >
            <input
              class="treeview-text-field"
              *ngIf="file?.renaming"
              (click)="preventClick($event)"
              (change)="file.folderRenameHandler($event)"
              (keyup)="file.folderRenameHandler($event)"
              (keydown)="file.folderRenameHandler($event)"
              ngModel="{{file.renamingFieldValue}}"
            />
            <img
              *ngIf="file?.renaming"
              src="{{treeviewStore.cancel}}"
              (click)="cancelEditing(file)"
            />
            <img
              *ngIf="file?.renaming && file?.renamingFieldValue?.length > 2"
              src="{{treeviewStore.confirm}}"
              (click)="confirmEditing(file)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
