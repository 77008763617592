<vic-base-page page="welding">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isDoubleJointVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Tally_Name')"
            >{{ 'databook.tally.name' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Started_Date')"
            >{{ 'databook.started.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Uploaded_Date')"
            >{{ 'databook.uploaded.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Position')"
            >{{ 'databook.position' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Tally')"
            >{{ 'databook.tally' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Anode')"
            >{{ 'databook.anode' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Side')"
            >{{ 'databook.side' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Weld')"
            >{{ 'databook.weld' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="170"
            *ngIf="shouldShowColumn('Individual_Length_m')"
            >{{ 'databook.individual.length.m' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="170"
            *ngIf="shouldShowColumn('Cumulative_Length_m')"
            >{{ 'databook.cumulative.length.m' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="170"
            *ngIf="shouldShowColumn('Individual_Weight_kg')"
            >{{ 'databook.individual.weight.kg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="170"
            *ngIf="shouldShowColumn('Cumulative_Weight_kg')"
            >{{ 'databook.cumulative.weight.kg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="170"
            *ngIf="shouldShowColumn('Pipe_Comment')"
            >{{ 'databook.comment' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Tally_Name')">{{
              data['Tally_Name']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Started_Date')">{{
              data['Started_Date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Uploaded_Date')">{{
              data['Uploaded_Date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Position')">{{
              data['Position']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Tally')">{{
              data['Tally']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Anode')">{{
              data['Anode']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Side')">{{
              data['Side']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Weld')">{{
              data['Weld']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Individual_Length_m')">{{
              data['Individual_Length_m']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cumulative_Length_m')">{{
              data['Cumulative_Length_m']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Individual_Weight_kg')">{{
              data['Individual_Weight_kg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cumulative_Weight_kg')">{{
              data['Cumulative_Weight_kg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Comment')">{{
              data['Pipe_Comment']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
