<div class="clr-form-control clr-row">
  <label
    class="clr-control-label clr-col-4"
    [ngClass]="{ 'clr-required-mark': required }"
    >{{ label || (labelKey | translate) }}</label
  >

  <div
    class="clr-control-container clr-col-8"
    [ngClass]="{ 'clr-error': control.touched && control.errors }"
  >
    <ng-content></ng-content>

    <div class="error-container">
      <pn-field-error [control]="control"></pn-field-error>
    </div>
  </div>
</div>
