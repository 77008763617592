export const COLUMNS = {
  ippn: 'IPPN',
  heat: 'HEAT',
  htl: 'HTL',
  order: 'Order',
  item: 'Item',
  grade: 'Grade',
  sequencial: 'Sequencial',
  pipe_number: 'Pipe Number',
  pipe_weight: 'Pipe Weight',
  pipe_length: 'Pipe Length',
  customer_order: 'Customer Order',
  customer_item: 'Customer Item',
  notional_item: 'Notional Item',
  nominal_od: 'Nominal OD',
  nominal_wt: 'Nominal WT',
  qty: 'Qty',
  unit: 'Unit',
  lot: 'Lot',
  date: 'Date',
  batch: 'Batch',
  ladle: 'Ladle',
  description: 'Description',
  tensile_longitudinal_sampling_side: 'Tensile - Longitudinal _ Sampling Side',
  tensile_longitudinal_elongation: 'Tensile - Longitudinal _ Elongation',
  tensile_longitudinal_ys_ts: 'Tensile - Longitudinal _ YS/TS',
  tensile_longitudinal_yield_strength:
    'Tensile - Longitudinal _ Yield Strength',
  tensile_longitudinal_tensile_strength:
    'Tensile - Longitudinal _ Tensile Strength',
  tensile_longitudinal_htl: 'Tensile - Longitudinal _ HTL',
  tensile_longitudinal_file_name: 'Tensile - Longitudinal _ File Name',
  tensile_longitudinal_areacplo: 'Tensile - Longitudinal _ AREACPLO',
  tensile_transverse_sampling_side: 'Tensile - Transverse _ Sampling Side',
  tensile_transverse_elongation: 'Tensile - Transverse _ Elongation',
  'tensile_transverse_ys/ts': 'Tensile - Transverse _ YS/TS',
  tensile_transverse_yield_strength: 'Tensile - Transverse _ Yield Strength',
  tensile_transverse_tensile_strength:
    'Tensile - Transverse _ Tensile Strength',
  tensile_transverse_htl: 'Tensile - Transverse _ HTL',
  tensile_transverse_file_name: 'Tensile - Transverse _ File Name',
  impact_longitudinal_sampling_side: 'Impact - Longitudinal _ Sampling Side',
  impact_longitudinal_shear_area_1: 'Impact - Longitudinal _ Shear Area 1',
  impact_longitudinal_shear_area_2: 'Impact - Longitudinal _ Shear Area 2',
  impact_longitudinal_shear_area_3: 'Impact - Longitudinal _ Shear Area 3',
  impact_longitudinal_average_ae: 'Impact - Longitudinal _ Average AE',
  impact_longitudinal_ae_1: 'Impact - Longitudinal _ AE 1',
  impact_longitudinal_ae_2: 'Impact - Longitudinal _ AE 2',
  impact_longitudinal_ae_3: 'Impact - Longitudinal _ AE 3',
  impact_longitudinal_average: 'Impact - Longitudinal _ Shear Area Average',
  impact_longitudinal_htl: 'Impact - Longitudinal _ HTL',
  impact_longitudinal_file_name: 'Impact - Longitudinal _ File Name',
  impact_transverse_sampling_side: 'Impact - Transverse _ Sampling Side',
  impact_transverse_shear_area_1: 'Impact - Transverse _ Shear Area 1',
  impact_transverse_shear_area_2: 'Impact - Transverse _ Shear Area 2',
  impact_transverse_shear_area_3: 'Impact - Transverse _ Shear Area 3',
  impact_transverse_average_ae: 'Impact - Transverse _ Average AE',
  impact_transverse_ae_1: 'Impact - Transverse _ AE 1',
  impact_transverse_ae_2: 'Impact - Transverse _ AE 2',
  impact_transverse_ae_3: 'Impact - Transverse _ AE 3',
  impact_transverse_average: 'Impact - Transverse _ Shear Area Average',
  impact_transverse_htl: 'Impact - Transverse _ HTL',
  impact_transverse_file_name: 'Impact - Transverse _ File Name',
  hardness_sampling_side: 'Hardness _ Sampling Side',
  hardness_q1_ow1: 'Hardness _ Q1 OW1',
  hardness_q1_ow2: 'Hardness _ Q1 OW2',
  hardness_q1_ow3: 'Hardness _ Q1 OW3',
  hardness_q1_ow4: 'Hardness _ Q1 OW4',
  hardness_q1_avg_ow: 'Hardness _ Q1 AVG OW',
  hardness_q1_iw1: 'Hardness _ Q1 IW1',
  hardness_q1_iw2: 'Hardness _ Q1 IW2',
  hardness_q1_iw3: 'Hardness _ Q1 IW3',
  hardness_q1_iw4: 'Hardness _ Q1 IW4',
  hardness_q1_avg_iw: 'Hardness _ Q1 AVG IW',
  hardness_q1_mw1: 'Hardness _ Q1 MW1',
  hardness_q1_mw2: 'Hardness _ Q1 MW2',
  hardness_q1_mw3: 'Hardness _ Q1 MW3',
  hardness_q1_mw4: 'Hardness _ Q1 MW4',
  hardness_q1_avg_mw: 'Hardness _ Q1 AVG MW',
  hardness_q1_avg: 'Hardness _ Q1 AVG',
  hardness_q2_ow1: 'Hardness _ Q2 OW1',
  hardness_q2_ow2: 'Hardness _ Q2 OW2',
  hardness_q2_ow3: 'Hardness _ Q2 OW3',
  hardness_q2_ow4: 'Hardness _ Q2 OW4',
  hardness_q2_avg_ow: 'Hardness _ Q2 AVG OW',
  hardness_q2_iw1: 'Hardness _ Q2 IW1',
  hardness_q2_iw2: 'Hardness _ Q2 IW2',
  hardness_q2_iw3: 'Hardness _ Q2 IW3',
  hardness_q2_iw4: 'Hardness _ Q2 IW4',
  hardness_q2_avg_iw: 'Hardness _ Q2 AVG IW',
  hardness_q2_mw1: 'Hardness _ Q2 MW1',
  hardness_q2_mw2: 'Hardness _ Q2 MW2',
  hardness_q2_mw3: 'Hardness _ Q2 MW3',
  hardness_q2_mw4: 'Hardness _ Q2 MW4',
  hardness_q2_avg_mw: 'Hardness _ Q2 AVG MW',
  hardness_q2_avg: 'Hardness _ Q2 AVG',
  hardness_q3_ow1: 'Hardness _ Q3 OW1',
  hardness_q3_ow2: 'Hardness _ Q3 OW2',
  hardness_q3_ow3: 'Hardness _ Q3 OW3',
  hardness_q3_ow4: 'Hardness _ Q3 OW4',
  hardness_q3_avg_ow: 'Hardness _ Q3 AVG OW',
  hardness_q3_iw1: 'Hardness _ Q3 IW1',
  hardness_q3_iw2: 'Hardness _ Q3 IW2',
  hardness_q3_iw3: 'Hardness _ Q3 IW3',
  hardness_q3_iw4: 'Hardness _ Q3 IW4',
  hardness_q3_avg_iw: 'Hardness _ Q3 AVG IW',
  hardness_q3_mw1: 'Hardness _ Q3 MW1',
  hardness_q3_mw2: 'Hardness _ Q3 MW2',
  hardness_q3_mw3: 'Hardness _ Q3 MW3',
  hardness_q3_mw4: 'Hardness _ Q3 MW4',
  hardness_q3_avg_mw: 'Hardness _ Q3 AVG MW',
  hardness_q3_avg: 'Hardness _ Q3 AVG',
  hardness_q4_ow1: 'Hardness _ Q4 OW1',
  hardness_q4_ow2: 'Hardness _ Q4 OW2',
  hardness_q4_ow3: 'Hardness _ Q4 OW3',
  hardness_q4_ow4: 'Hardness _ Q4 OW4',
  hardness_q4_avg_ow: 'Hardness _ Q4 AVG OW',
  hardness_q4_iw1: 'Hardness _ Q4 IW1',
  hardness_q4_iw2: 'Hardness _ Q4 IW2',
  hardness_q4_iw3: 'Hardness _ Q4 IW3',
  hardness_q4_iw4: 'Hardness _ Q4 IW4',
  hardness_q4_avg_iw: 'Hardness _ Q4 AVG IW',
  hardness_q4_mw1: 'Hardness _ Q4 MW1',
  hardness_q4_mw2: 'Hardness _ Q4 MW2',
  hardness_q4_mw3: 'Hardness _ Q4 MW3',
  hardness_q4_mw4: 'Hardness _ Q4 MW4',
  hardness_q4_avg_mw: 'Hardness _ Q4 AVG MW',
  hardness_q4_avg: 'Hardness _ Q4 AVG',
  hardness_htl: 'Hardness _ HTL',
  hardness_file_name: 'Hardness _ File Name',
  laser_flex_line_end: 'Laser - Flex Line _ END',
  laser_flex_line_sct: 'Laser - Flex Line _ SCT',
  laser_flex_line_wt: 'Laser - Flex Line _ WT',
  laser_flex_line_grade: 'Laser - Flex Line _ Grade',
  laser_flex_line_id_m1: 'Laser - Flex Line _ ID - M1',
  laser_flex_line_id_m2: 'Laser - Flex Line _ ID - M2',
  laser_flex_line_id_m3: 'Laser - Flex Line _ ID - M3',
  laser_flex_line_id_m4: 'Laser - Flex Line _ ID - M4',
  laser_flex_line_id_m5: 'Laser - Flex Line _ ID - M5',
  laser_flex_line_id_m6: 'Laser - Flex Line _ ID - M6',
  laser_flex_line_id_m7: 'Laser - Flex Line _ ID - M7',
  laser_flex_line_id_m8: 'Laser - Flex Line _ ID - M8',
  laser_flex_line_id_avg: 'Laser - Flex Line _ ID - AVG',
  laser_flex_line_id_max: 'Laser - Flex Line _ ID - MAX',
  laser_flex_line_id_min: 'Laser - Flex Line _ ID - MIN',
  laser_flex_line_id_oor: 'Laser - Flex Line _ ID - OOR',
  laser_flex_line_od_m1: 'Laser - Flex Line _ OD - M1',
  laser_flex_line_od_m2: 'Laser - Flex Line _ OD - M2',
  laser_flex_line_od_m3: 'Laser - Flex Line _ OD - M3',
  laser_flex_line_od_m4: 'Laser - Flex Line _ OD - M4',
  laser_flex_line_od_m5: 'Laser - Flex Line _ OD - M5',
  laser_flex_line_od_m6: 'Laser - Flex Line _ OD - M6',
  laser_flex_line_od_m7: 'Laser - Flex Line _ OD - M7',
  laser_flex_line_od_m8: 'Laser - Flex Line _ OD - M8',
  laser_flex_line_od_avg: 'Laser - Flex Line _ OD - AVG',
  laser_flex_line_od_max: 'Laser - Flex Line _ OD - MAX',
  laser_flex_line_od_min: 'Laser - Flex Line _ OD - MIN',
  laser_flex_line_od_oor: 'Laser - Flex Line _ OD - OOR',
  laser_flex_line_wt_m1: 'Laser - Flex Line _ WT - M1',
  laser_flex_line_wt_m2: 'Laser - Flex Line _ WT - M2',
  laser_flex_line_wt_m3: 'Laser - Flex Line _ WT - M3',
  laser_flex_line_wt_m4: 'Laser - Flex Line _ WT - M4',
  laser_flex_line_wt_m5: 'Laser - Flex Line _ WT - M5',
  laser_flex_line_wt_m6: 'Laser - Flex Line _ WT - M6',
  laser_flex_line_wt_m7: 'Laser - Flex Line _ WT - M7',
  laser_flex_line_wt_m8: 'Laser - Flex Line _ WT - M8',
  laser_flex_line_wt_m9: 'Laser - Flex Line _ WT - M9',
  laser_flex_line_wt_m10: 'Laser - Flex Line _ WT - M10',
  laser_flex_line_wt_m11: 'Laser - Flex Line _ WT - M11',
  laser_flex_line_wt_m12: 'Laser - Flex Line _ WT - M12',
  laser_flex_line_wt_m13: 'Laser - Flex Line _ WT - M13',
  laser_flex_line_wt_m14: 'Laser - Flex Line _ WT - M14',
  laser_flex_line_wt_m15: 'Laser - Flex Line _ WT - M15',
  laser_flex_line_wt_m16: 'Laser - Flex Line _ WT - M16',
  laser_flex_line_wt_avg: 'Laser - Flex Line _ WT - AVG',
  laser_flex_line_wt_max: 'Laser - Flex Line _ WT - MAX',
  laser_flex_line_wt_min: 'Laser - Flex Line _ WT - MIN',
  laser_flex_line_wt_ecc: 'Laser - Flex Line _ WT - ECC',
  laser_flex_line_actual_id: 'Laser - Flex Line _ Actual ID',
  laser_flex_line_nominal_od: 'Laser - Flex Line _ Nominal OD',
  laser_flex_line_nominal_wt: 'Laser - Flex Line _ Nominal WT',
  laser_flex_line_maximum_od: 'Laser - Flex Line _ Maximum OD',
  laser_flex_line_minimum_od: 'Laser - Flex Line _ Minimum OD',
  laser_flex_line_maximum_id: 'Laser - Flex Line _ Maximum ID',
  laser_flex_line_minimum_id: 'Laser - Flex Line _ Minimum ID',
  laser_flex_line_maximum_wt: 'Laser - Flex Line _ Maximum WT',
  laser_flex_line_minimum_wt: 'Laser - Flex Line _ Minimum WT',
  laser_flex_line_oor_end_b_section_1:
    'Laser - Flex Line _ OOR - End B - Section 1',
  laser_flex_line_oor_end_b_section_2:
    'Laser - Flex Line _ OOR - End B - Section 2',
  laser_flex_line_Oor_end_b_section_1:
    'Laser - Flex Line _ OOR - End B - Section 1',
  laser_flex_line_Oor_end_b_section_2:
    'Laser - Flex Line _ OOR - End B - Section 2',
  laser_flex_line_od_tolerances: 'Laser - Flex Line _ OD tolerances',
  laser_flex_line_project_number: 'Laser - Flex Line _ Project - Number',
  laser_flex_line_wt_tolerances: 'Laser - Flex Line _ WT tolerances',
  laser_flex_line_project_description:
    'Laser - Flex Line _ Project - Description',
  laser_flex_line_customer_description:
    'Laser - Flex Line _ Customer - Description',
  laser_flex_line_id_tolerances: 'Laser - Flex Line _ ID tolerances',
  laser_flex_line_file_name: 'Laser - Flex Line _ File Name',
  chemical_al: 'Chemical _ AL',
  chemical_as: 'Chemical _ AS',
  chemical_b: 'Chemical _ B',
  chemical_bi: 'Chemical _ BI',
  chemical_c: 'Chemical _ C',
  chemical_ca: 'Chemical _ CA',
  chemical_co: 'Chemical _ CO',
  chemical_cr: 'Chemical _ CR',
  chemical_cu: 'Chemical _ CU',
  chemical_h: 'Chemical _ H',
  chemical_mn: 'Chemical _ MN',
  chemical_mo: 'Chemical _ MO',
  chemical_n: 'Chemical _ N',
  chemical_nb: 'Chemical _ NB',
  chemical_ni: 'Chemical _ NI',
  chemical_o: 'Chemical _ O',
  chemical_p: 'Chemical _ P',
  chemical_pb: 'Chemical _ PB',
  chemical_s: 'Chemical _ S',
  chemical_sb: 'Chemical _ SB',
  chemical_se: 'Chemical _ SE',
  chemical_si: 'Chemical _ SI',
  chemical_sn: 'Chemical _ SN',
  chemical_sr: 'Chemical _ SR',
  chemical_ti: 'Chemical _ TI',
  chemical_v: 'Chemical _ V',
  chemical_w: 'Chemical _ W',
  chemical_pcm: 'Chemical _ Pcm',
  chemical_ce1: 'Chemical _ CE1',
  chemical_ce2: 'Chemical _ CE2',
  chemical_ce3: 'Chemical _ CE3',
  chemical_ce4: 'Chemical _ CE4',
  chemical_ce5: 'Chemical _ CE5',
  chemical_file_name: 'Chemical _ File Name',
  ladle_chemical_al: 'Ladle Chemical _ AL',
  ladle_chemical_as: 'Ladle Chemical _ AS',
  ladle_chemical_b: 'Ladle Chemical _ B',
  ladle_chemical_bi: 'Ladle Chemical _ BI',
  ladle_chemical_c: 'Ladle Chemical _ C',
  ladle_chemical_ca: 'Ladle Chemical _ CA',
  ladle_chemical_co: 'Ladle Chemical _ CO',
  ladle_chemical_cr: 'Ladle Chemical _ CR',
  ladle_chemical_cu: 'Ladle Chemical _ CU',
  ladle_chemical_h: 'Ladle Chemical _ H',
  ladle_chemical_mn: 'Ladle Chemical _ MN',
  ladle_chemical_mo: 'Ladle Chemical _ MO',
  ladle_chemical_n: 'Ladle Chemical _ N',
  ladle_chemical_nb: 'Ladle Chemical _ NB',
  ladle_chemical_ni: 'Ladle Chemical _ NI',
  ladle_chemical_o: 'Ladle Chemical _ O',
  ladle_chemical_p: 'Ladle Chemical _ P',
  ladle_chemical_pb: 'Ladle Chemical _ PB',
  ladle_chemical_s: 'Ladle Chemical _ S',
  ladle_chemical_sb: 'Ladle Chemical _ SB',
  ladle_chemical_se: 'Ladle Chemical _ SE',
  ladle_chemical_si: 'Ladle Chemical _ SI',
  ladle_chemical_sn: 'Ladle Chemical _ SN',
  ladle_chemical_sr: 'Ladle Chemical _ SR',
  ladle_chemical_ti: 'Ladle Chemical _ TI',
  ladle_chemical_v: 'Ladle Chemical _ V',
  ladle_chemical_w: 'Ladle Chemical _ W',
  ladle_chemical_pcm: 'Ladle Chemical _ Pcm',
  ladle_chemical_ceq: 'Ladle Chemical _ CEQ',
  ladle_chemical_ce1: 'Ladle Chemical _ CE1',
  ladle_chemical_ce2: 'Ladle Chemical _ CE2',
  ladle_chemical_ce3: 'Ladle Chemical _ CE3',
  ladle_chemical_ce4: 'Ladle Chemical _ CE4',
  ladle_chemical_ce5: 'Ladle Chemical _ CE5',
  ladle_chemical_file_name: 'Ladle Chemical _ File Name',
  'dimensional_customer_purchase_order_/_item':
    'Dimensional _ Customer Purchase Order / Item',
  dimensional_od: 'Dimensional _ OD',
  dimensional_wt: 'Dimensional _ WT',
  dimensional_grade: 'Dimensional _ Grade',
  dimensional_production_order: 'Dimensional _ Production Order',
  dimensional_body_inspection_time: 'Dimensional _ Body - Inspection time',
  'dimensional_bottom_od_(min)': 'Dimensional _ Bottom - OD (min)',
  'dimensional_bottom_od_(max)': 'Dimensional _ Bottom - OD (max)',
  'dimensional_body_od_(min)': 'Dimensional _ Body - OD (min)',
  'dimensional_body_od_(max)': 'Dimensional _ Body - OD (max)',
  'dimensional_body_od_(avg)': 'Dimensional _ Body - OD (avg)',
  'dimensional_top_od_(min)': 'Dimensional _ Top - OD (min)',
  'dimensional_top_od_(max)': 'Dimensional _ Top - OD (max)',
  'dimensional_bottom_wt_(min)': 'Dimensional _ Bottom - WT (min)',
  'dimensional_bottom_wt_(max)': 'Dimensional _ Bottom - WT (max)',
  'dimensional_body_wt_(min)': 'Dimensional _ Body - WT (min)',
  'dimensional_body_wt_(max)': 'Dimensional _ Body - WT (max)',
  'dimensional_body_wt_(avg)': 'Dimensional _ Body - WT (avg)',
  'dimensional_top_wt_(min)': 'Dimensional _ Top - WT (min)',
  'dimensional_top_wt_(max)': 'Dimensional _ Top - WT (max)',
  'dimensional_bottom_oor_(min)': 'Dimensional _ Bottom - OOR (min)',
  'dimensional_bottom_oor_(max)': 'Dimensional _ Bottom - OOR (max)',
  'dimensional_body_oor_(min)': 'Dimensional _ Body - OOR (min)',
  'dimensional_body_oor_(max)': 'Dimensional _ Body - OOR (max)',
  'dimensional_body_oor_(avg)': 'Dimensional _ Body - OOR (avg)',
  'dimensional_top_oor_(min)': 'Dimensional _ Top - OOR (min)',
  'dimensional_top_oor_(max)': 'Dimensional _ Top - OOR (max)',
  'dimensional_bottom_ecc_(min)': 'Dimensional _ Bottom - ECC (min)',
  'dimensional_bottom_ecc_(max)': 'Dimensional _ Bottom - ECC (max)',
  'dimensional_body_ecc_(min)': 'Dimensional _ Body - ECC (min)',
  'dimensional_body_ecc_(max)': 'Dimensional _ Body - ECC (max)',
  'dimensional_body_ecc_(avg)': 'Dimensional _ Body - ECC (avg)',
  'dimensional_top_ecc_(min)': 'Dimensional _ Top - ECC (min)',
  'dimensional_top_ecc_(max)': 'Dimensional _ Top - ECC (max)',
  dimensional_body_file_name: 'Dimensional _ Body - File Name',
  lcf_laser_measurement_length_pipe_sequencial_nº:
    'LCF laser measurement length _ Pipe sequencial nº',
  lcf_laser_measurement_length_a_pos_a_id:
    'LCF laser measurement length _ A - Pos. A ID',
  lcf_laser_measurement_length_a_pos_a_od:
    'LCF laser measurement length _ A - Pos. A OD',
  'lcf_laser_measurement_length_a_pos_a_wt_(1)':
    'LCF laser measurement length _ A - Pos. A WT (1)',
  'lcf_laser_measurement_length_a_pos_a_wt_(2)':
    'LCF laser measurement length _ A - Pos. A WT (2)',
  lcf_laser_measurement_length_a_pos_b_id:
    'LCF laser measurement length _ A - Pos. B ID',
  lcf_laser_measurement_length_a_pos_b_od:
    'LCF laser measurement length _ A - Pos. B OD',
  'lcf_laser_measurement_length_a_pos_b_wt_(1)':
    'LCF laser measurement length _ A - Pos. B WT (1)',
  'lcf_laser_measurement_length_a_pos_b_wt_(2)':
    'LCF laser measurement length _ A - Pos. B WT (2)',
  lcf_laser_measurement_length_a_pos_c_id:
    'LCF laser measurement length _ A - Pos. C ID',
  lcf_laser_measurement_length_a_pos_c_od:
    'LCF laser measurement length _ A - Pos. C OD',
  'lcf_laser_measurement_length_a_pos_c_wt_(1)':
    'LCF laser measurement length _ A - Pos. C WT (1)',
  'lcf_laser_measurement_length_a_pos_c_wt_(2)':
    'LCF laser measurement length _ A - Pos. C WT (2)',
  lcf_laser_measurement_length_a_pos_d_id:
    'LCF laser measurement length _ A - Pos. D ID',
  lcf_laser_measurement_length_a_pos_d_od:
    'LCF laser measurement length _ A - Pos. D OD',
  'lcf_laser_measurement_length_a_pos_d_wt_(1)':
    'LCF laser measurement length _ A - Pos. D WT (1)',
  'lcf_laser_measurement_length_a_pos_d_wt_(2)':
    'LCF laser measurement length _ A - Pos. D WT (2)',
  lcf_laser_measurement_length_a_roughness:
    'LCF laser measurement length _ A - Roughness',
  lcf_laser_measurement_length_a_taper_angle:
    'LCF laser measurement length _ A - Taper angle',
  lcf_laser_measurement_length_a_squareness:
    'LCF laser measurement length _ A - Squareness',
  lcf_laser_measurement_length_b_pos_a_id:
    'LCF laser measurement length _ B - Pos. A ID',
  lcf_laser_measurement_length_b_pos_a_od:
    'LCF laser measurement length _ B - Pos. A OD',
  'lcf_laser_measurement_length_b_pos_a_wt_(1)':
    'LCF laser measurement length _ B - Pos. A WT (1)',
  'lcf_laser_measurement_length_b_pos_a_wt_(2)':
    'LCF laser measurement length _ B - Pos. A WT (2)',
  lcf_laser_measurement_length_b_pos_b_id:
    'LCF laser measurement length _ B - Pos. B ID',
  lcf_laser_measurement_length_b_pos_b_od:
    'LCF laser measurement length _ B - Pos. B OD',
  'lcf_laser_measurement_length_b_pos_b_wt_(1)':
    'LCF laser measurement length _ B - Pos. B WT (1)',
  'lcf_laser_measurement_length_b_pos_b_wt_(2)':
    'LCF laser measurement length _ B - Pos. B WT (2)',
  lcf_laser_measurement_length_b_pos_c_id:
    'LCF laser measurement length _ B - Pos. C ID',
  lcf_laser_measurement_length_b_pos_c_od:
    'LCF laser measurement length _ B - Pos. C OD',
  'lcf_laser_measurement_length_b_pos_c_wt_(1)':
    'LCF laser measurement length _ B - Pos. C WT (1)',
  'lcf_laser_measurement_length_b_pos_c_wt_(2)':
    'LCF laser measurement length _ B - Pos. C WT (2)',
  lcf_laser_measurement_length_b_pos_d_id:
    'LCF laser measurement length _ B - Pos. D ID',
  lcf_laser_measurement_length_b_pos_d_od:
    'LCF laser measurement length _ B - Pos. D OD',
  'lcf_laser_measurement_length_b_pos_d_wt_(1)':
    'LCF laser measurement length _ B - Pos. D WT (1)',
  'lcf_laser_measurement_length_b_pos_d_wt_(2)':
    'LCF laser measurement length _ B - Pos. D WT (2)',
  lcf_laser_measurement_length_b_roughness:
    'LCF laser measurement length _ B - Roughness',
  lcf_laser_measurement_length_b_taper_angle:
    'LCF laser measurement length _ B - Taper angle',
  lcf_laser_measurement_length_b_squareness:
    'LCF laser measurement length _ B - Squareness',
  lcf_laser_measurement_length_actual_length:
    'LCF laser measurement length _ Actual Length',
  lcf_laser_measurement_length_squareness:
    'LCF laser measurement length _ Squareness',
  lcf_laser_measurement_length_visual_inspection:
    'LCF laser measurement length _ Visual Inspection',
  lcf_laser_measurement_length_status: 'LCF laser measurement length _ Status',
  lcf_laser_measurement_length_file_name:
    'LCF laser measurement length _ File Name',
  laser_pup_pieces_project_number: 'Laser - Pup Pieces _ Project - Number',
  laser_pup_pieces_file_name: 'Laser - Pup Pieces _ File Name',
  laser_pup_pieces_project_description:
    'Laser - Pup Pieces _ Project - Description',
  laser_pup_pieces_customer_description:
    'Laser - Pup Pieces _ Customer - Description',
  laser_pup_pieces_nominal_od: 'Laser - Pup Pieces _ Nominal OD',
  laser_pup_pieces_nominal_wt: 'Laser - Pup Pieces _ Nominal WT',
  laser_pup_pieces_grade: 'Laser - Pup Pieces _ Grade',
  laser_pup_pieces_od_tolerances: 'Laser - Pup Pieces _ OD tolerances',
  laser_pup_pieces_id_tolerances: 'Laser - Pup Pieces _ ID tolerances',
  laser_pup_pieces_wt_tolerances: 'Laser - Pup Pieces _ WT tolerances',
  laser_pup_pieces_id: 'Laser - Pup Pieces _ ID',
  laser_pup_pieces_maximum_od: 'Laser - Pup Pieces _ Maximum OD',
  laser_pup_pieces_minimum_od: 'Laser - Pup Pieces _ Minimum OD',
  laser_pup_pieces_maximum_id: 'Laser - Pup Pieces _ Maximum ID',
  laser_pup_pieces_minimum_id: 'Laser - Pup Pieces _ Minimum ID',
  laser_pup_pieces_maximum_wt: 'Laser - Pup Pieces _ Maximum WT',
  laser_pup_pieces_minimum_wt: 'Laser - Pup Pieces _ Minimum WT',
  laser_pup_pieces_end: 'Laser - Pup Pieces _ END',
  laser_pup_pieces_sct: 'Laser - Pup Pieces _ SCT',
  laser_pup_pieces_id_m1: 'Laser - Pup Pieces _ ID - M1',
  laser_pup_pieces_id_m2: 'Laser - Pup Pieces _ ID - M2',
  laser_pup_pieces_id_m3: 'Laser - Pup Pieces _ ID - M3',
  laser_pup_pieces_id_m4: 'Laser - Pup Pieces _ ID - M4',
  laser_pup_pieces_id_m5: 'Laser - Pup Pieces _ ID - M5',
  laser_pup_pieces_id_m6: 'Laser - Pup Pieces _ ID - M6',
  laser_pup_pieces_id_m7: 'Laser - Pup Pieces _ ID - M7',
  laser_pup_pieces_id_m8: 'Laser - Pup Pieces _ ID - M8',
  laser_pup_pieces_id_avg_: 'Laser - Pup Pieces _ ID - AVG ',
  laser_pup_pieces_id_max_: 'Laser - Pup Pieces _ ID - MAX ',
  laser_pup_pieces_id_min_: 'Laser - Pup Pieces _ ID - MIN ',
  laser_pup_pieces_id_oor_: 'Laser - Pup Pieces _ ID - OOR ',
  laser_pup_pieces_od_m1: 'Laser - Pup Pieces _ OD - M1',
  laser_pup_pieces_od_m2: 'Laser - Pup Pieces _ OD - M2',
  laser_pup_pieces_od_m3: 'Laser - Pup Pieces _ OD - M3',
  laser_pup_pieces_od_m4: 'Laser - Pup Pieces _ OD - M4',
  laser_pup_pieces_od_m5: 'Laser - Pup Pieces _ OD - M5',
  laser_pup_pieces_od_m6: 'Laser - Pup Pieces _ OD - M6',
  laser_pup_pieces_od_m7: 'Laser - Pup Pieces _ OD - M7',
  laser_pup_pieces_od_m8: 'Laser - Pup Pieces _ OD - M8',
  laser_pup_pieces_od_avg_: 'Laser - Pup Pieces _ OD - AVG ',
  laser_pup_pieces_od_max_: 'Laser - Pup Pieces _ OD - MAX ',
  laser_pup_pieces_od_min_: 'Laser - Pup Pieces _ OD - MIN ',
  laser_pup_pieces_od_oor_: 'Laser - Pup Pieces _ OD - OOR ',
  laser_pup_pieces_wt_m1: 'Laser - Pup Pieces _ WT - M1',
  laser_pup_pieces_wt_m2: 'Laser - Pup Pieces _ WT - M2',
  laser_pup_pieces_wt_m3: 'Laser - Pup Pieces _ WT - M3',
  laser_pup_pieces_wt_m4: 'Laser - Pup Pieces _ WT - M4',
  laser_pup_pieces_wt_m5: 'Laser - Pup Pieces _ WT - M5',
  laser_pup_pieces_wt_m6: 'Laser - Pup Pieces _ WT - M6',
  laser_pup_pieces_wt_m7: 'Laser - Pup Pieces _ WT - M7',
  laser_pup_pieces_wt_m8: 'Laser - Pup Pieces _ WT - M8',
  laser_pup_pieces_wt_m9: 'Laser - Pup Pieces _ WT - M9',
  laser_pup_pieces_wt_m10: 'Laser - Pup Pieces _ WT - M10',
  laser_pup_pieces_wt_m11: 'Laser - Pup Pieces _ WT - M11',
  laser_pup_pieces_wt_m12: 'Laser - Pup Pieces _ WT - M12',
  laser_pup_pieces_wt_m13: 'Laser - Pup Pieces _ WT - M13',
  laser_pup_pieces_wt_m14: 'Laser - Pup Pieces _ WT - M14',
  laser_pup_pieces_wt_m15: 'Laser - Pup Pieces _ WT - M15',
  laser_pup_pieces_wt_m16: 'Laser - Pup Pieces _ WT - M16',
  laser_pup_pieces_wt_avg: 'Laser - Pup Pieces _ WT - AVG',
  laser_pup_pieces_wt_max: 'Laser - Pup Pieces _ WT - MAX',
  laser_pup_pieces_wt_min: 'Laser - Pup Pieces _ WT - MIN',
  laser_pup_pieces_wt_ecc: 'Laser - Pup Pieces _ WT - ECC',
  'laser_pup_pieces_roughness_(ra)': 'Laser - Pup Pieces _ Roughness (Ra)',
  laser_pup_pieces_roughness: 'Laser - Pup Pieces _ Roughness',
  laser_pup_pieces_squareness: 'Laser - Pup Pieces _ Squareness',
  laser_pup_pieces_length: 'Laser - Pup Pieces _ Length',
  laser_pup_pieces_visual_inspection_status:
    'Laser - Pup Pieces _ Visual Inspection',
  qtd: 'qtd',
  test_group: 'testGroup',
  laser_pup_pieces_roughness_end_a: 'Laser - Pup Pieces _ Roughness - End A',
  laser_pup_pieces_roughness_end_b: 'Laser - Pup Pieces _ Roughness - End B',
  laser_pup_pieces_squareness_end_a: 'Laser - Pup Pieces _ Squareness - End A',
  laser_pup_pieces_squareness_end_b: 'Laser - Pup Pieces _ Squareness - End B',
  laser_pup_pieces_id_end_a_section_1:
    'Laser - Pup Pieces _ ID - End A - Section 1',
  laser_pup_pieces_id_end_a_section_2:
    'Laser - Pup Pieces _ ID - End A - Section 2',
  laser_pup_pieces_id_end_b_section_1:
    'Laser - Pup Pieces _ ID - End B - Section 1',
  laser_pup_pieces_id_end_b_section_2:
    'Laser - Pup Pieces _ ID - End B - Section 2',
  laser_pup_pieces_end_end_a_section_1:
    'Laser - Pup Pieces _ END - End A - Section 1',
  laser_pup_pieces_end_end_a_section_2:
    'Laser - Pup Pieces _ END - End A - Section 2',
  laser_pup_pieces_end_end_b_section_1:
    'Laser - Pup Pieces _ END - End B - Section 1',
  laser_pup_pieces_end_end_b_section_2:
    'Laser - Pup Pieces _ END - End B - Section 2',
  laser_pup_pieces_sct_end_a_section_1:
    'Laser - Pup Pieces _ SCT - End A - Section 1',
  laser_pup_pieces_sct_end_a_section_2:
    'Laser - Pup Pieces _ SCT - End A - Section 2',
  laser_pup_pieces_sct_end_b_section_1:
    'Laser - Pup Pieces _ SCT - End B - Section 1',
  laser_pup_pieces_sct_end_b_section_2:
    'Laser - Pup Pieces _ SCT - End B - Section 2',
  laser_pup_pieces_grade_end_a_section_1:
    'Laser - Pup Pieces _ Grade - End A - Section 1',
  laser_pup_pieces_grade_end_a_section_2:
    'Laser - Pup Pieces _ Grade - End A - Section 2',
  laser_pup_pieces_grade_end_b_section_1:
    'Laser - Pup Pieces _ Grade - End B - Section 1',
  laser_pup_pieces_grade_end_b_section_2:
    'Laser - Pup Pieces _ Grade - End B - Section 2',
  laser_pup_pieces_id_m1_end_a_section_1:
    'Laser - Pup Pieces _ ID - M1 - End A - Section 1',
  laser_pup_pieces_id_m1_end_a_section_2:
    'Laser - Pup Pieces _ ID - M1 - End A - Section 2',
  laser_pup_pieces_id_m1_end_b_section_1:
    'Laser - Pup Pieces _ ID - M1 - End B - Section 1',
  laser_pup_pieces_id_m1_end_b_section_2:
    'Laser - Pup Pieces _ ID - M1 - End B - Section 2',
  laser_pup_pieces_id_m2_end_a_section_1:
    'Laser - Pup Pieces _ ID - M2 - End A - Section 1',
  laser_pup_pieces_id_m2_end_a_section_2:
    'Laser - Pup Pieces _ ID - M2 - End A - Section 2',
  laser_pup_pieces_id_m2_end_b_section_1:
    'Laser - Pup Pieces _ ID - M2 - End B - Section 1',
  laser_pup_pieces_id_m2_end_b_section_2:
    'Laser - Pup Pieces _ ID - M2 - End B - Section 2',
  laser_pup_pieces_id_m3_end_a_section_1:
    'Laser - Pup Pieces _ ID - M3 - End A - Section 1',
  laser_pup_pieces_id_m3_end_a_section_2:
    'Laser - Pup Pieces _ ID - M3 - End A - Section 2',
  laser_pup_pieces_id_m3_end_b_section_1:
    'Laser - Pup Pieces _ ID - M3 - End B - Section 1',
  laser_pup_pieces_id_m3_end_b_section_2:
    'Laser - Pup Pieces _ ID - M3 - End B - Section 2',
  laser_pup_pieces_id_m4_end_a_section_1:
    'Laser - Pup Pieces _ ID - M4 - End A - Section 1',
  laser_pup_pieces_id_m4_end_a_section_2:
    'Laser - Pup Pieces _ ID - M4 - End A - Section 2',
  laser_pup_pieces_id_m4_end_b_section_1:
    'Laser - Pup Pieces _ ID - M4 - End B - Section 1',
  laser_pup_pieces_id_m4_end_b_section_2:
    'Laser - Pup Pieces _ ID - M4 - End B - Section 2',
  laser_pup_pieces_id_m5_end_a_section_1:
    'Laser - Pup Pieces _ ID - M5 - End A - Section 1',
  laser_pup_pieces_id_m5_end_a_section_2:
    'Laser - Pup Pieces _ ID - M5 - End A - Section 2',
  laser_pup_pieces_id_m5_end_b_section_1:
    'Laser - Pup Pieces _ ID - M5 - End B - Section 1',
  laser_pup_pieces_id_m5_end_b_section_2:
    'Laser - Pup Pieces _ ID - M5 - End B - Section 2',
  laser_pup_pieces_id_m6_end_a_section_1:
    'Laser - Pup Pieces _ ID - M6 - End A - Section 1',
  laser_pup_pieces_id_m6_end_a_section_2:
    'Laser - Pup Pieces _ ID - M6 - End A - Section 2',
  laser_pup_pieces_id_m6_end_b_section_1:
    'Laser - Pup Pieces _ ID - M6 - End B - Section 1',
  laser_pup_pieces_id_m6_end_b_section_2:
    'Laser - Pup Pieces _ ID - M6 - End B - Section 2',
  laser_pup_pieces_id_m7_end_a_section_1:
    'Laser - Pup Pieces _ ID - M7 - End A - Section 1',
  laser_pup_pieces_id_m7_end_a_section_2:
    'Laser - Pup Pieces _ ID - M7 - End A - Section 2',
  laser_pup_pieces_id_m7_end_b_section_1:
    'Laser - Pup Pieces _ ID - M7 - End B - Section 1',
  laser_pup_pieces_id_m7_end_b_section_2:
    'Laser - Pup Pieces _ ID - M7 - End B - Section 2',
  laser_pup_pieces_id_m8_end_a_section_1:
    'Laser - Pup Pieces _ ID - M8 - End A - Section 1',
  laser_pup_pieces_id_m8_end_a_section_2:
    'Laser - Pup Pieces _ ID - M8 - End A - Section 2',
  laser_pup_pieces_id_m8_end_b_section_1:
    'Laser - Pup Pieces _ ID - M8 - End B - Section 1',
  laser_pup_pieces_id_m8_end_b_section_2:
    'Laser - Pup Pieces _ ID - M8 - End B - Section 2',
  laser_pup_pieces_od_m1_end_a_section_1:
    'Laser - Pup Pieces _ OD - M1 - End A - Section 1',
  laser_pup_pieces_od_m1_end_a_section_2:
    'Laser - Pup Pieces _ OD - M1 - End A - Section 2',
  laser_pup_pieces_od_m1_end_b_section_1:
    'Laser - Pup Pieces _ OD - M1 - End B - Section 1',
  laser_pup_pieces_od_m1_end_b_section_2:
    'Laser - Pup Pieces _ OD - M1 - End B - Section 2',
  laser_pup_pieces_od_m2_end_a_section_1:
    'Laser - Pup Pieces _ OD - M2 - End A - Section 1',
  laser_pup_pieces_od_m2_end_a_section_2:
    'Laser - Pup Pieces _ OD - M2 - End A - Section 2',
  laser_pup_pieces_od_m2_end_b_section_1:
    'Laser - Pup Pieces _ OD - M2 - End B - Section 1',
  laser_pup_pieces_od_m2_end_b_section_2:
    'Laser - Pup Pieces _ OD - M2 - End B - Section 2',
  laser_pup_pieces_od_m3_end_a_section_1:
    'Laser - Pup Pieces _ OD - M3 - End A - Section 1',
  laser_pup_pieces_od_m3_end_a_section_2:
    'Laser - Pup Pieces _ OD - M3 - End A - Section 2',
  laser_pup_pieces_od_m3_end_b_section_1:
    'Laser - Pup Pieces _ OD - M3 - End B - Section 1',
  laser_pup_pieces_od_m3_end_b_section_2:
    'Laser - Pup Pieces _ OD - M3 - End B - Section 2',
  laser_pup_pieces_od_m4_end_a_section_1:
    'Laser - Pup Pieces _ OD - M4 - End A - Section 1',
  laser_pup_pieces_od_m4_end_a_section_2:
    'Laser - Pup Pieces _ OD - M4 - End A - Section 2',
  laser_pup_pieces_od_m4_end_b_section_1:
    'Laser - Pup Pieces _ OD - M4 - End B - Section 1',
  laser_pup_pieces_od_m4_end_b_section_2:
    'Laser - Pup Pieces _ OD - M4 - End B - Section 2',
  laser_pup_pieces_od_m5_end_a_section_1:
    'Laser - Pup Pieces _ OD - M5 - End A - Section 1',
  laser_pup_pieces_od_m5_end_a_section_2:
    'Laser - Pup Pieces _ OD - M5 - End A - Section 2',
  laser_pup_pieces_od_m5_end_b_section_1:
    'Laser - Pup Pieces _ OD - M5 - End B - Section 1',
  laser_pup_pieces_od_m5_end_b_section_2:
    'Laser - Pup Pieces _ OD - M5 - End B - Section 2',
  laser_pup_pieces_od_m6_end_a_section_1:
    'Laser - Pup Pieces _ OD - M6 - End A - Section 1',
  laser_pup_pieces_od_m6_end_a_section_2:
    'Laser - Pup Pieces _ OD - M6 - End A - Section 2',
  laser_pup_pieces_od_m6_end_b_section_1:
    'Laser - Pup Pieces _ OD - M6 - End B - Section 1',
  laser_pup_pieces_od_m6_end_b_section_2:
    'Laser - Pup Pieces _ OD - M6 - End B - Section 2',
  laser_pup_pieces_od_m7_end_a_section_1:
    'Laser - Pup Pieces _ OD - M7 - End A - Section 1',
  laser_pup_pieces_od_m7_end_a_section_2:
    'Laser - Pup Pieces _ OD - M7 - End A - Section 2',
  laser_pup_pieces_od_m7_end_b_section_1:
    'Laser - Pup Pieces _ OD - M7 - End B - Section 1',
  laser_pup_pieces_od_m7_end_b_section_2:
    'Laser - Pup Pieces _ OD - M7 - End B - Section 2',
  laser_pup_pieces_od_m8_end_a_section_1:
    'Laser - Pup Pieces _ OD - M8 - End A - Section 1',
  laser_pup_pieces_od_m8_end_a_section_2:
    'Laser - Pup Pieces _ OD - M8 - End A - Section 2',
  laser_pup_pieces_od_m8_end_b_section_1:
    'Laser - Pup Pieces _ OD - M8 - End B - Section 1',
  laser_pup_pieces_od_m8_end_b_section_2:
    'Laser - Pup Pieces _ OD - M8 - End B - Section 2',
  laser_pup_pieces_wt_m1_end_a_section_1:
    'Laser - Pup Pieces _ WT - M1 - End A - Section 1',
  laser_pup_pieces_wt_m1_end_a_section_2:
    'Laser - Pup Pieces _ WT - M1 - End A - Section 2',
  laser_pup_pieces_wt_m1_end_b_section_1:
    'Laser - Pup Pieces _ WT - M1 - End B - Section 1',
  laser_pup_pieces_wt_m1_end_b_section_2:
    'Laser - Pup Pieces _ WT - M1 - End B - Section 2',
  laser_pup_pieces_wt_m2_end_a_section_1:
    'Laser - Pup Pieces _ WT - M2 - End A - Section 1',
  laser_pup_pieces_wt_m2_end_a_section_2:
    'Laser - Pup Pieces _ WT - M2 - End A - Section 2',
  laser_pup_pieces_wt_m2_end_b_section_1:
    'Laser - Pup Pieces _ WT - M2 - End B - Section 1',
  laser_pup_pieces_wt_m2_end_b_section_2:
    'Laser - Pup Pieces _ WT - M2 - End B - Section 2',
  laser_pup_pieces_wt_m3_end_a_section_1:
    'Laser - Pup Pieces _ WT - M3 - End A - Section 1',
  laser_pup_pieces_wt_m3_end_a_section_2:
    'Laser - Pup Pieces _ WT - M3 - End A - Section 2',
  laser_pup_pieces_wt_m3_end_b_section_1:
    'Laser - Pup Pieces _ WT - M3 - End B - Section 1',
  laser_pup_pieces_wt_m3_end_b_section_2:
    'Laser - Pup Pieces _ WT - M3 - End B - Section 2',
  laser_pup_pieces_wt_m4_end_a_section_1:
    'Laser - Pup Pieces _ WT - M4 - End A - Section 1',
  laser_pup_pieces_wt_m4_end_a_section_2:
    'Laser - Pup Pieces _ WT - M4 - End A - Section 2',
  laser_pup_pieces_wt_m4_end_b_section_1:
    'Laser - Pup Pieces _ WT - M4 - End B - Section 1',
  laser_pup_pieces_wt_m4_end_b_section_2:
    'Laser - Pup Pieces _ WT - M4 - End B - Section 2',
  laser_pup_pieces_wt_m5_end_a_section_1:
    'Laser - Pup Pieces _ WT - M5 - End A - Section 1',
  laser_pup_pieces_wt_m5_end_a_section_2:
    'Laser - Pup Pieces _ WT - M5 - End A - Section 2',
  laser_pup_pieces_wt_m5_end_b_section_1:
    'Laser - Pup Pieces _ WT - M5 - End B - Section 1',
  laser_pup_pieces_wt_m5_end_b_section_2:
    'Laser - Pup Pieces _ WT - M5 - End B - Section 2',
  laser_pup_pieces_wt_m6_end_a_section_1:
    'Laser - Pup Pieces _ WT - M6 - End A - Section 1',
  laser_pup_pieces_wt_m6_end_a_section_2:
    'Laser - Pup Pieces _ WT - M6 - End A - Section 2',
  laser_pup_pieces_wt_m6_end_b_section_1:
    'Laser - Pup Pieces _ WT - M6 - End B - Section 1',
  laser_pup_pieces_wt_m6_end_b_section_2:
    'Laser - Pup Pieces _ WT - M6 - End B - Section 2',
  laser_pup_pieces_wt_m7_end_a_section_1:
    'Laser - Pup Pieces _ WT - M7 - End A - Section 1',
  laser_pup_pieces_wt_m7_end_a_section_2:
    'Laser - Pup Pieces _ WT - M7 - End A - Section 2',
  laser_pup_pieces_wt_m7_end_b_section_1:
    'Laser - Pup Pieces _ WT - M7 - End B - Section 1',
  laser_pup_pieces_wt_m7_end_b_section_2:
    'Laser - Pup Pieces _ WT - M7 - End B - Section 2',
  laser_pup_pieces_wt_m8_end_a_section_1:
    'Laser - Pup Pieces _ WT - M8 - End A - Section 1',
  laser_pup_pieces_wt_m8_end_a_section_2:
    'Laser - Pup Pieces _ WT - M8 - End A - Section 2',
  laser_pup_pieces_wt_m8_end_b_section_1:
    'Laser - Pup Pieces _ WT - M8 - End B - Section 1',
  laser_pup_pieces_wt_m8_end_b_section_2:
    'Laser - Pup Pieces _ WT - M8 - End B - Section 2',
  laser_pup_pieces_wt_m9_end_a_section_1:
    'Laser - Pup Pieces _ WT - M9 - End A - Section 1',
  laser_pup_pieces_wt_m9_end_a_section_2:
    'Laser - Pup Pieces _ WT - M9 - End A - Section 2',
  laser_pup_pieces_wt_m9_end_b_section_1:
    'Laser - Pup Pieces _ WT - M9 - End B - Section 1',
  laser_pup_pieces_wt_m9_end_b_section_2:
    'Laser - Pup Pieces _ WT - M9 - End B - Section 2',
  laser_pup_pieces_wt_avg_end_a_section_1:
    'Laser - Pup Pieces _ WT - AVG - End A - Section 1',
  laser_pup_pieces_wt_avg_end_a_section_2:
    'Laser - Pup Pieces _ WT - AVG - End A - Section 2',
  laser_pup_pieces_wt_avg_end_b_section_1:
    'Laser - Pup Pieces _ WT - AVG - End B - Section 1',
  laser_pup_pieces_wt_avg_end_b_section_2:
    'Laser - Pup Pieces _ WT - AVG - End B - Section 2',
  laser_pup_pieces_wt_ecc_end_a_section_1:
    'Laser - Pup Pieces _ WT - ECC - End A - Section 1',
  laser_pup_pieces_wt_ecc_end_a_section_2:
    'Laser - Pup Pieces _ WT - ECC - End A - Section 2',
  laser_pup_pieces_wt_ecc_end_b_section_1:
    'Laser - Pup Pieces _ WT - ECC - End B - Section 1',
  laser_pup_pieces_wt_ecc_end_b_section_2:
    'Laser - Pup Pieces _ WT - ECC - End B - Section 2',
  laser_pup_pieces_wt_m10_end_a_section_1:
    'Laser - Pup Pieces _ WT - M10 - End A - Section 1',
  laser_pup_pieces_wt_m10_end_a_section_2:
    'Laser - Pup Pieces _ WT - M10 - End A - Section 2',
  laser_pup_pieces_wt_m10_end_b_section_1:
    'Laser - Pup Pieces _ WT - M10 - End B - Section 1',
  laser_pup_pieces_wt_m10_end_b_section_2:
    'Laser - Pup Pieces _ WT - M10 - End B - Section 2',
  laser_pup_pieces_wt_m11_end_a_section_1:
    'Laser - Pup Pieces _ WT - M11 - End A - Section 1',
  laser_pup_pieces_wt_m11_end_a_section_2:
    'Laser - Pup Pieces _ WT - M11 - End A - Section 2',
  laser_pup_pieces_wt_m11_end_b_section_1:
    'Laser - Pup Pieces _ WT - M11 - End B - Section 1',
  laser_pup_pieces_wt_m11_end_b_section_2:
    'Laser - Pup Pieces _ WT - M11 - End B - Section 2',
  laser_pup_pieces_wt_m12_end_a_section_1:
    'Laser - Pup Pieces _ WT - M12 - End A - Section 1',
  laser_pup_pieces_wt_m12_end_a_section_2:
    'Laser - Pup Pieces _ WT - M12 - End A - Section 2',
  laser_pup_pieces_wt_m12_end_b_section_1:
    'Laser - Pup Pieces _ WT - M12 - End B - Section 1',
  laser_pup_pieces_wt_m12_end_b_section_2:
    'Laser - Pup Pieces _ WT - M12 - End B - Section 2',
  laser_pup_pieces_wt_m13_end_a_section_1:
    'Laser - Pup Pieces _ WT - M13 - End A - Section 1',
  laser_pup_pieces_wt_m13_end_a_section_2:
    'Laser - Pup Pieces _ WT - M13 - End A - Section 2',
  laser_pup_pieces_wt_m13_end_b_section_1:
    'Laser - Pup Pieces _ WT - M13 - End B - Section 1',
  laser_pup_pieces_wt_m13_end_b_section_2:
    'Laser - Pup Pieces _ WT - M13 - End B - Section 2',
  laser_pup_pieces_wt_m14_end_a_section_1:
    'Laser - Pup Pieces _ WT - M14 - End A - Section 1',
  laser_pup_pieces_wt_m14_end_a_section_2:
    'Laser - Pup Pieces _ WT - M14 - End A - Section 2',
  laser_pup_pieces_wt_m14_end_b_section_1:
    'Laser - Pup Pieces _ WT - M14 - End B - Section 1',
  laser_pup_pieces_wt_m14_end_b_section_2:
    'Laser - Pup Pieces _ WT - M14 - End B - Section 2',
  laser_pup_pieces_wt_m15_end_a_section_1:
    'Laser - Pup Pieces _ WT - M15 - End A - Section 1',
  laser_pup_pieces_wt_m15_end_a_section_2:
    'Laser - Pup Pieces _ WT - M15 - End A - Section 2',
  laser_pup_pieces_wt_m15_end_b_section_1:
    'Laser - Pup Pieces _ WT - M15 - End B - Section 1',
  laser_pup_pieces_wt_m15_end_b_section_2:
    'Laser - Pup Pieces _ WT - M15 - End B - Section 2',
  laser_pup_pieces_wt_m16_end_a_section_1:
    'Laser - Pup Pieces _ WT - M16 - End A - Section 1',
  laser_pup_pieces_wt_m16_end_a_section_2:
    'Laser - Pup Pieces _ WT - M16 - End A - Section 2',
  laser_pup_pieces_wt_m16_end_b_section_1:
    'Laser - Pup Pieces _ WT - M16 - End B - Section 1',
  laser_pup_pieces_wt_m16_end_b_section_2:
    'Laser - Pup Pieces _ WT - M16 - End B - Section 2',
  laser_pup_pieces_wt_max_end_a_section_1:
    'Laser - Pup Pieces _ WT - MAX - End A - Section 1',
  laser_pup_pieces_wt_max_end_a_section_2:
    'Laser - Pup Pieces _ WT - MAX - End A - Section 2',
  laser_pup_pieces_wt_max_end_b_section_1:
    'Laser - Pup Pieces _ WT - MAX - End B - Section 1',
  laser_pup_pieces_wt_max_end_b_section_2:
    'Laser - Pup Pieces _ WT - MAX - End B - Section 2',
  laser_pup_pieces_wt_min_end_a_section_1:
    'Laser - Pup Pieces _ WT - MIN - End A - Section 1',
  laser_pup_pieces_wt_min_end_a_section_2:
    'Laser - Pup Pieces _ WT - MIN - End A - Section 2',
  laser_pup_pieces_wt_min_end_b_section_1:
    'Laser - Pup Pieces _ WT - MIN - End B - Section 1',
  laser_pup_pieces_wt_min_end_b_section_2:
    'Laser - Pup Pieces _ WT - MIN - End B - Section 2',
  laser_pup_pieces_id_avg_end_a_section_1:
    'Laser - Pup Pieces _ ID - AVG - End A - Section 1',
  laser_pup_pieces_id_avg_end_a_section_2:
    'Laser - Pup Pieces _ ID - AVG - End A - Section 2',
  laser_pup_pieces_id_avg_end_b_section_1:
    'Laser - Pup Pieces _ ID - AVG - End B - Section 1',
  laser_pup_pieces_id_avg_end_b_section_2:
    'Laser - Pup Pieces _ ID - AVG - End B - Section 2',
  laser_pup_pieces_id_max_end_a_section_1:
    'Laser - Pup Pieces _ ID - MAX - End A - Section 1',
  laser_pup_pieces_id_max_end_a_section_2:
    'Laser - Pup Pieces _ ID - MAX - End A - Section 2',
  laser_pup_pieces_id_max_end_b_section_1:
    'Laser - Pup Pieces _ ID - MAX - End B - Section 1',
  laser_pup_pieces_id_max_end_b_section_2:
    'Laser - Pup Pieces _ ID - MAX - End B - Section 2',
  laser_pup_pieces_id_min_end_a_section_1:
    'Laser - Pup Pieces _ ID - MIN - End A - Section 1',
  laser_pup_pieces_id_min_end_a_section_2:
    'Laser - Pup Pieces _ ID - MIN - End A - Section 2',
  laser_pup_pieces_id_min_end_b_section_1:
    'Laser - Pup Pieces _ ID - MIN - End B - Section 1',
  laser_pup_pieces_id_min_end_b_section_2:
    'Laser - Pup Pieces _ ID - MIN - End B - Section 2',
  laser_pup_pieces_id_oor_end_a_section_1:
    'Laser - Pup Pieces _ ID - OOR - End A - Section 1',
  laser_pup_pieces_id_oor_end_a_section_2:
    'Laser - Pup Pieces _ ID - OOR - End A - Section 2',
  laser_pup_pieces_id_oor_end_b_section_1:
    'Laser - Pup Pieces _ ID - OOR - End B - Section 1',
  laser_pup_pieces_id_oor_end_b_section_2:
    'Laser - Pup Pieces _ ID - OOR - End B - Section 2',
  laser_pup_pieces_od_avg_end_a_section_1:
    'Laser - Pup Pieces _ OD - AVG - End A - Section 1',
  laser_pup_pieces_od_avg_end_a_section_2:
    'Laser - Pup Pieces _ OD - AVG - End A - Section 2',
  laser_pup_pieces_od_avg_end_b_section_1:
    'Laser - Pup Pieces _ OD - AVG - End B - Section 1',
  laser_pup_pieces_od_avg_end_b_section_2:
    'Laser - Pup Pieces _ OD - AVG - End B - Section 2',
  laser_pup_pieces_od_max_end_a_section_1:
    'Laser - Pup Pieces _ OD - MAX - End A - Section 1',
  laser_pup_pieces_od_max_end_a_section_2:
    'Laser - Pup Pieces _ OD - MAX - End A - Section 2',
  laser_pup_pieces_od_max_end_b_section_1:
    'Laser - Pup Pieces _ OD - MAX - End B - Section 1',
  laser_pup_pieces_od_max_end_b_section_2:
    'Laser - Pup Pieces _ OD - MAX - End B - Section 2',
  laser_pup_pieces_od_min_end_a_section_1:
    'Laser - Pup Pieces _ OD - MIN - End A - Section 1',
  laser_pup_pieces_od_min_end_a_section_2:
    'Laser - Pup Pieces _ OD - MIN - End A - Section 2',
  laser_pup_pieces_od_min_end_b_section_1:
    'Laser - Pup Pieces _ OD - MIN - End B - Section 1',
  laser_pup_pieces_od_min_end_b_section_2:
    'Laser - Pup Pieces _ OD - MIN - End B - Section 2',
  laser_pup_pieces_od_oor_end_a_section_1:
    'Laser - Pup Pieces _ OD - OOR - End A - Section 1',
  laser_pup_pieces_od_oor_end_a_section_2:
    'Laser - Pup Pieces _ OD - OOR - End A - Section 2',
  laser_pup_pieces_od_oor_end_b_section_1:
    'Laser - Pup Pieces _ OD - OOR - End B - Section 1',
  laser_pup_pieces_od_oor_end_b_section_2:
    'Laser - Pup Pieces _ OD - OOR - End B - Section 2',
  laser_pup_pieces_minimum_id_end_a_section_1:
    'Laser - Pup Pieces _ Minimum ID - End A - Section 1',
  laser_pup_pieces_minimum_id_end_a_section_2:
    'Laser - Pup Pieces _ Minimum ID - End A - Section 2',
  laser_pup_pieces_minimum_id_end_b_section_1:
    'Laser - Pup Pieces _ Minimum ID - End B - Section 1',
  laser_pup_pieces_minimum_id_end_b_section_2:
    'Laser - Pup Pieces _ Minimum ID - End B - Section 2',
  laser_pup_pieces_nominal_od_end_a_section_1:
    'Laser - Pup Pieces _ Nominal OD - End A - Section 1',
  laser_pup_pieces_nominal_od_end_a_section_2:
    'Laser - Pup Pieces _ Nominal OD - End A - Section 2',
  laser_pup_pieces_nominal_od_end_b_section_1:
    'Laser - Pup Pieces _ Nominal OD - End B - Section 1',
  laser_pup_pieces_nominal_od_end_b_section_2:
    'Laser - Pup Pieces _ Nominal OD - End B - Section 2',
  laser_pup_pieces_nominal_wt_end_a_section_1:
    'Laser - Pup Pieces _ Nominal WT - End A - Section 1',
  laser_pup_pieces_nominal_wt_end_a_section_2:
    'Laser - Pup Pieces _ Nominal WT - End A - Section 2',
  laser_pup_pieces_nominal_wt_end_b_section_1:
    'Laser - Pup Pieces _ Nominal WT - End B - Section 1',
  laser_pup_pieces_nominal_wt_end_b_section_2:
    'Laser - Pup Pieces _ Nominal WT - End B - Section 2',
  laser_pup_pieces_maximum_id_end_a_section_1:
    'Laser - Pup Pieces _ Maximum  ID - End A - Section 1',
  laser_pup_pieces_maximum_id_end_a_section_2:
    'Laser - Pup Pieces _ Maximum  ID - End A - Section 2',
  laser_pup_pieces_maximum_id_end_b_section_1:
    'Laser - Pup Pieces _ Maximum  ID - End B - Section 1',
  laser_pup_pieces_maximum_id_end_b_section_2:
    'Laser - Pup Pieces _ Maximum  ID - End B - Section 2',
  laser_pup_pieces_maximum_od_end_a_section_1:
    'Laser - Pup Pieces _ Maximum  OD - End A - Section 1',
  laser_pup_pieces_maximum_od_end_a_section_2:
    'Laser - Pup Pieces _ Maximum  OD - End A - Section 2',
  laser_pup_pieces_maximum_od_end_b_section_1:
    'Laser - Pup Pieces _ Maximum  OD - End B - Section 1',
  laser_pup_pieces_maximum_od_end_b_section_2:
    'Laser - Pup Pieces _ Maximum  OD - End B - Section 2',
  laser_pup_pieces_maximum_wt_end_a_section_1:
    'Laser - Pup Pieces _ Maximum  WT - End A - Section 1',
  laser_pup_pieces_maximum_wt_end_a_section_2:
    'Laser - Pup Pieces _ Maximum  WT - End A - Section 2',
  laser_pup_pieces_maximum_wt_end_b_section_1:
    'Laser - Pup Pieces _ Maximum  WT - End B - Section 1',
  laser_pup_pieces_maximum_wt_end_b_section_2:
    'Laser - Pup Pieces _ Maximum  WT - End B - Section 2',
  laser_pup_pieces_minimum_od_end_a_section_1:
    'Laser - Pup Pieces _ Minimum  OD - End A - Section 1',
  laser_pup_pieces_minimum_od_end_a_section_2:
    'Laser - Pup Pieces _ Minimum  OD - End A - Section 2',
  laser_pup_pieces_minimum_od_end_b_section_1:
    'Laser - Pup Pieces _ Minimum  OD - End B - Section 1',
  laser_pup_pieces_minimum_od_end_b_section_2:
    'Laser - Pup Pieces _ Minimum  OD - End B - Section 2',
  laser_pup_pieces_minimum_wt_end_a_section_1:
    'Laser - Pup Pieces _ Minimum  WT - End A - Section 1',
  laser_pup_pieces_minimum_wt_end_a_section_2:
    'Laser - Pup Pieces _ Minimum  WT - End A - Section 2',
  laser_pup_pieces_minimum_wt_end_b_section_1:
    'Laser - Pup Pieces _ Minimum  WT - End B - Section 1',
  laser_pup_pieces_minimum_wt_end_b_section_2:
    'Laser - Pup Pieces _ Minimum  WT - End B - Section 2',
  laser_pup_pieces_id_tolerances_end_a_section_1:
    'Laser - Pup Pieces _ ID tolerances - End A - Section 1',
  laser_pup_pieces_id_tolerances_end_a_section_2:
    'Laser - Pup Pieces _ ID tolerances - End A - Section 2',
  laser_pup_pieces_id_tolerances_end_b_section_1:
    'Laser - Pup Pieces _ ID tolerances - End B - Section 1',
  laser_pup_pieces_id_tolerances_end_b_section_2:
    'Laser - Pup Pieces _ ID tolerances - End B - Section 2',
  laser_pup_pieces_od_tolerances_end_a_section_1:
    'Laser - Pup Pieces _ OD tolerances - End A - Section 1',
  laser_pup_pieces_od_tolerances_end_a_section_2:
    'Laser - Pup Pieces _ OD tolerances - End A - Section 2',
  laser_pup_pieces_od_tolerances_end_b_section_1:
    'Laser - Pup Pieces _ OD tolerances - End B - Section 1',
  laser_pup_pieces_od_tolerances_end_b_section_2:
    'Laser - Pup Pieces _ OD tolerances - End B - Section 2',
  laser_pup_pieces_wt_tolerances_end_a_section_1:
    'Laser - Pup Pieces _ WT tolerances - End A - Section 1',
  laser_pup_pieces_wt_tolerances_end_a_section_2:
    'Laser - Pup Pieces _ WT tolerances - End A - Section 2',
  laser_pup_pieces_wt_tolerances_end_b_section_1:
    'Laser - Pup Pieces _ WT tolerances - End B - Section 1',
  laser_pup_pieces_wt_tolerances_end_b_section_2:
    'Laser - Pup Pieces _ WT tolerances - End B - Section 2',
  laser_pup_pieces_project_number_end_a_section_1:
    'Laser - Pup Pieces _ Project - Number - End A - Section 1',
  laser_pup_pieces_project_number_end_a_section_2:
    'Laser - Pup Pieces _ Project - Number - End A - Section 2',
  laser_pup_pieces_project_number_end_b_section_1:
    'Laser - Pup Pieces _ Project - Number - End B - Section 1',
  laser_pup_pieces_project_number_end_b_section_2:
    'Laser - Pup Pieces _ Project - Number - End B - Section 2',
  laser_pup_pieces_project_description_end_a_section_1:
    'Laser - Pup Pieces _ Project - Description - End A - Section 1',
  laser_pup_pieces_project_description_end_a_section_2:
    'Laser - Pup Pieces _ Project - Description - End A - Section 2',
  laser_pup_pieces_project_description_end_b_section_1:
    'Laser - Pup Pieces _ Project - Description - End B - Section 1',
  laser_pup_pieces_project_description_end_b_section_2:
    'Laser - Pup Pieces _ Project - Description - End B - Section 2',
  laser_pup_pieces_customer_description_end_a_section_1:
    'Laser - Pup Pieces _ Customer - Description - End A - Section 1',
  laser_pup_pieces_customer_description_end_a_section_2:
    'Laser - Pup Pieces _ Customer - Description - End A - Section 2',
  laser_pup_pieces_customer_description_end_b_section_1:
    'Laser - Pup Pieces _ Customer - Description - End B - Section 1',
  laser_pup_pieces_customer_description_end_b_section_2:
    'Laser - Pup Pieces _ Customer - Description - End B - Section 2',
  oder: 'Oder',
  dimensional_file_name: 'Dimensional _ File Name',
  dimensional_top_inspection_time: 'Dimensional _ Top - Inspection time',
  'dimensional_customer_purschase_order_/_item':
    'Dimensional _ Customer Purschase Order / Item',
  laser_flex_line_end_end_a_section_1:
    'Laser - Flex Line _ END - End A - Section 1',
  laser_flex_line_end_end_a_section_2:
    'Laser - Flex Line _ END - End A - Section 2',
  laser_flex_line_end_end_b_section_1:
    'Laser - Flex Line _ END - End B - Section 1',
  laser_flex_line_end_end_b_section_2:
    'Laser - Flex Line _ END - End B - Section 2',
  laser_flex_line_sct_end_a_section_1:
    'Laser - Flex Line _ SCT - End A - Section 1',
  laser_flex_line_sct_end_a_section_2:
    'Laser - Flex Line _ SCT - End A - Section 2',
  laser_flex_line_sct_end_b_section_1:
    'Laser - Flex Line _ SCT - End B - Section 1',
  laser_flex_line_sct_end_b_section_2:
    'Laser - Flex Line _ SCT - End B - Section 2',
  laser_flex_line_id_m1_end_a_section_1:
    'Laser - Flex Line _ ID - M1 - End A - Section 1',
  laser_flex_line_id_m1_end_a_section_2:
    'Laser - Flex Line _ ID - M1 - End A - Section 2',
  laser_flex_line_id_m1_end_b_section_1:
    'Laser - Flex Line _ ID - M1 - End B - Section 1',
  laser_flex_line_id_m1_end_b_section_2:
    'Laser - Flex Line _ ID - M1 - End B - Section 2',
  laser_flex_line_id_m2_end_a_section_1:
    'Laser - Flex Line _ ID - M2 - End A - Section 1',
  laser_flex_line_id_m2_end_a_section_2:
    'Laser - Flex Line _ ID - M2 - End A - Section 2',
  laser_flex_line_id_m2_end_b_section_1:
    'Laser - Flex Line _ ID - M2 - End B - Section 1',
  laser_flex_line_id_m2_end_b_section_2:
    'Laser - Flex Line _ ID - M2 - End B - Section 2',
  laser_flex_line_id_m3_end_a_section_1:
    'Laser - Flex Line _ ID - M3 - End A - Section 1',
  laser_flex_line_id_m3_end_a_section_2:
    'Laser - Flex Line _ ID - M3 - End A - Section 2',
  laser_flex_line_id_m3_end_b_section_1:
    'Laser - Flex Line _ ID - M3 - End B - Section 1',
  laser_flex_line_id_m3_end_b_section_2:
    'Laser - Flex Line _ ID - M3 - End B - Section 2',
  laser_flex_line_id_m4_end_a_section_1:
    'Laser - Flex Line _ ID - M4 - End A - Section 1',
  laser_flex_line_id_m4_end_a_section_2:
    'Laser - Flex Line _ ID - M4 - End A - Section 2',
  laser_flex_line_id_m4_end_b_section_1:
    'Laser - Flex Line _ ID - M4 - End B - Section 1',
  laser_flex_line_id_m4_end_b_section_2:
    'Laser - Flex Line _ ID - M4 - End B - Section 2',
  laser_flex_line_id_m5_end_a_section_1:
    'Laser - Flex Line _ ID - M5 - End A - Section 1',
  laser_flex_line_id_m5_end_a_section_2:
    'Laser - Flex Line _ ID - M5 - End A - Section 2',
  laser_flex_line_id_m5_end_b_section_1:
    'Laser - Flex Line _ ID - M5 - End B - Section 1',
  laser_flex_line_id_m5_end_b_section_2:
    'Laser - Flex Line _ ID - M5 - End B - Section 2',
  laser_flex_line_id_m6_end_a_section_1:
    'Laser - Flex Line _ ID - M6 - End A - Section 1',
  laser_flex_line_id_m6_end_a_section_2:
    'Laser - Flex Line _ ID - M6 - End A - Section 2',
  laser_flex_line_id_m6_end_b_section_1:
    'Laser - Flex Line _ ID - M6 - End B - Section 1',
  laser_flex_line_id_m6_end_b_section_2:
    'Laser - Flex Line _ ID - M6 - End B - Section 2',
  laser_flex_line_id_m7_end_a_section_1:
    'Laser - Flex Line _ ID - M7 - End A - Section 1',
  laser_flex_line_id_m7_end_a_section_2:
    'Laser - Flex Line _ ID - M7 - End A - Section 2',
  laser_flex_line_id_m7_end_b_section_1:
    'Laser - Flex Line _ ID - M7 - End B - Section 1',
  laser_flex_line_id_m7_end_b_section_2:
    'Laser - Flex Line _ ID - M7 - End B - Section 2',
  laser_flex_line_id_m8_end_a_section_1:
    'Laser - Flex Line _ ID - M8 - End A - Section 1',
  laser_flex_line_id_m8_end_a_section_2:
    'Laser - Flex Line _ ID - M8 - End A - Section 2',
  laser_flex_line_id_m8_end_b_section_1:
    'Laser - Flex Line _ ID - M8 - End B - Section 1',
  laser_flex_line_id_m8_end_b_section_2:
    'Laser - Flex Line _ ID - M8 - End B - Section 2',
  laser_flex_line_od_m1_end_a_section_1:
    'Laser - Flex Line _ OD - M1 - End A - Section 1',
  laser_flex_line_od_m1_end_a_section_2:
    'Laser - Flex Line _ OD - M1 - End A - Section 2',
  laser_flex_line_od_m1_end_b_section_1:
    'Laser - Flex Line _ OD - M1 - End B - Section 1',
  laser_flex_line_od_m1_end_b_section_2:
    'Laser - Flex Line _ OD - M1 - End B - Section 2',
  laser_flex_line_od_m2_end_a_section_1:
    'Laser - Flex Line _ OD - M2 - End A - Section 1',
  laser_flex_line_od_m2_end_a_section_2:
    'Laser - Flex Line _ OD - M2 - End A - Section 2',
  laser_flex_line_od_m2_end_b_section_1:
    'Laser - Flex Line _ OD - M2 - End B - Section 1',
  laser_flex_line_od_m2_end_b_section_2:
    'Laser - Flex Line _ OD - M2 - End B - Section 2',
  laser_flex_line_od_m3_end_a_section_1:
    'Laser - Flex Line _ OD - M3 - End A - Section 1',
  laser_flex_line_od_m3_end_a_section_2:
    'Laser - Flex Line _ OD - M3 - End A - Section 2',
  laser_flex_line_od_m3_end_b_section_1:
    'Laser - Flex Line _ OD - M3 - End B - Section 1',
  laser_flex_line_od_m3_end_b_section_2:
    'Laser - Flex Line _ OD - M3 - End B - Section 2',
  laser_flex_line_od_m4_end_a_section_1:
    'Laser - Flex Line _ OD - M4 - End A - Section 1',
  laser_flex_line_od_m4_end_a_section_2:
    'Laser - Flex Line _ OD - M4 - End A - Section 2',
  laser_flex_line_od_m4_end_b_section_1:
    'Laser - Flex Line _ OD - M4 - End B - Section 1',
  laser_flex_line_od_m4_end_b_section_2:
    'Laser - Flex Line _ OD - M4 - End B - Section 2',
  laser_flex_line_od_m5_end_a_section_1:
    'Laser - Flex Line _ OD - M5 - End A - Section 1',
  laser_flex_line_od_m5_end_a_section_2:
    'Laser - Flex Line _ OD - M5 - End A - Section 2',
  laser_flex_line_od_m5_end_b_section_1:
    'Laser - Flex Line _ OD - M5 - End B - Section 1',
  laser_flex_line_od_m5_end_b_section_2:
    'Laser - Flex Line _ OD - M5 - End B - Section 2',
  laser_flex_line_od_m6_end_a_section_1:
    'Laser - Flex Line _ OD - M6 - End A - Section 1',
  laser_flex_line_od_m6_end_a_section_2:
    'Laser - Flex Line _ OD - M6 - End A - Section 2',
  laser_flex_line_od_m6_end_b_section_1:
    'Laser - Flex Line _ OD - M6 - End B - Section 1',
  laser_flex_line_od_m6_end_b_section_2:
    'Laser - Flex Line _ OD - M6 - End B - Section 2',
  laser_flex_line_od_m7_end_a_section_1:
    'Laser - Flex Line _ OD - M7 - End A - Section 1',
  laser_flex_line_od_m7_end_a_section_2:
    'Laser - Flex Line _ OD - M7 - End A - Section 2',
  laser_flex_line_od_m7_end_b_section_1:
    'Laser - Flex Line _ OD - M7 - End B - Section 1',
  laser_flex_line_od_m7_end_b_section_2:
    'Laser - Flex Line _ OD - M7 - End B - Section 2',
  laser_flex_line_od_m8_end_a_section_1:
    'Laser - Flex Line _ OD - M8 - End A - Section 1',
  laser_flex_line_od_m8_end_a_section_2:
    'Laser - Flex Line _ OD - M8 - End A - Section 2',
  laser_flex_line_od_m8_end_b_section_1:
    'Laser - Flex Line _ OD - M8 - End B - Section 1',
  laser_flex_line_od_m8_end_b_section_2:
    'Laser - Flex Line _ OD - M8 - End B - Section 2',
  laser_flex_line_wt_m2_end_a_section_1:
    'Laser - Flex Line _ WT - M2 - End A - Section 1',
  laser_flex_line_wt_m2_end_a_section_2:
    'Laser - Flex Line _ WT - M2 - End A - Section 2',
  laser_flex_line_wt_m2_end_b_section_1:
    'Laser - Flex Line _ WT - M2 - End B - Section 1',
  laser_flex_line_wt_m2_end_b_section_2:
    'Laser - Flex Line _ WT - M2 - End B - Section 2',
  laser_flex_line_wt_m3_end_a_section_1:
    'Laser - Flex Line _ WT - M3 - End A - Section 1',
  laser_flex_line_wt_m3_end_a_section_2:
    'Laser - Flex Line _ WT - M3 - End A - Section 2',
  laser_flex_line_wt_m3_end_b_section_1:
    'Laser - Flex Line _ WT - M3 - End B - Section 1',
  laser_flex_line_wt_m3_end_b_section_2:
    'Laser - Flex Line _ WT - M3 - End B - Section 2',
  laser_flex_line_wt_m4_end_a_section_1:
    'Laser - Flex Line _ WT - M4 - End A - Section 1',
  laser_flex_line_wt_m4_end_a_section_2:
    'Laser - Flex Line _ WT - M4 - End A - Section 2',
  laser_flex_line_wt_m4_end_b_section_1:
    'Laser - Flex Line _ WT - M4 - End B - Section 1',
  laser_flex_line_wt_m4_end_b_section_2:
    'Laser - Flex Line _ WT - M4 - End B - Section 2',
  laser_flex_line_wt_m5_end_a_section_1:
    'Laser - Flex Line _ WT - M5 - End A - Section 1',
  laser_flex_line_wt_m5_end_a_section_2:
    'Laser - Flex Line _ WT - M5 - End A - Section 2',
  laser_flex_line_wt_m5_end_b_section_1:
    'Laser - Flex Line _ WT - M5 - End B - Section 1',
  laser_flex_line_wt_m5_end_b_section_2:
    'Laser - Flex Line _ WT - M5 - End B - Section 2',
  laser_flex_line_wt_m6_end_a_section_1:
    'Laser - Flex Line _ WT - M6 - End A - Section 1',
  laser_flex_line_wt_m6_end_a_section_2:
    'Laser - Flex Line _ WT - M6 - End A - Section 2',
  laser_flex_line_wt_m6_end_b_section_1:
    'Laser - Flex Line _ WT - M6 - End B - Section 1',
  laser_flex_line_wt_m6_end_b_section_2:
    'Laser - Flex Line _ WT - M6 - End B - Section 2',
  laser_flex_line_wt_m7_end_a_section_1:
    'Laser - Flex Line _ WT - M7 - End A - Section 1',
  laser_flex_line_wt_m7_end_a_section_2:
    'Laser - Flex Line _ WT - M7 - End A - Section 2',
  laser_flex_line_wt_m7_end_b_section_1:
    'Laser - Flex Line _ WT - M7 - End B - Section 1',
  laser_flex_line_wt_m7_end_b_section_2:
    'Laser - Flex Line _ WT - M7 - End B - Section 2',
  laser_flex_line_wt_m8_end_a_section_1:
    'Laser - Flex Line _ WT - M8 - End A - Section 1',
  laser_flex_line_wt_m8_end_a_section_2:
    'Laser - Flex Line _ WT - M8 - End A - Section 2',
  laser_flex_line_wt_m8_end_b_section_1:
    'Laser - Flex Line _ WT - M8 - End B - Section 1',
  laser_flex_line_wt_m8_end_b_section_2:
    'Laser - Flex Line _ WT - M8 - End B - Section 2',
  laser_flex_line_wt_m9_end_a_section_1:
    'Laser - Flex Line _ WT - M9 - End A - Section 1',
  laser_flex_line_wt_m9_end_a_section_2:
    'Laser - Flex Line _ WT - M9 - End A - Section 2',
  laser_flex_line_wt_m9_end_b_section_1:
    'Laser - Flex Line _ WT - M9 - End B - Section 1',
  laser_flex_line_wt_m9_end_b_section_2:
    'Laser - Flex Line _ WT - M9 - End B - Section 2',
  laser_flex_line_id_avg_end_a_section_1:
    'Laser - Flex Line _ ID - AVG - End A - Section 1',
  laser_flex_line_id_avg_end_a_section_2:
    'Laser - Flex Line _ ID - AVG - End A - Section 2',
  laser_flex_line_id_avg_end_b_section_1:
    'Laser - Flex Line _ ID - AVG - End B - Section 1',
  laser_flex_line_id_avg_end_b_section_2:
    'Laser - Flex Line _ ID - AVG - End B - Section 2',
  laser_flex_line_id_max_end_a_section_1:
    'Laser - Flex Line _ ID - MAX - End A - Section 1',
  laser_flex_line_id_max_end_a_section_2:
    'Laser - Flex Line _ ID - MAX - End A - Section 2',
  laser_flex_line_id_max_end_b_section_1:
    'Laser - Flex Line _ ID - MAX - End B - Section 1',
  laser_flex_line_id_max_end_b_section_2:
    'Laser - Flex Line _ ID - MAX - End B - Section 2',
  laser_flex_line_id_min_end_a_section_1:
    'Laser - Flex Line _ ID - MIN - End A - Section 1',
  laser_flex_line_id_min_end_a_section_2:
    'Laser - Flex Line _ ID - MIN - End A - Section 2',
  laser_flex_line_id_min_end_b_section_1:
    'Laser - Flex Line _ ID - MIN - End B - Section 1',
  laser_flex_line_id_min_end_b_section_2:
    'Laser - Flex Line _ ID - MIN - End B - Section 2',
  laser_flex_line_id_oor_end_a_section_1:
    'Laser - Flex Line _ ID - OOR - End A - Section 1',
  laser_flex_line_id_oor_end_a_section_2:
    'Laser - Flex Line _ ID - OOR - End A - Section 2',
  laser_flex_line_id_oor_end_b_section_1:
    'Laser - Flex Line _ ID - OOR - End B - Section 1',
  laser_flex_line_id_oor_end_b_section_2:
    'Laser - Flex Line _ ID - OOR - End B - Section 2',
  laser_flex_line_od_avg_end_a_section_1:
    'Laser - Flex Line _ OD - AVG - End A - Section 1',
  laser_flex_line_od_avg_end_a_section_2:
    'Laser - Flex Line _ OD - AVG - End A - Section 2',
  laser_flex_line_od_avg_end_b_section_1:
    'Laser - Flex Line _ OD - AVG - End B - Section 1',
  laser_flex_line_od_avg_end_b_section_2:
    'Laser - Flex Line _ OD - AVG - End B - Section 2',
  laser_flex_line_od_max_end_a_section_1:
    'Laser - Flex Line _ OD - MAX - End A - Section 1',
  laser_flex_line_od_max_end_a_section_2:
    'Laser - Flex Line _ OD - MAX - End A - Section 2',
  laser_flex_line_od_max_end_b_section_1:
    'Laser - Flex Line _ OD - MAX - End B - Section 1',
  laser_flex_line_od_max_end_b_section_2:
    'Laser - Flex Line _ OD - MAX - End B - Section 2',
  laser_flex_line_od_min_end_a_section_1:
    'Laser - Flex Line _ OD - MIN - End A - Section 1',
  laser_flex_line_od_min_end_a_section_2:
    'Laser - Flex Line _ OD - MIN - End A - Section 2',
  laser_flex_line_od_min_end_b_section_1:
    'Laser - Flex Line _ OD - MIN - End B - Section 1',
  laser_flex_line_od_min_end_b_section_2:
    'Laser - Flex Line _ OD - MIN - End B - Section 2',
  laser_flex_line_od_oor_end_a_section_1:
    'Laser - Flex Line _ OD - OOR - End A - Section 1',
  laser_flex_line_od_oor_end_a_section_2:
    'Laser - Flex Line _ OD - OOR - End A - Section 2',
  laser_flex_line_od_oor_end_b_section_1:
    'Laser - Flex Line _ OD - OOR - End B - Section 1',
  laser_flex_line_od_oor_end_b_section_2:
    'Laser - Flex Line _ OD - OOR - End B - Section 2',
  laser_flex_line_wt_avg_end_a_section_1:
    'Laser - Flex Line _ WT - AVG - End A - Section 1',
  laser_flex_line_wt_avg_end_a_section_2:
    'Laser - Flex Line _ WT - AVG - End A - Section 2',
  laser_flex_line_wt_avg_end_b_section_1:
    'Laser - Flex Line _ WT - AVG - End B - Section 1',
  laser_flex_line_wt_avg_end_b_section_2:
    'Laser - Flex Line _ WT - AVG - End B - Section 2',
  laser_flex_line_wt_m10_end_a_section_1:
    'Laser - Flex Line _ WT - M10 - End A - Section 1',
  laser_flex_line_wt_m10_end_a_section_2:
    'Laser - Flex Line _ WT - M10 - End A - Section 2',
  laser_flex_line_wt_m10_end_b_section_1:
    'Laser - Flex Line _ WT - M10 - End B - Section 1',
  laser_flex_line_wt_m10_end_b_section_2:
    'Laser - Flex Line _ WT - M10 - End B - Section 2',
  laser_flex_line_wt_m11_end_a_section_1:
    'Laser - Flex Line _ WT - M11 - End A - Section 1',
  laser_flex_line_wt_m11_end_a_section_2:
    'Laser - Flex Line _ WT - M11 - End A - Section 2',
  laser_flex_line_wt_m11_end_b_section_1:
    'Laser - Flex Line _ WT - M11 - End B - Section 1',
  laser_flex_line_wt_m11_end_b_section_2:
    'Laser - Flex Line _ WT - M11 - End B - Section 2',
  laser_flex_line_wt_m12_end_a_section_1:
    'Laser - Flex Line _ WT - M12 - End A - Section 1',
  laser_flex_line_wt_m12_end_a_section_2:
    'Laser - Flex Line _ WT - M12 - End A - Section 2',
  laser_flex_line_wt_m12_end_b_section_1:
    'Laser - Flex Line _ WT - M12 - End B - Section 1',
  laser_flex_line_wt_m12_end_b_section_2:
    'Laser - Flex Line _ WT - M12 - End B - Section 2',
  laser_flex_line_wt_m13_end_a_section_1:
    'Laser - Flex Line _ WT - M13 - End A - Section 1',
  laser_flex_line_wt_m13_end_a_section_2:
    'Laser - Flex Line _ WT - M13 - End A - Section 2',
  laser_flex_line_wt_m13_end_b_section_1:
    'Laser - Flex Line _ WT - M13 - End B - Section 1',
  laser_flex_line_wt_m13_end_b_section_2:
    'Laser - Flex Line _ WT - M13 - End B - Section 2',
  laser_flex_line_wt_m14_end_a_section_1:
    'Laser - Flex Line _ WT - M14 - End A - Section 1',
  laser_flex_line_wt_m14_end_a_section_2:
    'Laser - Flex Line _ WT - M14 - End A - Section 2',
  laser_flex_line_wt_m14_end_b_section_1:
    'Laser - Flex Line _ WT - M14 - End B - Section 1',
  laser_flex_line_wt_m14_end_b_section_2:
    'Laser - Flex Line _ WT - M14 - End B - Section 2',
  laser_flex_line_wt_m15_end_a_section_1:
    'Laser - Flex Line _ WT - M15 - End A - Section 1',
  laser_flex_line_wt_m15_end_a_section_2:
    'Laser - Flex Line _ WT - M15 - End A - Section 2',
  laser_flex_line_wt_m15_end_b_section_1:
    'Laser - Flex Line _ WT - M15 - End B - Section 1',
  laser_flex_line_wt_m15_end_b_section_2:
    'Laser - Flex Line _ WT - M15 - End B - Section 2',
  laser_flex_line_wt_m16_end_a_section_1:
    'Laser - Flex Line _ WT - M16 - End A - Section 1',
  laser_flex_line_wt_m16_end_a_section_2:
    'Laser - Flex Line _ WT - M16 - End A - Section 2',
  laser_flex_line_wt_m16_end_b_section_1:
    'Laser - Flex Line _ WT - M16 - End B - Section 1',
  laser_flex_line_wt_m16_end_b_section_2:
    'Laser - Flex Line _ WT - M16 - End B - Section 2',
  laser_flex_line_wt_max_end_a_section_1:
    'Laser - Flex Line _ WT - MAX - End A - Section 1',
  laser_flex_line_wt_max_end_a_section_2:
    'Laser - Flex Line _ WT - MAX - End A - Section 2',
  laser_flex_line_wt_max_end_b_section_1:
    'Laser - Flex Line _ WT - MAX - End B - Section 1',
  laser_flex_line_wt_max_end_b_section_2:
    'Laser - Flex Line _ WT - MAX - End B - Section 2',
  laser_flex_line_wt_min_end_a_section_1:
    'Laser - Flex Line _ WT - MIN - End A - Section 1',
  laser_flex_line_wt_min_end_a_section_2:
    'Laser - Flex Line _ WT - MIN - End A - Section 2',
  laser_flex_line_wt_min_end_b_section_1:
    'Laser - Flex Line _ WT - MIN - End B - Section 1',
  laser_flex_line_wt_min_end_b_section_2:
    'Laser - Flex Line _ WT - MIN - End B - Section 2',
  laser_flex_line_wt_ecc_end_a_section_1:
    'Laser - Flex Line _ WT - ECC - End A - Section 1',
  laser_flex_line_wt_ecc_end_a_section_2:
    'Laser - Flex Line _ WT - ECC - End A - Section 2',
  laser_flex_line_wt_ecc_end_b_section_1:
    'Laser - Flex Line _ WT - ECC - End B - Section 1',
  laser_flex_line_wt_ecc_end_b_section_2:
    'Laser - Flex Line _ WT - ECC - End B - Section 2',
  tally_list_file_name: 'Tally List _ File Name',
  laser_flex_line_wt_m1_end_a_section_1:
    'Laser - Flex Line _ WT - M1 - End A - Section 1',
  laser_flex_line_wt_m1_end_a_section_2:
    'Laser - Flex Line _ WT - M1 - End A - Section 2',
  laser_flex_line_wt_m1_end_b_section_1:
    'Laser - Flex Line _ WT - M1 - End B - Section 1',
  laser_flex_line_wt_m1_end_b_section_2:
    'Laser - Flex Line _ WT - M1 - End B - Section 2',

  piece_number: 'Piece Number',
  unity: 'Unity',
  temperature: 'Temperature',
  hardness_test_1_sampling_side: 'Hardness - Test 1 _ Sampling Side',
  hardness_test_1_q1_ow1: 'Hardness - Test 1 _ Q1 OW1',
  hardness_test_1_q1_ow2: 'Hardness - Test 1 _ Q1 OW2',
  hardness_test_1_q1_ow3: 'Hardness - Test 1 _ Q1 OW3',
  hardness_test_1_q1_ow4: 'Hardness - Test 1 _ Q1 OW4',
  hardness_test_1_q1_avg_ow: 'Hardness - Test 1 _ Q1 AVG OW',
  hardness_test_1_q1_mw1: 'Hardness - Test 1 _ Q1 MW1',
  hardness_test_1_q1_mw2: 'Hardness - Test 1 _ Q1 MW2',
  hardness_test_1_q1_mw3: 'Hardness - Test 1 _ Q1 MW3',
  hardness_test_1_q1_mw4: 'Hardness - Test 1 _ Q1 MW4',
  hardness_test_1_q1_avg_mw: 'Hardness - Test 1 _ Q1 AVG MW',
  hardness_test_1_q1_iw1: 'Hardness - Test 1 _ Q1 IW1',
  hardness_test_1_q1_iw2: 'Hardness - Test 1 _ Q1 IW2',
  hardness_test_1_q1_iw3: 'Hardness - Test 1 _ Q1 IW3',
  hardness_test_1_q1_iw4: 'Hardness - Test 1 _ Q1 IW4',
  hardness_test_1_q1_avg_iw: 'Hardness - Test 1 _ Q1 AVG IW',
  hardness_test_1_q1_avg: 'Hardness - Test 1 _ Q1 AVG',
  hardness_test_1_file_name: 'Hardness - Test 1 _ File Name',
  hardness_test_2_sampling_side: 'Hardness - Test 2 _ Sampling Side',
  hardness_test_2_q1_ow1: 'Hardness - Test 2 _ Q1 OW1',
  hardness_test_2_q1_ow2: 'Hardness - Test 2 _ Q1 OW2',
  hardness_test_2_q1_ow3: 'Hardness - Test 2 _ Q1 OW3',
  hardness_test_2_q1_ow4: 'Hardness - Test 2 _ Q1 OW4',
  hardness_test_2_q1_avg_ow: 'Hardness - Test 2 _ Q1 AVG OW',
  hardness_test_2_q1_mw1: 'Hardness - Test 2 _ Q1 MW1',
  hardness_test_2_q1_mw2: 'Hardness - Test 2 _ Q1 MW2',
  hardness_test_2_q1_mw3: 'Hardness - Test 2 _ Q1 MW3',
  hardness_test_2_q1_mw4: 'Hardness - Test 2 _ Q1 MW4',
  hardness_test_2_q1_avg_mw: 'Hardness - Test 2 _ Q1 AVG MW',
  hardness_test_2_q1_iw1: 'Hardness - Test 2 _ Q1 IW1',
  hardness_test_2_q1_iw2: 'Hardness - Test 2 _ Q1 IW2',
  hardness_test_2_q1_iw3: 'Hardness - Test 2 _ Q1 IW3',
  hardness_test_2_q1_iw4: 'Hardness - Test 2 _ Q1 IW4',
  hardness_test_2_q1_avg_iw: 'Hardness - Test 2 _ Q1 AVG IW',
  hardness_test_2_q1_avg: 'Hardness - Test 2 _ Q1 AVG',
  hardness_test_2_file_name: 'Hardness - Test 2 _ File Name',
  sequential_number: 'Sequential Number',
  laser_flex_line_id_m1_end_a: 'Laser - Flex Line _ ID - M1 - End A',
  laser_flex_line_id_m2_end_a: 'Laser - Flex Line _ ID - M2 - End A',
  laser_flex_line_id_m3_end_a: 'Laser - Flex Line _ ID - M3 - End A',
  laser_flex_line_id_m4_end_a: 'Laser - Flex Line _ ID - M4 - End A',
  laser_flex_line_id_avg_end_a: 'Laser - Flex Line _ ID - AVG - End A',
  laser_flex_line_id_max_end_a: 'Laser - Flex Line _ ID - MAX - End A',
  laser_flex_line_id_min_end_a: 'Laser - Flex Line _ ID - MIN - End A',
  laser_flex_line_id_oor_end_a: 'Laser - Flex Line _ ID - OOR - End A',
  laser_flex_line_wt_m1_end_a: 'Laser - Flex Line _ WT - M1 - End A',
  laser_flex_line_wt_m2_end_a: 'Laser - Flex Line _ WT - M2 - End A',
  laser_flex_line_wt_m3_end_a: 'Laser - Flex Line _ WT - M3 - End A',
  laser_flex_line_wt_m4_end_a: 'Laser - Flex Line _ WT - M4 - End A',
  laser_flex_line_wt_m5_end_a: 'Laser - Flex Line _ WT - M5 - End A',
  laser_flex_line_wt_m6_end_a: 'Laser - Flex Line _ WT - M6 - End A',
  laser_flex_line_wt_m7_end_a: 'Laser - Flex Line _ WT - M7 - End A',
  laser_flex_line_wt_m8_end_a: 'Laser - Flex Line _ WT - M8 - End A',
  laser_flex_line_wt_avg_end_a: 'Laser - Flex Line _ WT - AVG - End A',
  laser_flex_line_wt_max_end_a: 'Laser - Flex Line _ WT - MAX - End A',
  laser_flex_line_wt_min_end_a: 'Laser - Flex Line _ WT - MIN - End A',
  laser_flex_line_wt_ecc_end_a: 'Laser - Flex Line _ WT - ECC - End A',
  laser_flex_line_id_m1_end_b: 'Laser - Flex Line _ ID - M1 - End B',
  laser_flex_line_id_m2_end_b: 'Laser - Flex Line _ ID - M2 - End B',
  laser_flex_line_id_m3_end_b: 'Laser - Flex Line _ ID - M3 - End B',
  laser_flex_line_id_m4_end_b: 'Laser - Flex Line _ ID - M4 - End B',
  laser_flex_line_id_avg_end_b: 'Laser - Flex Line _ ID - AVG - End B',
  laser_flex_line_id_max_end_b: 'Laser - Flex Line _ ID - MAX - End B',
  laser_flex_line_id_min_end_b: 'Laser - Flex Line _ ID - MIN - End B',
  laser_flex_line_id_oor_end_b: 'Laser - Flex Line _ ID - OOR - End B',
  laser_flex_line_wt_m1_end_b: 'Laser - Flex Line _ WT - M1 - End B',
  laser_flex_line_wt_m2_end_b: 'Laser - Flex Line _ WT - M2 - End B',
  laser_flex_line_wt_m3_end_b: 'Laser - Flex Line _ WT - M3 - End B',
  laser_flex_line_wt_m4_end_b: 'Laser - Flex Line _ WT - M4 - End B',
  laser_flex_line_wt_m5_end_b: 'Laser - Flex Line _ WT - M5 - End B',
  laser_flex_line_wt_m6_end_b: 'Laser - Flex Line _ WT - M6 - End B',
  laser_flex_line_wt_m7_end_b: 'Laser - Flex Line _ WT - M7 - End B',
  laser_flex_line_wt_m8_end_b: 'Laser - Flex Line _ WT - M8 - End B',
  laser_flex_line_wt_avg_end_b: 'Laser - Flex Line _ WT - AVG - End B',
  laser_flex_line_wt_max_end_b: 'Laser - Flex Line _ WT - MAX - End B',
  laser_flex_line_wt_min_end_b: 'Laser - Flex Line _ WT - MIN - End B',
  laser_flex_line_wt_ecc_end_b: 'Laser - Flex Line _ WT - ECC - End B',
  laser_flex_line_taper_angle_end_a: 'Laser - Flex Line _ Taper Angle - End A',
  laser_flex_line_taper_angle_end_b: 'Laser - Flex Line _ Taper Angle - End B',
  laser_flex_line_status_label_is_status:
    'Laser - Flex Line _ Status label is Status',
  laser_pup_pieces_taper_angle_end_a:
    'Laser - Pup Pieces _ Taper Angle - End A',
  laser_pup_pieces_taper_angle_end_b:
    'Laser - Pup Pieces _ Taper Angle - End B',
};

export const FIRST_ORDER = [
  COLUMNS['ippn'],
  COLUMNS['heat'],
  COLUMNS['htl'],
  COLUMNS['order'],
  COLUMNS['item'],
  COLUMNS['pipe_number'],
  COLUMNS['customer_order'],
  COLUMNS['customer_item'],
  COLUMNS['notional_item'],
  COLUMNS['nominal_od'],
  COLUMNS['nominal_wt'],
  COLUMNS['qty'],
  COLUMNS['unit'],
  COLUMNS['lot'],
  COLUMNS['date'],
  COLUMNS['batch'],
  COLUMNS['ladle'],
  COLUMNS['description'],
] as any;

export const COLUMNS_ORDER = [
  COLUMNS['tensile_longitudinal_sampling_side'],
  COLUMNS['tensile_longitudinal_elongation'],
  COLUMNS['tensile_longitudinal_ys_ts'],
  COLUMNS['tensile_longitudinal_yield_strength'],
  COLUMNS['tensile_longitudinal_tensile_strength'],
  COLUMNS['tensile_longitudinal_htl'],
  COLUMNS['tensile_longitudinal_file_name'],
  COLUMNS['tensile_transverse_sampling_side'],
  COLUMNS['tensile_transverse_elongation'],
  COLUMNS['tensile_transverse_ys/ts'],
  COLUMNS['tensile_transverse_yield_strength'],
  COLUMNS['tensile_transverse_tensile_strength'],
  COLUMNS['tensile_transverse_htl'],
  COLUMNS['tensile_transverse_file_name'],
  COLUMNS['impact_longitudinal_sampling_side'],
  COLUMNS['impact_longitudinal_shear_area_1'],
  COLUMNS['impact_longitudinal_shear_area_2'],
  COLUMNS['impact_longitudinal_shear_area_3'],
  COLUMNS['impact_longitudinal_average_ae'],
  COLUMNS['impact_longitudinal_ae_1'],
  COLUMNS['impact_longitudinal_ae_2'],
  COLUMNS['impact_longitudinal_ae_3'],
  COLUMNS['impact_longitudinal_average'],
  COLUMNS['impact_longitudinal_htl'],
  COLUMNS['impact_longitudinal_file_name'],
  COLUMNS['impact_transverse_sampling_side'],
  COLUMNS['impact_transverse_shear_area_1'],
  COLUMNS['impact_transverse_shear_area_2'],
  COLUMNS['impact_transverse_shear_area_3'],
  COLUMNS['impact_transverse_average_ae'],
  COLUMNS['impact_transverse_ae_1'],
  COLUMNS['impact_transverse_ae_2'],
  COLUMNS['impact_transverse_ae_3'],
  COLUMNS['impact_transverse_average'],
  COLUMNS['impact_transverse_htl'],
  COLUMNS['impact_transverse_file_name'],
  COLUMNS['hardness_sampling_side'],
  COLUMNS['hardness_q1_ow1'],
  COLUMNS['hardness_q1_ow2'],
  COLUMNS['hardness_q1_ow3'],
  COLUMNS['hardness_q1_ow4'],
  COLUMNS['hardness_q1_avg_ow'],
  COLUMNS['hardness_q1_iw1'],
  COLUMNS['hardness_q1_iw2'],
  COLUMNS['hardness_q1_iw3'],
  COLUMNS['hardness_q1_iw4'],
  COLUMNS['hardness_q1_avg_iw'],
  COLUMNS['hardness_q1_mw1'],
  COLUMNS['hardness_q1_mw2'],
  COLUMNS['hardness_q1_mw3'],
  COLUMNS['hardness_q1_mw4'],
  COLUMNS['hardness_q1_avg_mw'],
  COLUMNS['hardness_q1_avg'],
  COLUMNS['hardness_q2_ow1'],
  COLUMNS['hardness_q2_ow2'],
  COLUMNS['hardness_q2_ow3'],
  COLUMNS['hardness_q2_ow4'],
  COLUMNS['hardness_q2_avg_ow'],
  COLUMNS['hardness_q2_iw1'],
  COLUMNS['hardness_q2_iw2'],
  COLUMNS['hardness_q2_iw3'],
  COLUMNS['hardness_q2_iw4'],
  COLUMNS['hardness_q2_avg_iw'],
  COLUMNS['hardness_q2_mw1'],
  COLUMNS['hardness_q2_mw2'],
  COLUMNS['hardness_q2_mw3'],
  COLUMNS['hardness_q2_mw4'],
  COLUMNS['hardness_q2_avg_mw'],
  COLUMNS['hardness_q2_avg'],
  COLUMNS['hardness_q3_ow1'],
  COLUMNS['hardness_q3_ow2'],
  COLUMNS['hardness_q3_ow3'],
  COLUMNS['hardness_q3_ow4'],
  COLUMNS['hardness_q3_avg_ow'],
  COLUMNS['hardness_q3_iw1'],
  COLUMNS['hardness_q3_iw2'],
  COLUMNS['hardness_q3_iw3'],
  COLUMNS['hardness_q3_iw4'],
  COLUMNS['hardness_q3_avg_iw'],
  COLUMNS['hardness_q3_mw1'],
  COLUMNS['hardness_q3_mw2'],
  COLUMNS['hardness_q3_mw3'],
  COLUMNS['hardness_q3_mw4'],
  COLUMNS['hardness_q3_avg_mw'],
  COLUMNS['hardness_q3_avg'],
  COLUMNS['hardness_q4_ow1'],
  COLUMNS['hardness_q4_ow2'],
  COLUMNS['hardness_q4_ow3'],
  COLUMNS['hardness_q4_ow4'],
  COLUMNS['hardness_q4_avg_ow'],
  COLUMNS['hardness_q4_iw1'],
  COLUMNS['hardness_q4_iw2'],
  COLUMNS['hardness_q4_iw3'],
  COLUMNS['hardness_q4_iw4'],
  COLUMNS['hardness_q4_avg_iw'],
  COLUMNS['hardness_q4_mw1'],
  COLUMNS['hardness_q4_mw2'],
  COLUMNS['hardness_q4_mw3'],
  COLUMNS['hardness_q4_mw4'],
  COLUMNS['hardness_q4_avg_mw'],
  COLUMNS['hardness_q4_avg'],
  COLUMNS['hardness_htl'],
  COLUMNS['hardness_file_name'],
  COLUMNS['laser_flex_line_end'],
  COLUMNS['laser_flex_line_sct'],
  COLUMNS['laser_flex_line_wt'],
  COLUMNS['laser_flex_line_grade'],
  COLUMNS['laser_flex_line_id_m1'],
  COLUMNS['laser_flex_line_id_m2'],
  COLUMNS['laser_flex_line_id_m3'],
  COLUMNS['laser_flex_line_id_m4'],
  COLUMNS['laser_flex_line_id_m5'],
  COLUMNS['laser_flex_line_id_m6'],
  COLUMNS['laser_flex_line_id_m7'],
  COLUMNS['laser_flex_line_id_m8'],
  COLUMNS['laser_flex_line_id_avg'],
  COLUMNS['laser_flex_line_id_max'],
  COLUMNS['laser_flex_line_id_min'],
  COLUMNS['laser_flex_line_id_oor'],
  COLUMNS['laser_flex_line_od_m1'],
  COLUMNS['laser_flex_line_od_m2'],
  COLUMNS['laser_flex_line_od_m3'],
  COLUMNS['laser_flex_line_od_m4'],
  COLUMNS['laser_flex_line_od_m5'],
  COLUMNS['laser_flex_line_od_m6'],
  COLUMNS['laser_flex_line_od_m7'],
  COLUMNS['laser_flex_line_od_m8'],
  COLUMNS['laser_flex_line_od_avg'],
  COLUMNS['laser_flex_line_od_max'],
  COLUMNS['laser_flex_line_od_min'],
  COLUMNS['laser_flex_line_od_oor'],
  COLUMNS['laser_flex_line_wt_m2'],
  COLUMNS['laser_flex_line_wt_m3'],
  COLUMNS['laser_flex_line_wt_m4'],
  COLUMNS['laser_flex_line_wt_m5'],
  COLUMNS['laser_flex_line_wt_m6'],
  COLUMNS['laser_flex_line_wt_m7'],
  COLUMNS['laser_flex_line_wt_m8'],
  COLUMNS['laser_flex_line_wt_m9'],
  COLUMNS['laser_flex_line_wt_m10'],
  COLUMNS['laser_flex_line_wt_m11'],
  COLUMNS['laser_flex_line_wt_m12'],
  COLUMNS['laser_flex_line_wt_m13'],
  COLUMNS['laser_flex_line_wt_m14'],
  COLUMNS['laser_flex_line_wt_m15'],
  COLUMNS['laser_flex_line_wt_m16'],
  COLUMNS['laser_flex_line_wt_avg'],
  COLUMNS['laser_flex_line_wt_max'],
  COLUMNS['laser_flex_line_wt_min'],
  COLUMNS['laser_flex_line_wt_ecc'],
  COLUMNS['laser_flex_line_actual_id'],
  COLUMNS['laser_flex_line_nominal_od'],
  COLUMNS['laser_flex_line_nominal_wt'],
  COLUMNS['laser_flex_line_maximum_od'],
  COLUMNS['laser_flex_line_minimum_od'],
  COLUMNS['laser_flex_line_maximum_id'],
  COLUMNS['laser_flex_line_minimum_id'],
  COLUMNS['laser_flex_line_maximum_wt'],
  COLUMNS['laser_flex_line_minimum_wt'],
  COLUMNS['laser_flex_line_od_tolerances'],
  COLUMNS['laser_flex_line_project_number'],
  COLUMNS['laser_flex_line_wt_tolerances'],
  COLUMNS['laser_flex_line_project_description'],
  COLUMNS['laser_flex_line_customer_description'],
  COLUMNS['laser_flex_line_id_tolerances'],
  COLUMNS['laser_flex_line_file_name'],
  COLUMNS['chemical_al'],
  COLUMNS['chemical_as'],
  COLUMNS['chemical_b'],
  COLUMNS['chemical_bi'],
  COLUMNS['chemical_c'],
  COLUMNS['chemical_ca'],
  COLUMNS['chemical_co'],
  COLUMNS['chemical_cr'],
  COLUMNS['chemical_cu'],
  COLUMNS['chemical_h'],
  COLUMNS['chemical_mn'],
  COLUMNS['chemical_mo'],
  COLUMNS['chemical_n'],
  COLUMNS['chemical_nb'],
  COLUMNS['chemical_ni'],
  COLUMNS['chemical_o'],
  COLUMNS['chemical_p'],
  COLUMNS['chemical_pb'],
  COLUMNS['chemical_s'],
  COLUMNS['chemical_sb'],
  COLUMNS['chemical_se'],
  COLUMNS['chemical_si'],
  COLUMNS['chemical_sn'],
  COLUMNS['chemical_sr'],
  COLUMNS['chemical_ti'],
  COLUMNS['chemical_v'],
  COLUMNS['chemical_w'],
  COLUMNS['chemical_pcm'],
  COLUMNS['chemical_ce1'],
  COLUMNS['chemical_ce2'],
  COLUMNS['chemical_ce3'],
  COLUMNS['chemical_ce4'],
  COLUMNS['chemical_ce5'],
  COLUMNS['chemical_file_name'],
  COLUMNS['ladle_chemical_al'],
  COLUMNS['ladle_chemical_as'],
  COLUMNS['ladle_chemical_b'],
  COLUMNS['ladle_chemical_bi'],
  COLUMNS['ladle_chemical_c'],
  COLUMNS['ladle_chemical_ca'],
  COLUMNS['ladle_chemical_co'],
  COLUMNS['ladle_chemical_cr'],
  COLUMNS['ladle_chemical_cu'],
  COLUMNS['ladle_chemical_h'],
  COLUMNS['ladle_chemical_mn'],
  COLUMNS['ladle_chemical_mo'],
  COLUMNS['ladle_chemical_n'],
  COLUMNS['ladle_chemical_nb'],
  COLUMNS['ladle_chemical_ni'],
  COLUMNS['ladle_chemical_o'],
  COLUMNS['ladle_chemical_p'],
  COLUMNS['ladle_chemical_pb'],
  COLUMNS['ladle_chemical_s'],
  COLUMNS['ladle_chemical_sb'],
  COLUMNS['ladle_chemical_se'],
  COLUMNS['ladle_chemical_si'],
  COLUMNS['ladle_chemical_sn'],
  COLUMNS['ladle_chemical_sr'],
  COLUMNS['ladle_chemical_ti'],
  COLUMNS['ladle_chemical_v'],
  COLUMNS['ladle_chemical_w'],
  COLUMNS['ladle_chemical_pcm'],
  COLUMNS['ladle_chemical_ce1'],
  COLUMNS['ladle_chemical_ce2'],
  COLUMNS['ladle_chemical_ce3'],
  COLUMNS['ladle_chemical_ce4'],
  COLUMNS['ladle_chemical_ce5'],
  COLUMNS['ladle_chemical_file_name'],
  COLUMNS['dimensional_customer_purchase_order_/_item'],
  COLUMNS['dimensional_od'],
  COLUMNS['dimensional_wt'],
  COLUMNS['dimensional_grade'],
  COLUMNS['dimensional_production_order'],
  COLUMNS['dimensional_body_inspection_time'],
  COLUMNS['dimensional_bottom_od_(min)'],
  COLUMNS['dimensional_bottom_od_(max)'],
  COLUMNS['dimensional_body_od_(min)'],
  COLUMNS['dimensional_body_od_(max)'],
  COLUMNS['dimensional_top_od_(min)'],
  COLUMNS['dimensional_top_od_(max)'],
  COLUMNS['dimensional_bottom_wt_(min)'],
  COLUMNS['dimensional_bottom_wt_(max)'],
  COLUMNS['dimensional_body_wt_(min)'],
  COLUMNS['dimensional_body_wt_(max)'],
  COLUMNS['dimensional_top_wt_(min)'],
  COLUMNS['dimensional_top_wt_(max)'],
  COLUMNS['dimensional_bottom_oor_(min)'],
  COLUMNS['dimensional_bottom_oor_(max)'],
  COLUMNS['dimensional_body_oor_(min)'],
  COLUMNS['dimensional_body_oor_(max)'],
  COLUMNS['dimensional_top_oor_(min)'],
  COLUMNS['dimensional_top_oor_(max)'],
  COLUMNS['dimensional_bottom_ecc_(min)'],
  COLUMNS['dimensional_bottom_ecc_(max)'],
  COLUMNS['dimensional_body_ecc_(min)'],
  COLUMNS['dimensional_body_ecc_(max)'],
  COLUMNS['dimensional_top_ecc_(min)'],
  COLUMNS['dimensional_top_ecc_(max)'],
  COLUMNS['dimensional_body_file_name'],
  COLUMNS['lcf_laser_measurement_length_pipe_sequencial_nº'],
  COLUMNS['lcf_laser_measurement_length_a_pos_a_id'],
  COLUMNS['lcf_laser_measurement_length_a_pos_a_od'],
  COLUMNS['lcf_laser_measurement_length_a_pos_a_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_a_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_b_id'],
  COLUMNS['lcf_laser_measurement_length_a_pos_b_od'],
  COLUMNS['lcf_laser_measurement_length_a_pos_b_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_b_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_c_id'],
  COLUMNS['lcf_laser_measurement_length_a_pos_c_od'],
  COLUMNS['lcf_laser_measurement_length_a_pos_c_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_c_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_d_id'],
  COLUMNS['lcf_laser_measurement_length_a_pos_d_od'],
  COLUMNS['lcf_laser_measurement_length_a_pos_d_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_a_pos_d_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_a_roughness'],
  COLUMNS['lcf_laser_measurement_length_a_taper_angle'],
  COLUMNS['lcf_laser_measurement_length_a_squareness'],
  COLUMNS['lcf_laser_measurement_length_b_pos_a_id'],
  COLUMNS['lcf_laser_measurement_length_b_pos_a_od'],
  COLUMNS['lcf_laser_measurement_length_b_pos_a_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_a_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_b_id'],
  COLUMNS['lcf_laser_measurement_length_b_pos_b_od'],
  COLUMNS['lcf_laser_measurement_length_b_pos_b_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_b_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_c_id'],
  COLUMNS['lcf_laser_measurement_length_b_pos_c_od'],
  COLUMNS['lcf_laser_measurement_length_b_pos_c_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_c_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_d_id'],
  COLUMNS['lcf_laser_measurement_length_b_pos_d_od'],
  COLUMNS['lcf_laser_measurement_length_b_pos_d_wt_(1)'],
  COLUMNS['lcf_laser_measurement_length_b_pos_d_wt_(2)'],
  COLUMNS['lcf_laser_measurement_length_b_roughness'],
  COLUMNS['lcf_laser_measurement_length_b_taper_angle'],
  COLUMNS['lcf_laser_measurement_length_b_squareness'],
  COLUMNS['lcf_laser_measurement_length_actual_length'],
  COLUMNS['lcf_laser_measurement_length_squareness'],
  COLUMNS['lcf_laser_measurement_length_visual_inspection'],
  COLUMNS['lcf_laser_measurement_length_status'],
  COLUMNS['lcf_laser_measurement_length_file_name'],
];
