<vic-base-page
  page="product"
  *mobxAutorun
>
  <div class="page-body-container">
    <div *ngIf="!isProductVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb></databook-breadcrumb>

    <div class="card-container">
      <div
        class="val-card clr-col-xl-4 clr-col-lg-6 clr-col-md-8 clr-col-12"
        style="flex: 1 !important"
      >
        <div class="title-container">
          <label class="session-title">
            {{ 'product.selection' | translate }}
          </label>
          <button
            *ngIf="canProductConfig()"
            (click)="openModalProduct()"
            class="btn header-btn"
          >
            {{ 'application.commons.buttons.new' | translate }}
          </button>
        </div>

        <p class="label-medium">
          {{ 'product.selection.description' | translate }}
        </p>

        <div class="product-list">
          <div *ngFor="let product of store.products">
            <div *ngIf="!product.deleted">
              <!-- <div class="tooltip-content" style="margin-left: -13rem;">{{item.product.description}}</div> -->
              <div
                *ngIf="canSeeProduct(product.gsi_id.toUpperCase())"
                class="product-list-item clr-row"
              >
                <div
                  class="clr-col-9 item label-align"
                  (click)="onSelectClick(product)"
                >
                  <img
                    *ngIf="!product?.icon?.url"
                    src="../../../assets/img/svg/{{
                      getName(product.name)
                    }}.svg"
                  />
                  <img
                    *ngIf="product?.icon?.url"
                    [src]="product?.icon?.url"
                    alt="Product"
                  />
                  <div>
                    <label class="label-big">{{ product.name }}</label>
                  </div>
                </div>
                <div
                  class="clr-col-3 btn-group btn-primary btn-icon"
                  style="margin-top: 15px"
                  *ngIf="canProductConfig()"
                >
                  <button
                    class="btn btn-link"
                    (click)="openModalEditProduct(product)"
                  >
                    <clr-icon
                      shape="note"
                      title="Edit"
                      size="24"
                    ></clr-icon>
                  </button>

                  <button
                    class="btn btn-link"
                    (click)="openDeleteModal(product)"
                  >
                    <clr-icon
                      shape="trash"
                      title="Delete"
                      size="24"
                    ></clr-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>

<create-edit-product-modal
  *ngIf="productModal.isSave || productModal.isEdit"
  [form]="isSaveAndEditForm"
  [isEditMode]="productModal.isEdit"
  [closeModal]="closeModal"
  [modal]="productModal"
  [modalIsOpen]="modalIsOpen"
></create-edit-product-modal>

<delete-product-modal
  *ngIf="productModal.confirmDelete || productModal.deleteByText"
  [form]="isDeleteForm"
  [openModal]="openModal"
  [closeModal]="closeModal"
  [modal]="productModal"
  [modalIsOpen]="modalIsOpen"
></delete-product-modal>
