import { Component, Inject, Input } from '@angular/core';
import { CoreService } from '../../../core/core.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataBookState } from '../../../services/databook.state';
import { DataBookService } from '../../../services/databook.service';
import { PROJECT_STORE, ProjectsStore } from '../../../mobx-stores';
import { TranslateModule } from '@ngx-translate/core';
import { InputFormControlComponent } from '../../../components/form/input-form-control/input-form-control.component';
import { NgIf } from '@angular/common';
import { ClrModalModule, ClrCommonFormsModule } from '@clr/angular';

@Component({
  selector: 'archive-project-modal',
  templateUrl: './archive-project-modal.component.html',
  styleUrls: ['./archive-project-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    NgIf,
    FormsModule,
    ClrCommonFormsModule,
    ReactiveFormsModule,
    InputFormControlComponent,
    TranslateModule,
  ],
})
export class ArchiveProjectModalComponent {
  @Input() form: FormGroup;
  @Input() modal;
  @Input() closeModal;
  @Input() modalIsOpen;
  @Input() populateResult;
  @Input() openModal;
  @Input() project;
  @Input() updateProjects: CallableFunction;

  constructor(
    public coreService: CoreService,
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
  ) {}

  isEqual() {
    return (
      this.project?.name?.toUpperCase() !==
      this.form?.get('name')?.value?.toString().toUpperCase()
    );
  }

  confirmArchive = () => {
    this.openModal('archiveByText');
  };

  archive = async () => {
    try {
      this.coreService.showLoading();

      if (
        this.project?.name?.toUpperCase() ===
        this.form.get('name').value?.toString().toUpperCase()
      ) {
        const params = {
          archive: true,
          id: this.project.id,
          project: this.project.name,
          ownerEmail: this.project.ownerEmail,
          ownerName: this.project.ownerName,
        };

        const result = await this.dataBookService.project(params);
        await this.updateProjects();

        if (result.projects) {
          await this.coreService.showAlertSuccessTranslate(
            'project.removed.success',
          );
          await this.populateResult(result);
        }
      } else {
        this.coreService.showAlertErrorTranslate(
          'confirm.exclusion.error.not.same',
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.form.controls['name'].setValue('');
      this.form.reset();
      this.coreService.hideLoading();
      this.closeModal();
    }
  };
}
