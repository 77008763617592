import { Component, Inject } from '@angular/core';
import { DataBookBaseComponent } from '../databook-base.component';
import { ActivatedRoute } from '@angular/router';
import { ConfigHandler } from '../../core/handlers/config.handler';
import { HttpClient } from '@angular/common/http';
import { DataBookState } from '../../services/databook.state';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';
import { PdfService } from '../../components/pdf/pdf.service';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
  DataExplorerType,
} from '../../mobx-stores';
import { Features } from '../../classes';
import { FeaturesEnum } from '../../enums';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import {
  ClrIconModule,
  ClrCheckboxModule,
  ClrCommonFormsModule,
  ClrModalModule,
} from '@clr/angular';
import { NgIf, NgClass } from '@angular/common';
import { BasePageComponent } from '../../core/components/page/base-page.component';
import { MobxAngularModule } from 'mobx-angular';

@Component({
  selector: 'data-flow-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    MobxAngularModule,
    BasePageComponent,
    NgIf,
    ClrIconModule,
    BreadcrumbComponent,
    ClrCheckboxModule,
    FormsModule,
    ClrCommonFormsModule,
    NgClass,
    ClrModalModule,
    TranslateModule,
  ],
})
export class DataBookDashboardComponent extends DataBookBaseComponent {
  featuresEnum = FeaturesEnum;
  disabled = true;
  approved;
  test;
  openConfirm = 'none';
  disableEnableConfirmation;
  currentFeature: FeaturesEnum;
  dataExplorerType = DataExplorerType;
  public features: { [key in FeaturesEnum]: boolean } = {
    [FeaturesEnum.dataExplorer]:
      this.store.selectedProduct?.hasDataExplorerFeature(),
    [FeaturesEnum.dataAnalysis]:
      this.store.selectedProduct?.hasDataAnalysisFeature(),
    [FeaturesEnum.eDoc]: this.store.selectedProduct?.hasEdocFeature(),
    [FeaturesEnum.materials]: this.store.selectedProduct?.hasMaterialsFeature(),
    [FeaturesEnum.tpiDigital]:
      this.store.selectedProduct?.hasTpiDigitalFeature(),
    [FeaturesEnum.databook]: this.store.selectedProduct?.hasDatabookFeature(),
  };

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    public config: ConfigHandler,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  cancelConfirmation() {
    this.openConfirm = 'none';
    this.store.selectedProduct.toogleFeature(this.currentFeature);
  }

  async confirm() {
    try {
      this.coreService.showLoading();

      if (this.isEnablingDatas()) {
        this.store.selectedProduct.enableFeature(FeaturesEnum.materials);
        //this.features.materials = true;
      } else if (
        this.currentFeature === FeaturesEnum.materials &&
        this.store.selectedProduct.getFeatureStatus(FeaturesEnum.materials) ===
          false
      ) {
        this.store.selectedProduct.disableFeature(FeaturesEnum.dataAnalysis);
        if (this.dataBookState.olddataexplorerintegration) {
          this.store.selectedProduct.disableFeature(FeaturesEnum.dataExplorer);
        }
      }

      await this.dataBookService.project({
        project: this.store.selectedProject.slug,
        product: this.store.selectedProduct.slug,
        productId: this.store.selectedProduct.id,
        features: this.currentFeature,
        value: this.features[this.currentFeature],
      });

      this.openConfirm = 'none';

      if (this.disableEnableConfirmation) {
        await this.coreService.showAlertSuccessTranslate(
          'feature.disabled.success',
        );
      } else {
        await this.coreService.showAlertSuccessTranslate(
          'feature.enabled.success',
        );
      }

      if (this.isEnablingDatas()) {
        this.store.gotoPage('materials');
        // this.coreService.getRouter().navigate(['/main/material-mgmt']);
      }
    } catch (error) {
      this.coreService.showAlertErrorTranslate(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  private isEnablingDatas() {
    return (
      (this.currentFeature === 'dataExplorer' ||
        this.currentFeature === 'dataAnalysis') &&
      this.disableEnableConfirmation
    );
  }

  getMessage() {
    if (this.disableEnableConfirmation) {
      return this.coreService.translate('confirm.enable.feature');
    } else {
      return this.coreService.translate('confirm.disable.feature');
    }
  }

  enableDisableFeature(feature: FeaturesEnum) {
    this.disableEnableConfirmation =
      this.store.selectedProduct.getFeatureStatus(feature);
    this.currentFeature = feature;
    this.openConfirm = 'open';
  }

  async onPageInit() {
    // console.log('onPageInit');
    // console.log(this.store.selectedProject);
    // console.log(this.store.selectedProduct);
    // console.log('onPageInit');
    // await super.initProjectProduct();
    // for (const item of this.dataBookState.entireProject.products) {
    //   if (item.product.id === this.store.selectedProduct.gsi_id&& item.features) {
    //     this.features = item.features;
    //   }
    // }
  }

  canSeeLog() {
    if (
      super.getRoleByProject() &&
      super.getRoleByProject().TPI_DIGITAL &&
      super.getRoleByProject().TPI_DIGITAL.RELEASE
    ) {
      return true;
    } else {
      return false;
    }
  }

  canUpload() {
    if (this.currentUser?.roles?.UPLOAD_TEST) {
      return true;
    }
    return false;
  }

  pipeLoad() {
    this.store.gotoPage('pipe-load');
    // this.coreService.getRouter().navigate(['/main/pipe-load']);
  }

  log() {
    this.store.goto('mes-log');
    // this.coreService.getRouter().navigate(['/main/mes-log']);
  }

  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.dashboard',
      null,
    );
    this.breadcrumb = this.store.breadcrumb;
    /*this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },

      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },
    ];*/
  }

  isDataAnalysisVisible() {
    const role = super.getRoleByProject();
    if (role && role.DATA_ANALYSIS && this.productHasFeature('dataAnalysis')) {
      return true;
    }
    return false;
  }

  isDatabookVisible() {
    const role = super.getRoleByProject();
    if (role && role.DATABOOK && this.productHasFeature('databook')) {
      return true;
    }
    return false;
  }

  isEDocVisible() {
    const role = super.getRoleByProject();
    if (
      role &&
      super.getRoleByProject().E_DOC &&
      this.productHasFeature('eDoc')
    ) {
      return true;
    }
    return false;
  }

  isDataExplorerVisible() {
    const roleByProject = super.getRoleByProject();
    if (
      roleByProject &&
      roleByProject.DATA_EXPLORER &&
      this.productHasFeature('dataExplorer')
    ) {
      return true;
    }
    return false;
  }

  isTpiVisible() {
    const role = super.getRoleByProject();
    if (role.TPI_DIGITAL && this.productHasFeature('tpiDigital')) {
      return true;
    }
    return false;
  }
  isMaterialVisible() {
    const role = super.getRoleByProject();
    if (role.MATERIALS && this.productHasFeature('materials')) {
      return true;
    }
    return false;
  }

  isQpVisible() {
    if (
      this.productHasFeature('materials') &&
      (super.isReadOnly() || super.getRoleByProject().QUALITY_PLAN)
    ) {
      return true;
    }
    return false;
  }

  getCondition() {
    return !this.store.selectedProject.gsi_id || !this.dataBookState.product;
  }

  async isDatabookIndexApproved() {
    if (!this.approved[6] || this.approved[6] === null) {
      return false;
    } else {
      return true;
    }
  }

  isDev() {
    return (
      this.userStore.user.email === 'lucasferre@gmail.com' ||
      this.userStore.user.email === 'stephane.fischer@ext.vallourec.com' ||
      this.userStore.user.email === 'felipe.jesus@ext.vallourec.com' ||
      this.userStore.user.email === 'matheus.moreira@ext.vallourec.com'
    );
  }

  selectItemDashBoardScope(page) {
    this.coreService
      .getRouter()
      .navigate([
        '/' +
          this.store.selectedProject.slug +
          '/' +
          this.store.selectedProduct.slug +
          '/' +
          page,
      ]);
  }

  upload() {
    this.store.gotoPage('upload-test');
    /*this.coreService.getRouter().navigate(['/main/upload-test/'  +
    this.store.selectedProject.gsi_id +
    '/' +
    this.dataBookState.product]);*/
  }

  async goToDataAnalysis(gotTo) {
    const result = await this.dataBookService.dataAnalysis({
      checkTest: true,
      productId: this.store.selectedProduct.id,
    });
    if (result.contains) {
      this.dataBookState.oldDataAnalysis = false;
      this.store.gotoPage('new-data-analysis');
    } else {
      this.dataBookState.oldDataAnalysis = true;
      this.store.gotoMaterialChooserWithDataPageType(gotTo);
    }
  }

  async gotToDataExplorer() {
      const result = await this.dataBookService.dataExplorer({
        checkTest: true,
        productId: this.store.selectedProduct.id,
      });

      if (
        result?.contains ||
        this.store.selectedProject.gsi_id === 'Yellow Tail'
      ) {
        this.store.gotoMaterialChooserWithDataPageType(
          DataExplorerType.dataExplorerAg,
        );
      } else {
        this.store.gotoMaterialChooserWithDataPageType(
          DataExplorerType.dataExplorer,
        );
      }
  }

  async gotToDataExplorerAg() {
    this.store.gotoMaterialChooserWithDataPageType(
      DataExplorerType.dataExplorerAg,
    );
  }

  async gotToMaterial() {
    this.coreService.showLoading();
    try {
      const params = {
        list: 'all',
        project: this.store.selectedProject.gsi_id,
        product: this.store.selectedProduct.gsi_id,
        productId: this.store.selectedProduct.id,
      };

      this.cfgVlr(params);
      const result = await this.dataBookService.materials(params);
      const materials = result.materials;
      const backBone = this.config.environment.endpoints.backbone;
      const orders = materials.reduce((acc, mat) => {
        if (
          mat.item &&
          mat.item.toString().trim().length &&
          !acc.includes(mat.item)
        ) {
          acc.push(mat.item);
        }
        return acc;
      }, []);
      if (orders.length) {
        const finalUrl = backBone + orders.toString();
        window.location.href = finalUrl;
      } else {
        this.coreService.showAlertDangerTranslate('error.material.is.empty');
      }
    } catch (error) {
      this.coreService.showAlertErrorTranslate(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  selectItemDashBoard(card) {
    this.store.gotoPage(card);
    /*this.coreService
      .getRouter()
      .navigate([
        '/main/' +
          card +
          '/' +
          this.store.selectedProject.gsi_id +
          '/' +
          this.store.selectedProduct.gsi_id,
      ]);*/
  }

  selectItemDashBoardParams(card, params) {
    this.store.gotoPage(card);
    // console.log(params);
    /*this.coreService
      .getRouter()
      .navigate(
        [
          '/main/' +
            card +
            '/' +
            this.store.selectedProject.gsi_id +
            '/' +
            this.store.selectedProduct.gsi_id,
        ],
        { queryParams: params }
      );*/
  }
}
