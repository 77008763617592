import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {
  DataExplorerType,
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import { DataBookService } from '../../services/databook.service';
import { plainToInstance } from 'class-transformer';
import {
  Product,
  Project,
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../../classes';
import { CoreService } from '../../core/core.service';
import { findProductsFromProjectId } from '../../classes/product-helpers';
import {
  FolderNameAndId,
  getFileById,
  getFolder,
  getRootFoldersForFrontEnd,
  treeviewItemGetPath,
} from '../../classes/treeview-helpers';
import { pipeNavigatorRouteNames } from '../../enums';
import {
  findMaterialFromId,
  findMaterialsFromProductId,
} from '../../classes/material-helpers';
import { DataBookState } from '../../services/databook.state';

@Injectable({
  providedIn: 'root',
})
export class ProjectListResolver {
  constructor(
    // eslint-disable-next-line no-unused-vars
    public dataBookState: DataBookState,
    // eslint-disable-next-line no-unused-vars
    private coreService: CoreService,
    // eslint-disable-next-line no-unused-vars
    public dataBookService: DataBookService,
    // eslint-disable-next-line no-unused-vars
    public activatedRouter: ActivatedRoute,
    // eslint-disable-next-line no-unused-vars
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    // eslint-disable-next-line no-unused-vars
    @Inject(USER_STORE) public userStore: UserStore,
    // eslint-disable-next-line no-unused-vars
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<void> {
    this.coreService.showLoading();

    try {
      // alert('Route name: '+route.data.routeName);
      // const a = await this.dataBookService.project({init: true}) as Project[];
      const items: Project[] = await this.dataBookService.project({
        init: true,
      });
      const mapped = items.map((item: Project) => {
        return plainToInstance(Project, item);
      });
      this.store.projects = mapped;
      this.store.activePageForBreadcrumb = 'Home';

      // const b = await findProjects(this.dataBookService, false);
      // this.store.projects = b;

      if (route.params['projectSlug']) {
        this.store.selectedProject = this.store.projects.find(
          (project: Project) => {
            return project.slug === route.params['projectSlug'];
          },
        );

        this.store.products = await findProductsFromProjectId(
          this.dataBookService,
          this.store.selectedProject.id,
          true,
        );

        if (route.params['productSlug']) {
          this.store.selectedProduct = this.store.products.find(
            (product: Product) => {
              return product.slug === route.params['productSlug'];
            },
          );

          this.dataBookState.olddataexplorerintegration =
            this.store.selectedProduct.olddataexplorerintegration;

          this.store.materials = await findMaterialsFromProductId(
            this.dataBookService,
            this.store.selectedProduct.id,
          );
          const routeNamesArray: pipeNavigatorRouteNames[] = [
            pipeNavigatorRouteNames.materialMaterialTypeDataExplorer,
            pipeNavigatorRouteNames.plpRoughCoat,
            pipeNavigatorRouteNames.plpThickness,
            pipeNavigatorRouteNames.plpPostBlasting,
            pipeNavigatorRouteNames.plpIncoming,
            pipeNavigatorRouteNames.plpIInductionCoilPreheat,
            pipeNavigatorRouteNames.plpFinalInspection,
            pipeNavigatorRouteNames.plpSinter_7Layer,
            pipeNavigatorRouteNames.plpThickness_3Layer,
            pipeNavigatorRouteNames.plpSawStation_7Layer,
            pipeNavigatorRouteNames.plpSawStation_5Layer,
            pipeNavigatorRouteNames.plpPostBlasting_3Layer,
            pipeNavigatorRouteNames.plpIndCoilPreheat_3Layer,
            pipeNavigatorRouteNames.plpIncoming_7Layer,
            pipeNavigatorRouteNames.plpIncoming_5Layer,
            pipeNavigatorRouteNames.plpIncoming_3Layer,
            pipeNavigatorRouteNames.plpFinalInspection_7Layer,
            pipeNavigatorRouteNames.plpFinalInspection_3Layer,
            pipeNavigatorRouteNames.plpCutback_7Layer,
            pipeNavigatorRouteNames.plpNewImpactTemplate,
            pipeNavigatorRouteNames.plpNewTensileTemplate,
            pipeNavigatorRouteNames.plpPelm,
            pipeNavigatorRouteNames.plpPullOff,
            pipeNavigatorRouteNames.plpAdhesion,
            pipeNavigatorRouteNames.plpMlp,
            pipeNavigatorRouteNames.plpWeldingTest,
            pipeNavigatorRouteNames.plpDensityGspp,
            pipeNavigatorRouteNames.plpMeltFlowIndex,
            pipeNavigatorRouteNames.plpEntrapped,
            pipeNavigatorRouteNames.plpConductivity,
            pipeNavigatorRouteNames.plpTensilePropTopcoat,
            pipeNavigatorRouteNames.plpPolypropylene,
            pipeNavigatorRouteNames.plpOit,
            pipeNavigatorRouteNames.plpIndentation,
            pipeNavigatorRouteNames.plpImpactUndercoating,
            pipeNavigatorRouteNames.plpHotWater,
            pipeNavigatorRouteNames.plpEpoxy,
            pipeNavigatorRouteNames.plpCathodicDisbondment,
            pipeNavigatorRouteNames.plpAdhesive,
            pipeNavigatorRouteNames.plpCure,
            pipeNavigatorRouteNames.plpPorosity,
            pipeNavigatorRouteNames.plpModulus,
            pipeNavigatorRouteNames.plpRawMaterials,
            pipeNavigatorRouteNames.plpTensilePropTopcoatInsulating,
            pipeNavigatorRouteNames.plpInterlayerAdhesion,
            pipeNavigatorRouteNames.plpShoreDTopcoat,
            pipeNavigatorRouteNames.plpCompression,
            pipeNavigatorRouteNames.plpMassRatio,
            pipeNavigatorRouteNames.plpAshDensity,
            pipeNavigatorRouteNames.plpDoubleJoint,
            pipeNavigatorRouteNames.plpDimensionsSepia,
            pipeNavigatorRouteNames.plpDimensions,
            pipeNavigatorRouteNames.plpTensile,
            pipeNavigatorRouteNames.plpImpact,
            pipeNavigatorRouteNames.plpSsc,
            pipeNavigatorRouteNames.plpAllSangomar,
            pipeNavigatorRouteNames.plpAll,
            pipeNavigatorRouteNames.plpHic,
            pipeNavigatorRouteNames.plpHardness,
            pipeNavigatorRouteNames.plpCtod,
            pipeNavigatorRouteNames.plpChemical,
          ];
          const routeName: string = route.data.routeName;
          if (
            routeNamesArray.some(
              (name) =>
                name.localeCompare(routeName, undefined, {
                  sensitivity: 'base',
                }) === 0,
            )
          ) {
            this.store.dataExplorerType = DataExplorerType.dataExplorer;
          }

          if (
            routeName ===
              pipeNavigatorRouteNames.materialMaterialTypeDataAnalysis ||
            routeName === pipeNavigatorRouteNames.dataAnalysisStatistics ||
            routeName === pipeNavigatorRouteNames.dataAnalysisBoxplot
          ) {
            this.store.dataExplorerType = DataExplorerType.dataAnalysis;

            if (route.params['dataAnalysisTestType']) {
              this.store.dataAnalysisselectedTestType =
                route.params['dataAnalysisTestType'];
            }

            if (route.params['dataAnalysisTestTypeProperty']) {
              this.store.dataAnalysisselectedTestTypeProperty =
                route.params['dataAnalysisTestTypeProperty'];
            }
          }

          if (route.params['materialId']) {
            this.store.materialId = route.params['materialId'] as number;
            this.store.material = await findMaterialFromId(
              this.dataBookService,
              this.store.materialId,
            );
          }
          this.store.activePageForBreadcrumb = routeName;

          if (
            routeName === pipeNavigatorRouteNames.databook ||
            routeName === pipeNavigatorRouteNames.databookFileId ||
            routeName === pipeNavigatorRouteNames.databookSearchName ||
            routeName === pipeNavigatorRouteNames.databookFolderId ||
            routeName === pipeNavigatorRouteNames.eDoc ||
            routeName === pipeNavigatorRouteNames.eDocFileId ||
            routeName === pipeNavigatorRouteNames.eDocFolderId
          ) {
            this.treeviewStore.dataBookService = this.dataBookService;
            this.treeviewStore.product = this.store.selectedProduct;

            if (routeName === pipeNavigatorRouteNames.databook) {
              this.treeviewStore.context = TreeviewItemContext.databook;
            } else if (routeName === pipeNavigatorRouteNames.eDoc) {
              this.treeviewStore.context = TreeviewItemContext.edoc;
            } else {
              const regexDatabookFile = /^\/.+\/.+\/databook\/\d+$/;
              const regexEdocFile = /^\/.+\/.+\/e-doc\/\d+$/;
              const regexDatabookFolder = /^\/.+\/.+\/databook\/folder\/\d+$/;
              const regexEdocFolder = /^\/.+\/.+\/e-doc\/folder\/\d+$/;
              if (
                regexDatabookFile.test(state.url) ||
                regexDatabookFolder.test(state.url)
              ) {
                this.treeviewStore.context = TreeviewItemContext.databook;
              } else if (
                regexEdocFile.test(state.url) ||
                regexEdocFolder.test(state.url)
              ) {
                this.treeviewStore.context = TreeviewItemContext.edoc;
              }
            }
            this.treeviewStore.context = TreeviewItemContext.databook;
            if (
              routeName === pipeNavigatorRouteNames.eDocFileId ||
              routeName === pipeNavigatorRouteNames.eDoc ||
              routeName === pipeNavigatorRouteNames.eDocFolderId
            ) {
              this.treeviewStore.context = TreeviewItemContext.edoc;
            }

            const rootFolders = (await getRootFoldersForFrontEnd(
              this.dataBookService,
              this.store.selectedProduct.id,
              this.treeviewStore.context,
            )) as FolderNameAndId[];
            this.treeviewStore.tree = rootFolders.map(
              (item: FolderNameAndId) => {
                return plainToInstance(TreeviewFolderItem, {
                  id: item.id,
                  product_id: item.product_id,
                  description: item.description,
                  name: item.name,
                  isRoot: true,
                  phase: item.phase,
                  fixed: item.fixed,
                  parent_id: null,
                  treeview_context: item.treeview_context,
                  folders: [],
                  files: [],
                });
              },
            );

            if (
              routeName === pipeNavigatorRouteNames.databookFileId ||
              routeName === pipeNavigatorRouteNames.eDocFileId
            ) {
              if (route.params['fileId']) {
                this.store.file = await getFileById(
                  this.dataBookService,
                  route.params['fileId'],
                );
                treeviewItemGetPath(
                  this.dataBookService,
                  this.store.file.id,
                  'file',
                )
                  .then((parentsIds: number[]) => {
                    if (parentsIds) {
                      this.treeviewStore.openTargetFileOrFolder(
                        'file',
                        this.store.file.id,
                        parentsIds,
                      );
                    }
                  })
                  .catch((error: any) => {
                    console.error(
                      'error on treeviewItemGetPath: ' + error.message,
                    );
                  });
              }
            }

            /*if(routeName === pipeNavigatorRouteNames.databookSearchName) {
                            if(route.params['searchName']) {
                                this.store.files = await getFileBySearchName(this.dataBookService, route.params['searchName'], this.store.selectedProduct.id);
                            }
                        }*/

            if (
              routeName === pipeNavigatorRouteNames.databookFolderId ||
              routeName === pipeNavigatorRouteNames.eDocFolderId
            ) {
              if (route.params['folderId']) {
                this.store.folder = await getFolder(
                  this.dataBookService,
                  route.params['folderId'],
                  false,
                  false,
                );
                treeviewItemGetPath(
                  this.dataBookService,
                  this.store.folder.id,
                  'folder',
                )
                  .then((parentsIds: number[]) => {
                    if (parentsIds) {
                      this.treeviewStore.openTargetFileOrFolder(
                        'folder',
                        this.store.folder.id,
                        parentsIds,
                      );
                    }
                  })
                  .catch((error: any) => {
                    console.error(
                      'error on treeviewItemGetPath: ' + error.message,
                    );
                  });
              }
            }
          }
        } else {
          this.store.selectedProduct = null;
        }
      }
    } catch (error) {
      await this.coreService.getRouter().navigate(['/']);
      console.log(error);
    } finally {
      this.coreService.hideLoading();
    }
  }
}
