import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputFormControlComponent } from '../../../../components/form/input-form-control/input-form-control.component';
import { NgIf, NgFor } from '@angular/common';
import {
  ClrModalModule,
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrIconModule,
} from '@clr/angular';

@Component({
  selector: 'workflow-modal',
  templateUrl: './workflow-modal.component.html',
  styleUrls: ['./workflow-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    NgIf,
    ClrCommonFormsModule,
    FormsModule,
    ReactiveFormsModule,
    InputFormControlComponent,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgFor,
    ClrIconModule,
    TranslateModule,
  ],
})
export class WorkflowModalComponent {
  @Input() isModalOpen = false;
  @Input() closeModal: () => void;

  @Input() title: {
    newWorkflow: string;
    editWorkflow: string;
    deleteWorkflow: string;
    newStep: string;
    editStep: string;
  };

  @Input() type: {
    create: boolean;
    update: boolean;
  };

  @Input() form: FormGroup;
  @Input() stepData;

  @Input() editValStep: () => void;
  @Input() save: () => void;
  @Input() update: () => void;
  @Input() updateStepForm: (index: number) => void;

  @Input() reorder: () => void;
  @Input() newStep: () => void;

  deleteStep(id) {
    this.stepData.splice(
      this.stepData.findIndex((index) => index.id === id),
      1,
    );
    this.reorder();
  }
}
