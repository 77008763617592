import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataExplorerComponent } from '../pages/data-explorer/data-explorer.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { PlpChemicalComponent } from '../pages/plp/chemical/plp-chemical.component';
import { PlpCtodComponent } from '../pages/plp/ctod/plp-ctod.component';
import { PlpHardnessComponent } from '../pages/plp/hardness/plp-hardness.component';
import { PlpHicComponent } from '../pages/plp/hic/plp-hic.component';
import { PlpAllComponent } from '../pages/plp/all/plp-all.component';
import { PlpAllSangomarComponent } from '../pages/plp/all-sangomar/plp-all-sangomar.component';
import { PlpSscComponent } from '../pages/plp/ssc/plp-ssc.component';
import { PlpImpactComponent } from '../pages/plp/impact/plp-impact.component';
import { PlpTensileComponent } from '../pages/plp/tensile/plp-tensile.component';
import { PlpDimensionsComponent } from '../pages/plp/dimensions/plp-dimensions.component';
import { PlpDimensionsSepiaComponent } from '../pages/plp/dimensions-sepia/plp-dimensions-sepia.component';
import { PlpPlatinaDJointComponent } from '../pages/plp/platina-d-joint/plp-platina-d-joint.component';
import { AshDensityComponent } from '../pages/plp/ash-density/ash-density.component';
import { MassRatioComponent } from '../pages/plp/mass-ratio/mass-ratio.component';
import { CompressionComponent } from '../pages/plp/compression/compression.component';
import { ShoreDTopcoatComponent } from '../pages/plp/shore-d-topcoat/shore-d-topcoat.component';
import { InterlayerAdhesionComponent } from '../pages/plp/interlayer-adhesion/interlayer-adhesion.component';
import { TensilePropTopcoatInsulationComponent } from '../pages/plp/tensile-prop-topcoat-insulating/tensile-prop-topcoat.component';
import { RawMaterialComponent } from '../pages/plp/raw-material/raw-material.component';
import { ModulusComponent } from '../pages/plp/modulus/modulus.component';
import { PorosityComponent } from '../pages/plp/porosity/porosity.component';
import { CureComponent } from '../pages/plp/cure/cure.component';
import { AdhesiveComponent } from '../pages/plp/adhesive/adhesive.component';
import { CathodicDisbondmentComponent } from '../pages/plp/cathodic-disbondment/cathodic-disbondment.component';
import { EpoxyComponent } from '../pages/plp/epoxy/epoxy.component';
import { HotWaterComponent } from '../pages/plp/hot-water/hot-water.component';
import { ImpactUndercoatingComponent } from '../pages/plp/impact-undercoating/impact-undercoating.component';
import { IndentationComponent } from '../pages/plp/indentation/indentation.component';
import { OitComponent } from '../pages/plp/oit/oit.component';
import { PolypropyleneComponent } from '../pages/plp/polypropylene/polypropylene.component';
import { TensilePropTopcoatComponent } from '../pages/plp/tensile-prop-topcoat/tensile-prop-topcoat.component';
import { ConductivityComponent } from '../pages/plp/conductivity/conductivity.component';
import { EntrappedAirComponent } from '../pages/plp/entrapped-air/entrapped-air.component';
import { MeltFlowIndexComponent } from '../pages/plp/melt-flow-index/melt-flow-index.component';
import { DensityGsppComponent } from '../pages/plp/density-gspp/density-gspp.component';
import { WeldingTestComponent } from '../pages/plp/welding-test/welding-test.component';
import { MlpComponent } from '../pages/plp/mlp/mlp.component';
import { AdhesionComponent } from '../pages/plp/adhesion/adhesion.component';
import { PullOffComponent } from '../pages/plp/pull-off/pull-off.component';
import { PlpPelmComponent } from '../pages/plp/pelm/plp-pelm.component';
import { PlpTensileNewTemplateComponent } from '../pages/plp/tensile-new-template/plp-tensile-new-template.component';
import { PlpImpactNewTemplateComponent } from '../pages/plp/impact-new-template/plp-impact-new-template.component';
import { Cutback7LayerComponent } from '../pages/plp/coating/cutback-7-layer/cutback-7-layer.component';
import { FinalInspection3LayerComponent } from '../pages/plp/coating/final-inspection-3-layer/final-inspection-3-layer.component';
import { FinalInspection5LayerComponent } from '../pages/plp/coating/final-inspection-5-layer/final-inspection-5-layer.component';
import { FinalInspection7LayerComponent } from '../pages/plp/coating/final-inspection-7-layer/final-inspection-7-layer.component';
import { Incoming3LayerComponent } from '../pages/plp/coating/incoming-3-layer/incoming-3-layer.component';
import { Incoming5LayerComponent } from '../pages/plp/coating/incoming-5-layer/incoming-5-layer.component';
import { Incoming7LayerComponent } from '../pages/plp/coating/incoming-7-layer/incoming-7-layer.component';
import { IndCoilPreheat3LayerComponent } from '../pages/plp/coating/ind-coil-preheat-3-layer/ind-coil-preheat-3-layer.component';
import { PostBlasting3LayerComponent } from '../pages/plp/coating/post-blasting-3-layer/post-blasting-3-layer.component';
import { SawStation5LayerComponent } from '../pages/plp/coating/saw-station-5-layer/saw-station-5-layer.component';
import { SawStation7LayerComponent } from '../pages/plp/coating/saw-station-7-layer/saw-station-7-layer.component';
import { Thickness3LayerComponent } from '../pages/plp/coating/thickness-3-layer/thickness-3-layer.component';
import { Sinter7LayerComponent } from '../pages/plp/coating/sinter-7-layer/sinter-7-layer.component';
import { FinalInspectionComponent } from '../pages/plp/coating/final-inspection/final-inspection.component';
import { IInductionCoilPreheatComponent } from '../pages/plp/coating/iInduction-coil-preheat/iInduction-coil-preheat.component';
import { IncomingComponent } from '../pages/plp/coating/incoming/incoming.component';
import { PostBlastingComponent } from '../pages/plp/coating/post-blasting/post-blasting.component';
import { ThicknessComponent } from '../pages/plp/coating/thickness/thickness.component';
import { RoughCoatComponent } from '../pages/plp/coating/rough-coat/rough-coat.component';
import { PlpComponent } from '../pages/plp/plp.component';

export enum TestTypesEnum {
  chemical = 'chemical',
  hardness = 'hardness',
  hic = 'hic',
  all = 'all',
  allSangomar = 'all-sangomar',
  ssc = 'ssc',
  impact = 'impact',
  tensile = 'tensile',
  dimensions = 'dimensions',
  dimensionsSepia = 'dimensions-sepia',
  doubleJoint = 'double-joint',
  ashDensity = 'ash-density',
  massRatio = 'mass-ratio',
  compression = 'compression',
  shoreDTopcoat = 'shore-d-topcoat',
  interlayerAdhesion = 'interlayer-adhesion',
  tensilePropTopcoatInsulating = 'tensile-prop-topcoat-insulating',
  rawMaterials = 'raw-materials',
  modulus = 'modulus',
  porosity = 'porosity',
  cure = 'cure',
  adhesive = 'adhesive',
  cathodicDisbondment = 'cathodic-disbondment',
  epoxy = 'epoxy',
  hotWater = 'hot-water',
  impactUndercoating = 'impact-undercoating',
  indentation = 'indentation',
  oit = 'oit',
  polypropylene = 'polypropylene',
  tensilePropTopcoat = 'tensile-prop-topcoat',
  conductivity = 'conductivity',
  entrapped = 'entrapped',
  meltFlowIndex = 'melt-flow-index',
  densityGspp = 'density-gspp',
  weldingTest = 'welding-test',
  mlp = 'mlp',
  adhesion = 'adhesion',
  pullOff = 'pull-off',
  pelm = 'pelm',
  newTensileTemplate = 'new-tensile-template',
  newImpactTemplate = 'new-impact-template',
  cutback7Layer = 'cutback-7-layer',
  finalInspection3Layer = 'final-inspection-3-layer',
  finalInspection5Layer = 'final-inspection-5-layer',
  finalInspection7Layer = 'final-inspection-7-layer',
  incoming3Layer = 'incoming-3-layer',
  incoming5Layer = 'incoming-5-layer',
  incoming7Layer = 'incoming-7-layer',
  indCoilPreheat3Layer = 'ind-coil-preheat-3-layer',
  postBlasting3Layer = 'post-blasting-3-layer',
  sawStation5Layer = 'saw-station-5-layer',
  sawStation7Layer = 'saw-station-7-layer',
  thickness3Layer = 'thickness-3-layer',
  sinter7Layer = 'sinter-7-layer',
  finalInspection = 'final-inspection',
  iInductionCoilPreheat = 'iInduction-coil-preheat',
  incoming = 'incoming',
  postBlasting = 'post-blasting',
  thickness = 'thickness',
  roughCoat = 'rough-coat',
}
