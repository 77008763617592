<pn-field-container
  [labelKey]="labelKey"
  [label]="label"
  [control]="control"
>
  <div class="pn-date-container">
    <input
      type="text"
      [formControl]="control"
      clrDate
    />
  </div>
</pn-field-container>
