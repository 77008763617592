import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataBookBaseComponent } from 'src/app/pages/databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClrInputModule } from '@clr/angular';

@Component({
  selector: 'data-explorer-header',
  templateUrl: './data-explorer-header.component.html',
  styleUrls: ['./data-explorer-header.component.scss'],
  standalone: true,
  imports: [ClrInputModule, TranslateModule],
})
export class DataExplorerHeaderComponent extends DataBookBaseComponent {
  @Output() quickFilterEvent = new EventEmitter<string>();
  @Output() exportEvent = new EventEmitter<void>();
  @Input() isAnyFilterPresent: boolean;
  @Input() isLoadingForExcel: boolean;

  translatedFilterPlaceholder =
    this.coreService.translate('application.commons.buttons.filter') + '...';

  private getInput() {
    return document.getElementById('filter-text-box') as HTMLInputElement;
  }

  clearFilters() {
    this.getInput().value = '';
    this.quickFilterEvent.emit('');
  }

  // TODO create another function for the filter button with click event for searchValue
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  onFilterTextBoxChanged(searchValue?: string) {
    const { value } = this.getInput();
    this.quickFilterEvent.emit(value);
  }
}
