import { TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const moveFolder = async (
  dataBookService: DataBookService,
  folderId: number,
  newParentId: number,
): Promise<boolean> => {
  const params: { functionName; args: any } = {
    functionName: 'moveFolder',
    args: {
      folderId,
      newParentId,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};

export const moveFolders = async (
  dataBookService: DataBookService,
  foldersIds: number[],
  newParentId: number,
): Promise<boolean> => {
  const params: { functionName; args: any } = {
    functionName: 'moveFolders',
    args: {
      foldersIds,
      newParentId,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};

export const copyFolder = async (
  dataBookService: DataBookService,
  folderId: number,
  newParentId: number,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'copyFolder',
    args: {
      folderId,
      newParentId,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};

export const copyFolders = async (
  dataBookService: DataBookService,
  foldersIds: number[],
  newParentId: number,
): Promise<any> => {
  const params: { functionName; args: any } = {
    functionName: 'copyFolders',
    args: {
      foldersIds,
      newParentId,
    },
  };
  return await dataBookService.newLambda<any>(params);
};
