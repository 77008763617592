import { Component } from '@angular/core';
import { DataBookBaseComponent } from '../databook-base.component';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import {
  ClrIconModule,
  ClrCommonFormsModule,
  ClrCheckboxModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrModalModule,
  ClrSelectModule,
  ClrInputModule,
  ClrRadioModule,
} from '@clr/angular';
import { NgIf } from '@angular/common';
import { BasePageComponent } from '../../core/components/page/base-page.component';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'data-flow-project',
  templateUrl: './material-mgmt.component.html',
  styleUrls: ['./material-mgmt.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    NgIf,
    ClrIconModule,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    ClrCheckboxModule,
    FormsModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrModalModule,
    ReactiveFormsModule,
    ClrSelectModule,
    ClrInputModule,
    ClrRadioModule,
    TranslateModule,
  ],
})
export class DataBookMaterialMgmtComponent extends DataBookBaseComponent {
  items;
  reopenLock: any;
  listLoading = true;
  scopeForm: FormGroup;
  action = 'none';
  actionConfirmRemove = 'none';
  dataRemove;
  oldDataExplorer;

  async onPageInit() {
    await super.initProjectProduct();

    this.reopenLock = this.dataBookState.reopen;
    this.oldDataExplorer = this.dataBookState.olddataexplorerintegration;
    this.coreService.showLoading();
    this.scopeForm = new FormGroup({
      description: new FormControl('', [Validators.required]),
      service: new FormControl('', [Validators.required]),
      od: new FormControl('', [Validators.required]),
      wt: new FormControl('', [Validators.required]),
      endFinishing: new FormControl('', [Validators.required]),
      quantity: new FormControl('', [Validators.required]),
      unit: new FormControl('', [Validators.required]),
      grade: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      id: new FormControl(''),
      productId: new FormControl(''),
      order: new FormControl(''),
      itemOrder: new FormControl(''),
      revision: new FormControl(''),
      lastUser: new FormControl(''),
      lastDate: new FormControl(''),
      customerItemOrder: new FormControl(''),
      customerOrder: new FormControl(''),
    });

    const resultInfo = await this.dataBookService.qpScopeOfSupply({
      init: true,
      productId: this.store.selectedProduct.id,
    });

    console.log('resultInfo', resultInfo);

    try {
      this.listLoading = true;

      await this.findItems();

      this.fetchResults();

      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
      await this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }

  public test() {
    console.log('TESTE SCOPE OF SUPLY');
  }

  private async findItems() {
    this.items = await this.dataBookService.qpScopeOfSupply({
      list: 'all',
      productId: this.store.selectedProduct.id,
    });
  }

  fetchResults() {
    this.items = this.items.items;
  }

  isScopSupplyVisible() {
    if (
      this.isAdmin() ||
      this.isReadOnly() ||
      super.getRoleByProject().QUALITY_PLAN
    ) {
      return true;
    }
    return false;
  }

  async onNewClick() {
    this.scopeForm.reset();
    this.productId.setValue(this.store.selectedProduct.id);
    this.lastDate.setValue(new Date());
    this.lastUser.setValue(this.currentUser.email);
    this.action = 'new';
    this.editStyle();
  }

  editStyle() {
    setTimeout(() => {
      document.querySelector('#radioId div').className =
        'clr-control-container clr-col-md-9 clr-col-12';
    }, 100);
  }

  onDeleteClickConfirm(data) {
    this.dataRemove = data;
    this.actionConfirmRemove = 'open';
  }

  async onEditClick(scope: any) {
    this.scopeForm.reset();

    this.type.setValue(scope.material_type);
    this.description.setValue(scope.description);
    this.service.setValue(scope.service.toString());
    this.od.setValue(scope.od);
    this.wt.setValue(scope.wt);
    this.grade.setValue(scope.grade);
    this.endFinishing.setValue(scope.end_finishing);
    this.quantity.setValue(scope.quantity);
    this.unit.setValue(scope.unit);
    this.revision.setValue(scope.revision);
    this.id.setValue(scope.id);
    this.productId.setValue(scope.product_id);
    this.order.setValue(scope.material_order);
    this.itemOrder.setValue(scope.ordered_item);
    this.lastDate.setValue(scope.last_date_update);
    this.lastUser.setValue(scope.last_user_who_update);
    this.customerItemOrder.setValue(scope.customer_order_item);
    this.customerOrder.setValue(scope.customer_order);

    this.action = 'edit';
    this.editStyle();
  }

  substr(revision) {
    return revision ? revision.substr(0, 10) + '...' : '';
  }

  canEdit() {
    if (
      this.productHasFeature('materials') &&
      (this.getRoleByProject().QUALITY_PLAN ||
        this.isAdmin() ||
        this.getRoleByProject().MATERIALS.MATERIAL_WRITE)
    ) {
      return true;
    }
    return false;
  }

  cancelRemove() {
    this.dataRemove = null;
    this.actionConfirmRemove = 'none';
  }

  async onDeleteClick() {
    try {
      this.coreService.showLoading();
      this.listLoading = true;

      this.items = await this.dataBookService.qpScopeOfSupply({
        delete: this.dataRemove.id,
        productId: this.store.selectedProduct.id,
      });
      await this.coreService.showAlertSuccessTranslate(
        'material.removed.success',
      );
      this.fetchResults();

      this.listLoading = false;
      this.cancelRemove();
    } catch (error) {
      this.listLoading = false;
      await this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }

  async onSaveClick() {
    try {
      if (this.scopeForm.valid) {
        this.coreService.showLoading();
        const scope = this.scopeForm.value;
        scope.user = this.currentUser.email;
        const params = {
          save: scope,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
        };
        console.log('THIS IS PARAMS', params);
        this.cfgVlr(params);
        this.items = await this.dataBookService.qpScopeOfSupply(params);
        if (scope.id) {
          await this.coreService.showAlertSuccessTranslate(
            'material.modified.success',
          );
        } else {
          await this.coreService.showAlertSuccessTranslate(
            'material.created.success',
          );
        }

        this.fetchResults();

        this.action = 'none';
        this.coreService.hideLoading();
      }
    } catch (error) {
      await this.coreService.processError(error);
    }
  }

  async onCancelClick() {
    this.action = 'none';
  }

  get type() {
    return this.scopeForm.get('type');
  }

  get description() {
    return this.scopeForm.get('description');
  }

  get service() {
    return this.scopeForm.get('service');
  }

  get od() {
    return this.scopeForm.get('od');
  }

  get wt() {
    return this.scopeForm.get('wt');
  }

  get grade() {
    return this.scopeForm.get('grade');
  }

  get endFinishing() {
    return this.scopeForm.get('endFinishing');
  }
  get productId() {
    return this.scopeForm.get('productId');
  }
  get quantity() {
    return this.scopeForm.get('quantity');
  }

  get unit() {
    return this.scopeForm.get('unit');
  }

  get revision() {
    return this.scopeForm.get('revision');
  }

  get id() {
    return this.scopeForm.get('id');
  }

  get order() {
    return this.scopeForm.get('order');
  }
  get itemOrder() {
    return this.scopeForm.get('itemOrder');
  }
  get lastUser() {
    return this.scopeForm.get('lastUser');
  }
  get lastDate() {
    return this.scopeForm.get('lastDate');
  }

  get customerItemOrder() {
    return this.scopeForm.get('customerItemOrder');
  }
  get customerOrder() {
    return this.scopeForm.get('customerOrder');
  }

  async updateOldDataExplorer() {
    try {
      this.coreService.showLoading();
      const params = {
        updateOldDataExplorer: true,
        value: this.oldDataExplorer,
        productId: this.store.selectedProduct.id,
      };
      await this.dataBookService.project(params);
      this.dataBookState.olddataexplorerintegration = this.oldDataExplorer;
      await this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
      );
      this.coreService.hideLoading();
    } catch (error) {
      this.coreService.processError(error);
    }
  }

  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.material.project',
      null,
    );
    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },
      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },
      {
        name: this.coreService.translate('databook.dashboard', null),
        link: '/',
      },
    ];
  }

  getCondition() {
    return !this.store.selectedProject.gsi_id || !this.dataBookState.product;
  }
}
