import { Project } from '../project.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';
import { plainToInstance } from 'class-transformer';

export const findProjects = async (
  dataBookService: DataBookService,
  loadProducts = true,
): Promise<Project[]> => {
  const params: { functionName; args: any } = {
    functionName: 'findProjects',
    args: {
      loadProducts: loadProducts,
    },
  };
  const items: Project[] = await dataBookService.newLambda<Project[]>(params);
  return items.map((item: Project) => {
    return plainToInstance(Project, item);
  });
};
