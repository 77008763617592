import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { CoreService } from '@core/core.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { ClrInputModule, ClrCommonFormsModule } from '@clr/angular';

@Component({
  selector: 'input-form-control',
  templateUrl: './input-form-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./input-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputFormControlComponent),
    },
  ],
  standalone: true,
  imports: [
    ClrInputModule,
    ClrCommonFormsModule,
    NgClass,
    NgIf,
    TranslateModule,
  ],
})
export class InputFormControlComponent implements ControlValueAccessor {
  @Input()
  label: string | undefined;

  @Input()
  name: string | undefined;

  @Input()
  form: FormGroup;

  @Input()
  required = false;

  @Input()
  disabled = false;

  @Input()
  readonly = false;

  @Input()
  id?: string | undefined;

  @Input()
  clrInput?: boolean;

  @Input()
  autocomplete?: string | undefined;

  @Input()
  placeholder?: string | undefined;

  @Input()
  max?: string | undefined;

  @Input()
  min?: string | undefined;

  @Input()
  class?: string | undefined;

  @Input()
  type?: string | undefined;

  @Input()
  style?: string | undefined;

  @Input()
  maxLength?: number;

  @Input()
  minLength?: number;

  @Input()
  size?: number;

  value = '';

  constructor(public coreService: CoreService) {}

  onChange(event) {}
  onTouched() {}

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }

  get description() {
    return this.form.get(this.name);
  }

  get error(): string | undefined {
    if (
      this.form.controls[this.name].valid ||
      this.form.controls[this.name].untouched
    ) {
      return undefined;
    }

    if (this.form.controls[this.name].hasError('required')) {
      return this.coreService.translate('input.error.required', null);
    }

    if (this.form.controls[this.name].hasError('pattern')) {
      return this.coreService.translate('input.error.pattern', null);
    }

    if (this.form.controls[this.name].hasError('minlength')) {
      return this.coreService.translate('input.error.minlength', null);
    }

    if (this.form.controls[this.name].hasError('maxlength')) {
      return this.coreService.translate('input.error.maxlength', null);
    }

    return this.coreService.translate('input.error.default', null);
  }
}
