<vic-base-page page="dataExplorer-impact-new-template">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isPelmVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Order')"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Mill_Item')"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Pipe_Heat')"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Plate_num')"
            >{{ 'data.book.plate.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Test_avg_reduced_area')"
            >{{ 'data.book.test.avg.reduced.area' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Test_temperature')"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Pipe_wt')"
            >{{ 'data.book.pipe.wt' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Direction')"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Standard')"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Description_charpy')"
            >{{ 'data.book.description.charpy' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Sample_type')"
            >{{ 'data.book.sample.type' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Energy_1_j')"
            >{{ 'data.book.energy.1.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Energy_2_j')"
            >{{ 'data.book.energy.2.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Energy_3_j')"
            >{{ 'data.book.energy.3.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Mean_j')"
            >{{ 'data.book.mean.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Shear_1_per_cent')"
            >{{ 'data.book.shear.1.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Shear_2_per_cent')"
            >{{ 'data.book.shear.2.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Shear_3_per_cent')"
            >{{ 'data.book.shear.3.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Mean_per_cent')"
            >{{ 'data.book.mean.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Sample_num')"
            >{{ 'data.book.sample.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Specimen')"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Qualification')"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Condition')"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Cvn_1_j')"
            >{{ 'data.book.cvn.1.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Cvn_2_j')"
            >{{ 'data.book.cvn.2.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Cvn_3_j')"
            >{{ 'data.book.cnv.3.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Cvn_avg_j')"
            >{{ 'data.book.cvn.avg.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Shear_avg')"
            >{{ 'data.book.shear.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Lat_1')"
            >{{ 'data.book.lat.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Lat_2')"
            >{{ 'data.book.lat.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Lat_3')"
            >{{ 'data.book.lat.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Lat_avg')"
            >{{ 'data.book.lat.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Ordem')">{{
              data['Mill_Ordem']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Plate_num')">{{
              data['Plate_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_avg_reduced_area')">{{
              data['Test_avg_reduced_area']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_temperature')">{{
              data['Test_temperature']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_wt')">{{
              data['Pipe_wt']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Direction')">{{
              data['Direction']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Standard')">{{
              data['Standard']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Description_charpy')">{{
              data['Description_charpy']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Sample_type')">{{
              data['Sample_type']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Energy_1_j')">{{
              data['Energy_1_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Energy_2_j')">{{
              data['Energy_2_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Energy_3_j')">{{
              data['Energy_3_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mean_j')">{{
              data['Mean_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Shear_1_per_cent')">{{
              data['Shear_1_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Shear_2_per_cent')">{{
              data['Shear_2_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Shear_3_per_cent')">{{
              data['Shear_3_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mean_per_cent')">{{
              data['Mean_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Sample_num')">{{
              data['Sample_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Specimen')">{{
              data['Specimen']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Qualification')">{{
              data['Qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Condition')">{{
              data['Condition']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cvn_1_j')">{{
              data['Cvn_1_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cvn_2_j')">{{
              data['Cvn_2_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cvn_3_j')">{{
              data['Cvn_3_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Cvn_avg_j')">{{
              data['Cvn_avg_j']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Shear_avg')">{{
              data['Shear_avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lat_1')">{{
              data['Lat_1']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lat_2')">{{
              data['Lat_2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lat_3')">{{
              data['Lat_3']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lat_avg')">{{
              data['Lat_avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
