import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataBookDashboardComponent } from '../pages/dashboard/dashboard.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { pipeNavigatorRouteNames } from '../enums/routes.enum';
import { UploadTestComponent } from '../pages/upload-test/upload-test.component';

export const products = [
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/upload-test',
    component: UploadTestComponent,
    data: { routeName: pipeNavigatorRouteNames.uploadTest },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug',
    component: DataBookDashboardComponent,
    data: { routeName: pipeNavigatorRouteNames.productPage },
    resolve: {
      data: ProjectListResolver,
    },
  },
];
