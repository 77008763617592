<div
  class="loading-container"
  [hidden]="loading ? !loading : !coreService.isLoading()"
>
  <div class="loading-spinner">
    <span class="spinner spinner-md spinner-inline">
      {{ message ? message : 'Loading...' }}
    </span>
    <span>
      {{ message ? message : 'Loading...' }}
    </span>
  </div>
</div>
