import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataBookBaseComponent } from '../../databook-base.component';

import { tableHeads, tables } from '../new-tpi.digital.constants';

import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ClrDatagridModule,
  ClrIconModule,
  ClrPopoverHostDirective,
  ClrStopEscapePropagationDirective,
} from '@clr/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PdfService } from '../../../components/pdf/pdf.service';
import { CoreService } from '../../../core/core.service';
import { ConfigHandler } from '../../../core/handlers/config.handler';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import { DataBookService } from '../../../services/databook.service';
import { DataBookState } from '../../../services/databook.state';

@Component({
  selector: 'new-tpi-digital-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ClrDatagridModule,
    NgFor,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgIf,
    NgClass,
    ClrIconModule,
    TranslateModule,
  ],
})
export class NewTpiDigitalTable extends DataBookBaseComponent {
  @Input() id:
    | 'dataGridPendingDocuments'
    | 'dataGridAllDocuments'
    | 'dataGridProjectDocuments' = 'dataGridPendingDocuments';

  @Input() type: 'PENDING_TABLE' | 'ALL_DOCUMENTS' | 'PROJECT_DOCUMENTS' =
    'PENDING_TABLE';

  @Input() actionButtonOverflowId: 'Pending' | 'All' | 'Project' = 'Pending';

  @Input() selectedDocumentStatus: string;
  @Input() selectedDocument;
  @Input() documents;
  @Input() selectedColumn: string;
  @Input() viewDocument: (doc: any) => void;
  @Input() download: (doc: any) => void;
  @Input() select: (status: string, doc: any) => void;
  @Input() disableButton: (status: string, doc: any) => boolean;
  @Input() isReleaseVisible: () => boolean;
  @Input() isApprovalVisible: () => boolean;
  @Input() sort: (column: string) => void;
  @Input() handleSelectedDocument: (event: any[]) => void;

  tableHeads = tableHeads;
  tables = tables;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public config: ConfigHandler,
    private http: HttpClient,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      http,
      store,
      userStore,
    );
  }
}
