import { Component, Inject, ViewChild } from '@angular/core';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';
import { DataBookState } from '../../services/databook.state';
import { ConfigHandler } from '../../core/handlers/config.handler';
import { DataBookBaseComponent } from '../databook-base.component';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../../components/pdf/pdf.service';
import { HttpClient } from '@angular/common/http';
import {
  DataExplorerType,
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import {
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
} from '@clr/angular';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';

@Component({
  selector: 'databook-material',
  templateUrl: './material.component.html',
  styleUrls: ['material.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgIf,
    TranslateModule,
  ],
})
export class MaterialComponent extends DataBookBaseComponent {
  materials;
  selectedMaterial;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    public config: ConfigHandler,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  async onPageInit() {
    await super.initProjectProduct();
    try {
      this.coreService.showLoading();

      const paramsTorequest = {
        list: 'all',
        project: this.store.selectedProject.gsi_id,
        product: this.store.selectedProduct.gsi_id,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(paramsTorequest);
      const result = await this.dataBookService.materials(paramsTorequest);
      this.populateResult(result);

      this.coreService.hideLoading();
    } catch (error) {
      this.coreService.processError(error);
    }
  }

  populateResult(result) {
    this.materials = result.materials;
    this.store.materials = this.materials;

    for (const item of this.materials) {
      if (item.deliveryquantity) {
        if (this.coreService.getLanguage() === 'pt-BR') {
          item.deliveryquantity =
            Number(item.deliveryquantity).toLocaleString('pt-BR') +
            this.concatUnit(item.unit);
        } else {
          item.deliveryquantity =
            Number(item.deliveryquantity).toLocaleString('en-us') +
            this.concatUnit(item.unit);
        }
      }
      if (this.coreService.getLanguage() === 'pt-BR') {
        item.quantity = Number(item.quantity).toLocaleString('pt-BR');
      } else {
        item.quantity = Number(item.quantity).toLocaleString('en-us');
      }
    }
  }

  goToDataAnalysis() {
    this.store.gotoPage('data-analysis');
    // this.coreService.getRouter().navigate(['/main/data-analysis']);
  }

  gotToDataExplorer() {
    this.store.gotoPage('plp');
    // this.coreService.getRouter().navigate(['/main/dataExplorer']);
  }

  materialClick($event) {
    this.store.material = this.selectedMaterial;
    this.store.materialId = this.selectedMaterial.id;
    this.store.activePageForBreadcrumb = 'material';

    if (this.store.dataExplorerType === DataExplorerType.dataExplorer) {
      this.store.gotoDataExplorer(this.store.material);
    } else {
      this.goToDataAnalysis();
      this.store.gotoDataAnalysis(this.store.material);
    }
  }

  concatUnit(unit) {
    if (unit === 'meter') {
      return '(m)';
    } else if (unit === 'ton') {
      return '(ton)';
    } else if (unit === 'fts') {
      return '(fts)';
    }
    return '';
  }

  configBreadCrumb() {
    let nameDashboard = this.coreService.translate(
      'databook.data.analysis',
      null,
    );
    if (this.store.dataExplorerType === DataExplorerType.dataExplorer) {
      nameDashboard = this.coreService.translate(
        'databook.data.explorer',
        null,
      );
    }
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.material.project',
      null,
    );
    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },
      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },
      {
        name: nameDashboard,
        link: '/',
        type: this.coreService.translate('databook.dashboard', null),
      },
    ];
  }

  getCondition() {
    return !this.store.selectedProject.gsi_id || !this.dataBookState.product;
  }
}
