import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { DataBookBaseComponent } from '../../databook-base.component';
import { DataBookState } from '../../../services/databook.state';
import { DataBookService } from '../../../services/databook.service';
import { CoreService } from '../../../core/core.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../../../components/pdf/pdf.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormsModule } from '@angular/forms';
import { v1 as uuid } from 'uuid';
import {
  mountPathTree,
  validateEmail,
  getCookie,
  setCookie,
} from '../../../components/utils';
import * as _ from 'lodash';
import Axios from 'axios';
import { ITreeOptions } from '@circlon/angular-tree-component';
import {
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import { plainToInstance } from 'class-transformer';
import { TreeviewFolderItem, TreeviewItemContext } from '../../../classes';
import {
  FolderNameAndId,
  getRootFoldersForFrontEnd,
} from '../../../classes/treeview-helpers';
import { TranslateModule } from '@ngx-translate/core';
import { DuplicatedFlowsModalComponent } from './duplicated-flows-modal/duplicated-flows-modal.component';
import { OpenMailModalComponent } from '../config-workflow-modal/open-mail-modal/open-mail-modal.component';
import { ConfigWorkflowModalComponent } from '../config-workflow-modal/config-workflow-modal.component';
import { MySignaturesComponent } from '../../my-signatures/component/my-signatures.component';
import { Treeview } from '../../databook/treeview/treeview';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrInputModule,
  ClrSelectModule,
  ClrIconModule,
  ClrRadioModule,
  ClrTextareaModule,
  ClrModalModule,
} from '@clr/angular';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb.component';
import { NgIf, NgFor } from '@angular/common';
import { BasePageComponent } from '../../../core/components/page/base-page.component';

declare let LacunaWebPKI: any;
declare let LacunaSignerWidget: any;
declare let gtag;

@Component({
  selector: 'tpi-flow-release',
  templateUrl: './tpi-flow-mgmt.component.html',
  styleUrls: ['./tpi-flow-mgmt.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    NgIf,
    BreadcrumbComponent,
    FormsModule,
    ClrCommonFormsModule,
    NgxDropzoneModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrInputModule,
    ClrSelectModule,
    NgFor,
    ClrIconModule,
    ClrRadioModule,
    Treeview,
    ClrTextareaModule,
    ClrModalModule,
    MySignaturesComponent,
    ConfigWorkflowModalComponent,
    OpenMailModalComponent,
    DuplicatedFlowsModalComponent,
    TranslateModule,
  ],
})
export class DataBookMgmtFlowComponent
  extends DataBookBaseComponent
  implements OnDestroy
{
  @ViewChild('tree')
  tree;

  @ViewChild('dropzone')
  dropzone: any;

  @ViewChild('modalSesSign')
  modalSesSign: any;
  emailList = [];
  addedFiles = [];
  additionalFile = [];
  endState = false;
  fileSize = 400000000;
  accept = ['application/pdf']; // .pdf
  showTree = false;
  isMailConfirmOpen = false;

  openModalPki = 'none';
  canSign = false;
  pki = new LacunaWebPKI();

  widget;
  comment;
  certificateList = [];
  certificate;
  workflows = [];
  workflow;
  oldId;
  signatureType;
  different = false;
  configSignature;
  configSignatureList = [];
  configSignatureCacheList = [];
  transferTo;
  isConfigMySignatureOpen = false;
  isConfigWorkflowOpen = false;
  isDuplicatedFlowsModalOpen = false;
  currentSteps;
  currentStatus;
  nextSteps;
  embedUrl;
  documentIdToSign = '';
  listDocumentsIdToSign = [];

  duplicatedFiles: any[] = [];

  TYPES = {
    LACUNA: 'LACUNA',
    LACUNA_SES: 'LACUNA_SES',
    CUSTOM: 'CUSTOM',
  };

  openEmailType = {
    create: true,
    update: false,
  };

  FILE_CHUNK_SIZE = 25 * 1024 * 1024;
  FILE_MIN_CHUNK_SIZE = 5 * 1024 * 1024;

  nodes = null;
  eDoc = 'DATABOOK';

  /**
   *  Tree file configuration.
   */
  options: ITreeOptions = {
    allowDrag: false,
    useCheckbox: true,
    isExpandedField: 'expanded',
  };

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    private http: HttpClient,
    @Inject(PROJECT_STORE) public projectStore: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      http,
      projectStore,
      userStore,
    );
  }

  async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy();
    this.widget = null;
  }

  onPageInit = async () => {
    this.treeviewStore.focusedItem = null;
    this.treeviewStore.selectedObjects = [];

    await super.initProjectProduct();

    await this.coreService.showLoading();

    this.configBreadCrumb();
    try {
      await this.initGeneralValues();
      await this.initSteps();
      await this.initTree();

      this.pki.init({
        // As soon as the component is ready we'll load the certificates.
        ready: () => {
          this.canSign = true;
        },

        defaultFail: (data) => {
          this.canSign = false;
          console.log(data);
        },
        notInstalled: (data) => {
          this.canSign = false;
        },
      });

      // TODO: remove when use lacuna
      // const observer = new MutationObserver((mutations) => {
      //     this.setDocToSign();
      // });
      // observer.observe(document, {attributes: false, childList: true, characterData: false, subtree: true});

      this.widget = new LacunaSignerWidget({
        enableRefusal: true,
        culture: 'en',
      });

      // this.widget.on(this.widget.events.documentSigned, (e) => {
      //     this.executeNextSignAndStartFlow(e, true);
      // });
      // this.widget.on(this.widget.events.documentApproved, (e) => {
      //     this.executeNextSignAndStartFlow(e, true);
      // });
      // this.widget.on(this.widget.events.documentRefused, (e) => {
      //     this.executeNextSignAndStartFlow(e, false);
      // });
      this.widget.listen((e) => {
        if (e.type === this.widget.events.documentSigned) {
          this.executeNextSignAndStartFlow(e, true);
        } else if (e.type === this.widget.events.documentApproved) {
          this.executeNextSignAndStartFlow(e, true);
        } else if (e.type === this.widget.events.documentRefused) {
          this.executeNextSignAndStartFlow(e, false);
        }
      });

      this.listCertificates();
      this.initCookieValues();
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
    await this.coreService.hideLoading();

    this.showTree = true;
  };

  sendGtag = async (eventAction) => {
    const param = {
      // feature:  'TPI page',
      // category: 'action',
      // event: eventAction,
      // pageName: '/main/new-tpi-digital',
      // userType: this.coreService.getConfig().currentUser.type,
      // project: this.store.selectedProject.gsi_id
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature: 'TPI page',
      spn_action: eventAction,
      spn_userType: this.coreService.getConfig().currentUser.type,
    };
    gtag('event', eventAction, param);
  };

  private removeEvent = () => {
    const win = window as any;
    const events = win.eventListeners().filter((evt) => {
      return evt.toString().includes('handler.callback(callbackModel);');
    });
    for (const evt of events) {
      win.removeEventListener('message', evt);
    }
  };

  private initGeneralValues = async () => {
    const completeName =
      this.currentUser?.firstName + ' ' + this.currentUser?.lastName;
    const result = await this.dataBookService.tpiDigitalInitialValues({
      user: this.userStore.user?.email,
      project: this.store.selectedProject.gsi_id,
      userName: completeName,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    });
    this.workflows = [];
    this.configSignatureCacheList = [];

    this.workflows.push(...result.workflows);

    if (this.oldId) {
      const filtered = this.workflows.filter((item) => {
        return item.id === this.oldId;
      });
      if (filtered && filtered.length) {
        this.workflow = filtered[0];
      }
    }

    this.configSignatureCacheList.push(...result.signatures);
    this.changeSignatureType(this.signatureType);
  };

  downladSigneDoc = async () => {
    await this.coreService.showLoading();
    const result = await this.dataBookService.signature({
      downladSigneDoc: this.documentIdToSign,
      preview: false,
      productId: this.store.selectedProduct.id,
    });

    // const linkSource = `data:application/pdf;base64,${result.bytes}`;
    const downloadLink = document.createElement('a');
    // const fileName = result.name;
    downloadLink.href = result;
    // downloadLink.download = fileName;
    downloadLink.click();

    await this.coreService.hideLoading();
  };
  signAgainAgain = async () => {
    await this.coreService.showLoading();
    const result = await this.dataBookService.signature({
      urlItem: this.documentIdToSign,
      email: 'lucas.mferreira@ext.vallourec.com',
      id: '74660673952',
      productId: this.store.selectedProduct.id,
    });
    this.embedUrl = result.embedUrl;
    this.openSignature();
    await this.coreService.hideLoading();
  };

  startSignature = async () => {
    await this.coreService.showLoading();

    // this.documentIdToSign = await this.dataBookService.mySignature({ sign: true,
    //     project: this.store.selectedProject.gsi_id, product: this.store.selectedProduct.gsi_id,
    //     email: this.currentUser?.email, userName: this.currentUser?.firstName + ' ' + this.currentUser?.lastName});

    this.documentIdToSign = await this.dataBookService.tpiDigitalStartFlow({
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
      signature: this.configSignature,
      email: this.userStore.user?.email,
      userName:
        this.userStore.user?.firstName + ' ' + this.userStore.user?.lastName,
    });

    await this.coreService.hideLoading();
  };
  openSignature = () => {
    this.modalSesSign.open();
    this.modalSesSign.closable = false;
    // document.querySelector('div button.close').remove();
  };

  // TODO: remove when use lacuna
  setDocToSign = () => {
    const embed = document.getElementById('embed-container');
    if (embed) {
      if (embed.children && embed.children.length) {
        embed.removeChild(embed.children[0]);
      }

      this.widget.renderElement(this.embedUrl, embed, 1, 100);
      const styleName = 'style';
      embed.children[0][styleName] = 'height:1000px; width:1000px;';
    }
  };

  executeNextSignAndStartFlow = async (doc, approve) => {
    try {
      await this.coreService.showLoading();
      if (this.canUpload()) {
        const index = this.listDocumentsIdToSign.indexOf(doc.id);
        if (index === -1) {
          return;
        }

        if (approve) {
          const params: any = this.createStartParams(this.addedFiles[index]);
          params.startOnly = true;
          await this.dataBookService.tpiDigitalStartFlow(params);
        }

        this.closeSigner();

        if (index + 1 < this.listDocumentsIdToSign.length) {
          this.documentIdToSign = this.listDocumentsIdToSign[index + 1];
          this.signSes();
        } else {
          // await this.signAndStartFlow(true);
          this.removeEvent();
          this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
            qtd: this.listDocumentsIdToSign.length,
            action: approve ? 'Uploaded' : 'Rejected',
          });
          this.back();
        }
      } else if (
        (this.canApprove() || this.canReprove()) &&
        this.currentSteps?.length
      ) {
        const items = this.currentSteps.map((item) => item.document_id);
        const index = items.indexOf(doc.id);

        if (index === -1) {
          return;
        } else if (index === 0) {
          await this.uploadAdditionalFile();
        }

        const addFile =
          this.additionalFile && this.additionalFile.length
            ? this.additionalFile[0]
            : null;

        if (approve) {
          // await this.proceedFlow('APPROVE');
          await this.executeProceed('APPROVE', null, null, addFile, index);
        } else {
          // await this.proceedFlow('REPROVE');
          await this.executeProceed('REPROVE', null, null, addFile, index);
        }

        this.closeSigner();

        if (index + 1 < items.length) {
          this.documentIdToSign = items[index + 1];
          this.signSes();
        } else {
          // await this.updaLoadAdditionalFile();
          this.removeEvent();
          this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
            qtd: items.length,
            action: approve ? 'Approved' : 'Rejected',
          });
          this.back();
        }
      }
      await this.coreService.hideLoading();
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
  };

  closeConfigMySignature = async () => {
    await this.initSelection();
    this.isConfigMySignatureOpen = false;
  };

  private initSelection = async () => {
    await this.coreService.showLoading();
    if (this.workflow) {
      this.oldId = this.workflow.id;
    }
    this.workflow = null;
    await this.initGeneralValues();
    await this.coreService.hideLoading();
  };

  closeConfigWorkflow = async () => {
    await this.initSelection();
    this.isConfigWorkflowOpen = false;
  };

  closeSigner = () => {
    const embed = document.getElementById('embed-container');
    if (embed && embed.children && embed.children.length) {
      const children = embed.children;
      if (children.length) {
        embed.removeChild(children[0]);
      }
    }
    this.modalSesSign.closable = true;
    this.modalSesSign.close();
    this.embedUrl = null;
  };

  initCookieValues = () => {
    const lastWf = getCookie('last_wf');
    const lastSign = getCookie('last_sign');
    if (lastWf) {
      for (const item of this.workflows) {
        if (
          (item.previous_workflow_id &&
            item.previous_workflow_id.toString() === lastWf.toString()) ||
          item.id.toString() === lastWf.toString()
        ) {
          this.workflow = item;
        }
      }
    }
    if (lastSign) {
      for (const item of this.configSignatureList) {
        if (item.id.toString() === lastSign.toString()) {
          this.configSignature = item;
        }
      }
    }
  };

  initTree = async () => {
    if (this.canStore()) {
      const rootFolders = (await getRootFoldersForFrontEnd(
        this.dataBookService,
        this.store.selectedProduct.id,
        this.eDoc === 'EDOC'
          ? TreeviewItemContext.edoc
          : TreeviewItemContext.databook,
      )) as FolderNameAndId[];

      this.treeviewStore.tree = rootFolders.map((item: FolderNameAndId) => {
        return plainToInstance(TreeviewFolderItem, {
          id: item.id,
          product_id: item.product_id,
          description: item.description,
          name: item.name,
          isRoot: true,
          phase: item.phase,
          fixed: item.fixed,
          parent_id: null,
          treeview_context: item.treeview_context,
          folders: [],
          files: [],
        });
      });
    }
    if (this.canTransfer()) {
      const params = {
        initEmails: true,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      const resultTree = await this.dataBookService.documents(params);
      this.emailList = _.sortBy(resultTree.emailList);
    }
  };

  // openConfirmMerge() {}
  // openSendEmailModal() {}
  // viewDocument2(sad: any) {}
  // updateFile2(sad: any) {}
  // openUpload2(sad: any) {}
  // downloadd() {}
  // downloadVersion(sad: any) {}
  // downloadMergedFilesArchive() {}

  initSteps = async () => {
    if (this.dataBookState?.idStepInstances?.length) {
      this.addedFiles = [];
      this.currentSteps = [];
      this.nextSteps = [];
      this.signatureType = null;
      let sameSignatureType = true;
      for (const idStep of this.dataBookState.idStepInstances) {
        const result = await this.dataBookService.tpiDigitalInitialValues({
          stepInstanceID: idStep,
          productId: this.store.selectedProduct.id,
        });
        const currentStep = result.currentStep;
        const nextStep = result.nextStep;

        this.currentSteps.push(currentStep);
        this.nextSteps.push(nextStep);

        const file = {
          description: currentStep.document_description,
          id: currentStep.document_id,
          revision: currentStep.document_revision,
          documentCode: currentStep.document_code,
          custom: currentStep.custom,
          name: currentStep.document_name,
          size: currentStep.document_length,
        };
        this.addedFiles.push(file);
        if (
          this.signatureType &&
          this.signatureType !== currentStep.signature_type
        ) {
          sameSignatureType = false;
        }
        this.signatureType = currentStep.signature_type;
      }
      if (sameSignatureType) {
        this.changeSignatureType(this.signatureType);
      } else {
        this.signatureType = null;
        this.configSignatureList = [];
        if (this.dataBookState.stepAction === 'APPROVE') {
          this.coreService.showAlertErrorTranslate(
            'tpi.signature.different.types',
          );
          this.different = true;
        }
      }
    }
  };

  populateResult(result) {
    for (const item of result.definitionDocument) {
      if (item.id === this.dataBookState.product) {
        this.nodes = item.children;
        break;
      }
    }
  }

  signatureRequired = () => {
    if (this.currentSteps && this.currentSteps.length) {
      for (const item of this.currentSteps) {
        if (item && item.mandatory) {
          return true;
        }
      }
    }
    return false;
  };

  isApproveReprove = () => {
    return (
      this.dataBookState.stepAction === 'APPROVE' ||
      this.dataBookState.stepAction === 'REPROVE'
    );
  };
  isAbleToSign = () => {
    return (
      this.dataBookState.stepAction === 'APPROVE' &&
      this.signatureType !== this.TYPES.LACUNA_SES
    );
  };

  isStepNotAvailable() {
    return !this.dataBookState?.stepAction;
  }

  isTPIVisible = () => {
    const roles = super.getRoleByProject();
    if (roles?.TPI_DIGITAL) {
      return true;
    }
    return false;
  };

  redirectToInstall = () => {
    window.open('https://get.webpkiplugin.com/Setup', '_blank');
  };

  goToConfigWorkflow = () => {
    this.isConfigWorkflowOpen = true;
  };

  gotToConfigSignature = () => {
    this.isConfigMySignatureOpen = true;
  };

  isLacunaSignature = () => {
    return (
      this.configSignature && this.configSignature.type === this.TYPES.LACUNA
    );
  };

  validateStorage = () => {
    let valid = true;
    if (this.tree.treeModel.selectedLeafNodes.length > 1) {
      this.coreService.showAlertErrorTranslate(
        'release.tree.is.already.selected',
      );
      valid = false;
    }
    if (this.tree.treeModel.selectedLeafNodes.length === 0) {
      this.coreService.showAlertErrorTranslate('release.no.tree.is.selected');
      valid = false;
    }
    return valid;
  };

  validateUpload = async () => {
    try {
      await this.coreService.showLoading();

      this.duplicatedFiles = [];

      if (!this.addedFiles.length) {
        this.coreService.showAlertErrorTranslate('tpi.files.required');
        return;
      }

      if (this.configSignature?.type === this.TYPES.LACUNA) {
        if (!this.certificateList || !this.certificateList.length) {
          this.listCertificates();
        }

        for (const cert of this.certificateList) {
          if (cert.email === this.configSignature.signature_provider_id) {
            this.certificate = cert;
            break;
          }
        }

        if (!this.certificate) {
          this.coreService.showAlertErrorTranslate(
            'tpi.lacuna.certificate.required',
          );
          return;
        }
      }

      if (!this.workflow) {
        this.coreService.showAlertErrorTranslate('tpi.workflow.required');
        return;
      }

      if (!this.signatureType) {
        this.coreService.showAlertErrorTranslate('tpi.signature.type.required');
        return;
      }

      for (const file of this.addedFiles) {
        if (!file.description || !file.description.length) {
          this.coreService.showAlertErrorTranslate(
            'tpi.file.doc.name.required',
            {
              fileName: file.name,
            },
          );
          return;
        }

        if (!file.revision || !file.revision.length) {
          this.coreService.showAlertErrorTranslate(
            'tpi.file.revision.required',
            {
              fileName: file.name,
            },
          );
          return;
        }

        const document = await this.dataBookService.getTpiFlowByName(
          file.name,
          this.store.selectedProduct.id,
        );

        if (document?.length) {
          this.duplicatedFiles.push(...document);
        }
      }

      await this.coreService.hideLoading();

      if (!this.duplicatedFiles?.length) {
        await this.upload();

        return;
      }

      this.isDuplicatedFlowsModalOpen = true;
    } catch (err) {
      console.log(err);

      await this.coreService.hideLoading();
    }
  };

  closeDuplicatedFilesModal = () => {
    this.isDuplicatedFlowsModalOpen = false;
  };

  prepareFilesToUpload(additional) {
    const files = [];
    const filesToUpload = additional ? this.additionalFile : this.addedFiles;
    for (const file of filesToUpload) {
      this.createItem(files, file);
    }
    return files;
  }

  private createItem(files: any[], file) {
    files.push({
      id: file.id,
      revision: file.revision,
      name: file.name,
      version: file.version,
      length: file.size,
      documentCode: file.documentCode,
      description: file.description,
      idLacuna: file.idLacuna,
      custom: file.custom,
    });
  }

  signSes = async () => {
    await this.coreService.showLoading();

    const filtered = _.find(
      this.currentSteps,
      (item) => item.document_id === this.documentIdToSign,
    );

    const result = await this.dataBookService.signature({
      urlItem: this.documentIdToSign,
      email: this.userStore.user?.email,
      id: null,
      order: filtered.order,
      productId: this.store.selectedProduct.id,
    });

    if (result === 'proceed_reject' || result === 'proceed_approval') {
      const items = this.currentSteps.map((item) => item.document_id);
      const index = items.indexOf(this.documentIdToSign);
      if (index === -1) {
        return;
      }
      const addFile =
        this.additionalFile && this.additionalFile.length
          ? this.additionalFile[0]
          : null;
      const action = result === 'proceed_approval' ? 'APPROVE' : 'REPROVE';
      await this.executeProceed(action, null, null, addFile, index);

      if (index + 1 === this.currentSteps.length) {
        this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
          qtd: this.currentSteps.length,
          action: result === 'proceed_approval' ? 'Approved' : 'Rejected',
        });
        this.back();
      }
    } else {
      this.embedUrl = result.embedUrl;
      this.openSignature();

      const myTimeout = setTimeout(() => {
        this.setDocToSign();
        clearTimeout(myTimeout);
      }, 1000);
    }
    await this.coreService.hideLoading();
  };

  upload = async () => {
    const filesOkToRemove = [];

    try {
      await this.coreService.showLoading();

      const itemsQuantity = this.addedFiles.length;

      if (this.configSignature?.type === this.TYPES.LACUNA) {
        const urls = await this.createPreSignedUrls(false, null);
        await this.uploadFile(urls, false, null);

        const signTokens = await this.startSign(false);
        const result = await this.completeSignAndStartFlow(signTokens);
        this.back();
      } else if (this.signatureType === 'LACUNA_SES') {
        const path =
          this.store.selectedProject.gsi_id +
          '/' +
          this.store.selectedProduct.gsi_id +
          '/tpi_digital/temp/';
        const urls = await this.createPreSignedUrls(false, path);
        await this.uploadFile(urls, false, path);

        this.listDocumentsIdToSign = [];

        for (const [index, file] of this.addedFiles.entries()) {
          const filesItem = [];
          this.createItem(filesItem, file);

          let paramsToStart = null;
          if (!this.configSignature) {
            paramsToStart = this.createStartParams(file);
          }

          const toSignDocs = await this.dataBookService.tpiDigitalStartFlow({
            paramsToStartItem: paramsToStart,
            project: this.store.selectedProject.gsi_id,
            product: this.store.selectedProduct.gsi_id,
            productId: this.store.selectedProduct.id,
            signatureType: this.signatureType,
            comment: this.comment,
            signature: this.configSignature,
            workflow: this.workflow,
            email: this.userStore.user?.email,
            files: filesItem,
            userName:
              this.userStore.user?.firstName +
              ' ' +
              this.userStore.user?.lastName,
          });
          this.listDocumentsIdToSign.push(toSignDocs.result[0].documentId);

          file.idLacuna = this.listDocumentsIdToSign[index];

          if (this.configSignature) {
            this.documentIdToSign = this.listDocumentsIdToSign[0];
            this.signSes();
          }

          filesOkToRemove.push(file);

          await this.coreService.showAlertSuccessTranslate(
            'tpi.success.operation.upload',
            { uploaded: file.name },
          );
        }

        const tempAdd = this.addedFiles.filter(
          (el) => !filesOkToRemove.includes(el),
        );

        this.addedFiles = tempAdd.slice();
        this.back();
      } else {
        const urls = await this.createPreSignedUrls(false, null);
        await this.uploadFile(urls, false, null);

        await this.signAndStartFlow(false);
        this.back();
      }
      this.sendGtag('upload');
      this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
        qtd: itemsQuantity,
        action: 'Uploaded',
      });
    } catch (error) {
      const tempAdd = this.addedFiles.filter(
        (el) => !filesOkToRemove.includes(el),
      );
      this.addedFiles = tempAdd.slice();

      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
    await this.coreService.hideLoading();
  };

  public createInitialGtag = () => {
    // fechamento dia 03/11 - 09:50
    for (let i = 0; i < 347; i++) {
      this.sendGtag('upload');
    }
    for (let i = 0; i < 573; i++) {
      this.sendGtag('approve');
    }
    for (let i = 0; i < 5; i++) {
      this.sendGtag('reject');
    }
    for (let i = 0; i < 96; i++) {
      this.sendGtag('cancel');
    }
    for (let i = 0; i < 1; i++) {
      this.sendGtag('acknowledged_rejection');
    }
    for (let i = 0; i < 242; i++) {
      this.sendGtag('store');
    }
    // 347 - upload
    // 573 - approve
    // 5 - reject
    // 96 - cancel
    // 1 - acknowledged_rejection
    // 242 - store
  };

  private signAndStartFlow = async (startOnly) => {
    for (const fileItem of this.addedFiles) {
      const params: any = this.createStartParams(fileItem);
      params.startOnly = startOnly;
      await this.dataBookService.tpiDigitalStartFlow(params);
    }
  };

  private createStartParams = (fileItem) => ({
    file: fileItem.idLacuna ? fileItem.idLacuna : fileItem.id,
    user: this.currentUser?.email,
    comment: this.comment,
    fileName: fileItem.name,
    fileLength: fileItem.size,
    custom: fileItem.custom,
    fileRevision: fileItem.revision,
    fileCode: fileItem.documentCode,
    fileDescription: fileItem.description,
    workflow: this.workflow,
    signature: this.configSignature,
    path: 'tpi_digital',
    project: this.store.selectedProject.gsi_id,
    product: this.store.selectedProduct.gsi_id,
    productId: this.store.selectedProduct.id,
    signatureType: this.signatureType,
    userWf: this.currentUser?.email,
    userWfName: this.currentUser?.firstName + ' ' + this.currentUser?.lastName,
  });

  private completeSignAndStartFlow = async (signTokens) => {
    for (let i = 0; i < signTokens.length; i++) {
      const token = signTokens[i];
      const fileItem = this.addedFiles[i];
      const params: any = this.createStartParams(fileItem);
      params.complete = token;
      await this.dataBookService.tpiDigitalStartFlow(params);
    }
  };

  private startSign = async (additional) => {
    const signTokens = [];
    for (const itemFile of this.addedFiles) {
      const params = {
        file: itemFile.id,
        signature: this.configSignature,
        initSign: true,
        productId: this.store.selectedProduct.id,
        userWf: this.userStore.user?.email,
        userWfName:
          this.userStore.user?.firstName + ' ' + this.userStore.user?.lastName,
      };
      let signToken = null;

      signToken = additional
        ? await this.dataBookService.tpiDigitalProceedFlow(params)
        : await this.dataBookService.tpiDigitalStartFlow(params);

      const token = await this.signWithRestPki(signToken);
      signTokens.push(token);
    }
    return signTokens;
  };

  signWithRestPki = async (result) => {
    return new Promise((resolve, reject) => {
      const signed = this.pki
        .signWithRestPki({
          token: result.token,
          thumbprint: this.certificate.thumbprint,
        })
        .success((obj) => {
          resolve(obj);
        })
        .fail((obj) => {
          reject(obj);
        });
    });
  };

  private uploadFile = async (result, additional, pathVal) => {
    const itemFiles = additional ? this.additionalFile : this.addedFiles;

    for (let i = 0; i < result.preSignedUrls.length; i++) {
      const preSignedUrl = result.preSignedUrls[i];

      if (preSignedUrl.uploadId) {
        const axios = Axios.create();
        delete axios.defaults.headers.put['Content-Type'];

        const promises = [];
        for (let j = 0; j < preSignedUrl.preSignedMultPart.length; j++) {
          const multPartPreSigned = preSignedUrl.preSignedMultPart[j];

          const start = j * this.FILE_CHUNK_SIZE;
          const end = (j + 1) * this.FILE_CHUNK_SIZE;
          let blob = null;

          if (itemFiles[i].size - end < this.FILE_MIN_CHUNK_SIZE) {
            blob = itemFiles[i].content.slice(start, itemFiles[i].size);
          } else if (j < preSignedUrl.preSignedMultPart.length) {
            blob = itemFiles[i].content.slice(start, end);
          } else {
            blob = itemFiles[i].content.slice(start);
          }

          promises.push(axios.put(multPartPreSigned, blob));
        }

        const resParts = await Promise.all(promises);
        const etagsPartNumberValue = resParts.map((part, index) => ({
          ETag: this.replaceAll(part.headers.etag, '"', ''),
          PartNumber: index + 1,
        }));
        const params = {
          completeMultiPart: additional
            ? 'tpi_digital_additional'
            : 'tpi_digital',
          parts: etagsPartNumberValue,
          path: pathVal ? pathVal + itemFiles[i].id : null,
          uploadId: preSignedUrl.uploadId,
          itemId: itemFiles[i].id,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);
        await this.dataBookService.documentPreSignedUrl(params);
      } else {
        await this.callHttpPut(
          preSignedUrl.preSignedMultPart[0],
          itemFiles[i].content,
          itemFiles[i].revision,
          itemFiles[i].name,
          null,
          null,
        );
      }
    }
  };

  private createPreSignedUrls = async (additional, tempPath) => {
    const filesToUpload = this.prepareFilesToUpload(additional);
    if (filesToUpload && filesToUpload.length) {
      const params = {
        user: this.userStore.user?.email,
        productId: this.store.selectedProduct.id,
        files: filesToUpload,
        path: tempPath ? true : false,
        uploadPart: tempPath
          ? tempPath
          : additional
            ? 'tpi_digital_additional'
            : 'tpi_digital',
      };
      this.cfgVlr(params);
      const result = await this.dataBookService.documentPreSignedUrl(params);
      return result;
    }
    return null;
  };

  validateApprove = () => {
    let valid = true;

    if (this.signatureRequired() && !this.configSignature) {
      this.coreService.showAlertErrorTranslate('tpi.certificate.required');
      valid = false;
    } else if (this.configSignature) {
      if (this.configSignature.type === this.TYPES.LACUNA) {
        if (!this.certificateList || !this.certificateList.length) {
          this.listCertificates();
        }
        for (const cert of this.certificateList) {
          if (cert.email === this.configSignature.signature_provider_id) {
            this.certificate = cert;
            break;
          }
        }
        if (!this.certificate) {
          this.coreService.showAlertErrorTranslate(
            'tpi.lacuna.certificate.required',
          );
          valid = false;
        }
      }
    }
    return valid;
  };

  private executeProceed = async (
    actionValue,
    fileItem,
    token,
    addFile,
    idx,
  ) => {
    await this.dataBookService.tpiDigitalProceedFlow({
      action: actionValue,
      currentStep: this.currentSteps[idx],
      nextStep: this.nextSteps[idx],
      comment: this.comment,
      signature: actionValue === 'APPROVE' ? this.configSignature : null,
      file: fileItem ? fileItem.id : null,
      addFile: addFile ? addFile.name : null,
      addLength: addFile ? addFile.size : null,
      addFileId: addFile ? addFile.id : null,
      addPath: addFile ? 'tpi_digital_additional' : null,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
      complete: token,
      userWf: this.userStore.user?.email,
      userWfName:
        this.userStore.user?.firstName + ' ' + this.userStore.user?.lastName,
    });
  };

  private uploadAdditionalFile = async () => {
    const urls = await this.createPreSignedUrls(true, null);
    if (urls) {
      await this.uploadFile(urls, true, null);
    }
  };

  private completeSignAndProceedFlow = async (signTokens, actionValue) => {
    for (const [index, signToken] of signTokens.entries()) {
      const token = signToken;
      const fileItem = this.addedFiles[index];
      const addFile = this.additionalFile?.length
        ? this.additionalFile[0]
        : null;

      await this.executeProceed(actionValue, fileItem, token, addFile, index);
    }

    await this.uploadAdditionalFile();
  };

  private proceedFlow = async (actionValue) => {
    for (const [index, fileItem] of this.addedFiles?.entries()) {
      const addFile =
        this.additionalFile && this.additionalFile.length
          ? this.additionalFile[0]
          : null;
      await this.executeProceed(actionValue, null, null, addFile, index);
    }

    await this.uploadAdditionalFile();
  };

  setDefaultSign = () => {
    if (this.signatureType === this.TYPES.LACUNA_SES) {
      this.configSignature = _.find(
        this.configSignatureCacheList,
        (item) => item.type === this.TYPES.LACUNA_SES,
      );
    }
  };

  approve = async () => {
    try {
      this.setDefaultSign();
      if (this.validateApprove()) {
        await this.coreService.showLoading();

        if (this.signatureType === this.TYPES.LACUNA_SES) {
          this.documentIdToSign = this.addedFiles[0].id;
          this.signSes();
        } else if (this.certificate) {
          const signTokens = await this.startSign(true);

          const result = await this.completeSignAndProceedFlow(
            signTokens,
            'APPROVE',
          );

          this.back();
          this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
            qtd: this.addedFiles.length,
            action: 'Approved',
          });
        } else {
          const result = await this.proceedFlow('APPROVE');

          this.back();
          this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
            qtd: this.addedFiles.length,
            action: 'Approved',
          });
        }
        this.sendGtag('approve');
      }
    } catch (error) {
      if (
        error?.stack?.includes('The document reached the maximum signatures')
      ) {
        this.coreService.showAlertError(
          'The document reached the maximum signatures (12).',
        );
      } else {
        await this.coreService.processError(error);
      }
    } finally {
      await this.coreService.hideLoading();
    }
  };

  reprove = async () => {
    try {
      if (this.comment?.trim().length) {
        await this.coreService.showLoading();

        for (const fileItem of this.addedFiles) {
          const filtered = _.find(
            this.currentSteps,
            (item) => item.document_id === fileItem.id,
          );

          if (
            filtered?.signature_type === this.TYPES.LACUNA_SES ||
            this.configSignature?.type === this.TYPES.LACUNA_SES
          ) {
            await this.dataBookService.signature({
              refusal: fileItem.id,
              comment: this.comment,
              productId: this.store.selectedProduct.id,
            });
          }
        }
        const result = await this.proceedFlow('REPROVE');
        this.back();
        this.sendGtag('reject');
        this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
          qtd: this.addedFiles.length,
          action: 'Rejected',
        });
      } else {
        this.coreService.showAlertErrorTranslate('error.comment.required');
      }
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
    await this.coreService.hideLoading();
  };

  executeEndFlow = async (completePath, idCategory, actionFlow) => {
    for (const [index, fileItem] of this.addedFiles.entries()) {
      const filtered = _.find(this.currentSteps, (item) => {
        return item.document_id === fileItem.id;
      });

      const finalComment = this.dataBookState.acknowledgeComment
        ? (this.comment ? this.comment + ' - ' : '') +
          this.dataBookState.acknowledgeComment
        : this.comment;

      const params = {
        file: fileItem.id,
        pathToStore: completePath,
        action: actionFlow,
        configType: filtered.signature_type,
        category: idCategory,
        currentStep: this.currentSteps[index],
        eDoc: this.eDoc === 'EDOC',
        productId: this.store.selectedProduct.id,
        comment: finalComment,
        fileDesc: fileItem.description,
        fileName: fileItem.name,
        fileCode: fileItem.documentCode,
        fileSize: fileItem.size,
        fileRevision: fileItem.revision,
        userWf: this.currentUser?.email,
        custom: fileItem.custom,
      };

      await this.dataBookService.tpiDigitalEndFlow(params);
    }
  };

  changeWf(wf) {
    setCookie(
      'last_wf',
      wf.id,
      365,
      this.dataBookService.config.environment.cookie_domain,
    );
  }

  changeTypeTree = async () => {
    this.initTree();
  };

  _store = async () => {
    try {
      const folder = this.treeviewStore.focusedItem as TreeviewFolderItem;

      if (folder) {
        await this.coreService.showLoading();
        const completePath = this.treeviewStore.getFolderPath(folder);

        await this.executeEndFlow(completePath, folder.id, 'STORE');

        this.sendGtag('store');
        this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
          qtd: this.addedFiles.length,
          action: 'Stored',
        });

        await this.coreService.hideLoading();
        this.back();
      } else {
        this.coreService.showAlertErrorTranslate('release.no.tree.is.selected');
      }
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
  };

  validateToSave = () => {
    let valid = true;
    const mailVal = this.transferTo;
    if (!mailVal || !mailVal.trim().length) {
      this.coreService.showAlertErrorTranslate('required.field.required', {});
      valid = false;
    } else if (!validateEmail(mailVal)) {
      this.coreService.showAlertErrorTranslate('databook.email.invalid');
      valid = false;
    }
    return valid;
  };

  cancelAddEmail = () => {
    this.isMailConfirmOpen = false;
  };

  confirmEmail = () => {
    this.execTransferTo();
    this.isMailConfirmOpen = false;
  };

  execTransferTo = async () => {
    for (let i = 0; i < this.currentSteps.length; i++) {
      if (this.signatureType === 'LACUNA_SES') {
        await this.dataBookService.signature({
          transfer: this.addedFiles[i].id,
          step: this.currentSteps[i].order,
          transferTo: this.transferTo,
        });
      }
      await this.dataBookService.tpiDigitalCancelTransferFlow({
        currentStep: this.currentSteps[i],
        comment: this.comment,
        transfer: true,
        transferTo: this.transferTo,
        userWf: this.currentUser?.email,
        productId: this.store.selectedProduct.id,
      });
    }
    this.sendGtag('transfer');
    this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
      qtd: this.currentSteps.length,
      action: 'Transferred',
    });
    this.back();
  };

  getEmailMessage = () => {
    if (!this.transferTo) return '';

    return (
      this.transferTo.toLowerCase().trim() +
      this.coreService.translate('confirm.add.wrong.email', null)
    );
  };

  transfer = async () => {
    if (!this.coreService.isLoading()) {
      await this.coreService.showLoading();
      try {
        if (this.validateToSave()) {
          if (!this.emailList.includes(this.transferTo.toLowerCase().trim())) {
            this.isMailConfirmOpen = true;
          } else {
            await this.execTransferTo();
          }
        }
      } catch (error) {
        await this.coreService.processError(error);
        await this.coreService.hideLoading();
      }
      await this.coreService.hideLoading();
    }
  };

  cancel = async () => {
    try {
      await this.coreService.showLoading();
      for (const curr of this.currentSteps) {
        await this.dataBookService.tpiDigitalCancelTransferFlow({
          currentStep: curr,
          comment: this.comment,
          cancel: true,
          userWf: this.userStore.user?.email,
        });
        for (const fileItem of this.addedFiles) {
          const filtered = _.find(
            this.currentSteps,
            (item) => item.document_id === fileItem.id,
          );

          if (
            filtered?.signature_type === this.TYPES.LACUNA_SES ||
            this.configSignature?.type === this.TYPES.LACUNA_SES
          ) {
            await this.dataBookService.signature({
              cancellation: fileItem.id,
              comment: this.comment,
            });
          }
        }
      }
      this.sendGtag('cancel');
      this.back();
      this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
        qtd: this.currentSteps.length,
        action: 'Canceled',
      });
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
    await this.coreService.hideLoading();
  };

  acknowledge = async () => {
    try {
      await this.coreService.showLoading();

      await this.executeEndFlow(null, null, 'ACKNOWLEDGE');

      this.back();
      this.coreService.showAlertSuccessTranslate('tpi.success.operation', {
        qtd: this.addedFiles.length,
        action: 'Acknowledged',
      });
      this.sendGtag('acknowledged_rejection');
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
    await this.coreService.hideLoading();
  };

  canUpload = () => this.isStepNotAvailable() && this.isReleaseVisible();

  canApprove = () =>
    this.dataBookState.stepAction === 'APPROVE' && this.isApprovalVisible();

  canReprove = () =>
    this.dataBookState.stepAction === 'REPROVE' && this.isApprovalVisible();

  canAcknowledge = () =>
    this.dataBookState.stepAction === 'ACKNOWLEDGE' && this.isReleaseVisible();

  canStore = () =>
    this.dataBookState.stepAction === 'STORE' && this.isReleaseVisible();

  canTransfer = () =>
    this.dataBookState.stepAction === 'TRANSFER' && this.isReleaseVisible();

  canCancel = () =>
    this.dataBookState.stepAction === 'CANCEL' && this.isReleaseVisible();

  isReleaseVisible = () => {
    if (super.getRoleByProject()?.TPI_DIGITAL?.RELEASE) {
      return true;
    }
    return false;
  };

  isApprovalVisible = () => {
    if (super.getRoleByProject()?.TPI_DIGITAL?.APPROVAL) {
      return true;
    }
    return false;
  };

  onFilesAdded = async (files: any[], additional) => {
    let allFilesAccepted = true;

    for (const file of files) {
      if (!this.accept?.includes(file.type)) {
        allFilesAccepted = false;
        continue;
      }

      if (file.size > 31457280) {
        // 10485760 = 10mb
        // 31457280 = 30mb
        // 41943040 = 40mb
        this.coreService.showAlertErrorTranslate('tpi.signature.file.size');
        continue;
      }

      const content = await this.readFile(file, additional);

      if (!content) {
        this.coreService.showAlertErrorTranslate('databook.encrypt.problem');
      }

      file.content = content;
      file.description = file.name;
      file.id = uuid();
      file.reportNumber = '';
      file.revision = '0';
      file.documentCode = '0';
      file.custom = '';
      if (additional) {
        this.additionalFile = [];
        this.additionalFile.push(file);
      } else {
        this.addedFiles.push(file);
      }
    }

    if (!allFilesAccepted) {
      this.coreService.showAlertErrorTranslate('databook.content.type.problem');
    }
  };

  readFile = async (file, additional) => {
    const content = (await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: ProgressEvent) => {
        const arr = new Uint8Array((e.target as any).result).subarray(0, 4);
        let header = '';
        for (const item of arr) {
          header += item.toString(16);
        }
        resolve({ content: (e.target as FileReader).result, mime: header });
      };
      reader.onloadend = (e: ProgressEvent) => {};
    })) as any;

    try {
      const contentFile = new Blob([content.content as any]);
      const textFile = await contentFile.text().then();
      if (
        (textFile.includes('Encrypt') ||
          textFile
            .substring(textFile.lastIndexOf('<<'), textFile.lastIndexOf('>>'))
            .includes('/Encrypt') ||
          content.mime !== '25504446') &&
        !additional
      ) {
        content.content = null;
      }
    } catch (e) {
      console.log(e);
      content.content = null;
    }
    return content.content;
  };

  back = () => {
    this.store.gotoPage('new-tpi-digital');
    // this.coreService.getRouter().navigate(['/main/new-tpi-digital']);
  };

  delete = (name, additional) => {
    if (additional) {
      const files = _.filter(this.additionalFile, (item) => item.name !== name);

      this.additionalFile = files;
    } else {
      const files = _.filter(this.addedFiles, (item) => item.name !== name);

      this.addedFiles = files;
    }

    this.duplicatedFiles = this.duplicatedFiles.filter(
      ({ document_name }) => document_name !== name,
    );
  };

  download = async (id, nameVal, additional) => {
    try {
      await this.coreService.showLoading();

      const filtered = _.find(this.currentSteps, (item) => {
        return item.document_id === id;
      });

      if (filtered?.signature_type === this.TYPES.LACUNA_SES) {
        const result = await this.dataBookService.signature({
          downladSigneDoc: id,
          preview: false,
        });

        // const linkSource = `data:application/pdf;base64,${result.bytes}`;
        const downloadLink = document.createElement('a');
        // const fileName = result.name;
        downloadLink.href = result;
        // downloadLink.download = fileName;
        downloadLink.click();
      } else {
        const params = {
          getObject: additional ? 'tpi_digital_additional' : 'tpi_digital',
          fileId: id,
          name: nameVal,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);
        const result = await this.dataBookService.documentPreSignedUrl(params);

        const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
        a.download = nameVal;
        a.href = result.url;
        a.click();
      }
    } catch (error) {
      await this.coreService.processError(error);
      await this.coreService.hideLoading();
    }
    await this.coreService.hideLoading();
  };

  changeSignatureType(signatureType) {
    this.configSignatureList = this.configSignatureCacheList.filter((item) => {
      return item.type === signatureType;
    });
  }
  changeSignature(config) {
    if (config?.type === this.TYPES.LACUNA && !this.canSign) {
      this.openModalPki = 'open';
    } else {
      this.openModalPki = 'none';
    }
    this.certificate = null;

    setCookie(
      'last_sign',
      config.id,
      365,
      this.dataBookService.config.environment.cookie_domain,
    );
  }

  close = () => {
    this.openModalPki = 'none';
  };

  refreshCertificates = async () => {
    await this.coreService.showLoading();
    await this.listCertificates();
    await this.coreService.hideLoading();
  };

  certificates(): any {
    return new Promise((resolve, reject) => {
      this.pki
        .listCertificates()
        .success((obj) => {
          resolve(obj);
        })
        .fail((obj) => {
          reject(obj);
        });
    });
  }

  listCertificates = async () => {
    this.certificateList = await this.certificates();
    // if (this.store.selectedProject.gsi_id === 'XPTO') {
    //     this.certificateList.push(this.getSampleCert('VICENTE TRINDADE - RELEASER',
    //         'vicente.trindade@vallourec.com', '11111111111'));
    //     this.certificateList.push(this.getSampleCert('VICENTE TRINDADE - TPI',
    //         'vicente.trindade@vallourec.com', '22222222222'));
    // }
    await this.coreService.hideLoading();
  };

  getSampleCert(nameDsc, emailDsc, cpfVal) {
    return {
      email: emailDsc,
      issuerName: 'AC SOLUTI Multipla',
      keyUsage: {
        crlSign: false,
        dataEncipherment: false,
        decipherOnly: false,
        digitalSignature: true,
        encipherOnly: false,
        keyAgreement: false,
        keyCertSign: false,
        keyEncipherment: true,
        nonRepudiation: true,
      },
      pkiBrazil: {
        certificateType: 'A3',
        cnpj: null,
        companyName: null,
        cpf: cpfVal,
        dateOfBirth:
          'Tue Nov 14 1978 00:00:00 GMT-0300 (Brasilia Standard Time)',
        isAplicacao: false,
        isPessoaFisica: true,
        isPessoaJuridica: false,
        nis: null,
        oabNumero: null,
        oabUF: null,
        responsavel: nameDsc,
        rgEmissor: null,
        rgEmissorUF: null,
        rgNumero: null,
      },
      pkiItaly: { codiceFiscale: null },
      subjectName: nameDsc,
      thumbprint: 'eca5f24b-88c3-4efc-bc1d-0d6181e12d9e',
      validityEnd: 'Tue Feb 15 2022 08:52:00 GMT-0300 (Brasilia Standard Time)',
      validityStart:
        'Tue Feb 19 2019 16:46:32 GMT-0300 (Brasilia Standard Time)',
    };
  }

  configBreadCrumb = () => {
    let bread = 'Upload Document';
    if (this.canApprove()) {
      bread = 'Approve Document';
    } else if (this.canReprove()) {
      bread = 'Reject Document';
    } else if (this.canAcknowledge()) {
      bread = 'Acknowledge rejection';
    } else if (this.canTransfer()) {
      bread = 'Transfer Document';
    } else if (this.canStore()) {
      bread = 'Store Document';
    } else if (this.canCancel()) {
      bread = 'Cancel';
    }
    this.breadcrumbCurrent = bread;
    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },
      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },
      {
        name: this.coreService.translate('databook.dashboard', null),
        link: '/',
      },
      {
        name: this.coreService.translate('databook.tpi.digital', null),
        link: '/main/new-tpi-digital',
      },
    ];
  };

  getCondition = () => {
    return !this.store.selectedProject.gsi_id || !this.dataBookState.product;
  };
}
