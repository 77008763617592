import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ClrCommonFormsModule,
  ClrInputModule,
  ClrModalModule,
  ClrSelectModule,
} from '@clr/angular';
import { CoreService } from '@core/core.service';
import { objToLabelValues } from '@functions/label-value';
import { LabelValue } from '@interfaces/label-value';
import { ProductType } from '@interfaces/product';
import { PROJECT_STORE, ProjectsStore } from '@mobx-stores/projects.store';
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from '@reactive-forms/components/input/input.component';
import { SelectComponent } from '@reactive-forms/components/select/select.component';
import { DataBookService } from '@services/databook.service';
import { ProductModal } from '../product.types';

interface ProductForm {
  name: FormControl<string>;
  type: FormControl<string>;
  description: FormControl<string>;
  supplier: FormControl<string>;
  lastModifiedBy: FormControl<string>;
  lastModificationDate: FormControl<string>;
}

@Component({
  selector: 'create-edit-product-modal',
  templateUrl: './create-edit-product-modal.component.html',
  standalone: true,
  imports: [
    ClrModalModule,
    ClrCommonFormsModule,
    ClrSelectModule,
    FormsModule,
    ClrInputModule,
    CommonModule,
    TranslateModule,
    SelectComponent,
    InputComponent,
    ReactiveFormsModule,
  ],
})
export class CreateEditProductModalComponent implements OnInit {
  @Input() isEditMode: boolean;
  @Input() form: FormGroup;
  @Input() closeModal: () => void;
  @Input() modal: ProductModal;
  @Input() modalIsOpen = false;

  productTypesLabelValues: LabelValue[] = objToLabelValues(
    ProductType,
    'product.productTypeValues',
  );

  productForm: FormGroup<ProductForm>;

  constructor(
    private coreService: CoreService,
    private dataBookService: DataBookService,
    @Inject(PROJECT_STORE) private store: ProjectsStore,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.productForm = this.fb.group({
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      supplier: [''],
      description: [''],
      lastModifiedBy: [{ value: '', disabled: true }],
      lastModificationDate: [{ value: '', disabled: true }],
    });

    if (this.isEditMode) {
      const {
        name,
        type,
        supplier,
        lastmodifiedby,
        lastmodifieddate,
        description,
      } = this.store.selectedProduct;

      this.productForm.patchValue({
        name,
        type,
        supplier,
        description,
        lastModifiedBy: lastmodifiedby,
        lastModificationDate: this.coreService.formatDate(lastmodifieddate),
      });

      if (type) {
        this.productForm.get('type').disable();
      }
    }
  }

  async onSubmit(): Promise<void> {
    try {
      this.coreService.showLoading();

      const { name, supplier, description, type } =
        this.productForm.getRawValue();

      await this.dataBookService.product({
        projectId: this.store.selectedProject.id,
        id: this.store.selectedProduct?.id,
        name,
        supplier,
        description,
        newProduct: this.modal.isSave,
        update: this.modal.isEdit,
        self: this.coreService.getConfig().currentUser.email,
        type,
      });
      await this.store.reloadProducts(this.dataBookService);

      // TODO: find the difference between edit mode and modal.isEdit
      if (this.modal.isEdit) {
        await this.coreService.showAlertSuccessTranslate(
          'product.modified.success',
        );
      } else {
        await this.coreService.showAlertSuccessTranslate(
          'product.created.success',
        );
      }
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
      this.closeModal();
    }
  }
}
