<div class="clr-row pipe-navigator-gap-1">
  <div class="clr-col">
    <div class="filter-container">
      <clr-input-container class="input-filter">
        <input
          clrInput
          type="text"
          size="25"
          id="filter-text-box"
          [placeholder]="translatedFilterPlaceholder"
          (input)="onFilterTextBoxChanged($event.target.value)"
        />
      </clr-input-container>
      <img
        class="filter-icon"
        [src]="
          isAnyFilterPresent
            ? 'assets/img/svg/filter-off.svg'
            : 'assets/img/svg/filter.svg'
        "
        (click)="isAnyFilterPresent ? clearFilters() : onFilterTextBoxChanged()"
        alt="filter"
        style="cursor: pointer"
        width="25"
        height="25"
      />
    </div>
  </div>

  <div class="clr-col-auto">
    <button
      class="btn btn-primary"
      (click)="exportEvent.emit()"
      [disabled]="isLoadingForExcel"
    >
      {{ 'application.commons.buttons.export.to.excel' | translate }}
    </button>
  </div>
</div>
