<pn-field-container
  [labelKey]="labelKey"
  [label]="label"
  [control]="control"
>
  <div class="clr-select-wrapper">
    <select
      class="clr-select"
      [formControl]="control"
    >
      <option
        *ngFor="let option of options"
        [value]="option.value"
      >
        {{ option.label || (option.labelKey | translate) }}
      </option>
    </select>
  </div>
</pn-field-container>
