import {
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../treeview-item.class';
import { DataBookService } from '../../services/databook.service';

export const getFolderAndFilesFromFolderIdAndProductId = async (
  dataBookService: DataBookService,
  productId: number,
  context: TreeviewItemContext,
  folderId?: number,
): Promise<TreeviewFolderItem[] | TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'getFolderAndFilesFromFolderIdAndProductId',
    args: {
      productId,
      context,
      folderId,
    },
  };
  return await dataBookService.newLambda<
    TreeviewFolderItem[] | TreeviewFolderItem | undefined
  >(params);
};

export const getRootFolders = async (
  dataBookService: DataBookService,
  productId: number,
  context: TreeviewItemContext,
): Promise<TreeviewFolderItem[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getRootFolderFromProduct',
    args: {
      productId,
      context,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem[]>(params);
};
