import { Component, OnInit } from '@angular/core';
import { PlpBaseComponent } from '../plp-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterColumnRangeComponent } from '../../../components/filter-column-range/filter-column-range.component';
import { FilterColumnComponent } from '../../../components/filter-column/filter-column.component';
import { FilterComponent } from '../../../components/filter/filter.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb.component';
import { NgIf } from '@angular/common';
import {
  ClrIconModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrModalModule,
} from '@clr/angular';
import { BasePageComponent } from '../../../core/components/page/base-page.component';

@Component({
  selector: 'density-gspp',
  templateUrl: './density-gspp.component.html',
  styleUrls: ['./density-gspp.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    ClrIconModule,
    NgIf,
    BreadcrumbComponent,
    FilterComponent,
    FilterColumnComponent,
    FilterColumnRangeComponent,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrModalModule,
    TranslateModule,
  ],
})
export class DensityGsppComponent extends PlpBaseComponent {
  public async getColumnNames(language) {
    const params = { test: 'incoming-3-layer', lang: language };
    this.cfgVlr(params);
    return await this.dataBookService.getTests(params);
  }

  public getPageSize() {
    return '1500-1137';
  }

  public getLineSize() {
    return 1430;
  }

  getTest() {
    return 'incoming-3-layer';
  }

  configCurrentTestBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'dataExplorer.test.density.gspp',
      null,
    );
  }

  getCondition() {
    return (
      !this.store.selectedProject.gsi_id ||
      !this.store.selectedProduct.gsi_id ||
      !this.store.materialId
    );
  }

  isDensityGspp() {
    if (super.getRoleByProject().DATA_EXPLORER) {
      return true;
    }
    return false;
  }
}
