import { action, computed, observable } from 'mobx-angular';
import { ElementRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeAutoObservable } from 'mobx';
import { Router, RouterStateSnapshot } from '@angular/router';
import {
  Features,
  Material,
  PermissionDTO,
  PermissionMatriceItemDto,
  PermissionMatriceProjectsDto,
  Product,
  Project,
  UserDTO,
} from '../classes';
import Cookies from 'js-cookie';
import { CoreService } from '../core/core.service';
import { ConfigHandler } from '../core/handlers/config.handler';
import { PROJECT_STORE, ProjectsStore } from './projects.store';
import { TREEVIEW_STORE, TreeviewStore } from './treeview.store';
import { DataBookService } from '../services/databook.service';
import {
  getAllPermissions,
  getAllPermissionsForUser,
  getAllProjectsForUserPermissions,
  getPermissions,
  getUsers,
  productGetFeaturesForUserPermissions,
  projectGetProductsForUserPermissions,
  savePermissionForUser,
} from '../classes/user-helpers';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  @observable user: any | null = null;
  @observable settings: any[] = [];
  httpClient: HttpClient;
  router: Router;

  protected ALL_PROJECTS_READ_ONLY = 'ALL_PROJECTS_READ_ONLY';
  protected ALL_PRODUCTS = 'ALL_PRODUCTS';

  constructor(
    private config: ConfigHandler,
    private coreService: CoreService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    makeAutoObservable(this);
  }

  @action
  async loadUserData(): Promise<void> {
    const token = await this.getAuthToken();
    await this.getUserVerify(token);
    this.user = await this.getUserDetail();
    this.coreService.currentUser = this.user;
  }

  public async getAuthToken(): Promise<string> {
    const cookie = this.coreService.getCookie('_ssoToken');
    return cookie;
  }

  public async getUserDetail(): Promise<any> {
    const options = {};
    const response = await this.coreService.callHttpPost(
      this.config.environment.endpoints.security + '/user/detail',
      null,
      options,
    );
    return response;
  }

  public async getUserVerify(token): Promise<any> {
    const options = { verifyToken: token };
    const response = await this.coreService.callHttpPost(
      this.config.environment.endpoints.security + '/user/verify',
      null,
      options,
    );
    return response;
  }

  public async loadVefifyToken(verifyToken): Promise<void> {
    const response = await this.getUserVerify(verifyToken);
    const expiresValue = new Date(new Date().getTime() + 60 * 60 * 1000); // expires in 60 minutes
    Cookies.set('_ssoToken', response.data, {
      expires: expiresValue,
      domain: this.config.environment.cookie_domain,
      path: '/',
    });
  }

  public async loadUserDetails(): Promise<boolean> {
    if (!this.user) {
      const response = await this.getUserDetail();
      this.user = response.user;
      this.coreService.currentUser = this.user;
      this.settings = response.rootSettings;
    }
    return true;
  }

  public async reloadUserDetails(): Promise<void> {
    const response = await this.getUserDetail();
    this.user = response.user;
    this.coreService.currentUser = this.user;
    this.settings = response.rootSettings;
  }

  hasAccess(permission: 'create' | 'read' | 'update' | 'delete'): boolean {
    const project: Project = this.store.selectedProject;
    const product: Product = this.store.selectedProduct;
    const material: Material = this.store.material;
    const treeviewContext = this.treeviewStore.context;

    return true;
  }

  isAdmin() {
    return this.user.roles.PROJECT_CONFIG;
  }

  productHasFeature(product: Product, feature: string) {
    if (!product.features) return false;
    return (
      product.features.filter((_feature: Features) => {
        return _feature.name === feature;
      }).length > 0
    );
  }
  isReadOnly() {
    if (this.user?.roles?.PROJECTS[this.ALL_PROJECTS_READ_ONLY]) {
      return this.user.roles.PROJECTS[this.ALL_PROJECTS_READ_ONLY].PRODUCT[
        this.ALL_PRODUCTS
      ];
    }
    return false;
  }

  getRoleByProject(projectGsiId: string, productGsiId: string) {
    if (this.user?.roles?.PROJECTS[projectGsiId]) {
      return this.user.roles.PROJECTS[projectGsiId].PRODUCT[productGsiId];
    } else {
      if (this.user?.roles?.PROJECTS[this.ALL_PROJECTS_READ_ONLY]) {
        return this.user.roles.PROJECTS[this.ALL_PROJECTS_READ_ONLY].PRODUCT[
          this.ALL_PRODUCTS
        ];
      }
    }
  }

  isDataAnalysisVisible(projectGsiId: string, productGsiId: string) {
    if (this.getRoleByProject(projectGsiId, productGsiId).DATA_ANALYSIS) {
      return true;
    }
    return false;
  }

  isDatabookVisible(projectGsiId: string, productGsiId: string) {
    if (this.getRoleByProject(projectGsiId, productGsiId).DATABOOK) {
      return true;
    }
    return false;
  }

  isEDocVisible(projectGsiId: string, productGsiId: string) {
    if (this.getRoleByProject(projectGsiId, productGsiId).E_DOC) {
      return true;
    }
    return false;
  }

  isDataExplorerVisible(projectGsiId: string, productGsiId: string) {
    const roleByProject = this.getRoleByProject(projectGsiId, productGsiId);
    if (roleByProject.DATA_EXPLORER) {
      return true;
    }
    return false;
  }

  isTpiVisible(projectGsiId: string, productGsiId: string) {
    const role = this.getRoleByProject(projectGsiId, productGsiId);
    if (role.TPI_DIGITAL) {
      return true;
    }
    return false;
  }

  isQpVisible(projectGsiId: string, productGsiId: string) {
    if (
      this.isReadOnly() ||
      this.getRoleByProject(projectGsiId, productGsiId).QUALITY_PLAN
    ) {
      return true;
    }
    return false;
  }

  @observable products: Product[] = [];
  @observable selectedProduct: Product | null = null;

  @observable projects: Project[] = [];
  @observable selectedProject: Project | null = null;

  @observable features: Features[] = [];
  @observable selectedFeature: Features | null = null;

  @observable users: UserDTO[] = [];
  @observable selectedUser: UserDTO | null = null;

  @observable allPermissions: PermissionMatriceProjectsDto[] = [];
  @observable permissions: PermissionDTO[] = [];
  @observable userPermissionsMatrice: PermissionMatriceProjectsDto[] = [];

  @action
  async loadUserManagement(dataBookService: any) {
    this.projects = await getAllProjectsForUserPermissions(dataBookService);
    this.users = await getUsers(dataBookService);
    this.allPermissions = await getAllPermissions(dataBookService);
  }

  @action
  async loadProducts(dataBookService: any) {
    if (this.selectedProject) {
      this.products = await projectGetProductsForUserPermissions(
        dataBookService,
        this.selectedProject.id,
      );
    }
  }

  @action
  async loadFeatures(dataBookService: any) {
    if (this.selectedProduct) {
      this.features = await productGetFeaturesForUserPermissions(
        dataBookService,
        this.selectedProduct.id,
      );
    }
  }

  @action
  async loadPermissions(dataBookService: any) {
    if (this.selectedFeature) {
      this.permissions = await getPermissions(
        dataBookService,
        this.selectedFeature.productFeatureId,
      );
    }
  }

  @action
  async getPermissionMatriceForUser(dataBookService: any, userId: number) {
    this.userPermissionsMatrice = await getAllPermissionsForUser(
      dataBookService,
      userId,
    );
  }

  @action
  async saveUserPermissions(
    dataBookService: any,
    userId: number,
    userPermissionsMatrice: PermissionMatriceProjectsDto[],
  ) {
    this.userPermissionsMatrice = await savePermissionForUser(
      dataBookService,
      userId,
      userPermissionsMatrice,
    );
  }
}

export const USER_STORE = new InjectionToken<UserStore>('UserStore');
