import { Injectable } from '@angular/core';
import { ProductType } from '@interfaces/product';
import { plainToInstance, Type } from 'class-transformer';
import { action, observable } from 'mobx-angular';
import { FeaturesEnum } from '../enums';
import { Features } from './features.class';
import { Material } from './material.class';
import { Project } from './project.class';

@Injectable({
  providedIn: 'root',
})
export class Product {
  id: number;
  gsi_id: string;
  name: string;
  icon?: {
    url: string;
  };
  description?: string;
  selectedProduct?: string;
  supplier?: string;
  slug?: string;
  weight = 0;
  olddataexplorerintegration = false;
  merging = false;
  active = true;
  deleted = false;
  lastmodifiedby: string;
  lastmodifieddate: string;
  type: ProductType;

  @Type(() => Project)
  project: Project;
  @Type(() => Features)
  @observable
  features: Features[];
  @Type(() => Material)
  @observable
  materials: Material[];

  hasEdocFeature = (): boolean => {
    return this.features.some((feature) => feature.name === FeaturesEnum.eDoc);
  };

  hasMaterialsFeature = (): boolean => {
    return this.features.some(
      (feature) => feature.name === FeaturesEnum.materials,
    );
  };

  hasDatabookFeature = (): boolean => {
    return this.features.some(
      (feature) => feature.name === FeaturesEnum.databook,
    );
  };

  hasDataAnalysisFeature = (): boolean => {
    return this.features.some(
      (feature) => feature.name === FeaturesEnum.dataAnalysis,
    );
  };

  hasTpiDigitalFeature = (): boolean => {
    return this.features.some(
      (feature) => feature.name === FeaturesEnum.tpiDigital,
    );
  };

  hasDataExplorerFeature = (): boolean => {
    return this.features.some(
      (feature) => feature.name === FeaturesEnum.dataExplorer,
    );
  };

  getFeatureStatus = (_feature: FeaturesEnum): boolean => {
    return (
      this.features.filter((feature) => feature.name === _feature).length > 0
    );
  };

  @action setFeatureStatus = (
    _feature: FeaturesEnum,
    _status: boolean,
  ): void => {
    const featureIndex = this.features.findIndex(
      (feature) => feature.name === _feature,
    );
    if (_status === true) {
      if (featureIndex === -1) {
        this.features.push(plainToInstance(Features, { name: _feature }));
      }
    } else {
      this.features = this.features.reduce(
        (acc: Features[], feature: Features) => {
          if (feature.name !== _feature) {
            acc.push(feature);
          }
          return acc;
        },
        [],
      );
    }
  };

  @action enableFeature = (_feature: FeaturesEnum): void => {
    this.setFeatureStatus(_feature, true);
  };

  @action disableFeature = (_feature: FeaturesEnum): void => {
    this.setFeatureStatus(_feature, false);
  };

  @action toogleFeature = (_feature: FeaturesEnum): void => {
    this.setFeatureStatus(_feature, !this.getFeatureStatus(_feature));
  };
}
