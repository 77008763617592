import {
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
} from '@angular/core';
import { DataBookBaseComponent } from 'src/app/pages/databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, NgFor, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  ClrAccordionModule,
  ClrIconModule,
  ClrDatagridModule,
  ClrConditionalModule,
  ClrCommonFormsModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrDropdownModule,
} from '@clr/angular';

@Component({
  selector: 'databook-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['tool.component.scss'],
  standalone: true,
  imports: [
    ClrAccordionModule,
    ClrIconModule,
    ClrDatagridModule,
    ClrConditionalModule,
    ClrCommonFormsModule,
    FormsModule,
    NgClass,
    NgIf,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrDropdownModule,
    NgFor,
    NgStyle,
    TranslateModule,
  ],
})
export class ToolComponent extends DataBookBaseComponent {
  paletteColors = [];
  positionSelected = ['reset'];

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChangeTools = new EventEmitter<any>();

  @Input() settingsChart: any = [];

  async onPageInit() {
    this.paletteColors = await this.dataBookService.paletteColors();
  }

  onChange() {
    this.onChangeTools.emit(this.settingsChart);
  }

  onChangeColor(group: string, paletteName: string) {
    this.settingsChart.colors = [group, paletteName];
    this.onChange();
  }

  setGradient(colors: any[]) {
    const length = colors.length;
    const percentage = 100 / length;
    let sumPercentage = 0;
    const gradient = [];
    let textColor = '#f4f4f4';

    colors.map((c, i) => {
      if (i === 0) {
        if (parseInt(c.replace('#', ''), 16) >= 16000000) {
          textColor = '#3f3f3f';
        }
      }
      gradient.push(`${c} ${sumPercentage}%`);
      sumPercentage += percentage;
      gradient.push(`${c} ${sumPercentage}%`);
    });

    const styles = {
      'background-image': `linear-gradient(to right, ${gradient})`,
      color: textColor,
      'background-repeat': 'no-repeat',
    };
    return styles;
  }

  async setPosition(position: string) {
    if (position === 'left') {
      this.settingsChart.legend.positionX = -0.4;
      this.positionSelected[0] = 'left';
    }
    if (position === 'middle') {
      this.settingsChart.legend.positionX = 0.5;
      this.positionSelected[0] = 'middle';
    }
    if (position === 'right') {
      this.settingsChart.legend.positionX = 1;
      this.positionSelected[0] = 'right';
    }
    if (position === 'down') {
      this.settingsChart.legend.positionY = 0;
      this.positionSelected[1] = 'down';
    }
    if (position === 'center') {
      this.settingsChart.legend.positionY = 0.5;
      this.positionSelected[1] = 'center';
    }
    if (position === 'up') {
      this.settingsChart.legend.positionY = 1;
      this.positionSelected[1] = 'up';
    }

    if (position === 'reset') {
      this.positionSelected = ['reset'];
      this.settingsChart.legend.positionY = 1;
      this.settingsChart.legend.positionX = 1;
    }
    this.onChange();
  }
}
