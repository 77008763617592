<clr-modal
  class="modal-project"
  [clrModalOpen]="modalIsOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div *ngIf="modal.confirmDelete">
      <div class="clr-row">
        <div class="clr-col-12">
          <b>{{ 'confirm.exclusion.selection' | translate }}</b>
        </div>
      </div>
    </div>

    <div *ngIf="modal.deleteByText">
      <div class="clr-row">
        <div class="clr-col-12">
          {{ 'confirm.exclusion.type.product.name' | translate }}
          <strong *ngIf="store.selectedProduct"
            >({{ store.selectedProduct.name }})</strong
          >
        </div>
        <div class="clr-col-12">
          <form
            [formGroup]="form"
            clrForm
            class="project-form"
          >
            <input-form-control
              label="application.commons.text.name"
              name="name"
              formControlName="name"
              [required]="true"
              [form]="form"
            ></input-form-control>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      *ngIf="modal.confirmDelete"
      type="button"
      class="btn btn-outline"
      (click)="confirmDelete()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>

    <button
      *ngIf="modal.deleteByText"
      [disabled]="!form.valid || isEqual()"
      type="button"
      class="btn btn-outline"
      (click)="delete()"
    >
      {{ 'confirm.exclusion.delete' | translate }}
    </button>
  </div>
</clr-modal>
