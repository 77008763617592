import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  title = 'app';

  constructor() {
    const featureName = 'Pipe Navigator';

    const param = {
      app_name: 'Pipe Navigator',
      screen_name: featureName ? featureName : 'Pipe Navigator',
    };
    gtag(param);
  }
}
