import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DataBookBaseComponent } from '../databook-base.component';
import * as _ from 'lodash';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { v1 as uuid } from 'uuid';
// DISABLED_OLD_TREEROOT
// import {ITreeOptions, TREE_ACTIONS, TreeDraggedElement, TreeNode,} from '@circlon/angular-tree-component';
import moment from 'moment';
import {
  ClrLoadingState,
  ClrIconModule,
  ClrCommonFormsModule,
  ClrModalModule,
  ClrInputModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrLoadingButtonModule,
  ClrLoadingModule,
} from '@clr/angular';
import Axios from 'axios';
import { Subject } from 'rxjs';
import { Buffer } from 'buffer';
import { HttpClient } from '@angular/common/http';
import { DataBookState } from '../../services/databook.state';
import { DataBookService } from '../../services/databook.service';
import { CoreService } from '../../core/core.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../../components/pdf/pdf.service';
import { ConfigHandler } from '../../core/handlers/config.handler';
import {
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import {
  FolderNameAndId,
  getRootFoldersForFrontEnd,
} from '../../classes/treeview-helpers';
import {
  TreeviewFileItem,
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../../classes';
import { plainToInstance } from 'class-transformer';
import { SafeUrlPipe } from '../../components/safe-url/safe-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { Treeview } from './treeview/treeview';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { BasePageComponent } from '../../core/components/page/base-page.component';

declare let gtag;

@Component({
  selector: 'data-flow-databook',
  templateUrl: './databook.component.html',
  styleUrls: ['./databook.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    NgIf,
    ClrIconModule,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    Treeview,
    ClrModalModule,
    FormsModule,
    ReactiveFormsModule,
    ClrInputModule,
    NgxDropzoneModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgFor,
    ClrLoadingButtonModule,
    ClrLoadingModule,
    SlicePipe,
    TranslateModule,
    SafeUrlPipe,
  ],
})
export class DataBookComponent
  extends DataBookBaseComponent
  implements OnDestroy
{
  @Input()
  searchProject: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('tree', { static: true })
  tree;

  @ViewChild('treeExchange', { static: true })
  treeExchange;

  @ViewChild('dropzone', { static: true })
  dropzone: any;

  formInitialized = false;
  mergedExists = null;
  sub;
  nodes = null;
  nodesToExchange = null;
  revisions = [];
  addedFiles = [];
  existingDocument = [];
  databookForm: FormGroup;
  actionExchange = 'none';
  actionCategory = 'none';
  actionRevision = 'none';
  actionConfirm = 'none';
  actionRename = 'none';
  actionUpload = 'none';
  actionConfirmCopy = 'none';
  actionConfirmMerge = 'none';
  openRemovedFiles = 'none';
  openDocumentPdfViewModal = 'none';
  openDocumentHtmlViewModal = 'none';
  documentFile;
  htmlFile;
  selectedFilesSize = 0;
  selectedNumberFiles = 0;
  currentNode;
  multipleFiles = true;
  searchValue = '';
  newValue;
  // fileSize = 100000000;
  check = false;
  checkAllAux = false;
  dataRemove = null;
  CHILDREN = 'children';
  internalDescription;
  costumerDescription;
  description;
  clickedSortNameValue = 'desc';
  clickedSortDateValue = 'desc';
  extraDocuments;
  dataSheet;
  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  openModalEmail = 'none';
  listRemovedFile = [];
  email: any;
  listEmails;
  treeToListToMerge = [];
  selectedFiles = [];
  searchSubject = new Subject<string>();
  copyFilesToUpdate = [];
  FILE_CHUNK_SIZE = 25 * 1024 * 1024;
  FILE_MIN_CHUNK_SIZE = 5 * 1024 * 1024;
  placeholder: string = this.coreService.translate(
    'application.commons.buttons.search',
  );
  rootCategory = `${this.coreService.translate(
    'add.root',
  )} ${this.coreService.translate('add.category')}`;

  rootFolders: FolderNameAndId[] = [];

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public httpClient: HttpClient,
    public pdfService: PdfService,
    public config: ConfigHandler,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  /**
   *  Tree file configuration.
   */
  options = {
    allowDrag: this.canUpload(),
    useCheckbox: true,
    useVirtualScroll: true,
    nodeHeight: 22,
    dropSlotHeight: 5,
    // useTriState: false,
    getNodeClone: (node) => {
      const clone = JSON.parse(JSON.stringify(node.data));
      const item = this.updateTreeIdCopy(clone, null);
      return item;
    },
    actionMapping: {
      mouse: {
        drop: (tree, node, $event: any, { from, to }) => {
          this.coreService.showLoading();

          try {
            if (to.parent.data.size && from.data.id !== to.parent.id) {
              this.coreService.showAlertErrorTranslate(
                'databook.drap.drop.error',
              );
            } else if (
              from.data.phase &&
              from.data.id !== to.parent.id &&
              !$event.ctrlKey
            ) {
              this.coreService.showAlertErrorTranslate(
                'databook.drap.drop.pather.error',
              );
            } else if (this.isNodeFixed(from)) {
              this.coreService.showAlertErrorTranslate(
                'databook.drap.drop.fixed',
              );
            } else {
              if ($event.ctrlKey && from.data.category) {
                from.data.category = to.parent.data.id;
              }
              // TREE_ACTIONS.MOVE_NODE(tree, node, $event, {
              //   from,
              //   to,
              // });
            }
          } catch (error) {
            this.coreService.processError(error);
          }

          this.coreService.hideLoading();
        },
      },
    },
  };
  /**
   *  Allowed content type of files to upload
   */
  accept = [
    'application/msproj', // .mpp
    'application/msproject', // .mpp
    'application/x-msproject', // .mpp
    'application/x-ms-project', // .mpp
    'application/x-dos_ms_project', // .mpp
    'zz-application/zz-winassoc-mpp', // .mpp
    'application/mpp', // .mpp
    'application/vnd.ms-project', // .mpp
    'application/pdf', // .pdf
    'application/prn', // .prn
    'application/msword', // .doc
    // 'application/msword', // .dot
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', // .dotx
    // 'application/vnd.ms-word.document.macroEnabled.12', // .docm
    // 'application/vnd.ms-word.template.macroEnabled.12', // .dotm
    'application/vnd.ms-excel', // .xls
    'application/vnd.ms-excel', // .xlt
    'application/vnd.ms-excel', // .xla
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
    'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
    'application/vnd.ms-excel.template.macroEnabled.12', // .xltm
    'application/vnd.ms-excel.addin.macroEnabled.12', // .xlam
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
    // 'application/vnd.ms-powerpoint', // .ppt
    // 'application/vnd.ms-powerpoint', // .pot
    // 'application/vnd.ms-powerpoint', // .pps
    // 'application/vnd.ms-powerpoint', // .ppa
    // 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    // 'application/vnd.openxmlformats-officedocument.presentationml.template', // .potx
    // 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // .ppsx
    // 'application/vnd.ms-powerpoint.addin.macroEnabled.12', // .ppam
    // 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', // .pptm
    // 'application/vnd.ms-powerpoint.template.macroEnabled.12', // .potm
    // 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', // .ppsm
    'image/bmp', // bmp
    'image/gif', // gif
    'image/jpe', // jpe
    'image/jpeg', // jpeg
    'image/jpg', // jpg
    'image/svg+xml', // svg
    'image/tiff', // tif
    'image/tiff', // tiff
    'image/x-icon', // ico
    'image/png', // png
    'text/html',
  ];

  async onPageInit() {
    this.treeviewStore.setSelectedObjects([]);

    // this.rootFolders = await getRootFoldersForFrontEnd(this.dataBookService, this.store.selectedProduct.id, TreeviewItemContext.databook) as FolderNameAndId[];
    const result = await this.dataBookService.checkMerge({
      productId: this.store.selectedProduct.id,
    });
    if (result.exists) {
      this.treeviewStore.mergedExists = moment(
        result.exists.LastModified,
      ).format('DD/MM/YYYY');
    }

    // console.log({rootFolders: this.rootFolders});

    /*this.sub = this.coreService.getRouter().events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.stopInterval();
        this.sub.unsubscribe();
      }
    });

    this.searchSubject.pipe(debounceTime(500)).subscribe((searchText) => {
      this.searchValue = searchText.trim().toLowerCase();
      if (this.searchValue.length >= 2) {
        this.tree.treeModel.filterNodes(this.searchValue, true);
      } else {
        this.searchValue = '';
        this.tree.treeModel.filterNodes(this.searchValue, false);
      }
      this.tree.sizeChanged();
    });

    // this.treeviewStore.context === TreeviewItemContext.edoc = this.coreService.getRouterParams();
    const params = await this.coreService.getRouterParams();
    this.treeviewStore.context === TreeviewItemContext.edoc = params.extraDocuments;



    if (params.project) {
      this.store.selectedProject.gsi_id = atob(params.project);
    }
    if (params.product) {
      const product = atob(params.product);
      this.store.selectedProduct.gsi_id= product;
      this.dataBookState.productId = product.toUpperCase();
      this.dataBookState.productDsc = this.replaceAll(product, '_', ' ');

      this.configBreadCrumb();
    }
    if (!this.store.selectedProject.gsi_id || !this.dataBookState.product) {
      this.coreService.getRouter().navigate(['/']);
    }

    if (this.treeviewStore.context === TreeviewItemContext.edoc !== null && this.treeviewStore.context === TreeviewItemContext.edoc !== undefined) {
      this.extraDocuments = false;
      if (params.extraDocuments === 'true') {
        this.extraDocuments = true;
      }
      try {
        this.initForm();
        this.coreService.showLoading();

        await this.initTreeDocuments();

        const routeParams = this.activatedRouter.snapshot.paramMap;
        const fileId = routeParams.get('fileId');
        const intVal = setInterval(() => {
          if (params.node || fileId) {
            const node = this.tree.treeModel.getNodeById(
                params.node ? params.node : fileId
            );
            if (node) {
              node.setActiveAndVisible();
              clearInterval(intVal);
            }
          } else {
            clearInterval(intVal);
          }
        }, 1000);

        setInterval(() => {
          this.selectedFilesSize = 0;
          this.selectedNumberFiles = 0;
          this.selectedFiles = [];
          for (const selected of this.tree.treeModel.selectedLeafNodes) {
            if (selected.data.size) {
              this.selectedNumberFiles++;
              this.selectedFilesSize += selected.data.size;
              this.selectedFiles.push(selected.data);
            }
          }
        }, 1000);

        await this.initInterval();
      } catch (error) {
        this.coreService.processError(error);
      }
      this.coreService.hideLoading();
    } else {
      this.coreService.getRouter().navigate(['/']);
      return;
    }*/
  }

  async sendGtag(eventAction) {
    const param = {
      debug_mode: true,
      // feature:  this.extraDocuments ? 'EDoc page' : 'Databook page',
      // category: 'action',
      // event: eventAction,
      // pageName: this.extraDocuments ? '/main/e-doc' : '/main/databook',
      // userType: this.coreService.getConfig().currentUser.type,
      // project: this.store.selectedProject.gsi_id
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature:
        this.treeviewStore.context === TreeviewItemContext.edoc
          ? 'EDoc page'
          : 'Databook page',
      spn_action: eventAction,
      spn_userType: this.userStore.user.type,
    };
    gtag('event', eventAction, param);
  }

  updateTreeIdCopy(node, id) {
    const idParent = id ? id : uuid();
    const itemReturn = {
      ...node,
      id: idParent,
      description: `Copy of ${node.description}`,
      name: `Copy of ${node.name}`,
    };

    if (node?.children) {
      for (const item of node.children) {
        let idChild = null;
        if (item.category) {
          item.category = idParent;
          const idFile = uuid();
          item.origin = item.id;
          item.id = idFile;
          this.copyFilesToUpdate.push(item);
        } else {
          idChild = uuid();
          item.id = idChild;
        }
        if (item.children && item.children.length) {
          this.updateTreeIdCopy(item, idChild);
        }
      }
    } else if (node.category) {
      itemReturn.origin = node.id;
      this.copyFilesToUpdate.push(itemReturn);
    }

    return itemReturn;
  }

  ngOnDestroy(): Promise<void> {
    this.stopInterval();
    return super.ngOnDestroy();
  }

  async setDataSheet() {
    try {
      if (this.tree.treeModel.selectedLeafNodes.length !== 1) {
        this.coreService.showAlertErrorTranslate('databook.select.one.file');
        return;
      }
      if (!this.store.materialId) {
        this.coreService.showAlertErrorTranslate('select.material.error');
        return;
      }
      this.coreService.showLoading();

      await this.dataBookService.qpScopeOfSupply({
        idDataSheet: this.tree.treeModel.selectedLeafNodes[0].data,
        saveDataSheet: true,
        material: this.store.materialId,
        product: this.store.selectedProduct.gsi_id,
        project: this.store.selectedProject.gsi_id,
        productId: this.store.selectedProduct.id,
      });

      await this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
      );
    } catch (error) {
      this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }

  canInsert() {
    if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      this.currentUser.roles.PROJECT_CONFIG
    ) {
      return true;
    }
    return false;
  }

  canMerge() {
    if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK &&
      super.getRoleByProject().DATABOOK.MERGE_FILES
    ) {
      return true;
    }
    return false;
  }

  // private async initInterval() {
  //   this.dataBookState.databookInterval.push(
  //     setInterval(() => {
  //       // if (window.location.pathname === '/main/databook') {
  //       this.findTree();
  //       // } else {
  //       //     await this.stopInterval();
  //       // }
  //     }, 60000)
  //   );
  //   // }, 600000);
  // }

  private async initTree() {
    const result = await this.initResults();
    this.populateTree(result);
  }

  private async initTreeDocuments() {
    const items = await this.initResults();
    this.populateResult(items);
  }

  async cancelTransfer() {
    this.actionExchange = 'none';
  }

  async confirmTransfer() {
    if (this.treeExchange.treeModel.selectedLeafNodes.length) {
      if (this.treeExchange.treeModel.selectedLeafNodes.length === 1) {
        const filesToTransfer = [];
        const completePathFiles = [];
        for (const selected of this.tree.treeModel.selectedLeafNodes) {
          if (selected.data.size) {
            filesToTransfer.push(selected.data);

            const nodeTree = this.tree.treeModel.getNodeById(
              selected.data.category,
            );
            const completePath = this.mountPath(nodeTree);
            completePathFiles.push(completePath);
          }
        }
        const params = {
          transfer: true,
          selected: filesToTransfer,
          transferTo: this.treeExchange.treeModel.selectedLeafNodes[0].data,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          completePath: completePathFiles,
          user: this.currentUser.firstName,
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);
        await this.dataBookService.documents(params);

        this.actionExchange = 'none';
        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );
      } else {
        this.coreService.showAlertErrorTranslate(
          'databook.select.one.path.to.transfer',
        );
      }
    } else {
      this.coreService.showAlertErrorTranslate(
        'databook.select.path.to.transfer',
      );
    }
  }

  public async transferDoc() {
    if (this.tree.treeModel.selectedLeafNodes.length) {
      const params = {
        extraDocs: false,
        init: true,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      const result = await this.dataBookService.documents(params);
      for (const item of result.definitionDocument.itens) {
        if (item.id === this.dataBookState.product) {
          this.nodesToExchange = item.children;
          break;
        }
      }

      // this.existingDocument = result.existingDocument;
      //
      // for (const item of this.nodesToExchange) {
      //     if (item.role) {
      //         console.log('');
      //     }
      //     if (!item.role || (item.role && this.hasRole(item.role))) {
      //         this.checkDocument(item);
      //         this.hasDocument(item);
      //     } else {
      //         const index = this.nodesToExchange.indexOf(item);
      //         if (index > -1) {
      //             this.nodesToExchange.splice(index, 1);
      //         }
      //     }
      // }
      this.actionExchange = 'open';
    } else {
      this.coreService.showAlertErrorTranslate('databook.select.file.transfer');
    }
  }

  public async downloadMergedFilesArchive() {
    try {
      this.coreService.showLoading();

      const params = {
        downloadMergedFilesArchive: true,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);

      const downloadFiles = await this.dataBookService.documents(params);
      if (downloadFiles.link) {
        const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
        a.href = downloadFiles.link;
        a.click();
      } else {
        this.coreService.showAlertErrorTranslate(
          'databook.download.merged.error',
        );
      }
    } catch (error) {
      this.coreService.processError(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  print() {
    const result: any = {}; // <--declare a variable
    Object.keys(this.tree.treeModel.selectedLeafNodeIds).forEach((x) => {
      // DISABLED_OLD_TREEROOT
      // const node: TreeNode = this.tree.treeModel.getNodeById(x);
      // if (node.isSelected) {
      //   console.log(
      //       'Selected:',
      //       node.data.name,
      //       'Parent:',
      //       node.parent.data.name
      //   );
      //   if (node.parent.data.name) {
      //     // if the node has parent
      //     if (!result[node.parent.data.name]) {
      //       // If the parent is not in the object
      //       result[node.parent.data.name] = {};
      //     } // create
      //     result[node.parent.data.name][node.data.name] = true;
      //   } else {
      //     if (!result[node.data.name]) {
      //       // If the node is not in the object
      //       result[node.data.name] = {}; // create
      //     }
      //   }
      // }
    });
    console.log(result);
  }

  cancelMerge() {
    this.actionConfirmMerge = 'none';
    this.treeviewStore.selectedObjects = [];
  }
  cancelCopy() {
    this.actionConfirmCopy = 'none';
  }

  click() {
    Object.keys(this.tree.treeModel.selectedLeafNodeIds).forEach((x) => {
      const node = this.tree.treeModel.getNodeById(x);
      if (node.isSelected) {
        console.log(
          'Selected: ',
          node.data.name,
          'Parent: ',
          node.parent.data.name,
        );
      }
    });
  }

  public openConfirmCopy() {
    if (
      this.tree.treeModel.selectedLeafNodes &&
      this.tree.treeModel.selectedLeafNodes.length
    ) {
      this.click();
      this.actionConfirmCopy = 'open';
    } else {
      this.coreService.showAlertErrorTranslate(
        'databook.no.select.file.download',
      );
    }
  }

  public openConfirmMerge() {
    // this.treeToListToMerge = [];
    // this.treeToList(this.treeToListToMerge);

    if (
      this.treeviewStore.selectedFilesForMergeRequest &&
      this.treeviewStore.selectedFilesForMergeRequest.length > 1
    ) {
      this.actionConfirmMerge = 'open';
    } else {
      this.coreService.showAlertErrorTranslate(
        'databook.no.select.file.download',
      );
    }
  }

  public async copy() {
    this.actionConfirmCopy = 'none';
  }

  public async merge() {
    try {
      // this.print();

      this.coreService.showLoading();
      const result = await this.dataBookService.checkMerge({
        merge: true,
        productId: this.store.selectedProduct.id,
      });

      if (result.ok) {
        this.dataBookService.merge({
          merge: true,
          productId: this.store.selectedProduct.id,
          productDsc: this.store.selectedProduct.description,
          currentUser: this.userStore.user.email,
          documentList: this.treeviewStore.treeListItems,
          initFs: false,
          env: this.config.environment.stage,
        });

        const options = { timeOut: 10000, extendedTimeOut: 0 };

        this.coreService.showAlertSuccessTranslate(
          'msg.info.merge.progress',
          options,
        );
        this.coreService.showAlertSuccessTranslate(
          'msg.info.merge.extension',
          options,
        );
      } else {
        this.coreService.showAlertErrorTranslate(
          'msg.info.merge.already.progress',
        );
      }
      this.actionConfirmMerge = 'none';

      this.sendGtag('merge');
    } catch (error) {
      this.coreService.processError(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  private async initResults() {
    const params = {
      extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
      init: true,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const result = await this.dataBookService.documents(params);

    if (result.exists) {
      this.mergedExists = moment(result.exists.LastModified).format(
        'DD/MM/YYYY',
      );
    }
    return result;
  }

  sortNameAsc() {
    this.clickedSortNameValue = 'asc';
    const sort = _.orderBy(this.tree.treeModel.nodes, ['description'], ['asc']);
    this.nameAsc(sort);
    this.nodes = sort;
  }

  nameAsc(sortTemp) {
    for (const item of sortTemp) {
      if (item.children && item.children.length) {
        item.children = _.orderBy(item.children, ['description'], ['asc']);
        this.nameAsc(item.children);
      }
    }
  }

  isNodeFixed(node) {
    if (node.data.fixed) {
      return true;
    }
    return false;
  }

  sortNameDesc() {
    this.clickedSortNameValue = 'desc';
    const sort = _.orderBy(
      this.tree.treeModel.nodes,
      ['description'],
      ['desc'],
    );
    this.nameDesc(sort);
    this.nodes = sort;
  }

  nameDesc(sortTemp) {
    for (const item of sortTemp) {
      if (item.children && item.children.length) {
        item.children = _.orderBy(item.children, ['description'], ['desc']);
        this.nameDesc(item.children);
      }
    }
  }

  // getAllItemsPerChildren(item, array) {
  //     array.push(item);
  //     if (item.children) {
  //         return item.children.map(i => this.getAllItemsPerChildren(i, array));
  //     }
  // }
  // treeToList(list) {
  //     for (const item of this.tree.treeModel.nodes) {
  //         this.getAllItemsPerChildren(item, list);
  //     }
  // }

  getAllItemsPerChildren(item, array, allowedIds) {
    if (allowedIds.includes(item.id)) {
      array.push(item);
    }
    if (item.children) {
      for (const child of item.children) {
        this.getAllItemsPerChildren(child, array, allowedIds);
      }
    }
  }

  treeToList(list) {
    const allowedIds = [];
    for (const selected of this.tree.treeModel.selectedLeafNodes) {
      let parentData = selected.parent;
      while (parentData && parentData.data && parentData.data.id) {
        allowedIds.push(parentData.data.id);
        parentData = parentData.parent;
      }
      allowedIds.push(selected.data.id);
    }

    for (const item of this.tree.treeModel.nodes) {
      this.getAllItemsPerChildren(item, list, allowedIds);
    }

    for (const item of list) {
      if (item.children) {
        delete item.children;
      }
    }
  }

  sortDateAsc() {
    this.clickedSortDateValue = 'asc';
    const sort = _.orderBy(this.tree.treeModel.nodes, ['datetime'], ['asc']);
    this.dateAsc(sort);
    this.nodes = sort;
  }

  dateAsc(sortTemp) {
    for (const item of sortTemp) {
      if (item.children && item.children.length) {
        item.children = _.orderBy(item.children, ['datetime'], ['asc']);
        this.dateAsc(item.children);
      }
    }
  }

  sortDateDesc() {
    this.clickedSortDateValue = 'desc';
    const sort = _.orderBy(this.tree.treeModel.nodes, ['datetime'], ['desc']);
    this.dateDesc(sort);
    this.nodes = sort;
  }

  dateDesc(sortTemp) {
    for (const item of sortTemp) {
      if (item.children && item.children.length) {
        item.children = _.orderBy(item.children, ['datetime'], ['desc']);
        this.dateDesc(item.children);
      }
    }
  }

  formatDateFile(data) {
    if (this.coreService.getLanguage() === 'en') {
      return moment(data).format('MM/DD/YYYY HH:mm:ss');
    }
    return moment(data).format('DD/MM/YYYY HH:mm:ss');
  }

  async xpto() {
    const params = {
      extraDocs: false,
      xpto: true,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);
  }

  async onSearchChange(searchText) {
    if (searchText.length > 2) {
      this.coreService.showLoading();

      await this.treeviewStore.searchFileWithDebounce(searchText);
    } else {
      const rootFolders = (await getRootFoldersForFrontEnd(
        this.dataBookService,
        this.store.selectedProduct.id,
        this.treeviewStore.context,
      )) as FolderNameAndId[];
      this.treeviewStore.tree = rootFolders.map((item: FolderNameAndId) => {
        return plainToInstance(TreeviewFolderItem, {
          id: item.id,
          product_id: item.product_id,
          description: item.description,
          name: item.name,
          isRoot: true,
          phase: item.phase,
          fixed: item.fixed,
          parent_id: null,
          treeview_context: item.treeview_context,
          folders: [],
          files: [],
        });
      });
    }
    this.coreService.hideLoading();
  }

  onClearClick() {
    this.searchValue = '';
    this.tree.treeModel.filterNodes(this.searchValue, true);
  }

  private initForm() {
    this.formInitialized = true;
    this.databookForm = new FormGroup({
      category: new FormControl('', [Validators.required]),
    });
  }

  public cancelRestore() {
    this.openRemovedFiles = 'none';
  }

  public async restoreFile(data) {
    this.coreService.showLoading();
    try {
      const params = {
        restoreData: data,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      await this.dataBookService.documents(params);
      this.openRemovedFiles = 'none';
      this.initTreeDocuments();
      await this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
      );
    } catch (error) {
      this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  }

  public async openRemoveListFiles() {
    const params = {
      listRemoved: true,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const result = await this.dataBookService.documents(params);
    this.listRemovedFile = result.removedDocument;
    if (this.listRemovedFile?.length) {
      this.openRemovedFiles = 'open';
    } else {
      this.coreService.showAlertDangerTranslate('databook.no.removed.files');
    }
  }

  populateResult(result) {
    this.populateTree(result);
    this.populateDocuments(result);
    this.createWithOutPath();

    setTimeout(() => {
      this.expandAll();
    }, 100);
  }

  private createWithOutPath() {
    if (this.existingDocument && this.existingDocument.length) {
      let hasDoc = false;
      for (const node of this.nodes) {
        if (node.name === 'FILES WITHOUT FOLDER') {
          hasDoc = true;
          node.children = node.children.concat(this.existingDocument);
        }
      }
      if (!hasDoc) {
        this.nodes.push({
          name: 'FILES WITHOUT FOLDER',
          id: uuid(),
          children: [],
        });
        this.nodes[this.nodes.length - 1].children = this.existingDocument;
      }
    }
  }

  private populateDocuments(result) {
    this.existingDocument = result.existingDocument;

    for (const item of this.nodes) {
      if (item.role) {
        console.log('');
      }
      if (!item.role || (item.role && this.hasRole(item.role))) {
        this.checkDocument(item);
        this.hasDocument(item);
      } else {
        const index = this.nodes.indexOf(item);
        if (index > -1) {
          this.nodes.splice(index, 1);
        }
      }
    }
  }

  private populateTree(result) {
    for (const item of result.definitionDocument.itens) {
      if (item.id === this.dataBookState.product) {
        this.nodes = item.children;
        break;
      }
    }
  }

  hasRole(role) {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.DATABOOK[role]
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK[role]
    ) {
      return true;
    }
    return false;
  }

  // Caso necessite que a árvore aparece aberta é só colocar true
  expandAll() {}

  /**
   * Check if a specific type of document was already upload.
   */
  hasDocument(node) {
    const filtered = _.filter(
      this.existingDocument,
      (item) => item.category === node.id,
    );
    if (filtered && filtered.length) {
      this.existingDocument = this.existingDocument.filter((item) => {
        return filtered.indexOf(item) < 0;
      });
    }
    if (!node.children) {
      node.children = [];
    }
    if (filtered && filtered.length) {
      node.children = node.children.concat(filtered);
    }
  }

  /**start
   * Just iterate over all document types to check if a specific type of document was already upload.
   */
  checkDocument(node) {
    for (const item of node.children) {
      if (item.children && item.children.length) {
        this.checkDocument(item);
      }
      this.hasDocument(item);
    }
  }

  onlyValidCharacters(event: KeyboardEvent) {
    const value = event.key;
    if (
      '1234567890qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM_.:=+-@ áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ)('.indexOf(
        value,
      ) < 0
    ) {
      event.preventDefault();
      this.coreService.showAlertErrorTranslate('string.invalid.character');
    }
  }

  delete(name) {
    const files = _.filter(
      this.treeviewStore.filesAddedForUpload,
      (item) => item.name !== name,
    );
    this.treeviewStore.filesAddedForUpload = files;
  }

  mountPath(
    item: TreeviewFolderItem | TreeviewFileItem,
    withFileInPath = true,
  ): string {
    if (item instanceof TreeviewFileItem) {
      return this.treeviewStore.getFilePath(item, withFileInPath);
    } else {
      return this.treeviewStore.getFolderPath(item);
    }
  }
  /**
   * Send all checked files by email
   * If Files > 10mb send a link for download
   */
  async sendDocsByEmail() {
    try {
      if (this.treeviewStore.selectedFiles.length === 0) {
        this.coreService.showAlertErrorTranslate('databook.email.no.documents');
        return;
      } else if (this.listEmails.length === 0) {
        this.coreService.showAlertErrorTranslate('databook.no.emails');
        return;
      }

      const files: TreeviewFileItem[] = this.treeviewStore.selectedFiles;
      if (files.length === 0) {
        this.coreService.showAlertErrorTranslate('databook.email.no.documents');
        return;
      } else if (
        !this.listEmails ||
        (this.listEmails && this.listEmails.length === 0)
      ) {
        this.coreService.showAlertErrorTranslate('databook.no.emails');
        return;
      }

      this.submitBtnState = ClrLoadingState.LOADING;
      const filesToSend: TreeviewFileItem[] = [];
      for (const file of files) {
        if (file.size) {
          file.complete_path = this.mountPath(file);
          filesToSend.push(file);
        }
      }

      const payload: any = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        sendEmail: true,
        docs: filesToSend,
        size: this.treeviewStore.size,
        user: this.userStore.user.email,
        userName:
          this.userStore.user.firstName + ' ' + this.userStore.user.lastName,
        emails: this.listEmails,
        productId: this.store.selectedProduct.id,
        samePath: this.treeviewStore.samePath,
      };

      console.log({ payload });

      await this.dataBookService.sendDocsByEmail(payload);
      await this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
      );

      this.sendGtag('share');

      this.submitBtnState = ClrLoadingState.DEFAULT;
      this.exitSendEmailModal();
      this.treeviewStore.selectedObjects = [];
    } catch (err) {
      console.log(err);
      this.coreService.showAlertErrorTranslate('databook.download.error');
      this.submitBtnState = ClrLoadingState.DEFAULT;
    }
  }

  openSendEmailModal() {
    if (this.treeviewStore.selectedFiles.length === 0) {
      this.coreService.showAlertErrorTranslate(
        'databook.no.select.file.download',
      );
      return;
    } else if (this.treeviewStore.size > 750) {
      this.coreService.showAlertErrorTranslate('databook.max.size.selected');
      return;
    } else if (this.selectedNumberFiles > 20) {
      this.coreService.showAlertErrorTranslate('databook.max.num.selected');
      return;
    }
    this.listEmails = [];
    this.openModalEmail = 'open';
  }

  exitSendEmailModal() {
    this.openModalEmail = 'none';
    this.email = '';
  }

  addEmailToList(value: string) {
    if (!this.validateEmail(value)) {
      this.coreService.showAlertErrorTranslate('databook.email.invalid');

      return;
    }

    this.listEmails.push(value);
    this.email = '';
  }

  deleteEmailToList(value) {
    const index = this.listEmails.indexOf(value);
    this.listEmails.splice(index, 1);
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  /**
   * Open upload modal
   */
  async downloadVersion(node: { file: TreeviewFileItem }) {
    await this.openModalRevision(node.file.s3key, node.file.description);
    this.currentNode = node;
  }

  /**
   *  Download all checked files.
   *  The back end server zip the files.
   */
  async download() {
    // samePath
    if (this.treeviewStore.selectedFiles.length === 0) {
      this.coreService.showAlertErrorTranslate(
        'databook.no.select.file.download',
      );
      return;
    }

    this.coreService.showLoading();

    const filesToDownload = [];
    for (const file of this.treeviewStore.selectedFiles) {
      if (file.size) {
        file.complete_path = this.mountPath(file, false);
        filesToDownload.push(file);
      }
    }

    const a = document.getElementById('downloadFiles') as HTMLAnchorElement;

    if (filesToDownload.length === 1) {
      a.download = filesToDownload[0].name;
    } else {
      a.download = 'databook.zip';
    }

    if (
      this.treeviewStore.samePath === 1 ||
      this.treeviewStore.samePath === 2
    ) {
      if (this.selectedFilesSize / 1000000 > 750) {
        this.coreService.hideLoading();
        this.coreService.showAlertErrorTranslate('databook.max.size.selected');
        return;
      }

      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        download: filesToDownload,
        productId: this.store.selectedProduct.id,
        samePath: this.treeviewStore.samePath,
      };
      this.cfgVlr(params);
      const linkFile = await this.dataBookService.documents(params);

      a.href = linkFile.downloadFiles;
      this.coreService.hideLoading();
      a.click();
    } else {
      for (const itemFile of filesToDownload) {
        const params = {
          extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
          download: [itemFile],
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          samePath: this.treeviewStore.samePath,
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);
        const linkFile = await this.dataBookService.documents(params);

        a.href = linkFile.downloadFiles;
        this.coreService.hideLoading();
        a.click();

        await this.wait(1000);
      }
    }
    this.sendGtag('download');
    await this.coreService.showAlertSuccessTranslate(
      'application.commons.success.operation',
    );

    this.treeviewStore.selectedObjects = [];
  }

  wait(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  failureDownload(error) {
    this.coreService.showAlertErrorTranslate('databook.download.error');
    this.coreService.hideLoading();
  }

  formatDate(milli) {
    return moment(milli).format('DD/MM/YYYY HH:mm:ss');
  }

  async checkUncheckAll() {
    this.checkAllAux = true;
    this.coreService.showLoading();

    // await setTimeout(() => {this.doCheckUncheckAll();}, 500);
    await this.doCheckUncheckAll();

    this.checkAllAux = false;
    await this.updateSizeNumberFile();

    this.coreService.hideLoading();
    this.check = !this.check;
  }

  async doCheckUncheckAll() {
    const nodes = this.tree.treeModel.nodes;

    for (const node of nodes) {
      await this.tree.treeModel.getNodeById(node.id).setIsSelected(!this.check);
    }
  }

  /**
   *  Just uncheck all checked checkbox.
   */
  cancelDownload() {
    this.tree.treeModel.doForAll((node) => node.setIsSelected(false));
  }

  onCopyNode($event) {
    try {
      let i = 0;
      const fileItemsIndex = [];
      for (const item of $event.to.parent.children) {
        item.position = i;
        fileItemsIndex.push(item);
        i++;
      }
      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        treeNode: this.nodes,
        updateListIndex: fileItemsIndex,
        copy: this.copyFilesToUpdate,
        categoryItem: $event.node.category,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);

      console.log('params ==> ', params);
      this.dataBookService.documents(params);
    } catch (error) {
      this.coreService.processError(error);
      this.copyFilesToUpdate = [];
    }
    this.copyFilesToUpdate = [];
  }

  /**
   * Every time the one category or one file was dragged and dropped we need to update the configuration
   * of document types.
   */
  onMoveNode($event) {
    try {
      let i = 0;
      const fileItensIndex = [];
      for (const item of $event.to.parent.children) {
        item.position = i;
        fileItensIndex.push(item);
        i++;
      }

      if ($event.node.category) {
        $event.node.category = $event.to.parent.id;
        $event.node.datetime = Date.now();

        const params = {
          extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
          tree: this.nodes,
          file: $event.node,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          updateListIndex: fileItensIndex,
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);
        this.dataBookService.documents(params);
      } else {
        const params = {
          extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
          tree: this.nodes,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          updateListIndex: null,
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);

        this.dataBookService.documents(params);
      }
    } catch (error) {
      this.coreService.processError(error);
    }

    this.expandAll();
  }

  /**
   * Update number of files and the size of checked files.
   */
  async doCheckUncheck($event, node) {
    const currentNode = await this.tree.treeModel.getNodeById(node.id);
    console.log(`currentNode ===> `, currentNode);

    const children = currentNode?.data?.children;
    console.log(`children ===> `, children);

    currentNode.setIsSelected(!currentNode.isSelected);

    if (children) {
      for (const [index, nodeChild] of children.entries()) {
        const child = await this.tree.treeModel.getNodeById(nodeChild.id);
        console.log(`child ${index} ===> `, child.isSelected);

        child.setIsSelected(currentNode.isSelected);

        console.log(`child ${index} === 2 ===> `, child.isSelected);
      }
    }

    console.log(
      ' this.tree.treeModel.selectedLeafNodes ==> ',
      this.tree.treeModel.selectedLeafNodes,
    );

    if (!this.checkAllAux && !$event?.node?.isLeaf) {
      this.updateSizeNumberFile();
    }
  }

  /**
   * Update number of files and the size of checked files.
   */
  updateSizeNumberFile() {
    this.selectedFilesSize = 0;
    this.selectedNumberFiles = 0;
    this.selectedFiles = [];

    for (const selected of this.tree.treeModel.selectedLeafNodes) {
      if (selected.data.size) {
        this.selectedNumberFiles++;
        this.selectedFilesSize += selected.data.size;
        this.selectedFiles.push(selected.data);
      }
    }
  }

  /**
   * Open upload modal
   */
  openUpload($event, node) {
    this.currentNode = node.data;
    this.actionUpload = 'upload';
    this.multipleFiles = true;
  }

  /**
   * Open upload modal
   */
  openUpload2(node: { folder: TreeviewFolderItem }) {
    this.currentNode = node.folder;
    this.actionUpload = 'upload';
    this.multipleFiles = true;
  }

  /**
   * Open upload modal
   */
  updateFile($event, node) {
    this.currentNode = node.data;
    this.actionUpload = 'upload';
    this.multipleFiles = false;
  }

  /**
   * Open upload modal
   */
  updateFile2(node: { file: TreeviewFileItem }) {
    // todo update file
    this.treeviewStore.fileSelectedForBeingUpdated = node.file;
    this.actionUpload = 'upload';
    this.multipleFiles = false;
  }

  /**
   * Close upload modal
   *
   */
  cancelUpload() {
    this.initModalUpload();
  }

  /**
   * Reset inital state of upload modal
   */
  initModalUpload() {
    this.actionUpload = 'none';
    this.treeviewStore.filesAddedForUpload = [];
    this.treeviewStore.selectedObjects = [];
    this.treeviewStore.folderTargetedForUpload = null;
    this.treeviewStore.fileSelectedForBeingUpdated = null;
    //this.currentNode = null;
    //this.addedFiles = [];
  }

  getFileRevisionIds(): { s3key: string; revision: number; length: number }[] {
    const files = [];
    if (
      this.treeviewStore.fileSelectedForBeingUpdated &&
      this.treeviewStore.fileSelectedForBeingUpdated.s3key
    ) {
      files.push({
        s3key: this.treeviewStore.fileSelectedForBeingUpdated.s3key,
        revision: this.treeviewStore.filesAddedForUpload[0].revision,
        length: this.treeviewStore.filesAddedForUpload[0].size,
      });
    } else if (
      this.treeviewStore.filesAddedForUpload &&
      this.treeviewStore.filesAddedForUpload.length > 0
    ) {
      for (const file of this.treeviewStore.filesAddedForUpload) {
        files.push({
          s3key: file.s3key,
          revision: file.revision,
          length: file.size,
        });
      }
    }
    return files;
  }

  stopInterval() {
    for (const item of this.dataBookState.databookInterval) {
      clearInterval(item);
    }
  }

  createEmail() {
    const params = {
      sendTicket: true,
      now: moment().format('DD/MM/YYYY HH:mm:ss'),
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    this.dataBookService.documents(params);
  }
  /**
   * Send the file to server.
   * This operation persist the file in the AWS S3
   *
   * When we read multipleFiles we can understand that we are talking
   * about update a existing file or adding a new file
   */
  async upload() {
    if (
      !this.treeviewStore.filesAddedForUpload ||
      this.treeviewStore.filesAddedForUpload.length === 0
    ) {
      this.coreService.showAlertErrorTranslate(
        'databook.no.select.file.upload',
      );
      return;
    }

    try {
      this.coreService.showLoading();

      await this.stopInterval();

      const uploadFiles: TreeviewFileItem[] = [];
      let canUpload = true;

      const fileIdsToUpload: {
        s3key: string;
        revision: number;
        length: number;
      }[] = this.getFileRevisionIds();

      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        prepareToSave: true,
        fileIds: fileIdsToUpload,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      await this.dataBookService.documents(params);

      type urlsOnlyWithUploadID = {
        uploadId: string;
        preSignedMultPart: string[];
      };

      const result: { preSignedUrls: any } =
        await this.dataBookService.documents(params);

      const presignedUrlsFromServeur: string[] = [];
      const presignedUrlsMultipartFromServeur: urlsOnlyWithUploadID[] = [];

      for (const item of result.preSignedUrls) {
        if (
          item.preSignedMultPart &&
          item.uploadId &&
          item.preSignedMultPart.length > 0
        ) {
          presignedUrlsMultipartFromServeur.push(item as urlsOnlyWithUploadID);
        } else {
          presignedUrlsFromServeur.push(item);
        }
      }

      for (const file of this.treeviewStore.filesAddedForUpload) {
        if (!this.validateFile(result, file)) {
          canUpload = false;
          break;
        }

        // if (this.treeviewStore.fileSelectedForBeingUpdated !== null) {
        //   this.treeviewStore.folderTargetedForUpload = file.folder_id;
        // }

        const preparedFile: TreeviewFileItem = plainToInstance(
          TreeviewFileItem,
          {
            size: file.size,
            name: file.name,
            description: file.description,
            datetime: Date.now(),
            filetype: file.filetype,
            id: this.treeviewStore.fileSelectedForBeingUpdated
              ? this.treeviewStore.fileSelectedForBeingUpdated.id
              : file.id,
            s3key: this.treeviewStore.fileSelectedForBeingUpdated
              ? this.treeviewStore.fileSelectedForBeingUpdated.s3key
              : file.s3key,
            document_code: file.document_code,
            revision: file.revision,
            position: file.position,
            folder_id: this.treeviewStore.folderTargetedForUpload.id,
            product_id: this.store.selectedProduct.id,
          },
        ) as TreeviewFileItem;

        if (
          !this.multipleFiles &&
          !this.treeviewStore.fileSelectedForBeingUpdated
        ) {
          preparedFile.id = null;
          preparedFile.s3key = null;
          preparedFile.folder_id =
            this.treeviewStore.folderTargetedForUpload.id;
        }
        uploadFiles.push(preparedFile);
      }

      if (canUpload) {
        /////////////////////////
        // PROCESS MULTIPART
        /////////////////////////
        for (let i = 0; i < presignedUrlsMultipartFromServeur.length; i++) {
          const preSignedUrl: urlsOnlyWithUploadID = result.preSignedUrls[i];
          const folderBeingUploadTo =
            this.treeviewStore.folderTargetedForUpload;
          const completePath = this.mountPath(folderBeingUploadTo);

          if (preSignedUrl.uploadId) {
            const axios = Axios.create();
            delete axios.defaults.headers.put['Content-Type'];

            const promises = [];
            for (let j = 0; j < preSignedUrl.preSignedMultPart.length; j++) {
              const multPartPreSigned = preSignedUrl.preSignedMultPart[j];

              const start = j * this.FILE_CHUNK_SIZE;
              const end = (j + 1) * this.FILE_CHUNK_SIZE;
              let blob = null;
              if (
                this.treeviewStore.filesAddedForUpload[i].size - end <
                this.FILE_MIN_CHUNK_SIZE
              ) {
                blob = this.treeviewStore.filesAddedForUpload[i].content.slice(
                  start,
                  this.treeviewStore.filesAddedForUpload[i].size,
                );
              } else if (j < preSignedUrl.preSignedMultPart.length) {
                blob = this.treeviewStore.filesAddedForUpload[i].content.slice(
                  start,
                  end,
                );
              } else {
                blob =
                  this.treeviewStore.filesAddedForUpload[i].content.slice(
                    start,
                  );
              }

              promises.push(axios.put(multPartPreSigned, blob));
            }
            const resParts = await Promise.all(promises);
            const etagsPartNumberValue = resParts.map((part, index) => ({
              ETag: this.replaceAll(part.headers.etag, '"', ''),
              PartNumber: index + 1,
            }));

            const paramss = {
              completeMultPart: true,
              parts: etagsPartNumberValue,
              extraDocs:
                this.treeviewStore.context === TreeviewItemContext.edoc,
              uploadId: preSignedUrl.uploadId,
              s3key: this.treeviewStore.filesAddedForUpload[i].s3key,
              project: this.store.selectedProject.gsi_id,
              product: this.store.selectedProduct.gsi_id,
              productId: this.store.selectedProduct.id,
            };
            this.cfgVlr(params);
            await this.dataBookService.documents(paramss);
          }
        }
        /////////////////////////
        // PROCESS MULTIPART
        /////////////////////////

        /////////////////////////
        // PROCESS NONE MULTIPART
        /////////////////////////
        for (let i = 0; i < presignedUrlsFromServeur.length; i++) {
          const preSignedUrl: string = result.preSignedUrls[i];
          const folderBeingUploadTo =
            this.treeviewStore.folderTargetedForUpload;
          const completePath = this.mountPath(folderBeingUploadTo);

          await this.callHttpPut(
            preSignedUrl,
            this.treeviewStore.filesAddedForUpload[i].content,
            this.treeviewStore.filesAddedForUpload[i].revision,
            this.treeviewStore.filesAddedForUpload[i].name,
            this.treeviewStore.folderTargetedForUpload.name,
            completePath,
          );
        }
        /////////////////////////
        // PROCESS NONE MULTIPART
        /////////////////////////

        const paramsss = {
          extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
          update: this.treeviewStore.fileSelectedForBeingUpdated !== null,
          add: uploadFiles,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          productId: this.store.selectedProduct.id,
        };
        console.log('paramsss', paramsss);
        this.cfgVlr(paramsss);
        const persisted: { savedFiles: TreeviewFileItem[] } =
          await this.dataBookService.documents(paramsss);
        console.log('persisted', persisted);

        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );
        this.sendGtag('upload');

        await this.treeviewStore.updateViewFilesAndFolders(
          this.treeviewStore.folderTargetedForUpload,
          true,
        );
        // if ( this.treeviewStore.fileSelectedForBeingUpdated === null && this.treeviewStore.filesAddedForUpload.length > 0 && this.treeviewStore.folderTargetedForUpload !== null) {
        //   if(persisted && persisted.savedFiles && persisted.savedFiles.length > 0) {
        //     persisted.savedFiles.forEach((file: TreeviewFileItem) => {
        //       this.treeviewStore.addFileIntoFolder(file, this.treeviewStore.folderTargetedForUpload);
        //     });
        //   }
        // } else {
        //     if(persisted && persisted.savedFiles && persisted.savedFiles.length > 0) {
        //         persisted.savedFiles.forEach((file: TreeviewFileItem) => {
        //           this.treeviewStore.updateFileInTree(file);
        //         });
        //     }
        // }
        // await this.findTree();
        this.initModalUpload();
        this.initModal();
      }
      // await this.initInterval();
    } catch (error) {
      await this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }

  async findTree() {
    const params = {
      extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
      init: true,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    this.dataBookService.documents(params).then((result) => {
      this.populateResult(result);
    });
  }

  private validateFile(result, file: TreeviewFileItem) {
    if (!file.revision && file.revision !== 0) {
      this.coreService.showAlertErrorTranslate('databook.required.revision');
      return false;
    }
    if (!file || !file.content || file.content.byteLength < 1500) {
      this.coreService.showAlertErrorTranslate('databook.size.problem');
      return false;
    }
    return true;
  }

  async updatedatetime() {
    this.coreService.showLoading();
    try {
      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        updatedatetime: true,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      await this.dataBookService.documents(params);
    } catch (error) {
      await this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }

  /**
   * Reset the modal initial state
   */
  initModal() {
    //this.tree.treeModel.update();
    //this.expandAll();
  }

  cancelRemove() {
    this.dataRemove = null;
    this.actionConfirm = 'none';
  }

  openRemoveModal(val) {
    this.isNodeFixed(val);
    this.dataRemove = val;
    this.actionConfirm = 'show';
  }

  /**
   * Remove seleted node and the children
   */
  async removeNode() {
    this.coreService.showLoading();
    const node = this.dataRemove.data;
    for (const item of this.nodes) {
      if (item.id === node.id) {
        this.nodes = _.filter(this.nodes, (data) => data.id !== node.id);
        break;
      } else {
        await this.remove(node.id, item);
      }
    }
    await this.updateTree();
    this.coreService.hideLoading();
    this.initModal();
    this.currentNode = null;

    this.dataRemove = null;
    this.actionConfirm = 'none';

    await this.coreService.showAlertSuccessTranslate(
      'application.commons.success.operation',
    );
  }

  /**
   * Remove the file in the AWS S3 and update DynamoDB
   */
  async removeServerFile(node) {
    if (node.category) {
      const nodeTree = this.tree.treeModel.getNodeById(node.id);
      const completePath = this.mountPath(nodeTree);

      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        remove: node.id /*file: node, */,
        productId: this.store.selectedProduct.id,
        path: completePath,
        fileName: node.description,
        now: moment().format('DD/MM/YYYY HH:mm:ss'),
        datetime: Date.now(),
        currentuser: {
          email: this.coreService.getConfig().currentUser.email,
          name:
            this.coreService.getConfig().currentUser.firstName +
            ' ' +
            this.coreService.getConfig().currentUser.lastName,
        },
      };
      this.cfgVlr(params);
      await this.dataBookService.documents(params);
    }
    if (node.children && node.children.length) {
      for (const item of node.children) {
        if (item.category) {
          const nodeTree = this.tree.treeModel.getNodeById(node.id);
          const completePath = this.mountPath(nodeTree);

          const params = {
            extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
            remove: item.id,
            file: item,
            path: completePath,
            fileName: node.description,
            datetime: Date.now(),
            now: moment().format('DD/MM/YYYY HH:mm:ss'),
            currentuser: {
              email: this.coreService.getConfig().currentUser.email,
              name:
                this.coreService.getConfig().currentUser.firstName +
                ' ' +
                this.coreService.getConfig().currentUser.lastName,
            },
            project: this.store.selectedProject.gsi_id,
            product: this.store.selectedProduct.gsi_id,
            productId: this.store.selectedProduct.id,
          };
          this.cfgVlr(params);
          await this.dataBookService.documents(params);
        }
        if (item.children && item.children.length) {
          await this.removeServerFile(item);
        }
      }
    }
  }

  canDefineCategory() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.CATEGORY_MANAGEMENT
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.CATEGORY_MANAGEMENT
    ) {
      return true;
    }
    return false;
  }

  isLucas() {
    return this.currentUser.email === 'lucasferre@gmail.com';
  }

  canUpload() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.UPLOAD
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.UPLOAD
    ) {
      return true;
    }
    return false;
  }

  canRemove() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.REMOVE
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.REMOVE
    ) {
      return true;
    }
    return false;
  }

  canSeeRename() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.MULTIPLE_NAME
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.MULTIPLE_NAME
    ) {
      return true;
    }
    return false;
  }

  canSeeRevision() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.SEE_REVISION
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.SEE_REVISION
    ) {
      return true;
    }
    return false;
  }

  canRename() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.RENAME
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.RENAME
    ) {
      return true;
    }
    return false;
  }

  canDownload() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS.DOWNLOAD
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK.DOWNLOAD
    ) {
      return true;
    }
    return false;
  }

  isDataBookVisible() {
    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      if (
        this.store.selectedProject.gsi_id &&
        this.dataBookState.productId &&
        super.getRoleByProject().E_DOC.EXTRA_DOCUMENTS
      ) {
        return true;
      }
    } else if (
      this.store.selectedProject.gsi_id &&
      this.dataBookState.productId &&
      super.getRoleByProject().DATABOOK
    ) {
      return true;
    }
    return false;
  }

  async updateFileTree(descr, id) {
    try {
      this.coreService.showLoading();
      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        updateTree: this.nodes,
        fileDescription: descr,
        docId: id,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      await this.dataBookService.documents(params);
    } catch (error) {
      await this.coreService.processError(error);
    }
    this.coreService.hideLoading();
    // await this.initInterval();
  }

  /**
   * Update the tree definition document type in DynamoDB
   */
  async updateTree() {
    try {
      this.coreService.showLoading();
      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        updateTree: this.nodes,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      await this.dataBookService.documents(params);
    } catch (error) {
      this.coreService.processError(error);
    }
    this.coreService.hideLoading();
    // await this.initInterval();
  }

  /**
   * Iterate over nodes to find witch file we need to remove.
   */
  async remove(id, node) {
    for (const item of node.children) {
      if (item.id === id) {
        node.children = _.filter(node.children, (data) => data.id !== id);
        await this.removeServerFile(item);
        break;
      } else if (item.children && item.children.length) {
        await this.remove(id, item);
      }
    }
  }

  updateNode(node) {
    if (node.data.size) {
      node.data.description = this.newValue;
    } else {
      node.data.name = this.newValue;
    }
    node.treeModel.update();
    console.log(node.data.text);
    this.newValue = '';
  }

  /**
   * On file add to upload we need to set some properties to the file object, as product, project, id ...
   */
  async onFilesAdded(files: File[]) {
    if (
      this.treeviewStore.fileSelectedForBeingUpdated === null &&
      this.treeviewStore.folderTargetedForUpload !== null
    ) {
      this.onFilesAddedForMultipleFileUpload(files);
    } else {
      this.onFilesAddedForFileUpdate(files);
    }
  }

  async onFilesAddedForMultipleFileUpload(files: File[]) {
    this.coreService.showLoading();

    if (!this.multipleFiles) {
      this.treeviewStore.filesAddedForUpload = [];
    }
    let allFilesAccepted = true;
    let allSizeAccepted = true;
    let i = 0;

    for (const file of files) {
      const treeviewFile = plainToInstance(TreeviewFileItem, {
        id: null,
        name: file.name,
        size: file.size,
        filetype: file.type,
        datetime: Date.now(),
      });
      if (
        this.accept.includes(treeviewFile.filetype) ||
        treeviewFile.name.split('.').pop() === 'mpp' ||
        treeviewFile.name.split('.').pop() === 'prn'
      ) {
        if (treeviewFile.size > 1500) {
          const fileAlreadyInArray =
            this.treeviewStore.filesAddedForUpload.find(
              (_file: TreeviewFileItem) => {
                return treeviewFile.name === _file.name;
              },
            );
          if (fileAlreadyInArray !== undefined) {
            continue;
          }
          const existingCurrenteFiles =
            this.treeviewStore.folderTargetedForUpload.files.find(
              (_file: TreeviewFileItem) => {
                return _file.name === treeviewFile.name;
              },
            );
          if (existingCurrenteFiles !== undefined) {
            this.coreService.showAlertErrorTranslate('databook.existing.file');
            this.treeviewStore.filesAddedForUpload = [];
            break;
          }
          treeviewFile.folder_id =
            this.treeviewStore.folderTargetedForUpload !== null
              ? this.treeviewStore.folderTargetedForUpload.id
              : null;
          treeviewFile.s3key = uuid();
          treeviewFile.position =
            this.treeviewStore.folderTargetedForUpload.files.length + i;
          treeviewFile.description = file.name;
          treeviewFile.content = await this.readFile(file);
          this.treeviewStore.filesAddedForUpload.push(treeviewFile);
          i++;
        } else {
          allSizeAccepted = false;
        }
      } else {
        allFilesAccepted = false;
      }
    }
    if (!allFilesAccepted) {
      this.coreService.showAlertErrorTranslate('databook.content.type.problem');
    }
    if (!allSizeAccepted) {
      this.coreService.showAlertErrorTranslate('databook.size.problem');
    }
    this.coreService.hideLoading();
  }

  async onFilesAddedForFileUpdate(files: File[]) {
    this.coreService.showLoading();

    if (!this.multipleFiles) {
      this.treeviewStore.filesAddedForUpload = [];
    }
    let allFilesAccepted = true;
    let allSizeAccepted = true;
    let i = 0;

    for (const file of files) {
      const treeviewFile = plainToInstance(TreeviewFileItem, {
        id: null,
        name: file.name,
        size: file.size,
        filetype: file.type,
        datetime: Date.now(),
      });
      if (
        this.accept.includes(treeviewFile.filetype) ||
        treeviewFile.name.split('.').pop() === 'mpp' ||
        treeviewFile.name.split('.').pop() === 'prn'
      ) {
        if (treeviewFile.size > 1500) {
          const fileAlreadyInArray =
            this.treeviewStore.filesAddedForUpload.find(
              (_file: TreeviewFileItem) => {
                return treeviewFile.name === _file.name;
              },
            );
          if (fileAlreadyInArray !== undefined) {
            continue;
          }
          treeviewFile.folder_id =
            this.treeviewStore.fileSelectedForBeingUpdated.folder_id;
          treeviewFile.position =
            this.treeviewStore.fileSelectedForBeingUpdated.position;
          treeviewFile.description = file.name;
          treeviewFile.content = await this.readFile(file);
          this.treeviewStore.filesAddedForUpload.push(treeviewFile);
          i++;
        } else {
          allSizeAccepted = false;
        }
      } else {
        allFilesAccepted = false;
      }
    }
    if (!allFilesAccepted) {
      this.coreService.showAlertErrorTranslate('databook.content.type.problem');
    }
    if (!allSizeAccepted) {
      this.coreService.showAlertErrorTranslate('databook.size.problem');
    }
    this.coreService.hideLoading();
  }

  async readFile(file): Promise<any> {
    const content = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: ProgressEvent) => {
        resolve((e.target as FileReader).result);
      };
    });
    return content;
  }

  /**
   * Transform binary file into a base 64 string.
   */
  transformFileToBase64(content) {
    let binary = '';
    const bytes = new Uint8Array(content);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = btoa(binary);
    return base64;
  }

  /**
   * Add one category in the tree and update in the server
   */
  async addCategory() {
    if (this.category.value && this.category.value.trim().length) {
      try {
        this.coreService.showLoading();
        if (this.currentNode) {
          if (!this.currentNode.children) {
            this.currentNode[this.CHILDREN] = [];
          }
          this.currentNode.children.push({
            name: this.category.value,
            id: uuid(),
            children: [],
          });
        } else {
          this.tree.treeModel.nodes.unshift({
            phase: false,
            name: this.category.value,
            id: uuid(),
            children: [],
          });
        }
        await this.updateTree();
        this.initModal();
        this.closeModalCategory();
        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );
      } catch (error) {
        this.coreService.processError(error);
      }
      this.coreService.hideLoading();
      // await this.initInterval();
    } else {
      this.coreService.showAlertErrorTranslate('databook.invalid.values');
    }
  }

  /**
   * Reset category modal state
   */
  closeModalCategory() {
    this.actionCategory = 'none';
    this.databookForm.reset();
    this.currentNode = null;
  }

  /**
   * Reset category modal state
   */
  closeModalRevision() {
    this.actionRevision = 'none';
    this.currentNode = null;
  }

  openModalRename($event, node) {
    this.currentNode = node.data;
    if (this.currentNode.custumerDescription === undefined) {
      this.currentNode.custumerDescription = '';
    }
    if (this.currentNode.internalDescription === undefined) {
      this.currentNode.internalDescription = '';
    }

    this.internalDescription = this.currentNode.internalDescription;
    this.costumerDescription = this.currentNode.custumerDescription;
    this.description = this.currentNode.description;
    this.actionRename = 'show';
  }

  async confirmRename() {
    if (
      this.currentNode.description &&
      this.currentNode.description.trim().length
    ) {
      try {
        const params = {
          extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
          updateTree: this.nodes,
          fileDescription: this.currentNode.description,
          internalDescription: this.currentNode.internalDescription,
          custumerDescription: this.currentNode.custumerDescription,
          docId: this.currentNode.id,
          project: this.store.selectedProject.gsi_id,
          product: this.store.selectedProduct.gsi_id,
          productId: this.store.selectedProduct.id,
        };
        this.coreService.showLoading();
        this.cfgVlr(params);
        await this.dataBookService.documents(params);
      } catch (error) {
        await this.coreService.processError(error);
      }
      this.coreService.hideLoading();

      this.internalDescription = null;
      this.costumerDescription = null;
      this.description = null;
      this.actionRename = 'none';
    } else {
      this.coreService.showAlertErrorTranslate('databook.description.required');
    }
  }

  cancelRename() {
    this.actionRename = 'none';

    this.currentNode.internalDescription = this.internalDescription;
    this.currentNode.custumerDescription = this.costumerDescription;
    this.currentNode.description = this.description;

    this.internalDescription = null;
    this.costumerDescription = null;
    this.description = null;
  }
  viewDocumentOnError(error: any) {
    console.log('error => ', error);
    // do anything
  }

  viewDocumentOnProgress(progressData: any) {
    console.log('progressData => ', progressData);
    // do anything with progress data. For example progress indicator
  }
  async viewDocument2(node: { file: TreeviewFileItem }) {
    console.log('node => ', node.file);

    this.coreService.showLoading();

    const params = {
      extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
      download: [node.file],
      productId: this.store.selectedProduct.id,
      samePath: this.treeviewStore.samePath,
      // attach: 'text/html',
      attach: 'application/pdf',
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);

    if (node.file.name.endsWith('.pdf')) {
      this.documentFile = linkFile.downloadFiles;
      this.htmlFile = null;
      this.openDocumentPdfViewModal = 'open';
      // } else if (node.data.name.endsWith('.html')) {
      //     this.documentFile = null;
      //     this.htmlFile = linkFile.downloadFiles + '&embedded=true';
      //     this.openDocumentHtmlViewModal = 'open';
    }
    this.sendGtag('view');
  }

  async viewDocument($event, node) {
    this.coreService.showLoading();

    const params = {
      extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
      download: [node.data],
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
      samePath: this.treeviewStore.samePath,
      // attach: 'text/html',
      attach: 'application/pdf',
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);

    if (node.data.name.endsWith('.pdf')) {
      this.documentFile = linkFile.downloadFiles;
      this.htmlFile = null;
      this.openDocumentPdfViewModal = 'open';
      // } else if (node.data.name.endsWith('.html')) {
      //     this.documentFile = null;
      //     this.htmlFile = linkFile.downloadFiles + '&embedded=true';
      //     this.openDocumentHtmlViewModal = 'open';
    }
    this.sendGtag('view');
  }
  async closeViewDocument() {
    this.openDocumentPdfViewModal = 'none';
    this.openDocumentHtmlViewModal = 'none';
  }

  /**
   * Open category modal
   */
  async openModalRevision(fileId, fileName) {
    try {
      this.initForm();

      this.coreService.showLoading();

      this.actionRevision = 'show';
      this.revisions = [];

      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        listObjectVersions: true,
        id: fileId,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      const result = await this.dataBookService.documents(params);

      this.revisions = result.listObjectVersions;

      for (const item of this.revisions) {
        item.date = moment(item.date).format('DD/MM/YYYY HH:mm:ss');

        if (item.originalName) {
          const buffer = Buffer.from(item.originalName, 'base64');
          item.originalName = buffer.toString();
        } else {
          item.originalName = fileName;
        }
      }
    } catch (error) {
      this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  }

  async downloadRevision(rev) {
    try {
      this.coreService.showLoading();
      let nameFile = this.currentNode.file.name;
      if (rev.originalName) {
        nameFile = rev.originalName;
      }
      const params = {
        extraDocs: this.treeviewStore.context === TreeviewItemContext.edoc,
        download: [
          {
            s3key: this.currentNode.file.s3key,
            version: rev.version,
            name: nameFile,
            description: nameFile,
          },
        ],
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      const linkFile = await this.dataBookService.documents(params);

      const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
      a.download = this.currentNode.file.name;
      a.href = linkFile.downloadFiles;
      a.click();
      await this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
      );
    } catch (error) {
      this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }

  /**
   * Open category modal
   */
  async openModalCategory($event, node) {
    await this.stopInterval();
    this.actionCategory = 'add';
    if (node) {
      this.currentNode = node.data;
    }
    this.revisions = [];
  }

  /**
   * Clonse modal category.
   */
  async cancelCategory() {
    this.closeModalCategory();
    // await this.initInterval();
  }

  /**
   * BreadCrumb configuration - links and labels
   */
  async configBreadCrumb() {
    const params = await this.coreService.getRouterParams();
    this.extraDocuments =
      this.treeviewStore.context === TreeviewItemContext.edoc;
    if (
      (this.treeviewStore.context === TreeviewItemContext.edoc) !== null &&
      (this.treeviewStore.context === TreeviewItemContext.edoc) !== undefined
    ) {
      this.extraDocuments = false;
      if (params.extraDocuments === 'true') {
        this.extraDocuments = true;
      }
    }
    if (!(this.treeviewStore.context === TreeviewItemContext.edoc)) {
      this.breadcrumbCurrent = this.coreService.translate(
        'databook.databook',
        null,
      );
      this.breadcrumb = [
        {
          name: this.dataBookState.projectName,
          link: '/main/project',
          type: this.coreService.translate('databook.project', null),
        },
        {
          name: this.dataBookState.productDsc,
          link: '/main/product',
          type: this.coreService.translate('databook.product', null),
        },
        {
          name: this.coreService.translate('databook.dashboard', null),
          link: '/',
        },
      ];
    } else {
      this.breadcrumbCurrent = this.coreService.translate(
        'databook.extra.documentation',
        null,
      );
      this.breadcrumb = [
        {
          name: this.dataBookState.projectName,
          link: '/main/project',
          type: this.coreService.translate('databook.project', null),
        },
        {
          name: this.dataBookState.productDsc,
          link: '/main/product',
          type: this.coreService.translate('databook.product', null),
        },
        {
          name: this.coreService.translate('databook.dashboard', null),
          link: '/',
        },
        // ,{name: this.coreService.translate('dashboard.e.doc', null), link: '/main/e-doc'}
      ];
    }
  }

  onDrop() {
    // console.log(event);
    // Do something with the event here
  }

  get category() {
    return this.databookForm.get('category');
  }
}
