import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const getFolderByOldId = async (
  dataBookService: DataBookService,
  oldId: string,
): Promise<number | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'getFolderByOldId',
    args: {
      oldId,
    },
  };
  return await dataBookService.newLambda<number | undefined>(params);
};
