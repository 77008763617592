import { DataBookService } from '../../services/databook.service';
import { UserDTO } from '../user.dto';

export const assignPermissionToUser = async (
  dataBookService: DataBookService,
  userId: number,
  permissionId: number,
  productFeatureId: number,
): Promise<UserDTO> => {
  const params: { functionName; args: any } = {
    functionName: 'assignPermissionToUser',
    args: {
      userId,
      permissionId,
      productFeatureId,
    },
  };
  return await dataBookService.newLambda<UserDTO>(params);
};

export const unassignPermissionToUser = async (
  dataBookService: DataBookService,
  userId: number,
  permissionId: number,
  productFeatureId: number,
): Promise<UserDTO> => {
  const params: { functionName; args: any } = {
    functionName: 'unassignPermissionToUser',
    args: {
      userId,
      permissionId,
      productFeatureId,
    },
  };
  return await dataBookService.newLambda<UserDTO>(params);
};
