import { ExcelStyle } from 'ag-grid-community';

export const LICENSE_KEY =
  'Using_this_AG_Grid_Enterprise_key_( AG-041760 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Vallourec Tubes France )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 28 May 2024 )____[v2]_MTcxNjg1MDgwMDAwMA==f064cc1e976cb438e85e727d35d05901';

export const EXCEL_STYLES = [
  {
    id: 'numberType',
    numberFormat: {
      format: '0',
    },
  },
  {
    id: 'currencyFormat',
    numberFormat: {
      format: '#,##0.00 €',
    },
  },
  {
    id: 'negativeInBrackets',
    numberFormat: {
      format: '$[blue] #,##0;$ [red](#,##0)',
    },
  },
  {
    id: 'booleanType',
    dataType: 'Boolean',
  },
  {
    id: 'stringType',
    dataType: 'String',
  },
  {
    id: 'dateType',
    dataType: 'DateTime',
  },
  {
    id: 'custom-header-pinned',
    interior: {
      color: '#BEC5AD',
      pattern: 'Solid',
    },
  },
  {
    id: 'custom-header-1',
    interior: {
      color: '#F2F2F2',
      pattern: 'Solid',
    },
  },
  {
    id: 'custom-header-2',
    interior: {
      color: '#96BBBB',
      pattern: 'Solid',
    },
  },
  {
    id: 'custom-header-3',
    interior: {
      color: '#FEC601',
      pattern: 'Solid',
    },
  },
  {
    id: 'custom-header-4',
    interior: {
      color: '#BF4040',
      pattern: 'Solid',
    },
  },
  {
    id: 'custom-header-5',
    interior: {
      color: '#BF4',
      pattern: 'Solid',
    },
  },
  {
    id: 'lightGrayBackground',
    interior: {
      color: '#D3D3D3',
      pattern: 'Solid',
    },
    font: {
      fontName: 'Calibri Light',
    },
  },
  {
    id: 'customCellDataExplorerTwoDigits',
    numberFormat: {
      format: '0.00',
    },
  },
  {
    id: 'customCellDataExplorerThreeDigits',
    numberFormat: {
      format: '0.000',
    },
  },
  {
    id: 'customCellDataExplorerFourDigits',
    numberFormat: {
      format: '0.0000',
    },
  },
  {
    id: 'customCellDataExplorerIppn',
    numberFormat: {
      format: '0',
    },
  },
  {
    id: 'customCellDataExplorer',
    numberFormat: {},
  },
] as ExcelStyle[];

export const ERROR_CODE_NO_PRODUCT_SELECTED = {
  code: 1,
  message: 'There is no selected product founded.',
};
export const ERROR_CODE_NO_DATA_TO_SUBMIT = {
  code: 2,
  message: 'There is no data to submit to grid.',
};
