import { NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ClrCommonFormsModule, ClrLoadingButtonModule, ClrLoadingModule,
  ClrModalModule,
  ClrPopoverHostDirective,
  ClrStopEscapePropagationDirective
} from '@clr/angular';
import { CoreService } from '@core/core.service';
import { PROJECT_STORE, ProjectsStore } from '@mobx-stores/projects.store';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '@reactive-forms/components/date-picker/date-picker.component';
import { InputComponent } from '@reactive-forms/components/input/input.component';
import { DataBookService } from '@services/databook.service';
import { Project } from '../../../classes';
import { DataBookState } from '../../../services/databook.state';

@Component({
  selector: 'create-edit-project-modal',
  templateUrl: './create-edit-project-modal.component.html',
  styleUrls: ['./create-edit-project-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    NgIf,
    FormsModule,
    ClrCommonFormsModule,
    ReactiveFormsModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrLoadingButtonModule,
    ClrLoadingModule,
    TranslateModule,
    InputComponent,
    DatePickerComponent,
  ],
})
export class CreateEditProjectModalComponent implements OnInit {
  @Input() modal;
  @Input() closeModal;
  @Input() modalIsOpen;
  @Input() populateResult;
  @Input() currentUser;
  @Input() updateProjects: CallableFunction;

  @Input() set project(value: Project) {
    this._project = value;

    if (value === null) {
      this.formGroup.reset();
    }

    if (!value?.id) {
      return;
    }

    this.formGroup.patchValue({
      id: parseString(value.gsi_id),
      ...[
        'poDate',
        'poNumber',
        'name',
        'customer',
        'lastModifiedBy',
        'ownerEmail',
        'ownerName',
        'lastModifiedDate',
      ].reduce((obj, key) => {
        obj[key] = parseString(value[key]);
        return obj;
      }, {}),
    });
  }

  get project(): Project {
    return this._project;
  }

  private _project: Project;

  formGroup: FormGroup = new FormGroup({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern('^[A-Za-z0-9À-ÖØ-öø-ÿ)( _-]+$'),
    ]),
    ownerEmail: new FormControl(
      '',
      Validators.compose([
        Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
      ]),
    ),
    ownerName: new FormControl('', [
      Validators.minLength(2),
      Validators.pattern(
        '^[A-Za-z0-9À-ÖØ-öø-ÿ]+(?:[ _-][A-Za-z0-9À-ÖØ-öø-ÿ]+)*$',
      ),
    ]),
    poDate: new FormControl(''),
    poNumber: new FormControl(''),
    customer: new FormControl(''),
    lastModifiedBy: new FormControl({ value: '', disabled: true }),
    lastModifiedDate: new FormControl({ value: '', disabled: true }),
  });

  constructor(
    public coreService: CoreService,
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
  ) {}

  ngOnInit(): void {
    if (this.modal.isEdit) {
      this.formGroup.get('id').disable();
    }
  }
  async saveProject(): Promise<void> {
    const action = this.modal.isEdit ? 'edit' : 'add';

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
    }

    this.coreService.showLoading();

    const params = this.generateParams(action);

    try {
      if (!params?.name?.trim().length || !params?.gsi_id?.trim().length) {
        this.coreService.showAlertErrorTranslate('project.required.fields');
        this.coreService.hideLoading();
        return;
      }

      const result = await this.dataBookService.project(params);

      await this.updateProjects();

      if (result?.project_exists) {
        this.coreService.showAlertErrorTranslate('project.already.exists');
        this.coreService.hideLoading();
        return;
      }

      this.formGroup.reset();
      await this.populateResult(result);

      this.coreService.showAlertSuccessTranslate(
        params.save === true
          ? 'project.created.success'
          : 'project.modified.success',
      );

      this.coreService.hideLoading();
      this.closeModal();
    } catch (error) {
      if (error?.errorMessage) {
        this.coreService.showAlertErrorTranslate(error.errorMessage);
        this.coreService.hideLoading();

        return;
      }

      this.coreService.showAlertErrorTranslate(
        'application.commons.error.message.server.error',
      );

      this.coreService.hideLoading();
    }
  }

  generateParams = (action): Param => ({
    id: this.project?.id,
    name: this.formGroup.get('name').value,
    poDate: this.formGroup.get('poDate').value,
    poNumber: this.formGroup.get('poNumber').value,
    customer: this.formGroup.get('customer').value,
    gsi_id: this.formGroup.get('id').value,
    ownerName: this.formGroup.get('ownerName').value,
    ownerEmail: this.formGroup.get('ownerEmail').value,
    save: action === 'add',
    edit: action === 'edit',
    self: this.currentUser.email,
  });
}

interface Param {
  name: string;
  poDate?: string;
  poNumber?: number;
  customer?: string;
  id: number;
  gsi_id: string;
  save?: boolean;
  edit?: boolean;
  self?: string;
  ownerName?: string;
  ownerEmail?: string;
  userId?: string;
}

function parseString(value: unknown): string {
  if (!value) return '';
  const valueString = String(value);
  return valueString === 'null' ? '' : valueString;
}
