<vic-base-page page="mlp">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isAshDensityVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            *ngIf="shouldShowColumn('Pipe_Heat')"
            [style.width.px]="200"
            >CS heat number</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('supplier')"
            [style.width.px]="200"
            >Supplier</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
            [style.width.px]="200"
            >Pipe num</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('mlp_num')"
            [style.width.px]="200"
            >MLP num</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('side')"
            [style.width.px]="200"
            >Side</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_nominal')"
            [style.width.px]="200"
            >ID nominal</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_tolerance')"
            [style.width.px]="200"
            >ID tolerance</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_ae')"
            [style.width.px]="200"
            >ID Pipe end 10mm quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_bf')"
            [style.width.px]="200"
            >ID Pipe end 10mm quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_cg')"
            [style.width.px]="200"
            >ID Pipe end 10mm quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_dh')"
            [style.width.px]="200"
            >ID Pipe end 10mm quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_ae')"
            [style.width.px]="200"
            >ID Pipe end 50mm quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_bf')"
            [style.width.px]="200"
            >ID Pipe end 50mm quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_cg')"
            [style.width.px]="200"
            >ID Pipe end 50mm quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_dh')"
            [style.width.px]="200"
            >ID Pipe end 50mm quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_nominal')"
            [style.width.px]="200"
            >OD nominal</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_tolerance')"
            [style.width.px]="200"
            >OD tolerance</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_ae')"
            [style.width.px]="200"
            >OD Pipe end 10mm quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_bf')"
            [style.width.px]="200"
            >OD Pipe end 10mm quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_cg')"
            [style.width.px]="200"
            >OD Pipe end 10mm quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_dh')"
            [style.width.px]="200"
            >OD Pipe end 10mm quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_ae')"
            [style.width.px]="200"
            >OD Pipe end 50mm quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_bf')"
            [style.width.px]="200"
            >OD Pipe end 50mm quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_cg')"
            [style.width.px]="200"
            >OD Pipe end 50mm quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_dh')"
            [style.width.px]="200"
            >OD Pipe end 50mm quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_id_pipe_end_tolerance')"
            [style.width.px]="200"
            >OOR ID Pipe end tolerance</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_id_pipe_end_quad_ae')"
            [style.width.px]="200"
            >OOR ID Pipe end quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_id_pipe_end_quad_bf')"
            [style.width.px]="200"
            >OOR ID Pipe end quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_id_pipe_end_quad_cg')"
            [style.width.px]="200"
            >OOR ID Pipe end quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_id_pipe_end_quad_dh')"
            [style.width.px]="200"
            >OOR ID Pipe end quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_od_pipe_end_tolerance')"
            [style.width.px]="200"
            >OOR OD Pipe end tolerance</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_od_pipe_end_quad_ae')"
            [style.width.px]="200"
            >OOR OD Pipe end quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_od_pipe_end_quad_bf')"
            [style.width.px]="200"
            >OOR OD Pipe end quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_od_pipe_end_quad_cg')"
            [style.width.px]="200"
            >OOR OD Pipe end quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('oor_od_pipe_end_quad_dh')"
            [style.width.px]="200"
            >OOR OD Pipe end quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_nominal')"
            [style.width.px]="200"
            >WT nominal</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_tolerance')"
            [style.width.px]="200"
            >WT tolerance</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_ae')"
            [style.width.px]="200"
            >WT Pipe end 10mm quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_bf')"
            [style.width.px]="200"
            >WT Pipe end 10mm quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_cg')"
            [style.width.px]="200"
            >WT Pipe end 10mm quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_dh')"
            [style.width.px]="200"
            >WT Pipe end 10mm quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_ae')"
            [style.width.px]="200"
            >WT Pipe end 50mm quad ae</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_bf')"
            [style.width.px]="200"
            >WT Pipe end 50mm quad bf</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_cg')"
            [style.width.px]="200"
            >WT Pipe end 50mm quad cg</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_dh')"
            [style.width.px]="200"
            >WT Pipe end 50mm quad dh</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('pipe_length')"
            [style.width.px]="200"
            >Pipe length</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('pipe_weight')"
            [style.width.px]="200"
            >Pipe weight</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('cs_smaller_thickness')"
            [style.width.px]="200"
            >CS smaller thickness</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('weld_overlay_length')"
            [style.width.px]="200"
            >Weld overlay length</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('liner_heat_number')"
            [style.width.px]="200"
            >Liner heat number</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('supplier')">{{
              data['supplier']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('mlp_num')">{{
              data['mlp_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('side')">{{
              data['side']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_nominal')">{{
              data['id_nominal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_tolerance')">{{
              data['id_tolerance']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_ae')">{{
              data['id_pipe_end_10mm_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_bf')">{{
              data['id_pipe_end_10mm_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_cg')">{{
              data['id_pipe_end_10mm_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_10mm_quad_dh')">{{
              data['id_pipe_end_10mm_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_ae')">{{
              data['id_pipe_end_50mm_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_bf')">{{
              data['id_pipe_end_50mm_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_cg')">{{
              data['id_pipe_end_50mm_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('id_pipe_end_50mm_quad_dh')">{{
              data['id_pipe_end_50mm_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_nominal')">{{
              data['od_nominal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_tolerance')">{{
              data['od_tolerance']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_ae')">{{
              data['od_pipe_end_10mm_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_bf')">{{
              data['od_pipe_end_10mm_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_cg')">{{
              data['od_pipe_end_10mm_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_10mm_quad_dh')">{{
              data['od_pipe_end_10mm_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_ae')">{{
              data['od_pipe_end_50mm_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_bf')">{{
              data['od_pipe_end_50mm_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_cg')">{{
              data['od_pipe_end_50mm_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_50mm_quad_dh')">{{
              data['od_pipe_end_50mm_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('oor_id_pipe_end_tolerance')"
              >{{ data['oor_id_pipe_end_tolerance'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('oor_id_pipe_end_quad_ae')">{{
              data['oor_id_pipe_end_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('oor_id_pipe_end_quad_bf')">{{
              data['oor_id_pipe_end_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('oor_id_pipe_end_quad_cg')">{{
              data['oor_id_pipe_end_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('oor_id_pipe_end_quad_dh')">{{
              data['oor_id_pipe_end_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('oor_od_pipe_end_tolerance')"
              >{{ data['oor_od_pipe_end_tolerance'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('oor_od_pipe_end_quad_ae')">{{
              data['oor_od_pipe_end_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('oor_od_pipe_end_quad_bf')">{{
              data['oor_od_pipe_end_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('oor_od_pipe_end_quad_cg')">{{
              data['oor_od_pipe_end_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('oor_od_pipe_end_quad_dh')">{{
              data['oor_od_pipe_end_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_nominal')">{{
              data['wt_nominal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_tolerance')">{{
              data['wt_tolerance']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_ae')">{{
              data['wt_pipe_end_10mm_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_bf')">{{
              data['wt_pipe_end_10mm_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_cg')">{{
              data['wt_pipe_end_10mm_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_10mm_quad_dh')">{{
              data['wt_pipe_end_10mm_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_ae')">{{
              data['wt_pipe_end_50mm_quad_ae']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_bf')">{{
              data['wt_pipe_end_50mm_quad_bf']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_cg')">{{
              data['wt_pipe_end_50mm_quad_cg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_50mm_quad_dh')">{{
              data['wt_pipe_end_50mm_quad_dh']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('pipe_length')">{{
              data['pipe_length']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('pipe_weight')">{{
              data['pipe_weight']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('cs_smaller_thickness')">{{
              data['cs_smaller_thickness']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('weld_overlay_length')">{{
              data['weld_overlay_length']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('liner_heat_number')">{{
              data['liner_heat_number']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="openModalIRN"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div *ngIf="!items || !items.length">NO DATA</div>
    <clr-datagrid
      *ngIf="irn && irn.length"
      class="val-datagrid"
    >
      <clr-dg-column
        *ngIf="shouldShowColumn('Pipe_Num')"
        [style.width.px]="100"
        >{{ 'data.book.pipe.num' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Quality_Cerficate_Num')"
        [style.width.px]="200"
        >{{ 'data.book.quality.certificate.num' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Heat')"
        [style.width.px]="220"
        >{{ 'data.book.heat' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Bundle')"
        [style.width.px]="220"
        >{{ 'data.book.bundle' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Length')"
        [style.width.px]="220"
        >{{ 'data.book.length' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Weight')"
        [style.width.px]="220"
        >{{ 'data.book.weight' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Batch')"
        [style.width.px]="220"
        >{{ 'data.book.batch' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Technical_Deviation')"
        [style.width.px]="400"
        >{{ 'data.book.technical.deviation' | translate }}</clr-dg-column
      >

      <clr-dg-row *clrDgItems="let data of irn">
        <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Num')">{{
          data['Pipe_Num']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Quality_Cerficate_Num')">{{
          data['Quality_Cerficate_Num']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Heat')">{{
          data['Heat']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Bundle')">{{
          data['Bundle']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Length')">{{
          data['Length']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Weight')">{{
          data['Weight']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Batch')">{{
          data['Batch']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Technical_Deviation')">{{
          data['Technical_Deviation']
        }}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination
          #paginationTaskDetails
          [clrDgPageSize]="10"
        >
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
            {{
              'application.commons.grid.footer.pagination.page.size' | translate
            }}
          </clr-dg-page-size>
          {{ paginationTaskDetails.firstItem + 1 }} -
          {{ paginationTaskDetails.lastItem + 1 }}
          {{ 'application.commons.grid.footer.pagination.of' | translate }}
          {{ paginationTaskDetails.totalItems }}
          {{ 'application.commons.grid.footer.pagination.records' | translate }}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.close' | translate }}
    </button>
  </div>
</clr-modal>
