import { Component, Inject } from '@angular/core';
import { DataBookBaseComponent } from '../databook-base.component';
import { DataBookState } from '../../services/databook.state';
import { DataBookService } from '../../services/databook.service';
import { CoreService } from '../../core/core.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../../components/pdf/pdf.service';
import { HttpClient } from '@angular/common/http';
import { ConfigHandler } from '../../core/handlers/config.handler';
import { FormBuilder, FormsModule } from '@angular/forms';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import {
  assignPermissionToUser,
  createUser,
  unassignPermissionToUser,
} from '../../classes/user-helpers';
import { UserDTO } from '../../classes';
import { NgFor, NgIf } from '@angular/common';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';
import { MobxAngularModule } from 'mobx-angular';

@Component({
  selector: 'users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss'],
  standalone: true,
  imports: [
    MobxAngularModule,
    BasePageComponent,
    BreadcrumbComponent,
    FormsModule,
    NgFor,
    NgIf,
  ],
})
export class UsersManagementComponent extends DataBookBaseComponent {
  newUserEmail = '';

  async addUser() {
    if (!this.validateEmailWithRegex(this.newUserEmail)) {
      alert('invalid email');
      return;
    }
    const newUsers = await createUser(this.dataBookService, this.newUserEmail);
    this.userStore.users = newUsers;
  }

  async selectUser(user: UserDTO) {
    this.userStore.selectedUser = user;
    await this.userStore.getPermissionMatriceForUser(
      this.dataBookService,
      user.id,
    );
  }

  async openProject() {
    if (this.userStore.selectedUser === null) {
      alert('please select a user');
      return;
    }
  }

  validateEmailWithRegex(email: string): boolean {
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
    return regex.test(email);
  }

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    private config: ConfigHandler,
    public formBuilder: FormBuilder,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  async checkboxClick(
    active: boolean,
    userId: number,
    permissionId: number,
    productFeatureId: number,
  ) {
    active = !active;
    if (active) {
      await assignPermissionToUser(
        this.dataBookService,
        userId,
        permissionId,
        productFeatureId,
      );
    } else {
      await unassignPermissionToUser(
        this.dataBookService,
        userId,
        permissionId,
        productFeatureId,
      );
    }
  }

  savePermissions() {
    this.userStore.saveUserPermissions(
      this.dataBookService,
      this.userStore.selectedUser.id,
      this.userStore.userPermissionsMatrice,
    );
  }
}
