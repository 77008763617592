import { Component, Inject, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreService } from '../../../core/core.service';

import { DataBookState } from '../../../services/databook.state';
import { DataBookService } from '../../../services/databook.service';
import { ProductModal } from '../product.types';
import { PROJECT_STORE, ProjectsStore } from '../../../mobx-stores';
import { TranslateModule } from '@ngx-translate/core';
import { InputFormControlComponent } from '../../../components/form/input-form-control/input-form-control.component';
import { NgIf } from '@angular/common';
import { ClrModalModule, ClrCommonFormsModule } from '@clr/angular';

@Component({
  selector: 'delete-product-modal',
  templateUrl: './delete-product-modal.component.html',
  styleUrls: ['./delete-product-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    NgIf,
    FormsModule,
    ClrCommonFormsModule,
    ReactiveFormsModule,
    InputFormControlComponent,
    TranslateModule,
  ],
})
export class DeleteProductModalComponent {
  @Input() form: FormGroup;
  @Input() openModal: (modalName: string) => void;
  @Input() closeModal: () => void;
  @Input() modal: ProductModal;
  @Input() modalIsOpen = false;

  constructor(
    public coreService: CoreService,
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
  ) {}

  isEqual() {
    return (
      this.store.selectedProduct?.name?.toUpperCase() !==
      this.form.get('name').value.toString().toUpperCase()
    );
  }

  confirmDelete() {
    this.form.controls['name'].setValue('');
    this.form.reset();
    this.openModal('deleteByText');
  }

  async delete() {
    try {
      this.coreService.showLoading();
      await this.dataBookService.product({
        id: this.store.selectedProduct.id,
        delete: true,
        self: this.coreService.getConfig().currentUser.email,
      });

      this.store.reloadProducts(this.dataBookService);

      await this.store.showAlertSuccessTranslate('product.removed.success');
      /*await this.coreService.showAlertSuccessTranslate(
        'product.removed.success'
      );*/
    } catch (error) {
      this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
      this.closeModal();
    }
    this.coreService.hideLoading();
  }
}
