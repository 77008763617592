<vic-base-page
  page="dashboard"
  *mobxAutorun
>
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="log()"
      *ngIf="canSeeLog() && false"
    >
      <clr-icon shape="plus"></clr-icon>
      <span class="nav-text">LOG</span>
    </a>
  </div>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>

    <div
      class="card-container"
      *ngIf="isDataExplorerVisible() || isDataAnalysisVisible()"
    ></div>

    <div class="dashboard-container">
      <div
        class="clr-row"
        *ngIf="isAdmin()"
        style="margin-bottom: 0vh"
      >
        <div class="clr-col-lg-6">
          <clr-toggle-container style="flex-direction: row-reverse">
            <clr-toggle-wrapper>
              <input
                type="checkbox"
                clrToggle
                [(ngModel)]="features.eDoc"
                (change)="enableDisableFeature(featuresEnum.eDoc)"
              />
              <label> {{ 'enable.disable' | translate }}</label>
            </clr-toggle-wrapper>
          </clr-toggle-container>
        </div>

        <div class="clr-col-lg-6">
          <clr-toggle-container style="flex-direction: row-reverse">
            <clr-toggle-wrapper>
              <input
                type="checkbox"
                clrToggle
                [(ngModel)]="features.materials"
                (change)="enableDisableFeature(featuresEnum.materials)"
              />
              <label> {{ 'enable.disable' | translate }}</label>
            </clr-toggle-wrapper>
          </clr-toggle-container>
        </div>
      </div>

      <div class="clr-row">
        <div
          class="clr-col-lg-6"
          *ngIf="isEDocVisible() || isAdmin()"
        >
          <div
            [ngClass]="{ 'dashboard-item-disabled': !features.eDoc }"
            class="dashboard-item"
            (click)="
              features.eDoc &&
                selectItemDashBoardParams('e-doc', { extraDocuments: true })
            "
          >
            <img
              alt="eDoc Logo"
              src="assets/img/svg/doc_e.svg"
            />
            <div class="dashboard-item-info">
              <p
                class="session-title"
                [ngClass]="{ 'session-title-disabled': !features.eDoc }"
              >
                {{ 'dashboard.e.doc' | translate }}
              </p>
              <label
                class="label-medium"
                [ngClass]="{ 'label-medium-disabled': !features.eDoc }"
              >
                {{ 'databook.dashboard.e.doc.description' | translate }}
              </label>
            </div>
            <br />
          </div>
        </div>
        <div
          class="clr-col-lg-6"
          *ngIf="isQpVisible() || isAdmin() || isMaterialVisible()"
        >
          <div
            [ngClass]="{ 'dashboard-item-disa  bled': !features.materials }"
            class="dashboard-item"
            (click)="
              features.materials && selectItemDashBoardScope('materials')
            "
          >
            <img
              alt="Project Management Logo"
              src="assets/img/svg/project_management.svg"
            />
            <div class="dashboard-item-info">
              <p
                class="session-title"
                [ngClass]="{ 'session-title-disabled': !features.materials }"
              >
                {{ 'databook.material.project' | translate }}
              </p>
              <label
                class="label-medium"
                [ngClass]="{ 'label-medium-disabled': !features.materials }"
              >
                {{ 'databook.dashboard.quality.plan.description' | translate }}
              </label>
            </div>
          </div>
        </div>

        <div
          class="clr-row"
          *ngIf="isAdmin()"
          style="margin-bottom: 0"
        >
          <div class="clr-col-lg-6">
            <clr-toggle-container style="flex-direction: row-reverse">
              <clr-toggle-wrapper>
                <input
                  type="checkbox"
                  clrToggle
                  [(ngModel)]="features.tpiDigital"
                  (change)="enableDisableFeature(featuresEnum.tpiDigital)"
                />
                <label> {{ 'enable.disable' | translate }}</label>
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </div>

          <div class="clr-col-lg-6">
            <clr-toggle-container style="flex-direction: row-reverse">
              <clr-toggle-wrapper>
                <input
                  type="checkbox"
                  clrToggle
                  [(ngModel)]="features.dataExplorer"
                  (change)="enableDisableFeature(featuresEnum.dataExplorer)"
                />
                <label> {{ 'enable.disable' | translate }}</label>
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </div>
        </div>

        <div
          class="clr-col-lg-6"
          *ngIf="isTpiVisible() || isAdmin()"
        >
          <div
            [ngClass]="{ 'dashboard-item-disabled': !features.tpiDigital }"
            class="dashboard-item"
            (click)="
              features.tpiDigital && selectItemDashBoard('new-tpi-digital')
            "
          >
            <img
              alt="Test Analysis Logo"
              src="assets/img/svg/test-analysis.svg"
            />
            <div class="dashboard-item-info">
              <p
                class="session-title"
                [ngClass]="{ 'session-title-disabled': !features.tpiDigital }"
              >
                {{ 'dashboard.tpi.digital' | translate }}
              </p>
              <label
                class="label-medium"
                [ngClass]="{ 'label-medium-disabled': !features.tpiDigital }"
              >
                {{ 'databook.dashboard.tpi.digital.description' | translate }}
              </label>
            </div>
          </div>
        </div>

        <div
          class="clr-col-lg-6"
          *ngIf="
            (isDataExplorerVisible() &&
              this.store.selectedProject &&
              this.store.selectedProject.gsi_id !== 'KEP-1') ||
            isAdmin()
          "
        >
          <div
            *ngIf="
              this.store.selectedProject && this.store.selectedProject.gsi_id
            "
            style="display: flex; flex-direction: row"
          >
            <div
              [ngClass]="{ 'dashboard-item-disabled': !features.dataExplorer }"
              class="dashboard-item"
              (click)="features.dataExplorer && gotToDataExplorer()"
            >
              <img
                alt="Data Explorer Logo"
                src="assets/img/svg/data_explorer.svg"
              />
              <div class="dashboard-item-info">
                <p
                  class="session-title data-explorer-title"
                  [ngClass]="{
                    'session-title-disabled': !features.dataExplorer
                  }"
                >
                  {{ 'dashboard.data.explorer' | translate }}
                </p>
                <label
                  class="label-medium"
                  [ngClass]="{
                    'label-medium-disabled': !features.dataExplorer
                  }"
                >
                  {{
                    'databook.dashboard.dataexplorer.description' | translate
                  }}
                </label>
              </div>
            </div>

            <div
              [ngClass]="{ 'dashboard-item-disabled': !features.dataExplorer }"
              style="width: 20%"
              class="dashboard-item"
              (click)="features.dataExplorer && upload()"
              *ngIf="canUpload()"
            >
              <div class="dashboard-item-info">
                <p
                  class="session-title data-explorer-title"
                  [ngClass]="{
                    'session-title-disabled': !features.dataExplorer
                  }"
                >
                  Data Upload
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-row"
          *ngIf="isAdmin()"
          style="margin-bottom: 0"
        >
          <div class="clr-col-lg-6">
            <clr-toggle-container style="flex-direction: row-reverse">
              <clr-toggle-wrapper>
                <input
                  type="checkbox"
                  clrToggle
                  [(ngModel)]="features.databook"
                  (change)="enableDisableFeature(featuresEnum.databook)"
                />
                <label> {{ 'enable.disable' | translate }}</label>
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </div>

          <div class="clr-col-lg-6">
            <clr-toggle-container style="flex-direction: row-reverse">
              <clr-toggle-wrapper>
                <input
                  type="checkbox"
                  clrToggle
                  [(ngModel)]="features.dataAnalysis"
                  (change)="enableDisableFeature(featuresEnum.dataAnalysis)"
                />
                <label> {{ 'enable.disable' | translate }}</label>
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </div>
        </div>

        <div
          class="clr-col-lg-6"
          *ngIf="isDatabookVisible() || isAdmin()"
        >
          <div
            [ngClass]="{ 'dashboard-item-disabled': !features.databook }"
            class="dashboard-item"
            (click)="
              features.databook &&
                selectItemDashBoardParams('databook', { extraDocuments: false })
            "
          >
            <img
              alt="Databook Logo"
              src="assets/img/svg/databook_digital.svg"
            />
            <div class="dashboard-item-info">
              <p
                class="session-title"
                [ngClass]="{ 'session-title-disabled': !features.databook }"
              >
                {{ 'dashboard.databook' | translate }}
              </p>
              <label
                class="label-medium"
                [ngClass]="{ 'label-medium-disabled': !features.databook }"
                >{{
                  'databook.dashboard.databook.description' | translate
                }}</label
              >
            </div>
          </div>
        </div>

        <div
          class="clr-col-lg-6"
          *ngIf="
            (isDataAnalysisVisible() &&
              this.store.selectedProject &&
              this.store.selectedProject.gsi_id !== 'KEP-1') ||
            isAdmin()
          "
        >
          <div
            [ngClass]="{ 'dashboard-item-disabled': !features.dataAnalysis }"
            class="dashboard-item"
            (click)="
              features.dataAnalysis &&
                goToDataAnalysis(dataExplorerType.dataAnalysis)
            "
          >
            <img
              alt="Data Analysis Logo"
              src="assets/img/svg/data_analysis.svg"
            />
            <div class="dashboard-item-info">
              <p
                class="session-title data-analysis-title"
                [ngClass]="{ 'session-title-disabled': !features.dataAnalysis }"
              >
                {{ 'dashboard.data.analysis' | translate }}
              </p>
              <label
                class="label-medium"
                [ngClass]="{ 'label-medium-disabled': !features.dataAnalysis }"
                >{{
                  'databook.dashboard.dataanalysis.description' | translate
                }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="openConfirm !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ getMessage() }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelConfirmation()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="confirm()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>
