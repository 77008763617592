import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DataBookBaseComponent } from '../../databook-base.component';
import { HttpClient } from '@angular/common/http';
import { DataBookState } from '../../../services/databook.state';
import { DataBookService } from '../../../services/databook.service';
import { CoreService } from '../../../core/core.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../../../components/pdf/pdf.service';
import { ConfigHandler } from '../../../core/handlers/config.handler';
import {
  FileOrFolder,
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import {
  FolderContent,
  FolderNameAndId,
  getFolderContentForFrontEnd,
  getNestedTreeView,
} from '../../../classes/treeview-helpers';
import { TreeviewFileItem, TreeviewFolderItem } from '../../../classes';
import { plainToInstance } from 'class-transformer';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MobxAngularModule } from 'mobx-angular';

declare let gtag;

@Component({
  selector: 'treeview-item',
  templateUrl: './treeview-item.html',
  styleUrls: ['./treeview-item.scss'],
  standalone: true,
  imports: [MobxAngularModule, NgIf, NgFor, NgClass, FormsModule],
})
export class TreeviewItem
  extends DataBookBaseComponent
  implements OnDestroy, OnInit
{
  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public httpClient: HttpClient,
    public pdfService: PdfService,
    public config: ConfigHandler,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  handleCtrlClick(): void {
    // Your custom logic for handling Ctrl+Click
    console.log('Ctrl key is down during click!');
  }

  @Input()
  folder?: TreeviewFolderItem;

  get sortedTree(): TreeviewFolderItem[] {
    return this.sortFolders(this.treeviewStore.tree);
  }

  sortFolders(folders?: TreeviewFolderItem[]): TreeviewFolderItem[] {
    if (!folders) {
      folders = this.treeviewStore.tree;
    }
    folders = folders.sort(
      (one: TreeviewFolderItem, other: TreeviewFolderItem) =>
        one.name.localeCompare(other.name),
    );
    folders = folders.map((folder: TreeviewFolderItem) => {
      if (folder.files) {
        folder.files = folder.files.sort(
          (one: TreeviewFileItem, other: TreeviewFileItem) =>
            one.description.localeCompare(other.description),
        );
      }
      if (folder.folders) {
        folder.folders = this.sortFolders(folder.folders);
      }
      return folder;
    });
    return folders;
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.folder === undefined) {
    }
  }

  /*DRAG DROP */
  onDragStart(event: DragEvent, obj: any) {
    this.treeviewStore.objectBeingDragNDroped = [];

    if (this.treeviewStore.selectionFolderOnly) return;

    if (!this.treeviewStore.selectedFiles.length) {
      if (obj?.folder_id) {
        this.treeviewStore.objectBeingDragNDroped.push(
          plainToInstance(TreeviewFolderItem, obj),
        ); // folder
      } else {
        this.treeviewStore.objectBeingDragNDroped.push(
          plainToInstance(TreeviewFileItem, obj),
        ); // file
      }
    } else {
      for (const file of this.treeviewStore.selectedFiles) {
        if (file?.folder_id) {
          this.treeviewStore.objectBeingDragNDroped.push(
            plainToInstance(TreeviewFolderItem, file),
          ); // folder
        } else {
          this.treeviewStore.objectBeingDragNDroped.push(
            plainToInstance(TreeviewFileItem, file),
          ); // file
        }
      }
    }

    this.treeviewStore.userIsDragNDroping = true;
  }

  onDragOver(event: DragEvent, obj: any) {
    if (!this.treeviewStore.selectionFolderOnly) {
      // Prevent default behavior to allow dropping
      if (this.isDoingDragAndDrop()) {
        event.preventDefault();
      }
    }
  }

  onDrop(event: DragEvent, folder: TreeviewFolderItem) {
    if (!this.treeviewStore.selectionFolderOnly) {
      const objBeingDragNDroped = this.treeviewStore.objectBeingDragNDroped;

      for (const { id, folder_id } of Object.values(objBeingDragNDroped)) {
        if (
          this.isDoingDragAndDrop() === false ||
          (!folder_id && id === folder.id)
        ) {
          event.preventDefault();
          return;
        }
      }

      event.preventDefault();
      if (event.ctrlKey) {
        this.treeviewStore.copyItem(
          this.dataBookService,
          objBeingDragNDroped,
          folder,
        );
      } else {
        this.treeviewStore.moveItem(
          this.dataBookService,
          objBeingDragNDroped,
          folder,
        );
      }

      this.treeviewStore.userIsDragNDroping = false;
      this.treeviewStore.objectBeingDragNDroped = [];
    }
  }

  isDoingDragAndDrop() {
    return this.treeviewStore.userIsDragNDroping;
  }

  /*DRAG DROP */

  getFileClassNames(file: TreeviewFileItem) {
    return {
      'treeview-item-selected': this.treeviewStore.selectedObjects.find(
        (item: FileOrFolder) => item?.id === file?.id,
      ),
      'folder-clickable-zone': true,
      draggable: true,
      'drop-target': this.isDoingDragAndDrop(),
      'is-being-copied': file.isBeingCopied,
      'is-being-cutted': file.isBeingCutted,
      'is-focused': file.isFocus,
    };
  }

  getFolderClassNames(folder: TreeviewFolderItem) {
    return {
      'treeview-item-selected': this.treeviewStore.selectedObjects.find(
        (item: FileOrFolder) => item?.id === folder?.id,
      ),
      'folder-clickable-zone': true,
      draggable: true,
      'drop-target': this.isDoingDragAndDrop(),
      'is-being-copied': folder.isBeingCopied,
      'is-being-cutted': folder.isBeingCutted,
      'is-focused': folder.isFocus,
    };
  }

  /*SELECTION*/
  async onFolderClick(event: MouseEvent, folder: TreeviewFolderItem) {
    this.treeviewStore.searchAutocompleteCandidates = [];
    this.treeviewStore.searchText = '';

    try {
      this.coreService.showLoading();
      if (
        this.treeviewStore.renaming ||
        this.treeviewStore.isCreating ||
        this.treeviewStore.isDeleting
      ) {
        if (event && event.preventDefault !== undefined) {
          event.stopPropagation();
          event.preventDefault();
        }
        this.coreService.hideLoading();
        return;
      }
      this.treeviewStore.setIsFocused(folder);

      if (event && event.preventDefault !== undefined) {
        if ((event.ctrlKey || event.metaKey) && folder) {
          const isAlreadyInSelectedObjects =
            this.treeviewStore.selectedObjects.find((item: FileOrFolder) => {
              return item.id === folder.id;
            });
          this.coreService.hideLoading();
          if (isAlreadyInSelectedObjects) {
            this.treeviewStore.selectedObjects =
              this.treeviewStore.selectedObjects.filter(
                (item: FileOrFolder) => {
                  return item.id !== folder.id;
                },
              );
            return;
          } else {
            this.treeviewStore.selectedObjects.push(folder);
            return;
          }
        } else {
          this.treeviewStore.selectedObjects = [];
        }
      }

      if (folder.isOpen) {
        folder.isOpen = false;
        this.coreService.hideLoading();
        return;
      }
      await this.updateViewFilesAndFolders(folder);
      folder.isOpen = true;
    } catch (error) {
      this.coreService.processError(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  ///////////////////

  public async updateViewFilesAndFolderss(
    folder: TreeviewFolderItem,
    content: TreeviewFolderItem,
  ) {
    folder.folders = content.folders.map((item: TreeviewFolderItem) => {
      return plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: false,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: null,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });
    });
    // if (clear) {
    //     folder.folders = [];
    //     folder.files = [];
    // }
    this.recursiveAddFoldersInFolderInTreeFromFolderId(
      this.treeviewStore.tree,
      folder.id,
      folder.folders,
    );
    folder.files = content.files.map((_file: TreeviewFileItem) => {
      const file = plainToInstance(TreeviewFileItem, _file);
      return file;
    });
    this.recursiveAddFilesInFolderInTreeFromFolderId(
      this.treeviewStore.tree,
      folder.id,
      folder.files,
    );
  }

  recursiveAddFilesInFolderInTreeFromFolderId(
    folders: TreeviewFolderItem[],
    folderId: number,
    files: TreeviewFileItem[],
  ) {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        // folders[i].files = [...folders[i].files, ...files];
        folders[i].files = [...files];
        return folders;
      }

      if (folders[i].folders.length > 0) {
        const inserted = this.recursiveAddFilesInFolderInTreeFromFolderId(
          folders[i].folders,
          folderId,
          files,
        );
        if (inserted) {
          return folders;
        }
      }
    }
    return false;
  }

  recursiveAddFoldersInFolderInTreeFromFolderId(
    folders: TreeviewFolderItem[],
    folderId: number,
    foldersToAdd: TreeviewFolderItem[],
  ) {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        // folders[i].folders = [...folders[i].folders, ...foldersToAdd];
        folders[i].folders = [...foldersToAdd];
        return folders;
      }

      if (folders[i].folders.length > 0) {
        const inserted = this.recursiveAddFoldersInFolderInTreeFromFolderId(
          folders[i].folders,
          folderId,
          foldersToAdd,
        );
        if (inserted) {
          return folders;
        }
      }
    }
    return false;
  }

  //////////////////

  private async updateViewFilesAndFolders(folder: TreeviewFolderItem) {
    let prodId = this.dataBookService.dataBookState.product;
    if (!prodId && this.treeviewStore.product) {
      prodId = this.treeviewStore.product.id;
    }
    const nest: TreeviewFolderItem[] = await getNestedTreeView(
      this.dataBookService,
      prodId,
      this.treeviewStore.context,
      folder.id,
    );
    for (const item of nest) {
      this.updateViewFilesAndFolderss(folder, item);
      console.log('ITEEEEEEEEEEEEEEEEEEEEEEEM', item);
    }
    // MATHEUS

    const content: FolderContent = (await getFolderContentForFrontEnd(
      this.dataBookService,
      folder.id,
    )) as FolderContent;
    folder.folders = content.folders.map((item: FolderNameAndId) => {
      return plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: false,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: item.parent_id,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });
    });
    if (!this.treeviewStore.selectionFolderOnly) {
      folder.files = content.files.map((_file: TreeviewFileItem) => {
        // targetFile
        const file = plainToInstance(TreeviewFileItem, _file);
        if (this.store.file && file.id === this.store.file.id) {
          this.treeviewStore.targetFile = file;
        }
        return file;
      });
    }
  }

  onFileClick(event: MouseEvent, file: TreeviewFileItem) {
    console.log('file ===> ', file);
    this.treeviewStore.searchAutocompleteCandidates = [];
    this.treeviewStore.searchText = '';

    console.log('this.treeviewStore ===> ', this.treeviewStore);

    if (
      this.treeviewStore.renaming ||
      this.treeviewStore.isCreating ||
      this.treeviewStore.isDeleting
    ) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    this.treeviewStore.setIsFocused(file);
    if ((event.ctrlKey || event.metaKey) && file) {
      const isAlreadyInSelectedObjects =
        this.treeviewStore.selectedObjects.find((item: FileOrFolder) => {
          return item.id === file.id;
        });
      if (isAlreadyInSelectedObjects) {
        this.treeviewStore.selectedObjects =
          this.treeviewStore.selectedObjects.filter((item: FileOrFolder) => {
            return item.id !== file.id;
          });
        return;
      } else {
        this.treeviewStore.selectedObjects.push(file);
        return;
      }
    } else {
      this.treeviewStore.selectedObjects = [];
      this.treeviewStore.selectedObjects.push(file);
    }
  }

  /*SELECTION*/

  debug(item: any) {
    console.log(item);
  }

  preventClick($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  cancelCreating() {
    this.treeviewStore.tree = this.treeviewStore.tree.filter(
      (folder: TreeviewFolderItem) => {
        return folder.isBeingCreating !== true;
      },
    );

    const recursiveCancelCreating = (folder: TreeviewFolderItem) => {
      folder.folders = folder.folders.filter((folder: TreeviewFolderItem) => {
        return folder.isBeingCreating !== true;
      });
      folder.folders.forEach((folder: TreeviewFolderItem) => {
        recursiveCancelCreating(folder);
      });
    };
    this.treeviewStore.tree.forEach((folder: TreeviewFolderItem) => {
      recursiveCancelCreating(folder);
    });
  }

  cancelEditing(item: any) {
    let file: TreeviewFileItem | undefined = undefined;
    let folder: TreeviewFolderItem | undefined = undefined;
    if (item.folder_id !== undefined) {
      file = plainToInstance(TreeviewFileItem, item);
    } else {
      folder = plainToInstance(TreeviewFolderItem, item);
    }

    if (file) {
      file.cancelEditing();
      this.treeviewStore.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'renaming',
        false,
      );
      this.treeviewStore.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'renamingFieldValue',
        '',
      );
    } else if (folder) {
      folder.cancelEditing();
      this.treeviewStore.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'renaming',
        false,
      );
      this.treeviewStore.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'renamingFieldValue',
        '',
      );
    }

    this.treeviewStore.renaming = false;
  }

  confirmCreating(item: any) {
    let file: TreeviewFileItem | undefined = undefined;
    let folder: TreeviewFolderItem | undefined = undefined;
    if (item.folder_id !== undefined) {
      file = plainToInstance(TreeviewFileItem, item);
    } else {
      folder = plainToInstance(TreeviewFolderItem, item);
    }

    if (folder) {
      folder.confirmCreating(this.dataBookService, this.treeviewStore);
    }

    this.treeviewStore.renaming = false;
  }

  confirmEditing(item: any) {
    let file: TreeviewFileItem | undefined = undefined;
    let folder: TreeviewFolderItem | undefined = undefined;
    if (item.folder_id !== undefined) {
      file = plainToInstance(TreeviewFileItem, item);
    } else {
      folder = plainToInstance(TreeviewFolderItem, item);
    }

    if (file) {
      file.confirmEditing(this.dataBookService);
      this.treeviewStore.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'renaming',
        false,
      );
      this.treeviewStore.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'renamingFieldValue',
        '',
      );
      this.treeviewStore.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'description',
        file.renamingFieldValue,
      );
    } else if (folder) {
      folder.confirmEditing(this.dataBookService);
      this.treeviewStore.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'renaming',
        false,
      );
      this.treeviewStore.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'renamingFieldValue',
        '',
      );
      this.treeviewStore.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'name',
        folder.renamingFieldValue,
      );
    }

    this.treeviewStore.renaming = false;
  }
}
