import { DataBookService } from '../services/databook.service';
import { MergeRequest } from './merge-request.class';

export const getLastMergeRequestsDoneFromProductId = async (
  dataBookService: DataBookService,
  productId: number,
): Promise<MergeRequest[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getLastMergeRequestsDoneFromProductId',
    args: {
      productId,
    },
  };
  return await dataBookService.newLambda<MergeRequest[]>(params);
};
