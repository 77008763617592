<div class="locations-container">
  <ng-container *ngFor="let file of files; index as i">
    <span class="label">
      {{ file }}
    </span>
    <span
      *ngIf="i < files.length - 1"
      class="pipe-separator"
      >▶</span
    >
  </ng-container>
</div>
