import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findProductFromProductAndProjectName = async (
  dataBookService: DataBookService,
  productName: string,
  projectName: string,
): Promise<Product | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'findProductFromProductAndProjectName',
    args: {
      productName,
      projectName,
    },
  };
  return await dataBookService.newLambda<Product | undefined>(params);
};
