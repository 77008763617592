export enum breadcrumbPagesEnum {
  product = 'PRODUCT PAGE',
  dataExplorer = 'DATA EXPLORER',
  newTpiDigital = 'NEW TPI DIGITAL',
  newTpiDigitalUpload = 'NEW TPI DIGITAL TPI FLOW MGMT',
  eDoc = 'E DOC',
  home = 'HOME',
  dataAnalysis = 'DATA ANALYSIS',
  material = 'MATERIAL',
  statistics = 'STATISTICS',
  boxplot = 'BOXPLOT',
}
