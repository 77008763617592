import { DataBookService } from '../../services/databook.service';
import { PermissionDTO } from '../permission.dto';

export const createPermission = async (
  dataBookService: DataBookService,
  name: string,
  featureId: number,
): Promise<PermissionDTO> => {
  const params: { functionName; args: any } = {
    functionName: 'createPermission',
    args: {
      name,
      featureId,
    },
  };
  return await dataBookService.newLambda<PermissionDTO>(params);
};

export const updatePermission = async (
  dataBookService: DataBookService,
  id: number,
  name: string,
  featureId: number,
): Promise<PermissionDTO[]> => {
  const params: { functionName; args: any } = {
    functionName: 'updatePermission',
    args: {
      id,
      name,
      featureId,
    },
  };
  return await dataBookService.newLambda<PermissionDTO[]>(params);
};

export const deletePermission = async (
  dataBookService: DataBookService,
  id: number,
): Promise<void> => {
  const params: { functionName; args: any } = {
    functionName: 'deletePermission',
    args: {
      id,
    },
  };
  return await dataBookService.newLambda<void>(params);
};
