import { fourDigitsNumber, twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'LADLE CHEMICAL ANALYSIS',
  headerClass: 'custom-header-3',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-3',
      children: [
        {
          field: 'AL',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_al']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_al']],
        },

        {
          field: 'AS',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_as']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_as']],
        },

        {
          field: 'B',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_b']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_b']],
        },
        {
          field: 'BI',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_b']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_bi']],
        },

        {
          field: 'C',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_c']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_c']],
        },
        {
          field: 'CA',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_ca']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ca']],
        },

        {
          field: 'CO',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_co']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_co']],
        },

        {
          field: 'CR',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_cr']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_cr']],
        },
        {
          field: 'CU',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_cu']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_cu']],
        },
        {
          field: 'H',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_h']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_h']],
        },
        {
          field: 'MN',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_mn']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_mn']],
        },

        {
          field: 'MO',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_mo']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_mo']],
        },

        {
          field: 'N',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_n']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_n']],
        },
        {
          field: 'NB',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_nb']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_nb']],
        },

        {
          field: 'NI',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_ni']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ni']],
        },

        {
          field: 'O',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_o']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_o']],
        },
        {
          field: 'P',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_p']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_p']],
        },
        {
          field: 'PB',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_pb']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_pb']],
        },

        {
          field: 'S',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_s']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_s']],
        },
        {
          field: 'SB',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_sb']],
        },

        {
          field: 'SE',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_se']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_se']],
        },
        {
          field: 'SI',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_si']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_si']],
        },

        {
          field: 'SN',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_sn']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_sn']],
        },

        {
          field: 'SR',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_sr']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_sr']],
        },
        {
          field: 'TI',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_ti']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ti']],
        },

        {
          field: 'V',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_v']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_v']],
        },
        {
          field: 'W',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerFourDigits',
          digits: 4,
          symbol: '',
          valueFormatter: (params) =>
            fourDigitsNumber('', params.data[COLUMNS['ladle_chemical_w']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_w']],
        },
        {
          field: 'CEQ',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_ceq']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ceq']],
        },

        {
          field: 'CE1',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_ce1']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ce1']],
        },
        {
          field: 'CE2',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_ce2']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ce2']],
        },
        {
          field: 'CE3',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_ce3']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ce3']],
        },
        {
          field: 'CE4',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_ce4']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ce4']],
        },
        {
          field: 'CE5',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_ce5']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_ce5']],
        },
        {
          field: 'PCM',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['ladle_chemical_pcm']]),
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_pcm']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-3',
      columnGroupShow: 'open',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-3',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['ladle_chemical_file_name']],
        },
      ],
    },
  ],
};
