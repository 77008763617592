import { Component, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { DataAnalysisBaseComponent } from '../data-analysis-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolComponent } from '../../../components/tool/tool.component';
import { InfoComponent } from '../../../components/info/info.component';
import { FilterColumnComponent } from '../../../components/filter-column/filter-column.component';
import { FilterComponent } from '../../../components/filter/filter.component';
import { NgFor, NgIf, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  ClrCommonFormsModule,
  ClrIconModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
} from '@clr/angular';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../../core/components/page/base-page.component';
declare let Plotly: any;
declare let gtag;
@Component({
  selector: 'data-flow-project',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    FormsModule,
    NgFor,
    NgIf,
    FilterComponent,
    FilterColumnComponent,
    InfoComponent,
    ClrIconModule,
    ToolComponent,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    DecimalPipe,
    TranslateModule,
  ],
})
export class StatisticsDataAnalysisComponent extends DataAnalysisBaseComponent {
  selectedColumn;
  selectedTest;

  selectedColumnDescription;
  maximum;
  minimum;

  data;
  items;

  deviation;
  mean;
  cpk;
  sampleSize;

  async testeChange(keyTest: { value: string }) {
    this.coreService.showLoading();

    this.selectedColumn = null;
    const _keyTest: string = keyTest.value.split(':')[1].trim();
    let idTestValue: number;
    this.tests.forEach((testItem: any) => {
      if (_keyTest === testItem.description) {
        idTestValue = testItem.id;
        this.properties = [];
        this.filters = [];
        testItem.tests.forEach((item: any) => {
          if (item.continuous && item.name !== 'File Name') {
            this.properties.push(item);
          }
          if (item.filter) {
            this.filters.push({ title: item, columns: [] });
          }
        });
      }
    });

    if (this.dataBookState.oldDataAnalysis) {
      const params = {
        project: this.store.selectedProject.gsi_id,
        materialId: this.store.materialId,
        test: this.selectedTest.includes('.')
          ? this.selectedTest.split('.')[2].toLowerCase()
          : this.selectedTest,
        productId: this.store.selectedProduct.id,
      };
      this.coreService.showLoading();
      this.cfgVlr(params);
      this.items = await this.dataBookService.getTestsResultPLP(params);
    } else {
      const params = { resultTests: idTestValue };
      this.cfgVlr(params);
      this.items = await this.dataBookService.dataAnalysis(params);
    }

    this.coreService.hideLoading();
  }

  public cfgVlr(params) {
    const vlr = this.dataBookService.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  }

  async propertyChange(propTest) {
    this.errorTest = null;
    propTest = propTest.value.split(':')[1].trim();
    if (this.selectedColumn) {
      this.coreService.showLoading();
      this.properties.forEach(
        (propertiesItem: { name: string; description: string }) => {
          if (propTest === propertiesItem.name) {
            this.selectedColumnDescription = propertiesItem.description;
          }
        },
      );
      this.items.items.forEach((_item: any) => {
        this.filters.forEach((filter: any) => {
          const value = _item[filter.title.name];
          if (!filter.columns.find((v) => v.description === value)) {
            filter.columns.push({
              description: value,
              name: value,
              checked: true,
            });
          }
        });
      });
      this.getData();
    }
    this.coreService.hideLoading();
    this.settings.legend.labelY = this.coreService.translate(
      'databook.frequencia',
      null,
    );
    this.settings.title.label = this.coreService.translate(
      'databook.histogram',
      null,
    );
  }

  getData() {
    this.errorTest = null;
    this.data = [];
    const valuesX = this.filterItems(this.items.items);

    for (const item1 of valuesX) {
      const item = item1[this.selectedColumn];
      if (item) {
        this.data.push(item);
      }
    }

    if (this.data.length > 0) {
      this.coreService.showLoading();
      this.generateGraphics();
    } else {
      this.showError('Teste não possui valores válidos');
    }
  }

  filterItems(items) {
    this.filters.forEach((filter) => {
      const propertie = filter.title.name;
      const filterOut = filter.columns.filter(
        (column) => column.checked === false,
      );
      filterOut.forEach((value) => {
        items = items.filter((item) => item[propertie] !== value.description);
      });
    });
    return items;
  }

  async sendGtag(eventAction) {
    const param = {
      // feature:  'Data Analysis',
      // category: 'action',
      // event: eventAction,
      // pageName: '/main/data-analysis/statistics',
      // userType: this.coreService.getConfig().currentUser.type,
      // project: this.store.selectedProject.gsi_id
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature: 'Data Analysis',
      spn_action: eventAction,
      spn_userType: this.coreService.getConfig().currentUser.type,
    };
    gtag('event', eventAction, param);
  }

  async generateGraphicsGtag() {
    this.sendGtag('search');
    this.getData();
  }
  async generateGraphics() {
    try {
      this.coreService.showLoading();

      const data: any = await this.createBasicTrace();
      const layout = this.getLayout();
      const settings = this.getSettings();
      const divChart = document.getElementById('chart') as any;

      if (!divChart) {
        setTimeout(() => this.generateGraphics(), 500);
        return;
      } else {
        this.sendGtag('view');
      }

      Plotly.newPlot(divChart, data, layout, settings);

      const normal = this.drawNormalDistribution(divChart, data);

      const max = this.getMaximum(normal.maxValue);
      const min = this.getMinimum(normal.minValue);

      this.drawMinimumLine(data, min, normal.maxPtsValue);
      this.drawMaximumLine(data, max, normal.maxPtsValue);

      Plotly.newPlot(divChart, data, layout, settings);

      divChart.on('plotly_afterplot', this.closeLoading);

      this.mean = this.calculateArithmeticMedia(this.data);
      this.deviation = this.calculateDeviation(this.data);
      this.sampleSize = this.data.length;
      this.calculateCpk(min, max);
    } catch (error) {
      this.coreService.processError(error);
    }
  }

  getLayout() {
    const colorway = this.paletteColors
      .find((g) => g.group === this.settings.colors[0])
      .items.find((p) => p.name === this.settings.colors[1]).colors;

    let formatTickDigital = '';
    if (
      this.selectedColumn.toLowerCase() === 'pipe_heat' ||
      this.selectedColumn.toLowerCase() === 'manufacturer_pipe' ||
      this.selectedColumn.toLowerCase() === 'customer_po' ||
      this.selectedColumn.toLowerCase() === 'mill_order'
    ) {
      formatTickDigital = 'd';
    }
    return {
      title: this.settings.title.label,
      font: {
        family: this.settings.fontFamily,
        size: this.settings.title.fontSize,
        color: this.settings.title.color,
      },
      autosize: true,
      margin: { l: 100, t: 50, b: 100, r: 50 },
      bargap: 0.005,
      barheatgap: 0.01,
      barmode: 'heat',
      xaxis: {
        tickformat: formatTickDigital,
        showticklabels: true,
        title: this.settings.legend.labelX,
        titlefont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
        tickangle: this.settings.tick.rotateX,
        tickfont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
      },
      yaxis: {
        anchor: 'x1',
        title: this.settings.legend.labelY,
        showticklabels: true,
        titlefont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
        tickangle: this.settings.tick.rotateY,
        tickfont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
      },
      yaxis2: {
        overlaying: 'y',
        anchor: 'x1',
        showgrid: false,
        zeroline: false,
        showline: false,
        showticklabels: false,
      },
      yaxis3: {
        overlaying: 'y',
        anchor: 'x1',
        showgrid: false,
        zeroline: false,
        showline: false,
        showticklabels: false,
      },
      colorway,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
    };
  }

  getMinimum(minValue) {
    let min = minValue;
    if (this.minimum) {
      min = this.minimum;
    }
    return min;
  }

  getMaximum(maxValue) {
    let max = maxValue;
    if (this.maximum) {
      max = this.maximum;
    }
    return max;
  }

  calculateArithmeticMedia(values: number[]) {
    let sum = 0;
    for (const itemData of values) {
      if (Number.isNaN(Number(itemData))) {
        const msg = this.coreService.translate(
          'databook.message.cp.cpk.deviation.error',
        );
        this.coreService.showAlertInfo(msg);
        this.deviation = null;
        this.cpk = null;

        return null;
      }
      sum = sum + Number(itemData);
    }
    return sum / values.length;
  }

  /**
   * R = max - min;
   */
  calculateAmpleness(values: number[]) {
    const extremes = this.getMinMax(values);
    return extremes.max - extremes.min;
  }

  /**
   * DP = RAIZ QUADRADA DE :
   *  a) Somatório de todos os valores Multiplicado por ( valor na posição 'X' - média aritmética dos dados) ao quadrado
   *  b) Resultado de a dividido pela quantidade de itens.
   *
   *  https://www.todamateria.com.br/desvio-padrao/
   */
  calculateDeviation(values: number[]) {
    let deviation = 0.0;
    const mean = this.calculateArithmeticMedia(values);
    if (mean) {
      let sum = 0;
      for (const itemData of values) {
        sum = sum + (Number(itemData) - mean) ** 2;
      }
      deviation = Math.sqrt(sum / values.length);
    }

    return deviation;
  }

  calculateMedian() {
    const half = Math.floor(this.data.length / 2);
    const sortedValues = _.sortBy(this.data);

    if (sortedValues.length % 2 === 0) {
      return (Number(sortedValues[half - 1]) + Number(sortedValues[half])) / 2;
    } else {
      return Number(sortedValues[half]);
    }
  }

  /**
   *             ( LSE - X     X - LIE )
   *  CPK =  MIN (--------- , ---------)
   *             (   3*º         3*º   )
   *
   *  Onde:
   *  LSE = Limite Superior de Especificação - this.maximum
   *  LIE = Limite Inferior de Especificação - this.minimum
   *  º = Desvio-padrão calculado a partir da amostragem de medições
   *  X = Mediana da característica medida
   *
   *  https://www.citisystems.com.br/cpk-indice-capacidade-performance-processo/
   */
  calculateCpk(lsl, usl) {
    const median = this.calculateMedian();
    const cpU = (usl - median) / (3 * this.deviation);
    const cpL = (median - lsl) / (3 * this.deviation);
    if (this.minimum && this.maximum) {
      this.cpk = cpU < cpL ? cpU : cpL;
    } else if (!this.minimum) {
      this.cpk = cpL;
    } else {
      this.cpk = cpL;
    }
  }

  /**
   *           e ** ( -1/2 (x - μ / º) ** 2)
   *  f(x) = -------------------------------
   *           raiz(2 * pi * ( º ** 2) )
   *
   *  Onde:
   *  e = constante euleriana
   *  pi = constante de pi (3,14)
   *  º = Desvio-padrão calculado a partir da amostragem de medições
   *  μ = Mediana da característica medida
   *
   *  https://www.inf.ufsc.br/~andre.zibetti/probabilidade/normal.html
   */
  calculateNormalDistribution(value, mean, deviation) {
    const dividend = Math.E ** -((value - mean) ** 2 / (2 * deviation ** 2));
    const divisor = deviation * Math.sqrt(2 * Math.PI);
    return dividend / divisor;
  }

  createBasicTrace() {
    return [
      {
        x: this.data,
        // name: ''+xName,
        histnorm: 'count',
        marker: {
          line: { width: 1 },
        },
        type: 'histogram',
        name: '',
        showlegend: false,
        xaxis: 'x1',
        yaxis: 'y1',
      },
    ];
  }

  drawMaximumLine(traces, maxCalculated, maxPts) {
    let max = maxCalculated;
    if (this.maximum) {
      max = this.maximum;
    }

    traces.push({
      mode: 'lines',
      line: { shape: 'spline' },
      name: 'Max',
      type: 'scatter',
      x: [max, max],
      y: [0, maxPts],
      marker: { color: '#D62728' },
      hovertext: ['Max', 'Max'],
      hoverinfo: 'text',
      showlegend: false,
      xaxis: 'x1',
      yaxis: 'y1',
    });
  }

  drawMinimumLine(traces, minCalculated, maxPts) {
    let min = minCalculated;
    if (this.minimum) {
      min = this.minimum;
    }

    traces.push({
      mode: 'lines',
      line: { shape: 'spline' },
      name: 'Min',
      type: 'scatter',
      hovertext: ['Min', 'Min'],
      hoverinfo: 'text',
      x: [min, min],
      y: [0, maxPts],
      marker: { color: '#D62728' },
      showlegend: false,
      xaxis: 'x1',
      yaxis: 'y1',
    });
  }

  /* Gera array de tamanho 500 entre min(x) e max(x) e aplica função de distribuição normal para y   */
  createNormalDistribution(min, max) {
    const interval = (max - min) / 100;
    const xaxis = [];
    const yaxis = [];
    this.mean = this.calculateArithmeticMedia(this.data);
    this.deviation = this.calculateDeviation(this.data);

    for (let i = min; i < max; i += interval) {
      const y = this.calculateNormalDistribution(i, this.mean, this.deviation);
      xaxis.push(i);
      yaxis.push(y);
    }
    return { xaxis, yaxis };
  }

  getMinMax(values: number[]) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    for (const itemData of values) {
      if (Number(itemData) < min) {
        min = Number(itemData);
      }
      if (Number(itemData) >= max) {
        max = Number(itemData);
      }
    }
    return { min, max };
  }

  drawNormalDistribution(divChart, traces) {
    const limits = this.getMinMax(this.data);

    const margin = limits.max - limits.min;

    const axis = this.createNormalDistribution(
      limits.min - margin,
      limits.max + margin,
    );

    traces.push({
      mode: 'lines',
      line: {
        shape: 'spline',
        dash: 'dot',
      },
      name: '',
      type: 'scatter',
      x: axis.xaxis,
      y: axis.yaxis,
      marker: { color: '#3A4750' },
      showlegend: false,
      xaxis: 'x1',
      yaxis: 'y2',
    });

    let maxPts = 0;
    divChart.calcdata[0].forEach((_item) => {
      maxPts = _item.pts.length > maxPts ? _item.pts.length : maxPts;
    });

    return {
      maxPtsValue: maxPts,
      minValue: limits.min,
      maxValue: limits.max,
    };
  }

  async onChangeTools() {
    this.generateGraphics();
  }
  /* Se os limites minimo e máximo foram setados */
  get hasLimitValues() {
    return this.maximum || this.minimum;
  }

  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.data.analysis.statistics',
      null,
    );

    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },

      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },

      {
        name: this.coreService.translate('databook.data.analysis', null),
        link: '/',
        type: this.coreService.translate('databook.dashboard', null),
      },
      {
        name: this.store.material ? this.store.material.description : '',
        type: this.coreService.translate('databook.material.project', null),
        link: '/main/material',
      },

      {
        name: this.coreService.translate('databook.data.analysis', null),
        link: '/main/data-analysis',
      },
    ];
  }

  getCondition() {
    return !this.store.selectedProject.gsi_id || !this.dataBookState.product;
  }
}
