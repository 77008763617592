<vic-base-page page="material-mgmt">
  <div class="header-actions container">
    <a
      class="btn-link"
      id="downloadFiles"
      [hidden]="true"
    ></a>
  </div>

  <div class="page-body-container">
    <div *ngIf="!canUpload()">{{ unauthorized() }}</div>
    <databook-breadcrumb> </databook-breadcrumb>

    <div class="val-card clr-col-12">
      <clr-tabs>
        <clr-tab cuplass="tab">
          <button
            clrTabLink
            class="tab-button title-btn"
          >
            <clr-icon
              shape="file"
              size="30"
            ></clr-icon>
            {{ 'data.explorer.upload.test' | translate }}
          </button>

          <clr-tab-content *clrIfActive>
            <br /><br />
            <div class="clr-row">
              <div class="clr-col-lg-6">
                <div class="dropzone-container">
                  <ngx-dropzone
                    style="width: 100%; height: 70px; background-color: #eafaff"
                    (change)="onFilesAdded($event.addedFiles)"
                    #dropzone
                    [multiple]="true"
                  >
                    <ngx-dropzone-label
                      >Drag and drop xlsx file, up to 30 MB</ngx-dropzone-label
                    >
                  </ngx-dropzone>
                </div>
              </div>
              <div
                class="clr-col-lg-6"
                style="text-align: right"
              >
                <button
                  id="uploadButtonId"
                  class="btn blue-color"
                  (click)="executeUpload()"
                >
                  UPLOAD
                </button>
              </div>
            </div>
            <div class="clr-row">
              <clr-datagrid style="width: 100%">
                <clr-dg-column [style.width.px]="800">
                  {{ 'databook.file.name' | translate }}</clr-dg-column
                >
                <clr-dg-column [style.width.px]="300">{{
                  'databook.document.code' | translate
                }}</clr-dg-column>
                <clr-dg-column [style.width.px]="100">Size</clr-dg-column>
                <clr-dg-column [style.width.px]="100"></clr-dg-column>

                <clr-dg-row *clrDgItems="let file of files">
                  <clr-dg-cell>{{ file.name }}</clr-dg-cell>
                  <clr-dg-cell>
                    <clr-select-container style="margin-top: 0">
                      <select
                        class="clr-col-12 clr-col-md-8"
                        clrSelect
                        [(ngModel)]="file.testType"
                      >
                        <option
                          *ngFor="let type of testTypes"
                          [ngValue]="type"
                        >
                          <label class="label-small">{{
                            type.description
                          }}</label>
                        </option>
                      </select>
                    </clr-select-container>
                  </clr-dg-cell>
                  <clr-dg-cell> {{ file.size / 1000000 }} (MB) </clr-dg-cell>
                  <clr-dg-cell>
                    <img
                      class="file-tree-icon"
                      (click)="delete(file.name)"
                      style="cursor: pointer"
                      src="../../../assets/img/svg/trash-icon.svg"
                      title="Delete"
                      alt="Trash Icon"
                    />
                  </clr-dg-cell>
                </clr-dg-row>
              </clr-datagrid>
            </div>
          </clr-tab-content>
        </clr-tab>

        <clr-tab class="tab">
          <button
            clrTabLink
            class="tab-button title-btn"
            (click)="loadFiles()"
          >
            <clr-icon
              shape="file-group"
              size="30"
            ></clr-icon>
            {{ 'uploaded.files' | translate }}
          </button>
          <clr-tab-content *clrIfActive>
            <div
              class="clr-row"
              style="display: flex; justify-content: right"
            >
              <button
                class="btn blue-color"
                (click)="regenerate(false)"
              >
                REGENERATE
              </button>

              <button
                class="btn blue-color"
                *ngIf="false"
                (click)="regenerate(true)"
              >
                FULL REGENERATE
              </button>
              <button
                class="btn blue-color"
                *ngIf="isDev()"
                (click)="regenerateDataAnalysis()"
              >
                REGENERATE DATA ANALYSIS
              </button>
            </div>

            <clr-datagrid
              style="width: 100%"
              [(clrDgSelected)]="selectedFiles"
              (clrDgSelectedChange)="handleSelectedDocument($event)"
              [clrDgPreserveSelection]
            >
              <clr-dg-column [style.width.px]="800">
                {{ 'databook.file.name' | translate }}</clr-dg-column
              >
              <clr-dg-column [style.width.px]="400">{{
                'databook.user' | translate
              }}</clr-dg-column>
              <clr-dg-column [style.width.px]="300">{{
                'databook.new.tpi.digital.created.on' | translate
              }}</clr-dg-column>
              <!--              <clr-dg-column [style.width.px]="100"></clr-dg-column>-->

              <clr-dg-row
                *clrDgItems="let file of uploadedFiles"
                [clrDgItem]="file"
                [clrDgSelectable]="true"
              >
                <clr-dg-cell
                  ><a (click)="download(file)">
                    {{ file.file_name }}
                  </a></clr-dg-cell
                >
                <clr-dg-cell
                  ><a (click)="download(file)">{{ file.user }}</a></clr-dg-cell
                >
                <clr-dg-cell
                  ><a (click)="download(file)">{{
                    file.created_on
                  }}</a></clr-dg-cell
                >
                <!--                <clr-dg-cell>-->
                <!--                  <img-->
                <!--                          class="file-tree-icon"-->
                <!--                          (click)="executeConfirmDelete(file)"-->
                <!--                          style="cursor: pointer"-->
                <!--                          src="../../../assets/img/svg/trash-icon.svg"-->
                <!--                          title="Delete"-->
                <!--                          alt="Trash Icon"-->
                <!--                  />-->
                <!--                </clr-dg-cell>-->
              </clr-dg-row>
            </clr-datagrid>
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="openConfirmRegenerate !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ messageRegenerate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelRegenerate()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      *ngIf="!fullRegenerate"
      (click)="executeRegenerate()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      *ngIf="fullRegenerate"
      (click)="executeFullRegenerate()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="confirmDelete !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ 'confirm.delete.upload.file' | translate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelDelete()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="inactivate()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>
