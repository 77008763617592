<div class="tool-card">
  <div class="card-block">
    <clr-accordion
      class="accordion"
      [clrAccordionMultiPanel]="true"
    >
      <!-- LUKETE VERIFICAR -->
      <!-- <clr-accordion-panel [clrAccordionPanelOpen]="true"> -->
      <clr-accordion-panel>
        <clr-accordion-title class="accordion-title">
          <clr-icon shape="tag"></clr-icon
          >{{ 'plp.data.analysis.tools.labels' | translate }}
        </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <section>
            <label class="label-small section">
              {{ 'plp.data.analysis.tools.title' | translate }}:
            </label>
            <div class="separator"></div>
            <div class="item">
              <input
                class="input"
                type="text"
                placeholder="title"
                (change)="onChange()"
                [(ngModel)]="settingsChart.title.label"
              />
            </div>

            <div class="item">
              <label class="label-small">
                {{ 'plp.data.analysis.tools.size' | translate }}:
              </label>
              <div class="range">
                <input
                  class="slider"
                  type="range"
                  (change)="onChange()"
                  [(ngModel)]="settingsChart.title.fontSize"
                  min="10"
                  max="16"
                />
                <p>{{ settingsChart.title.fontSize }} px</p>
              </div>
            </div>

            <br />
            <label class="label-small section">
              {{ 'plp.data.analysis.tools.legend' | translate }}:
            </label>
            <div class="separator"></div>

            <div class="item">
              <div class="btn-group btn-primary btn-icon">
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[0] === 'left' }"
                  (click)="setPosition('left')"
                >
                  <clr-icon shape="arrow left"></clr-icon>
                  <span class="clr-icon-title">Left</span>
                </button>
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[0] === 'middle' }"
                  (click)="setPosition('middle')"
                >
                  <clr-icon shape="align-middle"></clr-icon>
                  <span class="clr-icon-title">Middle</span>
                </button>
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[0] === 'right' }"
                  (click)="setPosition('right')"
                >
                  <clr-icon shape="arrow right"></clr-icon>
                  <span class="clr-icon-title">Right</span>
                </button>
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[1] === 'up' }"
                  (click)="setPosition('up')"
                >
                  <clr-icon shape="arrow up"></clr-icon>
                  <span class="clr-icon-title">Up</span>
                </button>
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[1] === 'center' }"
                  (click)="setPosition('center')"
                >
                  <clr-icon shape="align-center"></clr-icon>
                  <span class="clr-icon-title">Center</span>
                </button>
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[1] === 'down' }"
                  (click)="setPosition('down')"
                >
                  <clr-icon shape="arrow down"></clr-icon>
                  <span class="clr-icon-title">Down</span>
                </button>
                <button
                  class="btn button-primary"
                  [ngClass]="{ 'btn-active': positionSelected[0] === 'reset' }"
                  (click)="setPosition('reset')"
                >
                  <clr-icon shape="times"></clr-icon>
                  <span class="clr-icon-title">Reset</span>
                </button>
              </div>
            </div>

            <div class="item">
              <input
                class="input"
                type="text"
                placeholder="X"
                (change)="onChange()"
                [(ngModel)]="settingsChart.legend.labelX"
              />
            </div>

            <div class="item">
              <input
                class="input"
                type="text"
                placeholder="Y"
                (change)="onChange()"
                [(ngModel)]="settingsChart.legend.labelY"
              />
            </div>

            <div class="item">
              <label class="label-small">
                {{ 'plp.data.analysis.tools.size' | translate }}:
              </label>
              <div class="range">
                <input
                  class="slider"
                  type="range"
                  (change)="onChange()"
                  [(ngModel)]="settingsChart.legend.fontSize"
                  min="10"
                  max="16"
                />
                <p>{{ settingsChart.legend.fontSize }} px</p>
              </div>
            </div>

            <div class="item">
              <label class="label-small">
                {{ 'plp.data.analysis.tools.rotate' | translate }} X:
              </label>
              <div class="range">
                <input
                  class="slider"
                  type="range"
                  (change)="onChange()"
                  [(ngModel)]="settingsChart.tick.rotateX"
                  min="0"
                  max="90"
                />
                <p>{{ settingsChart.tick.rotateX }} °</p>
              </div>
            </div>
            <div class="item">
              <label class="label-small">
                {{ 'plp.data.analysis.tools.rotate' | translate }} Y:
              </label>
              <div class="range">
                <input
                  class="slider"
                  type="range"
                  (change)="onChange()"
                  [(ngModel)]="settingsChart.tick.rotateY"
                  min="0"
                  max="90"
                />
                <p>{{ settingsChart.tick.rotateY }} °</p>
              </div>
            </div>
          </section>
        </clr-accordion-content>
      </clr-accordion-panel>
      <clr-accordion-panel [clrAccordionPanelOpen]="true">
        <clr-accordion-title class="accordion-title">
          <clr-icon shape="color-picker"></clr-icon>
          {{ 'plp.data.analysis.tools.colors' | translate }}
        </clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
          <section>
            <div
              class="item"
              *ngIf="paletteColors"
            >
              <clr-dropdown>
                <button
                  class="btn-max btn button-secondary"
                  clrDropdownTrigger
                >
                  {{ 'plp.data.analysis.tools.palette' | translate }}
                  <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu
                  clrPosition="top-left"
                  *clrIfOpen
                >
                  <div *ngFor="let group of paletteColors">
                    <label class="label-small">
                      {{ group.group }}
                    </label>
                    <div class="separator"></div>

                    <div *ngFor="let palette of group.items">
                      <button
                        class="btn btn-link"
                        (click)="onChangeColor(group.group, palette.name)"
                      >
                        <div
                          class="gradient-color max-width"
                          [ngStyle]="setGradient(palette.colors)"
                        >
                          {{ palette.name }}
                        </div>
                      </button>
                    </div>
                  </div>
                </clr-dropdown-menu>
              </clr-dropdown>
            </div>
          </section>
        </clr-accordion-content>
      </clr-accordion-panel>
    </clr-accordion>
  </div>
</div>
