export class UserPermissionsFlat {
  id: number;
  userId: number;
  email: string;
  featureName: string;
  permissionName: string;
  projectName: string;
  productName: string;
  projectId: string;
  productId: string;
}
