import { Project } from '../project.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findProjectFromOldId = async (
  dataBookService: DataBookService,
  oldId: string,
): Promise<Project | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'findProjectFromOldId',
    args: {
      oldId,
    },
  };
  return await dataBookService.newLambda<Project | undefined>(params);
};
