import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from 'src/app/components/pdf/pdf.service';
import { CoreService } from 'src/app/core/core.service';
import { DataBookService } from 'src/app/services/databook.service';
import { DataBookState } from 'src/app/services/databook.state';
import { DataBookBaseComponent } from '../../databook-base.component';
import { v1 as uuid } from 'uuid';
import * as _ from 'lodash';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgIf, NgFor } from '@angular/common';
import {
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrIconModule,
  ClrModalModule,
  ClrInputModule,
  ClrCommonFormsModule,
  ClrSelectModule,
} from '@clr/angular';
declare let LacunaWebPKI: any;

@Component({
  selector: 'my-signatures',
  templateUrl: './my-signatures.component.html',
  styleUrls: ['./my-signatures.component.scss'],
  standalone: true,
  imports: [
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgIf,
    ClrIconModule,
    ClrModalModule,
    FormsModule,
    ReactiveFormsModule,
    ClrInputModule,
    ClrCommonFormsModule,
    ClrSelectModule,
    NgFor,
    NgxDropzoneModule,
    TranslateModule,
  ],
})
export class MySignaturesComponent extends DataBookBaseComponent {
  @ViewChild('dropzoneImage')
  dropzoneImage: any;

  @ViewChild('dropzoneCertificate')
  dropzoneCertificate: any;

  // tslint:disable-next-line:no-input-rename
  @Input() controlLoading;

  oldImage = null;
  oldCert = null;
  actionConfirmRemove = 'none';
  removeSignature;
  pki = new LacunaWebPKI();
  canSign = false;
  openModalPki = 'none';
  openSignatureModal = 'none';
  certificateList = [];
  addedFilesImages = [];
  addedFilesCertificates = [];
  mysSignatureForm: FormGroup;
  signatures = [];
  acceptImage = [
    'image/jpeg', // jpe
    'image/jpeg', // jpeg
    'image/jpeg', // jpg
    'image/png', // png
  ];
  signatureEdition = null;
  acceptCertificateExtension = ['pfx', 'crt', 'p12', 'pem'];
  FILE_SIZE = 5242880;
  TYPES = ['LACUNA', 'DOCUSIGN', 'CUSTOM'];

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    public formBuilder: FormBuilder,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
    this.pki.init({
      // As soon as the component is ready we'll load the certificates.
      ready: () => {
        this.canSign = true;
      },

      defaultFail: (data) => {
        this.canSign = false;
        console.log(data);
      },
      notInstalled: (data) => {
        this.canSign = false;
      },
    });
    this.listCertificates();
  }

  async onPageInit() {
    this.mysSignatureForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      id: new FormControl(null),
      certificate: new FormControl(null),
    });

    try {
      if (this.controlLoading) {
        this.coreService.showLoading();
      }

      const completeName =
        this.currentUser.firstName + ' ' + this.currentUser.lastName;
      const result = await this.dataBookService.signature({
        userId: this.userStore.user.id,
        list: true,
        email: this.currentUser.email,
        userName: completeName,
      });
      this.signatures = result;

      if (this.controlLoading) {
        this.coreService.hideLoading();
      }
    } catch (error) {
      this.coreService.processError(error);
    }
  }
  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate('My Signatures', null);
    this.breadcrumb = [
      {
        name: this.coreService.translate('Parameters', null),
        link: '/main/project',
      },
    ];
  }

  async getUrlFile(id, nameVal, image) {
    const path = this.createPath(image, id);
    const params = {
      path: true,
      getObject: path,
      name: nameVal,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const result = await this.dataBookService.documentPreSignedUrl(params);
    return result;
  }

  async downloadPicCert(id, nameVal, image) {
    try {
      this.coreService.showLoading();

      const result = await this.getUrlFile(id, nameVal, image);

      const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
      a.download = nameVal;
      a.href = result.url;
      a.click();
    } catch (error) {
      await this.coreService.processError(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  cancelRemove() {
    this.actionConfirmRemove = 'none';
    this.removeSignature = null;
  }
  openRemoveSignature(sig) {
    this.actionConfirmRemove = 'open';
    this.removeSignature = sig;
  }
  async remove() {
    try {
      this.coreService.showLoading();
      const completeName =
        this.currentUser.firstName + ' ' + this.currentUser.lastName;
      const result = await this.dataBookService.signature({
        remove: this.removeSignature.id,
        userId: this.userStore.user.id,
        email: this.userStore.user.email,
        userName: completeName,
      });
      this.signatures = result;

      await this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
      );
      this.actionConfirmRemove = 'none';
      this.coreService.hideLoading();
    } catch (error) {
      await this.coreService.processError(error);
    }
  }

  // Done
  async listCertificates() {
    this.certificateList = await this.certificates();
  }

  certificates(): any {
    return new Promise((resolve, reject) => {
      this.pki
        .listCertificates()
        .success((obj) => {
          resolve(obj);
        })
        .fail((obj) => {
          reject(obj);
        });
    });
  }
  closePki() {
    this.openModalPki = 'none';
  }

  resetModal() {
    this.mysSignatureForm.reset();
    this.addedFilesCertificates = [];
    this.addedFilesImages = [];
    this.oldCert = null;
    this.oldImage = null;
    this.signatureEdition = null;
  }

  editSignature(signature) {
    this.resetModal();

    if (signature) {
      this.type.setValue(signature.type);
      this.name.setValue(signature.description);
      this.id.setValue(signature.id);
      this.certificate.setValue(signature.signatureProviderId);

      this.oldImage = {
        path: this.createPath(true, signature.pictureId),
        id: signature.pictureId,
        name: signature.pictureName,
      };

      this.oldCert = {
        path: this.createPath(false, signature.certificateId),
        id: signature.certificateId,
        name: signature.certificateName,
      };

      this.signatureEdition = signature;
    }

    this.openSignatureModal = 'true';
  }

  changeType() {
    const type = this.mysSignatureForm.get('type').value;

    this.certificate.setValue(null);
    const certName = 'certificate';
    this.mysSignatureForm.controls[certName].setErrors(null);
    if (type === this.TYPES[0]) {
      this.mysSignatureForm.controls[certName].setValidators([
        Validators.required,
      ]);
    } else {
      this.mysSignatureForm.controls[certName].clearValidators();
    }
    this.mysSignatureForm.controls[certName].updateValueAndValidity();

    if (type === this.TYPES[0] && !this.canSign) {
      this.openModalPki = 'open';
    } else {
      this.openModalPki = 'none';
    }
  }
  isLacunaSignature() {
    return this.mysSignatureForm.get('type').value === this.TYPES[0];
  }
  redirectToInstall() {
    window.open('https://get.webpkiplugin.com/Setup', '_blank');
  }
  cancel() {
    this.mysSignatureForm.reset();
    this.openSignatureModal = 'none';
  }

  validateToSave(signature) {
    if (!this.mysSignatureForm.valid) {
      this.coreService.showAlertErrorTranslate('required.invalid.fields');
      return false;
    }
    const found = _.filter(this.signatures, (item) => {
      return (
        item.description === signature.name &&
        (item.id !== signature.id || !signature.id)
      );
    });
    if (found.length) {
      this.coreService.showAlertErrorTranslate('tpi.signature.repeated');
      return false;
    }
    return true;
  }

  async save() {
    try {
      const signature = this.mysSignatureForm.value;

      if (this.validateToSave(signature)) {
        this.coreService.showLoading();
        await this.uploadFiles();

        const result = await this.dataBookService.signature({
          userId: this.userStore.user.id,
          save: signature,
          thirdCert: signature.certificate,
          image: this.getFileToUpload(true),
          certificate: this.getFileToUpload(false),
          oldImage: this.oldImage,
          oldCert: this.oldCert,
          email: this.currentUser.email,
          userName:
            this.userStore.user.firstName + ' ' + this.userStore.user.lastName,
          productId: this.store.selectedProduct.id,
        });
        this.signatures = result;

        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );
        this.openSignatureModal = 'none';
        this.coreService.hideLoading();
      }
    } catch (error) {
      await this.coreService.processError(error);
    }
  }

  async uploadFiles() {
    const imageToUpload = this.getFileToUpload(true);
    const preSignedUrlImage = await this.createPreSignedUrls(
      imageToUpload,
      true,
    );
    if (
      preSignedUrlImage &&
      preSignedUrlImage.preSignedUrls &&
      preSignedUrlImage.preSignedUrls.length
    ) {
      await this.callHttpPut(
        preSignedUrlImage.preSignedUrls[0].preSignedMultPart[0],
        this.addedFilesImages[0].content,
        1,
        this.addedFilesImages[0].name,
        null,
        null,
      );
    }

    const certToUpload = this.getFileToUpload(false);
    const preSignedUrlCertificate = await this.createPreSignedUrls(
      certToUpload,
      false,
    );
    if (
      preSignedUrlCertificate &&
      preSignedUrlCertificate.preSignedUrls &&
      preSignedUrlCertificate.preSignedUrls.length
    ) {
      await this.callHttpPut(
        preSignedUrlCertificate.preSignedUrls[0].preSignedMultPart[0],
        this.addedFilesCertificates[0].content,
        1,
        this.addedFilesCertificates[0].name,
        null,
        null,
      );
    }
  }

  private async createPreSignedUrls(fileToUpload, image) {
    if (fileToUpload && fileToUpload.id) {
      const params = {
        files: [fileToUpload],
        uploadPart: this.createPath(image, null),
        path: true,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      const result = await this.dataBookService.documentPreSignedUrl(params);
      return result;
    }
    return null;
  }

  private createPath(image, id) {
    const path = image ? '/images/' : '/certificates/';
    return 'signatures/' + this.currentUser.email + path + (id ? id : '');
  }

  getFileToUpload(image) {
    if (image && this.addedFilesImages.length) {
      const file = this.addedFilesImages[0];
      return {
        id: file.id,
        name: file.description,
        revision: 1,
        length: file.size,
      };
    } else if (!image && this.addedFilesCertificates.length) {
      const file = this.addedFilesCertificates[0];
      return {
        id: file.id,
        name: file.description,
        revision: 1,
        length: file.size,
      };
    }
    return { id: null, name: null };
  }

  validateFile() {
    return true;
  }

  async file() {
    try {
      if (this.validateFile()) {
        this.coreService.showLoading();
        const signature = this.mysSignatureForm.value;

        await this.dataBookService.signature({
          addFile: signature,
          userId: this.userStore.user.id,
          email: this.userStore.user.email,
          productId: this.store.selectedProduct.id,
          userName:
            this.userStore.user.firstName + ' ' + this.userStore.user.lastName,
        });

        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );

        this.coreService.hideLoading();
      }
    } catch (error) {
      await this.coreService.processError(error);
    }
  }

  validateFileToUpload(image, file) {
    if (
      (image && !this.acceptImage.includes(file.type)) ||
      (!image &&
        !this.acceptCertificateExtension.includes(file.name.split('.').pop()))
    ) {
      this.coreService.showAlertErrorTranslate('databook.content.type.problem');
      return false;
    }
    if (file.size > this.FILE_SIZE) {
      this.coreService.showAlertErrorTranslate('tpi.signature.size');
      return false;
    }
    return true;
  }

  removeImgCert(image) {
    if (image) {
      this.oldImage = { id: null, name: null, path: this.oldImage.path };
      this.addedFilesImages = [];
    } else {
      this.oldCert = { id: null, name: null, path: this.oldCert?.path };
      this.addedFilesCertificates = [];
    }
  }

  onFilesAdded(files: File[], image) {
    files.forEach((file: File) => {
      if (this.validateFileToUpload(image, file)) {
        // @ts-ignore
        file.description = file.name;
        // @ts-ignore
        file.id = uuid();

        if (image) {
          this.addedFilesImages = [file];
        } else {
          this.addedFilesCertificates = [file];
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e: ProgressEvent) => {
          const key = 'content';
          file[key] = (e.target as FileReader).result;
        };
      }
    });

    for (const file of files) {
      if (this.validateFileToUpload(image, file)) {
        // @ts-ignore
        file.description = file.name;
        // @ts-ignore
        file.id = uuid();

        if (image) {
          this.addedFilesImages = [file];
        } else {
          this.addedFilesCertificates = [file];
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e: ProgressEvent) => {
          const key = 'content';
          file[key] = (e.target as FileReader).result;
        };
      }
    }
  }

  getImgFile() {
    if (this.addedFilesImages.length) {
      return this.addedFilesImages[0].description;
    } else if (this.oldImage) {
      return this.oldImage.name;
    }
  }

  getCertFile() {
    if (this.addedFilesCertificates.length) {
      return this.addedFilesCertificates[0].description;
    } else if (this.oldCert) {
      return this.oldCert.name;
    }
  }

  // async addNewSignature(certificate) {
  //   this.openNewSignatureModal = 'none';
  //   this.coreService.showLoading();
  //   console.log(certificate);
  //   const response = await this.dataBookService.addCertificate({
  //     email: certificate.email,
  //     name: certificate.subjectName,
  //     type: this.TYPES[0]
  //   });
  //   console.log('response front ->', response)
  // }

  get type() {
    return this.mysSignatureForm.get('type');
  }
  get name() {
    return this.mysSignatureForm.get('name');
  }
  get id() {
    return this.mysSignatureForm.get('id');
  }
  get certificate() {
    return this.mysSignatureForm.get('certificate');
  }
}
