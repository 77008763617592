import { zeroDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'PRODUCT TENSILE TRANSVERSE',
  headerClass: 'custom-header-4',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'HTL',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_htl']],
        },
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_sampling_side']],
        },
        {
          field: 'Elongation',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_transverse_elongation']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_elongation']],
        },
        {
          field: 'YS/TS',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_transverse_ys/ts']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_ys/ts']],
        },
        {
          field: 'Yield Strength',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_transverse_yield_strength']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_yield_strength']],
        },
        {
          field: 'Tensile Strength',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_transverse_tensile_strength']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_tensile_strength']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-4',
      columnGroupShow: 'open',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_transverse_file_name']],
        },
      ],
    },
  ],
};
