<a
  class="nav-link nav-icon-text"
  (click)="openContactUsModal()"
>
  <clr-icon shape="envelope"></clr-icon>
  <span class="nav-text"></span>
</a>

<clr-modal
  class="modal-project"
  [clrModalOpen]="actionContactUsModal !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <h2 class="vic-header-title">{{ 'db.contact.us' | translate }}</h2>
    <h5
      class="vic-header-title"
      style="margin-top: -5px"
    >
      {{ 'db.contact.us.description' | translate }}
    </h5>

    <form
      [formGroup]="formContactUs"
      clrForm
      class="project-form"
    >
      <clr-select-container style="height: 50px">
        <label>{{ 'db.contact.us.request.type' | translate }}</label>
        <select
          clrSelect
          name="type"
          formControlName="type"
          (change)="changeType()"
          required
        >
          <option
            value=""
            disabled
            hidden
            selected
          >
            {{ 'db.contact.us.select.request.type' | translate }}
          </option>
          <option [ngValue]="'db.contact.us.contact.us'">
            <label class="label-small">
              {{ 'db.contact.us.contact.us' | translate }}
            </label>
          </option>
          <option [ngValue]="'db.contact.us.feedback.improvement.idea'">
            <label class="label-small">
              {{ 'db.contact.us.feedback.improvement.idea' | translate }}
            </label>
          </option>
          <option [ngValue]="'db.contact.us.project.creation.request'">
            <label class="label-small">
              {{ 'db.contact.us.project.creation.request' | translate }}
            </label>
          </option>
          <option [ngValue]="'db.contact.us.question'">
            <label class="label-small">
              {{ 'db.contact.us.question' | translate }}
            </label>
          </option>
          <option [ngValue]="'db.contact.us.user.access.request'">
            <label class="label-small">
              {{ 'db.contact.us.user.access.request' | translate }}
            </label>
          </option>
          <option [ngValue]="'db.contact.us.report.a.bug'">
            <label class="label-small">
              {{ 'db.contact.us.report.a.bug' | translate }}
            </label>
          </option>
        </select>
        <clr-control-error>
          {{ 'required.field.required' | translate }}
        </clr-control-error>
      </clr-select-container>

      <clr-input-container>
        <label>{{ 'db.subject' | translate }}</label>
        <input
          clrInput
          name="subject"
          formControlName="subject"
          required
          maxlength="180"
        />
        <clr-control-error>
          {{ 'required.field.required' | translate }}
        </clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label *ngIf="isReportABug()">Version Number</label>
        <input
          clrInput
          *ngIf="isReportABug()"
          formControlName="version"
          readonly
          name="version"
          [value]="environment"
          [disabled]
        />
      </clr-input-container>
      <clr-textarea-container>
        <label *ngIf="isTypeValid() && isReportABug()">{{
          'db.steps' | translate
        }}</label>
        <textarea
          clrTextarea
          name="steps"
          formControlName="steps"
          placeholder="Please detail it here"
          rows="2"
          required
          *ngIf="isTypeValid() && isReportABug()"
          maxlength="2000"
        ></textarea>
        <clr-control-error>
          {{ 'required.field.required' | translate }}
        </clr-control-error>
      </clr-textarea-container>

      <clr-textarea-container>
        <label *ngIf="!isTypeValid()">
          {{ 'db.message.select.type' | translate }}
        </label>

        <label *ngIf="isTypeValid() && !isReportABug()">{{
          'db.message' | translate
        }}</label>
        <textarea
          clrTextarea
          name="message"
          formControlName="message"
          required
          rows="5"
          *ngIf="isTypeValid() && !isReportABug()"
          maxlength="2000"
        ></textarea>
        <clr-control-error>
          {{ 'required.field.required' | translate }}
        </clr-control-error>
      </clr-textarea-container>

      <clr-textarea-container>
        <label *ngIf="isTypeValid() && isReportABug()">{{
          'db.obtained' | translate
        }}</label>
        <textarea
          clrTextarea
          name="obtainedResult"
          formControlName="obtainedResult"
          required
          rows="2"
          *ngIf="isTypeValid() && isReportABug()"
          maxlength="2000"
        ></textarea>
        <clr-control-error>
          {{ 'required.field.required' | translate }}
        </clr-control-error>
      </clr-textarea-container>

      <clr-textarea-container>
        <label *ngIf="isTypeValid() && isReportABug()">{{
          'db.attended' | translate
        }}</label>
        <textarea
          clrTextarea
          name="attendedResult"
          formControlName="attendedResult"
          required
          rows="2"
          *ngIf="isTypeValid() && isReportABug()"
          maxlength="2000"
        ></textarea>
        <clr-control-error>
          {{ 'required.field.required' | translate }}
        </clr-control-error>
      </clr-textarea-container>
      <br />
      <div class="clr-col-12">
        <div class="clr-row">
          <div class="clr-col-2 attachment-style">
            {{ 'databook.attachment.s' | translate }}
          </div>
          <div class="clr-col-10">
            <ngx-dropzone
              (change)="onFilesAdded($event.addedFiles)"
              #dropzone
              [multiple]="true"
              style="height: 70px"
            >
              <ngx-dropzone-label
                >Drag and drop gif, jpg, pdf, png, and xlsx files, up to
                5MB</ngx-dropzone-label
              >
            </ngx-dropzone>
          </div>
        </div>
      </div>

      <div class="clr-col-12">
        <div
          *ngIf="addedFiles && addedFiles.length"
          style="overflow-x: scroll"
        >
          <clr-datagrid style="height: 300px">
            <clr-dg-column>
              {{ 'databook.file.name' | translate }}</clr-dg-column
            >
            <clr-dg-column [style.width.px]="160">
              {{ 'databook.file.size' | translate }} (MB)</clr-dg-column
            >
            <clr-dg-column [style.width.px]="20"></clr-dg-column>
            <clr-dg-row *clrDgItems="let file of addedFiles">
              <clr-dg-cell>{{ file.name }}</clr-dg-cell>
              <clr-dg-cell>{{ file.size / 1000000 }}</clr-dg-cell>
              <clr-dg-cell>
                <img
                  alt="trashIcon"
                  class="file-tree-icon"
                  (click)="delete(file.name)"
                  src="../../../assets/img/svg/trash-icon.svg"
                />
              </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination
                #paginationTaskDetails
                [clrDgPageSize]="10"
              >
                <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                  {{
                    'application.commons.grid.footer.pagination.page.size'
                      | translate
                  }}</clr-dg-page-size
                >
                {{ paginationTaskDetails.firstItem + 1 }} -
                {{ paginationTaskDetails.lastItem + 1 }}
                {{
                  'application.commons.grid.footer.pagination.of' | translate
                }}
                {{ paginationTaskDetails.totalItems }}
                {{
                  'application.commons.grid.footer.pagination.records'
                    | translate
                }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeContactUsModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      [disabled]="!formContactUs.valid"
      (click)="contactUs()"
    >
      {{ 'application.commons.buttons.send' | translate }}
    </button>
  </div>
</clr-modal>
