import { Injectable } from '@angular/core';
import { FeaturesEnum } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class Features {
  id: number;
  name: FeaturesEnum;
  productFeatureId: number;
}
