<vic-base-page page="dataExplorer">
  <div class="header-actions container"></div>

  <databook-breadcrumb> </databook-breadcrumb>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>

    <div class="val-card clr-col-12">
      <label class="session-title">{{ 'plp.select.test' | translate }}</label>
      <div class="clr-row">
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('chemical')"
          *ngIf="
            isChemicalVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.chemical' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('pelm')"
          *ngIf="dataBookState.project === 'SANGOMAR'"
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.pelm' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('hardness')"
          *ngIf="
            isHardnessVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.hardness' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('impact')"
          *ngIf="
            isImpactVisible() &&
            dataBookState.project !== 'SANGOMAR' &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.impact' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('new-impact-template')"
          *ngIf="dataBookState.project === 'SANGOMAR'"
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.impact' | translate }}2
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          *ngIf="
            isTensileVisible() &&
            dataBookState.project !== 'SANGOMAR' &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div
            class="test-button"
            (click)="cardClick('tensile')"
          >
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.tensile' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          *ngIf="dataBookState.project === 'SANGOMAR'"
        >
          <div
            class="test-button"
            (click)="cardClick('new-tensile-template')"
          >
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.tensile' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('ctod')"
          *ngIf="
            isCtodVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.ctod' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('dimensions')"
          *ngIf="
            isDimensionsVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.project !== 'SEPIA' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.dimension' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('dimensions-sepia')"
          *ngIf="
            isDimensionsVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.project === 'SEPIA'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.dimension' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('ssc')"
          *ngIf="
            isSccVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.ssc' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('hic')"
          *ngIf="
            isHicVisible() &&
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.product !== 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.hic' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('all')"
          *ngIf="
            dataBookState.product !== 'DOUBLE_JOINT' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product !== 'COATING' &&
            dataBookState.project !== 'SEPIA' &&
            dataBookState.product !== 'WELDING' &&
            dataBookState.project !== 'SANGOMAR'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.all' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('all-sangomar')"
          *ngIf="dataBookState.project === 'SANGOMAR'"
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.all' | translate }}
              </div>
            </div>
          </div>
        </div>

        <a
          class="btn-link"
          id="downloadFiles"
          [hidden]="true"
        ></a>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('double-joint')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product !== 'MLP' &&
            dataBookState.product === 'DOUBLE_JOINT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Double Joint</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('welding-test')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'WELDING'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Welding</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="downloadFile()"
          *ngIf="
            dataBookState.material &&
            dataBookState.material.datasheet &&
            dataBookState.project !== 'MERO' &&
            dataBookState.product !== 'host'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">DATASHEET</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="downloadFileMero(0)"
          *ngIf="
            dataBookState.material &&
            dataBookState.material.datasheet &&
            dataBookState.project === 'MERO' &&
            dataBookState.product === 'host'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">DATASHEET COMPLETE</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="downloadFileMero(1)"
          *ngIf="
            dataBookState.material &&
            dataBookState.material.datasheet &&
            dataBookState.project === 'MERO' &&
            dataBookState.product === 'host'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">DATASHEET ORIGINAL</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('ash-density')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">ASH DENSITY</div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('mass-ratio')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">MASS RATIO</div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('adhesion')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">ADHESION</div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('pull-off')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">PULL-OFF</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('porosity')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.porosity' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('cure')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.cure' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('melt-flow-index')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.melt.flow.index' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('incoming-3-layer')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.density.gspp' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('conductivity')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.conductivity' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('entrapped')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.entrapped' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('adhesive')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.adhesive' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('polypropylene')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.polypropylene' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('cathodic-disbondment')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.cathodic.disbondment' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('epoxy')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.epoxy' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('hot-water')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.hot.water' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('impact-undercoating')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.impact.undercoating' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('indentation')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.indentation' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('oit')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.oit' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('tensile-prop-topcoat')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('undercoating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.tensile.prop.topcoat' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('compression')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.compression' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('shore-d-topcoat')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.shore.d.topcoat' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('interlayer-adhesion')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.interlayer.adhesion' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('raw-materials')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.raw.materials' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('modulus')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.modulus' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('tensile-prop-topcoat-insulating')"
          *ngIf="
            dataBookState.product === 'COATING' &&
            dataBookState.project === 'MERO' &&
            dataBookState.material.description
              .toLowerCase()
              .includes('insulating')
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                {{ 'plp.test.tensile.properties.top.coat' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('mlp')"
          *ngIf="dataBookState.product === 'MLP'"
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">DATA</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('cutback-7-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Cutback 7 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('final-inspection-3-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Final Inspection 3 Layer</div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('final-inspection-5-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Final Inspection 5 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('final-inspection-7-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Final Inspection 7 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('incoming-3-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Incoming 3 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('incoming-5-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Incoming 5 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('incoming-7-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Incoming 7 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('ind-coil-preheat-3-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Ind Coil Preheat 3 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('post-blasting-3-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Post Blasting 3 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('saw-station-5-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Saw Station 5 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('saw-station-7-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Saw Station 7 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('thickness-3-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Thickness 3 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('sinter-7-layer')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Sinter 7 Layer</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('final-inspection')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description !== 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Final inspection</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('iInduction-coil-preheat')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description === 'OD 244.5 x 12.7 WT.'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Induction coil preheat</div>
            </div>
          </div>
        </div>
        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('incoming')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description !== 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Incoming</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('post-blasting')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description !== 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Post blasting</div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('rough-coat')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description !== 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">
                Rough coat, magnetism, DJ weld thickness
              </div>
            </div>
          </div>
        </div>

        <div
          class="clr-col-12 clr-col-sm-3"
          (click)="cardClick('thickness')"
          *ngIf="
            dataBookState.project === 'PLATINA' &&
            dataBookState.product === 'COATING' &&
            dataBookState.material.description !== 'OD 273.1 x 26.5 WT'
          "
        >
          <div class="test-button">
            <div class="val-card-body">
              <div class="test-button-label">Thickness</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>
