<a
  class="btn-link"
  id="downloadFiles"
  [hidden]="true"
></a>

<div style="text-align: right">
  <button
    class="btn btn-primary"
    (click)="editSignature(null)"
  >
    {{ 'application.commons.buttons.new' | translate }}
  </button>
</div>

<div class="val-card clr-col-12">
  <div class="clr-row">
    <clr-datagrid style="width: 100%">
      <clr-dg-column
        scope="col"
        [style.width.px]="450"
        >Signature Name</clr-dg-column
      >
      <clr-dg-column
        scope="col"
        [style.width.px]="400"
        >Signature Type</clr-dg-column
      >
      <clr-dg-column
        scope="col"
        [style.width.px]="450"
        >User Name</clr-dg-column
      >
      <clr-dg-column
        scope="col"
        [style.width.px]="30"
        >Picture</clr-dg-column
      >
      <clr-dg-column
        scope="col"
        [style.width.px]="30"
        >Cerificate</clr-dg-column
      >
      <clr-dg-column
        scope="col"
        [style.width.px]="30"
        >Modify</clr-dg-column
      >
      <clr-dg-column
        scope="col"
        [style.width.px]="30"
        >Delete</clr-dg-column
      >

      <clr-dg-row
        *clrDgItems="let sig of signatures"
        [clrDgItem]="sig"
      >
        <clr-dg-cell>{{ sig.description }}</clr-dg-cell>
        <clr-dg-cell>{{ sig.type }}</clr-dg-cell>
        <clr-dg-cell>{{ sig.userName }}</clr-dg-cell>

        <clr-dg-cell style="text-align: center">
          <a
            class="nav-link"
            (click)="downloadPicCert(sig.pictureId, sig.pictureName, true)"
            *ngIf="sig.pictureId"
          >
            <clr-icon
              size="16"
              shape="file"
            ></clr-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-cell style="text-align: center">
          <a
            class="nav-link"
            (click)="
              downloadPicCert(sig.certificateId, sig.certificateName, false)
            "
            *ngIf="sig.certificateId"
          >
            <clr-icon
              size="16"
              shape="file"
            ></clr-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-cell style="text-align: center">
          <a
            class="nav-link"
            (click)="editSignature(sig)"
          >
            <clr-icon
              size="16"
              shape="note"
            ></clr-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-cell style="text-align: center">
          <a
            class="nav-link"
            (click)="openRemoveSignature(sig)"
            *ngIf="sig.type !== 'LACUNA_SES'"
          >
            <clr-icon
              size="16"
              shape="trash"
            ></clr-icon>
          </a>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
</div>

<clr-modal
  [clrModalOpen]="actionConfirmRemove !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ 'confirm.exclusion' | translate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelRemove()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="remove()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="openSignatureModal !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <form
      class="vic-form-group"
      clr-form
      [formGroup]="mysSignatureForm"
      style="margin: 20px 0 0 0"
    >
      <div>
        <clr-input-container>
          <label class="clr-col-12 clr-col-md-3">Name *</label>
          <input
            clrInput
            class="clr-col-12 clr-col-md-8"
            type="text"
            name="name"
            formControlName="name"
            size="25"
            [maxLength]="200"
          />
        </clr-input-container>

        <clr-select-container>
          <label class="clr-col-12 clr-col-md-3"
            >{{ 'scope.type' | translate }} *</label
          >
          <select
            class="clr-col-12 clr-col-md-8"
            clrSelect
            name="type"
            formControlName="type"
            (change)="changeType()"
          >
            <option value="CUSTOM">Custom electronic signature</option>
            <option value="LACUNA">Lacuna QES</option>
            <!--                        <option value="LACUNA_SES">Lacuna SES</option>-->
          </select>
        </clr-select-container>

        <div
          *ngIf="openModalPki !== 'none' && !canSign && isLacunaSignature()"
          class="align-open-pki"
        >
          <button
            type="button"
            id="installOrUpdateWebExtensionButton"
            class="btn btn-lg btn-primary align-button"
            [disabled]="coreService.isLoading()"
            (click)="redirectToInstall()"
          >
            {{ 'databook.add.pki' | translate }}
          </button>
        </div>

        <clr-select-container *ngIf="isLacunaSignature() && canSign">
          <label class="clr-col-12 clr-col-md-3">Certificate *</label>
          <select
            class="clr-col-12 clr-col-md-8"
            clrSelect
            name="certificate"
            formControlName="certificate"
          >
            <option
              *ngFor="let cert of certificateList"
              [ngValue]="cert.email"
            >
              <label class="label-small">{{ cert.subjectName }}</label>
            </option>
          </select>
        </clr-select-container>
      </div>

      <div class="clr-row align-row">
        <label class="clr-col-12 clr-col-md-2 bold-black">Image</label>
      </div>
      <div class="clr-row align-row">
        <div class="clr-col-6">
          <ngx-dropzone
            class="upload-file"
            (change)="onFilesAdded($event.addedFiles, true)"
            #dropzoneImage
            [multiple]="true"
          >
            <ngx-dropzone-label>{{
              'application.commons.buttons.drag.drop.upload' | translate
            }}</ngx-dropzone-label>
          </ngx-dropzone>
        </div>
        <div class="clr-col-6">
          {{ getImgFile() }}
          <a
            class="nav-link"
            (click)="removeImgCert(true)"
          >
            <clr-icon
              size="16"
              shape="trash"
            ></clr-icon>
          </a>
        </div>
      </div>
      <div class="clr-row align-row"><br /></div>
      <div class="clr-row align-row">
        <label class="clr-col-12 clr-col-md-2 bold-black">Certificate</label>
      </div>
      <div class="clr-row align-row">
        <div class="clr-col-6">
          <ngx-dropzone
            class="upload-file"
            (change)="onFilesAdded($event.addedFiles, false)"
            #dropzoneCertificate
            [multiple]="true"
          >
            <ngx-dropzone-label>{{
              'application.commons.buttons.drag.drop.upload' | translate
            }}</ngx-dropzone-label>
          </ngx-dropzone>
        </div>
        <div class="clr-col-6">
          {{ getCertFile() }}
          <a
            class="nav-link"
            (click)="removeImgCert(false)"
          >
            <clr-icon
              size="16"
              shape="trash"
            ></clr-icon>
          </a>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancel()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-primary"
      (click)="save()"
    >
      SAVE
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="openModalPki !== 'none'"
  [clrModalSize]="'lg'"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-header">
    <button
      aria-label="Close"
      class="close"
      type="button"
    >
      <clr-icon
        aria-hidden="true"
        shape="close"
      ></clr-icon>
    </button>
    {{ 'databook.pki.extension' | translate }}
  </div>

  <div
    class="modal-body"
    style="text-align: center"
  >
    <div class="clr-row-col12">
      {{ 'databook.pki.necessary' | translate }}
      <br />
      <br />
      <button
        type="button"
        id="installOrUpdateWebExtensionButton"
        class="btn btn-lg btn-primary"
        (click)="redirectToInstall()"
      >
        {{ 'databook.add.pki' | translate }}
      </button>
    </div>
  </div>
</clr-modal>
