import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { NewTpiDigitalComponent } from '../pages/new-tpi-digital/new-tpi-digital.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { ConfigWorkflowComponent } from '../pages/new-tpi-digital/config-workflow-modal/config-workflow/config-workflow.component';
import { DataBookMgmtFlowComponent } from '../pages/new-tpi-digital/tpi-flow-mgmt/tpi-flow-mgmt.component';
import { pipeNavigatorRouteNames } from '../enums/routes.enum';

export const tpi = [
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/new-tpi-digital',
    component: NewTpiDigitalComponent,
    data: { routeName: pipeNavigatorRouteNames.newTpiDigital },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/:fileId/new-tpi-digital',
    component: NewTpiDigitalComponent,
    data: { routeName: pipeNavigatorRouteNames.newTpiDigitalFileId },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/config-workflow',
    component: ConfigWorkflowComponent,
    data: { routeName: pipeNavigatorRouteNames.configWorkflow },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/new-tpi-digital/tpi-flow-mgmt',
    component: DataBookMgmtFlowComponent,
    data: {
      routeName: pipeNavigatorRouteNames.newTpiDigitalTpiFlowMgmt,
    },
    resolve: {
      data: ProjectListResolver,
    },
  },
];
