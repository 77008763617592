import { TreeviewFileItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const editFile = async (
  dataBookService: DataBookService,
  id: number,
  name: string,
  filetype: string,
  s3key: string,
  size?: number,
  document_code?: string,
  datetime?: number,
  revision?: number,
  description?: string,
  position?: number,
): Promise<TreeviewFileItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'editFile',
    args: {
      id,
      name,
      filetype,
      s3key,
      size,
      document_code,
      datetime,
      revision,
      description,
      position,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};
