import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataExplorerComponent } from '../pages/data-explorer/data-explorer.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { PlpChemicalComponent } from '../pages/plp/chemical/plp-chemical.component';
import { PlpCtodComponent } from '../pages/plp/ctod/plp-ctod.component';
import { PlpHardnessComponent } from '../pages/plp/hardness/plp-hardness.component';
import { PlpHicComponent } from '../pages/plp/hic/plp-hic.component';
import { PlpAllComponent } from '../pages/plp/all/plp-all.component';
import { PlpAllSangomarComponent } from '../pages/plp/all-sangomar/plp-all-sangomar.component';
import { PlpSscComponent } from '../pages/plp/ssc/plp-ssc.component';
import { PlpImpactComponent } from '../pages/plp/impact/plp-impact.component';
import { PlpTensileComponent } from '../pages/plp/tensile/plp-tensile.component';
import { PlpDimensionsComponent } from '../pages/plp/dimensions/plp-dimensions.component';
import { PlpDimensionsSepiaComponent } from '../pages/plp/dimensions-sepia/plp-dimensions-sepia.component';
import { PlpPlatinaDJointComponent } from '../pages/plp/platina-d-joint/plp-platina-d-joint.component';
import { AshDensityComponent } from '../pages/plp/ash-density/ash-density.component';
import { MassRatioComponent } from '../pages/plp/mass-ratio/mass-ratio.component';
import { CompressionComponent } from '../pages/plp/compression/compression.component';
import { ShoreDTopcoatComponent } from '../pages/plp/shore-d-topcoat/shore-d-topcoat.component';
import { InterlayerAdhesionComponent } from '../pages/plp/interlayer-adhesion/interlayer-adhesion.component';
import { TensilePropTopcoatInsulationComponent } from '../pages/plp/tensile-prop-topcoat-insulating/tensile-prop-topcoat.component';
import { RawMaterialComponent } from '../pages/plp/raw-material/raw-material.component';
import { ModulusComponent } from '../pages/plp/modulus/modulus.component';
import { PorosityComponent } from '../pages/plp/porosity/porosity.component';
import { CureComponent } from '../pages/plp/cure/cure.component';
import { AdhesiveComponent } from '../pages/plp/adhesive/adhesive.component';
import { CathodicDisbondmentComponent } from '../pages/plp/cathodic-disbondment/cathodic-disbondment.component';
import { EpoxyComponent } from '../pages/plp/epoxy/epoxy.component';
import { HotWaterComponent } from '../pages/plp/hot-water/hot-water.component';
import { ImpactUndercoatingComponent } from '../pages/plp/impact-undercoating/impact-undercoating.component';
import { IndentationComponent } from '../pages/plp/indentation/indentation.component';
import { OitComponent } from '../pages/plp/oit/oit.component';
import { PolypropyleneComponent } from '../pages/plp/polypropylene/polypropylene.component';
import { TensilePropTopcoatComponent } from '../pages/plp/tensile-prop-topcoat/tensile-prop-topcoat.component';
import { ConductivityComponent } from '../pages/plp/conductivity/conductivity.component';
import { EntrappedAirComponent } from '../pages/plp/entrapped-air/entrapped-air.component';
import { MeltFlowIndexComponent } from '../pages/plp/melt-flow-index/melt-flow-index.component';
import { DensityGsppComponent } from '../pages/plp/density-gspp/density-gspp.component';
import { WeldingTestComponent } from '../pages/plp/welding-test/welding-test.component';
import { MlpComponent } from '../pages/plp/mlp/mlp.component';
import { AdhesionComponent } from '../pages/plp/adhesion/adhesion.component';
import { PullOffComponent } from '../pages/plp/pull-off/pull-off.component';
import { PlpPelmComponent } from '../pages/plp/pelm/plp-pelm.component';
import { PlpTensileNewTemplateComponent } from '../pages/plp/tensile-new-template/plp-tensile-new-template.component';
import { PlpImpactNewTemplateComponent } from '../pages/plp/impact-new-template/plp-impact-new-template.component';
import { Cutback7LayerComponent } from '../pages/plp/coating/cutback-7-layer/cutback-7-layer.component';
import { FinalInspection3LayerComponent } from '../pages/plp/coating/final-inspection-3-layer/final-inspection-3-layer.component';
import { FinalInspection5LayerComponent } from '../pages/plp/coating/final-inspection-5-layer/final-inspection-5-layer.component';
import { FinalInspection7LayerComponent } from '../pages/plp/coating/final-inspection-7-layer/final-inspection-7-layer.component';
import { Incoming3LayerComponent } from '../pages/plp/coating/incoming-3-layer/incoming-3-layer.component';
import { Incoming5LayerComponent } from '../pages/plp/coating/incoming-5-layer/incoming-5-layer.component';
import { Incoming7LayerComponent } from '../pages/plp/coating/incoming-7-layer/incoming-7-layer.component';
import { IndCoilPreheat3LayerComponent } from '../pages/plp/coating/ind-coil-preheat-3-layer/ind-coil-preheat-3-layer.component';
import { PostBlasting3LayerComponent } from '../pages/plp/coating/post-blasting-3-layer/post-blasting-3-layer.component';
import { SawStation5LayerComponent } from '../pages/plp/coating/saw-station-5-layer/saw-station-5-layer.component';
import { SawStation7LayerComponent } from '../pages/plp/coating/saw-station-7-layer/saw-station-7-layer.component';
import { Thickness3LayerComponent } from '../pages/plp/coating/thickness-3-layer/thickness-3-layer.component';
import { Sinter7LayerComponent } from '../pages/plp/coating/sinter-7-layer/sinter-7-layer.component';
import { FinalInspectionComponent } from '../pages/plp/coating/final-inspection/final-inspection.component';
import { IInductionCoilPreheatComponent } from '../pages/plp/coating/iInduction-coil-preheat/iInduction-coil-preheat.component';
import { IncomingComponent } from '../pages/plp/coating/incoming/incoming.component';
import { PostBlastingComponent } from '../pages/plp/coating/post-blasting/post-blasting.component';
import { ThicknessComponent } from '../pages/plp/coating/thickness/thickness.component';
import { RoughCoatComponent } from '../pages/plp/coating/rough-coat/rough-coat.component';
import { PlpComponent } from '../pages/plp/plp.component';
import { pipeNavigatorRouteNames } from '../enums';
import { MaterialComponent } from '../pages/material/material.component';

export const dataExplorer = [
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer-ag',
    component: DataExplorerComponent,
    data: { routeName: pipeNavigatorRouteNames.dataExplorer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // data explorer material selector
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer',
    component: MaterialComponent,
    data: {
      routeName: pipeNavigatorRouteNames.materialMaterialTypeDataExplorer,
    },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // data explorer test selector
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId',
    component: PlpComponent,
    data: { routeName: pipeNavigatorRouteNames.plp },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/chemical',
    component: PlpChemicalComponent,
    data: { routeName: pipeNavigatorRouteNames.plpChemical },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/ctod',
    component: PlpCtodComponent,
    data: { routeName: pipeNavigatorRouteNames.plpCtod },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/hardness',
    component: PlpHardnessComponent,
    data: { routeName: pipeNavigatorRouteNames.plpHardness },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/hic',
    component: PlpHicComponent,
    data: { routeName: pipeNavigatorRouteNames.plpHic },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // all tests
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/all',
    component: PlpAllComponent,
    data: { routeName: pipeNavigatorRouteNames.plpAll },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/all-sangomar',
    component: PlpAllSangomarComponent,
    data: { routeName: pipeNavigatorRouteNames.plpAllSangomar },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/ssc',
    component: PlpSscComponent,
    data: { routeName: pipeNavigatorRouteNames.plpSsc },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/impact',
    component: PlpImpactComponent,
    data: { routeName: pipeNavigatorRouteNames.plpImpact },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/tensile',
    component: PlpTensileComponent,
    data: { routeName: pipeNavigatorRouteNames.plpTensile },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/dimensions',
    component: PlpDimensionsComponent,
    data: { routeName: pipeNavigatorRouteNames.plpDimensions },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/dimensions-sepia',
    component: PlpDimensionsSepiaComponent,
    data: { routeName: pipeNavigatorRouteNames.plpDimensionsSepia },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/double-joint',
    component: PlpPlatinaDJointComponent,
    data: { routeName: pipeNavigatorRouteNames.plpDoubleJoint },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/ash-density',
    component: AshDensityComponent,
    data: { routeName: pipeNavigatorRouteNames.plpAshDensity },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/mass-ratio',
    component: MassRatioComponent,
    data: { routeName: pipeNavigatorRouteNames.plpMassRatio },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/compression',
    component: CompressionComponent,
    data: { routeName: pipeNavigatorRouteNames.plpCompression },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/shore-d-topcoat',
    component: ShoreDTopcoatComponent,
    data: { routeName: pipeNavigatorRouteNames.plpShoreDTopcoat },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/interlayer-adhesion',
    component: InterlayerAdhesionComponent,
    data: { routeName: pipeNavigatorRouteNames.plpInterlayerAdhesion },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/tensile-prop-topcoat-insulating',
    component: TensilePropTopcoatInsulationComponent,
    data: {
      routeName: pipeNavigatorRouteNames.plpTensilePropTopcoatInsulating,
    },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/raw-materials',
    component: RawMaterialComponent,
    data: { routeName: pipeNavigatorRouteNames.plpRawMaterials },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/modulus',
    component: ModulusComponent,
    data: { routeName: pipeNavigatorRouteNames.plpModulus },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/porosity',
    component: PorosityComponent,
    data: { routeName: pipeNavigatorRouteNames.plpPorosity },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/cure',
    component: CureComponent,
    data: { routeName: pipeNavigatorRouteNames.plpCure },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/adhesive',
    component: AdhesiveComponent,
    data: { routeName: pipeNavigatorRouteNames.plpAdhesive },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/cathodic-disbondment',
    component: CathodicDisbondmentComponent,
    data: { routeName: pipeNavigatorRouteNames.plpCathodicDisbondment },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/epoxy',
    component: EpoxyComponent,
    data: { routeName: pipeNavigatorRouteNames.plpEpoxy },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/hot-water',
    component: HotWaterComponent,
    data: { routeName: pipeNavigatorRouteNames.plpHotWater },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/impact-undercoating',
    component: ImpactUndercoatingComponent,
    data: { routeName: pipeNavigatorRouteNames.plpImpactUndercoating },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/indentation',
    component: IndentationComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIndentation },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/oit',
    component: OitComponent,
    data: { routeName: pipeNavigatorRouteNames.plpOit },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/polypropylene',
    component: PolypropyleneComponent,
    data: { routeName: pipeNavigatorRouteNames.plpPolypropylene },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/tensile-prop-topcoat',
    component: TensilePropTopcoatComponent,
    data: { routeName: pipeNavigatorRouteNames.plpTensilePropTopcoat },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/conductivity',
    component: ConductivityComponent,
    data: { routeName: pipeNavigatorRouteNames.plpConductivity },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/entrapped',
    component: EntrappedAirComponent,
    data: { routeName: pipeNavigatorRouteNames.plpEntrapped },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/melt-flow-index',
    component: MeltFlowIndexComponent,
    data: { routeName: pipeNavigatorRouteNames.plpMeltFlowIndex },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/density-gspp',
    component: DensityGsppComponent,
    data: { routeName: pipeNavigatorRouteNames.plpDensityGspp },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/welding-test',
    component: WeldingTestComponent,
    data: { routeName: pipeNavigatorRouteNames.plpWeldingTest },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/mlp',
    component: MlpComponent,
    data: { routeName: pipeNavigatorRouteNames.plpMlp },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/adhesion',
    component: AdhesionComponent,
    data: { routeName: pipeNavigatorRouteNames.plpAdhesion },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/pull-off',
    component: PullOffComponent,
    data: { routeName: pipeNavigatorRouteNames.plpPullOff },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/pelm',
    component: PlpPelmComponent,
    data: { routeName: pipeNavigatorRouteNames.plpPelm },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/new-tensile-template',
    component: PlpTensileNewTemplateComponent,
    data: { routeName: pipeNavigatorRouteNames.plpNewTensileTemplate },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/new-impact-template',
    component: PlpImpactNewTemplateComponent,
    data: { routeName: pipeNavigatorRouteNames.plpNewImpactTemplate },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/cutback-7-layer',
    component: Cutback7LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpCutback_7Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/final-inspection-3-layer',
    component: FinalInspection3LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpFinalInspection_3Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/final-inspection-5-layer',
    component: FinalInspection5LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpFinalInspection_5Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/final-inspection-7-layer',
    component: FinalInspection7LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpFinalInspection_7Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/incoming-3-layer',
    component: Incoming3LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIncoming_3Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/incoming-5-layer',
    component: Incoming5LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIncoming_5Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/incoming-7-layer',
    component: Incoming7LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIncoming_7Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/ind-coil-preheat-3-layer',
    component: IndCoilPreheat3LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIndCoilPreheat_3Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/post-blasting-3-layer',
    component: PostBlasting3LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpPostBlasting_3Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/saw-station-5-layer',
    component: SawStation5LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpSawStation_5Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/saw-station-7-layer',
    component: SawStation7LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpSawStation_7Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/thickness-3-layer',
    component: Thickness3LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpThickness_3Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/sinter-7-layer',
    component: Sinter7LayerComponent,
    data: { routeName: pipeNavigatorRouteNames.plpSinter_7Layer },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/final-inspection',
    component: FinalInspectionComponent,
    data: { routeName: pipeNavigatorRouteNames.plpFinalInspection },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/iInduction-coil-preheat',
    component: IInductionCoilPreheatComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIInductionCoilPreheat },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/incoming',
    component: IncomingComponent,
    data: { routeName: pipeNavigatorRouteNames.plpIncoming },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/post-blasting',
    component: PostBlastingComponent,
    data: { routeName: pipeNavigatorRouteNames.plpPostBlasting },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer/:materialId/thickness',
    component: ThicknessComponent,
    data: { routeName: pipeNavigatorRouteNames.plpThickness },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-explorer:materialId/rough-coat',
    component: RoughCoatComponent,
    data: { routeName: pipeNavigatorRouteNames.plpRoughCoat },
    resolve: {
      data: ProjectListResolver,
    },
  },
];
