import { COLUMNS } from '../data-explorer-column-order';
import { zeroDigitsNumber } from './column-utils';

export default {
  headerName: 'PRODUCT HARDNESS TRANSVERSE',
  headerClass: 'custom-header-4',
  children: [
    {
      headerName: 'Test 1',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Sampling Side'],
        },
        {
          field: 'Q1 OW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 OW1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 OW1'],
        },
        {
          field: 'Q1 OW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['hardness_test_1_q1_ow2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['hardness_test_1_q1_ow2']],
        },
        {
          field: 'Q1 OW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 OW3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 OW3'],
        },
        {
          field: 'Q1 OW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 OW4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 OW4'],
        },
        {
          field: 'Q1 Avg OW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 Avg OW']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 Avg OW'],
        },
        {
          field: 'Q1 IW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 IW1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 IW1'],
        },
        {
          field: 'Q1 IW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 IW2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 IW2'],
        },
        {
          field: 'Q1 IW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 IW3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 IW3'],
        },
        {
          field: 'Q1 IW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 IW4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 IW4'],
        },
        {
          field: 'Q1 Avg IW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 Avg IW']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 Avg IW'],
        },
        {
          field: 'Q1 MW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 MW1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 MW1'],
        },
        {
          field: 'Q1 MW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 MW2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 MW2'],
        },
        {
          field: 'Q1 MW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 MW3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 MW3'],
        },
        {
          field: 'Q1 MW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 MW4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 MW4'],
        },
        {
          field: 'Q1 Avg MW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 Avg MW']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 Avg MW'],
        },
        {
          field: 'Q1 Avg',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ Q1 Avg']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ Q1 Avg'],
        },
        {
          field: 'DUQ1_EP1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_EP1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_EP1'],
        },
        {
          field: 'DUQ1_EP2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_EP2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_EP2'],
        },
        {
          field: 'DUQ1_EP3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_EP3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_EP3'],
        },
        {
          field: 'DUQ1_EP4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_EP4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_EP4'],
        },
        {
          field: 'DUQ1_EPM',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_EPM']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_EPM'],
        },
        {
          field: 'DUQ1_IP1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_IP1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_IP1'],
        },
        {
          field: 'DUQ1_IP2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_IP2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_IP2'],
        },
        {
          field: 'DUQ1_IP3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_IP3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_IP3'],
        },
        {
          field: 'DUQ1_IP4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_IP4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_IP4'],
        },
        {
          field: 'DUQ1_IPM',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_IPM']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_IPM'],
        },
        {
          field: 'DUQ1_MP1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_MP1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_MP1'],
        },
        {
          field: 'DUQ1_MP2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_MP2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_MP2'],
        },
        {
          field: 'DUQ1_MP3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_MP3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_MP3'],
        },
        {
          field: 'DUQ1_MP4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_MP4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_MP4'],
        },
        {
          field: 'DUQ1_MPM',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 1 _ DUQ1_MPM']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ DUQ1_MPM'],
        },
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Hardness - Test 1 _ File Name'],
        },
      ],
    },
    {
      headerName: 'Test 2',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Sampling Side'],
        },
        {
          field: 'Q1 OW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 OW1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 OW1'],
        },
        {
          field: 'Q1 OW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 OW2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 OW2'],
        },
        {
          field: 'Q1 OW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 OW3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 OW3'],
        },
        {
          field: 'Q1 OW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 OW4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 OW4'],
        },
        {
          field: 'Q1 Avg OW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 Avg OW']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 Avg OW'],
        },
        {
          field: 'Q1 IW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 IW1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 IW1'],
        },
        {
          field: 'Q1 IW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 IW2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 IW2'],
        },
        {
          field: 'Q1 IW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 IW3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 IW3'],
        },
        {
          field: 'Q1 IW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 IW4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 IW4'],
        },
        {
          field: 'Q1 Avg IW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 Avg IW']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 Avg IW'],
        },
        {
          field: 'Q1 MW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 MW1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 MW1'],
        },
        {
          field: 'Q1 MW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 MW2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 MW2'],
        },
        {
          field: 'Q1 MW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 MW3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 MW3'],
        },
        {
          field: 'Q1 MW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 MW4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 MW4'],
        },
        {
          field: 'Q1 Avg MW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 Avg MW']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 Avg MW'],
        },
        {
          field: 'Q1 Avg',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ Q1 Avg']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ Q1 Avg'],
        },
        {
          field: 'DUQ1_EP1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_EP1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_EP1'],
        },
        {
          field: 'DUQ1_EP2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_EP2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_EP2'],
        },
        {
          field: 'DUQ1_EP3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_EP3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_EP3'],
        },
        {
          field: 'DUQ1_EP4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_EP4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_EP4'],
        },
        {
          field: 'DUQ1_EPM',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_EPM']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_EPM'],
        },
        {
          field: 'DUQ1_IP1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_IP1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_IP1'],
        },
        {
          field: 'DUQ1_IP2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_IP2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_IP2'],
        },
        {
          field: 'DUQ1_IP3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_IP3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_IP3'],
        },
        {
          field: 'DUQ1_IP4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_IP4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_IP4'],
        },
        {
          field: 'DUQ1_IPM',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_IPM']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_IPM'],
        },
        {
          field: 'DUQ1_MP1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_MP1']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_MP1'],
        },
        {
          field: 'DUQ1_MP2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_MP2']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_MP2'],
        },
        {
          field: 'DUQ1_MP3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_MP3']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_MP3'],
        },
        {
          field: 'DUQ1_MP4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_MP4']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_MP4'],
        },
        {
          field: 'DUQ1_MPM',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness - Test 2 _ DUQ1_MPM']),
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ DUQ1_MPM'],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-4',
      columnGroupShow: 'open',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data['Hardness - Test 2 _ File Name'],
        },
      ],
    },
  ],
};
