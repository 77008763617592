import { TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const getFolder = async (
  dataBookService: DataBookService,
  folderId: number,
  includeContent = false,
  includeChildrens = false,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'getFolder',
    args: {
      folderId,
      includeContent,
      includeChildrens,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};
