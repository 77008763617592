import { TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const folderGetChildFolders = async (
  dataBookService: DataBookService,
  folderId: number,
): Promise<TreeviewFolderItem[]> => {
  const params: { functionName; args: any } = {
    functionName: 'folderGetChildFolders',
    args: {
      folderId,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem[]>(params);
};
