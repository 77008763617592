export enum ProductType {
  carbonSteel = 'CARBON_STEEL',
  mlp = 'MLP',
  welding = 'WELDING',
  internalCoating = 'INTERNAL_COATING',
  tiCoating = 'TI_COATING',
  acCoating = 'AC_COATING',
  doubleJoint = 'DOUBLE_JOINT',
  pupPieces = 'PUP_PIECES',
  bending = 'BENDING',
  liner = 'LINER',
}
