import { zeroDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'PRODUCT HARDNESS TRANSVERSE',
  headerClass: 'custom-header-4',
  children: [
    {
      headerName: 'Test 1',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data['Hardness _ Sampling Side'],
        },
        {
          field: 'Q1 OW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 OW1']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 OW1'],
        },
        {
          field: 'Q1 OW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 OW2']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 OW2'],
        },
        {
          field: 'Q1 OW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 OW3']),
          valueGetter: (params: any) => {
            return params.data['Hardness _ Q1 OW3'];
          },
        },
        {
          field: 'Q1 OW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 OW4']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 OW4'],
        },
        {
          field: 'Q1 Avg OW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 Avg OW']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 Avg OW'],
        },
        {
          field: 'Q1 IW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 IW1']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 IW1'],
        },
        {
          field: 'Q1 IW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 IW2']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 IW2'],
        },
        {
          field: 'Q1 IW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 IW3']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 IW3'],
        },
        {
          field: 'Q1 IW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 IW4']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 IW4'],
        },
        {
          field: 'Q1 Avg IW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 Avg IW']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 Avg IW'],
        },
        {
          field: 'Q1 MW1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 MW1']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 MW1'],
        },
        {
          field: 'Q1 MW2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 MW2']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 MW2'],
        },
        {
          field: 'Q1 MW3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 MW3']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 MW3'],
        },
        {
          field: 'Q1 MW4',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 MW4']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 MW4'],
        },
        {
          field: 'Q1 Avg MW',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 Avg MW']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 Avg MW'],
        },
        {
          field: 'Q1 Avg',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber('', params.data['Hardness _ Q1 Avg']),
          valueGetter: (params: any) => params.data['Hardness _ Q1 Avg'],
        },
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['hardness_file_name']],
        },
      ],
    },
  ],
};
