import _ from 'lodash';
import { ColDefDataExplorer } from '../data-explorer';
import {
  actualLengthAndMass,
  dimensionalReport,
  individualIdentification,
  ladleChemical,
  lotIdentification,
  nominalData,
  pelmData,
  pelmEndA,
  pelmEndB,
  productChemicalAnalysis,
  productHardnessTransverse,
  productHardnessTransverseYellowtail,
  productImpactLongitudinal,
  productImpactTransverse,
  productTensileLongitudinal,
  productTensileTransverse,
} from './columns/index';

export const columnsDefFile: ColDefDataExplorer[] = [
  individualIdentification,
  lotIdentification,
  nominalData,
  actualLengthAndMass,
  pelmData,
  pelmEndA,
  pelmEndB,
  dimensionalReport,
  ladleChemical,
  productChemicalAnalysis,
  productTensileLongitudinal,
  productTensileTransverse,
  productImpactLongitudinal,
  productImpactTransverse,
  productHardnessTransverse,
  productHardnessTransverseYellowtail,
];

export const immutableColumnsDefFile: ColDefDataExplorer[] =
  _.cloneDeep(columnsDefFile);
