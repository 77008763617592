<vic-base-page page="dataExplorer-all-sangomar">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      *ngIf="dataBookState.project === 'PLATINA'"
      (click)="configExport()"
    >
      <clr-icon shape="filter-grid"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.filter.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isAllTestVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-checkbox-container clrInline>
          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="dimensionsChecked"
              [(ngModel)]="dimensionsChecked"
            />
            <label>Dimensions</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="chemicalChecked"
              [(ngModel)]="chemicalChecked"
            />
            <label>Chemical</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="tensileChecked"
              [(ngModel)]="tensileChecked"
            />
            <label>Tensile</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="pelmChecked"
              [(ngModel)]="pelmChecked"
            />
            <label>PELM</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="hardnessChecked"
              [(ngModel)]="hardnessChecked"
            />
            <label>Hardness</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="impactChecked"
              [(ngModel)]="impactChecked"
            />
            <label>Impact</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <!--                    DIMENSIONS-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_pipe_num') && dimensionsChecked"
            >{{ 'data.book.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_supplier') && dimensionsChecked"
            >{{ 'data.book.supplier' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpmpt_customer_po_num') && dimensionsChecked
            "
            >{{ 'data.book.customer.po' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_heat') && dimensionsChecked"
            >{{ 'data.book.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_dimension') && dimensionsChecked"
            >{{ 'data.book.dimension' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_item') && dimensionsChecked"
            >{{ 'data.book.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_mill_order') && dimensionsChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_mill_item') && dimensionsChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_od_nom'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpmpt_od_nom') && dimensionsChecked"
            >{{ 'data.book.od.nominal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_wt_nom'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpmpt_wt_nom') && dimensionsChecked"
            >{{ 'data.book.wt.nominal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_lenght'"
            *ngIf="shouldShowColumn('tpmpt_lenght') && dimensionsChecked"
            >{{ 'data.book.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_weight_bare_kg'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_weight_bare_kg') && dimensionsChecked
            "
            >{{ 'data.book.weight.bare' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_lot') && dimensionsChecked"
            >{{ 'data.book.lot' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpmpt_certificate_num') && dimensionsChecked
            "
            >{{ 'data.book.certificate' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpmpt_manufacturer_location') &&
              dimensionsChecked
            "
            >{{ 'data.book.manufacture.loc' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_pts_pipe_num') && dimensionsChecked"
            >{{ 'data.book.pts.pipe.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_steel_grade') && dimensionsChecked"
            >{{ 'data.book.steel.grade' | translate }}</clr-dg-column
          >

          <!--                    CHEMICAL-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_sample_num') && chemicalChecked"
            >{{ 'data.book.sample.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_specimen') && chemicalChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_al_n'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_al_n') && chemicalChecked"
            >{{ 'data.book.al.n' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_aluminium_total_al'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tptd_aluminium_total_al') && chemicalChecked
            "
            >{{ 'data.book.aluminium.total' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_boron_b'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_boron_b') && chemicalChecked"
            >{{ 'data.book.boron' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_ca_s'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_ca_s') && chemicalChecked"
            >{{ 'data.book.ca.s' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_calcium_ca'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_calcium_ca') && chemicalChecked"
            >{{ 'data.book.calcium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_carbon_c'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_carbon_c') && chemicalChecked"
            >{{ 'data.book.carbon' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_carbon_equivalent_ce'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tptd_carbon_equivalent_ce') && chemicalChecked
            "
            >{{ 'data.book.carbon.equivalent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_chromium_cr'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_chromium_cr') && chemicalChecked"
            >{{ 'data.book.chromium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_copper_cu'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_copper_cu') && chemicalChecked"
            >{{ 'data.book.copper' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_manganese_mn'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_manganese_mn') && chemicalChecked"
            >{{ 'data.book.manganese' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_molybdenum_mo'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_molybdenum_mo') && chemicalChecked"
            >{{ 'data.book.molybdenum' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_nb_v_ti'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_nb_v_ti') && chemicalChecked"
            >{{ 'data.book.nb.v.ti' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_nickel_ni'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_nickel_ni') && chemicalChecked"
            >{{ 'data.book.nickel' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_niobium_nb'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_niobium_nb') && chemicalChecked"
            >{{ 'data.book.niobium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_pcm_value'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_pcm_value') && chemicalChecked"
            >{{ 'data.book.pcm.value' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_nitrogen_n'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_nitrogen_n') && chemicalChecked"
            >{{ 'data.book.nitrogen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_phosporus_p'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_phosporus_p') && chemicalChecked"
            >{{ 'data.book.phosporus' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_silicon_si'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_silicon_si') && chemicalChecked"
            >{{ 'data.book.silicon' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_sulphur_s'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_sulphur_s') && chemicalChecked"
            >{{ 'data.book.sulphur' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_titanium_ti'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_titanium_ti') && chemicalChecked"
            >{{ 'data.book.titanium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tptd_vanadium_v'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tptd_vanadium_v') && chemicalChecked"
            >{{ 'data.book.vanadium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcd_manufacturer_pipe') && chemicalChecked
            "
            >{{ 'data.book.manufacturers.pipe' | translate }}</clr-dg-column
          >

          <!--                    TENSILE-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_direction') && tensileChecked"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcdd_elongation_per_cent') && tensileChecked
            "
            >{{ 'data.book.elegation.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcdd_initial_gauge_length') && tensileChecked
            "
            >{{ 'data.book.inital.gauge.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_rt_05') && tensileChecked"
            >{{ 'data.book.rt.05' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_sample_num') && tensileChecked"
            >{{ 'data.book.sample.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_sample_type') && tensileChecked"
            >{{ 'data.book.sample.type' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_temperature') && tensileChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcdd_manufacturer_pipe') && tensileChecked
            "
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcdd_uniform_elongation') && tensileChecked
            "
            >{{ 'data.book.uniform.elogation' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_uts_rm_per_cent') && tensileChecked"
            >{{ 'data.book.uts.rm.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_ys_uts') && tensileChecked"
            >{{ 'data.book.ys.uts' | translate }}</clr-dg-column
          >

          <!--                    HIC-->
          <!--                
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_mill_order') && hicChecked">{{'data.book.mill.order' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_mill_item') && hicChecked">{{'data.book.mill.item' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_pipe_heat') && hicChecked">{{'data.book.pipe.heat' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_specimen') && hicChecked">{{'data.book.specimen' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_avg_hic_test') && hicChecked">{{'data.book.test.avg.hic.test' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_avg_scc_test') && hicChecked">{{'data.book.test.avg.ssc.test' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_1_hic_test') && hicChecked">{{'data.book.test.1.hic.test' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_1_last_date') && hicChecked">{{'data.book.test.1.last.date' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_2_hic_test') && hicChecked">{{'data.book.test.2.hic.test' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_2_last_date') && hicChecked">{{'data.book.test.2.last.date' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_3_hic_test') && hicChecked">{{'data.book.test.3.hic.test' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_3_last_date') && hicChecked">{{'data.book.test.3.last.date' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_test_temperature') && hicChecked">{{'data.book.test.temperature' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_standard') && hicChecked">{{'data.book.standard' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_qualification') && hicChecked">{{'data.book.qualification' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_manufacturer_pipe') && hicChecked">{{'data.book.manufacturer.pipe' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tpcdd_condition') && hicChecked">{{'data.book.condition' | translate}}</clr-dg-column> -->

          <!--                    PELM-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_pipe_num') && pelmChecked"
            >{{ 'data.book.pipe.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_plane_mm') && pelmChecked"
            >{{ 'data.book.plane.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_id_45'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_id_45') && pelmChecked"
            >{{ 'data.book.id.45' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_id_90'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_id_90') && pelmChecked"
            >{{ 'data.book.id.90' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_id_135'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_id_135') && pelmChecked"
            >{{ 'data.book.id.135' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_id_180'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_id_180') && pelmChecked"
            >{{ 'data.book.id.180' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_id_max_mm'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_id_max_mm') && pelmChecked"
            >{{ 'data.book.id.max.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_id_min_mm'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_id_min_mm') && pelmChecked"
            >{{ 'data.book.id.min.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_measurement'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_measurement') && pelmChecked"
            >{{ 'data.book.measurement' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_oor_id_max_mm'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_oor_id_max_mm') && pelmChecked"
            >{{ 'data.book.oor.id.max.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_0'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_0') && pelmChecked"
            >{{ 'data.book.wt.0' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_45'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_45') && pelmChecked"
            >{{ 'data.book.wt.45' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_90'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_90') && pelmChecked"
            >{{ 'data.book.wt.90' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_135'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_135') && pelmChecked"
            >{{ 'data.wt.135' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_180'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_180') && pelmChecked"
            >{{ 'data.book.wt.180' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_225'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_225') && pelmChecked"
            >{{ 'data.book.wt.225' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_270'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_270') && pelmChecked"
            >{{ 'data.book.wt.270' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_315'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_315') && pelmChecked"
            >{{ 'data.book.wt.315' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_max_mm'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_max_mm') && pelmChecked"
            >{{ 'data.book.wt.max.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_wt_min_mm'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_wt_min_mm') && pelmChecked"
            >{{ 'data.book.wt.min.mm' | translate }}</clr-dg-column
          >

          <!--                    HARDNESS-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_pipe_heat') && hardnessChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_specimen') && hardnessChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_avg') && hardnessChecked"
            >{{ 'data.book.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_description_hardness') && hardnessChecked
            "
            >{{ 'data.book.description.hardness' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_iw_avg') && hardnessChecked"
            >{{ 'data.book.iw.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_iw1') && hardnessChecked"
            >{{ 'data.book.iw.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_iw2') && hardnessChecked"
            >{{ 'data.book.iw.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_iw3') && hardnessChecked"
            >{{ 'data.book.iw.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_iw4') && hardnessChecked"
            >{{ 'data.book.iw.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mw_avg') && hardnessChecked"
            >{{ 'data.book.mw.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mw1') && hardnessChecked"
            >{{ 'data.book.mw.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mw2') && hardnessChecked"
            >{{ 'data.book.mw.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mw3') && hardnessChecked"
            >{{ 'data.book.mw.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mw4') && hardnessChecked"
            >{{ 'data.book.mw.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_ow_avg') && hardnessChecked"
            >{{ 'data.book.ow.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_ow1') && hardnessChecked"
            >{{ 'data.book.ow.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_ow2') && hardnessChecked"
            >{{ 'data.book.ow.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_ow3') && hardnessChecked"
            >{{ 'data.book.ow.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_ow4') && hardnessChecked"
            >{{ 'data.book.ow.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_manufacturer_pipe') && hardnessChecked
            "
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >

          <!--                    IMPACT-->
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_direction') && impactChecked"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="220"
            [clrDgField]="'tpthd_energy_1_j'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpthd_energy_1_j') && impactChecked"
            >{{ 'data.book.energy.1.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="230"
            [clrDgField]="'tpthd_energy_2_j'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpthd_energy_2_j') && impactChecked"
            >{{ 'data.book.energy.2.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpthd_energy_3_j'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpthd_energy_3_j') && impactChecked"
            >{{ 'data.book.energy.3.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpthd_mean_j'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpthd_mean_j') && impactChecked"
            >{{ 'data.book.mean.j' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_mean_per_cent') && impactChecked"
            >{{ 'data.book.mean.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_sample_num') && impactChecked"
            >{{ 'data.book.sample.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpthd_sample_typesample_type') && impactChecked
            "
            >{{ 'data.book.sample.type' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_shear_1_per_cent') && impactChecked"
            >{{ 'data.book.shear.1.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_shear_2_per_cent') && impactChecked"
            >{{ 'data.book.shear.2.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_shear_3_per_cent') && impactChecked"
            >{{ 'data.book.shear.3.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_test_temperature') && impactChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >

          <!-- ctod
                
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_mill_order')&& ctodChecked" >{{'data.book.mill.order' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_mill_item')&& ctodChecked" >{{'data.book.mill.item' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_pipe_heat')&& ctodChecked" >{{'data.book.pipe.heat' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_specimen')&& ctodChecked" >{{'data.book.specimen' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_avg_ctod')&& ctodChecked" >{{'data.book.test.avg.ctod' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_1_ctod')&& ctodChecked" >{{'data.book.test.1.ctod' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_1_last_date')&& ctodChecked" >{{'data.book.test.1.last.date' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_2_ctod')&& ctodChecked" >{{'data.book.test.2.ctod' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_2_last_date')&& ctodChecked" >{{'data.book.test.2.last.date' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_3_ctod')&& ctodChecked" >{{'data.book.test.3.ctod' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_3_last_date')&& ctodChecked" >{{'data.book.test.3.last.date' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_test_temperature')&& ctodChecked" >{{'data.book.test.temperature' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_ctod_size_bx2b')&& ctodChecked" >{{'data.book.ctod.size.b.2b' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_direction')&& ctodChecked" >{{'data.book.direction' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_standard')&& ctodChecked" >{{'data.book.standard' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_qualification')&& ctodChecked" >{{'data.book.qualification' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_manufacturer_pipe')&& ctodChecked" >{{'data.book.manufacturer.pipe' | translate}}</clr-dg-column>
                    <clr-dg-column [style.width.px]="200" *ngIf="shouldShowColumn('tptd_condition')&& ctodChecked" >{{'data.book.condition' | translate}}</clr-dg-column>
               -->
          <clr-dg-row *clrDgItems="let data of items">
            <!--                        DIMENSIONS-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_pipe_num') && dimensionsChecked"
              >{{ data['tpmpt_pipe_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_supplier') && dimensionsChecked"
              >{{ data['tpmpt_supplier'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_customer_po_num') && dimensionsChecked
              "
              >{{ data['tpmpt_customer_po_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_heat') && dimensionsChecked"
              >{{ data['tpmpt_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_dimension') && dimensionsChecked"
              >{{ data['tpmpt_dimension'] }}</clr-dg-cell
            >
            <!-- <clr-dg-cell *ngIf="shouldShowColumn('tpmpt_mill') && dimensionsChecked">{{data['tpmpt_mill']}}</clr-dg-cell> -->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_item') && dimensionsChecked"
              >{{ data['tpmpt_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_mill_order') && dimensionsChecked"
              >{{ data['tpmpt_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_mill_item') && dimensionsChecked"
              >{{ data['tpmpt_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_od_nom') && dimensionsChecked"
              >{{ data['tpmpt_od_nom'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_wt_nom') && dimensionsChecked"
              >{{ data['tpmpt_wt_nom'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_lenght') && dimensionsChecked"
              >{{ data['tpmpt_lenght'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_weight_bare_kg') && dimensionsChecked
              "
              >{{ data['tpmpt_weight_bare_kg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_lot') && dimensionsChecked"
              >{{ data['tpmpt_lot'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_certificate_num') && dimensionsChecked
              "
              >{{ data['tpmpt_certificate_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_manufacturer_location') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_manufacturer_location'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_pts_pipe_num') && dimensionsChecked
              "
              >{{ data['tpmpt_pts_pipe_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_steel_grade') && dimensionsChecked"
              >{{ data['tpmpt_steel_grade'] }}</clr-dg-cell
            >

            <!--                        CHEMICAL-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_sample_num') && chemicalChecked"
              >{{ data['tptd_sample_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_specimen') && chemicalChecked"
              >{{ data['tpcd_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_al_n') && chemicalChecked"
              >{{ data['tptd_al_n'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tptd_aluminium_total_al') && chemicalChecked
              "
              >{{ data['tptd_aluminium_total_al'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_boron_b') && chemicalChecked"
              >{{ data['tptd_boron_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_ca_s') && chemicalChecked"
              >{{ data['tptd_ca_s'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_calcium_ca') && chemicalChecked"
              >{{ data['tptd_calcium_ca'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_carbon_c') && chemicalChecked"
              >{{ data['tptd_carbon_c'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tptd_carbon_equivalent_ce') && chemicalChecked
              "
              >{{ data['tptd_carbon_equivalent_ce'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_chromium_cr') && chemicalChecked"
              >{{ data['tptd_chromium_cr'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_copper_cu') && chemicalChecked"
              >{{ data['tptd_copper_cu'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_manganese_mn') && chemicalChecked"
              >{{ data['tptd_manganese_mn'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_molybdenum_mo') && chemicalChecked"
              >{{ data['tpcd_molybdenum_mo'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_nb_v_ti') && chemicalChecked"
              >{{ data['tptd_nb_v_ti'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_nickel_ni') && chemicalChecked"
              >{{ data['tptd_nickel_ni'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_niobium_nb') && chemicalChecked"
              >{{ data['tptd_niobium_nb'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_pcm_value') && chemicalChecked"
              >{{ data['tptd_pcm_value'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_nitrogen_n') && chemicalChecked"
              >{{ data['tptd_nitrogen_n'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_phosporus_p') && chemicalChecked"
              >{{ data['tptd_phosporus_p'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_silicon_si') && chemicalChecked"
              >{{ data['tptd_silicon_si'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_sulphur_s') && chemicalChecked"
              >{{ data['tptd_sulphur_s'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_titanium_ti') && chemicalChecked"
              >{{ data['tptd_titanium_ti'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_vanadium_v') && chemicalChecked"
              >{{ data['tptd_vanadium_v'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcd_manufacturer_pipe') && chemicalChecked
              "
              >{{ data['tpcd_manufacturer_pipe'] }}</clr-dg-cell
            >

            <!--                        TENSILE-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_direction') && tensileChecked"
              >{{ data['tpcdd_direction'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcdd_elongation_per_cent') && tensileChecked
              "
              >{{ data['tpcdd_elongation_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcdd_initial_gauge_length') && tensileChecked
              "
              >{{ data['tpcdd_initial_gauge_length'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_rt_05') && tensileChecked"
              >{{ data['tpcdd_rt_05'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_sample_num') && tensileChecked"
              >{{ data['tpcdd_sample_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_sample_type') && tensileChecked"
              >{{ data['tpcdd_sample_type'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcdd_test_temperature') && tensileChecked
              "
              >{{ data['tpcdd_test_temperature'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcdd_manufacturer_pipe') && tensileChecked
              "
              >{{ data['tpcdd_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcdd_uniform_elongation') && tensileChecked
              "
              >{{ data['tpcdd_uniform_elongation'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcdd_uts_rm_per_cent') && tensileChecked
              "
              >{{ data['tpcdd_uts_rm_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_ys_uts') && tensileChecked"
              >{{ data['tpcdd_ys_uts'] }}</clr-dg-cell
            >

            <!--                        PELM-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_pipe_num') && pelmChecked"
              >{{ data['tphe_pipe_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_plane_mm') && pelmChecked"
              >{{ data['tphe_plane_mm'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_id_45') && pelmChecked"
              >{{ data['tphe_id_45'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_id_90') && pelmChecked"
              >{{ data['tphe_id_90'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_id_135') && pelmChecked"
              >{{ data['tphe_id_135'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_id_180') && pelmChecked"
              >{{ data['tphe_id_180'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_id_max_mm') && pelmChecked"
              >{{ data['tphe_id_max_mm'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_id_min_mm') && pelmChecked"
              >{{ data['tphe_id_min_mm'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_measurement') && pelmChecked"
              >{{ data['tphe_measurement'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_oor_id_max_mm') && pelmChecked"
              >{{ data['tphe_oor_id_max_mm'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('tphe_wt_0') && pelmChecked">{{
              data['tphe_wt_0']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_45') && pelmChecked"
              >{{ data['tphe_wt_45'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_90') && pelmChecked"
              >{{ data['tphe_wt_90'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_135') && pelmChecked"
              >{{ data['tphe_wt_135'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_180') && pelmChecked"
              >{{ data['tphe_wt_180'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_225') && pelmChecked"
              >{{ data['tphe_wt_225'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_270') && pelmChecked"
              >{{ data['tphe_wt_270'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_315') && pelmChecked"
              >{{ data['tphe_wt_315'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_max_mm') && pelmChecked"
              >{{ data['tphe_wt_max_mm'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_wt_min_mm') && pelmChecked"
              >{{ data['tphe_wt_min_mm'] }}</clr-dg-cell
            >
            <!--                        HARDNESS-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_pipe_heat') && hardnessChecked"
              >{{ data['tpid_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_specimen') && hardnessChecked"
              >{{ data['tpid_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_avg') && hardnessChecked"
              >{{ data['tpid_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_description_hardness') && hardnessChecked
              "
              >{{ data['tpid_description_hardness'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_iw_avg') && hardnessChecked"
              >{{ data['tpid_iw_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_iw1') && hardnessChecked"
              >{{ data['tpid_iw1'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_iw2') && hardnessChecked"
              >{{ data['tpid_iw2'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_iw3') && hardnessChecked"
              >{{ data['tpid_iw3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_iw4') && hardnessChecked"
              >{{ data['tpid_iw4'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mw_avg') && hardnessChecked"
              >{{ data['tpid_mw_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mw1') && hardnessChecked"
              >{{ data['tpid_mw1'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mw2') && hardnessChecked"
              >{{ data['tpid_mw2'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mw3') && hardnessChecked"
              >{{ data['tpid_mw3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mw4') && hardnessChecked"
              >{{ data['tpid_mw4'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_ow_avg') && hardnessChecked"
              >{{ data['tpid_ow_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_ow1') && hardnessChecked"
              >{{ data['tpid_ow1'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_ow2') && hardnessChecked"
              >{{ data['tpid_ow2'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_ow3') && hardnessChecked"
              >{{ data['tpid_ow3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_ow4') && hardnessChecked"
              >{{ data['tpid_ow4'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_manufacturer_pipe') && hardnessChecked
              "
              >{{ data['tpid_manufacturer_pipe'] }}</clr-dg-cell
            >
            <!--                        IMPACT-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_direction') && impactChecked"
              >{{ data['tpthd_direction'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_energy_1_j') && impactChecked"
              >{{ data['tpthd_energy_1_j'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_energy_2_j') && impactChecked"
              >{{ data['tpthd_energy_2_j'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_energy_3_j') && impactChecked"
              >{{ data['tpthd_energy_3_j'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_mean_j') && impactChecked"
              >{{ data['tpthd_mean_j'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_mean_per_cent') && impactChecked"
              >{{ data['tpthd_mean_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_sample_num') && impactChecked"
              >{{ data['tpthd_sample_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_sample_typesample_type') &&
                impactChecked
              "
              >{{ data['tpthd_sample_typesample_type'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_shear_1_per_cent') && impactChecked
              "
              >{{ data['tpthd_shear_1_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_shear_2_per_cent') && impactChecked
              "
              >{{ data['tpthd_shear_2_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_shear_3_per_cent') && impactChecked
              "
              >{{ data['tpthd_shear_3_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_test_temperature') && impactChecked
              "
              >{{ data['tpthd_test_temperature'] }}</clr-dg-cell
            >
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="sheetFilter !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <clr-select-container>
          <label>Formato</label>
          <select
            clrSelect
            [(ngModel)]="sheetFormat"
          >
            <!-- <option value="1">Pipe Movement Track</option> -->
            <option value="2">Manufacturer Pipe TS</option>
            <option value="3">Pipe Chemical Data</option>
            <option value="4">Liner Chemical Data</option>
            <option value="5">Pipe Impact Data</option>
            <option value="6">Pipe Toughness Data</option>
            <option value="7">Pipe Corrosion Data</option>
            <option value="8">Pipe Tensile Hardness Data</option>
            <!-- <option value="9">Pipe BendTest Data</option> -->
            <!-- <option value="10">Pipe IntCoating Data</option> -->
            <!-- <option value="11">Pipe Coating Data</option> -->
          </select>

          <!-- <clr-control-error *clrIfError="unit.invalid && unit.touched">
                        {{'scope.unit.invalid' | translate}}
                    </clr-control-error> -->
        </clr-select-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="sheetFilter = 'none'"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      (click)="filterSheet()"
      class="btn btn-outline"
    >
      {{ 'application.commons.buttons.apply' | translate }}
    </button>
  </div>
</clr-modal>
