import {
  Component,
  ComponentFactoryResolver,
  Inject,
  ViewChild,
} from '@angular/core';
import '@cds/core/icon/register.js';
import { HttpClient } from '@angular/common/http';

import { DataBookBaseComponent } from '../databook-base.component';
import { ActivatedRoute } from '@angular/router';
import { ConfigHandler } from '../../core/handlers/config.handler';
import { DataBookState } from '../../services/databook.state';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';
import { PdfService } from '../../components/pdf/pdf.service';
import {
  showColumnFieldString as _showColumnFieldString,
  showColumnField as showColumnField,
} from '../../components/utils';
import {
  actions,
  setActionStatus,
  disableButton,
  tables,
  tableHeads,
  TableTypes,
} from './new-tpi.digital.constants';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import moment from 'moment';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import { SafeUrlPipe } from '../../components/safe-url/safe-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigWorkflowModalComponent } from './config-workflow-modal/config-workflow-modal.component';
import { HistoryModalComponent } from './history-modal/history-modal.component';
import { NewTpiDigitalTable } from './table/table.component';
import { FormsModule } from '@angular/forms';
import {
  ClrCommonFormsModule,
  ClrSelectModule,
  ClrInputModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrTabsModule,
  ClrConditionalModule,
  ClrModalModule,
  ClrIconModule,
} from '@clr/angular';
import { NgIf, NgFor } from '@angular/common';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';

declare let gtag;

@Component({
  selector: 'new-tpi-digital',
  templateUrl: './new-tpi-digital.component.html',
  styleUrls: ['./new-tpi-digital.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    BreadcrumbComponent,
    NgIf,
    ClrCommonFormsModule,
    ClrSelectModule,
    FormsModule,
    NgFor,
    ClrInputModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrTabsModule,
    ClrConditionalModule,
    NewTpiDigitalTable,
    ClrModalModule,
    HistoryModalComponent,
    ClrIconModule,
    ConfigWorkflowModalComponent,
    TranslateModule,
    SafeUrlPipe,
  ],
})
export class NewTpiDigitalComponent extends DataBookBaseComponent {
  @ViewChild('modalSesView')
  modalSesView: any;

  documents;
  rawDocuments;
  rawDocumentsToSearch;
  selectedViewDocument;
  selectedDocument = [];
  selectedDocumentStatus = '';
  disableAllButtons = true;
  approved;
  clickedSortNameValue: 'desc' | 'asc' = 'desc';

  selectedColumn = '';
  searchBarText = '';

  tableHeads = tableHeads;

  stepInstance = '';
  actionType;
  isConfigWorkflowOpen = false;
  historyModal = false;
  steps = [];
  nextSteps = [];
  showSteps = [];
  documentFile;
  base64File;
  actions = actions;
  document_name = '';

  constructor(
    private resolver: ComponentFactoryResolver,
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public config: ConfigHandler,
    private http: HttpClient,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      http,
      store,
      userStore,
    );
  }

  onPageInit = async () => {
    await super.initProjectProduct();
    this.configBreadCrumb();
    // TODO: delete in future
    await this.getWorkflows(
      tables.PENDING_TABLE.actions,
      tables.PENDING_TABLE.filters,
    );

    const routeParams = this.activatedRouter.snapshot.paramMap;
    const fileId = routeParams.get('fileId');

    if (fileId && fileId !== 'tpi-flow-mgmt') {
      const found = this.documents.find((doc) => {
        return doc.document_id === fileId;
      });
      if (found) {
        this.viewDocument({
          id_step_instance: found.id_step_instance,
          document_name: found.document_name,
          document_id: found.document_id,
          owner: found.owner,
          action: found.action,
          signature_type: found.signature_type,
        });
      } else {
        this.selectedViewDocument = null;
        this.coreService.showAlertError('Document not found.');
      }
    } else {
      this.selectedViewDocument = null;
    }
  };

  configBreadCrumb = () => {
    this.store.newTpiSelectedType = this.store.activePageForBreadcrumb;
  };

  isReleaseVisible = () => {
    const x = super.getRoleByProject();
    if (
      this.store?.selectedProject?.id &&
      this.store?.selectedProduct?.id &&
      super.getRoleByProject()?.TPI_DIGITAL?.RELEASE
    ) {
      return true;
    }
    return false;
  };

  isApprovalVisible = () => {
    if (
      this.store?.selectedProject?.id &&
      this.store?.selectedProduct?.id &&
      super.getRoleByProject()?.TPI_DIGITAL?.APPROVAL
    ) {
      return true;
    }
    return false;
  };

  isTPIVisible = () => {
    if (
      this.store?.selectedProject?.id &&
      this.store?.selectedProduct?.id &&
      super.getRoleByProject()?.TPI_DIGITAL
    ) {
      return true;
    }
    return false;
  };

  updateDocumentsTable = (table: TableTypes) => {
    this.selectedDocument = [];
    this.selectedDocumentStatus = '';
    this.selectedColumn = '';
    this.searchBarText = '';
    this.disableAllButtons = true;

    this.getWorkflows(tables[table].actions, tables[table].filters);
  };

  isWorkFlowVisible = () => {
    if (
      this.store.selectedProject.gsi_id &&
      this.store?.selectedProduct?.id &&
      super.getRoleByProject()?.TPI_DIGITAL?.RELEASE
    ) {
      return true;
    }
    return false;
  };

  sendGtag = (eventAction) => {
    const param = {
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature: 'TPI page',
      spn_action: eventAction,
      spn_userType: this.coreService.getConfig().currentUser.type,
    };
    gtag('event', eventAction, param);
  };

  getWorkflows = async (actionsValues, filters) => {
    try {
      this.coreService.showLoading();

      const result = await this.dataBookService.listWorkflows({
        tpi_roles_by_project: super.getRoleByProject()?.TPI_DIGITAL,
        currentUser: this.coreService.getConfig().currentUser.email,
        actions: actionsValues,
        project: this.store.selectedProject.gsi_id,
        projects: this.store.projects,
        productId: this.store.selectedProduct.id,
        filters,
      });

      for (const doc of result.documents) {
        doc.comment = this.getComment(doc) + this.getPath(doc);
        doc.deadline = this.getDocDeaLine(doc);
        doc.created_on = this.formatDate(doc.created_on);
        doc.shakeholder = doc.shakeholder ? doc.shakeholder : doc.started_by;
        doc.action = this.setStatus(doc.action);
      }

      this.documents = result.documents;
      this.rawDocuments = this.documents;
      this.createRawDocumentsToSearch();

      this.dataBookState.documents = this.documents;
    } catch (error) {
      this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  setStatus = (action: string) => {
    return setActionStatus[action];
  };

  getDocDeaLine = (doc) => {
    const final = moment(doc.created_on).toDate();
    const now = moment().utc();
    const diffDays = now.diff(final, 'days');
    if (diffDays > doc.delay_day && doc.delay_day && doc.action === 'SEND_TO') {
      return '⚠️ Late';
    }
    return '';
  };

  disableButton = (action: string, doc?): boolean => {
    const isCancelOrTransfer = action === 'TRANSFER' || action === 'CANCEL';

    const { email } = this.coreService.getConfig().currentUser;

    if (doc) {
      if (
        !isCancelOrTransfer &&
        email !== doc.owner &&
        !this.isReleaseVisible()
      ) {
        return true;
      }

      return disableButton[action](doc.action) || false;
    }

    if (this.disableAllButtons || !this.selectedDocumentStatus || !action) {
      return true;
    }

    const documentOwnerAndUserIsDifferent = this.selectedDocument.find(
      ({ owner }) => email !== owner,
    );

    if (
      !isCancelOrTransfer &&
      documentOwnerAndUserIsDifferent &&
      !this.isReleaseVisible()
    ) {
      return true;
    }

    const result = disableButton[action](this.selectedDocumentStatus);
    return result || false;
  };

  getUrlFile = async (doc, inline, contentType) => {
    const params = {
      getObject: 'tpi_digital',
      fileId: doc.document_id,
      name: doc.document_name,
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
      contentType,
      inline,
    };
    this.cfgVlr(params);
    const result = await this.dataBookService.documentPreSignedUrl(params);
    return result;
  };

  formatDate = (date: string) => this.coreService.formatDate(date);

  redirect = (statusItem) => {
    this.store.gotoPage('new-tpi-digital/tpi-flow-mgmt');
    /*this.coreService
      .getRouter()
      .navigate(['/main/new-tpi-digital/tpi-flow-mgmt']);*/
  };

  downloadMass = async () => {
    for (const doc of this.selectedDocument) {
      await this.download(doc);
    }
  };

  download = async (doc) => {
    try {
      this.coreService.showLoading();

      if (doc.signature_type === 'LACUNA_SES') {
        const result = await this.dataBookService.signature({
          downladSigneDoc: doc.document_id,
          preview: false,
          productId: this.store.selectedProduct.id,
        });
        // Used to download base64 files
        // const linkSource = `data:application/pdf;base64,${result.bytes}`;

        const filtered = _.find(this.rawDocuments, (item) => {
          return item.document_id === doc.document_id;
        });

        const downloadLink = document.createElement('a');
        downloadLink.href = result;
        downloadLink.download = filtered.document_description;
        downloadLink.click();
      } else {
        const result = await this.getUrlFile(doc, false, null);
        const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
        a.download = doc.document_name;
        a.href = result.url;
        a.click();
      }

      this.sendGtag('download');
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  downloadFile = async (url) => {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'blob' as 'json' }).subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const file = new Blob(binaryData, { type: dataType });
          resolve(file);
        },
        (err) => {
          err.error.status = err.status ? err.status : 500;
          reject(err.error);
        },
      );
    });
  };

  downloadAdditional = async (id, nameVal) => {
    try {
      this.coreService.showLoading();

      const params = {
        getObject: 'tpi_digital_additional',
        fileId: id,
        name: nameVal,
        productId: this.store.selectedProduct.id,
      };

      this.cfgVlr(params);
      const result = await this.dataBookService.documentPreSignedUrl(params);

      const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
      a.download = nameVal;
      a.href = result.url;
      a.click();
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  public cfgVlr = (params) => {
    const vlr = this.dataBookService.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  };

  closeViewDocument = () => {
    this.modalSesView.closable = true;
    this.modalSesView.close();
  };

  openViewDocument = () => {
    this.modalSesView.open();
    this.modalSesView.closable = true;
  };

  viewDocument = async (doc) => {
    try {
      this.coreService.showLoading();

      this.selectedViewDocument = doc;

      this.openViewDocument();

      if (this.selectedViewDocument.signature_type === 'LACUNA_SES') {
        const result = await this.dataBookService.signature({
          downladSigneDoc: doc.document_id,
          preview: true,
          productId: this.store.selectedProduct.id,
        });
        // this.base64File = `data:application/pdf;base64,${result}`;
        this.documentFile = result;
        this.base64File = null;
      } else {
        const result = await this.getUrlFile(doc, true, 'application/pdf');
        this.documentFile = result.url + '#toolbar=0&navpanes=0&scrollbar=0';
        this.base64File = null;
      }

      this.sendGtag('view');
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  upload = () => {
    this.dataBookState.idStepInstances = null;
    this.dataBookState.stepAction = null;
    this.redirect(null);
  };

  select = (type: string, doc?: any) => {
    let action = null;

    if (doc) {
      this.dataBookState.idStepInstances = [doc.id_step_instance.toString()];
      action = doc.action;
    } else {
      this.dataBookState.idStepInstances = this.selectedDocument.map(
        ({ id_step_instance }) => id_step_instance.toString(),
      );

      action = this.selectedDocument[0]?.action || null;
    }

    this.dataBookState.stepAction = type;
    if (type === 'ACKNOWLEDGE') {
      this.dataBookState.acknowledgeComment = this.selectedDocument.map(
        ({ comment }) => comment,
      );
    } else {
      this.dataBookState.acknowledgeComment = null;
    }

    this.selectedViewDocument = {};

    this.redirect(action);
  };

  selectItemDashBoard = (card) => {
    this.dataBookState.idStepInstances = null;
    this.dataBookState.stepAction = null;
    this.store.gotoPage(card);
    // this.coreService.getRouter().navigate(['/main/' + card]);
  };

  handleSelectedDocument = (event) => {
    this.selectedDocument = event;

    this.disableAllButtons = true;

    const documentActions = this.selectedDocument.map(({ action }) => action);

    // check if checkboxes has different eventsActions selected
    const buttonsWithDiffActions = new Set(documentActions).size !== 1;

    const documentFromDiffProjectOrProduct = this.selectedDocument.find(
      (item) => item.product_id !== this.dataBookState.product,
    );

    if (
      !buttonsWithDiffActions &&
      (!documentFromDiffProjectOrProduct ||
        documentFromDiffProjectOrProduct?.length === 0)
    ) {
      this.disableAllButtons = false;
    }

    this.selectedDocumentStatus = documentActions[0];
  };

  sort = (column) => {
    this.selectedColumn = column;

    this.clickedSortNameValue =
      this.clickedSortNameValue === 'desc' ? 'asc' : 'desc';

    const sort = _.orderBy(
      this.documents,
      [column],
      [this.clickedSortNameValue],
    );
    this.sortChildren(sort, column);
    this.documents = sort;
  };

  sortChildren = (sortTemp, column) => {
    if (!sortTemp?.length) return;

    for (const item of sortTemp) {
      if (item.children && item.children.length) {
        item.children = _.orderBy(
          item.children,
          [column],
          [this.clickedSortNameValue],
        );
        this.sortChildren(item.children, column);
      }
    }
  };

  exportToExcelList = () => {
    const columns = [];
    columns.push(
      this.coreService.translate(
        'databook.new.tpi.digital.workflow.name',
        null,
      ),
    );
    columns.push(this.coreService.translate('databook.document.project', null));
    columns.push(this.coreService.translate('databook.document.product', null));
    columns.push(this.coreService.translate('databook.document.name', null));
    columns.push(
      this.coreService.translate('databook.document.revision', null),
    );
    columns.push(this.coreService.translate('databook.file.status', null));
    columns.push(this.coreService.translate('databook.custom', null));
    columns.push(this.coreService.translate('databook.document.code', null));
    columns.push(this.coreService.translate('databook.uploaded.by', null));
    columns.push(this.coreService.translate('databook.uploaded.on', null));
    columns.push(
      this.coreService.translate('databook.document.pending.action.by', null),
    );
    columns.push(
      this.coreService.translate('databook.document.deadline', null),
    );
    columns.push(this.coreService.translate('databook.lastComment', null));

    const transformed = this.documents.map((doc) => {
      const commentVal = this.getComment(doc) + this.getPath(doc);
      return {
        name: doc.name,
        project_name: doc.project_name,
        product_name: doc.product_name,
        document_name: doc.document_name,
        document_revision: doc.document_revision,
        status: doc.action,
        custom: doc.custom,
        document_code: doc.document_code,
        started_by: doc.started_by,
        uploaded_on: doc.created_on,
        pending_action_by: doc.shakeholder ? doc.shakeholder : doc.user_email,
        deadline: doc.deadline,
        comment: commentVal && commentVal !== 'null' ? commentVal : '',
      };
    });

    this.exportToExcel(columns, 'list.xlsx', transformed);
  };

  exportToExcelHistory = () => {
    const columns = [];
    columns.push(this.coreService.translate('tpi.utc.time', null));
    columns.push(this.coreService.translate('tpi.user', null));
    columns.push(this.coreService.translate('tpi.event', null));
    columns.push(this.coreService.translate('tpi.comment', null));

    const transformed = this.steps.map((step) => ({
      created_on: step.created_on,
      created_by: step.created_by,
      action: step.action,
      comment: step.comment,
    }));

    this.exportToExcel(columns, 'history.xlsx', transformed);
  };

  exportToExcel = (columns, fileName, transformed) => {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformed);
    XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: 'A1' });

    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };

    const options: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    XLSX.writeFile(workbook, fileName, options);

    this.coreService.showAlertSuccessTranslate(
      'application.commons.success.operation',
    );
  };

  getComment = (doc) => {
    if (
      doc.action === 'SEND_TO' ||
      doc.action === 'BACK_RELEASER_APPROVAL' ||
      doc.action === 'BACK_RELEASER_REJECT'
    ) {
      return doc.last_comment ? doc.last_comment : '';
    }

    return doc.comment ? doc.comment : '';
  };

  getPath = (doc) => {
    if (doc.store_path && doc.store_path.length) {
      return ' - Stored on: ' + doc.store_path;
    }

    return '';
  };

  openHistory = async (doc) => {
    try {
      this.coreService.showLoading();

      const result = await this.dataBookService.tpiFlowInfo({
        currentStep: doc.id_step_instance.toString(),
        userWf: this.userStore.user?.email,
        productId: this.store.selectedProduct.id,
      });

      this.steps = result.steps;
      this.showSteps = result.steps;
      this.showSteps[0].comment = this.showSteps[0].document_name;
      this.nextSteps = result.nextSteps;
      this.document_name = doc.document_name;

      this.historyModal = true;
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  mergeSteps = () => {
    if (this.showSteps.length === this.steps.length) {
      this.showSteps = [...this.steps, ...this.nextSteps];
      return;
    }

    this.showSteps = this.steps;
  };

  closeHistory = () => {
    this.historyModal = false;
  };

  gotToConfigWorkflow = () => {
    this.isConfigWorkflowOpen = true;
  };

  closeConfigWorkflow = () => {
    this.isConfigWorkflowOpen = false;
  };

  createRawDocumentsToSearch = async () => {
    this.rawDocumentsToSearch = [];
    for (const item of this.rawDocuments) {
      this.rawDocumentsToSearch.push(JSON.parse(JSON.stringify(item)));
    }
  };

  handleSearchBar = () => {
    if (this.selectedColumn.trim() === '') return;

    const searchTextValue = this.searchBarText.trim();

    if (!searchTextValue) {
      this.documents = this.rawDocuments;
      return;
    }

    this.documents = this.rawDocumentsToSearch.reduce(
      (filtered, values, idx) => {
        const column = this.rawDocuments[idx][this.selectedColumn];
        const contains = column
          ?.toLowerCase()
          .includes(searchTextValue.toLowerCase());

        values[this.selectedColumn] = column;

        if (contains) {
          values[this.selectedColumn] = this.highlight(column, searchTextValue);
          filtered.push(values);
        }
        return filtered;
      },
      [],
    );
  };

  highlight = (inputText, searchText) => {
    const index = inputText.toLowerCase().indexOf(searchText.toLowerCase());
    inputText =
      inputText.substring(0, index) +
      '<span class="highlightSearch">' +
      inputText.substring(index, index + searchText.length) +
      '</span>' +
      inputText.substring(index + searchText.length);
    return inputText;
  };

  showColumnFieldString = (name: string): string => {
    return _showColumnFieldString(name, 15);
  };

  showColumnField = (name: string): boolean => showColumnField(name, 15);

  getSvg(icon: string) {
    return `assets/icons/${icon}.svg`;
  }
}
