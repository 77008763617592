<clr-modal
  [clrModalOpen]="isModalOpen"
  [clrModalSize]="'xl'"
  [clrModalStaticBackdrop]="true"
  [clrModalClosable]="false"
>
  <div
    class="modal-body"
    *ngIf="isReleaseVisible()"
  >
    <div
      class="clr-col-md-12 clr-col-12 tpi-header"
      style="display: none"
    >
      <clr-toggle-container>
        <clr-toggle-wrapper>
          <input
            class="nav-text"
            type="checkbox"
            clrToggle
            name="sequential"
            value="option1"
            [(ngModel)]="sequential"
          />
          <label>
            {{ 'application.commons.text.sequential' | translate }}</label
          >
        </clr-toggle-wrapper>
      </clr-toggle-container>
    </div>

    <div class="val-card clr-col-12">
      <div class="title-container">
        <label class="session-title">Workflows</label>
        <button
          type="button"
          class="btn btn-primary"
          (click)="newWorkflow()"
          id="newWfButtonId"
          *ngIf="showNewWorkflowButton"
        >
          New Workflow
        </button>
      </div>
      <div
        class="clr-row grid-row-padding"
        id="workflowListDataGridId"
        class="table-list"
      >
        <clr-datagrid class="val-datagrid">
          <clr-dg-column>
            {{ 'databook.new.tpi.digital.workflow.name' | translate }}
          </clr-dg-column>
          <clr-dg-column>
            {{ 'databook.new.tpi.digital.project' | translate }}
          </clr-dg-column>
          <clr-dg-column [style.min-width.px]="350">
            {{ 'databook.new.tpi.digital.created.by' | translate }}
          </clr-dg-column>
          <clr-dg-column>
            {{ 'databook.new.tpi.digital.created.on' | translate }}
          </clr-dg-column>
          <clr-dg-column>
            {{ 'databook.new.tpi.digital.steps' | translate }}
          </clr-dg-column>
          <clr-dg-column>
            {{ 'databook.new.tpi.digital.signatories' | translate }}
          </clr-dg-column>

          <clr-dg-row *ngFor="let item of workflowData; let i = index">
            <clr-dg-cell id="nameIdConfigWfId">{{ item.name }}</clr-dg-cell>
            <clr-dg-cell>{{ item.project_name }}</clr-dg-cell>
            <clr-dg-cell [style.min-width.px]="350">{{
              item.created_updated_by
            }}</clr-dg-cell>
            <clr-dg-cell>{{ formatDate(item.created_updated_on) }}</clr-dg-cell>
            <clr-dg-cell>{{ item.steps.length }}</clr-dg-cell>
            <clr-dg-cell>
              <button class="icon-btn">
                <clr-icon
                  id="pencilIdIcon"
                  [ngClass]="{ wf_test_edit: item.name === 'WF_TEST' }"
                  shape="pencil"
                  title="Edit"
                  (click)="
                    openEditWorkflowModal(
                      item.id,
                      item.version,
                      item.name,
                      item.steps
                    )
                  "
                >
                </clr-icon>
              </button>
              <button class="icon-btn">
                <clr-icon
                  [ngClass]="
                    item.name === 'WF_TEST'
                      ? 'wf_test_delete'
                      : item.name === 'WF_TEST_SES'
                        ? 'wf_test_delete_ses'
                        : ''
                  "
                  shape="trash"
                  class="is-error"
                  title="Delete"
                  (click)="openDeleteWorkflowModal(item.id)"
                >
                </clr-icon>
              </button>
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
      <div class="text-right label-tiny">{{ workflowData?.length }} Flows</div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
      id="closeWorkfloListButtonId"
    >
      CLOSE
    </button>
  </div>
</clr-modal>
