import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataBookMaterialMgmtComponent } from '../pages/material-mgmt/material-mgmt.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { MaterialComponent } from '../pages/material/material.component';
import { pipeNavigatorRouteNames } from '../enums/routes.enum';
import { UploadTestComponent } from '../pages/upload-test/upload-test.component';

export const materials = [
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/:materialId/upload-test',
    component: UploadTestComponent,
    data: { routeName: pipeNavigatorRouteNames.uploadTest },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/materials',
    component: DataBookMaterialMgmtComponent,
    data: { routeName: pipeNavigatorRouteNames.materialMgmt },
    resolve: {
      data: ProjectListResolver,
    },
  },
  /*{
        canActivate: [AuthenticateGuard],
        path: ':projectSlug/:productSlug/material',
        component: MaterialComponent,
        data: {routeName: pipeNavigatorRouteNames.materialSelection},
        resolve: {
            data: ProjectListResolver,
        },
    },*/
];
