import {
  TreeviewFileItem,
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../treeview-item.class';
import { plainToInstance } from 'class-transformer';
import { folderGetFiles } from './folder-get-files';
import { DataBookService } from '../../services/databook.service';

export class FolderNameAndId {
  id: number;
  name: string;
  description: string;
  treeview_context: TreeviewItemContext;
  product_id: number;
  phase: boolean;
  fixed: boolean;
  parent_id: string;
}

export const getRootFoldersForFrontEnd = async (
  dataBookService: DataBookService,
  productId: number,
  context: TreeviewItemContext,
): Promise<FolderNameAndId[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getRootFoldersForFrontEnd',
    args: {
      productId,
      context,
    },
  };
  const foldersNamesAndIds: FolderNameAndId[] =
    await dataBookService.newLambda<FolderNameAndId[]>(params);
  // Sort the foldersNamesAndIds alphabetically by the name field
  foldersNamesAndIds.sort((a: FolderNameAndId, b: FolderNameAndId) =>
    a.name.localeCompare(b.name),
  );
  return foldersNamesAndIds;
};

export class FolderContent {
  files: TreeviewFileItem[] = [];
  folders: FolderNameAndId[] = [];
}

export const getFolderContentForFrontEnd = async (
  dataBookService: DataBookService,
  folderId: number,
): Promise<FolderContent> => {
  const params: { functionName; args: any } = {
    functionName: 'getFolderContentForFrontEnd',
    args: {
      folderId,
    },
  };
  return await dataBookService.newLambda<FolderContent>(params);
};
