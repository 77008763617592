import { DataBookService } from '../../services/databook.service';
import { UserDTO } from '../user.dto';

export const getUser = async (
  dataBookService: DataBookService,
  email: string,
): Promise<UserDTO> => {
  const params: { functionName; args: any } = {
    functionName: 'assignPermissionToUser',
    args: {
      email,
    },
  };
  return await dataBookService.newLambda<UserDTO>(params);
};

export const getUsers = async (
  dataBookService: DataBookService,
): Promise<UserDTO[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getUsers',
    args: {},
  };
  return await dataBookService.newLambda<UserDTO[]>(params);
};

export const removeUser = async (
  dataBookService: DataBookService,
  userId: number,
): Promise<UserDTO[]> => {
  const params: { functionName; args: any } = {
    functionName: 'removeUser',
    args: {
      userId,
    },
  };
  return await dataBookService.newLambda<UserDTO[]>(params);
};

export const createUser = async (
  dataBookService: DataBookService,
  email: string,
): Promise<UserDTO[]> => {
  const params: { functionName; args: any } = {
    functionName: 'createUser',
    args: {
      email,
    },
  };
  return await dataBookService.newLambda<UserDTO[]>(params);
};
