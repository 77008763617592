import { twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'databook.dataexplorer.column.title.nominal.data',
  headerClass: 'custom-header-pinned',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      children: [
        {
          field: 'databook.dataexplorer.column.title.grade',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['grade']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      columnGroupShow: 'open',
      children: [
        {
          field: 'databook.dataexplorer.column.title.mill.order',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['order']],
        },
        {
          field: 'databook.dataexplorer.column.custom.order',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['customer_order']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      children: [
        {
          field: 'databook.dataexplorer.column.title.od.nominal',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['nominal_od']]),
          valueGetter: (params: any) => params.data[COLUMNS['nominal_od']],
        },
        {
          field: 'databook.dataexplorer.column.title.wt.nominal',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['nominal_wt']]),
          valueGetter: (params: any) => params.data[COLUMNS['nominal_wt']],
        },
      ],
    },
  ],
};
