<vic-base-page page="boxplot">
  <div class="header-actions container"></div>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>
    <br /><br />

    <div class="val-card clr-col-12">
      <div class="clr-row">
        <div class="clr-col-12 toolbar">
          <div class="item-toolbar">
            <label class="label-small">{{
              'plp.data.analysis.test' | translate
            }}</label>
            &nbsp;&nbsp;
            <select
              class="input"
              [(ngModel)]="selectedTest"
              #keyTestX
              (change)="testeChange()"
            >
              <option
                *ngFor="let test of tests"
                [ngValue]="test.description"
              >
                <label class="label-small">{{
                  test.description | translate
                }}</label>
              </option>
            </select>
          </div>

          <div
            *ngIf="selectedTest"
            class="item-toolbar"
          >
            <label class="label-small">{{
              'plp.data.analysis.property.x' | translate
            }}</label>
            &nbsp;&nbsp;
            <select
              class="input"
              [(ngModel)]="selectedX"
              (change)="propertyChange()"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let prop of propertyX"
                [ngValue]="prop.name"
              >
                <label class="label-small">{{ prop.description }}</label>
              </option>
            </select>
          </div>

          <div
            *ngIf="selectedX"
            class="item-toolbar"
          >
            <label class="label-small">
              {{ 'plp.data.analysis.property.y' | translate }}
            </label>
            <clr-dropdown class="dropdown">
              <button
                class="btn-max btn button-secondary"
                clrDropdownTrigger
              >
                <label class="label-small">
                  {{ 'application.commons.text.select' | translate }}
                </label>

                <clr-icon shape="caret down"></clr-icon>
              </button>
              <ng-template [(clrIfOpen)]="dropdownOpen">
                <clr-dropdown-menu clrPosition="bottom">
                  <!-- Select all items -->
                  <label class="dropdown-item">
                    <input
                      type="checkbox"
                      clrCheckbox
                      name="selectAll"
                      [(ngModel)]="selectedAll"
                      (change)="selectAllToggle()"
                    />
                    <ng-container *ngIf="!selectedAll; else elseTemplate">
                      {{ 'application.commons.text.select.all' | translate }}
                    </ng-container>
                    <ng-template #elseTemplate>
                      {{ 'application.commons.text.unselect.all' | translate }}
                    </ng-template>
                  </label>

                  <!-- Options -->
                  <div *ngFor="let prop of propertyY">
                    <label class="dropdown-item">
                      <input
                        type="checkbox"
                        clrCheckbox
                        name="prop.description"
                        [(ngModel)]="checkY[prop.name]"
                      />
                      {{ prop.description }}
                    </label>
                  </div>
                </clr-dropdown-menu>
              </ng-template>
            </clr-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="hasPropertiesSelected"
      class="clr-row"
    >
      <databook-filter>
        <div *ngFor="let filter of filters">
          <div>
            <databook-filter-column
              (change)="generateGraphicsGtag()"
              [label]="filter.title.description"
              [columns]="filter.columns"
            >
            </databook-filter-column>
          </div>
        </div>
      </databook-filter>
      <div class="clr-row clr-col">
        <div
          class="val-card"
          [ngClass]="{ 'clr-col-12': !showTool, 'clr-col-9': showTool }"
        >
          <div *ngIf="!errorTest">
            <div class="clr-row clr-justify-content-end">
              <databook-info
                [title]="
                  'databook.data.analysis.boxplot.info.title' | translate
                "
                [body]="
                  'databook.data.analysis.boxplot.info.description' | translate
                "
                [position]="'bottom-right'"
              ></databook-info>
              <button
                type="button"
                class="btn btn-icon btn-icon-card"
                aria-label="editing"
                (click)="toggleShowTool()"
              >
                <clr-icon shape="cog"></clr-icon>
              </button>
            </div>
            <div id="chart"></div>
          </div>
          <span
            *ngIf="errorTest"
            class="clr-subtext"
            >{{ errorTest }}</span
          >
        </div>

        <databook-tool
          *ngIf="showTool"
          class="clr-col-3"
          [settingsChart]="settings"
          (onChangeTools)="onChangeTools()"
        ></databook-tool>
      </div>
    </div>
  </div>
</vic-base-page>
