import { Component, Input } from '@angular/core';
import {
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrDropdownModule,
  ClrIconModule,
  ClrConditionalModule,
} from '@clr/angular';

@Component({
  selector: 'databook-info',
  templateUrl: './info.component.html',
  styleUrls: ['info.component.scss'],
  standalone: true,
  imports: [
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrDropdownModule,
    ClrIconModule,
    ClrConditionalModule,
  ],
})
export class InfoComponent {
  infoOpen = false;

  @Input() title;

  @Input() body;

  @Input() position;
}
