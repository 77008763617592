import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const removeFolder = async (
  dataBookService: DataBookService,
  folderId: number,
): Promise<boolean> => {
  const params: { functionName; args: any } = {
    functionName: 'removeFolder',
    args: {
      folderId,
      whoRemoved: dataBookService.userStore.user.email,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};
