import { DataBookService } from '../../services/databook.service';

export async function getLatestRevisionFilesWithPath(
  dataBookService: DataBookService,
  productId: number,
  projectId: number,
) {
  return dataBookService.newLambda<DatabookFile[]>({
    functionName: 'getLatestRevisionFilesWithPath',
    args: { productId, projectId },
  });
}
