const VIEW_MODE_TREEVIEW = 'treeview';
const VIEW_MODE_TABLE = 'table';
const DATABOOK_VIEW_STORAGE_ITEM = 'databook-view';

export default class DatabookViewMode {
  static isTreeview() {
    return DatabookViewMode.get() === VIEW_MODE_TREEVIEW;
  }

  static toggle() {
    const viewMode =
      DatabookViewMode.get() === VIEW_MODE_TREEVIEW
        ? VIEW_MODE_TABLE
        : VIEW_MODE_TREEVIEW;

    DatabookViewMode.set(viewMode);

    return viewMode;
  }

  static get() {
    return (
      localStorage.getItem(DATABOOK_VIEW_STORAGE_ITEM) || VIEW_MODE_TREEVIEW
    );
  }

  private static set(viewMode: string) {
    localStorage.setItem(DATABOOK_VIEW_STORAGE_ITEM, viewMode);
  }

  static remove() {
    localStorage.removeItem(DATABOOK_VIEW_STORAGE_ITEM);
  }
}
