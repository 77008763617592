import { Project } from '../project.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const addProject = async (
  dataBookService: DataBookService,
  name,
  code,
  poNumber,
  poDate,
  ownerEmail,
  ownerName,
  customer,
  lastModifiedBy,
  lastModifiedDate,
  description?,
  urlCode?,
  olddataexplorerintegration?: boolean,
  deleted?: boolean,
  oldId?: string,
): Promise<Project | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'addProject',
    args: {
      name,
      code,
      poNumber,
      poDate,
      ownerEmail,
      ownerName,
      customer,
      lastModifiedBy,
      lastModifiedDate,
      description,
      urlCode,
      olddataexplorerintegration,
      deleted,
      oldId,
    },
  };
  return await dataBookService.newLambda<Project | undefined>(params);
};
