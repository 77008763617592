<config-workflow
  *ngIf="isConfigWorkflowOpen"
  [isModalOpen]="isConfigWorkflowOpen"
  [isReleaseVisible]="isReleaseVisible"
  [newWorkflow]="newWorkflow"
  [workflowData]="workflowData"
  [openEditWorkflowModal]="openEditWorkflowModal"
  [openDeleteWorkflowModal]="openDeleteWorkflowModal"
  [showNewWorkflowButton]="showNewWorkflowButton"
  [formatDate]="formatDate"
  [closeModal]="closeModal"
></config-workflow>

<workflow-modal
  *ngIf="childModal.workflow"
  [isModalOpen]="childModal.workflow"
  [closeModal]="closeChildModal"
  [title]="modalTitle"
  [type]="workflowType"
  [form]="workflowForm"
  [stepData]="stepData"
  [editValStep]="editValStep"
  [save]="save"
  [update]="update"
  [updateStepForm]="updateStepForm"
  [reorder]="reorder"
  [newStep]="newStep"
></workflow-modal>

<workflow-step-modal
  *ngIf="childModal.workflowStep"
  [isModalOpen]="childModal.workflowStep"
  [closeModal]="closeNewWorkflowStepModal"
  [title]="modalTitle"
  [type]="workflowStepType"
  [form]="workflowStepForm"
  [deleteStepInWorkflowModal]="deleteStepInWorkflowModal"
  [editStep]="editStep"
  [addNewStep]="addNewStep"
></workflow-step-modal>

<open-mail-modal
  *ngIf="childModal.openEmailConfirm"
  [isModalOpen]="childModal.openEmailConfirm"
  [closeModal]="closeChildModal"
  [getEmailMessage]="getEmailMessage"
  [type]="openEmailType"
  [confirmEmail]="confirmEmail"
  [confirmEditEmail]="confirmEditEmail"
></open-mail-modal>

<clr-modal
  [clrModalOpen]="openConfirm"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        {{ 'confirm.delete.upload.file' | translate }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelExclusion()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="deleteStepInWorkflowModal()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>
