import * as _ from 'lodash';
import { DataBookBaseComponent } from '../databook-base.component';
import * as XLSX from 'xlsx';
import { Directive } from '@angular/core';
import moment from 'moment';
declare let gtag;
@Directive()
export class PlpBaseComponent extends DataBookBaseComponent {
  columnsToggle = [];
  public pipeFilter;
  public heatFilter;
  public itemsCache;
  public items;
  public header = [];
  public headerName = [];
  openModalIRN = false;
  public irn;

  public testItem;
  public columnsRange;
  public irnCache;

  public async getColumnNames(language) {
    return { tests: [] };
  }

  async onPageInit() {
    this.coreService.showLoading();
    this.configBreadCrumb();
    try {
      const columns = await this.getColumnNames(this.coreService.getLanguage());
      if (this.store.materialId) {
        const params = {
          materialId: this.store.materialId,
          test: this.getTest(),
          productId: this.store.selectedProduct.id,
        };
        this.cfgVlr(params);
        const response = await this.dataBookService.getTestsResultPLP(params);
        this.fetchData(response);
      } else {
        const msg = this.coreService.translate(
          'dataExplorer.databook.dataExplorer.no.data.modal.message',
        );
        this.coreService.showAlertInfo(msg);
      }
      await this.mountFilterArrays(columns.tests);
    } catch (error) {
      console.log(error);
      this.coreService.processError(error);
    }
    this.coreService.hideLoading();
  }
  closeModal() {
    this.openModalIRN = false;
  }

  shouldShowColumn(columnName) {
    const column = _.find(this.columnsToggle, (_column) => {
      return _column.name === columnName;
    });
    const x = column ? column.checked : true;
    return x;
  }
  async loadIRNData(data) {
    this.irn = [];
    const params = {
      project: this.store.selectedProject.gsi_id,
      projectId: this.store.selectedProject.id,
      test: 'irn',
      irn: data.Quality_Certificate_Num,
    };
    this.cfgVlr(params);
    const response = await this.dataBookService.getTestsResultPLP(params);
    this.fetchIrnData(response);
    this.openModalIRN = true;
  }

  async sendGtag(eventAction) {
    const param = {
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature: 'Data explorer',
      spn_action: eventAction,
      spn_userType: this.coreService.getConfig().currentUser.type,
      // feature:  'Data explorer',
      // category: 'action',
      // event: eventAction,
      // test: this.getTest(),
      // pageName: '/main/dataExplorer',
      // userType: this.coreService.getConfig().currentUser.type,
      // project: this.dataBookState.project
    };
    gtag('event', eventAction, param);
  }

  private hasItems() {
    return this.itemsCache && this.itemsCache.length;
  }

  exportToExcel() {
    const newItems = [];
    const availible = [];
    for (const item of this.items) {
      const newItem = {};
      for (const item1 of this.columnsToggle) {
        if (item1.checked && item1.name !== 'Type') {
          newItem[item1.name] = item[item1.name];
          if (!availible.includes(item1.decription)) {
            availible.push(item1.description);
          }
        }
      }
      newItems.push(newItem);
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newItems);
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.r; C <= range.e.r; ++C) {
      const address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
      if (!worksheet[address]) {
        continue;
      }
      worksheet[address].v = availible[C];
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };
    const options: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    XLSX.writeFile(workbook, this.getNameFile('.xlsx'), options);
  }

  getNameFile(extension) {
    return (
      this.dataBookState.project.toLowerCase() +
      '_' +
      this.getTest().toLowerCase() +
      '_test_' +
      this.getDateNow() +
      extension
    );
  }
  getDateNow() {
    const format = this.getFormat();
    const dateMoment = moment(Date.now()).format(format);
    return dateMoment;
  }

  getFormat() {
    let format = 'DD_MM_YYYY';
    if (this.coreService.getLanguage() !== 'pt-BR') {
      format = 'MM_DD_YYYY';
    }
    return format;
  }

  exportToPdf() {
    const borders = [false, false, false, true];
    const headerItems = [];
    const widthsLenght = [];
    for (const item of this.columnsToggle) {
      if (item.checked && item.name !== 'Type') {
        headerItems.push({
          text: item.description,
          name: item.name,
          border: borders,
          style: 'tableHeaderStyle',
        });
        widthsLenght.push('*');
      }
    }
    const bodyInfo = [];
    bodyInfo.push(headerItems);

    for (const item of this.items) {
      const rowInfo = [];
      for (const key of headerItems) {
        const attrValue = item[key.name];
        rowInfo.push({ text: attrValue, alignment: 'center' });
      }
      bodyInfo.push(rowInfo);
    }

    const contentInfo = [
      {
        style: 'tableStyle',
        table: {
          headerRows: 1,
          // widths: widthsLenght,
          body: bodyInfo,
        },
        layout: {
          defaultBorder: true,
        },
      },
    ];

    this.pdfService.exportAsPdfFilePageSize(
      'Report',
      contentInfo,
      'report',
      true,
      this.getPageSize(),
      this.getLineSize(),
      null,
      null,
      this.getNameFile('.pdf'),
    );
  }

  public getPageSize() {
    return '1700-1137';
  }

  public getLineSize() {
    return 1650;
  }
  mountFilterArrays(columnNames) {
    this.columnsRange = this.mountFilterRangeObjects(columnNames);
    this.columnsToggle = this.mountColumnsToggle(columnNames);
  }

  isMillOrderOrMillItem(column) {
    return (
      column &&
      column.name &&
      (column.name.toUpperCase() === 'MILL_ORDER' ||
        column.name.toUpperCase() === 'MILL_ITEM')
    );
  }

  mountColumnsToggle(columnNames) {
    return _.map(columnNames, (column: any) => {
      return {
        ...column,
        checked: this.isMillOrderOrMillItem(column) ? false : true,
      };
    });
  }

  mountFilterRangeObjects(columnNames) {
    this.header = [];
    this.header.push('Type');
    this.headerName.push('Type');

    const returnItems = [];
    for (const column of columnNames) {
      if (column) {
        if (
          column.name.toLowerCase() !== 'pipe_heat' &&
          column.name.toLowerCase() !== 'manufacturer_pipe' &&
          column.name.toLowerCase() !== 'dj_num'
        ) {
          this.header.push(column.description);
          this.headerName.push(column.name);

          const columnValues = _.map(this.items, (data) => {
            return parseFloat(data[column.name]);
          });

          const minVal = Math.min(...columnValues);
          const maxVal = Math.max(...columnValues);

          returnItems.push({
            string: column.string,
            description: column.description,
            name: column.name,
            min: minVal,
            minSelected: minVal,
            max: maxVal,
            maxSelected: maxVal,
            stringFilter: null,
          });
        }
      }
    }
    return returnItems;
  }

  configCurrentTestBreadCrumb() {}

  configBreadCrumb() {
    this.configCurrentTestBreadCrumb();

    if (
      this.store.selectedProduct.gsi_id &&
      this.store.selectedProject.gsi_id &&
      this.store.materialId
    ) {
      this.breadcrumb = [
        {
          name: this.dataBookState.projectName,
          link: '/main/project',
          type: this.coreService.translate('databook.project', null),
        },
        {
          name: this.dataBookState.productDsc,
          link: '/main/product',
          type: this.coreService.translate('databook.product', null),
        },
        {
          name: this.coreService.translate('databook.data.explorer', null),
          link: '/',
          type: this.coreService.translate('databook.dashboard', null),
        },
        {
          name: this.store.material ? this.store.material.description : '',
          type: this.coreService.translate('databook.material.project', null),
          link: '/main/material',
        },
        {
          link: '/main/dataExplorer',
          name: this.coreService.translate('dataExplorer.select.test', null),
        },
      ];
    }
  }

  getTest() {
    return '';
  }

  getCondition() {
    return false;
  }

  public checkAndBack(condition) {
    if (!this.dataBookState.project || !this.dataBookState.product) {
      this.coreService.getRouter().navigate(['/']);
    } else if (!this.store.materialId) {
      this.coreService.showAlertErrorTranslate('select.material.error');
      this.coreService.getRouter().navigate(['/']);
    }
  }

  onChangeColumnRangeFilter(item) {
    this.items = _.filter(this.itemsCache, (data) => {
      let pipeHeatFiltered = true;
      if (item.pipeFilter) {
        if (this.store.selectedProduct.gsi_id === 'COATING') {
          const columnValueNum = data.Pipe_Num;
          const columnValueSon = data.son;
          pipeHeatFiltered =
            columnValueNum
              .toString()
              .toLowerCase()
              .includes(item.pipeFilter.toLowerCase()) ||
            (columnValueSon &&
              columnValueSon.includes(item.pipeFilter.toLowerCase()));
        } else {
          let columnValue = data.Manufacturer_Pipe;
          if (this.store.selectedProduct.gsi_id === 'DOUBLE_JOINT') {
            columnValue = data.DJ_Num;
          }
          pipeHeatFiltered = columnValue
            .toString()
            .toLowerCase()
            .includes(item.pipeFilter.toLowerCase());
        }
      }

      if (item.heatFilter) {
        const columnValue = data.Pipe_Heat;
        pipeHeatFiltered =
          columnValue
            .toString()
            .toLowerCase()
            .includes(item.heatFilter.toLowerCase()) && pipeHeatFiltered;
      }

      if (item.selectedColumn) {
        if (item.selectedColumn.string) {
          if (item.selectedColumn.stringFilter) {
            const columnValue = data[item.selectedColumn.name];
            return (
              columnValue &&
              columnValue
                .toString()
                .toLowerCase()
                .includes(item.selectedColumn.stringFilter.toLowerCase()) &&
              pipeHeatFiltered
            );
          } else {
            return true && pipeHeatFiltered;
          }
        } else {
          const columnValue = parseFloat(data[item.selectedColumn.name]);
          return (
            columnValue >= item.selectedColumn.minSelected &&
            columnValue <= item.selectedColumn.maxSelected &&
            pipeHeatFiltered
          );
        }
      }
      return true && pipeHeatFiltered;
    });
    this.sendGtag('search');
  }

  fetchData(response) {
    this.items = response.items;
    this.itemsCache = response.items;

    this.completeData(response);
  }
  fetchIrnData(response) {
    this.irn = response.items;
    this.irnCache = response.items;

    this.completeData(response);
  }

  completeData(response) {}
}
