import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import {
  ClarityModule,
  ClrNavigationModule,
  ClrVerticalNavModule,
} from '@clr/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { MobxAngularModule } from 'mobx-angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ToastrModule } from 'ngx-toastr';
import 'reflect-metadata';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { HttpLoaderFactory } from './app/app.module';
import { PdfService } from './app/components/pdf/pdf.service';
import { CustomRouteReuseStrategy } from './app/components/router-reuse-strategy/custom-reuse-strategy.provider';
import { CoreService } from './app/core/core.service';
import { AuthenticateGuard } from './app/core/guards/authenticate.guard';
import { ConfigHandler } from './app/core/handlers/config.handler';
import { CanDeactivateGuard } from './app/guards/can-deactivate.guard';
import {
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from './app/mobx-stores';
import { DataBookService } from './app/services/databook.service';
import { DataBookState } from './app/services/databook.state';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      // NgxSoapModule,
      MobxAngularModule,
      AgGridModule,
      NgxDropzoneModule,
      BrowserModule,
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      ClarityModule,
      ClrNavigationModule,
      ClrVerticalNavModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      DragDropModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
      }),
      AppRoutingModule,
    ),
    CoreService,
    AuthenticateGuard,
    CanDeactivateGuard,
    DataBookService,
    DataBookState,
    PdfService,
    {
      provide: PROJECT_STORE,
      useClass: ProjectsStore,
    },
    {
      provide: USER_STORE,
      useClass: UserStore,
    },
    {
      provide: TREEVIEW_STORE,
      useClass: TreeviewStore,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: ConfigHandler,
      useValue: {
        applicationId: 'PIPE_NAVIGATOR',
        environment,
      },
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.error(err));
