<vic-base-page page="modulus">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isModulusVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            *ngIf="shouldShowColumn('Issuer')"
            [style.width.px]="200"
            >{{ 'data.book.issuer' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Standard')"
            [style.width.px]="200"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Manufacturer')"
            [style.width.px]="200"
            >{{ 'data.book.manufacturer' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Reference_Criteria')"
            [style.width.px]="400"
            >{{ 'data.book.reference.criteria' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Test_Conditions')"
            [style.width.px]="200"
            >{{ 'data.book.test.conditions' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Acceptance_Criteria')"
            [style.width.px]="700"
            >{{ 'data.book.acceptance.criteria' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Dimension')"
            [style.width.px]="350"
            >{{ 'data.book.dimension' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Item')"
            [style.width.px]="200"
            >{{ 'data.book.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Sample_Date')"
            [style.width.px]="200"
            >{{ 'data.book.sample.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Shift')"
            [style.width.px]="200"
            >{{ 'data.book.shift' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Layer')"
            [style.width.px]="200"
            >{{ 'data.book.layer' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Material')"
            [style.width.px]="200"
            >{{ 'data.book.material' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Raw_Material_Lot_Number')"
            [style.width.px]="400"
            >{{
              'data.book.raw.material.lot.number' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Remarks')"
            [style.width.px]="200"
            >{{ 'data.book.remarks' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Pipe_Num')"
            [style.width.px]="200"
            >{{ 'data.book.pipe.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Production_Batch')"
            [style.width.px]="200"
            >{{ 'data.book.production.batch' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Quality_Certificate_Num')"
            [style.width.px]="200"
            >{{
              'data.book.quality.certificate.num' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Specimen')"
            [style.width.px]="200"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >

          <clr-dg-column
            *ngIf="shouldShowColumn('Results_Tangent_Modulus_Mpa')"
            [style.width.px]="200"
            >{{
              'data.book.results.tangent.modulus.mpa' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Avg_Tangent_Modulus_Mpa')"
            [style.width.px]="200"
            >{{
              'data.book.avg.tangent.modulus.mpa' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Speed_Test')"
            [style.width.px]="200"
            >{{ 'data.book.speed.test' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell
              *ngIf="shouldShowColumn('Issuer')"
              type="date"
              >{{ data['Issuer'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Standard')"
              type="date"
              >{{ data['Standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Manufacturer')"
              type="date"
              >{{ data['Manufacturer'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Reference_Criteria')"
              type="date"
              >{{ data['Reference_Criteria'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Test_Conditions')"
              type="date"
              >{{ data['Test_Conditions'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Acceptance_Criteria')"
              type="date"
              >{{ data['Acceptance_Criteria'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Dimension')"
              type="date"
              >{{ data['Dimension'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Item')"
              type="date"
              >{{ data['Item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Sample_Date')"
              type="date"
              >{{ data['Sample_Date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Shift')"
              type="date"
              >{{ data['Shift'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Layer')"
              type="date"
              >{{ data['Layer'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Material')"
              type="date"
              >{{ data['Material'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Raw_Material_Lot_Number')"
              type="date"
              >{{ data['Raw_Material_Lot_Number'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Remarks')"
              type="date"
              >{{ data['Remarks'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Pipe_Num')"
              type="date"
              >{{ data['Pipe_Num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Production_Batch')"
              type="date"
              >{{ data['Production_Batch'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Quality_Certificate_Num')"
              type="date"
              >{{ data['Quality_Certificate_Num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Specimen')"
              type="date"
              >{{ data['Specimen'] }}</clr-dg-cell
            >

            <clr-dg-cell
              *ngIf="shouldShowColumn('Results_Tangent_Modulus_Mpa')"
              type="date"
              >{{ data['Results_Tangent_Modulus_Mpa'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Avg_Tangent_Modulus_Mpa')"
              type="date"
              >{{ data['Avg_Tangent_Modulus_Mpa'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Speed_Test')"
              type="date"
              >{{ data['Speed_Test'] }}</clr-dg-cell
            >
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="openModalIRN"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div *ngIf="!items || !items.length">NO DATA</div>
    <clr-datagrid
      *ngIf="irn && irn.length"
      class="val-datagrid"
    >
      <clr-dg-column
        *ngIf="shouldShowColumn('Pipe_Num')"
        [style.width.px]="100"
        >{{ 'data.book.pipe.num' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Quality_Cerficate_Num')"
        [style.width.px]="200"
        >{{ 'data.book.quality.certificate.num' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Heat')"
        [style.width.px]="220"
        >{{ 'data.book.heat' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Bundle')"
        [style.width.px]="220"
        >{{ 'data.book.bundle' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Length')"
        [style.width.px]="220"
        >{{ 'data.book.length' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Weight')"
        [style.width.px]="220"
        >{{ 'data.book.weight' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Batch')"
        [style.width.px]="220"
        >{{ 'data.book.batch' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Technical_Deviation')"
        [style.width.px]="400"
        >{{ 'data.book.technical.deviation' | translate }}</clr-dg-column
      >

      <clr-dg-row *clrDgItems="let data of irn">
        <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Num')">{{
          data['Pipe_Num']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Quality_Cerficate_Num')">{{
          data['Quality_Cerficate_Num']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Heat')">{{
          data['Heat']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Bundle')">{{
          data['Bundle']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Length')">{{
          data['Length']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Weight')">{{
          data['Weight']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Batch')">{{
          data['Batch']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Technical_Deviation')">{{
          data['Technical_Deviation']
        }}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination
          #paginationTaskDetails
          [clrDgPageSize]="10"
        >
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
            {{
              'application.commons.grid.footer.pagination.page.size' | translate
            }}
          </clr-dg-page-size>
          {{ paginationTaskDetails.firstItem + 1 }} -
          {{ paginationTaskDetails.lastItem + 1 }}
          {{ 'application.commons.grid.footer.pagination.of' | translate }}
          {{ paginationTaskDetails.totalItems }}
          {{ 'application.commons.grid.footer.pagination.records' | translate }}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.close' | translate }}
    </button>
  </div>
</clr-modal>
