<vic-base-page page="dataExplorer-all">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      *ngIf="dataBookState.project === 'PLATINA'"
      (click)="configExport()"
    >
      <clr-icon shape="filter-grid"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.filter.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isChemicalVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-checkbox-container clrInline>
          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="chemicalChecked"
              [(ngModel)]="chemicalChecked"
            />
            <label>Chemical</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="sscChecked"
              [(ngModel)]="sscChecked"
            />
            <label>SSC</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="hicChecked"
              [(ngModel)]="hicChecked"
            />
            <label>HIC</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="hardnessChecked"
              [(ngModel)]="hardnessChecked"
            />
            <label>Hardness</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="impactChecked"
              [(ngModel)]="impactChecked"
            />
            <label>Impact</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="dimensionsChecked"
              [(ngModel)]="dimensionsChecked"
            />
            <label>Dimensions</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="tensileChecked"
              [(ngModel)]="tensileChecked"
            />
            <label>Tensile</label>
          </clr-checkbox-wrapper>

          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="options"
              value="ctodChecked"
              [(ngModel)]="ctodChecked"
            />
            <label>CTOD</label>
          </clr-checkbox-wrapper>
        </clr-checkbox-container>

        <clr-datagrid
          *ngIf="items && items.length && getTest() === 'all'"
          class="val-datagrid"
        >
          <!--                    CHEMICAL-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_mill_order') && chemicalChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_mill_item') && chemicalChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_pipe_heat') && chemicalChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_specimen') && chemicalChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcd_carbon_equivalent_ce') && chemicalChecked
            "
            >{{ 'data.book.carbon.equivalent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_carbon_c'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_carbon_c') && chemicalChecked"
            >{{ 'data.book.carbon' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_manganese_mn'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_manganese_mn') && chemicalChecked"
            >{{ 'data.book.manganese' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_silicon_si'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_silicon_si') && chemicalChecked"
            >{{ 'data.book.silicon' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_aluminium_total_al'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpcd_aluminium_total_al') && chemicalChecked
            "
            >{{ 'data.book.aluminium.total' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_phosporus_p'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_phosporus_p') && chemicalChecked"
            >{{ 'data.book.phosporus' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_sulphur_s'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_sulphur_s') && chemicalChecked"
            >{{ 'data.book.sulphur' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_niobium_nb'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_niobium_nb') && chemicalChecked"
            >{{ 'data.book.niobium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_vanadium_v'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_vanadium_v') && chemicalChecked"
            >{{ 'data.book.vanadium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_nickel_ni'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_nickel_ni') && chemicalChecked"
            >{{ 'data.book.nickel' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_chromium_cr'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_chromium_cr') && chemicalChecked"
            >{{ 'data.book.chromium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_copper_cu'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_copper_cu') && chemicalChecked"
            >{{ 'data.book.copper' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_boron_b'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_boron_b') && chemicalChecked"
            >{{ 'data.book.boron' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_molybdenum_mo'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_molybdenum_mo') && chemicalChecked"
            >{{ 'data.book.molybdenum' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_titanium_ti'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_titanium_ti') && chemicalChecked"
            >{{ 'data.book.titanium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_nitrogen_n'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_nitrogen_n') && chemicalChecked"
            >{{ 'data.book.nitrogen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_pcm_value'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_pcm_value') && chemicalChecked"
            >{{ 'data.book.pcm.value' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_calcium_ca'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_calcium_ca') && chemicalChecked"
            >{{ 'data.book.calcium' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_nb_v_ti'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_nb_v_ti') && chemicalChecked"
            >{{ 'data.book.nb.v.ti' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpcd_al_n'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpcd_al_n') && chemicalChecked"
            >{{ 'data.book.al.n' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_standard') && chemicalChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcd_qualification') && chemicalChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpcd_manufacturer_pipe') && chemicalChecked
            "
            >{{ 'data.book.manufacturers.pipe' | translate }}</clr-dg-column
          >

          <!--                    SSC-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_mill_order') && sscChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_mill_item') && sscChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_item') && sscChecked"
            >{{ 'data.book.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_pipe_heat') && sscChecked"
            >{{ 'data.book.mill.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('tpcdd_last_date') && sscChecked"
            >{{ 'data.book.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('tpcdd_pts_pipe_num') && sscChecked"
            >{{ 'data.book.pts.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_plate_num') && sscChecked"
            >{{ 'data.book.plate' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_specimen') && sscChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_avg_scc_test') && sscChecked"
            >{{ 'data.book.test.avg.ssc.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_1_scc_test') && sscChecked"
            >{{ 'data.book.test.1.ssctest' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_1_last_date') && sscChecked"
            >{{ 'data.book.test.1.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_2_scc_test') && sscChecked"
            >{{ 'data.book.test.2.ssctest' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_2_last_date') && sscChecked"
            >{{ 'data.book.test.2.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_3_scc_test') && sscChecked"
            >{{ 'data.book.test.3.ssctest' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_3_last_date') && sscChecked"
            >{{ 'data.book.test.3.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_temperature') && sscChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_standard') && sscChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_qualification') && sscChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_manufacturer_pipe') && sscChecked"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_condition') && sscChecked"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <!--                    HIC-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_mill_order') && hicChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_mill_item') && hicChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_pipe_heat') && hicChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_specimen') && hicChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_avg_hic_test') && hicChecked"
            >{{ 'data.book.test.avg.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_avg_scc_test') && hicChecked"
            >{{ 'data.book.test.avg.ssc.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_1_hic_test') && hicChecked"
            >{{ 'data.book.test.1.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_1_last_date') && hicChecked"
            >{{ 'data.book.test.1.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_2_hic_test') && hicChecked"
            >{{ 'data.book.test.2.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_2_last_date') && hicChecked"
            >{{ 'data.book.test.2.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_3_hic_test') && hicChecked"
            >{{ 'data.book.test.3.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_3_last_date') && hicChecked"
            >{{ 'data.book.test.3.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_test_temperature') && hicChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_standard') && hicChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_qualification') && hicChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_manufacturer_pipe') && hicChecked"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpcdd_condition') && hicChecked"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <!--                    Hardness-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_mill_order') && hardnessChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_mill_item') && hardnessChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_pipe_heat') && hardnessChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_specimen'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_specimen') && hardnessChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_ow1'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_ow1') && hardnessChecked"
            >{{ 'data.book.ow.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_ow2'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_ow2') && hardnessChecked"
            >{{ 'data.book.ow.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_ow3'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_ow3') && hardnessChecked"
            >{{ 'data.book.ow.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_ow4'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_ow4') && hardnessChecked"
            >{{ 'data.book.ow.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_ow_avg'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_ow_avg') && hardnessChecked"
            >{{ 'data.book.ow.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_mw1'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_mw1') && hardnessChecked"
            >{{ 'data.book.mw.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_mw2'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_mw2') && hardnessChecked"
            >{{ 'data.book.mw.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_mw3'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_mw3') && hardnessChecked"
            >{{ 'data.book.mw.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_mw4'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_mw4') && hardnessChecked"
            >{{ 'data.book.mw.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_mw_avg'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_mw_avg') && hardnessChecked"
            >{{ 'data.book.mw.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_iw1'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_iw1') && hardnessChecked"
            >{{ 'data.book.iw.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_iw2'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_iw2') && hardnessChecked"
            >{{ 'data.book.iw.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_iw3'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_iw3') && hardnessChecked"
            >{{ 'data.book.iw.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_iw4'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_iw4') && hardnessChecked"
            >{{ 'data.book.iw.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tphe_iw_avg'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tphe_iw_avg') && hardnessChecked"
            >{{ 'data.book.iw.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_standard') && hardnessChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_qualification') && hardnessChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tphe_manufacturer_pipe') && hardnessChecked
            "
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tphe_condition') && hardnessChecked"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <!--                    IMPACT-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mill_order') && impactChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_mill_item') && impactChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_pipe_heat') && impactChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_specimen') && impactChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_test_avg_reduced_area') && impactChecked
            "
            >{{
              'data.book.test.avg.reduced.area.value' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_test_avg_parent_metal') && impactChecked
            "
            >{{ 'data.book.test.avg.parent.metal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_test_1_areaed') && impactChecked"
            >{{ 'data.book.test.1.areaed' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_test_1_parent_metal') && impactChecked
            "
            >{{ 'data.book.test.1.parent.metal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_test_2_areaed') && impactChecked"
            >{{ 'data.book.test.2.areaed' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_test_2_parent_metal') && impactChecked
            "
            >{{ 'data.book.test.2.parent.metal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_test_3_areaed') && impactChecked"
            >{{ 'data.book.test.3.areaed' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpid_test_3_parent_metal') && impactChecked
            "
            >{{ 'data.book.test.3.parent.metal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_test_temperature') && impactChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_direction') && impactChecked"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_standard') && impactChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_qualification') && impactChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_manufacturer_pipe') && impactChecked"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_description_charpy') && impactChecked"
            >{{ 'data.book.description.charpy' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpid_condition') && impactChecked"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <!--                    DIMENSIONS-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_pipe_num') && dimensionsChecked"
            >{{ 'data.book.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpmpt_customer_po_num') && dimensionsChecked
            "
            >{{ 'data.book.customer.po' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_heat') && dimensionsChecked"
            >{{ 'data.book.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_mill') && dimensionsChecked"
            >{{ 'data.book.mill' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_mill_order') && dimensionsChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_mill_item') && dimensionsChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_od_nom'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpmpt_od_nom') && dimensionsChecked"
            >{{ 'data.book.od.nominal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_wt_nom'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpmpt_wt_nom') && dimensionsChecked"
            >{{ 'data.book.wt.nominal' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_lenght') && dimensionsChecked"
            >{{ 'data.book.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpmpt_lot') && dimensionsChecked"
            >{{ 'data.book.lot' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpmpt_certificate_num') && dimensionsChecked
            "
            >{{ 'data.book.certificate' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpmpt_manufacturer_location') &&
              dimensionsChecked
            "
            >{{ 'data.book.manufacture.loc' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_weight_bare_kg'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_weight_bare_kg') && dimensionsChecked
            "
            >{{ 'data.book.weight.bare' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_straight_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_straight_p_end_a') && dimensionsChecked
            "
            >{{ 'data.book.straigthness.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_straight_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_straight_p_end_b') && dimensionsChecked
            "
            >{{ 'data.book.straigthness.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_straight_pipe_body'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_straight_pipe_body') && dimensionsChecked
            "
            >{{ 'data.book.straigthness.pipe.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_od_oor_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_od_oor_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.od.oor.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_od_oor_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_od_oor_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.od.oor.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_pipe_end_a_min'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_a_min') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.pipe.end.a.min' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_pipe_end_a_max'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_a_max') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.pipe.end.a.max' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_pipe_end_a_avg'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_a_avg') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.pipe.end.a.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_oor_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_oor_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.oor.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_pipe_end_b_min'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_b_min') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.pipe.end.b.min' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_pipe_end_b_max'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_b_max') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.pipe.end.b.max' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_pipe_end_b_avg'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_b_avg') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.pipe.end.b.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_id_oor_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_id_oor_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.id.oor.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_1_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_1_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.1.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_2_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_2_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.2.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_3_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_3_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.3.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_4_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_4_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.4.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_min_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_min_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.min.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_max_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_max_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.max.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_avg_p_end_a'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_avg_p_end_a') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.avg.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_1_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_1_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.1.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_2_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_2_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.2.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_3_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_3_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.3.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_4_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_4_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.4.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_min_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_min_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.min.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_max_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_max_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.max.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_wt_avg_p_end_b'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_wt_avg_p_end_b') &&
              dimensionsChecked
            "
            >{{ 'data.book.wt.avg.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_od_avg_body'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_od_avg_body') &&
              dimensionsChecked
            "
            >{{ 'data.book.od.avg.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_od_max_body'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_od_max_body') &&
              dimensionsChecked
            "
            >{{ 'data.book.od.max.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_od_min_body'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_od_min_body') &&
              dimensionsChecked
            "
            >{{ 'data.book.od.min.body' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpmpt_fnl_prod_od_body_oor'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpmpt_fnl_prod_od_body_oor') &&
              dimensionsChecked
            "
            >{{ 'data.book.od.body.body' | translate }}</clr-dg-column
          >

          <!--                    TENSILE-->
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_mill_order') && tensileChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_mill_item') && tensileChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_pipe_heat') && tensileChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_specimen') && tensileChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="220"
            [clrDgField]="'tpthd_tensile_strength_mpa'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpthd_tensile_strength_mpa') && tensileChecked
            "
            >{{ 'data.book.tensile.strength' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="230"
            [clrDgField]="'tpthd_yield_strength_mpa'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpthd_yield_strength_mpa') && tensileChecked
            "
            >{{ 'data.book.yield.strength' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpthd_y_t'"
            [clrDgSortBy]="compare"
            *ngIf="shouldShowColumn('tpthd_y_t') && tensileChecked"
            >{{ 'data.book.y.t' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            [clrDgField]="'tpthd_elongation_per_cent'"
            [clrDgSortBy]="compare"
            *ngIf="
              shouldShowColumn('tpthd_elongation_per_cent') && tensileChecked
            "
            >{{ 'data.book.elongation' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpthd_uniform_elongation') && tensileChecked
            "
            >{{ 'data.book.uniform.elongation' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_test_temperature') && tensileChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_direction') && tensileChecked"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_standard') && tensileChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_qualification') && tensileChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="
              shouldShowColumn('tpthd_manufacturer_pipe') && tensileChecked
            "
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tpthd_condition') && tensileChecked"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <!--                    ctod-->

          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_mill_order') && ctodChecked"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_mill_item') && ctodChecked"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_pipe_heat') && ctodChecked"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_specimen') && ctodChecked"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_avg_ctod') && ctodChecked"
            >{{ 'data.book.test.avg.ctod' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_1_ctod') && ctodChecked"
            >{{ 'data.book.test.1.ctod' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_1_last_date') && ctodChecked"
            >{{ 'data.book.test.1.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_2_ctod') && ctodChecked"
            >{{ 'data.book.test.2.ctod' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_2_last_date') && ctodChecked"
            >{{ 'data.book.test.2.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_3_ctod') && ctodChecked"
            >{{ 'data.book.test.3.ctod' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_3_last_date') && ctodChecked"
            >{{ 'data.book.test.3.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_test_temperature') && ctodChecked"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_ctod_size_bx2b') && ctodChecked"
            >{{ 'data.book.ctod.size.b.2b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_direction') && ctodChecked"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_standard') && ctodChecked"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_qualification') && ctodChecked"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_manufacturer_pipe') && ctodChecked"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tptd_condition') && ctodChecked"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <!--                        CHEMICAL-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_mill_order') && chemicalChecked"
              >{{ data['tpcd_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_mill_item') && chemicalChecked"
              >{{ data['tpcd_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_pipe_heat') && chemicalChecked"
              >{{ data['tpcd_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_specimen') && chemicalChecked"
              >{{ data['tpcd_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcd_carbon_equivalent_ce') && chemicalChecked
              "
              >{{ data['tpcd_carbon_equivalent_ce'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_carbon_c') && chemicalChecked"
              >{{ data['tpcd_carbon_c'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_manganese_mn') && chemicalChecked"
              >{{ data['tpcd_manganese_mn'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_silicon_si') && chemicalChecked"
              >{{ data['tpcd_silicon_si'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcd_aluminium_total_al') && chemicalChecked
              "
              >{{ data['tpcd_aluminium_total_al'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_phosporus_p') && chemicalChecked"
              >{{ data['tpcd_phosporus_p'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_sulphur_s') && chemicalChecked"
              >{{ data['tpcd_sulphur_s'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_niobium_nb') && chemicalChecked"
              >{{ data['tpcd_niobium_nb'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_vanadium_v') && chemicalChecked"
              >{{ data['tpcd_vanadium_v'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_nickel_ni') && chemicalChecked"
              >{{ data['tpcd_nickel_ni'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_chromium_cr') && chemicalChecked"
              >{{ data['tpcd_chromium_cr'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_copper_cu') && chemicalChecked"
              >{{ data['tpcd_copper_cu'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_boron_b') && chemicalChecked"
              >{{ data['tpcd_boron_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_molybdenum_mo') && chemicalChecked"
              >{{ data['tpcd_molybdenum_mo'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_titanium_ti') && chemicalChecked"
              >{{ data['tpcd_titanium_ti'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_nitrogen_n') && chemicalChecked"
              >{{ data['tpcd_nitrogen_n'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_pcm_value') && chemicalChecked"
              >{{ data['tpcd_pcm_value'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_calcium_ca') && chemicalChecked"
              >{{ data['tpcd_calcium_ca'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_nb_v_ti') && chemicalChecked"
              >{{ data['tpcd_nb_v_ti'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_al_n') && chemicalChecked"
              >{{ data['tpcd_al_n'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_standard') && chemicalChecked"
              >{{ data['tpcd_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcd_qualification') && chemicalChecked"
              >{{ data['tpcd_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpcd_manufacturer_pipe') && chemicalChecked
              "
              >{{ data['tpcd_manufacturer_pipe'] }}</clr-dg-cell
            >

            <!--                        SSC-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_mill_order') && sscChecked"
              >{{ data['tpcdd_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_mill_item') && sscChecked"
              >{{ data['tpcdd_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('tpcdd_item') && sscChecked">{{
              data['tpcdd_item']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_pipe_heat') && sscChecked"
              >{{ data['tpcdd_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_last_date') && sscChecked"
              >{{ data['tpcdd_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_pts_pipe_num') && sscChecked"
              >{{ data['tpcdd_pts_pipe_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_plate_num') && sscChecked"
              >{{ data['tpcdd_plate_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_specimen') && sscChecked"
              >{{ data['tpcdd_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_avg_scc_test') && sscChecked"
              >{{ data['tpcdd_test_avg_scc_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_1_scc_test') && sscChecked"
              >{{ data['tpcdd_test_1_scc_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_1_last_date') && sscChecked"
              >{{ data['tpcdd_test_1_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_2_scc_test') && sscChecked"
              >{{ data['tpcdd_test_2_scc_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_2_last_date') && sscChecked"
              >{{ data['tpcdd_test_2_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_3_scc_test') && sscChecked"
              >{{ data['tpcdd_test_3_scc_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_3_last_date') && sscChecked"
              >{{ data['tpcdd_test_3_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_temperature') && sscChecked"
              >{{ data['tpcdd_test_temperature'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_standard') && sscChecked"
              >{{ data['tpcdd_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_qualification') && sscChecked"
              >{{ data['tpcdd_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_manufacturer_pipe') && sscChecked"
              >{{ data['tpcdd_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_condition') && sscChecked"
              >{{ data['tpcdd_condition'] }}</clr-dg-cell
            >

            <!--                        HIC-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_mill_order') && hicChecked"
              >{{ data['tpcdd_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_mill_item') && hicChecked"
              >{{ data['tpcdd_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_pipe_heat') && hicChecked"
              >{{ data['tpcdd_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_specimen') && hicChecked"
              >{{ data['tpcdd_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_avg_hic_test') && hicChecked"
              >{{ data['tpcdd_test_avg_hic_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_avg_scc_test') && hicChecked"
              >{{ data['tpcdd_test_avg_scc_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_1_hic_test') && hicChecked"
              >{{ data['tpcdd_test_1_hic_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_1_last_date') && hicChecked"
              >{{ data['tpcdd_test_1_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_2_hic_test') && hicChecked"
              >{{ data['tpcdd_test_2_hic_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_2_last_date') && hicChecked"
              >{{ data['tpcdd_test_2_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_3_hic_test') && hicChecked"
              >{{ data['tpcdd_test_3_hic_test'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_3_last_date') && hicChecked"
              >{{ data['tpcdd_test_3_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_test_temperature') && hicChecked"
              >{{ data['tpcdd_test_temperature'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_standard') && hicChecked"
              >{{ data['tpcdd_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_qualification') && hicChecked"
              >{{ data['tpcdd_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_manufacturer_pipe') && hicChecked"
              >{{ data['tpcdd_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpcdd_condition') && hicChecked"
              >{{ data['tpcdd_condition'] }}</clr-dg-cell
            >

            <!--                        Hardness-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mill_order') && hardnessChecked"
              >{{ data['tphe_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mill_item') && hardnessChecked"
              >{{ data['tphe_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_pipe_heat') && hardnessChecked"
              >{{ data['tphe_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_specimen') && hardnessChecked"
              >{{ data['tphe_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_ow1') && hardnessChecked"
              >{{ data['tphe_ow1'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_ow2') && hardnessChecked"
              >{{ data['tphe_ow2'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_ow3') && hardnessChecked"
              >{{ data['tphe_ow3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_ow4') && hardnessChecked"
              >{{ data['tphe_ow3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_ow_avg') && hardnessChecked"
              >{{ data['tphe_ow_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mw1') && hardnessChecked"
              >{{ data['tphe_mw1'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mw2') && hardnessChecked"
              >{{ data['tphe_mw2'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mw3') && hardnessChecked"
              >{{ data['tphe_mw3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mw4') && hardnessChecked"
              >{{ data['tphe_mw4'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_mw_avg') && hardnessChecked"
              >{{ data['tphe_mw_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_iw1') && hardnessChecked"
              >{{ data['tphe_iw1'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_iw2') && hardnessChecked"
              >{{ data['tphe_iw2'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_iw3') && hardnessChecked"
              >{{ data['tphe_iw3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_iw4') && hardnessChecked"
              >{{ data['tphe_iw4'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_iw_avg') && hardnessChecked"
              >{{ data['tphe_iw_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_standard') && hardnessChecked"
              >{{ data['tphe_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_qualification') && hardnessChecked"
              >{{ data['tphe_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tphe_manufacturer_pipe') && hardnessChecked
              "
              >{{ data['tphe_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tphe_condition') && hardnessChecked"
              >{{ data['tphe_condition'] }}</clr-dg-cell
            >

            <!--                        IMPACT-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mill_order') && impactChecked"
              >{{ data['tpid_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_mill_item') && impactChecked"
              >{{ data['tpid_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_pipe_heat') && impactChecked"
              >{{ data['tpid_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_specimen') && impactChecked"
              >{{ data['tpid_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_test_avg_reduced_area') && impactChecked
              "
              >{{ data['tpid_test_avg_reduced_area'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_test_avg_parent_metal') && impactChecked
              "
              >{{ data['tpid_test_avg_parent_metal'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_test_1_areaed') && impactChecked"
              >{{ data['tpid_test_1_areaed'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_test_1_parent_metal') && impactChecked
              "
              >{{ data['tpid_test_1_parent_metal'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_test_2_areaed') && impactChecked"
              >{{ data['tpid_test_2_areaed'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_test_2_parent_metal') && impactChecked
              "
              >{{ data['tpid_test_2_parent_metal'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_test_3_areaed') && impactChecked"
              >{{ data['tpid_test_3_areaed'] }}</clr-dg-cell
            >

            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_test_3_parent_metal') && impactChecked
              "
              >{{ data['tpid_test_3_parent_metal'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_test_temperature') && impactChecked"
              >{{ data['tpid_test_temperature'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_direction') && impactChecked"
              >{{ data['tpid_direction'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_standard') && impactChecked"
              >{{ data['tpid_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_qualification') && impactChecked"
              >{{ data['tpid_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_manufacturer_pipe') && impactChecked
              "
              >{{ data['tpid_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpid_description_charpy') && impactChecked
              "
              >{{ data['tpid_description_charpy'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpid_condition') && impactChecked"
              >{{ data['tpid_condition'] }}</clr-dg-cell
            >

            <!--                        DIMENSIONS-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_pipe_num') && dimensionsChecked"
              >{{ data['tpmpt_pipe_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_customer_po_num') && dimensionsChecked
              "
              >{{ data['tpmpt_customer_po_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_heat') && dimensionsChecked"
              >{{ data['tpmpt_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_mill') && dimensionsChecked"
              >{{ data['tpmpt_mill'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_mill_order') && dimensionsChecked"
              >{{ data['tpmpt_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_mill_item') && dimensionsChecked"
              >{{ data['tpmpt_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_od_nom') && dimensionsChecked"
              >{{ data['tpmpt_od_nom'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_wt_nom') && dimensionsChecked"
              >{{ data['tpmpt_wt_nom'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_lenght') && dimensionsChecked"
              >{{ data['tpmpt_lenght'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpmpt_lot') && dimensionsChecked"
              >{{ data['tpmpt_lot'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_certificate_num') && dimensionsChecked
              "
              >{{ data['tpmpt_certificate_num'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_manufacturer_location') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_manufacturer_location'] }}</clr-dg-cell
            >

            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_weight_bare_kg') && dimensionsChecked
              "
              >{{ data['tpmpt_weight_bare_kg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_straight_p_end_a') && dimensionsChecked
              "
              >{{ data['tpmpt_straight_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_straight_p_end_b') && dimensionsChecked
              "
              >{{ data['tpmpt_straight_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_straight_pipe_body') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_straight_pipe_body'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_od_oor_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_od_oor_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_od_oor_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_od_oor_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_a_min') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_pipe_end_a_min'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_a_max') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_pipe_end_a_max'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_a_avg') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_pipe_end_a_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_oor_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_oor_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_b_min') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_pipe_end_b_min'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_b_max') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_pipe_end_b_max'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_pipe_end_b_avg') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_pipe_end_b_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_id_oor_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_id_oor_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_1_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_1_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_2_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_2_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_3_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_3_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_4_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_4_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_min_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_min_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_max_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_max_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_avg_p_end_a') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_avg_p_end_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_1_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_1_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_2_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_2_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_3_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_3_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_4_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_4_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_min_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_min_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_max_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_max_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_wt_avg_p_end_b') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_wt_avg_p_end_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_od_avg_body') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_od_avg_body'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_od_max_body') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_od_max_body'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_od_min_body') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_od_min_body'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpmpt_fnl_prod_od_body_oor') &&
                dimensionsChecked
              "
              >{{ data['tpmpt_fnl_prod_od_body_oor'] }}</clr-dg-cell
            >

            <!--                        TENSILE-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_mill_order') && tensileChecked"
              >{{ data['tpthd_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_mill_item') && tensileChecked"
              >{{ data['tpthd_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_pipe_heat') && tensileChecked"
              >{{ data['tpthd_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_specimen') && tensileChecked"
              >{{ data['tpthd_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_tensile_strength_mpa') && tensileChecked
              "
              >{{ data['tpthd_tensile_strength_mpa'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_yield_strength_mpa') && tensileChecked
              "
              >{{ data['tpthd_yield_strength_mpa'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_y_t') && tensileChecked"
              >{{ data['tpthd_y_t'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_elongation_per_cent') && tensileChecked
              "
              >{{ data['tpthd_elongation_per_cent'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_uniform_elongation') && tensileChecked
              "
              >{{ data['tpthd_uniform_elongation'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_test_temperature') && tensileChecked
              "
              >{{ data['tpthd_test_temperature'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_direction') && tensileChecked"
              >{{ data['tpthd_direction'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_standard') && tensileChecked"
              >{{ data['tpthd_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_qualification') && tensileChecked"
              >{{ data['tpthd_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="
                shouldShowColumn('tpthd_manufacturer_pipe') && tensileChecked
              "
              >{{ data['tpthd_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tpthd_condition') && tensileChecked"
              >{{ data['tpthd_condition'] }}</clr-dg-cell
            >

            <!--                        ctod-->
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_mill_order') && ctodChecked"
              >{{ data['tptd_mill_order'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_mill_item') && ctodChecked"
              >{{ data['tptd_mill_item'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_pipe_heat') && ctodChecked"
              >{{ data['tptd_pipe_heat'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_specimen') && ctodChecked"
              >{{ data['tptd_specimen'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_avg_ctod') && ctodChecked"
              >{{ data['tptd_test_avg_ctod'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_1_ctod') && ctodChecked"
              >{{ data['tptd_test_1_ctod'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_1_last_date') && ctodChecked"
              >{{ data['tptd_test_1_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_2_ctod') && ctodChecked"
              >{{ data['tptd_test_2_ctod'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_2_last_date') && ctodChecked"
              >{{ data['tptd_test_2_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_3_ctod') && ctodChecked"
              >{{ data['tptd_test_3_ctod'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_3_last_date') && ctodChecked"
              >{{ data['tptd_test_3_last_date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_test_temperature') && ctodChecked"
              >{{ data['tptd_test_temperature'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_ctod_size_bx2b') && ctodChecked"
              >{{ data['tptd_ctod_size_bx2b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_direction') && ctodChecked"
              >{{ data['tptd_direction'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_standard') && ctodChecked"
              >{{ data['tptd_standard'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_qualification') && ctodChecked"
              >{{ data['tptd_qualification'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_manufacturer_pipe') && ctodChecked"
              >{{ data['tptd_manufacturer_pipe'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tptd_condition') && ctodChecked"
              >{{ data['tptd_condition'] }}</clr-dg-cell
            >
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>

        <clr-datagrid
          *ngIf="items && items.length && getTest() === 'all_liner'"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('project_name')"
            >Project Name</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('client')"
            >Client</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('sub_contractor')"
            >Sub Contractor</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('type')"
            >Type</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="400"
            *ngIf="shouldShowColumn('dimension')"
            >Dimension</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="500"
            *ngIf="shouldShowColumn('material')"
            >Material</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="400"
            *ngIf="shouldShowColumn('pipe_num')"
            >Pipe Num</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('coil_heat_num')"
            >Coil Heat Num</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('coil_lot_num')"
            >Coil Lot Num</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wire_heat_num')"
            >Wire Heat Num</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('qualification')"
            >Qualification</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('length_m')"
            >Length M</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('length_state')"
            >Length State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_pipe_end_r_0')"
            >OD Pipe End R 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_pipe_end_r_90')"
            >OD Pipe End R 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_pipe_end_mid_0')"
            >OD Pipe End Mid 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_pipe_end_mid_90')"
            >OD Pipe End Mid 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_pipe_end_y_0')"
            >OD Pipe End Y 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_pipe_end_y_90')"
            >OD Pipe End Y 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('od_state')"
            >OD State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_02_length')"
            >Strgh 02 Length</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_pipe_end_r_0_1atm')"
            >Strgh Pipe End R 0 1ATM</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_pipe_end_mid_0')"
            >Strgh Pipe End Mid 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_pipe_end_y_0_1atm')"
            >Strgh Pipe End Y 0 1ATM</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_pipe_end_r_90_1atm')"
            >Strgh Pipe End R 90 1ATM</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_pipe_end_mid_90')"
            >Strgh Pipe End Mid 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_pipe_end_y_90_1atm')"
            >Strgh Pipe End Y 90 1ATM</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('strgh_state')"
            >Strgh State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('liner_cap_height')"
            >Liner Cap Heigth</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('liner_cap_state')"
            >Liner Cap State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_r_0')"
            >WT Pipe End R 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_r_90')"
            >WT Pipe End R 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_r_180')"
            >WT Pipe End R 180</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_r_270')"
            >WT Pipe End R 270</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_mid_0')"
            >WT Pipe End Mid 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_mid_90')"
            >WT Pipe End Mid 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_mid_180')"
            >WT Pipe End Mid 180</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_mid_270')"
            >WT Pipe End Mid 270</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_y_0')"
            >WT Pipe End Y 0</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_y_90')"
            >WT Pipe End Y 90</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_y_180')"
            >WT Pipe End Y 180</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_pipe_end_y_270')"
            >WT Pipe End Y 270</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('wt_state')"
            >WT State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('penetration_test')"
            >Penetration Test</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('radiography_test')"
            >Radiography Test</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('visual_test')"
            >Visual Test</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_paw_plasma_wire_size')"
            >Welding Paw Plasma Wire Size</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_paw_plasma_wire_speed')"
            >Welding Paw Plasma Wire Speed</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_paw_plasma_amperage')"
            >Welding Paw Plasma Amperage</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_paw_plasma_volt_range')"
            >Welding Paw Plasma Volt Range</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="220"
            *ngIf="shouldShowColumn('welding_paw_plasma_travel_speed')"
            >Welding Paw Plasma Travel Speed</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_paw_plasma_heat_input')"
            >Welding Paw Plasma Heat Input</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_gtaw_tig_amperage')"
            >Welding GTAW TIG Amperage</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="250"
            *ngIf="shouldShowColumn('welding_gtaw_tig_plasma_volt_range')"
            >Welding GTAW TIG Plasma Volt Range</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_gtaw_tig_travel_speed')"
            >Welding GTAW TIG Travel Speed</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('welding_gtaw_tig_heat_input')"
            >Welding GTAW TIG Heat Input</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_analysis_c')"
            >Chemical Analysis C</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_analysis_cr')"
            >Chemical Analysis Cr</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_analysis_mo')"
            >Chemical Analysis Mo</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_analysis_ni')"
            >Chemical Analysis Ni</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_pren_sp1_base')"
            >Chemical Pren SP1 Base</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_pren_sp2_seam_weld')"
            >Chemical Pren SP2 Seam Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_pren_test')"
            >Chemical Pren Test</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_ys_sp1_lon_base')"
            >Tensile YS SP1 Lon Base</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_ys_sp2_tra_weld')"
            >Tensile YS SP2 TRA Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_ys_sp3_tra_weld')"
            >Tensile YS SP3 TRA Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_ys_state')"
            >Tensile YS State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_strength_rm_sp1_lon')"
            >Tensile Strength RM SP1 Lon</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="230"
            *ngIf="shouldShowColumn('tensile_strength_rm_sp2_tra_weld')"
            >Tensile Strength RM SP2 TRA Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="230"
            *ngIf="shouldShowColumn('tensile_strength_rm_sp3_tra_weld')"
            >Tensile Strength RM SP3 TRA Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_strength_rm_state')"
            >Tensile Strength RM State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_elongation_sp1_lon_base')"
            >Tensile Elongation SP1 Lon Base</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="220"
            *ngIf="shouldShowColumn('tensile_elongation_sp2_tra_weld')"
            >Tensile Elongation SP2 TRA Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="220"
            *ngIf="shouldShowColumn('tensile_elongation_sp3_tra_weld')"
            >Tensile Elongation SP3 TRA Weld</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('tensile_elongation_state')"
            >Tensile Elongation State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('guided_bend_root_sp1')"
            >Guided Bend Root SP1</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('guided_bend_root_sp2')"
            >Guided Bend Root SP2</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('guided_bend_face_sp1')"
            >Guided Bend Face SP1</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('guided_bend_face_sp2')"
            >Guided Bend Face Sp2</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('guided_bend_state')"
            >Guided Bend State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_1')"
            >Hardness Point 1</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_2')"
            >Hardness Point 2</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_3')"
            >Hardness Point 3</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_4')"
            >Hardness Point 4</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_5')"
            >Hardness Point 5</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_6')"
            >Hardness Point 6</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_7')"
            >Hardness Point 7</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_8')"
            >Hardness Point 8</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_9')"
            >Hardness Point 9</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_10')"
            >Hardness Point 10</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_11')"
            >Hardness Point 11</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_12')"
            >Hardness Point 12</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_13')"
            >Hardness Point 13</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_14')"
            >Hardness Point 14</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_15')"
            >Hardness Point 15</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_point_16')"
            >Hardness Point 16</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('hardness_state')"
            >Hardness State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('metalographic_macro_state')"
            >Metalographic Macro State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('metalographic_micro_state')"
            >Metalographic Micro State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('corrosion_sp1_base_seam')"
            >Corrosion SP1 Base Seam</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('corrosion_sp1_base_seam_state')"
            >Corrosion SP1 Base Seam State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('corrosion_sp2_pipe_body')"
            >Corrosion SP2 Pipe Body</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('corrosion_sp2_pipe_body_state')"
            >Corrosion SP2 Pipe Body State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('corrosion_state')"
            >Corrosion State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('inspection_date')"
            >Inspection Date</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('remarks')"
            >Remarks</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('project_name')">{{
              data['project_name']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('client')">{{
              data['client']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('sub_contractor')">{{
              data['sub_contractor']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('type')">{{
              data['type']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('dimension')">{{
              data['dimension']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('material')">{{
              data['material']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('pipe_num')">{{
              data['pipe_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('coil_heat_num')">{{
              data['coil_heat_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('coil_lot_num')">{{
              data['coil_lot_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wire_heat_num')">{{
              data['wire_heat_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('qualification')">{{
              data['qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('length_m')">{{
              data['length_m']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('length_state')">{{
              data['length_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_r_0')">{{
              data['od_pipe_end_r_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_r_90')">{{
              data['od_pipe_end_r_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_mid_0')">{{
              data['od_pipe_end_mid_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_mid_90')">{{
              data['od_pipe_end_mid_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_y_0')">{{
              data['od_pipe_end_y_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_pipe_end_y_90')">{{
              data['od_pipe_end_y_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('od_state')">{{
              data['od_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_02_length')">{{
              data['strgh_02_length']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_pipe_end_r_0_1atm')">{{
              data['strgh_pipe_end_r_0_1atm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_pipe_end_mid_0')">{{
              data['strgh_pipe_end_mid_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_pipe_end_y_0_1atm')">{{
              data['strgh_pipe_end_y_0_1atm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_pipe_end_r_90_1atm')">{{
              data['strgh_pipe_end_r_90_1atm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_pipe_end_mid_90')">{{
              data['strgh_pipe_end_mid_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_pipe_end_y_90_1atm')">{{
              data['strgh_pipe_end_y_90_1atm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('strgh_state')">{{
              data['strgh_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('liner_cap_height')">{{
              data['liner_cap_height']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('liner_cap_state')">{{
              data['liner_cap_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_r_0')">{{
              data['wt_pipe_end_r_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_r_90')">{{
              data['wt_pipe_end_r_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_r_180')">{{
              data['wt_pipe_end_r_180']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_r_270')">{{
              data['wt_pipe_end_r_270']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_mid_0')">{{
              data['wt_pipe_end_mid_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_mid_90')">{{
              data['wt_pipe_end_mid_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_mid_180')">{{
              data['wt_pipe_end_mid_180']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_mid_270')">{{
              data['wt_pipe_end_mid_270']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_y_0')">{{
              data['wt_pipe_end_y_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_y_90')">{{
              data['wt_pipe_end_y_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_y_180')">{{
              data['wt_pipe_end_y_180']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_pipe_end_y_270')">{{
              data['wt_pipe_end_y_270']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('wt_state')">{{
              data['wt_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('penetration_test')">{{
              data['penetration_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('radiography_test')">{{
              data['radiography_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('visual_test')">{{
              data['visual_test']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_paw_plasma_wire_size')"
              >{{ data['welding_paw_plasma_wire_size'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_paw_plasma_wire_speed')"
              >{{ data['welding_paw_plasma_wire_speed'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_paw_plasma_amperage')"
              >{{ data['welding_paw_plasma_amperage'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_paw_plasma_volt_range')"
              >{{ data['welding_paw_plasma_volt_range'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_paw_plasma_travel_speed')"
              >{{ data['welding_paw_plasma_travel_speed'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_paw_plasma_heat_input')"
              >{{ data['welding_paw_plasma_heat_input'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_gtaw_tig_amperage')"
              >{{ data['welding_gtaw_tig_amperage'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_gtaw_tig_plasma_volt_range')"
              >{{ data['welding_gtaw_tig_plasma_volt_range'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_gtaw_tig_travel_speed')"
              >{{ data['welding_gtaw_tig_travel_speed'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('welding_gtaw_tig_heat_input')"
              >{{ data['welding_gtaw_tig_heat_input'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_analysis_c')">{{
              data['chemical_analysis_c']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_analysis_cr')">{{
              data['chemical_analysis_cr']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_analysis_mo')">{{
              data['chemical_analysis_mo']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_analysis_ni')">{{
              data['chemical_analysis_ni']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_pren_sp1_base')">{{
              data['chemical_pren_sp1_base']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('chemical_pren_sp2_seam_weld')"
              >{{ data['chemical_pren_sp2_seam_weld'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_pren_test')">{{
              data['chemical_pren_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('tensile_ys_sp1_lon_base')">{{
              data['tensile_ys_sp1_lon_base']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('tensile_ys_sp2_tra_weld')">{{
              data['tensile_ys_sp2_tra_weld']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('tensile_ys_sp3_tra_weld')">{{
              data['tensile_ys_sp3_tra_weld']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('tensile_ys_state')">{{
              data['tensile_ys_state']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_strength_rm_sp1_lon')"
              >{{ data['tensile_strength_rm_sp1_lon'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_strength_rm_sp2_tra_weld')"
              >{{ data['tensile_strength_rm_sp2_tra_weld'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_strength_rm_sp3_tra_weld')"
              >{{ data['tensile_strength_rm_sp3_tra_weld'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_strength_rm_state')"
              >{{ data['tensile_strength_rm_state'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_elongation_sp1_lon_base')"
              >{{ data['tensile_elongation_sp1_lon_base'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_elongation_sp2_tra_weld')"
              >{{ data['tensile_elongation_sp2_tra_weld'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('tensile_elongation_sp3_tra_weld')"
              >{{ data['tensile_elongation_sp3_tra_weld'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('tensile_elongation_state')">{{
              data['tensile_elongation_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('guided_bend_root_sp1')">{{
              data['guided_bend_root_sp1']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('guided_bend_root_sp2')">{{
              data['guided_bend_root_sp2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('guided_bend_face_sp1')">{{
              data['guided_bend_face_sp1']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('guided_bend_face_sp2')">{{
              data['guided_bend_face_sp2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('guided_bend_state')">{{
              data['guided_bend_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_1')">{{
              data['hardness_point_1']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_2')">{{
              data['hardness_point_2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_3')">{{
              data['hardness_point_3']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_4')">{{
              data['hardness_point_4']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_5')">{{
              data['hardness_point_5']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_6')">{{
              data['hardness_point_6']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_7')">{{
              data['hardness_point_7']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_8')">{{
              data['hardness_point_8']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_9')">{{
              data['hardness_point_9']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_10')">{{
              data['hardness_point_10']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_11')">{{
              data['hardness_point_11']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_12')">{{
              data['hardness_point_12']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_13')">{{
              data['hardness_point_13']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_14')">{{
              data['hardness_point_14']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_15')">{{
              data['hardness_point_15']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_point_16')">{{
              data['hardness_point_16']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('hardness_state')">{{
              data['hardness_state']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('metalographic_macro_state')"
              >{{ data['metalographic_macro_state'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('metalographic_micro_state')"
              >{{ data['metalographic_micro_state'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('corrosion_sp1_base_seam')">{{
              data['corrosion_sp1_base_seam']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('corrosion_sp1_base_seam_state')"
              >{{ data['corrosion_sp1_base_seam_state'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('corrosion_sp2_pipe_body')">{{
              data['corrosion_sp2_pipe_body']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('corrosion_sp2_pipe_body_state')"
              >{{ data['corrosion_sp2_pipe_body_state'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('corrosion_state')">{{
              data['corrosion_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('inspection_date')">{{
              data['inspection_date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('remarks')">{{
              data['remarks']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>

        <clr-datagrid
          *ngIf="items && items.length && getTest() === 'all_coil'"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('project_name')"
            >Project Name</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('client')"
            >Client</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('sub_contractor')"
            >sub_contractor</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('type')"
            >type</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="400"
            *ngIf="shouldShowColumn('dimension')"
            >Dimension</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="550"
            *ngIf="shouldShowColumn('material')"
            >Material</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('heat_num')"
            >Heat Num</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('lot_num')"
            >Lot_Num</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('qualification')"
            >Qualification</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('dim_coil_thickness_mm')"
            >Dim Coil Thickness(mm)</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('dim_thickness_state')"
            >Dim Thickness State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('dim_coil_width_mm')"
            >Dim Coil Width(mm)</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('dim_width_state')"
            >Dim Width State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_c')"
            >Chemical Coil C</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_si')"
            >Chemical Coil SI</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_mn')"
            >Chemical Coil MN</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_p')"
            >Chemical Coil P</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_s')"
            >Chemical Coil S</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_cr')"
            >Chemical Coil CR</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_nb_ta')"
            >Chemical Coil Nb Ta</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_co')"
            >Chemical Coil CO</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_mo')"
            >Chemical Coil MO</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_fe')"
            >Chemical Coil Fe</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_al')"
            >Chemical Coil Al</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_ti')"
            >Chemical Coil TI</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_ni')"
            >Chemical Coil Ni</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_n')"
            >Chemical Coil N</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_state')"
            >Chemical Coil State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_c')"
            >Chemical Weld Wire C</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_si')"
            >Chemical Weld Wire SI</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_mn')"
            >Chemical Weld Wire Mn</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_p')"
            >Chemical Weld Wire P</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_s')"
            >Chemical Weld Wire S</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_cr')"
            >Chemical Weld Wire Cr</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_nb_ta')"
            >Chemical Weld Wire Nb Ta</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_co')"
            >Chemical Weld Wire CO</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_mo')"
            >Chemical Weld Wire Mo</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_fe')"
            >Chemical Weld Wire Fe</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_al')"
            >Chemical Weld Wire Al</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_ti')"
            >Chemical Weld Wire Ti</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_ni')"
            >Chemical Weld Wire Ni</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_n')"
            >Chemical Weld Wire N</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_state')"
            >Chemical Weld Wire State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_coil_pre')"
            >Chemical Coil Pre</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_weld_wire_pre')"
            >Chemical Weld Wire Pre</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('chemical_pre_state')"
            >Chemical Pre State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('rzdin_roughness_value')"
            >Rzdin Roughness Value</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('rzdin_roughness_state')"
            >Rzdin Roughness State</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('datasheet_tensile')"
            >Datasheet Tensile</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('datasheet_yield')"
            >Datasheet Yield</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('datasheet_elongation')"
            >Datasheet Elongation</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('datasheet_hardness')"
            >Datasheet Hardness</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('inspection_date')"
            >Inspection Date</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('remarks')"
            >Remarks</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('project_name')">{{
              data['project_name']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('client')">{{
              data['client']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('sub_contractor')">{{
              data['sub_contractor']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('type')">{{
              data['type']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('dimension')">{{
              data['dimension']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('material')">{{
              data['material']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('heat_num')">{{
              data['heat_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('lot_num')">{{
              data['lot_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('qualification')">{{
              data['qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('dim_coil_thickness_mm')">{{
              data['dim_coil_thickness_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('dim_thickness_state')">{{
              data['dim_thickness_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('dim_coil_width_mm')">{{
              data['dim_coil_width_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('dim_width_state')">{{
              data['dim_width_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_c')">{{
              data['chemical_coil_c']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_si')">{{
              data['chemical_coil_si']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_mn')">{{
              data['chemical_coil_mn']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_p')">{{
              data['chemical_coil_p']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_s')">{{
              data['chemical_coil_s']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_cr')">{{
              data['chemical_coil_cr']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_nb_ta')">{{
              data['chemical_coil_nb_ta']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_co')">{{
              data['chemical_coil_co']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_mo')">{{
              data['chemical_coil_mo']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_fe')">{{
              data['chemical_coil_fe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_al')">{{
              data['chemical_coil_al']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_ti')">{{
              data['chemical_coil_ti']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_ni')">{{
              data['chemical_coil_ni']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_n')">{{
              data['chemical_coil_n']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_state')">{{
              data['chemical_coil_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_c')">{{
              data['chemical_weld_wire_c']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_si')">{{
              data['chemical_weld_wire_si']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_mn')">{{
              data['chemical_weld_wire_mn']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_p')">{{
              data['chemical_weld_wire_p']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_s')">{{
              data['chemical_weld_wire_s']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_cr')">{{
              data['chemical_weld_wire_cr']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_nb_ta')">{{
              data['chemical_weld_wire_nb_ta']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_co')">{{
              data['chemical_weld_wire_co']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_mo')">{{
              data['chemical_weld_wire_mo']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_fe')">{{
              data['chemical_weld_wire_fe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_al')">{{
              data['chemical_weld_wire_al']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_ti')">{{
              data['chemical_weld_wire_ti']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_ni')">{{
              data['chemical_weld_wire_ni']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_n')">{{
              data['chemical_weld_wire_n']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_state')">{{
              data['chemical_weld_wire_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_coil_pre')">{{
              data['chemical_coil_pre']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_weld_wire_pre')">{{
              data['chemical_weld_wire_pre']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('chemical_pre_state')">{{
              data['chemical_pre_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('rzdin_roughness_value')">{{
              data['rzdin_roughness_value']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('rzdin_roughness_state')">{{
              data['rzdin_roughness_state']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('datasheet_tensile')">{{
              data['datasheet_tensile']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('datasheet_yield')">{{
              data['datasheet_yield']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('datasheet_elongation')">{{
              data['datasheet_elongation']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('datasheet_hardness')">{{
              data['datasheet_hardness']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('inspection_date')">{{
              data['inspection_date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('remarks')">{{
              data['remarks']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="sheetFilter !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <clr-select-container>
          <label>Formato</label>
          <select
            clrSelect
            [(ngModel)]="sheetFormat"
          >
            <!-- <option value="1">Pipe Movement Track</option> -->
            <option value="2">Manufacturer Pipe TS</option>
            <option value="3">Pipe Chemical Data</option>
            <option value="4">Liner Chemical Data</option>
            <option value="5">Pipe Impact Data</option>
            <option value="6">Pipe Toughness Data</option>
            <option value="7">Pipe Corrosion Data</option>
            <option value="8">Pipe Tensile Hardness Data</option>
            <!-- <option value="9">Pipe BendTest Data</option> -->
            <!-- <option value="10">Pipe IntCoating Data</option> -->
            <!-- <option value="11">Pipe Coating Data</option> -->
          </select>

          <!-- <clr-control-error *clrIfError="unit.invalid && unit.touched">
            
                        {{'scope.unit.invalid' | translate}}
                    </clr-control-error> -->
        </clr-select-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="sheetFilter = 'none'"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      (click)="filterSheet()"
      class="btn btn-outline"
    >
      {{ 'application.commons.buttons.apply' | translate }}
    </button>
  </div>
</clr-modal>
