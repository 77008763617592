import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { DataBookBaseComponent } from '../databook-base.component';
import { DataBookState } from '../../services/databook.state';
import { DataBookService } from '../../services/databook.service';
import { CoreService } from '../../core/core.service';
import { PdfService } from '../../components/pdf/pdf.service';

import { ProductModal, AllProducts } from './product.types';
import { allProducts } from './product.constants';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import { Product } from 'src/app/classes';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteProductModalComponent } from './delete-product-modal/delete-product-modal.component';
import { CreateEditProductModalComponent } from './create-edit-product-modal/create-edit-product-modal.component';
import { ClrCommonFormsModule, ClrIconModule } from '@clr/angular';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { NgIf, NgFor } from '@angular/common';
import { BasePageComponent } from '../../core/components/page/base-page.component';
import { MobxAngularModule } from 'mobx-angular';

@Component({
  selector: 'data-flow-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [
    MobxAngularModule,
    BasePageComponent,
    NgIf,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    NgFor,
    ClrIconModule,
    CreateEditProductModalComponent,
    DeleteProductModalComponent,
    TranslateModule,
  ],
})
export class DataBookProductComponent extends DataBookBaseComponent {
  modalIsOpen = false;
  productModal: ProductModal = {
    isEdit: false,
    isSave: false,
    confirmDelete: false,
    deleteByText: false,
  };
  isDeleteForm: FormGroup;
  isSaveAndEditForm: FormGroup;
  isEditForm: FormGroup;

  product: Product | null = null;

  products: Product[] = [] as Product[];
  allAvailableProducts: AllProducts[] = [] as AllProducts[];
  allProducts: AllProducts[] = allProducts;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    public formBuilder: FormBuilder,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
    this.isDeleteForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
    });

    this.isSaveAndEditForm = this.formBuilder.group({
      description: new FormControl('', Validators.required),
      selectedProduct: new FormControl('', Validators.required),
      icon: [null],
      supplier: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      lastModifiedDate: new FormControl(''),
      lastModifiedBy: new FormControl(''),
    });
  }

  async onPageInit() {
    // try {
    //   await super.initProjectProduct();
    //   this.coreService.showLoading();
    //   // this.products = _.find(this.dataBookState.projects, { id: this.dataBookState.project }).products || [];
    //   const params = { project: this.dataBookState.project };
    //   const result = await this.dataBookService.product(params);
    //
    //   this.products = result?.products;
    //
    //   this.coreService.hideLoading();
    // } catch (error) {
    //   this.coreService.processError(error);
    // }
    this.store.activePageForBreadcrumb = 'Product page';
  }

  populateAllAvailableProducts() {
    this.allAvailableProducts = [];
    for (const prod of this.allProducts) {
      const found = _.find(this.store.products, (item) => {
        return item.gsi_id === prod.id;
      });
      if (
        (!found || found.deleted) &&
        !this.allAvailableProducts.includes(prod)
      ) {
        this.allAvailableProducts.push(prod);
      }
    }
  }

  openModalProduct() {
    this.populateAllAvailableProducts();

    this.isSaveAndEditForm.controls['description'].setValue('');
    this.isSaveAndEditForm.controls['selectedProduct'].setValue('');
    this.isSaveAndEditForm.controls['supplier'].setValue('');
    this.isSaveAndEditForm.controls['name'].setValue('');
    this.isSaveAndEditForm.controls['lastModifiedDate'].setValue('');
    this.isSaveAndEditForm.controls['lastModifiedBy'].setValue('');

    this.openModal('isSave');
  }

  getName(name) {
    if (
      name !== 'Welding' &&
      name !== 'Anti Corrosive Coating' &&
      name !== 'Double Joint' &&
      name !== 'C Steel' &&
      name !== 'Coating' &&
      name !== 'Host pipe'
    ) {
      return 'Host pipe';
    }
    return name;
  }

  openModalEditProduct(product: Product) {
    this.populateAllAvailableProducts();
    this.store.selectedProduct = product;
    this.product = product;
    this.isSaveAndEditForm.controls['description'].setValue(
      this.store.selectedProduct.description || '',
    );
    this.isSaveAndEditForm.controls['selectedProduct'].setValue(
      this.store.selectedProduct.name || '',
    );
    this.isSaveAndEditForm.controls['name'].setValue(
      this.store.selectedProduct.name || '',
    );
    this.isSaveAndEditForm.controls['supplier'].setValue(
      this.store.selectedProduct.supplier || '',
    );
    this.isSaveAndEditForm.controls['lastModifiedDate'].setValue(
      this.store.selectedProduct.lastmodifieddate || '',
    );
    this.isSaveAndEditForm.controls['lastModifiedBy'].setValue(
      this.store.selectedProduct.lastmodifiedby || '',
    );
    this.openModal('isEdit');
  }

  openDeleteModal(product: Product) {
    console.log({ product });
    this.product = product;
    this.store.selectedProduct = product;
    this.openModal('confirmDelete');
  }

  canProductConfig() {
    if (this.userStore.user.roles.PRODUCT_CONFIG) {
      return true;
    }
    return false;
  }

  getCondition() {
    return !this.store.selectedProject.gsi_id;
  }

  isProductVisible() {
    if (this.userStore.user.roles.PRODUCT_CONFIG) {
      return true;
    }
    return false;
  }

  canSeeProduct(productName: string) {
    if (
      this.userStore.user?.roles?.PROJECTS[this.store?.selectedProject?.gsi_id]
    ) {
      if (
        Object.keys(
          this.userStore.user.roles.PROJECTS[
            this.store?.selectedProject?.gsi_id
          ].PRODUCT,
        ).includes(productName)
      ) {
        return true;
      }
    }
    if (this.userStore.user?.roles?.PROJECTS[this.ALL_PROJECTS_READ_ONLY]) {
      return this.userStore.user.roles.PROJECTS[this.ALL_PROJECTS_READ_ONLY]
        .PRODUCT[this.ALL_PRODUCTS];
    }
  }

  escapeRegExp(str) {
    return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  async onSelectClick(product: Product) {
    this.store.selectedProduct = product;

    this.dataBookState.olddataexplorerintegration =
      product.olddataexplorerintegration;

    this.coreService.hideLoading();
    this.store.gotoProduct(
      this.store.selectedProject.slug,
      this.store.selectedProduct.slug,
    );
  }

  updateProducts = (products: Product[]) => {
    console.log('products === > ', products);
    this.products = products;
  };

  openModal = (modalName: string) => {
    for (const modal of Object.keys(this.productModal)) {
      if (modal === modalName) {
        this.productModal[modalName] = true;
        continue;
      }

      this.productModal[modal] = false;
    }

    this.modalIsOpen = true;
  };

  closeModal = () => {
    for (const modal of Object.keys(this.productModal)) {
      this.productModal[modal] = false;
    }

    this.modalIsOpen = false;
    this.product = null;
    this.isDeleteForm.reset();
    this.isSaveAndEditForm.reset();
  };
}
