import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'databook.dataexplorer.column.title.individual.identification',
  headerClass: 'custom-header-pinned',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      children: [
        {
          field: 'databook.dataexplorer.column.title.piece.number',
          pinned: 'left',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['piece_number']],
        },
        {
          field: 'databook.dataexplorer.column.title.ippn',
          pinned: 'left',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorerIppn',
          filter: 'agTextColumnFilter',
          valueGetter: (params: any) => params.data[COLUMNS['ippn']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      columnGroupShow: 'open',
      children: [
        {
          field: 'databook.dataexplorer.column.title.mill.item',
          pinned: 'left',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['item']],
        },
        {
          field: 'databook.dataexplorer.column.title.customer.item',
          pinned: 'left',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => params.data[COLUMNS['customer_item']],
        },
        {
          field: 'databook.dataexplorer.column.title.file',
          pinned: 'left',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['tally_list_file_name']],
        },
      ],
    },
  ],
};
