import { Component, OnDestroy } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { DataBookBaseComponent } from '../databook-base.component';
import { TreeviewFileItem, TreeviewFolderItem } from '../../classes';
import { TestTypesEnum } from '../../enums';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { ClrCommonFormsModule } from '@clr/angular';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';
declare let gtag;
@Component({
  templateUrl: 'plp.component.html',
  styleUrls: ['plp.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    NgIf,
    TranslateModule,
  ],
})
export class PlpComponent extends DataBookBaseComponent {
  idSheetPlatina;
  platinaSheetName;

  async onPageInit() {
    await super.initProjectProduct();
    if (this.store && this.store.selectedProject.gsi_id === 'PLATINA') {
      const params = {
        extraDocs: false,
        init: true,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      const result: {
        existingDocument: TreeviewFileItem[];
        definitionDocument: TreeviewFolderItem[];
        exists: any;
      } = await this.dataBookService.documents(params);

      console.log({ result });

      let folderId = null;
      for (const rootFolder of result.definitionDocument) {
        if (rootFolder.id === this.dataBookState.product) {
          for (const secondLevelFolder of rootFolder.folders) {
            if (secondLevelFolder.name.toUpperCase() === 'TFMC SHEET') {
              folderId = secondLevelFolder.id;
              break;
            }
          }
          break;
        }
      }

      for (const file of result.existingDocument) {
        if (file.folder_id === folderId) {
          this.idSheetPlatina = file.id;
          this.platinaSheetName = file.description;
          break;
        }
      }
    }
  }

  isHicVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isSccVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isDimensionsVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isCtodVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isTensileVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isImpactVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isHardnessVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isChemicalVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isAdhesionVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  isPelmVisible() {
    if (
      super.getRoleByProject().DATA_EXPLORER &&
      ((this.store && this.store.selectedProject.gsi_id !== 'SEPIA') ||
        (this.store &&
          this.store.selectedProject.gsi_id === 'SEPIA' &&
          this.store.selectedProduct.gsi_id !== 'liner' &&
          this.store.selectedProduct.gsi_id !== 'ANTI_CORROSIVE_COATING'))
    ) {
      return true;
    }
    return false;
  }

  async downloadFileMlp() {
    this.coreService.showLoading();
    const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
    const fileLink = {
      category: '0b26cfd0-33cc-11eb-98b1-5ddb3241e2bc',
      complete_path: '/DataSheet',
      datetime: 1594813176770,
      description: 'Cladtek - 23_10_2020 DM.XLSX',
      id: this.dataBookService.config.environment.production
        ? '0b26cfd0-33cc-11eb-98b1-5ddb3241e2bc'
        : '0b26cfd0-33cc-11eb-98b1-5ddb3241e2bc',
      name: 'Cladtek - 23_10_2020 DM.XLSX',
      product: 'MLP',
      project: 'MERO',
    };

    const params = {
      extraDocs: false,
      download: [fileLink],
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);
    a.href = linkFile.downloadFiles;

    this.coreService.hideLoading();
    a.click();
  }

  async downloadFileDoubleJoint() {
    this.coreService.showLoading();
    const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
    const fileLink = {
      category: '889be7a0-1475-11eb-8873-7346a46f17e4',
      complete_path: '/Data Sheet',
      datetime: 1594813176770,
      description: 'Double joint template V1_vicente_Platina.xls',
      id: this.dataBookService.config.environment.production
        ? '2e6d2390-1491-11eb-859c-53a5ee9c3084'
        : '8b796510-1475-11eb-8873-7346a46f17e4',
      name: 'Double joint template V1_vicente_Platina.xls',
      product: 'DOUBLE_JOINT',
      project: 'PLATINA',
    };
    const params = {
      extraDocs: false,
      download: [fileLink],
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);
    a.href = linkFile.downloadFiles;

    this.coreService.hideLoading();
    a.click();
  }

  async downloadFileMero(index) {
    this.coreService.showLoading();
    const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
    const dsheet = JSON.parse(this.store.material.datasheet);
    const fileLink = {
      category: dsheet[index].category,
      complete_path: '/Data Sheet',
      datetime: dsheet[index].datetime,
      description: dsheet[index].description,
      id: dsheet[index].id,
      name: dsheet[index].name,
      product: this.store.selectedProduct.gsi_id,
      project: this.dataBookState.projectCode,
    };

    const params = {
      extraDocs: false,
      download: [fileLink],
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);
    a.href = linkFile.downloadFiles;

    this.coreService.hideLoading();

    a.click();
  }

  async downloadFile() {
    this.coreService.showLoading();
    const a = document.getElementById('downloadFiles') as HTMLAnchorElement;
    const dsheet = JSON.parse(this.store.material.datasheet);
    const fileLink = {
      category: dsheet.category,
      complete_path: '/Data Sheet',
      datetime: dsheet.datetime,
      description: dsheet.description,
      id: dsheet.id,
      name: dsheet.name,
      product: this.store.selectedProduct.gsi_id,
      project: this.dataBookState.projectCode,
    };
    const params = {
      extraDocs: false,
      download: [fileLink],
      project: this.store.selectedProject.gsi_id,
      product: this.store.selectedProduct.gsi_id,
      productId: this.store.selectedProduct.id,
    };
    this.cfgVlr(params);
    const linkFile = await this.dataBookService.documents(params);
    a.href = linkFile.downloadFiles;

    this.coreService.hideLoading();

    a.click();
  }

  async sendGtag(eventAction, testType) {
    const param = {
      // feature:  'Data explorer',
      // category: 'action',
      // event: eventAction,
      // test: testType,
      // pageName: '/main/dataExplorer',
      // userType: this.coreService.getConfig().currentUser.type,
      // project: this.dataBookState.project
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature: 'Data explorer',
      spn_action: eventAction,
      spn_userType: this.coreService.getConfig().currentUser.type,
    };
    gtag('event', eventAction, param);
  }

  async cardClick(testType: TestTypesEnum | string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        material: this.store.material.description,
      },
    };
    this.sendGtag('view', testType);
    this.store.gotoDataExplorerTestType(testType);
  }

  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'dataExplorer.select.test',
      null,
    );

    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },

      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },

      {
        name: this.coreService.translate('databook.data.explorer', null),
        link: '/',
        type: this.coreService.translate('databook.dashboard', null),
      },

      {
        name: this.store.material ? this.store.material.description : '',
        type: this.coreService.translate('databook.material.project', null),
        link: '/main/material',
      },
    ];
  }

  getCondition() {
    return false;
  }

  public checkAndBack(condition) {
    if (!this.store.selectedProject.gsi_id || !this.dataBookState.product) {
      this.coreService.getRouter().navigate(['/']);
    } else if (!this.store.materialId) {
      this.coreService.showAlertErrorTranslate('select.material.error');
      this.store.gotoPage('material');
      // this.coreService.getRouter().navigate(['/main/material']);
    }
  }
}
