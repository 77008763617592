import { UserPermissionsFlat } from './user-permissions-flat.dto';

export class CreateUserDTO {
  email: string;
}

export class UserDTO {
  id: number;
  email: string;
  permissions?: UserPermissionsFlat[];
}
