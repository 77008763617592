import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import { DataBookService } from '../../../services/databook.service';
import { BaseComponent } from '../base.component';
import { CoreService } from '../../core.service';
import { TranslateModule } from '@ngx-translate/core';
import { ContactUsComponent } from '../../../components/contact-us/contact-us.component';
import { SearchComponent } from '../search/search.component';
import { FormsModule } from '@angular/forms';
import {
  ClrIconModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrDropdownModule,
  ClrConditionalModule,
  ClrCommonFormsModule,
} from '@clr/angular';
import { NgIf, NgFor } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'vic-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
  standalone: true,
  imports: [
    LoadingComponent,
    NgIf,
    ClrIconModule,
    FormsModule,
    NgFor,
    SearchComponent,
    ContactUsComponent,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrDropdownModule,
    ClrConditionalModule,
    ClrCommonFormsModule,
    TranslateModule,
  ],
})
export class BasePageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input()
  image;

  @Input()
  title;

  @Input()
  project;

  @Input()
  hideHeader = false;

  @Input()
  hideBackButton = false;

  @Input()
  showSearch = false;

  // searchText: string = '';

  @Output()
  search = new EventEmitter();

  currentLanguage: string = null;

  autocompletePosition = { left: 0, top: 0 };

  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      this.treeviewStore.searchAutocompleteCandidatesGlobal.length > 0 &&
      this.autoCompleteInput &&
      this.autoCompleteInput.nativeElement
    ) {
      if (!this.autoCompleteInput.nativeElement.contains(event.target)) {
        this.treeviewStore.searchAutocompleteCandidatesGlobal = [];
        this.treeviewStore.searchAutocompleteCandidatesGlobalFeatures = [];

        this.treeviewStore.searchTextGlobal = '';
      }
    }
  }

  updateAutocompletePosition() {
    const inputField = document.querySelector(
      '.treeview-search-input-field-global',
    );
    const rect = inputField.getBoundingClientRect();
    this.autocompletePosition.left = rect.left - 15;
    this.autocompletePosition.top = rect.bottom;
  }

  hideReturnButton() {
    // console.log('ACTIVE PAGE',this.store.activePageForBreadcrumb)
    return this.store.activePageForBreadcrumb !== 'Home';
  }

  ngAfterViewInit() {
    this.treeviewStore.basePageComponent = this;
    // on page scroll call this.updateAutocompletePosition
    window.addEventListener(
      'scroll',
      this.updateAutocompletePosition.bind(this),
      true,
    );
    this.treeviewStore.dataBookService = this.dataBookService;
  }

  constructor(
    protected coreService: CoreService,
    public dataBookService: DataBookService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(coreService);
  }

  async ngOnInit() {
    this.currentLanguage = this.coreService.getLanguage();
    this.onPageInit();
    setTimeout(() => {
      if (document.getElementById('content-area')) {
        document.getElementById('content-area').scrollTop = 0;
      }
    }, 100);

    this.registryLog(this.logEvents.PAGE_IN);
  }

  async onMySignaturesClick() {
    this.coreService.getRouter().navigate(['my-signatures']);
  }

  async onRefreshClick() {
    this.onPageInit();
  }

  async onCloseClick() {
    this.coreService.getLocation().back();
  }

  async onLanguageClick(language: string) {
    this.coreService.switchLanguage(language);
    this.currentLanguage = this.coreService.getLanguage();
  }

  async onLogoutClick() {
    this.coreService.logout();
  }

  async onLoginHistoryClick() {
    this.coreService.getRouter().navigate(['/security/login-history']);
  }

  async onSearchChange(searchText) {
    this.search.emit(searchText);
  }

  home() {
    this.coreService.getRouter().navigate(['']);
  }
}
