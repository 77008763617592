import { twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'databook.dataexplorer.column.title.actual.length.and.mass',
  headerClass: 'custom-header-pinned',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-pinned',
      children: [
        {
          field: 'databook.dataexplorer.column.title.length',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['pipe_length']]),
          valueGetter: (params: any) => params.data[COLUMNS['pipe_length']],
        },
        {
          field: 'databook.dataexplorer.column.title.weight',
          headerClass: 'custom-header-pinned',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber('', params.data[COLUMNS['pipe_weight']]),
          valueGetter: (params: any) => params.data[COLUMNS['pipe_weight']],
        },
      ],
    },
  ],
};
