import { Component, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { DataBookBaseComponent } from '../../../databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';
import {
  ClrModalModule,
  ClrCheckboxModule,
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrIconModule,
} from '@clr/angular';

@Component({
  selector: 'config-workflow',
  templateUrl: './config-workflow.component.html',
  styleUrls: ['./config-workflow.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    NgIf,
    ClrCheckboxModule,
    FormsModule,
    ClrCommonFormsModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgFor,
    ClrIconModule,
    NgClass,
    TranslateModule,
  ],
})
export class ConfigWorkflowComponent {
  @Input() isModalOpen = false;
  @Input() closeModal: () => void;

  @Input() isReleaseVisible: () => boolean;
  @Input() canDeactivate: () => boolean;

  @Input() newWorkflow: () => void;
  @Input() openEditWorkflowModal: (
    id: number,
    version: number,
    name: string,
    steps: any,
  ) => void;
  @Input() openDeleteWorkflowModal: (id: number) => void;
  @Input() formatDate: (date: string) => any;

  @Input() workflowData: any = [];
  @Input() showNewWorkflowButton = false;

  sequential = true;
}
