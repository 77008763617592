import { zeroDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'PRODUCT TENSILE LONGITUDINAL',
  headerClass: 'custom-header-4',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'HTL',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_htl']],
        },
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) => {
            return params.data[COLUMNS['tensile_longitudinal_sampling_side']];
          },
        },
        {
          field: 'Elongation',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_longitudinal_elongation']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_elongation']],
        },
        {
          field: 'AREACPLO',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_longitudinal_areacplo']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_areacplo']],
        },
        {
          field: 'YS/TS',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_longitudinal_ys_ts']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_ys_ts']],
        },
        {
          field: 'Yield Strength',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_longitudinal_yield_strength']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_yield_strength']],
        },
        {
          field: 'Tensile Strength',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['tensile_longitudinal_tensile_strength']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_tensile_strength']],
        },
      ],
    },
    {
      headerName: '',
      columnGroupShow: 'open',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['tensile_longitudinal_file_name']],
        },
      ],
    },
  ],
};
