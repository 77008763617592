import { twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'PELM DATA',
  headerClass: 'custom-header-2',
  children: [
    {
      headerName: 'Order Information',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'OD Tolerances',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_od_tolerances']],
        },
        {
          field: 'ID Tolerances',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_id_tolerances']],
        },
        {
          field: 'WT Tolerances',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_wt_tolerances']],
        },
      ],
    },
    {
      headerName: 'Ref. Value',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Actual ID',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_actual_id']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_actual_id']],
        },
      ],
    },
    {
      headerName: 'Dimensional Tolerance',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'OD Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_minimum_od']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_minimum_od']],
        },
        {
          field: 'OD Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_maximum_od']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_maximum_od']],
        },
        {
          field: 'ID Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_minimum_id']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_minimum_id']],
        },
        {
          field: 'ID Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_maximum_id']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_maximum_id']],
        },
        {
          field: 'WT Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_minimum_wt']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_minimum_wt']],
        },
        {
          field: 'WT Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['laser_flex_line_maximum_wt']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['laser_flex_line_maximum_wt']],
        },
      ],
    },
  ],
};
