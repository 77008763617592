<vic-base-page page="tpi-flow">
  <div class="header-actions container">
    <a
      class="btn-link"
      id="downloadFiles"
      [hidden]="true"
    ></a>
  </div>

  <div class="page-body-container">
    <div *ngIf="!isTPIVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div style="text-align: right">
      <button
        class="btn btn-primary"
        (click)="validateUpload()"
        *ngIf="canUpload()"
        id="uploadButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.upload.sign' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="approve()"
        *ngIf="canApprove() && !different"
        id="approveButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.approve' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="reprove()"
        *ngIf="canReprove()"
        id="repproveButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.reject' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="_store()"
        *ngIf="canStore()"
        id="storeButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.store' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="acknowledge()"
        *ngIf="canAcknowledge()"
        id="acknowledgeButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.acknowledge' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="cancel()"
        *ngIf="canCancel()"
        id="cancelButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.canceled' | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="transfer()"
        *ngIf="canTransfer()"
        id="tranferButtonId"
        [disabled]="coreService.isLoading()"
      >
        {{ 'databook.transfer' | translate }}
      </button>
      <button
        class="btn btn-prdesimary"
        (click)="back()"
        id="backButtonId"
      >
        BACK
      </button>
    </div>

    <div
      style="text-align: right"
      *ngIf="false"
    >
      <input
        [(ngModel)]="documentIdToSign"
        type="text"
        style="margin-right: 10px"
      />
      <button
        class="btn btn-primary"
        (click)="startSignature()"
      >
        CREATE FILE
      </button>
      <button
        class="btn btn-primary"
        (click)="signSes()"
      >
        1º SIGN
      </button>
      <button
        class="btn btn-primary"
        (click)="signAgainAgain()"
      >
        2º SIGN
      </button>
      <button
        class="btn btn-primary"
        (click)="downladSigneDoc()"
      >
        DOWNLOAD SIGNED
      </button>
    </div>

    <div class="val-card">
      <div class="clr-row clr-align-items-center clr-justify-content-center">
        <div id="idSign"></div>

        <div class="clr-row clr-col-12">
          <div class="clr-col-sm-12 clr-col-md-12 clr-col-lg-5 paper-left">
            <div
              *ngIf="isStepNotAvailable()"
              class="clr-col divider"
            >
              <label>{{ 'databook.upload.new.documents' | translate }}</label>
              <div class="dropzone-container">
                <ngx-dropzone
                  style="height: 70px; background-color: #eafaff"
                  (change)="onFilesAdded($event.addedFiles, false)"
                  #dropzone
                  [multiple]="true"
                >
                  <ngx-dropzone-label
                    >Drag and drop pdf file, up to 30 MB</ngx-dropzone-label
                  >
                </ngx-dropzone>
              </div>
            </div>

            <div
              *ngIf="isStepNotAvailable()"
              class="clr-break-row"
            ></div>

            <div
              *ngIf="addedFiles?.length"
              class="clr-col phone-view"
              style="margin-top: 40px"
            >
              <clr-datagrid>
                <clr-dg-column [style.width.px]="280">
                  {{ 'databook.file.name' | translate }}</clr-dg-column
                >
                <clr-dg-column [style.width.px]="280"
                  >{{ 'databook.doc.name' | translate }}*</clr-dg-column
                >
                <clr-dg-column [style.width.px]="10"
                  >{{ 'databook.revision' | translate }}*</clr-dg-column
                >
                <clr-dg-column [style.width.px]="10">{{
                  'databook.document.code' | translate
                }}</clr-dg-column>
                <clr-dg-column [style.width.px]="10">Custom</clr-dg-column>
                <clr-dg-column [style.width.px]="10"></clr-dg-column>

                <clr-dg-row *clrDgItems="let file of addedFiles">
                  <clr-dg-cell>{{ file.name }}</clr-dg-cell>
                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.description }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.description"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>

                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.revision }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.revision"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>

                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.documentCode }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.documentCode"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>

                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.custom }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.custom"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>

                  <clr-dg-cell>
                    <img
                      class="file-tree-icon"
                      (click)="delete(file.name, false)"
                      style="cursor: pointer"
                      src="../../../assets/img/svg/trash-icon.svg"
                      *ngIf="isStepNotAvailable()"
                      title="Delete"
                      alt="Delete Icon"
                    />
                    <img
                      class="file-tree-icon"
                      (click)="download(file.id, file.name, false)"
                      style="cursor: pointer"
                      src="../../../assets/img/svg/download-icon.svg"
                      *ngIf="dataBookState.stepAction"
                      title="Download"
                      alt="Download Icon"
                    />
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer *ngIf="isStepNotAvailable()">
                  <clr-dg-pagination
                    #paginationTaskDetails
                    [clrDgPageSize]="5"
                  >
                    <clr-dg-page-size [clrPageSizeOptions]="[5, 10]">
                      {{
                        'application.commons.grid.footer.pagination.page.size'
                          | translate
                      }}
                    </clr-dg-page-size>
                    {{ paginationTaskDetails.firstItem + 1 }} -
                    {{ paginationTaskDetails.lastItem + 1 }}
                    {{
                      'application.commons.grid.footer.pagination.of'
                        | translate
                    }}
                    {{ paginationTaskDetails.totalItems }}
                    {{
                      'application.commons.grid.footer.pagination.records'
                        | translate
                    }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>

            <div
              *ngIf="addedFiles && addedFiles.length"
              class="clr-break-row"
            ></div>

            <div
              *ngIf="isApproveReprove()"
              class="clr-col divider"
              style="margin-top: 1.2rem"
            >
              <label class="clr-control-label">
                {{ 'databook.additional.document' | translate }}
              </label>
              <div class="dropzone-container">
                <ngx-dropzone
                  style="height: 70px; background-color: #eafaff"
                  (change)="onFilesAdded($event.addedFiles, true)"
                  #dropzone
                  [multiple]="false"
                >
                  <ngx-dropzone-label
                    >Drag and drop pdf file, up to 30 MB</ngx-dropzone-label
                  >
                </ngx-dropzone>
              </div>
            </div>

            <div
              *ngIf="isApproveReprove()"
              class="clr-break-row"
            ></div>

            <clr-datagrid
              *ngIf="additionalFile && additionalFile.length"
              class="phone-view"
              style="margin-top: 40px"
            >
              <clr-dg-column [style.width.px]="505">
                {{ 'databook.file.name' | translate }}</clr-dg-column
              >
              <clr-dg-column [style.width.px]="10"></clr-dg-column>

              <clr-dg-row *clrDgItems="let file of additionalFile">
                <clr-dg-cell>{{ file.name }}</clr-dg-cell>
                <clr-dg-cell>
                  <img
                    class="file-tree-icon"
                    (click)="delete(file.name, true)"
                    style="cursor: pointer"
                    src="../../../assets/img/svg/trash-icon.svg"
                    *ngIf="isApproveReprove()"
                    alt="Trash Icon"
                  />
                  <img
                    class="file-tree-icon"
                    (click)="download(file.id, file.name, true)"
                    style="cursor: pointer"
                    src="../../../assets/img/svg/download-icon.svg"
                    *ngIf="!isApproveReprove()"
                    alt="Download Icon"
                  />
                </clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>

            <div
              *ngIf="additionalFile && additionalFile.length"
              class="clr-break-row"
            ></div>
            <div
              *ngIf="canTransfer()"
              class="clr-col divider"
            >
              <clr-input-container>
                <label>{{ 'databook.transfer.to' | translate }}</label>
                <input
                  id="emailTransferToId"
                  class="emailTransferToClass"
                  clrInput
                  [(ngModel)]="transferTo"
                  type="text"
                  maxlength="200"
                  class="clr-input"
                />
              </clr-input-container>
            </div>

            <div
              *ngIf="canTransfer()"
              class="clr-break-row"
            ></div>

            <div
              *ngIf="canUpload()"
              class="clr-col divider"
            >
              <div
                class="clr-col-sm-12 clr-col-md-6 clr-justify-content-start"
                style="padding-left: 0"
              >
                <clr-select-container>
                  <label>{{ 'tpi.signature.type' | translate }} *</label>
                  <select
                    clrSelect
                    class="clr-input"
                    name="signatureType"
                    [(ngModel)]="signatureType"
                    (change)="changeSignatureType(signatureType)"
                    id="selectSignatureTypeId"
                  >
                    <option value="CUSTOM">Custom electronic signature</option>
                    <option value="LACUNA">Lacuna QES</option>
                    <option value="LACUNA_SES">Lacuna SES</option>
                  </select>
                </clr-select-container>
              </div>
            </div>

            <div
              *ngIf="canUpload()"
              class="clr-break-row"
            ></div>

            <div
              *ngIf="isAbleToSign()"
              class="clr-col divider"
            >
              <div class="clr-row clr-justify-content-start">
                <clr-select-container class="clr-col-10 clr-col-md-6">
                  <label>{{ 'databook.select.sign' | translate }}</label>
                  <select
                    class="clr-input"
                    clrSelect
                    name="configSignature"
                    id="configSignatureId"
                    [(ngModel)]="configSignature"
                    (change)="changeSignature(configSignature)"
                  >
                    <option value="">
                      {{ 'application.commons.text.select' | translate }}
                    </option>
                    <option
                      *ngFor="let configSignature of configSignatureList"
                      [ngValue]="configSignature"
                    >
                      <label class="label-small">{{
                        configSignature.description
                      }}</label>
                    </option>
                  </select>
                </clr-select-container>
                <div class="clr-col-2">
                  <button
                    type="button"
                    class="btn btn-icon"
                    style="margin-top: 40px; margin-left: 10px"
                    (click)="gotToConfigSignature()"
                  >
                    <clr-icon shape="cog"></clr-icon>
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngIf="isAbleToSign()"
              class="clr-break-row"
            ></div>

            <div
              class="clr-col"
              *ngIf="openModalPki !== 'none' && !canSign && isLacunaSignature()"
            >
              <div class="clr-row">
                <div class="clr-col-11">
                  <button
                    type="button"
                    id="installOrUpdateWebExtensionButton"
                    class="btn btn-lg btn-primary"
                    [disabled]="coreService.isLoading()"
                    style="margin-top: 30px"
                    (click)="redirectToInstall()"
                  >
                    {{ 'databook.add.pki' | translate }}
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngIf="openModalPki !== 'none' && !canSign && isLacunaSignature()"
              class="clr-break-row"
            ></div>

            <div
              *ngIf="isStepNotAvailable()"
              class="clr-col divider"
            >
              <div class="clr-row clr-justify-content-start">
                <clr-select-container class="clr-col-10 clr-col-md-6">
                  <label>{{ 'databook.workflow' | translate }}*</label>
                  <select
                    id="selectWfReleaseId"
                    class="clr-input"
                    clrSelect
                    name="workflow"
                    [(ngModel)]="workflow"
                    (change)="changeWf(workflow)"
                  >
                    <option value="">
                      {{ 'application.commons.text.select' | translate }}
                    </option>
                    <option
                      *ngFor="let work of workflows"
                      [ngValue]="work"
                    >
                      {{ work.name }}
                    </option>
                  </select>
                </clr-select-container>
                <div class="clr-col-2">
                  <button
                    type="button"
                    class="btn btn-icon"
                    style="margin-top: 35px; margin-left: 10px"
                    (click)="goToConfigWorkflow()"
                  >
                    <clr-icon shape="cog"></clr-icon>
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngIf="isStepNotAvailable()"
              class="clr-break-row"
            ></div>

            <div
              *ngIf="canStore()"
              class="clr-col divider"
            >
              <div class="clr-row clr-justify-content-start">
                <label class="clr-control-label">
                  {{ 'tpi.store.documents' | translate }}
                </label>

                <div class="clr-break-row"></div>

                <clr-radio-container clrInline>
                  <label>{{ 'databook.edoc' | translate }}</label>
                  <clr-radio-wrapper>
                    <input
                      type="radio"
                      clrRadio
                      name="eDoc"
                      required
                      value="DATABOOK"
                      [(ngModel)]="eDoc"
                      (change)="changeTypeTree()"
                    />
                    <label>Databook</label>
                  </clr-radio-wrapper>
                  <clr-radio-wrapper>
                    <input
                      type="radio"
                      clrRadio
                      name="eDoc"
                      required
                      value="EDOC"
                      [(ngModel)]="eDoc"
                      (change)="changeTypeTree()"
                    />
                    <label>E-Doc</label>
                  </clr-radio-wrapper>
                </clr-radio-container>

                <div class="clr-break-row"></div>

                <!--                        (openConfirmMerge)="openConfirmMerge()"-->
                <!--                        (sendDocsByEmail)="openSendEmailModal()"-->
                <!--                        (viewDocument)="viewDocument2($event)"-->
                <!--                        (updateFile)="updateFile2($event)"-->
                <!--                        (openUpload)="openUpload2($event)"-->
                <!--                        (download)="downloadd()"-->
                <!--                        (downloadVersion)="downloadVersion($event)"-->
                <!--                        (downloadMerge)="downloadMergedFilesArchive()"-->

                <div *ngIf="showTree">
                  <treeview [selectionFolderOnly]="true"></treeview>
                </div>

                <!--                <tree-root-->
                <!--                  #tree-->
                <!--                  [nodes]="nodes"-->
                <!--                  [options]="options"-->
                <!--                  class="file-tree"-->
                <!--                >-->
                <!--                  <ng-template-->
                <!--                    #treeNodeTemplate-->
                <!--                    let-node-->
                <!--                    let-index="index"-->
                <!--                  >-->
                <!--                    <div class="file-tree">-->
                <!--                      <span class="label-small">-->
                <!--                        {{ node.data.name }}-->
                <!--                      </span>-->
                <!--                    </div>-->
                <!--                  </ng-template>-->
                <!--                </tree-root>-->
              </div>
            </div>

            <div
              *ngIf="canStore()"
              class="clr-break-row"
            ></div>

            <div class="clr-col">
              <clr-textarea-container>
                <label>{{ 'databook.comment' | translate }}</label>
                <textarea
                  clrTextarea
                  [(ngModel)]="comment"
                  cols="110"
                  style="width: 100%"
                  id="textareaCommentId"
                  class="textareaComment"
                ></textarea>
              </clr-textarea-container>
            </div>
          </div>

          <div class="clr-col-sm-12 clr-col-md-12 clr-col-lg-7 paper-right">
            <div
              *ngIf="addedFiles && addedFiles.length"
              class="clr-col"
              style="margin-top: 40px"
            >
              <clr-datagrid>
                <clr-dg-column [style.width.px]="280">
                  {{ 'databook.file.name' | translate }}</clr-dg-column
                >
                <clr-dg-column [style.width.px]="280"
                  >{{ 'databook.doc.name' | translate }}*</clr-dg-column
                >
                <clr-dg-column [style.width.px]="10"
                  >{{ 'databook.revision' | translate }}*</clr-dg-column
                >
                <clr-dg-column [style.width.px]="10">{{
                  'databook.document.code' | translate
                }}</clr-dg-column>
                <clr-dg-column [style.width.px]="10">Custom</clr-dg-column>
                <clr-dg-column [style.width.px]="10"></clr-dg-column>

                <clr-dg-row *clrDgItems="let file of addedFiles">
                  <clr-dg-cell>{{ file.name }}</clr-dg-cell>
                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.description }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.description"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>

                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.revision }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.revision"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>

                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.documentCode }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.documentCode"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>
                  <clr-dg-cell>
                    <div *ngIf="dataBookState.stepAction">
                      {{ file.custom }}
                    </div>
                    <input
                      class="clr-col-12 clr-col-md-10"
                      [(ngModel)]="file.custom"
                      type="text"
                      *ngIf="isStepNotAvailable()"
                    />
                  </clr-dg-cell>
                  <clr-dg-cell>
                    <img
                      class="file-tree-icon"
                      (click)="delete(file.name, false)"
                      style="cursor: pointer"
                      src="../../../assets/img/svg/trash-icon.svg"
                      *ngIf="isStepNotAvailable()"
                      title="Delete"
                      alt="Trash Icon"
                    />
                    <img
                      class="file-tree-icon"
                      (click)="download(file.id, file.name, false)"
                      style="cursor: pointer"
                      src="../../../assets/img/svg/download-icon.svg"
                      *ngIf="dataBookState.stepAction"
                      title="Download"
                      alt="Download Icon"
                    />
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer *ngIf="isStepNotAvailable()">
                  <clr-dg-pagination
                    #paginationTaskDetails
                    [clrDgPageSize]="5"
                  >
                    <clr-dg-page-size [clrPageSizeOptions]="[5, 10]">
                      {{
                        'application.commons.grid.footer.pagination.page.size'
                          | translate
                      }}
                    </clr-dg-page-size>
                    {{ paginationTaskDetails.firstItem + 1 }} -
                    {{ paginationTaskDetails.lastItem + 1 }}
                    {{
                      'application.commons.grid.footer.pagination.of'
                        | translate
                    }}
                    {{ paginationTaskDetails.totalItems }}
                    {{
                      'application.commons.grid.footer.pagination.records'
                        | translate
                    }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>

            <div
              *ngIf="addedFiles && addedFiles.length"
              class="clr-break-row"
            ></div>

            <clr-datagrid
              *ngIf="additionalFile && additionalFile.length"
              style="margin-top: 40px"
            >
              <clr-dg-column [style.width.px]="505">
                {{ 'databook.file.name' | translate }}</clr-dg-column
              >
              <clr-dg-column [style.width.px]="10"></clr-dg-column>

              <clr-dg-row *clrDgItems="let file of additionalFile">
                <clr-dg-cell>{{ file.name }}</clr-dg-cell>
                <clr-dg-cell>
                  <img
                    class="file-tree-icon"
                    (click)="delete(file.name, true)"
                    style="cursor: pointer"
                    src="../../../assets/img/svg/trash-icon.svg"
                    *ngIf="isApproveReprove()"
                    alt="Trash Icon"
                  />
                  <img
                    class="file-tree-icon"
                    (click)="download(file.id, file.name, true)"
                    style="cursor: pointer"
                    src="../../../assets/img/svg/download-icon.svg"
                    *ngIf="!isApproveReprove()"
                    alt="Download Icon"
                  />
                </clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="openModalPki !== 'none'"
  [clrModalSize]="'lg'"
  [clrModalStaticBackdrop]="true"
  [clrModalClosable]="false"
>
  <div class="modal-header">
    <button
      aria-label="Close"
      class="close"
      type="button"
    >
      <clr-icon
        aria-hidden="true"
        shape="close"
      ></clr-icon>
    </button>
    {{ 'databook.pki.extension' | translate }}
  </div>

  <div
    class="modal-body"
    style="text-align: center"
  >
    <div class="clr-row-col12">
      {{ 'databook.pki.necessary' | translate }}
      <br />
      <br />
      <button
        type="button"
        id="installOrUpdateWebExtensionButton"
        class="btn btn-lg btn-primary"
        (click)="redirectToInstall()"
      >
        {{ 'databook.add.pki' | translate }}
      </button>
    </div>
  </div>
</clr-modal>

<clr-modal
  [clrModalSize]="'xl'"
  [clrModalStaticBackdrop]="true"
  #modalSesSign
>
  <div class="modal-header"></div>

  <div class="modal-body">
    <div id="embed-container"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      *ngIf="false"
      (click)="closeSigner()"
    >
      CLOSE
    </button>
  </div>
</clr-modal>

<clr-modal
  *ngIf="isConfigMySignatureOpen"
  [clrModalOpen]="isConfigMySignatureOpen"
  [clrModalSize]="'xl'"
  [clrModalStaticBackdrop]="true"
  [clrModalClosable]="false"
>
  <div class="modal-header"></div>

  <div class="modal-body">
    <my-signatures></my-signatures>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeConfigMySignature()"
    >
      CLOSE
    </button>
  </div>
</clr-modal>

<config-workflow-modal
  *ngIf="isConfigWorkflowOpen"
  [isConfigWorkflowOpen]="isConfigWorkflowOpen"
  [closeModal]="closeConfigWorkflow"
></config-workflow-modal>

<open-mail-modal
  *ngIf="isMailConfirmOpen"
  [isModalOpen]="isMailConfirmOpen"
  [closeModal]="cancelAddEmail"
  [getEmailMessage]="getEmailMessage"
  [type]="openEmailType"
  [confirmEmail]="confirmEmail"
></open-mail-modal>

<duplicated-flows-modal
  *ngIf="duplicatedFiles?.length && isDuplicatedFlowsModalOpen"
  [modalIsOpen]="duplicatedFiles?.length && isDuplicatedFlowsModalOpen"
  [closeModal]="closeDuplicatedFilesModal"
  [files]="duplicatedFiles"
  [upload]="upload"
>
</duplicated-flows-modal>
