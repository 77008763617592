<vic-base-page page="dataExplorer-impact">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isImpactVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>
      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Order')"
          >
            <ng-container>{{
              'data.book.mill.order' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Mill_Item')"
          >
            <ng-container>{{ 'data.book.mill.item' | translate }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Pipe_Heat')"
          >
            <ng-container>{{ 'data.book.pipe.heat' | translate }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="60"
            *ngIf="shouldShowColumn('Specimen')"
          >
            <ng-container>{{ 'data.book.specimen' | translate }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="200"
            *ngIf="shouldShowColumn('Test_Avg_Reduced_area_value')"
          >
            <ng-container>{{
              'data.book.test.avg.reduced.area.value' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="135"
            *ngIf="shouldShowColumn('Test_Avg_Parent_metal')"
          >
            <ng-container>{{
              'data.book.test.avg.parent.metal' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="95"
            *ngIf="shouldShowColumn('Test_1_Areaed')"
          >
            <ng-container>{{
              'data.book.test.1.areaed' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="115"
            *ngIf="shouldShowColumn('Test_1_Parent_metal')"
          >
            <ng-container>{{
              'data.book.test.1.parent.metal' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Test_2_Areaed')"
          >
            <ng-container>{{
              'data.book.test.2.areaed' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="110"
            *ngIf="shouldShowColumn('Test_2_Parent_metal')"
          >
            <ng-container>{{
              'data.book.test.2.parent.metal' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="110"
            *ngIf="shouldShowColumn('Test_3_Areaed')"
          >
            <ng-container>{{
              'data.book.test.3.areaed' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="110"
            *ngIf="shouldShowColumn('Test_3_Parent_metal')"
          >
            <ng-container>{{
              'data.book.test.3.parent.metal' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="170"
            *ngIf="shouldShowColumn('Test_Temperature')"
          >
            <ng-container>{{
              'data.book.test.temperature' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="100"
            *ngIf="shouldShowColumn('Direction')"
          >
            <ng-container>{{ 'data.book.direction' | translate }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Standard')"
          >
            <ng-container>{{ 'data.book.standard' | translate }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="60"
            *ngIf="shouldShowColumn('Qualification')"
          >
            <ng-container>{{
              'data.book.qualification' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
          >
            <ng-container>{{
              'data.book.manufacturer.pipe' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="205"
            *ngIf="shouldShowColumn('Description_Charpy')"
          >
            <ng-container>{{
              'data.book.description.charpy' | translate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-column
            [style.width.px]="60"
            *ngIf="shouldShowColumn('Condition')"
          >
            <ng-container>{{ 'data.book.condition' | translate }}</ng-container>
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Order')">{{
              data['Mill_Order']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Specimen')">{{
              data['Specimen']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('Test_Avg_Reduced_area_value')"
              >{{ data['Test_Avg_Reduced_area_value'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('Test_Avg_Parent_metal')">{{
              data['Test_Avg_Parent_metal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_1_Areaed')">{{
              data['Test_1_Areaed']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_1_Parent_metal')">{{
              data['Test_1_Parent_metal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test  _2_Areaed')">{{
              data['Test_2_Areaed']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_2_Parent_metal')">{{
              data['Test_2_Parent_metal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_3_Areaed')">{{
              data['Test_3_Areaed']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_3_Parent_metal')">{{
              data['Test_3_Parent_metal']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_Temperature')">{{
              data['Test_Temperature']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Direction')">{{
              data['Direction']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Standard')">{{
              data['Standard']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Qualification')">{{
              data['Qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Description_Charpy')">{{
              data['Description_Charpy']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Condition')">{{
              data['Condition']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
