export const formatUnit = (unit) => (unit ? ' + unit + ' : '');

export const returnExistingValue = (value) => {
  return value ? value : '';
};

export const digitsNumber = (unit, params, digits) => {
  try {
    const value = Number(params);
    if (Number.isNaN(value)) {
      return returnExistingValue(params) + formatUnit(unit);
    }
    if (digits) {
      return value.toFixed(digits) + formatUnit(unit);
    }
    return returnExistingValue(params) + formatUnit(unit);
  } catch (e) {
    return params;
  }
};
export const zeroDigitsNumber = (unit, params) => {
  return digitsNumber(unit, params, null);
};

export const threeDigitsNumber = (unit, params) => {
  return digitsNumber(unit, params, 3);
};
export const twoDigitsNumber = (unit, params) => {
  return digitsNumber(unit, params, 2);
};

export const fourDigitsNumber = (unit, params) => {
  return digitsNumber(unit, params, 4);
};

export const formatter = (value, digits) => {
  // var sansDec = currency.toFixed(0);
  let formatted = '';
  if (value === null) {
    formatted = '';
  } else {
    formatted =
      value >= 1000
        ? value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : value;
    if (digits !== '')
      formatted = `${formatted} ${digits !== '' ? digits : ''}`;
  }
  return formatted;
};
