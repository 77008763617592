import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataBookDataAnalysisComponent } from '../pages/data-analysis/data-analysis.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { StatisticsDataAnalysisComponent } from '../pages/data-analysis/statistics/statistics.component';
import { BoxplotDataAnalysisComponent } from '../pages/data-analysis/boxplot/boxplot.component';
import { pipeNavigatorRouteNames } from '../enums/routes.enum';
import { MaterialComponent } from '../pages/material/material.component';

export const dataAnalysis = [
  {
    // data analysis material selector
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis',
    component: MaterialComponent,
    data: {
      routeName: pipeNavigatorRouteNames.materialMaterialTypeDataAnalysis,
    },
    resolve: {
      data: ProjectListResolver,
    },
  },
  /*{ // data analysis material selector // todo: discover what this route is
        canActivate: [AuthenticateGuard],
        path: ':projectSlug/:productSlug/data-analysis',
        component: DataExplorerComponent,
        data: {routeName: pipeNavigatorRouteNames.dataExplorer},
        resolve: {
            data: ProjectListResolver,
        },
    },*/

  {
    // this page is data analysis type selector
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/new-data-analysis',
    component: DataBookDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysis },
    resolve: {
      data: ProjectListResolver,
    },
  },

  {
    // This page is test type and property ( field ) selector for boxplot
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/new-data-analysis/boxplot',
    component: BoxplotDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisBoxplot },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // This page is test type and property ( field ) selector for statistics
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/new-data-analysis/statistics',
    component: StatisticsDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisStatistics },
    resolve: {
      data: ProjectListResolver,
    },
  },

  {
    // this page is data analysis type selector
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId',
    component: DataBookDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysis },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // This page is test type and property ( field ) selector for boxplot
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId/boxplot',
    component: BoxplotDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisBoxplot },
    resolve: {
      data: ProjectListResolver,
    },
  },

  {
    // This page is test type and property ( field ) selector for statistics
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId/statistics',
    component: StatisticsDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisStatistics },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // This page is test type and property ( field ) selector for boxplot
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId/boxplot/:dataAnalysisTestType',
    component: BoxplotDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisBoxplot },
    resolve: {
      data: ProjectListResolver,
    },
  },

  {
    // This page is test type and property ( field ) selector for statistics
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId/statistics/:testType',
    component: StatisticsDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisStatistics },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    // This page is test type and property ( field ) selector for boxplot
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId/boxplot/:dataAnalysisTestType/:dataAnalysisTestTypeProperty',
    component: BoxplotDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisBoxplot },
    resolve: {
      data: ProjectListResolver,
    },
  },

  {
    // This page is test type and property ( field ) selector for statistics
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/data-analysis/:materialId/statistics/:dataAnalysisTestType/:dataAnalysisTestTypeProperty',
    component: StatisticsDataAnalysisComponent,
    data: { routeName: pipeNavigatorRouteNames.dataAnalysisStatistics },
    resolve: {
      data: ProjectListResolver,
    },
  },
];
