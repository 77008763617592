<vic-base-page page="material">
  <div class="header-actions container"></div>
  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>
    <div class="dashboard-container">
      <div class="val-card clr-col-12">
        <div class="title-container">
          <label class="session-title">
            {{ 'databook.material.your.selection' | translate }}
          </label>
        </div>
        <div class="clr-row grid-row-padding">
          <clr-datagrid
            class="val-datagrid"
            [(clrDgSingleSelected)]="selectedMaterial"
            [clrDgRowSelection]="true"
            (clrDgSingleSelectedChange)="materialClick($event)"
          >
            <clr-dg-column
              [style.width.px]="100"
              class="datagrid-header"
            >
              {{ 'plp.material.type' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="120"
              class="datagrid-header"
            >
              {{ 'data.book.mill.order.order.item' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="400"
              class="datagrid-header"
            >
              {{ 'plp.material.description' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="120"
              class="datagrid-header"
            >
              {{ 'plp.material.od' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="120"
              class="datagrid-header"
            >
              {{ 'plp.material.wt' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="400"
              class="datagrid-header"
            >
              {{ 'plp.material.material' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="190"
              class="datagrid-header"
            >
              {{ 'plp.material.quantity' | translate }}
            </clr-dg-column>
            <clr-dg-column
              [style.width.px]="190"
              class="datagrid-header"
            >
              {{ 'plp.material.delivered.quantity' | translate }}
            </clr-dg-column>

            <clr-dg-row
              *clrDgItems="let mat of materials"
              [clrDgItem]="mat"
            >
              <clr-dg-cell>{{ mat.type }}</clr-dg-cell>
              <clr-dg-cell>{{ mat.item }} - {{ mat.itemorder }}</clr-dg-cell>

              <clr-dg-cell>{{ mat.description }}</clr-dg-cell>
              <clr-dg-cell>{{ mat.od }}</clr-dg-cell>
              <clr-dg-cell>{{ mat.wt }}</clr-dg-cell>
              <clr-dg-cell>{{ mat.grade }}</clr-dg-cell>
              <clr-dg-cell>{{ mat.quantity }}</clr-dg-cell>
              <clr-dg-cell *ngIf="mat.deliveryquantity">
                {{ mat.deliveryquantity }}
              </clr-dg-cell>
              <clr-dg-cell *ngIf="!mat.deliveryquantity">-</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination
                #paginationTaskDetails
                [clrDgPageSize]="10"
              >
                <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                  {{
                    'application.commons.grid.footer.pagination.page.size'
                      | translate
                  }}</clr-dg-page-size
                >
                {{ paginationTaskDetails.firstItem + 1 }} -
                {{ paginationTaskDetails.lastItem + 1 }}
                {{
                  'application.commons.grid.footer.pagination.of' | translate
                }}
                {{ paginationTaskDetails.totalItems }}
                {{
                  'application.commons.grid.footer.pagination.records'
                    | translate
                }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>
