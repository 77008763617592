<vic-base-page page="dataExplorer-dimensions">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isDimensionsVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO <DATA></DATA></div>
        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column *ngIf="shouldShowColumn('manufacturer_pipe')">{{
            'data.book.manufacture.pipe' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('val_id')">{{
            'Val id' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Pipe')">{{
            'data.book.pipe' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Customer_PO')">{{
            'data.book.customer.po' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Pipe_Heat')">{{
            'data.book.heat' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Mill')">{{
            'data.book.mill' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Mill_Order')">{{
            'data.book.mill.order' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Mill_Item')">{{
            'data.book.mill.item' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_nominal_(mm)')">{{
            'data.book.od.nominal' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_nominal_(mm)')">{{
            'data.book.wt.nominal' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Length_(m)')">{{
            'data.book.length' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Lot')">{{
            'data.book.lot' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Certificate')">{{
            'data.book.certificate' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Manufacture_Loc')">{{
            'data.book.manufacture.loc' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Weight_Bare_(kg)')">{{
            'data.book.weight.bare' | translate
          }}</clr-dg-column>
          <clr-dg-column
            *ngIf="shouldShowColumn('Straigthness_p_End_A_(mm)')"
            >{{ 'data.book.straigthness.p.end.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Straigthness_p_End_B_(mm)')"
            >{{ 'data.book.straigthness.p.end.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Straigthness_pipe_Body_(mm)')"
            >{{ 'data.book.straigthness.pipe.body' | translate }}</clr-dg-column
          >

          <!-- AQUI -->
          <clr-dg-column *ngIf="shouldShowColumn('squareness_p_end_a_0')">{{
            'data.book.squareness.p.end.a.0' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('squareness_p_end_a_90')">{{
            'data.book.squareness.p.end.a.90' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('squareness_p_end_b_0')">{{
            'data.book.squareness.pipe.end.b.0' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('squareness_p_end_b_90')">{{
            'data.book.squareness.pipe.end.b.90' | translate
          }}</clr-dg-column>
          <!-- ATE -->

          <clr-dg-column *ngIf="shouldShowColumn('OD_p_End_A_min')">{{
            'data.book.od.p.end.a.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_p_End_A_max')">{{
            'data.book.od.p.end.a.max' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_p_End_B_min')">{{
            'data.book.od.p.end.b.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_p_End_B_max')">{{
            'data.book.od.p.end.b.max' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_OoR_p_End_A_(mm)')">{{
            'data.book.od.oor.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_OoR_p_End_B_(mm)')">{{
            'data.book.od.oor.p.end.b' | translate
          }}</clr-dg-column>

          <!-- AQUI -->
          <clr-dg-column *ngIf="shouldShowColumn('ID_body_Min_(mm)')">{{
            'data.book.id.body.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_body_Max_(mm)')">{{
            'data.book.id.body.max' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_body_Avg_(mm)')">{{
            'data.book.id.body.avg' | translate
          }}</clr-dg-column>
          <!-- ATE -->

          <clr-dg-column *ngIf="shouldShowColumn('ID_pipe_End_A_Min_(mm)')">{{
            'data.book.id.pipe.end.a.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_pipe_End_A_Max_(mm)')">{{
            'data.book.id.pipe.end.a.max' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_pipe_End_A_Avg_(mm)')">{{
            'data.book.id.pipe.end.a.avg' | translate
          }}</clr-dg-column>

          <clr-dg-column *ngIf="shouldShowColumn('ID_OoR_p_End_A_(mm)')">{{
            'data.book.id.oor.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_pipe_End_B_Min_(mm)')">{{
            'data.book.id.pipe.end.b.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_pipe_End_B_Max_(mm)')">{{
            'data.book.id.pipe.end.b.max' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_pipe_End_B_Avg_(mm)')">{{
            'data.book.id.pipe.end.b.avg' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('ID_OoR_p_End_B_(mm)')">{{
            'data.book.id.oor.p.end.b' | translate
          }}</clr-dg-column>

          <!-- AQUI -->
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_avg')"
            >{{ 'data.book.id.pipe.end.a.qp.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_avg')"
            >{{ 'data.book.id.pipe.end.b.qp.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_a')"
            >{{ 'data.book.id.pipe.end.a.qp.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_a')"
            >{{ 'data.book.id.pipe.end.b.qp.a' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_b')"
            >{{ 'data.book.id.pipe.end.a.qp.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_b')"
            >{{ 'data.book.id.pipe.end.b.qp.b' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_c')"
            >{{ 'data.book.id.pipe.end.a.qp.c' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_c')"
            >{{ 'data.book.id.pipe.end.b.qp.c' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_d')"
            >{{ 'data.book.id.pipe.end.a.qp.d' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_d')"
            >{{ 'data.book.id.pipe.end.b.qp.d' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_oor')"
            >{{ 'data.book.id.pipe.end.a.qp.oor' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_oor')"
            >{{ 'data.book.id.pipe.end.b.qp.oor' | translate }}</clr-dg-column
          >
          <!-- ATE -->

          <clr-dg-column *ngIf="shouldShowColumn('WT_1_p_End_A_(mm)')">{{
            'data.book.wt.1.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_2_p_End_A_(mm)')">{{
            'data.book.wt.2.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_3_p_End_A_(mm)')">{{
            'data.book.wt.3.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_4_p_End_A_(mm)')">{{
            'data.book.wt.4.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_min_p_End_A_(mm)')">{{
            'data.book.wt.min.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_max_p_End_A_(mm)')">{{
            'data.book.wt.max.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_avg_p_End_A_(mm)')">{{
            'data.book.wt.avg.p.end.a' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_1_p_End_B_(mm)')">{{
            'data.book.wt.1.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_2_p_End_B_(mm)')">{{
            'data.book.wt.2.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_3_p_End_B_(mm)')">{{
            'data.book.wt.3.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_4_p_End_B_(mm)')">{{
            'data.book.wt.4.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_min_p_End_B_(mm)')">{{
            'data.book.wt.min.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_max_p_End_B_(mm)')">{{
            'data.book.wt.max.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_avg_p_End_B_(mm)')">{{
            'data.book.wt.avg.p.end.b' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_avg_Body_(mm)')">{{
            'data.book.od.avg.body' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_max_Body_(mm)')">{{
            'data.book.od.max.body' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_min_Body_(mm)')">{{
            'data.book.od.min.body' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('OD_body_OoR_(mm)')">{{
            'data.book.od.body.oor' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_min_body')">{{
            'data.book.wt.min.body' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_avg_body')">{{
            'data.book.wt.avg.body' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('WT_max_body')">{{
            'data.book.wt.max.body' | translate
          }}</clr-dg-column>

          <clr-dg-column *ngIf="shouldShowColumn('Ecc_body_mim')">{{
            'data.book.ecc.body.mim' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_body_avg')">{{
            'data.book.ecc.body.avg' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_body_max')">{{
            'data.book.ecc.body.max' | translate
          }}</clr-dg-column>

          <!-- AQUI -->
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_pipe_end_a_min')">{{
            'data.book.ecc.pipe.end.a.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_pipe_end_a_avg')">{{
            'data.book.ecc.pipe.end.a.avg' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_pipe_end_a_max')">{{
            'data.book.ecc.pipe.end.a.max' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_pipe_end_b_min')">{{
            'data.book.ecc.pipe.end.b.min' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_pipe_end_b_avg')">{{
            'data.book.ecc.pipe.end.b.avg' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Ecc_pipe_end_b_max')">{{
            'data.book.ecc.pipe.end.b.max' | translate
          }}</clr-dg-column>
          <!-- ATE -->

          <clr-dg-column *ngIf="shouldShowColumn('Fnl_prod_od_body_oor_mim')">{{
            'data.book.od.body.oor.mim' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Fnl_prod_od_body_oor_avg')">{{
            'data.book.od.body.oor.avg' | translate
          }}</clr-dg-column>
          <clr-dg-column *ngIf="shouldShowColumn('Fnl_prod_od_body_oor_max')">{{
            'data.book.od.body.oor.max' | translate
          }}</clr-dg-column>

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('manufacturer_pipe')">{{
              data['manufacturer_pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('val_id')">{{
              data['val_id']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe')">{{
              data['Pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Customer_PO')">{{
              data['Customer_PO']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill')">{{
              data['Mill']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Order')">{{
              data['Mill_Order']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_nominal_(mm)')">{{
              data['OD_nominal_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_nominal_(mm)')">{{
              data['WT_nominal_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Length_(m)')">{{
              data['Length_(m)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Lot')">{{
              data['Lot']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Certificate')">{{
              data['Certificate']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacture_Loc')">{{
              data['Manufacture_Loc']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Weight_Bare_(kg)')">{{
              data['Weight_Bare_(kg)']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('Straigthness_p_End_A_(mm)')"
              >{{ data['Straigthness_p_End_A_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Straigthness_p_End_B_(mm)')"
              >{{ data['Straigthness_p_End_B_(mm)'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Straigthness_pipe_Body_(mm)')"
              >{{ data['Straigthness_pipe_Body_(mm)'] }}</clr-dg-cell
            >

            <!-- AQUI -->
            <clr-dg-cell *ngIf="shouldShowColumn('squareness_p_end_a_0')">{{
              data['squareness_p_end_a_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('squareness_p_end_a_90')">{{
              data['squareness_p_end_a_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('squareness_p_end_b_0')">{{
              data['squareness_p_end_b_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('squareness_p_end_b_90')">{{
              data['squareness_p_end_b_90']
            }}</clr-dg-cell>
            <!-- ATE -->

            <clr-dg-cell *ngIf="shouldShowColumn('OD_p_End_A_min')">{{
              data['OD_p_End_A_min']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_p_End_A_max')">{{
              data['OD_p_End_A_max']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_p_End_B_min')">{{
              data['OD_p_End_B_min']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_p_End_B_max')">{{
              data['OD_p_End_B_max']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_OoR_p_End_A_(mm)')">{{
              data['OD_OoR_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_OoR_p_End_B_(mm)')">{{
              data['OD_OoR_p_End_B_(mm)']
            }}</clr-dg-cell>

            <!-- AQUI -->
            <clr-dg-cell *ngIf="shouldShowColumn('ID_body_Min_(mm)')">{{
              data['ID_body_Min_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_body_Max_(mm)')">{{
              data['ID_body_Max_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_body_Avg_(mm)')">{{
              data['ID_body_Avg_(mm)']
            }}</clr-dg-cell>
            <!-- ATE -->

            <clr-dg-cell *ngIf="shouldShowColumn('ID_pipe_End_A_Min_(mm)')">{{
              data['ID_pipe_End_A_Min_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_pipe_End_A_Max_(mm)')">{{
              data['ID_pipe_End_A_Max_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_pipe_End_A_Avg_(mm)')">{{
              data['ID_pipe_End_A_Avg_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_OoR_p_End_A_(mm)')">{{
              data['ID_OoR_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_pipe_End_B_Min_(mm)')">{{
              data['ID_pipe_End_B_Min_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_pipe_End_B_Max_(mm)')">{{
              data['ID_pipe_End_B_Max_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_pipe_End_B_Avg_(mm)')">{{
              data['ID_pipe_End_B_Avg_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('ID_OoR_p_End_B_(mm)')">{{
              data['ID_OoR_p_End_B_(mm)']
            }}</clr-dg-cell>

            <!-- AQUI -->
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_avg')"
              >{{ data['fnl_prod_id_pipe_end_a_qp_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_avg')"
              >{{ data['fnl_prod_id_pipe_end_b_qp_avg'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_a')"
              >{{ data['fnl_prod_id_pipe_end_a_qp_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_a')"
              >{{ data['fnl_prod_id_pipe_end_b_qp_a'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_b')"
              >{{ data['fnl_prod_id_pipe_end_a_qp_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_b')"
              >{{ data['fnl_prod_id_pipe_end_b_qp_b'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_c')"
              >{{ data['fnl_prod_id_pipe_end_a_qp_c'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_c')"
              >{{ data['fnl_prod_id_pipe_end_b_qp_c'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_d')"
              >{{ data['fnl_prod_id_pipe_end_a_qp_d'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_d')"
              >{{ data['fnl_prod_id_pipe_end_b_qp_d'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_a_qp_oor')"
              >{{ data['fnl_prod_id_pipe_end_a_qp_oor'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('fnl_prod_id_pipe_end_b_qp_oor')"
              >{{ data['fnl_prod_id_pipe_end_b_qp_oor'] }}</clr-dg-cell
            >
            <!-- ATE -->

            <clr-dg-cell *ngIf="shouldShowColumn('WT_1_p_End_A_(mm)')">{{
              data['WT_1_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_2_p_End_A_(mm)')">{{
              data['WT_2_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_3_p_End_A_(mm)')">{{
              data['WT_3_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_4_p_End_A_(mm)')">{{
              data['WT_4_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_min_p_End_A_(mm)')">{{
              data['WT_min_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_max_p_End_A_(mm)')">{{
              data['WT_max_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_avg_p_End_A_(mm)')">{{
              data['WT_avg_p_End_A_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_1_p_End_B_(mm)')">{{
              data['WT_1_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_2_p_End_B_(mm)')">{{
              data['WT_2_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_3_p_End_B_(mm)')">{{
              data['WT_3_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_4_p_End_B_(mm)')">{{
              data['WT_4_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_min_p_End_B_(mm)')">{{
              data['WT_min_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_max_p_End_B_(mm)')">{{
              data['WT_max_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_avg_p_End_B_(mm)')">{{
              data['WT_avg_p_End_B_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_avg_Body_(mm)')">{{
              data['OD_avg_Body_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_max_Body_(mm)')">{{
              data['OD_max_Body_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_min_Body_(mm)')">{{
              data['OD_min_Body_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OD_body_OoR_(mm)')">{{
              data['OD_body_OoR_(mm)']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_min_body')">{{
              data['WT_min_body']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_avg_body')">{{
              data['WT_avg_body']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('WT_max_body')">{{
              data['WT_max_body']
            }}</clr-dg-cell>

            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_body_mim')">{{
              data['Ecc_body_mim']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_body_avg')">{{
              data['Ecc_body_avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_body_max')">{{
              data['Ecc_body_max']
            }}</clr-dg-cell>

            <!-- AQUI -->
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_pipe_end_a_min')">{{
              data['Ecc_pipe_end_a_min']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_pipe_end_a_avg')">{{
              data['Ecc_pipe_end_a_avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_pipe_end_a_max')">{{
              data['Ecc_pipe_end_a_max']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_pipe_end_b_min')">{{
              data['Ecc_pipe_end_b_min']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_pipe_end_b_avg')">{{
              data['Ecc_pipe_end_b_avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ecc_pipe_end_b_max')">{{
              data['Ecc_pipe_end_b_max']
            }}</clr-dg-cell>
            <!-- ATE -->

            <clr-dg-cell *ngIf="shouldShowColumn('Fnl_prod_od_body_oor_mim')">{{
              data['Fnl_prod_od_body_oor_mim']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Fnl_prod_od_body_oor_avg')">{{
              data['Fnl_prod_od_body_oor_avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Fnl_prod_od_body_oor_max')">{{
              data['Fnl_prod_od_body_oor_max']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
