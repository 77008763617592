import {
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const getNestedTreeView = async (
  dataBookService: DataBookService,
  productId: number,
  context: TreeviewItemContext,
  fromFolderId?: number,
): Promise<TreeviewFolderItem[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getNestedTreeView',
    args: {
      productId,
      context,
      fromFolderId,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem[]>(params);
};
