<vic-base-page page="incoming-3-layer">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isDensityGspp()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            *ngIf="shouldShowColumn('DJ_No')"
            [style.width.px]="200"
            >{{ 'data.book.dj.no' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('SJ_No')"
            [style.width.px]="200"
            >{{ 'data.book.sj.no' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Length')"
            [style.width.px]="200"
            >{{ 'data.book.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Line')"
            [style.width.px]="200"
            >{{ 'data.book.line' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Event_Date')"
            [style.width.px]="200"
            >{{ 'data.book.event.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Visual_Inspection')"
            [style.width.px]="1600"
            >{{ 'data.book.visual.inspection' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Salt_Test_North')"
            [style.width.px]="200"
            >{{ 'data.book.salt.test.north' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Salt_Test_South')"
            [style.width.px]="200"
            >{{ 'data.book.salt.test.south' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_North_3')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.north.3' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_North_6')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.north.6' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_North_9')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.north.9' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_North_12')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.north.12' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_South_3')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.south.3' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_South_6')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.south.6' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_South_9')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.south.9' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Residual_Magnetism_South_12')"
            [style.width.px]="200"
            >{{
              'data.book.residual.magnetism.south.12' | translate
            }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell
              *ngIf="shouldShowColumn('DJ_No')"
              type="date"
              >{{ data['DJ_No'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('SJ_No')"
              type="date"
              >{{ data['SJ_No'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Length')"
              type="date"
              >{{ data['Length'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Line')"
              type="date"
              >{{ data['Line'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Event_Date')"
              type="date"
              >{{ data['Event_Date'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Visual_Inspection')"
              type="date"
              >{{ data['Visual_Inspection'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Salt_Test_North')"
              type="date"
              >{{ data['Salt_Test_North'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Salt_Test_South')"
              type="date"
              >{{ data['Salt_Test_South'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_North_3')"
              type="date"
              >{{ data['Residual_Magnetism_North_3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_North_6')"
              type="date"
              >{{ data['Residual_Magnetism_North_6'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_North_9')"
              type="date"
              >{{ data['Residual_Magnetism_North_9'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_North_12')"
              type="date"
              >{{ data['Residual_Magnetism_North_12'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_South_3')"
              type="date"
              >{{ data['Residual_Magnetism_South_3'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_South_6')"
              type="date"
              >{{ data['Residual_Magnetism_South_6'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_South_9')"
              type="date"
              >{{ data['Residual_Magnetism_South_9'] }}</clr-dg-cell
            >
            <clr-dg-cell
              *ngIf="shouldShowColumn('Residual_Magnetism_South_12')"
              type="date"
              >{{ data['Residual_Magnetism_South_12'] }}</clr-dg-cell
            >
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="openModalIRN"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div *ngIf="!items || !items.length">NO DATA</div>
    <clr-datagrid
      *ngIf="irn && irn.length"
      class="val-datagrid"
    >
      <clr-dg-column
        *ngIf="shouldShowColumn('Pipe_Num')"
        [style.width.px]="100"
        >{{ 'data.book.pipe.num' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Quality_Cerficate_Num')"
        [style.width.px]="200"
        >{{ 'data.book.quality.certificate.num' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Heat')"
        [style.width.px]="220"
        >{{ 'data.book.heat' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Bundle')"
        [style.width.px]="220"
        >{{ 'data.book.bundle' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Length')"
        [style.width.px]="220"
        >{{ 'data.book.length' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Weight')"
        [style.width.px]="220"
        >{{ 'data.book.weight' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Batch')"
        [style.width.px]="220"
        >{{ 'data.book.batch' | translate }}</clr-dg-column
      >
      <clr-dg-column
        *ngIf="shouldShowColumn('Technical_Deviation')"
        [style.width.px]="400"
        >{{ 'data.book.technical.deviation' | translate }}</clr-dg-column
      >

      <clr-dg-row *clrDgItems="let data of irn">
        <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Num')">{{
          data['Pipe_Num']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Quality_Cerficate_Num')">{{
          data['Quality_Cerficate_Num']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Heat')">{{
          data['Heat']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Bundle')">{{
          data['Bundle']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Length')">{{
          data['Length']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Weight')">{{
          data['Weight']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Batch')">{{
          data['Batch']
        }}</clr-dg-cell>
        <clr-dg-cell *ngIf="shouldShowColumn('Technical_Deviation')">{{
          data['Technical_Deviation']
        }}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination
          #paginationTaskDetails
          [clrDgPageSize]="10"
        >
          <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
            {{
              'application.commons.grid.footer.pagination.page.size' | translate
            }}
          </clr-dg-page-size>
          {{ paginationTaskDetails.firstItem + 1 }} -
          {{ paginationTaskDetails.lastItem + 1 }}
          {{ 'application.commons.grid.footer.pagination.of' | translate }}
          {{ paginationTaskDetails.totalItems }}
          {{ 'application.commons.grid.footer.pagination.records' | translate }}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.close' | translate }}
    </button>
  </div>
</clr-modal>
