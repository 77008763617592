<vic-base-page page="dataExplorer-tensile-new-template">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isPelmVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Order')"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Mill_Item')"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Pipe_Heat')"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Yield_strength_mpa')"
            >{{ 'data.book.yield.strength.mpa' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Uniform_elongation')"
            >{{ 'data.book.uniform.elongation' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Test_temperature')"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Pipe_wt')"
            >{{ 'data.book.pipe.wt' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Direction')"
            >{{ 'data.book.direction' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Standard')"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Sample_num')"
            >{{ 'data.book.sample.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Sample_type')"
            >{{ 'data.book.sample.type' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Initial_gauge_length')"
            >{{ 'data.book.initial.gauge.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Uts_rm_per_cent')"
            >{{ 'data.book.uts.rm.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Ys_uts')"
            >{{ 'data.book.ys.uts' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Elongation_per_cent')"
            >{{ 'data.book.elongation.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rm_mpa')"
            >{{ 'data.book.rm.mpa' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rm_min')"
            >{{ 'data.book.rm.min' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rm_max')"
            >{{ 'data.book.rm.max' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rt_05')"
            >{{ 'data.book.rt.05' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rt_05_min')"
            >{{ 'data.book.rt.05.min' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rt_05_max')"
            >{{ 'data.book.rt.05.max' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rx_rm')"
            >{{ 'data.book.rx.rm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Width_mm')"
            >{{ 'data.book.width.mm | translate' }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('A_mm2')"
            >{{ 'data.book.a.mm.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('E_per_cent')"
            >{{ 'data.book.e.per.cent' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Ue')"
            >{{ 'data.book.ue' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Rp_02')"
            >{{ 'data.book.rp.02' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Ordem')">{{
              data['Mill_Ordem']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Yield_strength_mpa')">{{
              data['Yield_strength_mpa']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Uniform_elongation')">{{
              data['Uniform_elongation']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_temperature')">{{
              data['Test_temperature']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_wt')">{{
              data['Pipe_wt']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Direction')">{{
              data['Direction']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Standard')">{{
              data['Standard']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Sample_num')">{{
              data['Sample_num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Sample_type')">{{
              data['Sample_type']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Initial_gauge_length')">{{
              data['Initial_gauge_length']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Uts_rm_per_cent')">{{
              data['Uts_rm_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ys_uts')">{{
              data['Ys_uts']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Elongation_per_cent')">{{
              data['Elongation_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rm_mpa')">{{
              data['Rm_mpa']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rm_min')">{{
              data['Rm_min']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rm_max')">{{
              data['Rm_max']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rt_05')">{{
              data['Rt_05']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rt_05_min')">{{
              data['Rt_05_min']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rt_05_max')">{{
              data['Rt_05_max']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rx_rm')">{{
              data['Rx_rm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Width_mm')">{{
              data['Width_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('A_mm2')">{{
              data['A_mm2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('E_per_cent')">{{
              data['E_per_cent']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Ue')">{{
              data['Ue']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Rp_02')">{{
              data['Rp_02']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
