<vic-base-page page="dataExplorer-hic">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isHicVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>
      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Order')"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Mill_Item')"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Pipe_Heat')"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Specimen')"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="105"
            *ngIf="shouldShowColumn('Test_Avg_HIC_test')"
            >{{ 'data.book.test.avg.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="110"
            *ngIf="shouldShowColumn('Test_Avg_SCC_test')"
            >{{ 'data.book.test.avg.ssc.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Test_1_HIC_test')"
            >{{ 'data.book.test.1.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Test_1_Last_date')"
            >{{ 'data.book.test.1.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Test_2_HIC_test')"
            >{{ 'data.book.test.2.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Test_2_Last_date')"
            >{{ 'data.book.test.2.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Test_3_HIC_test')"
            >{{ 'data.book.test.3.hic.test' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Test_3_Last_date')"
            >{{ 'data.book.test.3.last.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Test_Temperature')"
            >{{ 'data.book.test.temperature' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="145"
            *ngIf="shouldShowColumn('Standard')"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Qualification')"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Condition')"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Order')">{{
              data['Mill_Order']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Specimen')">{{
              data['Specimen']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_Avg_HIC_test')">{{
              data['Test_Avg_HIC_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_Avg_SCC_test')">{{
              data['Test_Avg_SCC_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_1_HIC_test')">{{
              data['Test_1_HIC_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_1_Last_date')">{{
              data['Test_1_Last_date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_2_HIC_test')">{{
              data['Test_2_HIC_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_2_Last_date')">{{
              data['Test_2_Last_date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_3_HIC_test')">{{
              data['Test_3_HIC_test']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_3_Last_date')">{{
              data['Test_3_Last_date']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Test_Temperature')">{{
              data['Test_Temperature']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Standard')">{{
              data['Standard']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Qualification')">{{
              data['Qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Condition')">{{
              data['Condition']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
