<vic-base-page page="dataExplorer-platina-d-joint">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isDoubleJointVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Order_Num')"
            >{{ 'data.book.order.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Order_Item')"
            >{{ 'data.book.order.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Dimension')"
            >{{ 'data.book.dimension' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('DJ_Num')"
            >{{ 'data.book.dj.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Pipe1_Num')"
            >{{ 'data.book.pipe1.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Pipe1_Length')"
            >{{ 'data.book.pipe1.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Pipe1_Welded_End')"
            >{{ 'data.book.pipe1.welded.end' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="50"
            *ngIf="shouldShowColumn('Pipe2_Num')"
            >{{ 'data.book.pipe2.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Pipe2_Length')"
            >{{ 'data.book.pipe2.length' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="150"
            *ngIf="shouldShowColumn('Pipe2_Welded_End')"
            >{{ 'data.book.pipe2.welded.end' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Order_Num')">{{
              data['Order_Num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Order_Item')">{{
              data['Order_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Dimension')">{{
              data['Dimension']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('DJ_Num')">{{
              data['DJ_Num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe1_Num')">{{
              data['Pipe1_Num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe1_Length')">{{
              data['Pipe1_Length']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe1_Welded_End')">{{
              data['Pipe1_Welded_End']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe2_Num')">{{
              data['Pipe2_Num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe2_Length')">{{
              data['Pipe2_Length']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe2_Welded_End')">{{
              data['Pipe2_Welded_End']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
