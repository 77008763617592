import {
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const addRootFolder = async (
  dataBookService: DataBookService,
  productId: number,
  context: TreeviewItemContext,
  name: string,
  description?: string,
  phase?: boolean,
  fixed?: boolean,
  oldDynamoId?: string,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'addRootFolder',
    args: {
      productId,
      context,
      name,
      description,
      phase,
      fixed,
      oldDynamoId,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};
