import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';
import { plainToInstance } from 'class-transformer';

export const findProductsFromProjectId = async (
  dataBookService: DataBookService,
  projectId: number,
  loadEntities = true,
): Promise<Product[] | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findProductsFromProjectId',
    args: {
      projectId,
      loadEntities,
    },
  };
  const items: any = await dataBookService.newLambda<Product[] | undefined>(
    params,
  );
  return items.map((item: Product) => {
    return plainToInstance(Product, item);
  });
};
