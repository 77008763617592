import { twoDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'DIMENSIONAL REPORT',
  headerClass: 'custom-header-2',
  children: [
    {
      headerName: 'OD Body',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_od_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_od_(min)']],
        },
        {
          field: 'Avg',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_od_(avg)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_od_(avg)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_od_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_od_(max)']],
        },
      ],
    },
    {
      headerName: 'WT Body',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_wt_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_wt_(min)']],
        },
        {
          field: 'Avg',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_wt_(avg)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_wt_(avg)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_wt_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_wt_(max)']],
        },
      ],
    },
    {
      headerName: 'OOR Body',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_oor_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_oor_(min)']],
        },
        {
          field: 'Avg',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_oor_(avg)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_oor_(avg)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_oor_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_oor_(max)']],
        },
      ],
    },
    {
      headerName: 'ECC Body',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_ecc_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_ecc_(min)']],
        },
        {
          field: 'Avg',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_ecc_(avg)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_ecc_(avg)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_body_ecc_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_ecc_(max)']],
        },
      ],
    },
    {
      headerName: 'OD Bottom',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_od_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_od_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_od_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_od_(max)']],
        },
      ],
    },
    {
      headerName: 'WT Bottom',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_wt_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_wt_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_wt_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_wt_(max)']],
        },
      ],
    },
    {
      headerName: 'OOR Bottom',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_oor_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_oor_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_oor_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_oor_(max)']],
        },
      ],
    },
    {
      headerName: 'ECC Bottom',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_ecc_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_ecc_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_bottom_ecc_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_bottom_ecc_(max)']],
        },
      ],
    },
    {
      headerName: 'OD Top',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_od_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_od_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_od_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_od_(max)']],
        },
      ],
    },
    {
      headerName: 'WT Top',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_wt_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_wt_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_wt_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_wt_(max)']],
        },
      ],
    },
    {
      headerName: 'OOR Top',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_oor_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_oor_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_oor_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_oor_(max)']],
        },
      ],
    },
    {
      headerName: 'ECC Top',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'Min',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_ecc_(min)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_ecc_(min)']],
        },
        {
          field: 'Max',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorerTwoDigits',
          digits: 2,
          symbol: '',
          valueFormatter: (params) =>
            twoDigitsNumber(
              '',
              params.data[COLUMNS['dimensional_top_ecc_(max)']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_top_ecc_(max)']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-2',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_file_name']],
        },
        {
          field: 'Inspection time',
          headerClass: 'custom-header-2',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['dimensional_body_inspection_time']],
        },
      ],
    },
  ],
};
