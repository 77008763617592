import { Material } from '../material.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findMaterialsFromProductId = async (
  dataBookService: DataBookService,
  productId: number,
): Promise<Material[]> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findMaterialsFromProductId',
    args: {
      productId,
    },
  };
  return await dataBookService.newLambda<Material[] | undefined>(params);
};
