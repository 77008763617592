<vic-base-page page="dataExplorer-pem">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isPelmVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            [style.width.px]="130"
            *ngIf="shouldShowColumn('Mill_Ordem')"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="120"
            *ngIf="shouldShowColumn('Mill_Item')"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Pipe_Heat')"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Measurement')"
            >{{ 'data.book.measurement' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Plane_mm')"
            >{{ 'data.book.plane.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Id_45')"
            >{{ 'data.book.id.45' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Id_90')"
            >{{ 'data.book.id.90' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Id_135')"
            >{{ 'data.book.id.135' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Id_180')"
            >{{ 'data.book.id.180' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Id_min_mm')"
            >{{ 'data.book.id.min.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Id_max_mm')"
            >{{ 'data.book.id.max.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_0')"
            >{{ 'data.book.wt.0' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_45')"
            >{{ 'data.book.wt.45' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_90')"
            >{{ 'data.book.wt.90' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_135')"
            >{{ 'data.book.wt.135' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_180')"
            >{{ 'data.book.wt.180' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_225')"
            >{{ 'data.book.wt.225' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_270')"
            >{{ 'data.book.wt.270' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_315')"
            >{{ 'data.book.wt.315' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_min_mm')"
            >{{ 'data.book.wt.min.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Wt_max_mm')"
            >{{ 'data.book.wt.max.mm' | translate }}</clr-dg-column
          >
          <clr-dg-column
            [style.width.px]="80"
            *ngIf="shouldShowColumn('Oor_id_max_mm')"
            >{{ 'data.book.oor.id.max.mm' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Ordem')">{{
              data['Mill_Ordem']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Measurement')">{{
              data['Measurement']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Plane_mm')">{{
              data['Plane_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Id_45')">{{
              data['Id_45']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Id_90')">{{
              data['Id_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Id_135')">{{
              data['Id_135']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Id_180')">{{
              data['Id_180']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Id_min_mm')">{{
              data['Id_min_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Id_max_mm')">{{
              data['Id_max_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_0')">{{
              data['Wt_0']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_45')">{{
              data['Wt_45']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_90')">{{
              data['Wt_90']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_135')">{{
              data['Wt_135']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_180')">{{
              data['Wt_180']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_225')">{{
              data['Wt_225']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_270')">{{
              data['Wt_270']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_315')">{{
              data['Wt_315']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_min_mm')">{{
              data['Wt_min_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Wt_max_mm')">{{
              data['Wt_max_mm']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Oor_id_max_mm')">{{
              data['Oor_id_max_mm']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
