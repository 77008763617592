<div class="treeview-dropdown-container">
  <button
    [class]="
      'treeview-dropdown-button' +
      (treeviewStore.selectedFiles.length > 0 ? '' : ' disable') +
      (open ? ' open' : '')
    "
    (click)="openDropdown()"
  >
    {{
      'databook.treeview.dropdown.selectedFiles'
        | translate
          : {
              length: this.treeviewStore.selectedFiles.length,
              size: treeviewStore.sizeFormated
            }
    }}
  </button>
  <ul
    class="treeview-dropdown-selection-container"
    *ngIf="treeviewStore.selectedFiles.length > 0 && open"
  >
    <li
      class="treeview-dropdown-row"
      *ngFor="let item of treeviewStore.selectedFiles"
    >
      <div>
        <img
          class="treeview-dropdown-row-icon"
          src="{{ item.icon }}"
        />
        <span class="treeview-dropdown-row-title">{{ item.description }}</span>
      </div>
      <div>
        <span class="treeview-dropdown-row-size"
          >{{ item.size | formatBytes }}</span
        >
        <button
          class="treeview-dropdown-row-button-delete"
          (click)="removeFromSelection(item)"
        >
          <img src="{{ treeviewStore.delete }}" />
        </button>
      </div>
    </li>
  </ul>
</div>
