import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FieldErrorComponent } from '@reactive-forms/components/field-error/field-error.component';

@Component({
  selector: 'pn-field-container',
  standalone: true,
  imports: [CommonModule, FieldErrorComponent, TranslateModule],
  templateUrl: './field-container.component.html',
  styleUrl: './field-container.component.scss',
})
export class FieldContainerComponent implements OnInit {
  @Input() label: string;
  @Input() labelKey: string;
  @Input() control: AbstractControl;

  required: boolean;

  ngOnInit(): void {
    this.required = this.control.hasValidator(Validators.required);
  }
}
