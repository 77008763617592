<div
  *mobxAutorun
  (click)="treeviewStore.onClickOutside($event)"
  class="treeview-container"
>
  <div
    class="treeview-header"
    *ngIf="!selectionFolderOnly"
  >
    <div style="display: flex; flex-direction: row; gap: 12px; flex-wrap: wrap">
      <div id="searchbar">
        <div class="treeview-header-button treeview-search-input">
          <div
            [class]="'search-box treeview-search-input-field ' + (!isViewModeTreeview() ? 'disabled' : '')"
          >
            <div class="search-icon">
              <clr-icon
                shape="search"
                *ngIf="!treeviewStore.searchText"
                style="flex: 1 0"
              ></clr-icon>
              <clr-icon
                shape="times"
                *ngIf="treeviewStore.searchText"
                style="cursor: pointer; flex: 1 0"
                (click)="treeviewStore.searchText = '';treeviewStore.onSearchButtonClick()"
              ></clr-icon>
            </div>
            <input
              class="search-input"
              type="text"
              [disabled]="!isViewModeTreeview()"
              [placeholder]="'application.commons.buttons.search' | translate"
              [(ngModel)]="treeviewStore.searchText"
              (ngModelChange)="treeviewStore.onSearchButtonClick()"
            />
          </div>
          <div
            class="autocomplete-options"
            *ngIf="treeviewStore.searchAutocompleteCandidates.length > 0"
            [style.left.px]="autocompletePosition.left"
            [style.top.px]="autocompletePosition.top"
          >
            <div
              *ngFor="let match of treeviewStore.searchAutocompleteCandidates"
              (click)="treeviewStore.onTreeviewAutocompleteClick(match)"
            >
              <img
                class="treeview-autocomplete-candidate"
                src="{{match.type === 'file' ? match.isPdf ? treeviewStore.pdfIconUri : treeviewStore.fileIconUri : treeviewStore.closedFolder}}"
              />{{ match.name }}
            </div>
          </div>
        </div>
        <div
          *ngIf="treeviewStore.searchText.length > 0 && treeviewStore.searchText.length < 2"
          class="treeview-search-error"
        >
          {{ 'databook.searchError' | translate }}
        </div>
      </div>
      <button
        class="header-treeview-button"
        [disabled]="!canAddRootFolder() || !isViewModeTreeview()"
        (click)="addItem()"
        title="{{ 'databook.treeview.addRootFolder' | translate }}"
      >
        <img src="{{treeviewStore.addFolder}}" />
      </button>
      <button
        class="header-treeview-button"
        [disabled]="selectionFolderOnly || treeviewStore.selectedFiles.length !== 1"
        (click)="downloadButton(3)"
        title="{{ 'databook.treeview.download' | translate }}"
      >
        <img src="{{treeviewStore.download}}" />
      </button>
      <button
        class="header-treeview-button"
        [disabled]="selectionFolderOnly || treeviewStore.selectedFiles.length <= 1"
        (click)="downloadButton(1)"
        title="{{ 'databook.treeview.zip' | translate }}"
      >
        <img src="{{treeviewStore.zip}}" />
      </button>
      <button
        class="header-treeview-button"
        [disabled]="selectionFolderOnly || treeviewStore.selectedFiles.length <= 0"
        (click)="treeviewStore.sendByEmailHandler.emit()"
        title="{{ 'databook.treeview.email' | translate }}"
      >
        <img src="{{treeviewStore.email}}" />
      </button>
      <treeview-dropdown-list-component />
    </div>

    <button
      class="header-treeview-button"
      (click)="changeView()"
      *ngIf="isDatabook()"
      title="{{ 'databook.treeview.changeview' | translate }}"
    >
      <clr-icon
        *ngIf="!isViewModeTreeview()"
        shape="tree-view"
        size="lg"
        solid="true"
        style="color: black !important"
      />
      <clr-icon
        *ngIf="isViewModeTreeview()"
        shape="table"
        size="lg"
        solid="true"
        style="color: black !important"
      />
    </button>
  </div>
  <treeview-item
    style="flex: 1"
    *ngIf="isViewModeTreeview()"
  />
  <treeview-options
    [canDownload]="canDownload()"
    [canUpload]="canUpload()"
    [canRename]="canRename()"
    [canRemove]="canRemove()"
    (deleteItem)="deleteItem()"
  />
  <gridview
    style="flex: 1"
    *ngIf="!isViewModeTreeview()"
  />
</div>

<!-- delete modal -->
<clr-modal
  class="modal-tree-view"
  [clrModalOpen]="modalIsOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div
      *ngIf="confirmDelete"
      class="clr-row"
    >
      <div class="clr-col-12">
        <b>{{deleteFolderOrFilleMessage}}</b>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      (click)="confirmDelete()"
    >
      {{ 'confirm.exclusion.delete' | translate }}
    </button>
  </div>
</clr-modal>

<!-- create item modal -->
<clr-modal
  [clrModalSize]="'sm'"
  [clrModalOpen]="createItemModalIsOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <form
      [formGroup]="formCreateItemForm"
      clrForm
    >
      <h4 class="modal-title">
        {{ 'databook.treeview.new_folder' | translate }}
      </h4>
      <clr-input-container>
        <label>{{ 'databook.treeview.name' | translate }}</label>
        <input
          clrInput
          name="itemToAdd"
          formControlName="itemToAdd"
          maxlength="30"
          minlength="3"
          required
        />
        <clr-control-error
          >{{ 'required.field.required' | translate }}</clr-control-error
        >
      </clr-input-container>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeAddItemModal()"
    >
      {{ 'databook.treeview.add_folder__cancel_action' | translate }}
    </button>

    <button
      type="submit"
      class="btn btn-outline"
      (click)="confirmAddFolder()"
      [disabled]="this.formCreateItemForm.controls['itemToAdd'].invalid"
    >
      {{ 'databook.treeview.add_folder_confirm_action' | translate }}
    </button>
  </div>
</clr-modal>
