import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ClrSelectModule } from '@clr/angular';
import { LabelValue } from '@interfaces/label-value';
import { TranslateModule } from '@ngx-translate/core';
import { FormField } from '@reactive-forms/classes/form-field';
import { FieldContainerComponent } from '@reactive-forms/components/field-container/field-container.component';
import { FieldErrorComponent } from '@reactive-forms/components/field-error/field-error.component';

@Component({
  selector: 'pn-select',
  standalone: true,
  imports: [
    CommonModule,
    ClrSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    FieldErrorComponent,
    FieldContainerComponent,
  ],
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
})
export class SelectComponent extends FormField {
  @Input() options: LabelValue[] = [];
}
