import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'databook-path-cell-renderer',
  templateUrl: './databook-path-cell-renderer.component.html',
  styleUrls: ['./databook-path-cell-renderer.component.scss'],
  preserveWhitespaces: false,
  standalone: true,
  imports: [NgFor, NgIf],
})
export class DatabookPathCellRendererComponent
  implements ICellRendererAngularComp
{
  files: string;

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams) {
    this.files = params.value.split('/').slice(0, -1);
    return false;
  }
}
