import { action, computed, observable } from 'mobx-angular';
import { EventEmitter, Injectable, InjectionToken } from '@angular/core';
import { makeAutoObservable } from 'mobx';
import {
  getLastMergeRequestsDoneFromProductId,
  MergeRequest,
  Product,
  Project,
  TreeviewFileItem,
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../classes';

import { plainToInstance } from 'class-transformer';
import {
  copyFiles,
  copyFolder,
  FolderContent,
  FolderNameAndId,
  getFileBySearchName,
  getFolder,
  getFolderContentForFrontEnd,
  getNestedTreeView,
  getRootFoldersForFrontEnd,
  GlobalSearchResult,
  moveFiles,
  moveFolders,
  ProductsFeaturesList,
  removeFile,
  removeFolder,
  renameFile,
  searchItem,
  searchItemGlobal,
  SearchTreeviewItemReturn,
  treeviewItemGetPath,
  updateFilePosition,
} from '../classes/treeview-helpers';
import { paramCase } from 'change-case';
import { Treeview } from '../pages/databook/treeview/treeview';
import { BasePageComponent } from '../core/components/page/base-page.component';
import { featureSlugMap } from '../classes/constants';
import { formatBytes } from '../pages/databook/gridview/tools';

export type FileOrFolder = TreeviewFileItem | TreeviewFolderItem;

@Injectable({
  providedIn: 'root',
})
export class TreeviewStore {
  @observable contextMenuDomElement: any | null = null;
  @observable projectStore: any | null = null;
  @observable samePath = 2;
  @observable selectedDownloadMode: 'default' | 'compressed' | 'individual' =
    'default';
  @observable readyMergeRequest: MergeRequest | null = null;
  @observable emailsForSendByEmail: TreeviewFileItem | null = null;
  @observable fileSelectedForBeingUpdated: TreeviewFileItem | null = null;
  @observable filesAddedForUpload: TreeviewFileItem[] = [];
  @observable targetFile: TreeviewFileItem | null = null;
  @observable targetFolder: TreeviewFolderItem | null = null;
  @observable folderTargetedForUpload: TreeviewFolderItem | null = null;
  @observable product: Product;
  @observable context: TreeviewItemContext;
  @observable tree: TreeviewFolderItem[];
  @observable userIsDragNDroping = false;
  @observable renaming = false;
  @observable isCreating = false;
  @observable isDeleting = false;
  @observable isCutting = false;
  @observable objectBeingDragNDroped: FileOrFolder[] = [];
  @observable objectPointedOnContextMenu: FileOrFolder | null = null;
  @observable focusedItem: FileOrFolder | null = null;
  @observable clipboard: FileOrFolder | FileOrFolder[] | null = null;
  @observable parentFolderForAddFolderOrFile: TreeviewFolderItem | null = null;
  @observable selectedObjects: FileOrFolder[] = [];
  @observable dataBookService: any;
  @observable mergedExists: string;
  @observable debounceTimer: NodeJS.Timeout | undefined = null;

  @observable searchText = '';
  @observable searchAutocompleteCandidates: SearchTreeviewItemReturn[] = [];

  @observable searchTextGlobal = '';
  @observable searchAutocompleteCandidatesGlobal: SearchTreeviewItemReturn[] =
    [];
  @observable
  searchAutocompleteCandidatesGlobalFeatures: ProductsFeaturesList[] = [];

  setObjectPointedOnContextMenu(
    objectPointedOnContextMenu: FileOrFolder | null,
  ) {
    this.objectPointedOnContextMenu = objectPointedOnContextMenu;
  }

  setSelectedObjects(selectedObjects: FileOrFolder[]) {
    this.selectedObjects = selectedObjects;
  }

  removeSelectedObject(item: FileOrFolder) {
    this.setSelectedObjects(
      this.selectedObjects.filter((object) => object !== item),
    );
  }

  getFolderPath(
    folderToCompare: TreeviewFolderItem,
    path = '',
    root?: TreeviewFolderItem[],
  ): string | null {
    if (!root) {
      root = this.tree;
    }
    for (const folder of root) {
      if (folder.id === folderToCompare.id) {
        return path + '/' + paramCase(folder.name);
      }
      const nestedPath = this.getFolderPath(
        folderToCompare,
        path + '/' + paramCase(folder.name),
        folder.folders,
      );
      if (nestedPath) {
        return nestedPath;
      }
    }
    return null;
  }

  getFilePath(
    fileToCompare: TreeviewFileItem,
    withFileInPath = true,
    path = '',
    root?: TreeviewFolderItem[],
  ): string | null {
    if (!root) {
      root = this.tree;
    }
    for (const folder of root) {
      for (const file of folder.files) {
        if (file.id === fileToCompare.id) {
          if (withFileInPath) {
            const fileExtention = file.name.split('.').pop();
            const fileWithoutExtention = file.name.replace(
              '.' + fileExtention,
              '',
            );

            const fileWithParamCase =
              paramCase(fileWithoutExtention) + '.' + fileExtention;
            return (
              path + '/' + paramCase(folder.name) + '/' + fileWithParamCase
            );
          } else {
            return path + '/' + paramCase(folder.name);
          }
        }
      }
      const nestedPath = this.getFilePath(
        fileToCompare,
        withFileInPath,
        path + '/' + paramCase(folder.name),
        folder.folders,
      );
      if (nestedPath) {
        return nestedPath;
      }
    }
    return null;
  }

  @computed get selectedFiles(): TreeviewFileItem[] {
    return this.selectedObjects.filter(
      (obj: FileOrFolder) => obj instanceof TreeviewFileItem,
    ) as TreeviewFileItem[];
  }

  @computed get selectedFilesForMergeRequest(): TreeviewFileItem[] {
    return this.selectedFiles.filter((file: TreeviewFileItem) => {
      return file.filetype && file.filetype.match(new RegExp('pdf'));
    }) as TreeviewFileItem[];
  }

  @computed get size(): number {
    return Math.floor(
      this.selectedFiles.reduce((acc, file) => acc + file.size, 0) /
        1024 /
        1024,
    );
  }

  @computed get sizeFormated(): string {
    return formatBytes(
      this.selectedFiles.reduce((acc, file) => acc + file.size, 0),
    );
  }

  downloadHandler = new EventEmitter<void>();
  openUpload = new EventEmitter<{ folder: TreeviewFolderItem }>();
  viewDocumentHandler = new EventEmitter<{ file: TreeviewFileItem }>();
  updateFileHandler = new EventEmitter<{ file: TreeviewFileItem }>();
  downloadVersionHandler = new EventEmitter<{ file: TreeviewFileItem }>();
  sendByEmailHandler = new EventEmitter<void>();
  openConfirmMerge = new EventEmitter<void>();
  treeviewComponent?: Treeview;
  basePageComponent?: BasePageComponent;

  treeListItems;
  coreService;
  selectionFolderOnly;

  fileIconUri = '/assets/img/png/file.png';
  pdfIconUri = '/assets/img/png/pdf-file.png';
  featureIcon = '/assets/img/png/feature-icon.png';
  closedFolder = '/assets/img/png/closed-folder.png';
  openedFolder = '/assets/img/png/opened-folder.png';

  cut = '/assets/img/png/cut.png';
  zip = '/assets/img/png/zip.png';
  upload = '/assets/img/png/upload.png';
  download = '/assets/img/png/download.png';
  version = '/assets/img/png/versions.png';
  viewDocument = '/assets/img/png/view-document.svg';
  copy = '/assets/img/png/copy.png';
  share = '/assets/img/png/share.svg';
  paste = '/assets/img/png/paste.png';
  edit = '/assets/img/png/edit.png';
  delete = '/assets/img/png/delete.png';
  up = '/assets/img/png/up.png';
  down = '/assets/img/png/down.png';
  addFolder = '/assets/img/png/add-folder.svg';
  addFile = '/assets/img/png/add-file.png';
  refresh = '/assets/img/png/refresh.svg';
  validate = '/assets/img/png/validate.png';
  cancel = '/assets/img/png/cancel.png';
  confirm = '/assets/img/png/confirm.svg';
  email = '/assets/img/png/email.svg';
  updateFile = '/assets/img/png/update-file.png';
  merge = '/assets/img/png/merge.png';
  selectAll = '/assets/img/png/select-all.png';
  searchIcon = '/assets/img/png/search-icon.png';

  constructor() {
    makeAutoObservable(this);
    if (this.product) {
      this.checkIfMergeRequestFileIsAvailableEvery30seconds();
    }
  }

  checkIfMergeRequestFileIsAvailableEvery30seconds() {
    const interval = setInterval(() => {
      if (this.readyMergeRequest === null) {
        getLastMergeRequestsDoneFromProductId(
          this.dataBookService,
          this.product.id,
        )
          .then((mergeRequests: MergeRequest[]) => {
            if (mergeRequests.length > 0) {
              this.readyMergeRequest = mergeRequests[0];
              clearInterval(interval);
            }
          })
          .catch((error: any) => {
            console.error(error);
          });
      }
    }, 30000);
  }

  contextMenuIsVisible = false;
  position = { x: 0, y: 0 };

  openContextMenu(event: any, obj: any) {
    if (!this.selectionFolderOnly) {
      event.preventDefault();
      if (obj?.folder_id) {
        this.objectPointedOnContextMenu = plainToInstance(
          TreeviewFolderItem,
          obj,
        );
      } else {
        this.objectPointedOnContextMenu = plainToInstance(
          TreeviewFileItem,
          obj,
        );
      }
      // this.position = {x: event.clientX, y: event.clientY+window.scrollY};
      this.position = { x: event.pageX, y: event.pageY };
      this.contextMenuIsVisible = true;

      this.contextMenuDomElement.nativeElement.style.left =
        this.position.x + 'px';
      this.contextMenuDomElement.nativeElement.style.top =
        this.position.y + 'px';
    }
  }

  onClickOutside($event: MouseEvent) {
    if (this.selectionFolderOnly) {
      return;
    }

    if (
      this.contextMenuDomElement &&
      this.contextMenuDomElement.nativeElement.contains($event.target as Node)
    ) {
      console.log('Clicked element is inside the contextMenuDomElement');
      return;
    }

    this.isCutting = false;
    this.contextMenuIsVisible = false;

    if (this.objectPointedOnContextMenu === null) {
      return;
    }

    this.setIsBeingCopied(this.objectPointedOnContextMenu, false);
    this.setIsBeingCutted(this.objectPointedOnContextMenu, false);
    this.objectPointedOnContextMenu = null;
  }

  insertFolderInTreeNestedAndUpdateImmutableTree(
    parentId: number | null,
    folderToInsert: TreeviewFolderItem,
  ) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    if (folderToInsert.parent_id === null) {
      this.tree = [folderToInsert, ...newTree];
      return;
    }
    const findParentFolderAndInsert = (folders: TreeviewFolderItem[]): any => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === parentId) {
          folders[i].isOpen = true;
          folders[i].folders = [folderToInsert, ...folders[i].folders];
          return folders;
        }

        if (folders[i].folders.length > 0) {
          const inserted = findParentFolderAndInsert(folders[i].folders);
          if (inserted) {
            return folders;
          }
        }
      }
      return false;
    };

    const result = findParentFolderAndInsert(newTree);
    console.log('result', result);
    this.tree = result;
  }

  //*UPDATE SET IS OPEN NESTED FROM FOLDER ID*//
  setIsOpenNested(folderToOpen: TreeviewFolderItem, isOpen: boolean) {
    const findParentFolderAndInsert = (folders: TreeviewFolderItem[]): any => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === folderToOpen.id) {
          folders[i].isOpen = isOpen;
          return folders;
        }

        if (folders[i].folders.length > 0) {
          const inserted = findParentFolderAndInsert(folders[i].folders);
          if (inserted) {
            return folders;
          }
        }
      }
      return false;
    };
    const result = findParentFolderAndInsert(this.tree);
    console.log('result', result);
    this.tree = result;
  }

  setIsOpenNestedOnSearch(folderToOpen: TreeviewFolderItem, isOpen: boolean) {
    const findAndOpenFolder = (folders: TreeviewFolderItem[]): any => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === folderToOpen.id) {
          folders[i].isOpen = isOpen;
          return folders;
        }

        if (folders[i].folders.length > 0) {
          folders[i].folders = findAndOpenFolder(folders[i].folders);
        }
      }
      return folders;
    };

    this.tree = findAndOpenFolder(this.tree);
  }

  //*UPDATE SET IS OPEN NESTED FROM FOLDER ID*//

  // NEW
  recursiveAddFilesInFolderInTreeFromFolderId(
    folders: TreeviewFolderItem[],
    folderId: number,
    files: TreeviewFileItem[],
  ) {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        // folders[i].files = [...folders[i].files, ...files];
        folders[i].files = [...files];
        return folders;
      }

      if (folders[i].folders.length > 0) {
        const inserted = this.recursiveAddFilesInFolderInTreeFromFolderId(
          folders[i].folders,
          folderId,
          files,
        );
        if (inserted) {
          return folders;
        }
      }
    }
    return false;
  }

  recursiveAddFoldersInFolderInTreeFromFolderId(
    folders: TreeviewFolderItem[],
    folderId: number,
    foldersToAdd: TreeviewFolderItem[],
  ) {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        // folders[i].folders = [...folders[i].folders, ...foldersToAdd];
        folders[i].folders = [...foldersToAdd];
        return folders;
      }

      if (folders[i].folders.length > 0) {
        const inserted = this.recursiveAddFoldersInFolderInTreeFromFolderId(
          folders[i].folders,
          folderId,
          foldersToAdd,
        );
        if (inserted) {
          return folders;
        }
      }
    }
    return false;
  }

  // NEW

  /*NEW IN STORE FODLER CLICK HANLDER */
  async onFolderClick(
    folder: TreeviewFolderItem,
    ignoreOpenCloseFolder = false,
  ) {
    if (this.renaming || this.isCreating || this.isDeleting) {
      return;
    }
    this.setIsFocused(folder);
    if (!ignoreOpenCloseFolder) {
      this.setIsOpenNested(folder, !folder.isOpen);
    }
    await this.updateViewFilesAndFolders(folder, false);
  }

  public async updateViewFilesAndFolders(
    folder: TreeviewFolderItem,
    clear: boolean,
  ) {
    const content: FolderContent = (await getFolderContentForFrontEnd(
      this.dataBookService,
      folder.id,
    )) as FolderContent;
    folder.folders = content.folders.map((item: FolderNameAndId) => {
      return plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: false,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: null,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });
    });
    // if (clear) {
    //     folder.folders = [];
    //     folder.files = [];
    // }
    this.recursiveAddFoldersInFolderInTreeFromFolderId(
      this.tree,
      folder.id,
      folder.folders,
    );
    folder.files = content.files.map((_file: TreeviewFileItem) => {
      const file = plainToInstance(TreeviewFileItem, _file);
      if (this.projectStore.file && file.id === this.projectStore.file.id) {
        this.targetFile = file;
      }
      return file;
    });
    this.recursiveAddFilesInFolderInTreeFromFolderId(
      this.tree,
      folder.id,
      folder.files,
    );
  }

  findFolderObjectFromTreeRecursivelyFromFolderId(
    folderId: number,
    folders: TreeviewFolderItem[],
  ): TreeviewFolderItem | null {
    for (let i = 0; i < folders.length; i++) {
      if (folders[i].id === folderId) {
        return folders[i];
      }

      if (folders[i].folders.length > 0) {
        const found = this.findFolderObjectFromTreeRecursivelyFromFolderId(
          folderId,
          folders[i].folders,
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  addFilesFromFolderIdToSelectedObjects(folder: TreeviewFolderItem) {
    const folderFound: TreeviewFolderItem | null =
      this.findFolderObjectFromTreeRecursivelyFromFolderId(
        folder.id,
        this.tree,
      );
    if (folderFound !== null) {
      folderFound.files.forEach((file: TreeviewFileItem) => {
        this.selectedObjects.push(file);
      });
    }
  }

  async selectAllFilesInFolderNested(
    folder: TreeviewFolderItem,
  ): Promise<void> {
    await this.onFolderClick(folder, folder.isOpen);
    this.addFilesFromFolderIdToSelectedObjects(folder);
    for await (const folderItem of folder.folders) {
      await this.selectAllFilesInFolderNested(folderItem);
    }
  }

  copyToClipboard(text) {
    const input = document.createElement('input');
    input.style.display = 'none';
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  transformTreeToList(tree, list) {
    const traverseRecursively = (no) => {
      if (no.files && Array.isArray(no.files) && no.files.length) {
        // Add files to list
        for (const fileItem of no.files) {
          list.push(fileItem.s3key);
        }
      }
      if (no.folders && Array.isArray(no.folders) && no.folders.length) {
        // Add folders to list
        for (const fileItem of no.folders) {
          list.push(fileItem.name);
        }
        // Traverse the folders recursively
        for (const folder of no.folders) {
          traverseRecursively(folder);
        }
      }
    };
    // Traverse the tree recursively
    traverseRecursively(tree);
    return list;
  }

  // treeToList(list) {
  //     const allowedIds = [];
  //     for (const selected of this.tree.treeModel.selectedLeafNodes) {
  //         let parentData = selected.parent;
  //         while (parentData && parentData.data && parentData.data.id) {
  //             allowedIds.push(parentData.data.id);

  //             parentData = parentData.parent;
  //         }
  //         allowedIds.push(selected.data.id);
  //     }
  //
  //     for (const item of this.tree.treeModel.nodes) {
  //         this.getAllItemsPerChildren(item, list, allowedIds);
  //     }
  //
  //     for (const item of list) {
  //         if (item.children) {
  //             delete item.children;
  //         }
  //     }
  // }

  mergeFiles() {
    const newTree = [];
    for (const item of this.tree) {
      const newItem = this.filterSelectedTree(item, this.selectedFiles);
      newTree.push(newItem);
    }

    this.treeListItems = [];
    for (const itemTree of newTree) {
      this.treeListItems.push(itemTree.name);
      this.transformTreeToList(itemTree, this.treeListItems);
    }

    this.openConfirmMerge.emit();
  }

  async onMenuItemClick(
    $event,
    option:
      | 'Cut'
      | 'Copy'
      | 'Paste'
      | 'Upload File'
      | 'Rename'
      | 'Remove'
      | 'Add Folder'
      | 'View'
      | 'Share'
      | 'Email'
      | 'Update file'
      | 'Download'
      | 'Select all'
      | 'Download compressed'
      | 'Download version'
      | 'Up'
      | 'Down',
  ) {
    try {
      this.coreService.showLoading();

      let file: TreeviewFileItem | undefined = undefined;
      let folder: TreeviewFolderItem | undefined = undefined;
      let folderOrFile: any | undefined = undefined;
      switch (option) {
        case 'Share':
          this.clipboard = this.objectPointedOnContextMenu;
          this.setIsBeingCopied(this.objectPointedOnContextMenu, true);
          try {
            const item = this.objectPointedOnContextMenu as any;
            let url = '';
            let contextFinal: string = this.context;
            if (contextFinal === 'edoc') {
              contextFinal = 'e-doc';
            }
            if (item.folder_id !== undefined) {
              // file
              url = `${window.location.origin}/${this.projectStore.selectedProject.slug}/${this.projectStore.selectedProduct.slug}/${contextFinal}/${this.objectPointedOnContextMenu.id}`;
            } else {
              url = `${window.location.origin}/${this.projectStore.selectedProject.slug}/${this.projectStore.selectedProduct.slug}/${contextFinal}/folder/${this.objectPointedOnContextMenu.id}`;
            }
            // this.copyToClipboard(url);
            await navigator.clipboard.writeText(url);
          } catch (err) {
            console.error('Error in copying text to clipboard: ', err);
          }
          break;
        case 'Paste':
          if (this.clipboard) {
            if (this.isCutting) {
              if (Array.isArray(this.clipboard)) {
                for (const item of Object.values(this.clipboard)) {
                  await this.moveItem(
                    this.dataBookService,
                    [item],
                    this.objectPointedOnContextMenu as TreeviewFolderItem,
                  );
                  this.isCutting = false;
                }
              } else {
                await this.moveItem(
                  this.dataBookService,
                  [this.clipboard],
                  this.objectPointedOnContextMenu as TreeviewFolderItem,
                );
                this.isCutting = false;
              }
            } else {
              if (Array.isArray(this.clipboard)) {
                for (const item of Object.values(this.clipboard)) {
                  await this.copyItem(
                    this.dataBookService,
                    [item],
                    this.objectPointedOnContextMenu as TreeviewFolderItem,
                  );
                  await this.coreService.showAlertSuccessTranslate(
                    'databook.success.multiple.move.message',
                  );
                }
              } else {
                await this.copyItem(
                  this.dataBookService,
                  [this.clipboard],
                  this.objectPointedOnContextMenu as TreeviewFolderItem,
                );
                await this.coreService.showAlertSuccessTranslate(
                  'databook.success.single.move.message',
                );
              }
            }

            this.clipboard = null;
          }
          $event.stopPropagation();
          this.contextMenuIsVisible = false;
          break;

        case 'Copy':
          this.clipboard =
            this.selectedObjects.length > 0
              ? this.selectedObjects.filter((item) => {
                  if ('name' in item) {
                    return item;
                  }
                })
              : this.objectPointedOnContextMenu;
          this.setIsBeingCopied(this.clipboard, true);
          this.setIsBeingCutted(this.clipboard, false);
          await this.coreService.showAlertSuccessTranslate(
            'databook.success.single.copy.message',
          );
          this.isCutting = false;
          $event.stopPropagation();
          break;
        case 'Cut':
          this.clipboard =
            this.selectedObjects.length > 0
              ? this.selectedObjects.filter((item) => {
                  if ('filetype' in item) {
                    return item;
                  }
                })
              : this.objectPointedOnContextMenu;
          this.setIsBeingCutted(this.clipboard, true);
          this.setIsBeingCopied(this.clipboard, false);
          this.isCutting = true;
          await this.coreService.showAlertSuccessTranslate(
            'databook.success.single.cut.message',
          );
          $event.stopPropagation();
          break;
        case 'Up':
          await this.updatePosition($event, false);
          break;
        case 'Down':
          await this.updatePosition($event, true);
          break;
        case 'View':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id !== undefined) {
            file = plainToInstance(TreeviewFileItem, folderOrFile);
            this.viewDocumentHandler.emit({ file: file });
          } else {
            alert("You can't view a folder");
          }
          break;
        case 'Download':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id !== undefined) {
            file = plainToInstance(TreeviewFileItem, folderOrFile);
            this.samePath = 3;

            this.downloadHandler.emit();
          } else {
            alert("You can't view a folder");
          }
          break;
        case 'Download compressed':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id !== undefined) {
            file = plainToInstance(TreeviewFileItem, folderOrFile);
            this.selectedFiles.push(file);
            this.samePath = 2;
            this.downloadHandler.emit();
          } else {
            alert("You can't view a folder");
          }
          break;

        case 'Download version':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id !== undefined) {
            file = plainToInstance(TreeviewFileItem, folderOrFile);
            this.downloadVersionHandler.emit({ file: file });
          } else {
            alert("You can't download a folder version");
          }
          break;

        case 'Update file':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id !== undefined) {
            const selectedFolder = await getFolder(
              this.dataBookService,
              folderOrFile.folder_id,
              false,
              false,
            );
            const folderInstance = plainToInstance(
              TreeviewFolderItem,
              selectedFolder,
            );
            this.folderTargetedForUpload = folderInstance;

            file = plainToInstance(TreeviewFileItem, folderOrFile);
            this.updateFileHandler.emit({ file: file });
          } else {
            alert("You can't update a folder");
          }
          break;
        case 'Email':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id !== undefined) {
            file = plainToInstance(TreeviewFileItem, folderOrFile);
            this.selectedObjects = [file];
            this.sendByEmailHandler.emit();
          } else {
            alert("You can't view a folder");
          }
          break;
        case 'Upload File':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id === undefined) {
            folder = plainToInstance(TreeviewFolderItem, folderOrFile);
            const files: TreeviewFileItem[] =
              await this.openFolderFilesOnly(folder);
            this.folderTargetedForUpload = folder;
            this.folderTargetedForUpload.files = files;
            this.openUpload.emit({ folder: folder });
          } else {
            alert("You can't view a folder");
          }
          break;
        case 'Add Folder':
          folderOrFile = this.objectPointedOnContextMenu as any;
          if (folderOrFile.folder_id === undefined) {
            // is file type
            folder = plainToInstance(TreeviewFolderItem, folderOrFile);
            this.parentFolderForAddFolderOrFile = folder;
            const newFolder = plainToInstance(TreeviewFolderItem, {
              id: null,
              name: '',
              folders: [],
              files: [],
              isBeingCopied: false,
              isBeingCreating: true,
              isFocus: true,
              isRoot: false,
              phase: false,
              fixed: false,
              parent_id: folder.id,
              product_id: this.product.id,
              treeview_context: this.context,
              renamingFieldValue: '',
            });
            this.insertFolderInTreeNestedAndUpdateImmutableTree(
              folder.id,
              newFolder,
            );
          } else {
            alert("You can't add a folder here");
          }
          this.isCreating = false;
          break;
        case 'Rename':
          if (this.objectPointedOnContextMenu !== null) {
            this.renaming = true;
            folderOrFile = this.objectPointedOnContextMenu as any;

            if (!folderOrFile?.folder_id) {
              folder = plainToInstance(TreeviewFolderItem, folderOrFile);

              this.modifyFolderPropertyFromTreeRecurcivly(
                this.tree,
                folder,
                'renaming',
                true,
              );
              this.modifyFolderPropertyFromTreeRecurcivly(
                this.tree,
                folder,
                'renamingFieldValue',
                folder.name,
              );
            } else {
              file = plainToInstance(TreeviewFileItem, folderOrFile);

              this.modifyFilePropertyFromTreeRecurcivly(
                this.tree,
                file,
                'renaming',
                true,
              );
              this.modifyFilePropertyFromTreeRecurcivly(
                this.tree,
                file,
                'renamingFieldValue',
                file.description,
              );
            }
          }
          this.renaming = false;
          break;
        // FIXME : quick and dirty fix  commented by lotfi add refactor this store is called on component
        //       the new function is deleteFolderFromStore and deleteFileFromStore on @action at the end of the file
        // case 'Remove':
        //   // if (this.objectPointedOnContextMenu !== null) {
        //   //   this.isDeleting = true;
        //   //   folderOrFile = this.objectPointedOnContextMenu as any;
        //   //   if (folderOrFile.folder_id === undefined) {
        //   //     folder = plainToInstance(TreeviewFolderItem, folderOrFile);
        //   //   } else {
        //   //     file = plainToInstance(TreeviewFileItem, folderOrFile);
        //   //   }
        //   //   if (
        //   //       confirm(
        //   //           `Are you sure you want to delete the ${(folder && folder?.name) ?  `folder ${folder?.name}` : `file ${ file?.description ||folder?.description }`}`
        //   //       )
        //   //   ) {
        //   //     if (file) {
        //   //       const deleteResponse: boolean = await removeFile(
        //   //         this.dataBookService,
        //   //         file.id
        //   //       );
        //   //       if (deleteResponse) {
        //   //         this.removeFile(file);
        //   //       } else {
        //   //         alert('An error occured while deleting the folder');
        //   //       }
        //   //     } else if (folder) {
        //   //       const deleteResponse: boolean = await removeFolder(
        //   //         this.dataBookService,
        //   //         folder.id
        //   //       );
        //   //       if (deleteResponse) {
        //   //         this.removeFolderFromTree(folder);
        //   //       } else {
        //   //         alert('An error occured while deleting the folder');
        //   //       }
        //   //     }
        //   //   }
        //   // }
        //   this.isDeleting = false;
        //   break;
        case 'Select all':
          this.selectedObjects = [];
          if (this.objectPointedOnContextMenu !== null) {
            folderOrFile = this.objectPointedOnContextMenu as any;
            if (folderOrFile.folder_id === undefined) {
              folder = plainToInstance(TreeviewFolderItem, folderOrFile);
            } else {
              file = plainToInstance(TreeviewFileItem, folderOrFile);
            }
            if (folder) {
              this.coreService.showLoading();
              await this.selectAllFilesInFolderNested(folder);
              this.coreService.hideLoading();
            }
          }
          break;
      }
      this.contextMenuIsVisible = false;
    } catch (error) {
      this.coreService.processError(error);
      this.coreService.hideLoading();
    }
    this.coreService.hideLoading();
  }

  private filterSelectedTree(tree, selectedFiles) {
    // Check if it's a selected file
    // const selectedFile = (file) => {
    //     return selectedFiles.includes(file);
    // }

    // Helper function to iterate recursively
    const traverseRecursively = (no) => {
      // Check if it's a file
      if (no.files && Array.isArray(no.files)) {
        // Filter only selected files
        // no.files = no.files.filter(selectedFile);
        no.files = no.files.filter((item) => {
          const found = selectedFiles.find((obj) => obj.id === item.id);
          return found;
        });
        console.log(2);
      }

      // Check if you have folders
      if (no.folders && Array.isArray(no.folders)) {
        // Filter folders recursively

        no.folders = no.folders.map(traverseRecursively).filter((folder) => {
          // Verificar se a pasta tem arquivos selecionados ou pastas filhas
          return (
            folder.files.length > 0 ||
            folder.folders.length > 0 ||
            folder.files.some((file) =>
              selectedFiles.find((selectedFile) => selectedFile.id === file.id),
            )
          );
        });

        // no.folders = no.folders
        //     .map(traverseRecursively)
        //     .filter(folder => folder.files.length > 0 || folder.folders.length > 0);
        console.log(1);
      }

      return no;
    };

    // Create a copy of the original tree
    const bewTree = JSON.parse(JSON.stringify(tree));

    // Traverse the tree recursively and filter selected files
    return traverseRecursively(bewTree);
  }

  private async updatePosition($event, down: boolean): Promise<void> {
    if (this.objectPointedOnContextMenu !== null) {
      this.coreService.showLoading();
      const folderOrFile = this.objectPointedOnContextMenu as any;
      const file = plainToInstance(TreeviewFileItem, folderOrFile);
      const updated = await updateFilePosition(
        this.dataBookService,
        file.folder_id,
        file.id,
        file.position,
        down,
      );
      if (updated && updated.ok) {
        this.updateFilePositionOnFolder(file, down);
      }
      this.coreService.hideLoading();
    }
    $event.stopPropagation();
  }

  removeFolderFromTree(folder: TreeviewFolderItem) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    if (folder.parent_id === null) {
      this.tree = this.tree.filter((_folder: TreeviewFolderItem) => {
        return _folder.id !== folder.id;
      });
      return;
    }

    const findParentFolderAndDelete = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === folder.parent_id) {
          folders[i].folders = folders[i].folders.filter(
            (_folder: TreeviewFolderItem) => {
              return _folder.id !== folder.id;
            },
          );
          return true;
        }

        if (folders[i].folders.length > 0) {
          const deleted = findParentFolderAndDelete(folders[i].folders);
          if (deleted) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndDelete(newTree);
    this.tree = newTree;
  }

  removeCreatingFolderFolderFromTree() {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    this.tree = this.tree.filter((_folder: TreeviewFolderItem) => {
      return _folder.isBeingCreating === false;
    });

    const findParentFolderAndDelete = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        folders[i].folders = folders[i].folders.filter(
          (_folder: TreeviewFolderItem) => {
            return _folder.isBeingCreating === false;
          },
        );
        if (folders[i].folders.length > 0) {
          const deleted = findParentFolderAndDelete(folders[i].folders);
          if (deleted) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndDelete(newTree);
    this.tree = newTree;
  }

  updateFilePositionOnFolder(file: TreeviewFileItem, down: boolean) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    const findParentFolderAndUpdate = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === file.folder_id) {
          const currentIdxFile = folders[i].files.findIndex(
            (obj) => obj.id === file.id,
          );
          let newIdxFile = currentIdxFile - 1;
          if (down) {
            newIdxFile = currentIdxFile + 1;
          }

          const currPos = folders[i].files[currentIdxFile].position;
          const newPos = folders[i].files[newIdxFile].position;

          const temp = folders[i].files[currentIdxFile];
          folders[i].files[currentIdxFile] = folders[i].files[newIdxFile];
          folders[i].files[newIdxFile] = temp;
          folders[i].files[newIdxFile].position =
            folders[i].files[currentIdxFile].position;

          folders[i].files[currentIdxFile].position = currPos;
          folders[i].files[newIdxFile].position = newPos;

          return true;
        }
        if (folders[i].folders.length > 0) {
          const updated = findParentFolderAndUpdate(folders[i].folders);
          if (updated) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndUpdate(newTree);
    this.tree = newTree;
  }

  updateFileInTree(file: TreeviewFileItem) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    const findParentFolderAndUpdate = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === file.folder_id) {
          folders[i].files = folders[i].files.map((_file: TreeviewFileItem) => {
            if (_file.id === file.id) {
              _file.name = file.name;
              _file.s3key = file.s3key;
              _file.datetime = file.datetime;
              return _file;
            }
            return _file;
          });
          return true;
        }
        if (folders[i].folders.length > 0) {
          const updated = findParentFolderAndUpdate(folders[i].folders);
          if (updated) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndUpdate(newTree);
    this.tree = newTree;
  }

  sortByName(arr) {
    arr.sort(function (a, b) {
      const descA = a.name.toUpperCase();
      const descB = b.name.toUpperCase();
      if (descA < descB) {
        return -1; // a before b
      }
      if (descA > descB) {
        return 1; // b before a
      }
      return 0; // same order
    });
  }

  insertFileInTree(file: TreeviewFileItem, parentFolderId: number) {
    const newTree: TreeviewFolderItem[] = [...this.tree];

    const findParentFolderAndInsert = async (
      folders: TreeviewFolderItem[],
    ): Promise<boolean> => {
      for (const folder of folders) {
        if (folder.id === parentFolderId) {
          file.folder_id = parentFolderId;

          const description = file.description;

          const newDescription = this.descriptionOfCopiedFile(
            folder.files,
            file,
          );

          if (description !== newDescription) {
            file.description = newDescription;

            await renameFile(this.dataBookService, file.id, file.description);

            this.modifyFilePropertyFromTreeRecurcivly(
              undefined,
              file,
              'renaming',
              false,
            );
            this.modifyFilePropertyFromTreeRecurcivly(
              undefined,
              file,
              'renamingFieldValue',
              '',
            );
            this.modifyFilePropertyFromTreeRecurcivly(
              undefined,
              file,
              'description',
              file.description,
            );
          }
          folder.files.push(file);

          this.sortByName(folder.files);
          return true;
        }

        if (folder.folders.length > 0) {
          const inserted = await findParentFolderAndInsert(folder.folders);

          if (inserted) {
            return true;
          }
        }
      }

      return false;
    };

    findParentFolderAndInsert(newTree);
    this.tree = newTree;
  }

  insertFolderInTree(folder: TreeviewFolderItem, parentFolderId: number) {
    const newTree: TreeviewFolderItem[] = [...this.tree];

    const findParentFolderAndInsert = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === parentFolderId) {
          folders[i].folders.push(folder);
          this.sortByName(folders[i].folders);
          return true;
        }

        if (folders[i].folders.length > 0) {
          const inserted = findParentFolderAndInsert(folders[i].folders);
          if (inserted) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndInsert(newTree);
    this.tree = newTree;
  }

  removeFileInTree(file: TreeviewFileItem) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    const findParentFolderAndDelete = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === file.folder_id) {
          folders[i].files = folders[i].files.filter(
            (_file: TreeviewFileItem) => {
              return _file.id !== file.id;
            },
          );
          return true;
        }
        if (folders[i].folders.length > 0) {
          const deleted = findParentFolderAndDelete(folders[i].folders);
          if (deleted) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndDelete(newTree);
    this.tree = newTree;
  }

  removeFolderInTree(folder: TreeviewFolderItem) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    const findParentFolderAndDelete = (
      folders: TreeviewFolderItem[],
    ): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === folder.id) {
          folders.splice(i, 1);
          return true;
        }
        if (folders[i].folders.length > 0) {
          const deleted = findParentFolderAndDelete(folders[i].folders);
          if (deleted) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndDelete(newTree);
    this.tree = newTree;
  }

  addFileIntoFolder(file: TreeviewFileItem, folder: TreeviewFolderItem) {
    const newTree: TreeviewFolderItem[] = [...this.tree];
    const findParentFolderAndAdd = (folders: TreeviewFolderItem[]): boolean => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === folder.id) {
          folders[i].files.push(file);
          return true;
        }
        if (folders[i].folders.length > 0) {
          const added = findParentFolderAndAdd(folders[i].folders);
          if (added) {
            return true;
          }
        }
      }
      return false;
    };

    findParentFolderAndAdd(newTree);
    this.tree = newTree;
  }

  async copyItem(
    dataBookService: any,
    items: FileOrFolder[],
    targetFolder: TreeviewFolderItem,
  ) {
    this.coreService.showLoading();

    const filesToMove: TreeviewFileItem[] = [];
    const foldersToMove: TreeviewFolderItem[] = [];

    for (const item of items) {
      if (item?.folder_id) {
        filesToMove.push(plainToInstance(TreeviewFileItem, item));
      } else {
        const folder = plainToInstance(TreeviewFolderItem, item);
        folder.files = await this.openFolderFilesOnly(folder);
        folder.folders = await this.openFoldersFoldersOnly(folder);
        foldersToMove.push(plainToInstance(TreeviewFolderItem, folder));
      }
    }

    if (filesToMove?.length > 0) {
      const response = await copyFiles(
        dataBookService,
        filesToMove.map(({ id }) => Number(id)),
        targetFolder.id,
      );
      if (response) {
        for (let i = 0; i < filesToMove.length; i++) {
          filesToMove[i].id = response[i];
          this.copyFile(filesToMove[i], targetFolder);
        }
      } else {
        console.log('error on moving files');
      }
    }

    for (const folderToMove of foldersToMove) {
      const response = await copyFolder(
        dataBookService,
        folderToMove.id,
        targetFolder.id,
      );
      if (response) {
        folderToMove.id = response.id;
        this.copyFolder(folderToMove, targetFolder);
      } else {
        console.log('error on moving folder');
      }
    }

    this.coreService.hideLoading();
  }

  //todo:TOASTS
  async moveItem(
    dataBookService: any,
    items: FileOrFolder[],
    targetFolder: TreeviewFolderItem,
  ) {
    console.log('item ===> ', items);
    console.log('targetFolder ===> ', targetFolder);

    this.coreService.showLoading();

    const filesToMove: TreeviewFileItem[] = [];
    const foldersToMove: TreeviewFolderItem[] = [];

    for (const item of items) {
      if (item?.folder_id) {
        filesToMove.push(plainToInstance(TreeviewFileItem, item));
      } else {
        foldersToMove.push(plainToInstance(TreeviewFolderItem, item));
      }
    }

    if (filesToMove?.length) {
      const response = await moveFiles(
        dataBookService,
        filesToMove.map(({ id }) => Number(String(id).replace(/[{}]/g, ''))),
        targetFolder.id,
      );

      if (!response) {
        console.log('error o move file');
      }

      this.moveFiles(filesToMove, targetFolder);
    }

    if (foldersToMove?.length) {
      const response = await moveFolders(
        dataBookService,
        foldersToMove.map(({ id }) => Number(String(id).replace(/[{}]/g, ''))),
        targetFolder.id,
      );

      if (!response) {
        console.log('error o move folder');
      }

      this.moveFolders(foldersToMove, targetFolder);
    }

    this.coreService.hideLoading();
  }

  moveFiles(files: TreeviewFileItem[], targetFolder: TreeviewFolderItem) {
    for (const file of files) {
      const fileToMove = plainToInstance(TreeviewFileItem, file);

      this.removeFileInTree(fileToMove);
      this.insertFileInTree(fileToMove, targetFolder.id);
    }
  }

  moveFolders(folders: TreeviewFolderItem[], targetFolder: TreeviewFolderItem) {
    for (const folder of folders) {
      const folderToMove = plainToInstance(TreeviewFolderItem, folder);
      this.removeFolderInTree(folderToMove);
      this.insertFolderInTree(folderToMove, targetFolder.id);
    }
  }

  copyFile(file: TreeviewFileItem, targetFolder: TreeviewFolderItem) {
    const fileToMove = plainToInstance(TreeviewFileItem, file);
    //this.removeFileInTree(fileToMove);
    this.insertFileInTree(fileToMove, targetFolder.id);
  }

  copyFiles(files: TreeviewFileItem[], targetFolder: TreeviewFolderItem) {
    for (const file of files) {
      const fileToInsert = plainToInstance(TreeviewFileItem, file);
      this.insertFileInTree(fileToInsert, targetFolder.id);
    }
  }

  async copyFolder(
    folder: TreeviewFolderItem,
    targetFolder: TreeviewFolderItem,
  ) {
    const folderToMove = plainToInstance(TreeviewFolderItem, folder);

    await this.copyItem(this.dataBookService, folderToMove.files, folderToMove);

    // Copiar cada subpasta na pasta
    for (const subfolder of folderToMove.folders) {
      await this.copyItem(this.dataBookService, [subfolder], folderToMove);
    }

    this.insertFolderInTree(folderToMove, targetFolder.id);
  }

  removeFile(
    file: TreeviewFileItem,
    folders: TreeviewFolderItem[] | undefined = undefined,
  ) {
    if (!folders) {
      folders = [...this.tree];
    }
    folders.forEach((folder: TreeviewFolderItem, indexFolder: number) => {
      if (folder.files) {
        folder.files.forEach(
          (fileInFolder: TreeviewFileItem, indexFile: number) => {
            if (fileInFolder.id === file.id) {
              folders[indexFolder].files.splice(
                folder.files.indexOf(fileInFolder),
                1,
              );
              return;
            }
          },
        );
      }
      if (folder.folders) {
        this.removeFile(file, folder.folders);
      }
    });
    return (this.tree = [...folders]);
  }

  removeFolder(
    folder: TreeviewFolderItem,
    folders: TreeviewFolderItem[] | undefined = undefined,
  ) {
    if (!folders) {
      folders = [...this.tree];
    }
    folders.forEach((_folder: TreeviewFolderItem, indexFolder: number) => {
      if (_folder.id === folder.id) {
        folders[indexFolder].files.splice(folders.indexOf(_folder), 1);
        return;
      }
      if (_folder.folders) {
        this.removeFolder(folder, _folder.folders);
      }
    });
    return (this.tree = [...folders]);
  }

  addFileInFolder(
    file: TreeviewFileItem,
    folder: TreeviewFolderItem,
    folders: TreeviewFolderItem[] | undefined = undefined,
  ) {
    if (!folders) {
      folders = [...this.tree];
    }

    folders.forEach((_folder: TreeviewFolderItem, indexFolder: number) => {
      if (_folder.id === folder.id) {
        if (!folders[indexFolder].files) {
          folders[indexFolder].files = [];
        }
        folders[indexFolder].files.push(file);

        return;
      }
      if (_folder.folders) {
        this.addFileInFolder(file, folder, _folder.folders);
      }
    });

    return (this.tree = [...folders]);
  }

  addFolderInFolder(
    folderSource: TreeviewFolderItem,
    folderTarget: TreeviewFolderItem,
    folders: TreeviewFolderItem[] | undefined = undefined,
  ) {
    if (!folders) {
      folders = [...this.tree];
    }

    folders.forEach((_folder: TreeviewFolderItem, indexFolder: number) => {
      if (_folder.id === folderTarget.id) {
        folders[indexFolder].folders.push(folderSource);
        return;
      }
      if (_folder.folders) {
        this.addFolderInFolder(folderSource, folderTarget, _folder.folders);
      }
    });

    return (this.tree = [...folders]);
  }

  setIsBeingCopied(item: any, value: boolean) {
    let file: TreeviewFileItem | undefined = undefined;
    let folder: TreeviewFolderItem | undefined = undefined;
    if (Array.isArray(this.clipboard)) {
      if (
        Object.values(item).some(
          (item: TreeviewFileItem) => item.id !== undefined && item.id !== null,
        )
      ) {
        file = plainToInstance(TreeviewFileItem, item);
      } else {
        folder = plainToInstance(TreeviewFolderItem, item);
      }
      this.isCutting = !value;
    } else {
      if (item.id !== undefined && item.id !== null) {
        file = plainToInstance(TreeviewFileItem, item);
      } else {
        folder = plainToInstance(TreeviewFolderItem, item);
      }
    }

    if (file) {
      this.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'isBeingCopied',
        value,
      );
      this.isCutting = !value;
    } else if (folder) {
      this.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'isBeingCopied',
        value,
      );
      this.isCutting = !value;
    } else {
      this.coreService.showAlertErrorTranslate(
        'databook.error.single.copy.message',
      );
      console.log('error on setIsBeingCopied');
    }
  }

  setIsBeingCutted(item: any | any[], value: boolean) {
    let file: TreeviewFileItem | undefined = undefined;
    let folder: TreeviewFolderItem | undefined = undefined;
    if (Array.isArray(this.clipboard)) {
      if (
        item.some(
          (item: TreeviewFileItem) => item.id !== undefined && item.id !== null,
        )
      ) {
        file = plainToInstance(TreeviewFileItem, item);
      } else {
        folder = plainToInstance(TreeviewFolderItem, item);
      }
    } else {
      if (item.folder_id !== undefined) {
        file = plainToInstance(TreeviewFileItem, item);
      } else {
        folder = plainToInstance(TreeviewFolderItem, item);
      }
    }

    if (file) {
      this.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'isBeingCutted',
        value,
      );
      this.isCutting = value;
    } else if (folder) {
      this.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'isBeingCutted',
        value,
      );
      this.isCutting = value;
    } else {
      this.coreService.showAlertErrorTranslate(
        'databook.error.single.cut.message',
      );
      console.log('error on setIsBeingCutted');
    }
  }

  setIsFocused(item: any) {
    let file: TreeviewFileItem | undefined = undefined;
    let folder: TreeviewFolderItem | undefined = undefined;

    if (item.folder_id !== undefined) {
      file = plainToInstance(TreeviewFileItem, item);
    } else {
      folder = plainToInstance(TreeviewFolderItem, item);
    }

    if (this.focusedItem) {
      let fileFocused: TreeviewFileItem | undefined = undefined;
      let folderFocused: TreeviewFolderItem | undefined = undefined;
      const focusedItemAny = this.focusedItem as any;
      if (focusedItemAny.folder_id !== undefined) {
        fileFocused = plainToInstance(TreeviewFileItem, this.focusedItem);
      } else {
        folderFocused = plainToInstance(TreeviewFolderItem, this.focusedItem);
      }

      if (fileFocused) {
        this.modifyFilePropertyFromTreeRecurcivly(
          undefined,
          fileFocused,
          'isFocus',
          false,
        );
      } else if (folderFocused) {
        this.modifyFolderPropertyFromTreeRecurcivly(
          undefined,
          folderFocused,
          'isFocus',
          false,
        );
      } else {
        console.log('error on setIsFocused folderFocued');
      }
      if (
        (item.folder_id !== undefined &&
          this.focusedItem &&
          this.focusedItem.folder_id !== undefined &&
          this.focusedItem.id === item.id) ||
        (item.folder_id === undefined &&
          this.focusedItem &&
          this.focusedItem.folder_id === undefined &&
          this.focusedItem.id === item.id)
      ) {
        this.focusedItem = null;
        this.setFocusObject(file, folder, false);
      } else {
        this.focusedItem = item;
        this.setFocusObject(file, folder, true);
      }
    } else {
      this.focusedItem = item;
      this.setFocusObject(file, folder, true);
    }
  }

  private setFocusObject(
    file: TreeviewFileItem,
    folder: TreeviewFolderItem,
    val: boolean,
  ) {
    if (file) {
      this.modifyFilePropertyFromTreeRecurcivly(
        undefined,
        file,
        'isFocus',
        val,
      );
    } else if (folder) {
      this.modifyFolderPropertyFromTreeRecurcivly(
        undefined,
        folder,
        'isFocus',
        val,
      );
    } else {
      console.log('error on setIsFocused');
    }
  }

  modifyFilePropertyFromTreeRecurcivly(
    folders: TreeviewFolderItem[] | undefined,
    file: TreeviewFileItem,
    property: string,
    value: any,
  ) {
    if (!folders) {
      folders = [...this.tree];
    }

    folders.forEach((folder: TreeviewFolderItem, indexFolder: number) => {
      if (folder.files) {
        folder.files.forEach(
          (fileInFolder: TreeviewFileItem, indexFile: number) => {
            if (
              fileInFolder.id === file.id &&
              Object.keys(file).includes(property)
            ) {
              folders[indexFolder].files[indexFile][property] = value;
              this.tree = [...folders];
              return;
            }
          },
        );
      }
      if (folder.folders) {
        this.modifyFilePropertyFromTreeRecurcivly(
          folder.folders,
          file,
          property,
          value,
        );
      }
    });
    this.tree = [...folders];
    return;
  }

  modifyFolderPropertyFromTreeRecurcivly(
    tree: TreeviewFolderItem[] | undefined,
    folder: TreeviewFolderItem,
    property: string,
    value: any,
  ) {
    if (!tree) {
      tree = [...this.tree];
    }
    tree.forEach((item: TreeviewFolderItem, index: number) => {
      if (item.id === folder.id && Object.keys(item).includes(property)) {
        tree[index][property] = value;
        this.tree = [...tree];
        return;
      }
      if (item.folders) {
        this.modifyFolderPropertyFromTreeRecurcivly(
          item.folders,
          folder,
          property,
          value,
        );
      }
    });
    this.tree = [...tree];
    return;
  }

  onRefreshButtonClick() {
    // todo implement
  }

  onTreeviewGlobalAutocompleteClick(match: SearchTreeviewItemReturn) {
    console.log(match);
    if (match.type === 'file') {
      let contextFinal: string = match.context;
      if (contextFinal === 'edoc') {
        contextFinal = 'e-doc';
      }
      window.location.href =
        '/' +
        match.projectSlug +
        '/' +
        match.productSlug +
        '/' +
        contextFinal +
        '/' +
        match.id;
    } else if (match.type === 'folder') {
      let contextFinal: string = match.context;
      if (contextFinal === 'edoc') {
        contextFinal = 'e-doc';
      }
      window.location.href =
        '/' +
        match.projectSlug +
        '/' +
        match.productSlug +
        '/' +
        contextFinal +
        '/' +
        'folder' +
        '/' +
        match.id;
    }
  }

  onTreeviewGlobalAutocompleteFeatureClick(feature: ProductsFeaturesList) {
    window.location.href =
      '/' +
      feature.projectSlug +
      '/' +
      feature.productSlug +
      '/' +
      featureSlugMap.get(feature.feature);
  }

  async onTreeviewAutocompleteClick(match: SearchTreeviewItemReturn) {
    // alert(match.id+ '-' +match.type);
    treeviewItemGetPath(this.dataBookService, match.id, match.type)
      .then((parentsIds: number[]) => {
        if (parentsIds) {
          // alert('parentsIds: '+parentsIds.join(', '));
          this.openTargetFileOrFolder(match.type, match.id, parentsIds, true);
          this.searchAutocompleteCandidates = [];
          this.searchText = '';
        } else {
          // alert('error on treeviewItemGetPath: parentsIds is null');
          this.searchAutocompleteCandidates = [];
          this.searchText = '';
        }
      })
      .catch((error: any) => {
        // alert('error on treeviewItemGetPath: ' + error.message);
        this.searchAutocompleteCandidates = [];
        this.searchText = '';
      });
  }

  async onSearchButtonClick() {
    this.treeviewComponent.updateAutocompletePosition();
    if (this.searchText.length >= 2) {
      this.searchAutocompleteCandidates = await searchItem(
        this.dataBookService,
        this.product.id,
        this.searchText,
        this.context,
      );
    } else {
      this.searchAutocompleteCandidates = [];
    }
  }

  async onGlobalSearchButtonClick(projectStore: any, userStore: any) {
    this.basePageComponent.updateAutocompletePosition();

    if (this.searchTextGlobal.length > 2) {
      const result: GlobalSearchResult = await searchItemGlobal(
        this.dataBookService,
        projectStore.projects.map((project: Project) => project.id),
        this.searchTextGlobal,
      );

      this.searchAutocompleteCandidatesGlobal = plainToInstance(
        SearchTreeviewItemReturn,
        result.databookItems,
      );
      const features = plainToInstance(ProductsFeaturesList, result.features);

      /*features = features.filter((feature: ProductsFeaturesList) => {
                      if(feature.feature === 'eDoc') {
                          return userStore.isEDocVisible(feature.projectGsiId, feature.productGsiId);
                      } else if(feature.feature === 'materials') {
                          return userStore.isQpVisible(feature.projectGsiId, feature.productGsiId);
                      } else if(feature.feature === 'tpiDigital') {
                          return userStore.isTpiVisible(feature.projectGsiId, feature.productGsiId);
                      } else if(feature.feature === 'dataAnalysis') {
                          return userStore.isDatabookVisible(feature.projectGsiId, feature.productGsiId);
                      } else if(feature.feature === 'dataExplorer') {
                          return userStore.isDataExplorerVisible(feature.projectGsiId, feature.productGsiId);
                      } else if(feature.feature === 'databook') {
                          return userStore.isDatabookVisible(feature.projectGsiId, feature.productGsiId);
                      }
                      return false;
                  });*/
      this.searchAutocompleteCandidatesGlobalFeatures = features.splice(0, 10);
      console.log(
        'this.searchAutocompleteCandidatesGlobalFeatures',
        this.searchAutocompleteCandidatesGlobalFeatures.length,
      );
    } else {
      this.searchAutocompleteCandidatesGlobal = [];
      this.searchAutocompleteCandidatesGlobalFeatures = [];
    }
  }

  async openTargetFileOrFolder(
    itemType: 'file' | 'folder',
    itemId: number,
    parentsIds: number[],
    changeUrl = false,
  ) {
    let itemFound = false;

    // INIT
    this.tree = [];
    const rootFolders = (await getRootFoldersForFrontEnd(
      this.dataBookService,
      this.projectStore.selectedProduct.id,
      this.context,
    )) as FolderNameAndId[];
    this.tree = rootFolders.map((item: FolderNameAndId) => {
      return plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: true,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: null,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });
    });
    // INIT

    if (parentsIds.length === 0) {
      this.tree.forEach((folder: TreeviewFolderItem) => {
        if (folder.id === itemId) {
          this.setIsFocused(folder);
        }
      });
    } else {
      const recursive = async (folder: TreeviewFolderItem) => {
        if (itemFound) {
          return;
        }

        if (parentsIds.includes(folder.id)) {
          const content: FolderContent = (await getFolderContentForFrontEnd(
            this.dataBookService,
            folder.id,
          )) as FolderContent;

          folder.folders = content.folders.map((item: FolderNameAndId) => {
            const _folder = plainToInstance(TreeviewFolderItem, {
              id: item.id,
              product_id: item.product_id,
              description: item.description,
              name: item.name,
              isRoot: false,
              phase: item.phase,
              fixed: item.fixed,
              parent_id: null,
              treeview_context: item.treeview_context,
              folders: [],
              files: [],
            });
            return _folder;
          });
          folder.files = content.files.map((_file: TreeviewFileItem) => {
            const file = plainToInstance(TreeviewFileItem, _file);
            return file;
          });
          this.modifyFolderPropertyFromTreeRecurcivly(
            undefined,
            folder,
            'isOpen',
            true,
          );

          if (itemType === 'folder') {
            folder.folders.forEach((folder: TreeviewFolderItem) => {
              if (folder.id === itemId) {
                this.setIsFocused(folder);
                itemFound = true;
              }
            });
            if (!itemFound) {
              for await (const _folder of folder.folders) {
                if (parentsIds.includes(_folder.id)) {
                  await recursive(_folder);
                }
              }
            }
          } else {
            folder.files.forEach((file: TreeviewFileItem) => {
              if (file.id === itemId) {
                this.setIsFocused(file);
                itemFound = true;
              }
            });
            if (!itemFound) {
              for await (const _folder of folder.folders) {
                if (parentsIds.includes(_folder.id)) {
                  await recursive(_folder);
                }
              }
            }
          }
        } else {
          // todo don't open folder if it's not in the path
        }
      };
      for await (const folder of this.tree) {
        if (parentsIds.includes(folder.id)) {
          await recursive(folder);
        }
      }
    }

    if (itemFound) {
      const fileDomId = `treeview-${itemType}-item-${itemId}`;
      setTimeout(() => {
        if (this.treeviewComponent) {
          this.treeviewComponent.scrollToElementById(fileDomId, {
            behavior: 'smooth',
            block: 'start',
          });
        }
        if (changeUrl) {
          let url = '';
          let contextFinal: string = this.context;
          if (contextFinal === 'edoc') {
            contextFinal = 'e-doc';
          }
          if (itemType === 'file') {
            url = `${window.location.origin}/${this.projectStore.selectedProject.slug}/${this.projectStore.selectedProduct.slug}/${contextFinal}/${itemId}`;
          } else {
            url = `${window.location.origin}/${this.projectStore.selectedProject.slug}/${this.projectStore.selectedProduct.slug}/${contextFinal}/folder/${itemId}`;
          }
          if (url !== '') {
            window.history.pushState({}, '', url);
          }
        }
      }, 100);
    }
  }

  onAddButtonClick() {
    // TODO:add folder
    console.log(' onAddButtonClick ');
    const newRootFolder = plainToInstance(TreeviewFolderItem, {
      id: null,
      name: '',
      folders: [],
      files: [],
      isBeingCopied: false,
      isBeingCreating: true,
      isFocus: true,
      isRoot: true,
      phase: false,
      fixed: false,
      parent_id: null,
      product_id: this.product.id,
      treeview_context: this.context,
      renamingFieldValue: '',
    });
    console.log('this.context ', this.context);
    console.log(' this.product.id ', this.product.id);
    const newTree = [...this.tree];
    newTree.unshift(newRootFolder);
    this.tree = newTree;
    return newRootFolder;
  }

  async openFolder(
    folder: TreeviewFolderItem | undefined,
    projectStore: any,
    forceTargetFolder?: number,
    forceTargetFile?: number,
  ): Promise<TreeviewFolderItem[]> {
    this.setIsFocused(folder);

    if (folder.isOpen) {
      folder.isOpen = false;
      return;
    }
    const content: FolderContent = (await getFolderContentForFrontEnd(
      this.dataBookService,
      folder.id,
    )) as FolderContent;
    folder.folders = content.folders.map((item: FolderNameAndId) => {
      const _folder = plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: false,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: null,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });

      if (projectStore.folder && _folder.id === projectStore.folder.id) {
        this.targetFolder = _folder;
      } else if (forceTargetFolder && _folder.id === forceTargetFolder) {
        alert('forceTargetFolder: ' + _folder.name);
        this.targetFolder = _folder;
        // this.setIsFocused(_folder);
      }
      return _folder;
    });
    folder.files = content.files.map((_file: TreeviewFileItem) => {
      // targetFile
      const file = plainToInstance(TreeviewFileItem, _file);
      if (projectStore.file && file.id === projectStore.file.id) {
        this.targetFile = file;
      } else if (forceTargetFile && file.id === forceTargetFile) {
        alert('forceTargetFile: ' + file.name);
        this.targetFile = file;
        // this.setIsFocused(file);
      }
      return file;
    });
    this.modifyFolderPropertyFromTreeRecurcivly(
      undefined,
      folder,
      'isOpen',
      true,
    );

    return folder.folders;
  }

  async searchFileWithDebounce(query: string): Promise<void> {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      await this.searchFile(query);
    }, 300); // delay em milissegundos
  }

  async searchFile(query: string): Promise<TreeviewFileItem[]> {
    const content: TreeviewFileItem[] = await getFileBySearchName(
      this.dataBookService,
      query,
      this.product.id,
    );
    console.log(content);
    // I believe it'll be needed to call the function to fill the treeview properly, content must be treated though
    const foundFiles = content.map((_file: any) => {
      return plainToInstance(TreeviewFileItem, {
        name: _file.file_name,
        id: _file.file_id,
        folder_id: _file.folder_id,
        parent_id: _file.parent_id,
      });
    });

    console.log('this is foundFiles', foundFiles);

    function searchTree(node, matchingId) {
      if (node.id == matchingId) {
        return node;
      } else if (node.folders != null) {
        let result = null;
        for (let i = 0; result == null && i < node.folders.length; i++) {
          result = searchTree(node.folders[i], matchingId);
        }
        return result;
      }
      return null;
    }

    for (const fileItem of foundFiles) {
      const node = searchTree(this.tree, fileItem.folder_id);
      if (node != null) {
        await this.updateFileInTree(fileItem);
        console.log('foundFiles', fileItem);
        this.setIsFocused(fileItem);
        this.setIsOpenNestedOnSearch(node, true);
        const nest: TreeviewFolderItem[] = await getNestedTreeView(
          this.dataBookService,
          this.product.id,
          this.context,
          fileItem.folder_id,
        );
        console.log(nest, 'NEEEEEEEEEEEEEEEEEEEEEEEEST');
        nest.forEach((item: TreeviewFolderItem) => {
          this.setIsOpenNested(item, true);
          if (item.parent_id) {
            this.insertFolderInTreeNestedAndUpdateImmutableTree(
              item.parent_id,
              item,
            );
          }
          this.updateViewFilesAndFolders(item, true);
        });
      }
    }
    console.log('saiu');
    this.coreService.hideLoading();
    console.log(this.tree);

    return content;
  }

  async openFolderFilesOnly(
    folder: TreeviewFolderItem,
  ): Promise<TreeviewFileItem[]> {
    const content: FolderContent = (await getFolderContentForFrontEnd(
      this.dataBookService,
      folder.id,
    )) as FolderContent;
    const files = content.files.map((_file: TreeviewFileItem) => {
      return plainToInstance(TreeviewFileItem, _file);
    });
    return files;
  }

  async openFoldersFoldersOnly(
    folder: TreeviewFolderItem,
  ): Promise<TreeviewFolderItem[]> {
    const content: FolderContent = (await getFolderContentForFrontEnd(
      this.dataBookService,
      folder.id,
    )) as FolderContent;
    const folders = content.folders.map((item: FolderNameAndId) => {
      const _folder = plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: false,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: null,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });
      return _folder;
    });
    return folders;
  }

  descriptionOfCopiedFile = (files, file): string => {
    const nameAlreadyExists = files?.find(
      ({ description }) => description === file.description,
    );

    if (!nameAlreadyExists) {
      return file?.description;
    }

    const [name, ext] = file?.description?.split('.');

    const copyNumber = name?.split('(copy)')[1];

    const copiedFile = `${name}-(copy)`;

    const copyAlreadyExists = files?.find(
      ({ description }) => description === `${copiedFile}.${ext}`,
    );

    if (!copyAlreadyExists && !copyNumber?.length) {
      return `${copiedFile}.${ext}`;
    }

    let number = 0;

    let finalName = '';

    while (!finalName) {
      number++;

      const nameAlreadyExists = files?.find(
        ({ description }) => description === `${copiedFile}-${number}.${ext}`,
      );

      if (!nameAlreadyExists) {
        finalName = `${copiedFile}-${number++}.${ext}`;
      }
    }

    return finalName;
  };

  // TODO: refactor iniate by lotfi
  @action
  async deleteFolderFromStore(folder: TreeviewFolderItem) {
    return await removeFolder(this.dataBookService, folder.id);
  }

  @action
  async deleteFileFromStore(file: TreeviewFileItem) {
    return await removeFile(this.dataBookService, file.id);
  }
}

export const TREEVIEW_STORE = new InjectionToken<TreeviewStore>(
  'TreeviewStore',
);
