import { AllProducts } from './product.types';

export const allProducts: AllProducts[] = [
  { id: 'host', name: 'Host pipe', description: '', order: 1, active: true },
  { id: 'C_STEEL', name: 'C Steel', description: '', order: 1, active: true },
  { id: 'liner', name: 'Liner', description: '', order: 2, active: true },
  { id: 'MLP', name: 'MLP', description: '', order: 3, active: true },
  { id: 'COATING', name: 'Coating', description: '', order: 4, active: true },
  {
    id: 'THERMAL_INSULATING_COATING',
    name: 'Thermal Insulating Coating',
    description: '',
    order: 4,
    active: true,
  },
  {
    id: 'ANTI_CORROSIVE_COATING',
    name: 'Anti Corrosive Coating',
    description: '',
    order: 5,
    active: true,
  },
  {
    id: 'DOUBLE_JOINT',
    name: 'Double Joint',
    description: '',
    order: 6,
    active: true,
  },
  { id: 'WELDING', name: 'Welding', description: '', order: 7, active: true },
];
