import { LabelValue } from '@interfaces/label-value';

function arrayToLabelValues<T>(
  array: T[],
  labelKeyPrefix: string,
): LabelValue<T>[] {
  return array.map((value) => ({
    value,
    labelKey: `${labelKeyPrefix}.${value}`,
  }));
}

export function objToLabelValues<T>(
  obj: Record<string, T>,
  labelKeyPrefix: string,
): LabelValue<T>[] {
  return arrayToLabelValues(Object.values(obj), labelKeyPrefix);
}
