import { Component, Injectable, OnDestroy } from '@angular/core';
import { PlpBaseComponent } from '../plp-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterColumnRangeComponent } from '../../../components/filter-column-range/filter-column-range.component';
import { FilterColumnComponent } from '../../../components/filter-column/filter-column.component';
import { FilterComponent } from '../../../components/filter/filter.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb.component';
import { NgIf } from '@angular/common';
import {
  ClrIconModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
} from '@clr/angular';
import { BasePageComponent } from '../../../core/components/page/base-page.component';

@Component({
  templateUrl: 'plp-hardness.component.html',
  styleUrls: ['plp-hardness.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    ClrIconModule,
    NgIf,
    BreadcrumbComponent,
    FilterComponent,
    FilterColumnComponent,
    FilterColumnRangeComponent,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    TranslateModule,
  ],
})
@Injectable()
export class PlpHardnessComponent extends PlpBaseComponent {
  public async getColumnNames(language) {
    const params = { test: 'hardness', lang: language };
    this.cfgVlr(params);
    return await this.dataBookService.getTests(params);
  }

  public getPageSize() {
    return '500-1137';
  }

  public getLineSize() {
    return 1000;
  }

  getTest() {
    return 'hardness';
  }

  configCurrentTestBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'dataExplorer.test.hardness',
      null,
    );
  }

  getCondition() {
    return (
      !this.store.selectedProject.gsi_id ||
      !this.store.selectedProduct.gsi_id ||
      !this.store.materialId
    );
  }

  isHardnessVisible() {
    if (super.getRoleByProject().DATA_EXPLORER) {
      return true;
    }
    return false;
  }
}
