import { zeroDigitsNumber } from './column-utils';
import { COLUMNS } from '../data-explorer-column-order';

export default {
  headerName: 'PRODUCT IMPACT TRANSVERSE',
  headerClass: 'custom-header-4',
  children: [
    {
      headerName: '',
      headerClass: 'custom-header-4',
      children: [
        {
          field: 'Transverse HTL',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_htl']],
        },
        {
          field: 'Sampling Side',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_sampling_side']],
        },
        {
          field: 'Shear Area 1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_shear_area_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_shear_area_1']],
        },
        {
          field: 'Shear Area 2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_shear_area_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_shear_area_2']],
        },
        {
          field: 'Shear Area 3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_shear_area_3']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_shear_area_3']],
        },
        {
          field: 'Shear Area Average',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_average']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_average']],
        },
        {
          field: 'AE1',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_ae_1']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_ae_1']],
        },
        {
          field: 'AE2',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_ae_2']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_ae_2']],
        },
        {
          field: 'AE3',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_ae_3']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_ae_3']],
        },
        {
          field: 'Average AE',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          digits: 0,
          symbol: '',
          valueFormatter: (params) =>
            zeroDigitsNumber(
              '',
              params.data[COLUMNS['impact_transverse_average_ae']],
            ),
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_average_ae']],
        },
      ],
    },
    {
      headerName: '',
      headerClass: 'custom-header-4',
      columnGroupShow: 'open',
      children: [
        {
          field: 'File',
          headerClass: 'custom-header-4',
          sortable: true,
          cellClass: 'customCellDataExplorer',
          valueGetter: (params: any) =>
            params.data[COLUMNS['impact_transverse_file_name']],
        },
      ],
    },
  ],
};
