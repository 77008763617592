import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx-angular';
import { DataBookService } from '../services/databook.service';
import {
  addFileIntoFolder,
  addFolder,
  addRootFolder,
  FolderContent,
  FolderNameAndId,
  getFolderContentForFrontEnd,
  renameFile,
  renameFolder,
} from './treeview-helpers';
import { plainToInstance } from 'class-transformer';
import { TreeviewStore } from '../mobx-stores';
import { Product } from './product.class';

export enum TreeviewItemContext {
  databook = 'databook',
  edoc = 'edoc',
}

@Injectable({
  providedIn: 'root',
})
export class TreeviewFolderItem {
  get isFolder(): boolean {
    return true;
  }
  get domId(): string {
    return `treeview-folder-item-${this.id}`;
  }

  id: number;
  @observable isBeingCreating = false;
  @observable isBeingDeleted = false;
  product_id: number;
  description?: string;
  name?: string;
  oldDynamoId: string;
  phase?: boolean;
  fixed?: boolean;
  parent_id: number | null;
  folder_id: number | undefined;
  treeview_context: TreeviewItemContext;

  @observable folders: TreeviewFolderItem[] = [];
  @computed get foldersSorted(): TreeviewFolderItem[] {
    return this.folders.sort((a: TreeviewFolderItem, b: TreeviewFolderItem) =>
      a.name.localeCompare(b.name),
    );
  }

  @observable files: TreeviewFileItem[] = [];
  @computed get filesSorted(): TreeviewFileItem[] {
    return this.files.sort((a: TreeviewFileItem, b: TreeviewFileItem) =>
      a.name.localeCompare(b.name),
    );
  }

  @computed get filesAndFoldersSorted(): (
    | TreeviewFileItem
    | TreeviewFolderItem
  )[] {
    return [...this.folders, ...this.files].sort(
      (
        a: TreeviewFileItem | TreeviewFolderItem,
        b: TreeviewFileItem | TreeviewFolderItem,
      ) => a.name.localeCompare(b.name),
    );
  }

  @observable isRoot = false;

  @observable isOpen = false;

  @observable renaming = false;
  @observable renamingFieldValue = '';

  @observable selected = false;

  @observable isBeingCopied = false;
  @observable isBeingCutted = false;

  @observable isFocus = false;

  fileIconUri = '/assets/img/png/file.png';
  pdfIconUri = '/assets/img/png/pdf-file.png';
  closedFolder = '/assets/img/png/closed-folder.png';
  openedFolder = '/assets/img/png/opened-folder.png';

  @observable get icon(): string {
    if (this.isOpen) {
      return this.openedFolder;
    } else {
      return this.closedFolder;
    }
  }

  @action
  folderRenameHandler($event) {
    this.renamingFieldValue = $event.target.value;
  }

  sortByName(arr) {
    return arr.sort(function (a, b) {
      const descA = a.name.toUpperCase();
      const descB = b.name.toUpperCase();
      if (descA < descB) {
        return -1; // a before b
      }
      if (descA > descB) {
        return 1; // b before a
      }
      return 0; // same order
    });
  }
  insertFolderInTreeNestedAndUpdateImmutableTree(
    treeviewStore: any,
    parentId: number | null,
    folder: TreeviewFolderItem,
  ) {
    const newTree: TreeviewFolderItem[] = [...treeviewStore.tree];
    if (folder.parent_id === null) {
      treeviewStore.tree = [folder, ...newTree];
      treeviewStore.tree = this.sortByName(treeviewStore.tree);
      return;
    }
    const findParentFolderAndInsert = (folders: TreeviewFolderItem[]): any => {
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].id === parentId) {
          folders[i].folders = [folder, ...folders[i].folders];
          folders[i].folders = this.sortByName(folders[i].folders);
          return folders;
        }

        if (folders[i].folders.length > 0) {
          const inserted = findParentFolderAndInsert(folders[i].folders);
          if (inserted) {
            return folders;
          }
        }
      }
      return false;
    };

    treeviewStore.tree = findParentFolderAndInsert(newTree);
  }

  @action
  async confirmCreating(dataBookService: any, treeviewStore: any) {
    const parentFolderId = this.parent_id;
    let createdFolder: TreeviewFolderItem | undefined = await addFolder(
      dataBookService,
      this.renamingFieldValue,
      '',
      treeviewStore.product.id,
      parentFolderId,
      treeviewStore.context,
      false,
      false,
    );
    createdFolder = plainToInstance(TreeviewFolderItem, createdFolder);
    if (createdFolder.parent_id === null) {
      createdFolder.isRoot = true;
    }
    treeviewStore.removeCreatingFolderFolderFromTree();
    if (createdFolder) {
      this.insertFolderInTreeNestedAndUpdateImmutableTree(
        treeviewStore,
        parentFolderId,
        createdFolder,
      );
    } else {
      alert('Error while creating folder');
    }
  }

  @action
  async confirmEditing(dataBookService: any) {
    this.renaming = false;
    await renameFolder(dataBookService, this.id, this.renamingFieldValue);
    this.renamingFieldValue = '';
  }

  @action
  cancelEditing() {
    this.renaming = false;
    this.renamingFieldValue = '';
  }

  @computed
  getClassNames() {
    return {
      'treeview-folder': true,
      'treeview-folder-root': this.isRoot === true,
    };
  }

  @computed
  getVisible() {
    return {
      display: this.isOpen ? 'block' : 'none',
    };
  }

  @action
  async openClose(event: MouseEvent, dataBookService: any) {
    console.log(event.ctrlKey);
    console.log(event);

    if (event.ctrlKey) {
      alert('CTRL + Click');
    }

    if (this.isOpen) {
      this.isOpen = false;
      return;
    }
    const content: FolderContent = (await getFolderContentForFrontEnd(
      dataBookService,
      this.id,
    )) as FolderContent;
    this.folders = content.folders.map((item: FolderNameAndId) => {
      return plainToInstance(TreeviewFolderItem, {
        id: item.id,
        product_id: item.product_id,
        description: item.description,
        name: item.name,
        isRoot: false,
        phase: item.phase,
        fixed: item.fixed,
        parent_id: null,
        treeview_context: item.treeview_context,
        folders: [],
        files: [],
      });
    });
    this.files = content.files.map((file: TreeviewFileItem) => {
      return plainToInstance(TreeviewFileItem, file);
    });
    this.isOpen = true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TreeviewFileItem {
  get isFolder(): boolean {
    return false;
  }
  get domId(): string {
    return `treeview-file-item-${this.id}`;
  }
  id: number;
  folder_id: number | null;
  size?: number;
  document_code?: string;
  datetime: number | null = null;
  revision?: number;
  s3key: string;
  description?: string;
  name?: string;
  oldDynamoId: string;
  filetype?: string;
  position?: number;
  path_when_removed?: string;
  '';
  remover_email?: string;
  remover_name?: string;
  removed?: number;
  folder?: TreeviewFolderItem;
  folder_path?: number[];

  content?: any;

  complete_path?: string;

  @observable isBeingCreating = false;
  @observable isBeingDeleted = false;

  @observable isBeingCopied = false;
  @observable isBeingCutted = false;
  @observable isFocus = false;
  @observable renaming = false;
  @observable renamingFieldValue = '';

  @computed
  get icon(): string {
    if (this.filetype && this.filetype.match(new RegExp('pdf', 'i'))) {
      return this.pdfIconUri;
    } else if (this.filetype && this.name.match(new RegExp('pdf', 'i'))) {
      return this.pdfIconUri;
    } else {
      return this.fileIconUri;
    }
  }

  @action
  async confirmCreating(
    dataBookService: any,
    createFile = false,
    treeviewStore: any,
  ) {
    if (createFile) {
      const createdFolder: TreeviewFolderItem | undefined = await addRootFolder(
        dataBookService,
        treeviewStore.product.id,
        treeviewStore.context,
        this.renamingFieldValue,
      );
      if (createdFolder) {
        treeviewStore.tree = treeviewStore.tree.filter(
          (folder: TreeviewFolderItem) => {
            return folder.id !== null && folder.id !== undefined;
          },
        );
        treeviewStore.tree = [createdFolder, ...treeviewStore.tree];
      }
    } else {
      await renameFile(dataBookService, this.id, this.renamingFieldValue);
      this.renaming = false;
      this.renamingFieldValue = '';
    }
  }

  @action
  async confirmEditing(dataBookService: any) {
    // const createdFolder: TreeviewFolderItem | undefined = await addFileIntoFolder(dataBookService, treeviewStore.product.id, treeviewStore.context, this.renamingFieldValue);
    // if (createdFolder) {
    //     treeviewStore.tree = treeviewStore.tree.filter((folder: TreeviewFolderItem) => {
    //         return folder.id !== null && folder.id !== undefined;
    //     });
    //     treeviewStore.tree = [createdFolder, ...treeviewStore.tree];
    // }
    this.renaming = false;
    await renameFile(dataBookService, this.id, this.renamingFieldValue);
    this.renamingFieldValue = '';
  }

  @action
  cancelEditing() {
    this.renaming = false;
    this.renamingFieldValue = '';
  }

  @action
  folderRenameHandler($event) {
    this.renamingFieldValue = $event.target.value;
  }

  fileIconUri = '/assets/img/png/file.png';
  pdfIconUri = '/assets/img/png/pdf-file.png';
  closedFolder = '/assets/img/png/closed-folder.png';
  openedFolder = '/assets/img/png/opened-folder.png';
}
