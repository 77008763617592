import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '@components/pdf/pdf.service';
import { CoreService } from '@core/core.service';
import { PROJECT_STORE, ProjectsStore } from '@mobx-stores/projects.store';
import { TREEVIEW_STORE, TreeviewStore } from '@mobx-stores/treeview.store';
import { USER_STORE, UserStore } from '@mobx-stores/user.store';
import { TranslateService } from '@ngx-translate/core';
import { DataBookService } from '@services/databook.service';
import { DataBookState } from '@services/databook.state';
import {
  ColDef,
  ColumnApi,
  ColumnEverythingChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  SideBarDef,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { plainToInstance } from 'class-transformer';
import { TreeviewFileItem } from 'src/app/classes';
import { getLatestRevisionFilesWithPath } from 'src/app/classes/treeview-helpers/get-latest-revision-files-with-path';
import { LICENSE_KEY } from '../../data-explorer/tools/constants';
import { DataBookBaseComponent } from '../../databook-base.component';
import { createContextMenuItems } from './tools/createContextMenuItems';
import { SIDE_BAR } from './constants/sideBar';
import { DATABOOK_FILES_STATUS_BAR } from './constants/statusBar';
import {
  DEFAULT_COL_DEF,
  DATABOOK_FILES_COLUMN_DEFS,
} from './constants/columnDefs';
import { Lambda, observe } from 'mobx';
import { AgGridModule } from 'ag-grid-angular';

@Component({
  selector: 'gridview',
  templateUrl: './gridview.component.html',
  styleUrls: ['./gridview.component.scss'],
  standalone: true,
  imports: [AgGridModule],
})
export class GridviewComponent
  extends DataBookBaseComponent
  implements OnDestroy, OnInit
{
  private gridApi!: GridApi<DatabookFile>;
  public columnDefs = DATABOOK_FILES_COLUMN_DEFS;
  public defaultColDef: ColDef = DEFAULT_COL_DEF;
  public statusBar = DATABOOK_FILES_STATUS_BAR;
  public rowData!: DatabookFile[];
  public sideBar: SideBarDef = SIDE_BAR;
  public context: {
    translate: TranslateService;
    treeviewStore: TreeviewStore;
  };
  private disposeUpdateSelectedNodes: Lambda;
  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public httpClient: HttpClient,
    public pdfService: PdfService,
    private translate: TranslateService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
    LicenseManager.setLicenseKey(LICENSE_KEY);
    this.context = {
      translate: this.translate,
      treeviewStore: this.treeviewStore,
    };
    this.disposeUpdateSelectedNodes = observe(this.treeviewStore, (change) => {
      if (change.name === 'selectedObjects') {
        this.updateSelectedNodes();
      }
    });
  }
  // @ts-ignore
  ngOnDestroy(): Promise<void> {
    this.disposeUpdateSelectedNodes();
  }

  async onGridReady(params: GridReadyEvent<DatabookFile>) {
    this.gridApi = params.api;
    this.rowData = await getLatestRevisionFilesWithPath(
      this.dataBookService,
      this.store.selectedProduct.id,
      this.store.selectedProject.id,
    );
    try {
      loadColumnState(params.columnApi);
    } catch (error) {
      console.error('Could not load the ag-grid columns state.');
    }
  }

  onSelectionChanged() {
    this.treeviewStore.setSelectedObjects(
      this.gridApi
        .getSelectedRows()
        .map((selectedRow) => plainToInstance(TreeviewFileItem, selectedRow)),
    );
  }

  getContextMenuItems(params: GetContextMenuItemsParams) {
    params.context.treeviewStore.setObjectPointedOnContextMenu(
      plainToInstance(TreeviewFileItem, params.node.data),
    );
    return createContextMenuItems(params.context.treeviewStore);
  }

  updateSelectedNodes() {
    const selectedNodes = [];
    this.gridApi.forEachNode((node) => {
      const isNodeSelected = this.treeviewStore.selectedObjects.some(
        (selected) => selected.id === node.data.id,
      );
      if (isNodeSelected) {
        selectedNodes.push(node);
      }
    });

    const excludedNodes = this.gridApi
      .getSelectedNodes()
      .filter((node) => !selectedNodes.includes(node));

    this.gridApi.setNodesSelected({
      nodes: excludedNodes,
      newValue: false,
    });
    this.gridApi.setNodesSelected({
      nodes: selectedNodes,
      newValue: true,
    });
  }

  gridColumnsChanged(params: ColumnEverythingChangedEvent) {
    if (
      !['uiColumnMoved', 'uiColumnDragged', 'toolPanelUi'].includes(
        params.source,
      )
    ) {
      console.log(params.source);
      return;
    }

    saveColumnState(params.columnApi);
  }

  getLocaleText(params) {
    return params.context.translate.instant(`grid.${params.key}`);
  }
}

const COLUMN_STATE_KEY = 'databook.columnstate';

function loadColumnState(columnApi: ColumnApi) {
  const columnState = JSON.parse(localStorage.getItem(COLUMN_STATE_KEY));
  if (columnState) {
    columnApi.applyColumnState({
      state: columnState,
      applyOrder: true,
    });
  }
}

function saveColumnState(columnApi: ColumnApi) {
  localStorage.setItem(
    COLUMN_STATE_KEY,
    JSON.stringify(columnApi.getColumnState()),
  );
}
