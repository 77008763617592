import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { ClrModalModule } from '@clr/angular';

@Component({
  selector: 'open-mail-modal',
  templateUrl: './open-mail-modal.component.html',
  styleUrls: ['./open-mail-modal.component.scss'],
  standalone: true,
  imports: [ClrModalModule, NgIf, TranslateModule],
})
export class OpenMailModalComponent {
  @Input() isModalOpen = false;
  @Input() closeModal: () => void;
  @Input() getEmailMessage: () => string;
  @Input() confirmEmail: () => void;
  @Input() confirmEditEmail: () => void;

  @Input() type: {
    create: boolean;
    update: boolean;
  };

  cancelAddEmail = () => {
    this.closeModal();
  };
}
