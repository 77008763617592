import {
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../treeview-item.class';
import { DataBookService } from '../../services/databook.service';

export const addFolder = async (
  dataBookService: DataBookService,
  name: string,
  description: string,
  productId: number,
  parentId: number | null,
  context: TreeviewItemContext,
  phase?: boolean,
  fixed?: boolean,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'addFolderIntoFolderForFrontEnd',
    args: {
      productId,
      context,
      parentId,
      name,
      phase,
      fixed,
      description,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};
