import { Component, Input, OnInit } from '@angular/core';
import { setActionStatus } from '../../new-tpi.digital.constants';
import { DataBookBaseComponent } from '../../../databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  ClrModalModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
} from '@clr/angular';

@Component({
  selector: 'duplicated-flows-modal',
  templateUrl: './duplicated-flows-modal.component.html',
  styleUrls: ['./duplicated-flows-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    TranslateModule,
  ],
})
export class DuplicatedFlowsModalComponent
  extends DataBookBaseComponent
  implements OnInit
{
  @Input() modalIsOpen;
  @Input() closeModal;
  @Input() files;
  @Input() upload;

  setStatus = (action: string) => setActionStatus[action];

  formatDate = (date: string) => this.coreService.formatDate(date);
}
