import { Component, Injectable, OnDestroy } from '@angular/core';
import {
  ClrDatagridSortOrder,
  ClrDatagridComparatorInterface,
  ClrIconModule,
  ClrCheckboxModule,
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrModalModule,
  ClrSelectModule,
} from '@clr/angular';
import { PlpBaseComponent } from '../plp-base.component';
import * as XLSX from 'xlsx';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FilterColumnRangeComponent } from '../../../components/filter-column-range/filter-column-range.component';
import { FilterColumnComponent } from '../../../components/filter-column/filter-column.component';
import { FilterComponent } from '../../../components/filter/filter.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb.component';
import { NgIf } from '@angular/common';
import { BasePageComponent } from '../../../core/components/page/base-page.component';

class Comparator implements ClrDatagridComparatorInterface<any> {
  compare(a: any, b: any) {
    return a - b;
  }
}

@Component({
  templateUrl: 'plp-all.component.html',
  styleUrls: ['plp-all.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    NgIf,
    ClrIconModule,
    BreadcrumbComponent,
    FilterComponent,
    FilterColumnComponent,
    FilterColumnRangeComponent,
    ClrCheckboxModule,
    FormsModule,
    ClrCommonFormsModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrModalModule,
    ClrSelectModule,
    TranslateModule,
  ],
})
@Injectable()
export class PlpAllComponent extends PlpBaseComponent {
  sheetFilter: any = 'none';
  sheetFormat: any = '2';
  dimensionsChecked = true;
  chemicalChecked = true;
  sscChecked = true;
  hicChecked = true;
  hardnessChecked = true;
  impactChecked = true;
  tensileChecked = true;
  ctodChecked = true;
  descSort: any;

  descSortTable() {
    return (this.descSort = ClrDatagridSortOrder.DESC);
  }
  public async getColumnNames(language) {
    const params = { test: this.getTest(), lang: language };
    this.cfgVlr(params);
    const columns = await this.dataBookService.getTests(params);
    return columns;
  }

  public compare = new Comparator();

  async filterSheet() {
    this.coreService.showLoading();
    console.log(this.sheetFormat);
    const columns = [];
    switch (this.sheetFormat) {
      case '1': // Pipe Movement Track
        break;
      case '2': // Manufacturer Pipe TS
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) >= 234 && parseInt(i) <= 277)) {
            this.columnsToggle[i].checked = false;
          } else {
            this.columnsToggle[i].checked = true;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      case '3': // Pipe Chemical Data
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) >= 278 && parseInt(i) <= 304)) {
            this.columnsToggle[i].checked = false;
          } else {
            this.columnsToggle[i].checked = true;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      case '4': // Liner Chemical Data
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) >= 278 && parseInt(i) <= 304)) {
            this.columnsToggle[i].checked = false;
          } else {
            this.columnsToggle[i].checked = true;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      case '5': // Pipe Impact Data
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) > 175 && parseInt(i) < 193)) {
            this.columnsToggle[i].checked = false;
          } else {
            this.columnsToggle[i].checked = true;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      case '6': // Pipe Toughness Data
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) > 305 && parseInt(i) < 322)) {
            this.columnsToggle[i].checked = false;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      case '7': // Pipe Corrosion Data
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) > 194 && parseInt(i) < 210)) {
            this.columnsToggle[i].checked = false;
          } else {
            this.columnsToggle[i].checked = true;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      case '8': // Pipe Tensile Hardness Data
        for (const i in this.columnsToggle) {
          if (!(parseInt(i) > 160 && parseInt(i) < 174)) {
            this.columnsToggle[i].checked = false;
          } else {
            this.columnsToggle[i].checked = true;
          }
        }
        this.coreService.hideLoading();
        this.sheetFilter = 'none';
        break;
      // case "9": // Pipe BendTest Data
      //     for(var i in this.columnsToggle){
      //         if(!(parseInt(i) > 234 && parseInt(i) < 277)){
      //             this.columnsToggle[i].checked = false;
      //         }
      //     }
      //     this.coreService.hideLoading();
      //     break;
      // case "10": // Pipe IntCoating Data
      //     for(var i in this.columnsToggle){
      //         if(!(parseInt(i) > 234 && parseInt(i) < 277)){
      //             this.columnsToggle[i].checked = false;
      //         }
      //     }
      //     this.coreService.hideLoading();
      //     break;
      // case "11": // Pipe Coating Data
      //     for(var i in this.columnsToggle){
      //         if(!(parseInt(i) > 234 && parseInt(i) < 277)){
      //             this.columnsToggle[i].checked = false;
      //         }
      //     }
      //     this.coreService.hideLoading();
      //     break;
      default:
        this.sheetFilter = 'none';
        this.coreService.hideLoading();
        break;
    }
  }

  public getPageSize() {
    return '1700-1137';
  }

  public getLineSize() {
    return 1650;
  }

  getTest() {
    // TEMPORARIO REMOVER
    if (this.store.selectedProject.gsi_id === 'SEPIA') {
      if (this.store.selectedProduct.gsi_id === 'liner') {
        if (this.store.material.material_type === 'Coil') {
          return 'all_coil';
        } else if (this.store.material.material_type === 'Liner') {
          return 'all_liner';
        }
      } else if (
        this.store.selectedProduct.gsi_id === 'ANTI_CORROSIVE_COATING'
      ) {
        return 'all_anti_corrosive_coating';
      }
    }

    return 'all';
  }

  configCurrentTestBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'dataExplorer.test.all',
      null,
    );
  }

  getCondition() {
    return (
      !this.store.selectedProject.gsi_id ||
      !this.store.selectedProduct.gsi_id ||
      !this.store.materialId
    );
  }

  isChemicalVisible() {
    if (super.getRoleByProject().DATA_EXPLORER) {
      return true;
    }
    return false;
  }

  configExport() {
    this.sheetFilter = 'action';
  }
}
