import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DataBookState } from '../../services/databook.state';
import { DataBookService } from '../../services/databook.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../pdf/pdf.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClrCommonFormsModule } from '@clr/angular';

@Component({
  selector: 'databook-filter-column-range',
  templateUrl: './filter-column-range.component.html',
  styleUrls: ['filter-column-range.component.scss'],
  standalone: true,
  imports: [ClrCommonFormsModule, FormsModule, NgFor, NgIf, TranslateModule],
})
export class FilterColumnRangeComponent {
  @Input() columns = [];

  @Input() showPipe = true;

  selectedColumn: any;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChangeFilter = new EventEmitter<any>();

  heatFilter;
  pipeFilter;

  constructor(public dataBookState: DataBookState) {}

  onChange() {
    this.onChangeFilter.emit({
      selectedColumn: this.selectedColumn,
      heatFilter: this.heatFilter,
      pipeFilter: this.pipeFilter,
    });
  }

  onChangeMinSlider() {
    const min = this.selectedColumn.min;
    const max = this.selectedColumn.maxSelected;

    this.selectedColumn.minSelected = Math.min(
      Math.max(this.selectedColumn.minSelected, min),
      max,
    );
    this.onChange();
  }

  onChangeMaxSlider() {
    const min = this.selectedColumn.minSelected;
    const max = this.selectedColumn.max;

    this.selectedColumn.maxSelected = Math.min(
      Math.max(this.selectedColumn.maxSelected, min),
      max,
    );
    this.onChange();
  }
}
