import { Component, Inject, OnInit } from '@angular/core';
import { CoreService } from '../../core/core.service';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import {
  hasUndefinedLink,
  removeUndefinedLink,
  selectBreadcrumb,
  updateHasArrow,
  isADefinedBreadcrumb,
} from '../utils';
import { breadcrumbPagesEnum } from '../../enums/breadcrumbPages.enum';
import { ClrCommonFormsModule } from '@clr/angular';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'databook-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ClrCommonFormsModule],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb: {
    name: string;
    link?: string;
    type: string;
    hasArrow?: boolean;
  }[] = [];
  breadcrumbCurrent = '';

  constructor(
    // eslint-disable-next-line no-unused-vars
    public coreService: CoreService,
    // eslint-disable-next-line no-unused-vars
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    // eslint-disable-next-line no-unused-vars
    @Inject(USER_STORE) public userStore: UserStore,
  ) {}

  redirect(link) {
    this.coreService.getRouter().navigate([link]);
  }

  ngOnInit() {
    this.breadcrumbCurrent = this.store.activePageForBreadcrumb;
    //Checks if is on home(project) page
    if (this.breadcrumbCurrent === 'Home') {
      this.breadcrumb.push({
        name: this.coreService.translate('databook.home'),
        link: `/`,
        type: '',
        hasArrow: false,
      });
      this.breadcrumb.splice(1, this.breadcrumb.length - 1);
      this.store.selectedProject = null;
    }

    //Checks if is on selected project page
    if (this.store.selectedProject) {
      this.breadcrumb.push(
        {
          name: this.coreService.translate('databook.home'),
          link: `/`,
          type: '',
          hasArrow: true,
        },
        {
          name: this.store.selectedProject.name,
          link: `/${this.store.selectedProject.slug}`,
          type: this.coreService.translate('databook.project', null),
          hasArrow: true,
        },
      );
      //checks if is on selected product page, adds product to breadcrumb home>project>product
      if (this.store.selectedProduct) {
        this.breadcrumb.push({
          name: this.store.selectedProduct.name,
          link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}`,
          type: this.coreService.translate('databook.product', null),
          hasArrow: this.breadcrumbCurrent === breadcrumbPagesEnum.product,
        });
        if (
          this.breadcrumbCurrent.toUpperCase() === breadcrumbPagesEnum.product
        ) {
          this.store.materialId = null;
          this.store.dataExplorerType = null;
          this.breadcrumb.splice(3, this.breadcrumb.length - 3);
        }
        //checks if selected one dataexplorer
        if (this.store.dataExplorerType) {
          this.breadcrumb.push({
            name: this.coreService.translate(
              selectBreadcrumb(this.store.dataExplorerType),
              null,
            ),
            link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.dataExplorerType}`,
            type: '',
            hasArrow: true,
          });
          this.breadcrumb.splice(3, this.breadcrumb.length - 4);
          if (
            this.breadcrumbCurrent.toUpperCase() ===
              breadcrumbPagesEnum.dataExplorer ||
            this.breadcrumbCurrent.toUpperCase() ===
              breadcrumbPagesEnum.dataAnalysis
          ) {
            this.store.materialId = null;
            this.breadcrumb.splice(4, this.breadcrumb.length - 4);
          }
          //checks if a material was selected if so, adds it to breadcrumb home>project>product>datanalysis>material
          if (this.store.materialId) {
            this.breadcrumb.push({
              name: this.store.material.description,
              link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.dataExplorerType}/${this.store.materialId}`,
              type: 'Material',
              hasArrow: false,
            });
            //checks if is on statistics page if so, adds breadcrumb home>project>product>datanalysis>material>statistics
            if (
              this.store.activePageForBreadcrumb.toUpperCase() ===
              breadcrumbPagesEnum.statistics
            ) {
              this.breadcrumb.push({
                name: this.coreService.translate(
                  'databook.data.analysis.statistics',
                  null,
                ),
                link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.dataExplorerType}/${this.store.materialId}/${this.store.activePageForBreadcrumb}`,
                type: '',
                hasArrow: false,
              });
              //checks if is on boxplot page if so, adds breadcrumb home>project>product>datanalysis>material>boxplot
            }
            if (
              this.store.activePageForBreadcrumb.toUpperCase() ===
              breadcrumbPagesEnum.boxplot
            ) {
              this.breadcrumb.push({
                name: this.coreService.translate(
                  'databook.data.analysis.boxplot',
                  null,
                ),
                link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.dataExplorerType}/${this.store.materialId}/${this.store.activePageForBreadcrumb}`,
                type: '',
                hasArrow: false,
              });
            }
          }
        }
      }
    }

    if (
      this.store.activePageForBreadcrumb.toUpperCase() ===
      breadcrumbPagesEnum.newTpiDigital
    ) {
      this.breadcrumb.push({
        name: this.coreService.translate('dashboard.tpi.digital'),
        link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.activePageForBreadcrumb}`,
        type: '',
      });
    }
    if (
      this.store.newTpiSelectedType?.toUpperCase() ===
        breadcrumbPagesEnum.newTpiDigital &&
      this.store.activePageForBreadcrumb.toUpperCase() ===
        breadcrumbPagesEnum.newTpiDigitalUpload
    ) {
      this.breadcrumb.push(
        {
          name: this.coreService.translate('dashboard.tpi.digital'),
          link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.newTpiSelectedType}`,
          type: '',
        },
        {
          name: this.coreService.translate('dashboard.tpi.digital.upload'),
          link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.activePageForBreadcrumb}`,
          type: '',
        },
      );
    }

    if (
      this.store.activePageForBreadcrumb.toUpperCase() ===
      breadcrumbPagesEnum.eDoc
    ) {
      this.breadcrumb.push({
        name: this.coreService.translate('dashboard.e.doc'),
        link: `/${this.store.selectedProject.slug}/${this.store.selectedProduct.slug}/${this.store.activePageForBreadcrumb}`,
        type: '',
      });
    }

    if (
      !isADefinedBreadcrumb(this.store.activePageForBreadcrumb.toUpperCase())
    ) {
      this.breadcrumb.push({
        name: this.store.activePageForBreadcrumb,
        link: `/${this.store.selectedProject?.slug}/${this.store.selectedProduct?.slug}/${this.store.activePageForBreadcrumb}`,
        type: '',
      });
    } else if (hasUndefinedLink(this.breadcrumb)) {
      removeUndefinedLink(this.breadcrumb);
    }

    updateHasArrow(this.breadcrumb);
  }
}
