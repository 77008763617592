import { Material } from '../material.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const insertMaterial = async (
  dataBookService: DataBookService,
  productId: number,
  description: string,
  service = false,
  od: number,
  wt: number,
  grade: string,
  quantity: number,
  comment: string,
  approved = false,
  end_finishing?: string,
  unit?: string,
  revision?: string,
  material_type?: string,
  fert?: string,
  material_order?: string,
  ordered_item?: string,
  data_sheet?: string,
  last_date_update?: string,
  last_user_who_update?: string,
  customer_order_item?: string,
  customer_order?: string,
): Promise<Material | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'insertMaterial',
    args: {
      productId,
      description,
      service,
      od,
      wt,
      grade,
      quantity,
      comment,
      approved,
      end_finishing,
      unit,
      revision,
      material_type,
      fert,
      material_order,
      ordered_item,
      data_sheet,
      last_date_update,
      last_user_who_update,
      customer_order_item,
      customer_order,
    },
  };
  return await dataBookService.newLambda<Material | undefined>(params);
};
