import { TreeviewFileItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const addFileIntoFolder = async (
  dataBookService: DataBookService,
  name,
  filetype,
  s3key,
  folder_id,
  size,
  document_code,
  datetime,
  revision,
  description,
  position,
  path_when_removed,
  remover_email,
  remover_name,
  removed,
): Promise<TreeviewFileItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'addFileIntoFolder',
    args: {
      name,
      filetype,
      s3key,
      folder_id,
      size,
      document_code,
      datetime,
      revision,
      description,
      position,
      path_when_removed,
      remover_email,
      remover_name,
      removed,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};
