<vic-base-page
  page="project"
  *mobxAutorun
>
  <div class="header-actions container"></div>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>

    <div
      class="clr-row"
      *ngIf="(showError || !isProjectVisible()) && initialized"
    >
      <div class="clr-col-1"></div>
      <div class="clr-col-10">
        <div
          class="val-card clr-col-12"
          style="text-align: center"
        >
          <label
            class="session-title"
            style="color: red"
          >
            {{ 'access.error' | translate }}
          </label>
        </div>
      </div>
    </div>

    <div
      class="clr-row"
      style="margin-bottom: 0 !important"
      *ngIf="!showError && isProjectVisible() && initialized"
    >
      <div class="clr-col-1"></div>
      <div class="clr-col-10">
        <div class="val-card clr-col-12">
          <div class="title-container clr-col-12">
            <label class="session-title">
              {{ 'select.your.project' | translate }}
            </label>
            <div style="width: 50%">
              <databook-search
                (searchProject)="onSearchChange($event)"
              ></databook-search>
            </div>
            <button
              *ngIf="canProjectConfig()"
              (click)="modalProject(null)"
              class="btn header-btn"
            >
              {{ 'create.your.project' | translate }}
            </button>
          </div>

          <clr-datagrid
            class="val-datagrid"
            id="projectsGridId"
          >
            <clr-dg-column>
              <button
                type="button"
                class="btn btn-outline button-secondary-column tab-button"
                (click)="sort('fav')"
                [ngClass]="selectedColumn !== 'fav' ? 'no-border' : ''"
              >
                {{ 'application.project.favorite' | translate }}
              </button>
            </clr-dg-column>

            <clr-dg-column [style.max-width.px]="400">
              <button
                type="button"
                class="btn btn-outline button-secondary-column tab-button"
                (click)="sort('name')"
                [ngClass]="selectedColumn !== 'name' ? 'no-border' : ''"
              >
                {{ 'application.project.name' | translate }}
              </button>
            </clr-dg-column>

            <clr-dg-column [style.max-width.px]="500">
              <button
                type="button"
                class="btn btn-outline button-secondary-column tab-button"
                (click)="sort('customer')"
                [ngClass]="selectedColumn !== 'customer' ? 'no-border' : ''"
              >
                {{ 'application.project.customer' | translate }}
              </button>
            </clr-dg-column>

            <clr-dg-column>
              <button
                type="button"
                class="btn btn-outline button-secondary-column tab-button"
                (click)="sort('poDate')"
                [ngClass]="selectedColumn !== 'poDate' ? 'no-border' : ''"
              >
                {{ 'application.project.podate' | translate }}
              </button>
            </clr-dg-column>

            <clr-dg-column>
              <button
                type="button"
                style="width: 80%"
                class="btn btn-outline button-secondary-column tab-button no-border"
              >
                {{ 'application.project.actions' | translate }}
              </button>
            </clr-dg-column>

            <clr-dg-row *ngFor="let project of store.projects">
              <clr-dg-cell
                *ngIf="project.visibleInProjectList"
                class="break-word text-align-center"
              >
                <clr-icon
                  shape="star"
                  [ngClass]="{ 'is-solid': project.isFavorite }"
                  id="favoriteStarId"
                  (click)="setFavorite(project)"
                  class="icon-button"
                >
                </clr-icon>
              </clr-dg-cell>
              <clr-dg-cell
                *ngIf="project.visibleInProjectList"
                [style.max-width.px]="400"
                class="break-word text-align-center"
              >
                <a (click)="onSelectClick(project)">{{ project.name }} </a>
              </clr-dg-cell>
              <clr-dg-cell
                *ngIf="project.visibleInProjectList"
                [style.max-width.px]="500"
                class="break-word text-align-center"
              >
                <a (click)="onSelectClick(project)">
                  {{ project.customer }}
                </a>
              </clr-dg-cell>
              <clr-dg-cell
                *ngIf="project.visibleInProjectList"
                class="break-word text-align-center"
              >
                <a (click)="onSelectClick(project)">{{ project.poDate }} </a>
              </clr-dg-cell>
              <clr-dg-cell
                *ngIf="project.visibleInProjectList"
                class="text-align-center"
              >
                <clr-icon
                  shape="pencil"
                  [title]="'application.commons.buttons.edit' | translate"
                  *ngIf="canProjectConfig()"
                  id="idEditProjectButton"
                  (click)="modalProject(project)"
                  class="icon-button"
                ></clr-icon>
                &nbsp;

                <img
                  [src]="getImage('archive.svg')"
                  *ngIf="canProjectConfig()"
                  (click)="openArchiveModal(project)"
                  class="icon-button"
                  style="height: 16px"
                />
              </clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </div>
      </div>
      <div class="clr-col-1"></div>
    </div>
  </div>
</vic-base-page>

<create-edit-project-modal
  *ngIf="projectModal.isSave || projectModal.isEdit"
  [modal]="projectModal"
  [form]="formProject"
  [closeModal]="closeModal"
  [modalIsOpen]="modalIsOpen"
  [project]="this.store.selectedProject"
  [updateProjects]="this.updateProjects"
  [currentUser]="userStore.user"
  [populateResult]="populateResult"
></create-edit-project-modal>

<archive-project-modal
  *ngIf="projectModal.confirmArchive || projectModal.archiveByText"
  [modal]="projectModal"
  [form]="archiveProjectForm"
  [openModal]="openModal"
  [closeModal]="closeModal"
  [modalIsOpen]="modalIsOpen"
  [populateResult]="populateResult"
  [updateProjects]="this.updateProjects"
  [project]="this.store.selectedProject"
></archive-project-modal>
