import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {
  DataExplorerType,
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import { DataBookService } from '../../services/databook.service';
import { plainToInstance } from 'class-transformer';
import {
  Product,
  Project,
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../../classes';
import { CoreService } from '../../core/core.service';
import { findProductsFromProjectId } from '../../classes/product-helpers';
import {
  FolderNameAndId,
  getFileById,
  getFolder,
  getRootFoldersForFrontEnd,
  treeviewItemGetPath,
} from '../../classes/treeview-helpers';
import { pipeNavigatorRouteNames } from '../../enums';
import {
  findMaterialFromId,
  findMaterialsFromProductId,
} from '../../classes/material-helpers';
import { DataBookState } from '../../services/databook.state';

@Injectable({
  providedIn: 'root',
})
export class UsersManagementResolver {
  constructor(
    // eslint-disable-next-line no-unused-vars
    public dataBookState: DataBookState,
    // eslint-disable-next-line no-unused-vars
    private coreService: CoreService,
    // eslint-disable-next-line no-unused-vars
    public dataBookService: DataBookService,
    // eslint-disable-next-line no-unused-vars
    public activatedRouter: ActivatedRoute,
    // eslint-disable-next-line no-unused-vars
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    // eslint-disable-next-line no-unused-vars
    @Inject(USER_STORE) public userStore: UserStore,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<void> {
    this.coreService.showLoading();

    try {
      await this.userStore.loadUserManagement(this.dataBookService);
    } catch (error) {
      console.log(error);
      this.coreService.hideLoading();
    } finally {
      this.coreService.hideLoading();
    }
  }
}
