<clr-modal
  [clrModalOpen]="modalIsOpen"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="modal-header">
      <clr-icon
        shape="history"
        size="30"
      ></clr-icon>
      <h3 class="modal-title-history">TPI History</h3>
      <hr style="width: 100%" />
      <h6 class="modal-title-subtitle">{{ document_name }}</h6>
      <label
        *ngIf="showSteps?.length"
        class="modal-subtitle-history"
      >
        {{ showSteps[0].document_description }}</label
      >
    </div>

    <div class="clr-row">
      <div class="clr-col-12">
        <clr-datagrid>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'tpi.utc.time' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column [style.max-width.px]="400">
            <span class="tpi-history-column-title">
              {{ 'tpi.user' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'tpi.event' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'tpi.comment' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'tpi.attachment' | translate }}
            </span>
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let step of showSteps">
            <clr-dg-cell
              class="break-word text-align-center"
              [ngClass]="{ 'next-steps': !step.created_on }"
            >
              {{ step.created_on ? simpleFmt(step.created_on) : 'Next' }}
            </clr-dg-cell>
            <clr-dg-cell
              [style.max-width.px]="400"
              class="break-word text-align-center"
              [ngClass]="{ 'next-steps': !step.created_on }"
            >
              <span class="tpi-history-column">
                {{ step.created_by }}
              </span>
            </clr-dg-cell>
            <clr-dg-cell
              class="break-word text-align-center"
              [ngClass]="{ 'next-steps': !step.created_on }"
            >
              {{ step.created_on ? (step.action | translate) : 'Send to' }}
            </clr-dg-cell>
            <clr-dg-cell
              class="break-word text-align-center"
              [ngClass]="{ 'next-steps': !step.created_on }"
            >
              {{ step.comment || step.email }}
            </clr-dg-cell>
            <clr-dg-cell [ngClass]="{ 'next-steps': !step.created_on }">
              <img
                class="file-tree-icon"
                (click)="
                  downloadAdditional(step.additional_id, step.additional_file)
                "
                style="cursor: pointer"
                src="../../../../assets/img/svg/download-icon.svg"
                *ngIf="step.additional_id"
                alt="Download Icon"
              />
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
    </div>
  </div>
  <div
    class="modal-footer modal-footer-history"
    [ngClass]="nextSteps.length > 0 ? 'history-modal-footer' : ''"
  >
    <button
      id="buttonNexStepsId"
      type="button"
      class="btn btn-primary"
      (click)="mergeSteps()"
      *ngIf="nextSteps.length > 0"
    >
      <span
        *ngIf="showSteps.length === steps?.length"
        id="qtdNexStepsId"
      >
        {{ nextSteps.length }}
      </span>
      <span *ngIf="showSteps.length !== steps?.length">
        {{ 'application.commons.hide' | translate }}
      </span>

      <span *ngIf="nextSteps.length === 1">
        {{ 'application.commons.next.step.in.workflow' | translate }}
      </span>

      <span *ngIf="nextSteps.length > 1">
        {{ 'application.commons.next.steps.in.workflow' | translate }}
      </span>
    </button>

    <div>
      <button
        type="button"
        class="btn btn-outline button-primary"
        (click)="exportToExcelHistory()"
      >
        {{ 'application.commons.buttons.export.to.excel' | translate }}
      </button>

      <button
        id="idCloseHistryButton"
        type="button"
        class="btn btn-outline"
        (click)="closeModal()"
      >
        {{ 'application.commons.buttons.cancel' | translate }}
      </button>
    </div>
  </div>
</clr-modal>
