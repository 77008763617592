import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from 'src/app/pages/databook/gridview/tools';

@Pipe({
  standalone: true,
  name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
  transform(value: number, decimal = 2): string {
    return formatBytes(value, decimal);
  }
}
