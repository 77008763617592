import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormField } from '@reactive-forms/classes/form-field';
import { FieldContainerComponent } from '@reactive-forms/components/field-container/field-container.component';

@Component({
  selector: 'pn-input',
  standalone: true,
  imports: [CommonModule, FieldContainerComponent, ReactiveFormsModule],
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true,
    },
  ],
})
export class InputComponent extends FormField {
  @Input() type = 'text';
}
