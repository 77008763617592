import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ViewChild,
} from '@angular/core';
import { DataBookBaseComponent } from '../databook-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';

@Component({
  selector: 'databook-data-analysis',
  templateUrl: './data-analysis.component.html',
  styleUrls: ['./data-analysis.component.scss'],
  standalone: true,
  imports: [BasePageComponent, BreadcrumbComponent, TranslateModule],
})
export class DataBookDataAnalysisComponent extends DataBookBaseComponent {
  async onPageInit() {
    this.store.activePageForBreadcrumb = 'material';
    await super.initProjectProduct();
  }

  hideLoading() {
    this.coreService.hideLoading();
  }

  selectItemDataAnalysis(dataAnalysis) {
    this.store.gotoPage('data-analysis/' + dataAnalysis);
    // this.coreService.getRouter().navigate(['/main/data-analysis/' + dataAnalysis]);
  }

  isDataAnalysisVisible() {
    if (super.getRoleByProject().DATA_ANALYSIS) {
      return true;
    }
    return false;
  }
  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.data.analysis',
      null,
    );
    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },

      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },

      {
        name: this.coreService.translate('databook.data.analysis', null),
        link: '/',
        type: this.coreService.translate('databook.dashboard', null),
      },

      {
        name: this.store.material ? this.store.material.description : '',
        type: this.coreService.translate('databook.material.project', null),
        link: '/main/material',
      },
    ];
  }

  getCondition() {
    return false;
  }

  goToTestType(type) {
    if (this.dataBookState.oldDataAnalysis) {
      this.store.gotoDataAnalysisType(type);
    } else {
      this.store.gotoPage('new-data-analysis/' + type);
    }
  }

  public checkAndBack(condition) {
    if (this.dataBookState.oldDataAnalysis === undefined) {
      this.store.gotoDashboardPage();
    }
    if (!this.store.selectedProject.gsi_id || !this.dataBookState.product) {
      this.store.goto('');
      // this.coreService.getRouter().navigate(['/main/project']);
    } else if (
      !this.store.materialId &&
      this.dataBookState.oldDataAnalysis === true
    ) {
      this.coreService.showAlertErrorTranslate('select.material.error');
      this.store.gotoPage('material');
      // this.coreService.getRouter().navigate(['/main/material']);
    }
  }
}
