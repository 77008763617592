import { Component, OnInit, Input } from '@angular/core';
import { CoreService } from '../../core.service';

@Component({
  selector: 'vic-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
})
export class LoadingComponent implements OnInit {
  @Input()
  loading;

  @Input()
  message;

  constructor(public coreService: CoreService) {}

  ngOnInit() {}
}
