import { Component } from '@angular/core';
import { DataAnalysisBaseComponent } from '../data-analysis-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolComponent } from '../../../components/tool/tool.component';
import { InfoComponent } from '../../../components/info/info.component';
import { FilterColumnComponent } from '../../../components/filter-column/filter-column.component';
import { FilterComponent } from '../../../components/filter/filter.component';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  ClrCommonFormsModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrDropdownModule,
  ClrIconModule,
  ClrConditionalModule,
  ClrCheckboxModule,
} from '@clr/angular';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../../core/components/page/base-page.component';

declare let Plotly: any;
declare let gtag;
@Component({
  templateUrl: './boxplot.component.html',
  styleUrls: ['./boxplot.component.scss'],
  standalone: true,
  imports: [
    BasePageComponent,
    BreadcrumbComponent,
    ClrCommonFormsModule,
    FormsModule,
    NgFor,
    NgIf,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    ClrDropdownModule,
    ClrIconModule,
    ClrConditionalModule,
    ClrCheckboxModule,
    FilterComponent,
    FilterColumnComponent,
    NgClass,
    InfoComponent,
    ToolComponent,
    TranslateModule,
  ],
})
export class BoxplotDataAnalysisComponent extends DataAnalysisBaseComponent {
  propertyX;
  propertyY;
  selectedX = null;
  selectedY = [];
  selectedTest;

  items;

  checkY = [];
  selectedAll = false;
  _dropdownOpen = false;

  get dropdownOpen() {
    return this._dropdownOpen;
  }

  set dropdownOpen(value: boolean) {
    this._dropdownOpen = value;
    if (!value) {
      this.propertyChange();
    }
  }

  async sendGtag(eventAction) {
    const param = {
      spn_project: this.store.selectedProject.gsi_id,
      spn_feature: 'Data Analysis',
      spn_action: eventAction,
      spn_userType: this.coreService.getConfig().currentUser.type,
      // feature:  'Data Analysis',
      // category: 'action',
      // event: eventAction,
      // pageName: '/main/data-analysis/boxplot',
      // userType: this.coreService.getConfig().currentUser.type,
      // project: this.store.selectedProject.gsi_id
    };
    gtag('event', eventAction, param);
  }

  async generateGraphicsGtag() {
    this.sendGtag('search');
    this.generateGraphics();
  }

  async generateGraphics() {
    this.errorTest = null;
    try {
      const values = await this.getData();
      const data = await this.getTraces(values.Y, values.X);
      const layout = this.getLayout();
      const settings = this.getSettings();
      const divChart = document.getElementById('chart') as any;

      if (!divChart) {
        setTimeout(() => this.generateGraphics(), 500);
        return;
      } else {
        this.sendGtag('view');
      }

      Plotly.newPlot(divChart, data, layout, settings);

      divChart.on('plotly_afterplot', this.closeLoading);
    } catch (error) {
      this.coreService.processError(error);
    }
  }

  async getData() {
    const values = this.filterItems(this.items.items);

    if (values.length === 0) {
      this.showError('Teste não possui valores válidos');
    }

    const Y = [];
    const X = [];

    values.map((value) => {
      // config data axis Y
      this.selectedY.forEach((selection) => {
        if (!Y[selection]) {
          Y[selection] = [value[selection]];
        } else {
          Y[selection].push(value[selection]);
        }
      });
      // config data axis X
      X.push(value[this.selectedX]);
    });

    return { Y, X };
  }

  filterItems(items) {
    this.filters.forEach((filter) => {
      const propertie = filter.title.name;
      const filterOut = filter.columns.filter(
        (column) => column.checked === false,
      );
      filterOut.forEach((value) => {
        items = items.filter((item) => item[propertie] !== value.description);
      });
    });
    return items;
  }

  getTraces(valueY, valueX: string[]) {
    return Object.keys(valueY).map((property) => {
      return {
        type: 'box',
        name: property,
        y: valueY[property],
        x: valueX,
        boxmean: false,
        orientation: 'v',
        marker: {
          size: 3,
        },
        boxpoints: 'all',
        jitter: 3,
        whiskerwidth: 3,
        line: {
          width: 1,
        },
      };
    });
  }

  getLayout() {
    const colorway = this.paletteColors
      .find((g) => g.group === this.settings.colors[0])
      .items.find((p) => p.name === this.settings.colors[1]).colors;
    return {
      title: this.settings.title.label,
      font: {
        family: this.settings.fontFamily,
        size: this.settings.title.fontSize,
        color: this.settings.title.color,
      },
      margin: { l: 100, t: 50, b: 100, r: 50 },
      showlegend: true,
      legend: {
        x: this.settings.legend.positionX,
        y: this.settings.legend.positionY,
        font: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
        bgcolor: this.settings.legend.fill,
      },
      yaxis: {
        title: this.settings.legend.labelY,
        type: 'number',
        titlefont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
        showticklabels: this.settings.tick.show,
        tickangle: this.settings.tick.rotateY,
        tickfont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
      },
      xaxis: {
        title: this.settings.legend.labelX,
        type: 'category',
        titlefont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
        showticklabels: this.settings.tick.show,
        tickangle: this.settings.tick.rotateX,
        tickfont: {
          family: this.settings.fontFamily,
          size: this.settings.legend.fontSize,
          color: this.settings.legend.color,
        },
      },
      boxmode: 'group',
      bargap: 0.005,
      bargroupgap: 0.01,
      colorway,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
    };
  }

  async testeChange() {
    this.propertyX = [];
    this.propertyY = [];
    this.selectedX = null;
    this.selectedY = [];
    this.checkY = this.checkY.map(() => false);
    this.coreService.showLoading();
    let idTestValue: number;

    for (const item1 of this.tests) {
      if (this.selectedTest === item1.description) {
        idTestValue = item1.id;

        this.propertyY = [];
        this.filters = [];
        item1.tests.map((item) => {
          if (item.continuous) {
            this.propertyY.push(item);
          }
          if (item.filter) {
            this.filters.push({ title: item, columns: [] });
          }
        });

        this.propertyX = item1.tests.filter((t) => t.continuous === false);
        this.propertyY.forEach((prop) => {
          this.checkY[prop.name] = false;
        });
        if (this.selectedTest.includes('.')) {
          this.settings.title.label = this.selectedTest
            .split('.')[2]
            .toUpperCase();
          this.settings.legend.labelY = this.selectedTest
            .split('.')[2]
            .toUpperCase();
        } else {
          this.settings.title.label = this.selectedTest.toUpperCase();
          this.settings.legend.labelY = this.selectedTest.toUpperCase();
        }
      }
    }
    if (this.dataBookState.oldDataAnalysis) {
      const params = {
        project: this.store.selectedProject.gsi_id,
        projectId: this.store.selectedProject.id,
        material: this.store.materialId,
        materialId: this.store.materialId,
        test: this.selectedTest.split('.')[2].toLowerCase(),
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);

      // const pageTitle = document.title + ' - ' + this.selectedTest.split('.')[2].toUpperCase()
      // window.history.pushState({}, pageTitle, window.location.origin + window.location.pathname + '/' + this.selectedTest.split('.')[2].toLowerCase());

      this.items = await this.dataBookService.getTestsResultPLP(params);
    } else {
      const params = { resultTests: idTestValue };
      this.cfgVlr(params);
      this.items = await this.dataBookService.dataAnalysis(params);
    }

    this.items.items.forEach((_v: any, i: number) => {
      this.filters.forEach((filter: any) => {
        const value = this.items.items[i][filter.title.name];
        if (!filter.columns.find((v) => v.description === value)) {
          filter.columns.push({
            description: value,
            name: value,
            checked: true,
          });
        }
      });
    });

    const filtersToRemove = [];
    this.filters.forEach((filter: any) => {
      if (filter.columns.length === 0) {
        filtersToRemove.push(filter);
      }
    });
    this.filters = this.filters.filter(
      (item) => !filtersToRemove.includes(item),
    );
    this.coreService.hideLoading();
  }

  public cfgVlr(params) {
    const vlr = this.dataBookService.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  }

  async propertyChange() {
    console.log(this.dropdownOpen);
    if (this.hasPropertiesSelected && !this.dropdownOpen) {
      this.settings.legend.labelX = this.selectedX
        .replace('_', ' ')
        .toUpperCase();
      this.coreService.showLoading();
      this.generateGraphics();
    }
  }

  selectAllToggle() {
    if (this.selectedAll) {
      for (const key in this.checkY) {
        this.checkY[key] = true;
      }
    } else {
      for (const key in this.checkY) {
        this.checkY[key] = false;
      }
    }
  }

  get hasPropertiesSelected(): boolean {
    this.selectedY = [];
    for (const s in this.checkY) {
      if (this.checkY[s]) {
        this.selectedY.push(s);
      }
    }
    return this.selectedX && this.selectedY.length > 0;
  }

  async onChangeTools() {
    this.generateGraphics();
  }

  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.data.analysis.boxplot',
      null,
    );
    this.breadcrumb = [
      {
        name: this.store.selectedProject.name,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },

      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },

      {
        name: this.coreService.translate('databook.data.analysis', null),
        link: '/',
        type: this.coreService.translate('databook.dashboard', null),
      },
      {
        name: this.store.material ? this.store.material.description : '',
        type: this.coreService.translate('databook.material.project', null),
        link: '/main/material',
      },
      {
        name: this.coreService.translate('databook.data.analysis', null),
        link: '/main/data-analysis',
      },
    ];
  }
}
