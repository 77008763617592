<vic-base-page
  page="dashboard"
  *mobxAutorun
>
  <div class="header-actions container"></div>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>

    <input
      type="text"
      [(ngModel)]="newUserEmail"
      placeholder="Email"
      class="user-email-input"
    />
    <button (click)="addUser()">Add User</button>

    <div class="users-list">
      <div
        (click)="selectUser(user)"
        *ngFor="let user of userStore.users"
      >
        {{ user.email }}
      </div>
    </div>

    <div
      class="permission-matrice-wrapper"
      *ngIf="userStore.userPermissionsMatrice.length > 0"
    >
      <div
        class="permission-matrice-project-wrapper"
        *ngFor="let project of userStore.userPermissionsMatrice"
      >
        <div class="permission-matrice-project-label">
          {{ project.id }} {{ project.name }}
        </div>
        <div class="permission-matrice-product-list">
          <div
            class="permission-matrice-product-wrapper"
            *ngFor="let product of project.products"
          >
            <div class="permission-matrice-product-label">
              {{ product.id }} {{ product.name }}
            </div>
            <div class="permission-matrice-features-list">
              <div
                class="permission-matrice-features-wrapper"
                *ngFor="let feature of product.features"
              >
                <div class="permission-matrice-feature-label">
                  {{ feature.id }} {{ feature.name }}
                </div>
                <div class="permission-matrice-permissions-list">
                  <div
                    class="permission-matrice-permissions-wrapper"
                    *ngFor="let permission of feature.permissions"
                  >
                    <div class="permission-matrice-permissions-label">
                      <div>{{ permission.id }} {{ permission.name }}</div>
                      <div>
                        <input
                          type="checkbox"
                          (click)="
                            checkboxClick(
                              permission.active,
                              this.userStore.selectedUser.id,
                              permission.id,
                              permission.productFeatureId
                            )
                          "
                          [(ngModel)]="permission.active"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div>{{userStore.selectedUser.id}} - {{userStore.selectedUser.email}}</div>
            <div style="border: solid 1px #d23b3b; margin: 4px;" (click)="this.userStore.selectedProject = project; this.userStore.loadProducts(this.dataBookService)" *ngFor="let project of userStore.projects">{{project.id}} {{project.name}}</div>
            <div style="border: solid 1px #70933f; margin: 4px;" (click)="this.userStore.selectedProduct = product; this.userStore.loadFeatures(this.dataBookService)" *ngFor="let product of userStore.products">{{product.id}} {{product.name}}</div>
            <div style="border: solid 1px #133d5e; margin: 4px;" (click)="this.userStore.selectedFeature = feature; this.userStore.loadPermissions(this.dataBookService)" *ngFor="let feature of userStore.features">{{feature.productFeatureId}} {{feature.name}}</div>
            <div style="border: solid 1px rgba(255,176,0,0.37); margin: 4px;" *ngFor="let permission of userStore.permissions">{{permission.id}} {{permission.name}}</div>-->
    </div>
  </div>
</vic-base-page>
```
