<clr-dropdown class="dropdown">
  <button
    type="button"
    class="btn btn-icon"
    clrDropdownTrigger
  >
    <clr-icon
      shape="info-circle"
      size="24"
    ></clr-icon>
  </button>
  <ng-template [(clrIfOpen)]="infoOpen">
    <clr-dropdown-menu [clrPosition]="position">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-block">
        <div class="description-card">
          {{ body }}
        </div>
      </div>
    </clr-dropdown-menu>
  </ng-template>
</clr-dropdown>
