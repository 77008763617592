import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CellClassParams, GridApi, GridOptions, IServerSideGetRowsParams,} from 'ag-grid-community';
import {ColumnApi} from 'ag-grid-community/dist/lib/columns/columnApi';
import {LicenseManager} from 'ag-grid-enterprise';
import _ from 'lodash';
import {PdfService} from '../../components/pdf/pdf.service';
import {CoreService} from '../../core/core.service';
import {PROJECT_STORE, ProjectsStore, USER_STORE, UserStore,} from '../../mobx-stores';
import {DataBookService} from '../../services/databook.service';
import {DataBookState} from '../../services/databook.state';
import {DataBookBaseComponent} from '../databook-base.component';
import {CustomLoadingCellRenderer} from './ag-grid/custom-loading-cell-renderer.component';
import {columnsDefFile, immutableColumnsDefFile,} from './columns-def/phs-columns-def';
import {ColDefDataExplorer, DataExplorerRow} from './data-explorer';
import {supplyToGrid} from './tools/ag-grid';
import {
    ERROR_CODE_NO_DATA_TO_SUBMIT,
    ERROR_CODE_NO_PRODUCT_SELECTED,
    EXCEL_STYLES,
    LICENSE_KEY,
} from './tools/constants';
import {exportToExcel} from './tools/excel-export';
import {fetchS3Content} from './tools/fetch-s3';
import {AgGridModule} from 'ag-grid-angular';
import {ProgressBarComponent} from '../../components/progress-bar/progress-bar.component';
import {DataExplorerHeaderComponent} from './components/data-explorer-header/data-explorer-header.component';
import {BreadcrumbComponent} from '../../components/breadcrumb/breadcrumb.component';
import {BasePageComponent} from '../../core/components/page/base-page.component';
import {addCellRenderedComponentOnEachColumnDef} from './columns-def/tools/addCellRenderedComponentOnEachColumnDef';

interface Child {
    field: string;
    headerClass?: string;
    pinned?: string;
    cellClass?: string;
    sortable?: boolean;
    filter?: string;
    children?: Child[];
}

interface Header {
    headerName: string;
    headerClass?: string;
    children: Child[];
}

@Component({
    templateUrl: 'data-explorer.component.html',
    styleUrls: ['data-explorer.component.scss'],
    standalone: true,
    imports: [
        BasePageComponent,
        BreadcrumbComponent,
        DataExplorerHeaderComponent,
        ProgressBarComponent,
        AgGridModule,
    ],
})
export class DataExplorerComponent extends DataBookBaseComponent implements OnInit {
    constructor(
        public dataBookState: DataBookState,
        public dataBookService: DataBookService,
        public coreService: CoreService,
        public activatedRouter: ActivatedRoute,
        public pdfService: PdfService,
        public httpClient: HttpClient,
        @Inject(PROJECT_STORE) public store: ProjectsStore,
        @Inject(USER_STORE) public userStore: UserStore,
        private translateService: TranslateService,
    ) {
        super(
            dataBookState,
            dataBookService,
            coreService,
            activatedRouter,
            pdfService,
            httpClient,
            store,
            userStore,
        );
    }

    public gridOptions: GridOptions = {};
    public loadProgress = 0;
    public loadProgressError = false;

    // TODO create a class to manage data and search ?
    private gridApi: GridApi;
    private fetchedDataExplorerList: DataExplorerRow[];
    private columnApi: ColumnApi;
    private loadingCellRendererParams = {
        loadingMessage: 'Table creation in progress. Almost there',
        errorMessage: false,
    };
    private currentLang: string;
    private originalHeaders: ColDefDataExplorer[] = immutableColumnsDefFile;


    // TEMPLATE EVENT
    public onUpdateQuickFilter(quickFilter = '') {
        if (quickFilter.length == 0) {
            this.gridApi.setFilterModel(null);
        }

        this.gridApi.setQuickFilter(quickFilter);
        if (this.gridOptions) {
            this.onGridReady(this.gridOptions);
        }
    }

    public isAnyFilterPresent() {
        return this.gridApi?.getQuickFilter()?.length > 0;
    }

    public async onClickExportToExcel() {
        this.coreService.showLoading();
        try {
            await exportToExcel(this.gridApi);
        } catch (error) {
            await this.coreService.processError(error);
        } finally {
            this.coreService.hideLoading();
        }
    }

    // COMPONENT EVENT
    public async onPageInit() {
        LicenseManager.setLicenseKey(LICENSE_KEY);


        try {
            this.coreService.showLoading();
            this.gridOptions = this.createGridOptions();

            this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                if (this.currentLang !== event.lang) {
                    this.translateHeaders();
                }

                this.currentLang = event.lang;
            });
        } catch (error) {
            console.error(error);
            this.coreService.showAlertErrorTranslate(
                'databook.dataexplorer.list.error',
            );
        } finally {
            this.coreService.hideLoading();
        }
    }

    private createGridOptions(): GridOptions {
        console.log(this.getColumnsAndTranslateHeaders())
        return {
            // columnDefs: this.getColumnsAndTranslateHeaders(),
            defaultColDef: {
                filter: true,
                resizable: true,
                enableValue: true,
                enablePivot: true,
                enableRowGroup: true,
                cellClassRules: {
                    lightGrayBackground: (params: CellClassParams) =>
                        (params.node.rowIndex || 0) % 2 == 0,
                },
            },
            rowSelection: 'single',
            suppressCopyRowsToClipboard: true,
            headerHeight: 25,
            rowHeight: 25,
            autoGroupColumnDef: {
                minWidth: 200,
            },
            excelStyles: EXCEL_STYLES,
            getRowStyle: (params) =>
                params.node.rowIndex % 2 === 0 ? {background: '#D3D3D3'} : null,
            onGridReady: (params) => this.onGridReady(params),
            onComponentStateChanged: () => this.autoSizeAll(false),
            loadingCellRenderer: CustomLoadingCellRenderer,
            loadingCellRendererParams: this.loadingCellRendererParams,
            rowModelType: 'serverSide',
            serverSideStoreType: 'partial',
            // ... other grid configuration options
            cacheBlockSize: 450,
            maxBlocksInCache: 450,
            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: this.coreService.translate(
                            'databook.dataexplorer.column.tool',
                        ),
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressValues: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressColumnFilter: false,
                            suppressColumnSelectAll: false,
                            suppressColumnExpandAll: false,
                        },
                    },
                ],
                defaultToolPanel: '',
            },
        };
    }

    mergeArrays(firstJsonArray: Header [], secondJsonArray: ColDefDataExplorer[]): Header[] {
        firstJsonArray.forEach(firstJson => {
            const correspondingSecondJson = secondJsonArray.find(sj => sj.headerName === firstJson.headerName);

            if (correspondingSecondJson) {
                if (correspondingSecondJson.headerClass) {
                    firstJson.headerClass = correspondingSecondJson.headerClass as string;
                }

                firstJson.children.forEach(firstChild => {
                    // Set headerClass of firstChild to parent's headerClass if it's missing
                    if (!firstChild.headerClass) {
                        firstChild.headerClass = firstJson.headerClass;
                    }

                    const fieldPropertiesMap = new Map();
                    correspondingSecondJson.children.forEach(secondChild => {
                        secondChild.children.forEach(grandChild => {
                            fieldPropertiesMap.set(grandChild.field, {
                                headerClass: grandChild.headerClass,
                                sortable: grandChild.sortable,
                                cellClass: grandChild.cellClass,
                                pinned: grandChild.pinned
                            });
                        });
                    });

                    // Apply properties to grandchildren, inheriting headerClass from parent if missing
                    firstChild.children.forEach(grandChild => {
                        const properties = fieldPropertiesMap.get(grandChild.field);
                        if (properties) {
                            grandChild.headerClass = grandChild.headerClass || firstChild.headerClass;
                            grandChild.sortable = properties.sortable;
                            grandChild.cellClass = properties.cellClass;
                            grandChild.pinned = properties.pinned;
                        } else if (!grandChild.headerClass) {
                            grandChild.headerClass = firstChild.headerClass;
                        }
                    });
                });
            }
        });

        return firstJsonArray;
    }


    private async onGridReady({api, columnApi}: GridOptions) {
        // TODO review property usage
        this.gridApi = api;

        this.columnApi = columnApi;
        const columns = await this.getColumnsDef();
        if (columns) {
            const oldColumnsDef = this.getColumnsAndTranslateHeaders()
            const mergedArray = this.mergeArrays(columns.columnDefs, oldColumnsDef);
            console.log(" mergedArray ", mergedArray)
            api.setColumnDefs(columns.columnDefs)
        } else {
            const oldColumnsDef = this.getColumnsAndTranslateHeaders()
            api.setColumnDefs(oldColumnsDef)
        }


        this.gridApi.setServerSideDatasource(this.createServerSideDataSource());
        this.gridApi.getToolPanelInstance('columns').collapseColumnGroups();
    }

    private getColumnsAndTranslateHeaders() {
        return addCellRenderedComponentOnEachColumnDef(
            columnsDefFile,
            (column) => {
                if (column.headerName) {
                    column.headerName = this.coreService.translate(column.headerName) || '';
                }

                if (column.field) {
                    column.field = this.coreService.translate(column.field) || '';
                }

                return column;
            });
    }

    private autoSizeAll(skipHeader: boolean) {
        this.columnApi.autoSizeColumns(
            this.columnApi
                .getColumns()
                .reduce(
                    (allColumnIds, column) => [...allColumnIds, column.getId()],
                    [],
                ),
            skipHeader,
        );
    }

    // Create a custom data source
    private createServerSideDataSource() {
        console.log(' createServerSideDataSource ')
        return {
            getRows: async (params: IServerSideGetRowsParams) => {
                try {
                    this.loadingCellRendererParams.errorMessage = false;
                    if (this.canFilterGridByQuickFilter()) {
                        supplyToGrid(params, this.filterGridByQuickFilter());
                        return;
                    }

                    if (this.fetchedDataExplorerList) {
                        supplyToGrid(params, this.fetchedDataExplorerList);
                        return;
                    }

                    await this.loadDataExplorerData(params);
                } catch (error) {
                    this.onFailDataSourceCreation(params, error);
                }
            },
        };
    }

    private translateHeaders = () => {
        const columns: ColDefDataExplorer[] = _.cloneDeep(this.originalHeaders);

        const translatedColumns = this.recursiveTranslateHeaders(columns);

        this.gridApi.setColumnDefs(translatedColumns);
        this.hideEmptyColumns();
    }

    private recursiveTranslateHeaders = (
        columns: ColDefDataExplorer[],
    ): ColDefDataExplorer[] => {
        return columns.map((column) => {
            const translatedColumn: ColDefDataExplorer = {...column}; // Clone the column object

            if (Array.isArray(column.children)) {
                translatedColumn.children = this.recursiveTranslateHeaders(
                    column.children,
                );
            }

            if (translatedColumn.headerName) {
                translatedColumn.headerName =
                    this.coreService.translate(translatedColumn.headerName) || '';
            }

            if (translatedColumn.field) {
                translatedColumn.field =
                    this.coreService.translate(translatedColumn.field) || '';
            }

            return translatedColumn;
        });
    };

    private onFailDataSourceCreation(
        params: IServerSideGetRowsParams,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: any,
    ) {
        this.loadProgressError = true;
        this.loadingCellRendererParams.errorMessage = true;
        this.loadingCellRendererParams.loadingMessage = error.message;
        console.error(error);
        params.fail(); // Notify ag-Grid that data loading has failed
    }

    private canFilterGridByQuickFilter() {
        return (
            this.fetchedDataExplorerList && this.gridApi.getQuickFilter()?.length > 0
        );
    }

    private filterGridByQuickFilter() {
        // To search only certain column
        // const code = this.columns['ippn'];

        return this.fetchedDataExplorerList.filter((item) =>
            Object.keys(item).some((code) =>
                String(item[code]).includes(this.gridApi.getQuickFilter()),
            ),
        );
    }

    private async loadDataExplorerData(params: IServerSideGetRowsParams) {
        const fileUrl = await this.getDataExplorerFileUrl(params);
        const fetchedDataExplorerList = await this.fetchAndParseData(fileUrl);
        this.fetchedDataExplorerList = fetchedDataExplorerList;
        supplyToGrid(params, fetchedDataExplorerList);
        this.hideEmptyColumns();
    }

    async getDataExplorerFileUrl(params: IServerSideGetRowsParams) {
        if (!this.store.selectedProduct?.id) {
            throw new Error(ERROR_CODE_NO_PRODUCT_SELECTED.message, {
                cause: ERROR_CODE_NO_PRODUCT_SELECTED.code,
            });
        }

        const result = await this.dataBookService.dataExplorer(
            {
                listTests: true,
                productId: this.store.selectedProduct.id,
            },
            // is localhost false, else true
            true,
        );

        if (!result.fileUrl) {
            if (!result?.testsResult?.length) {
                throw new Error(ERROR_CODE_NO_DATA_TO_SUBMIT.message, {
                    cause: ERROR_CODE_NO_DATA_TO_SUBMIT.code,
                });
            }

            // Is it still useful ?
            // Maybe we just have to display the error message
            supplyToGrid(params, result?.testsResult);
            return;
        }

        return result.fileUrl;
    }

    async getColumnsDef() {
        const params = {
            columns: true,
            key: `data_explorer/columns_def/${this.store.selectedProject.gsi_id}-${this.store.selectedProduct.gsi_id}-${this.store.selectedProduct.id}.json`,
        }
        return await this.dataBookService.dataExplorerColumnsDef(params, true)
    }

    private async fetchAndParseData(
        fileUrl?: string,
    ): Promise<DataExplorerRow[]> {
        return JSON.parse(
            await fetchS3Content({
                preSignedUrl: fileUrl,
                httpClient: this.httpClient,
                onDownloadProgress: (loadProgress) => {
                    this.loadProgress = loadProgress;
                },
            }),
        );
    }

    /**
     * Hide empty columns if all the column is empty
     */
    private hideEmptyColumns() {
        const [renderedNodes] = this.gridApi.getRenderedNodes();

        this.columnApi.getColumns().forEach((column) => {
            this.columnApi.setColumnVisible(
                column,
                Boolean(this.gridApi.getValue(column, renderedNodes)),
            );
        });
    }
}

// function isOffline() {
//   return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
// }
