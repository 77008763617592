<vic-base-page page="databook">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="setDataSheet()"
      *ngIf="canInsert() && false"
    >
      <clr-icon shape="plus"></clr-icon>
      <span class="nav-text">{{ 'databook.set.data.sheet' | translate }}</span>
    </a>

    <!-- <a class="nav-link nav-icon-text"  (click)="createEmail()">
      <clr-icon shape="plus"></clr-icon>
      <span class="nav-text">EMAIL</span>
    </a> -->

    <a
      class="nav-link nav-icon-text"
      (click)="openRemoveListFiles()"
      *ngIf="canUpload() && isLucas()"
    >
      <clr-icon shape="plus"></clr-icon>
      <span class="nav-text">
        {{ 'application.restore.remove.files' | translate }}
      </span>
    </a>
  </div>

  <div class="page-body-container">
    <a
      class="btn-link"
      id="downloadFiles"
      [hidden]="true"
    ></a>
    <a
      class="btn-link"
      id="sendByEmail"
      [hidden]="true"
    ></a>
    <databook-breadcrumb> </databook-breadcrumb>

    <div class="databook-container">
      <div class="val-card auto-card clr-col-12 container-tree-files">
        <!-- <div class="title-container">
          <label class="session-title">
            {{ 'databook.documents' | translate }}
          </label>

          <databook-search
            (searchProject)="onSearchChange($event)"
            [minLength]="3"
            [whichPage]="'databook'"
          ></databook-search>

          <div class="category-root">
            <img
              class="file-tree-icon"
              (click)="openModalCategory($event, null)"
              *ngIf="canDefineCategory()"
              src="../../../assets/img/svg/plus-icon.svg"
            />
            <span style="text-align: center">
              {{ rootCategory }}
            </span>
          </div>
        </div>-->

        <div class="file-tree-container">
          <div class="file-tree-container-scroll">
            <button
              style="display: none"
              class="select-unselect-button"
              (click)="checkUncheckAll()"
            >
              <label class="label-small">
                <b *ngIf="!check">
                  {{ 'application.commons.text.select.all' | translate }}
                </b>
                <b *ngIf="check">
                  {{ 'application.commons.text.unselect.all' | translate }}
                </b>
              </label>
            </button>

            <button
              class="file-tree-button"
              (click)="sortNameAsc()"
              *ngIf="clickedSortNameValue === 'desc'"
              style="float: right; margin-left: 4px; display: none"
            >
              {{ 'name.asc' | translate }}
            </button>
            <button
              class="file-tree-button"
              (click)="sortNameDesc()"
              *ngIf="clickedSortNameValue === 'asc'"
              style="float: right; margin-left: 4px; display: none"
            >
              {{ 'name.desc' | translate }}
            </button>
            <button
              class="file-tree-button"
              (click)="sortDateAsc()"
              *ngIf="clickedSortDateValue === 'desc'"
              style="float: right; display: none"
            >
              {{ 'date.asc' | translate }}
            </button>
            <button
              class="file-tree-button"
              (click)="sortDateDesc()"
              *ngIf="clickedSortDateValue === 'asc'"
              style="float: right; display: none"
            >
              {{ 'date.desc' | translate }}
            </button>

            <!-- <treeview-item
                    *ngFor="let folder of this.rootFolders"
                    [item]="folder"
                    [isRoot]="true"
            ></treeview-item> -->

            <treeview
              (openConfirmMerge)="openConfirmMerge()"
              (sendDocsByEmail)="openSendEmailModal()"
              (viewDocument)="viewDocument2($event)"
              (updateFile)="updateFile2($event)"
              (openUpload)="openUpload2($event)"
              (download)="download()"
              (downloadVersion)="downloadVersion($event)"
              (downloadMerge)="downloadMergedFilesArchive()"
              [selectionFolderOnly]="false"
            ></treeview>

            <!--            <div style="height: 65vh; width: 100%; overflow: hidden">-->
            <!--              <tree-root-->
            <!--                #tree-->
            <!--                [nodes]="nodes"-->
            <!--                [options]="options"-->
            <!--                (copyNode)="onCopyNode($event)"-->
            <!--                (moveNode)="onMoveNode($event)"-->
            <!--                class="file-tree"-->
            <!--              >-->
            <!--                <ng-template-->
            <!--                  #treeNodeWrapperTemplate-->
            <!--                  let-node-->
            <!--                  let-index="index"-->
            <!--                >-->
            <!--                  <div-->
            <!--                    class="file-tree"-->
            <!--                    (treeDrop)="onDrop($event)"-->
            <!--                    [treeAllowDrop]="node.allowDrop"-->
            <!--                    [treeDrag]="node"-->
            <!--                    [treeDragEnabled]="node.allowDrag()"-->
            <!--                  >-->
            <!--                    <div class="tree-checkbox-expander">-->
            <!--                      <input-->
            <!--                        type="checkbox"-->
            <!--                        [(ngModel)]="node.isSelected"-->
            <!--                        (change)="doCheckUncheck($event, node)"-->
            <!--                        style="margin-right: 1px"-->
            <!--                      />-->
            <!--                      <tree-node-expander-->
            <!--                        [node]="node"-->
            <!--                        style="margin-bottom: 1px"-->
            <!--                      ></tree-node-expander>-->

            <!--                      <span-->
            <!--                        class="label-small"-->
            <!--                        *ngIf="!node.data.size && !canRename()"-->
            <!--                      >-->
            <!--                        {{ node.data.name }}-->
            <!--                      </span>-->
            <!--                      <span-->
            <!--                        class="label-small"-->
            <!--                        [ngClass]="{ 'fixed-node': isNodeFixed(node) }"-->
            <!--                        *ngIf="isNodeFixed(node)"-->
            <!--                      >-->
            <!--                        {{ node.data.name }}-->
            <!--                      </span>-->
            <!--                      <span-->
            <!--                        class="label-small"-->
            <!--                        *ngIf="node.data.size && !canRename()"-->
            <!--                      >-->
            <!--                        <b>{{ node.data.description }}</b>-->
            <!--                      </span>-->
            <!--                      &lt;!&ndash;                  <span *ngIf="node.data.revision" style="color:green;">{{node.data.revision}}</span><span style="color:green;" *ngIf="node.data.documentCode"> - {{node.data.documentCode}}</span>&ndash;&gt;-->
            <!--                      <input-->
            <!--                        type="text"-->
            <!--                        class="document-name-input"-->
            <!--                        [(ngModel)]="node.data.name"-->
            <!--                        (blur)="updateTree()"-->
            <!--                        (keypress)="onlyValidCharacters($event)"-->
            <!--                        *ngIf="-->
            <!--                          !node.data.size && canRename() && !isNodeFixed(node)-->
            <!--                        "-->
            <!--                        size="100"-->
            <!--                        (focus)="stopInterval()"-->
            <!--                        id="categoryInputFieldId"-->
            <!--                      />-->

            <!--                      <input-->
            <!--                        type="text"-->
            <!--                        class="document-name-input"-->
            <!--                        [(ngModel)]="node.data.description"-->
            <!--                        (focus)="stopInterval()"-->
            <!--                        (blur)="-->
            <!--                          updateFileTree(node.data.description, node.data.id)-->
            <!--                        "-->
            <!--                        size="100"-->
            <!--                        (keypress)="onlyValidCharacters($event)"-->
            <!--                        *ngIf="node.data.size && canRename()"-->
            <!--                        style="font-weight: bold"-->
            <!--                      />-->

            <!--                      <span *ngIf="node.data.size">-->
            <!--                        {{ formatDateFile(node.data.datetime) }}-->
            <!--                      </span>-->

            <!--                      <button-->
            <!--                        class="file-tree-button"-->
            <!--                        (click)="openUpload($event, node)"-->
            <!--                        id="loadFile"-->
            <!--                        *ngIf="!node.data.size && canUpload()"-->
            <!--                      >-->
            <!--                        {{ 'application.commons.buttons.upload' | translate }}-->
            <!--                      </button>-->
            <!--                      &nbsp;-->
            <!--                      <img-->
            <!--                        class="file-actionConfirmtree-icon"-->
            <!--                        (click)="openModalCategory($event, node)"-->
            <!--                        id="addCategoryButton"-->
            <!--                        *ngIf="!node.data.size && canDefineCategory()"-->
            <!--                        src="../../../assets/img/svg/plus-icon.svg"-->
            <!--                      />-->
            <!--                      &nbsp;-->

            <!--                      <img-->
            <!--                        class="file-tree-icon"-->
            <!--                        (click)="openRemoveModal(node)"-->
            <!--                        *ngIf="-->
            <!--                          !node.data.phase && canRemove() && !isNodeFixed(node)-->
            <!--                        "-->
            <!--                        src="../../../assets/img/svg/trash-icon.svg"-->
            <!--                        cy-data="trash"-->
            <!--                      />-->
            <!--                      &nbsp;-->

            <!--                      <button-->
            <!--                        class="file-tree-button"-->
            <!--                        (click)="updateFile($event, node)"-->
            <!--                        id="updateFileButtonId"-->
            <!--                        *ngIf="node.data.size && canUpload()"-->
            <!--                      >-->
            <!--                        {{ 'application.commons.buttons.update' | translate }}-->
            <!--                      </button>-->
            <!--                      &nbsp;-->
            <!--                      <button-->
            <!--                        class="file-tree-button"-->
            <!--                        (click)="openModalRevision($event, node)"-->
            <!--                        id="seeVersionButtonId"-->
            <!--                        *ngIf="node.data.size && canSeeRevision()"-->
            <!--                      >-->
            <!--                        {{ 'application.commons.buttons.version' | translate }}-->
            <!--                      </button>-->

            <!--                      &nbsp;-->
            <!--                      <button-->
            <!--                        class="file-tree-button"-->
            <!--                        (click)="openModalRename($event, node)"-->
            <!--                        *ngIf="node.data.size && canSeeRename()"-->
            <!--                      >-->
            <!--                        {{ 'databook.rename' | translate }}-->
            <!--                      </button>-->

            <!--                      &nbsp;-->
            <!--                      <button-->
            <!--                        class="file-tree-button"-->
            <!--                        (click)="viewDocument($event, node)"-->
            <!--                        *ngIf="-->
            <!--                          node.data.size && node.data.name.endsWith('.pdf')-->
            <!--                        "-->
            <!--                      >-->
            <!--                        View-->
            <!--                      </button>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </ng-template>-->
            <!--              </tree-root>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <div
        style="display: none"
        class="side-download"
      >
        <div class="download-card">
          <div class="title-container">
            <label class="session-title small">
              {{ 'databook.download.documents' | translate }}
            </label>
          </div>
          <div class="download-info">
            <div class="download-info-item">
              <label class="label-small">
                {{ 'databook.document.s' | translate }}:&nbsp;
              </label>
              <label class="label-small secondary-color-text-label">
                {{ selectedNumberFiles }}
              </label>
            </div>
            <div class="download-info-item">
              <label class="label-small">
                {{ 'databook.size' | translate }}: &nbsp;
              </label>
              <label class="label-small secondary-color-text-label">
                {{ selectedFilesSize / 1000000 }} (MB)
              </label>
            </div>
          </div>

          <div class="download-buttons">
            <button
              type="button"
              class="btn btn-outline button-tertiary"
              (click)="cancelDownload()"
            >
              {{ 'databook.download.cancel' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline button-primary"
              (click)="download()"
              *ngIf="canDownload()"
            >
              {{ 'databook.download' | translate }}
            </button>
          </div>
          <button
            type="button"
            class="btn btn-outline button-secondary"
            style="flex: 1; width: 100%; margin-top: 8px"
            (click)="openSendEmailModal()"
            *ngIf="canDownload()"
            id="sendByEmailModalButtonId"
          >
            <clr-icon
              style="margin-right: 15px"
              class="is-solid"
              shape="envelope"
            ></clr-icon>
            {{ 'databook.send.by.email' | translate }}
          </button>
        </div>

        <div
          class="download-card-transparent"
          *ngIf="(canMerge() && !extraDocuments) || canUpload()"
          style="text-align: center"
        >
          <button
            type="button"
            class="btn btn-outline button-primary"
            *ngIf="false"
            (click)="openConfirmCopy()"
          >
            {{ 'application.commons.text.copy.files' | translate }}
          </button>

          <button
            type="button"
            class="btn btn-outline button-primary"
            *ngIf="canMerge() && !extraDocuments"
            (click)="openConfirmMerge()"
            id="mergeFilesButtonId"
          >
            {{ 'application.commons.text.merge' | translate }}
          </button>
        </div>

        <div
          class="download-card-transparent"
          *ngIf="mergedExists && canDownload() && !extraDocuments"
          style="text-align: center"
        >
          <button
            type="button"
            class="btn btn-outline button-primary"
            (click)="downloadMergedFilesArchive()"
          >
            {{ 'application.commons.download.merged' | translate }} ({{
              mergedExists
            }})
          </button>
        </div>

        <div
          class="download-card-transparent"
          *ngIf="extraDocuments"
          style="text-align: center"
        >
          <button
            type="button"
            class="btn btn-outline button-primary"
            (click)="transferDoc()"
          >
            {{ 'application.commons.transfer' | translate }}
          </button>
        </div>

        <div
          class="download-card-transparent"
          *ngIf="canUpload() && false"
          style="text-align: center"
        >
          <button
            type="button"
            class="btn btn-outline button-primary"
            (click)="openRemoveListFiles()"
          >
            {{ 'application.restore.remove.files' | translate }}
          </button>
        </div>
      </div>
      <!--      <a class="nav-link nav-icon-text"  (click)="updatedatetime()" hidden="true">-->
      <!--        <clr-icon shape="file-group"></clr-icon>-->
      <!--        <span class="nav-text">Update</span>-->
      <!--      </a>-->
    </div>
  </div>
</vic-base-page>

<clr-modal
  [clrModalOpen]="actionCategory !== 'none'"
  [clrModalSize]="'lg'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
  *ngIf="formInitialized"
>
  <div class="modal-body">
    <form
      clrForm
      class="vic-form-group"
      [formGroup]="databookForm"
    >
      <clr-input-container>
        <label class="clr-col-12 clr-col-md-2">{{
          'databook.category' | translate
        }}</label>
        <input
          clrInput
          class="clr-col-12 clr-col-md-10"
          formControlName="category"
          type="text"
          id="categoryDescriptionId"
          (keypress)="onlyValidCharacters($event)"
        />

        <clr-control-error>
          <span *ngIf="category.invalid && category.touched">
            {{ 'databook.category.error' | translate }}
          </span>
        </clr-control-error>
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelCategory()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      id="saveCategoryButtonId"
      (click)="addCategory()"
    >
      {{ 'application.commons.buttons.add' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionUpload !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
  class="clr-col-xl-4 clr-col-lg-6 clr-col-md-8 clr-col-12"
>
  <div class="modal-body">
    <div class="clr-col-12">
      <label class="session-title">{{ 'databook.upload' | translate }}</label>
      <ngx-dropzone
        (change)="onFilesAdded($event.addedFiles)"
        #dropzone
        [multiple]="multipleFiles"
      >
        <ngx-dropzone-label>
          {{ 'application.commons.buttons.drag.drop.upload' | translate }}
        </ngx-dropzone-label>
      </ngx-dropzone>
    </div>
    <div class="clr-row">
      <div class="clr-col-12">
        <div
          *ngIf="
            treeviewStore.filesAddedForUpload &&
            treeviewStore.filesAddedForUpload.length
          "
          style="overflow-x: scroll"
        >
          <clr-datagrid style="height: 300px">
            <clr-dg-column [style.width]="auto">
              {{ 'databook.file.name' | translate }}
            </clr-dg-column>
            <clr-dg-column [style.width.px]="100">
              {{ 'databook.file.size' | translate }} (MB)</clr-dg-column
            >
            <clr-dg-column [style.width]="auto">
              <pre style="margin: 0px; border: none">{{
              'databook.description' | translate
            }} <b style="color: red;">*</b></pre>
            </clr-dg-column>
            <clr-dg-column [style.width.px]="50">{{
              'databook.document.code' | translate
            }}</clr-dg-column>
            <clr-dg-column [style.width.px]="50">
              <pre style="margin: 0px; border: none">{{
              'databook.revision' | translate
            }} <b style="color: red;">*</b></pre>
            </clr-dg-column>

            <clr-dg-column [style.width.px]="20"></clr-dg-column>

            <clr-dg-row
              *clrDgItems="let file of treeviewStore.filesAddedForUpload"
            >
              <clr-dg-cell
                >{{ file.name | slice: 0 : 80
                }}{{ file.name.length > 80 ? '...' : '' }}</clr-dg-cell
              >
              <clr-dg-cell>{{ file.size / 1000000 }}</clr-dg-cell>
              <clr-dg-cell>
                <input
                  class="clr-col-12 clr-col-md-10"
                  [(ngModel)]="file.description"
                  type="text"
                  (keypress)="onlyValidCharacters($event)"
                />
              </clr-dg-cell>

              <clr-dg-cell>
                <input
                  class="clr-col-12 clr-col-md-10"
                  [(ngModel)]="file.document_code"
                  type="text"
                  (keypress)="onlyValidCharacters($event)"
                  name="documentCode"
                />
              </clr-dg-cell>

              <clr-dg-cell>
                <input
                  class="clr-col-12 clr-col-md-10"
                  [(ngModel)]="file.revision"
                  type="number"
                  (keypress)="onlyValidCharacters($event)"
                  name="documentRevision"
                />
              </clr-dg-cell>
              <clr-dg-cell>
                <img
                  class="file-tree-icon"
                  (click)="delete(file.name)"
                  src="../../../assets/img/svg/trash-icon.svg"
                />
              </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination
                #paginationTaskDetails
                [clrDgPageSize]="10"
              >
                <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                  {{
                    'application.commons.grid.footer.pagination.page.size'
                      | translate
                  }}</clr-dg-page-size
                >
                {{ paginationTaskDetails.firstItem + 1 }} -
                {{ paginationTaskDetails.lastItem + 1 }}
                {{
                  'application.commons.grid.footer.pagination.of' | translate
                }}
                {{ paginationTaskDetails.totalItems }}
                {{
                  'application.commons.grid.footer.pagination.records'
                    | translate
                }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
  <div class="modal-footer">
    <button
      type="button"
      class="button-secondary btn btn-outline"
      (click)="cancelUpload()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="button-primary btn btn-outline"
      id="uploadId"
      (click)="upload()"
    >
      {{ 'application.commons.buttons.upload' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionRevision === 'show'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  *ngIf="currentNode && revisions && revisions.length"
  [clrModalStaticBackdrop]="true"
  class="clr-col-xl-4 clr-col-lg-6 clr-col-md-8 clr-col-12"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-1">
        <b>{{ 'databook.file.name' | translate }}</b>
      </div>
      <div class="clr-col-5">
        {{ currentNode.description }}
      </div>
      <div class="clr-col-1">
        <b>{{ 'databook.size' | translate }}</b>
      </div>
      <div class="clr-col-2">{{ currentNode.size / 1000000 }} (MB)</div>
    </div>
    <div class="clr-row"></div>
    <div class="clr-row">
      <div class="clr-col-12">
        <div style="overflow-x: scroll">
          <clr-datagrid
            style="height: 300px"
            id="dataGridRevisionList"
          >
            <clr-dg-column [style.width.px]="100">{{
              'databook.revision' | translate
            }}</clr-dg-column>
            <clr-dg-column [style.width.px]="200">{{
              'databook.modification' | translate
            }}</clr-dg-column>
            <clr-dg-column [style.width.px]="650">{{
              'application.commons.text.name' | translate
            }}</clr-dg-column>
            <clr-dg-column [style.width.px]="410">{{
              'databook.responsible' | translate
            }}</clr-dg-column>
            <clr-dg-column [style.width.px]="10"></clr-dg-column>

            <clr-dg-row *clrDgItems="let rev of revisions">
              <clr-dg-cell>{{ rev.revision }}</clr-dg-cell>
              <clr-dg-cell>{{ rev.date }}</clr-dg-cell>
              <clr-dg-cell>{{ rev.originalName }}</clr-dg-cell>
              <clr-dg-cell>{{ rev.user }}</clr-dg-cell>
              <clr-dg-cell style="text-align: center">
                <img
                  class="file-tree-icon"
                  (click)="downloadRevision(rev)"
                  src="../../../assets/img/svg/download-icon.svg"
                />
              </clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>
              <clr-dg-pagination
                #paginationTaskDetails
                [clrDgPageSize]="10"
              >
                <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                  {{
                    'application.commons.grid.footer.pagination.page.size'
                      | translate
                  }}</clr-dg-page-size
                >
                {{ paginationTaskDetails.firstItem + 1 }} -
                {{ paginationTaskDetails.lastItem + 1 }}
                {{
                  'application.commons.grid.footer.pagination.of' | translate
                }}
                {{ paginationTaskDetails.totalItems }}
                {{
                  'application.commons.grid.footer.pagination.records'
                    | translate
                }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
  <div class="modal-footer">
    <button
      type="button"
      class="button-secondary btn btn-outline"
      id="cancelButtonSeeRevisionId"
      (click)="closeModalRevision()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="openRemovedFiles !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <clr-datagrid class="val-datagrid">
        <clr-dg-column [style.width.px]="200">{{
          'databook.file.name' | translate
        }}</clr-dg-column>
        <clr-dg-column [style.width.px]="200">{{
          'databook.user' | translate
        }}</clr-dg-column>
        <clr-dg-column [style.width.px]="200">{{
          'databook.email' | translate
        }}</clr-dg-column>
        <clr-dg-column [style.width.px]="200">{{
          'databook.date.time' | translate
        }}</clr-dg-column>
        <clr-dg-column [style.width.px]="200">{{
          'databook.path' | translate
        }}</clr-dg-column>

        <clr-dg-row *clrDgItems="let data of listRemovedFile">
          <clr-dg-action-overflow>
            <button
              class="action-item"
              (click)="restoreFile(data)"
            >
              {{ 'application.commons.buttons.restore' | translate }}
            </button>
          </clr-dg-action-overflow>

          <clr-dg-cell>{{ data.name }}</clr-dg-cell>
          <clr-dg-cell>{{ data.whoremoved.name }}</clr-dg-cell>
          <clr-dg-cell>{{ data.whoremoved.email }}</clr-dg-cell>
          <clr-dg-cell>{{ formatDate(data.removed) }}</clr-dg-cell>
          <clr-dg-cell>{{ data.pathwhenremoved }}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination
            #paginationTaskDetails
            [clrDgPageSize]="10"
          >
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
              {{
                'application.commons.grid.footer.pagination.page.size'
                  | translate
              }}
            </clr-dg-page-size>
            {{ paginationTaskDetails.firstItem + 1 }} -
            {{ paginationTaskDetails.lastItem + 1 }}
            {{ 'application.commons.grid.footer.pagination.of' | translate }}
            {{ paginationTaskDetails.totalItems }}
            {{
              'application.commons.grid.footer.pagination.records' | translate
            }}
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelRestore()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionConfirmCopy !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ 'confirm.copy.files' | translate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelCopy()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      (click)="copy()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionConfirmMerge !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ 'confirm.merge' | translate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelMerge()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      id="confirmMergeFilesButtonId"
      (click)="merge()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionConfirm !== 'none'"
  [clrModalSize]="'md'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <b>{{ 'confirm.exclusion' | translate }}</b>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelRemove()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline cofirmDelete"
      id="#cofirmDeleteId"
      (click)="removeNode()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionRename !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div
    class="modal-body"
    *ngIf="currentNode"
  >
    <div class="clr-row">
      <div class="clr-col-12">
        <clr-input-container
          style="flex-direction: row; margin-top: 15px; margin-left: 5px"
        >
          <label
            style="white-space: nowrap"
            class="label-small"
            >{{ 'description' | translate }}</label
          >
          <input
            clrInput
            [(ngModel)]="currentNode.description"
            type="text"
            size="250"
            style="margin-left: 10px; height: 50px"
            (keypress)="onlyValidCharacters($event)"
          />
        </clr-input-container>
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col-12">
        <clr-input-container
          style="flex-direction: row; margin-top: 15px; margin-left: 5px"
        >
          <label
            style="white-space: nowrap"
            class="label-small"
          >
            {{
              'description.customer'
                | translate: { customer: dataBookState.project }
            }}
          </label>
          <input
            clrInput
            [(ngModel)]="currentNode.custumerDescription"
            type="text"
            size="250"
            style="margin-left: 10px; height: 50px"
            (keypress)="onlyValidCharacters($event)"
          />
        </clr-input-container>
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col-12">
        <clr-input-container
          style="flex-direction: row; margin-top: 15px; margin-left: 5px"
        >
          <label
            style="white-space: nowrap"
            class="label-small"
            >{{ 'description.internal' | translate }}</label
          >
          <input
            clrInput
            [(ngModel)]="currentNode.internalDescription"
            type="text"
            size="250"
            style="margin-left: 10px; height: 50px"
            (keypress)="onlyValidCharacters($event)"
          />
        </clr-input-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelRename()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      (click)="confirmRename()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="openModalEmail !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <h2>{{ 'databook.documents.to.send' | translate }}</h2>
    <div
      *ngFor="let file of this.treeviewStore.selectedFiles"
      class="docs"
    >
      <strong *ngIf="file.size">{{ file.description }}</strong>
    </div>
    <div class="clr-row">
      <div class="val-card filter clr-col-6">
        <h4>{{ 'databook.add.emails' | translate }}</h4>
        <div class="clr-row align">
          <input
            [(ngModel)]="email"
            [placeholder]="'Add email:'"
            type="email"
            class="form-control add-email"
            id="emailValueInputId"
            (keyup.enter)="addEmailToList($event.target.value)"
          />

          <button
            type="button"
            class="btn btn-outline button-primary"
            id="addEmailToListButtonId"
            (click)="addEmailToList(email)"
          >
            {{ 'databook.add' | translate }}
          </button>
        </div>
      </div>
      <div class="val-card filter clr-col-6">
        <h4>Emails:</h4>
        <div
          class="clr-row"
          *ngFor="let email of listEmails"
        >
          <span
            *ngIf="listEmails"
            style="padding: 8px"
            class="label label-light-blue"
          >
            {{ email }}
            <clr-icon
              *ngIf="listEmails"
              (click)="deleteEmailToList(email)"
              style="margin-left: 5px; cursor: pointer"
              shape="times"
            ></clr-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <label *ngIf="this.treeviewStore.size > 7">{{
      'databook.grater.than.7mb' | translate
    }}</label>
    <button
      type="button"
      class="btn btn-outline"
      (click)="exitSendEmailModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      [clrLoading]="submitBtnState"
      type="button"
      class="btn btn-outline button-primary"
      id="sendByEmailButtonId"
      (click)="sendDocsByEmail()"
    >
      {{ 'databook.send.by.email' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="actionExchange !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <!-- DISABLED_OLD_TREEROOT 
          <tree-root
          #treeExchange
          [nodes]="nodesToExchange"
          [options]="options"
          class="file-tree"
        >
          <ng-template
            #treeNodeTemplateExchange
            let-node
            let-index="index"
          >
            <div class="file-tree">
              <span class="label-small">
                {{ node.data.name }}
              </span>
            </div>
          </ng-template>
        </tree-root> -->
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="cancelTransfer()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-outline"
      (click)="confirmTransfer()"
    >
      {{ 'application.commons.buttons.confirm' | translate }}
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="openDocumentHtmlViewModal !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div
    class="modal-body"
    style="overflow: hidden"
  >
    <div
      *ngIf="htmlFile"
      style="width: 1080px; overflow: hidden"
    >
      <!--        <div [innerHtml]="htmlFile | safeHtml"></div>-->
      <iframe
        [src]="htmlFile | safeUrl"
        (load)="coreService.hideLoading()"
      >
      </iframe>
      <!--        <object [data]="htmlFile | safeUrl" width="500" height="200"></object>-->
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeViewDocument()"
    >
      CLOSE
    </button>
  </div>
</clr-modal>

<clr-modal
  [clrModalOpen]="openDocumentPdfViewModal !== 'none'"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <ng-container *ngIf="documentFile">
      <!--      <pdf-viewer-->
      <!--        [src]="documentFile"-->
      <!--        [render-text]="true"-->
      <!--        [original-size]="true"-->
      <!--        [fit-to-page]="true"-->
      <!--        [external-link-target]="'blank'"-->
      <!--        (error)="viewDocumentOnError($event)"-->
      <!--        (on-progress)="viewDocumentOnProgress($event)"-->
      <!--        (after-load-complete)="coreService.hideLoading()"-->
      <!--      ></pdf-viewer>-->

      <iframe
        [src]="documentFile | safeUrl"
        (load)="coreService.hideLoading()"
      >
      </iframe>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeViewDocument()"
    >
      CLOSE
    </button>
  </div>
</clr-modal>
