import { StatusPanelDef } from 'ag-grid-community';

export const DATABOOK_FILES_STATUS_BAR: {
  statusPanels: StatusPanelDef[];
} = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      statusPanelParams: {
        aggFuncs: ['count'],
      },
    },
    {
      statusPanel: 'agFilteredRowCountComponent',
      statusPanelParams: {
        aggFuncs: ['count'],
      },
    },
    {
      statusPanel: 'agSelectedRowCountComponent',
      statusPanelParams: {
        aggFuncs: ['count'],
      },
    },
  ],
};
