<vic-base-page page="pull-off">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <!-- <div *ngIf="!isAshDensityVisible()"> {{unauthorized()}}</div> -->

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            *ngIf="shouldShowColumn('Sample_Date')"
            [style.width.px]="200"
            >{{ 'data.book.sample.date' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Shift')"
            [style.width.px]="150"
            >{{ 'data.book.shift' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Material')"
            [style.width.px]="150"
            >{{ 'data.book.material' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Raw_Material_Lot_Number')"
            [style.width.px]="190"
            >{{
              'data.book.raw.material.lot.number' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Remarks')"
            [style.width.px]="150"
            >{{ 'data.book.remarks' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Pipe_Num')"
            [style.width.px]="150"
            >{{ 'data.book.pipe.num' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Undercoating_Scope')"
            [style.width.px]="150"
            >{{
              'data.book.test.undercoating.scope' | translate
            }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Pull_Rate_mpa_s')"
            [style.width.px]="150"
            >{{ 'data.book.test.pull.rate.mps' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Thickness')"
            [style.width.px]="150"
            >{{ 'data.book.test.thickness' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Specimem')"
            [style.width.px]="150"
            >{{ 'data.book.test.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Results')"
            [style.width.px]="250"
            >{{ 'data.book.results' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Status')"
            [style.width.px]="150"
            >{{ 'data.book.status' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Quality_Certificate_Num')"
            [style.width.px]="200"
            >{{
              'data.book.quality.certificate.num' | translate
            }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell
              *ngIf="shouldShowColumn('Sample_Date')"
              type="date"
              >{{ data['Sample_Date'] }}</clr-dg-cell
            >
            <clr-dg-cell *ngIf="shouldShowColumn('Shift')">{{
              data['Shift']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Material')">{{
              data['Material']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Raw_Material_Lot_Number')">{{
              data['Raw_Material_Lot_Number']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Remarks')">{{
              data['Remarks']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Num')">{{
              data['Pipe_Num']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Undercoating_Scope')">{{
              data['Undercoating_Scope']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pull_Rate_mpa_s')">{{
              data['Pull_Rate_mpa_s']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Thickness')">{{
              data['Thickness']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Specimem')">{{
              data['Specimem']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Results')">{{
              data['Results']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Status')">{{
              data['Status']
            }}</clr-dg-cell>
            <clr-dg-cell
              *ngIf="shouldShowColumn('Quality_Certificate_Num')"
              type="date"
              >{{ data['Quality_Certificate_Num'] }}</clr-dg-cell
            >
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</vic-base-page>
