export const tableHeads = [
  { field: 'name', title: 'databook.new.tpi.digital.workflow.name' },
  { field: 'project_name', title: 'databook.document.project' },
  { field: 'product_name', title: 'databook.document.product' },
  { field: 'document_description', title: 'databook.document.name' },
  { field: 'document_revision', title: 'databook.document.revision' },
  { field: 'action', title: 'databook.file.status' },
  { field: 'custom', title: 'databook.custom' },
  { field: 'document_code', title: 'databook.document.code' },
  { field: 'created_by', title: 'databook.uploaded.by' },
  { field: 'created_on', title: 'databook.uploaded.on' },
  { field: 'shakeholder', title: 'databook.document.pending.action.by' },
  { field: 'deadline', title: 'databook.document.deadline' },
  { field: 'comment', title: 'databook.lastComment' },
  { field: '', title: '' },
];

export type TableTypes =
  | 'PENDING_TABLE'
  | 'ALL_DOCUMENTS'
  | 'PROJECT_DOCUMENTS';

export const disableButton = {
  APPROVE: (selectedDocumentStatus): boolean =>
    ({
      'Storage Pending': true,
      'Acknowledge Rejection': true,
      Rejected: true,
      Stored: true,
      Canceled: true,
    })[`${selectedDocumentStatus}`],
  REPROVE: (selectedDocumentStatus): boolean =>
    ({
      'Storage Pending': true,
      'Acknowledge Rejection': true,
      Rejected: true,
      Stored: true,
      Canceled: true,
    })[`${selectedDocumentStatus}`],
  STORE: (selectedDocumentStatus): boolean =>
    ({
      'Acknowledge Rejection': true,
      Approved: true,
      Rejected: true,
      Stored: true,
      'Awaiting signature': true,
      Canceled: true,
    })[`${selectedDocumentStatus}`],
  ACKNOWLEDGE: (selectedDocumentStatus): boolean =>
    ({
      'Storage Pending': true,
      // 'Acknowledge Rejection': true,
      Approved: true,
      Rejected: true,
      Stored: true,
      'Awaiting signature': true,
      Canceled: true,
    })[`${selectedDocumentStatus}`],
  TRANSFER: (selectedDocumentStatus): boolean =>
    ({
      'Acknowledge Rejection': true,
      Rejected: true,
      Stored: true,
      Canceled: true,
    })[`${selectedDocumentStatus}`],
  CANCEL: (selectedDocumentStatus): boolean =>
    ({
      'Acknowledge Rejection': true,
      Rejected: true,
      Stored: true,
      Canceled: true,
    })[`${selectedDocumentStatus}`],
};

export const setActionStatus = {
  SEND_TO: 'Awaiting signature',
  BACK_RELEASER_APPROVAL: 'Storage Pending',
  BACK_RELEASER_REJECT: 'Acknowledge Rejection',
  APPROVE: 'Approved',
  REJECTED: 'Rejected',
  CANCEL: 'Canceled',
  STORE: 'Stored',
  ACKNOWLEDGE: 'Rejected',
};

export const actions: {
  BACK_RELEASER_APPROVAL: string;
  BACK_RELEASER_REJECT: string;
  SEND_TO: string;
  TRANSFER: string;
} = {
  BACK_RELEASER_APPROVAL: 'BACK_RELEASER_APPROVAL',
  BACK_RELEASER_REJECT: 'BACK_RELEASER_REJECT',
  SEND_TO: 'SEND_TO',
  TRANSFER: 'TRANSFER',
};

export const tables = {
  PENDING_TABLE: {
    filters: {
      project: true,
      pending: true,
    },
    actions: [
      actions.BACK_RELEASER_APPROVAL,
      actions.BACK_RELEASER_REJECT,
      actions.SEND_TO,
      actions.TRANSFER,
    ],
  },
  ALL_DOCUMENTS: {
    filters: {
      project: false,
    },
    actions: [],
  },
  PROJECT_DOCUMENTS: {
    filters: {
      project: true,
    },
    actions: [],
  },
};
