<div class="search-container">
  <div class="search-box">
    <div class="search-icon">
      <clr-icon
        shape="search"
        *ngIf="!searchText"
        style="flex: 1 0"
      ></clr-icon>
      <clr-icon
        shape="times"
        *ngIf="searchText"
        style="cursor: pointer; flex: 1 0"
        (click)="onClearClick()"
      ></clr-icon>
    </div>
    <input
      class="search-input"
      type="text"
      [placeholder]="'application.commons.buttons.search' | translate"
      [(ngModel)]="searchText"
      (ngModelChange)="onSearchChange()"
    />
  </div>
  <div
    *ngIf="this.whichPage === 'databook'"
    class="search-advise"
    [ngClass]="{ hidden: !searchAdviseVisible }"
  >
    {{ 'databook.search.condition.desc' | translate }}
  </div>
</div>
