<div
  #pipeNavigatorContextMenu
  class="context-menu"
  [ngStyle]="{
    top: treeviewStore.position.y + 'px',
    left: treeviewStore.position.x + 'px'
  }"
  [ngStyle]="{
    visibility: treeviewStore.contextMenuIsVisible ? 'visible' : 'hidden'
  }"
>
  <ul *ngIf="treeviewStore.contextMenuIsVisible">
    <li
      *ngIf="treeviewStore.objectPointedOnContextMenu.folder_id !== undefined"
      (click)="treeviewStore.onMenuItemClick($event, 'View')"
    >
      <div>
        <img src="{{ treeviewStore.viewDocument }}" /><span>{{
          'databook.treeview.viewDocument' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="
        canDownload &&
        treeviewStore.objectPointedOnContextMenu.folder_id !== undefined
      "
      (click)="treeviewStore.onMenuItemClick($event, 'Download')"
    >
      <div>
        <img src="{{ treeviewStore.download }}" /><span>{{
          'databook.treeview.download' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="treeviewStore.objectPointedOnContextMenu.folder_id !== undefined"
      (click)="treeviewStore.onMenuItemClick($event, 'Download version')"
    >
      <div id="seeVersions">
        <img src="{{ treeviewStore.version }}" /><span>{{
          'databook.treeview.version' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="
        canDownload &&
        treeviewStore.objectPointedOnContextMenu.folder_id !== undefined
      "
      (click)="treeviewStore.onMenuItemClick($event, 'Download compressed')"
    >
      <div id="downloadZipFile">
        <img src="{{ treeviewStore.zip }}" /><span>{{
          'databook.treeview.zip' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="
        canUpload &&
        treeviewStore.objectPointedOnContextMenu.folder_id !== undefined
      "
      (click)="treeviewStore.onMenuItemClick($event, 'Update file')"
    >
      <div id="updateFile">
        <img src="{{ treeviewStore.updateFile }}" /><span>{{
          'databook.treeview.updateFile' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="canUpload"
      (click)="treeviewStore.onMenuItemClick($event, 'Copy')"
    >
      <div>
        <img src="{{ treeviewStore.copy }}" /><span>{{
          'databook.treeview.copy' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="canUpload"
      (click)="treeviewStore.onMenuItemClick($event, 'Cut')"
    >
      <div>
        <img src="{{ treeviewStore.cut }}" /><span>{{
          'databook.treeview.cut' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="canUpload && treeviewStore.clipboard !== null"
      (click)="treeviewStore.onMenuItemClick($event, 'Paste')"
    >
      <div>
        <img src="{{ treeviewStore.paste }}" /><span>{{
          'databook.treeview.paste' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="
        canUpload &&
        treeviewStore.objectPointedOnContextMenu.folder_id === undefined
      "
      (click)="treeviewStore.onMenuItemClick($event, 'Add Folder')"
    >
      <div>
        <img src="{{ treeviewStore.addFolder }}" /><span>{{
          'databook.treeview.addFolder' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="
        canUpload &&
        treeviewStore.objectPointedOnContextMenu.folder_id === undefined
      "
      (click)="treeviewStore.onMenuItemClick($event, 'Upload File')"
    >
      <div id="addFile">
        <img src="{{ treeviewStore.addFile }}" /><span>{{
          'databook.treeview.addFile' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="canRename"
      (click)="treeviewStore.onMenuItemClick($event, 'Rename')"
    >
      <div>
        <img src="{{ treeviewStore.edit }}" /><span>{{
          'databook.treeview.edit' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="canRemove"
      (click)="deleteItem.emit()"
    >
      <div id="removeFolder">
        <img src="{{ treeviewStore.delete }}" /><span>{{
          'databook.treeview.delete' | translate
        }}</span>
      </div>
    </li>
    <li (click)="treeviewStore.onMenuItemClick($event, 'Share')">
      <div>
        <img src="{{ treeviewStore.share }}" /><span>{{
          'databook.treeview.share' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="treeviewStore.objectPointedOnContextMenu.folder_id !== undefined"
      (click)="treeviewStore.onMenuItemClick($event, 'Email')"
    >
      <div id="sendByEmailModal">
        <img src="{{ treeviewStore.email }}" /><span>{{
          'databook.treeview.email' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="
        false &&
        treeviewStore.objectPointedOnContextMenu.folder_id !== undefined
      "
      class="clr-row"
      style="margin-bottom: 0"
    >
      <div
        class="clr-col-md-6"
        (click)="treeviewStore.onMenuItemClick($event, 'Up')"
      >
        <img src="{{ treeviewStore.up }}" /><span>{{
          'databook.treeview.up' | translate
        }}</span>
      </div>
      <div
        class="clr-col-md-6"
        (click)="treeviewStore.onMenuItemClick($event, 'Down')"
      >
        <img src="{{ treeviewStore.down }}" /><span>{{
          'databook.treeview.down' | translate
        }}</span>
      </div>
    </li>
    <li
      *ngIf="treeviewStore.objectPointedOnContextMenu.folder_id === undefined"
      (click)="treeviewStore.onMenuItemClick($event, 'Select all')"
    >
      <div>
        <img src="{{ treeviewStore.selectAll }}" /><span>{{
          'databook.treeview.selectAll' | translate
        }}</span>
      </div>
    </li>
  </ul>
</div>
