import { Injectable, ViewChild } from '@angular/core';
declare let LacunaSignerWidget: any;

@Injectable()
export class DataBookState {
  forceShow = false;
  reopen;
  approved;
  participants;
  projects;
  project;
  projectGsi;
  productGsi;
  projectName;
  entireProject;
  projectCode;
  urlCode;
  dataSheet;
  product;
  productId;
  databookInterval = [];
  productDsc;
  dashboard;
  material;
  materials;
  documents;
  document;
  pendencies;
  projectInfo;
  olddataexplorerintegration;
  oldDataAnalysis;
  olddataexplorerintegrationByProduct;
  stepAction;
  acknowledgeComment;
  idStepInstances;
  cardSelection;

  module = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // ['blockquote', 'code-block'],

      // [{ header: 1 }, { header: 2 }],               // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ script: 'sub'}, { script: 'super' }],      // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }],                           // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      [
        'link',
        'image',
        // , 'video'
      ], // link and image, video
    ],
  };
}
