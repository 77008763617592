import { TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const getAllFolders = async (
  dataBookService: DataBookService,
): Promise<TreeviewFolderItem[]> => {
  const params: { functionName; args: any } = {
    functionName: 'getAllFolders',
    args: {},
  };
  return await dataBookService.newLambda<TreeviewFolderItem[]>(params);
};
