import { Component, Inject, ViewChild } from '@angular/core';
import { CoreService } from '../../core/core.service';
import { BaseComponent } from '../../core/components/base.component';
import { DataBookService } from '../../services/databook.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import * as _ from 'lodash';
import { USER_STORE, UserStore } from '../../mobx-stores';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgIf } from '@angular/common';
import {
  ClrIconModule,
  ClrModalModule,
  ClrCommonFormsModule,
  ClrSelectModule,
  ClrInputModule,
  ClrTextareaModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
} from '@clr/angular';

@Component({
  selector: 'databook-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['contact-us.component.scss'],
  standalone: true,
  imports: [
    ClrIconModule,
    ClrModalModule,
    FormsModule,
    ClrCommonFormsModule,
    ReactiveFormsModule,
    ClrSelectModule,
    ClrInputModule,
    NgIf,
    ClrTextareaModule,
    NgxDropzoneModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    TranslateModule,
  ],
})
export class ContactUsComponent extends BaseComponent {
  @ViewChild('dropzone', { static: true })
  dropzone: any;
  actionContactUsModal = 'none';
  formContactUs: FormGroup;
  addedFiles = [];
  acceptImage = [
    'image/gif', // gif
    'image/jpg', // jpg
    'image/jpeg', // jpeg
    'image/png', // png
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx,
    'application/pdf', // pdf
  ];
  environment: any = {};
  constructor(
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public formBuilder: FormBuilder,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(coreService);
    this.environment = this.coreService.getConfig().environment.frontVersion;
    this.formContactUs = this.formBuilder.group(
      {
        subject: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        message: new FormControl('', Validators.required),
        version: new FormControl(this.environment),
        steps: new FormControl(''),
        obtainedResult: new FormControl(''),
        attendedResult: new FormControl(''),
      },
      { validators: this.customValidator },
    );
  }
  customValidator(form: FormGroup): ValidationErrors | null {
    const steps = form.get('steps').value;
    const obtained = form.get('obtainedResult').value;
    const attended = form.get('attendedResult').value;
    const message = form.get('message').value;

    if (steps && obtained && attended) {
      return null;
    } else if (message) {
      return null;
    } else {
      return { incomplete: true };
    }
  }
  isTypeValid() {
    const type = this.formContactUs.get('type').value;
    if (type && type.trim().length > 0) {
      return true;
    }
    return false;
  }
  isReportABug() {
    const type = this.formContactUs.get('type').value;
    return type === 'db.contact.us.report.a.bug';
  }
  delete(name) {
    const files = _.filter(this.addedFiles, (item) => item.name !== name);
    this.addedFiles = files;
  }

  async onFilesAdded(files) {
    this.coreService.showLoading();
    let allSizeAccepted = true;
    let allSizeMaxAccepted = true;
    let allFilesAccepted = true;

    if (this.addedFiles.length + files.length > 3) {
      this.coreService.showAlertErrorTranslate(
        'databook.contact.us.length.problem',
      );
      this.coreService.hideLoading();
      return;
    }

    for (const file of files) {
      if (!this.acceptImage.includes(file.type)) {
        allFilesAccepted = false;
        continue;
      }

      if (file.size < 1500) {
        allSizeAccepted = false;
        continue;
      }

      if (file.size > 5242880) {
        allSizeMaxAccepted = false;
        continue;
      }

      this.addedFiles.push(file);
      await this.readFile(file);
      file.fileName = file.name;
      file.contentBase64 = this.arrayBufferToBase64(file.content);
    }

    if (!allSizeMaxAccepted) {
      this.coreService.showAlertErrorTranslate(
        'databook.contact.us.size.problem',
      );
    }

    if (!allSizeAccepted) {
      this.coreService.showAlertErrorTranslate('databook.size.problem');
    }

    if (!allFilesAccepted) {
      this.coreService.showAlertErrorTranslate(
        'databook.content.type.problem.image',
      );
    }

    this.coreService.hideLoading();
  }

  async readFile(file) {
    const content = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: ProgressEvent) => {
        resolve((e.target as FileReader).result);
      };
    });
    const key = 'content';
    file[key] = content;
  }

  changeType() {
    const type = this.formContactUs.get('type').value;
    let message = '';
    if (type === 'db.contact.us.contact.us') {
      message = 'Send a kind message to IT team ...';
    } else if (type === 'db.contact.us.feedback.improvement.idea') {
      message = 'You can share here your idea ...';
    } else if (type === 'db.contact.us.project.creation.request') {
      message = `Project name:
Customer:
PO date:
(VSB) PO number:
Owner:
Users
User mail:
Company:
Features (Data analysis, Data Explorer, Databook, e-Doc, Digital TPI) & Read/Write:`;
    } else if (type === 'db.contact.us.question') {
      message = 'Ask your question ...';
    } else if (type === 'db.contact.us.user.access.request') {
      message = `User(s) mail:
Project:
Product:
Features (Data analysis, Data Explorer, Databook, e-Doc, Digital TPI) & Read/Write:`;
    } else if (type === 'db.contact.us.report.a.bug') {
      message = `Steps to reproduce:
Observed:
Expected:`;
    }

    this.formContactUs.get('message').setValue(message);
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  async contactUs() {
    this.coreService.showLoading();
    const files = [];
    for (const item of this.addedFiles) {
      files.push({ name: item.fileName, content: item.contentBase64 });
    }
    const params = {
      subject: this.formContactUs.get('subject').value,
      message:
        this.formContactUs.get('type').value === 'db.contact.us.report.a.bug'
          ? `Version: ${this.formContactUs.get('version').value}
     Obtained result: ${this.formContactUs.get('obtainedResult').value}
      Attended result: ${this.formContactUs.get('attendedResult').value}`
          : this.formContactUs.get('message').value,
      type: this.coreService.translate(
        this.formContactUs.get('type').value,
        null,
      ),
      email: this.userStore.user.email,
      user: this.userStore.user.firstName + ' ' + this.userStore.user.lastName,
      getSigned: files,
    };
    try {
      const result = await this.dataBookService.contactUs(params);
      if (result.ok) {
        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );
      } else {
        await this.coreService.showAlertErrorTranslate(
          'db.contact.us.count.max.reached',
        );
      }
      this.formContactUs.reset();
    } catch (error) {
      console.log(error);
      this.coreService.showAlertErrorTranslate(
        'application.commons.error.message.server.error',
      );
    }
    this.coreService.hideLoading();
    this.actionContactUsModal = 'none';
  }

  openContactUsModal() {
    this.addedFiles = [];
    this.actionContactUsModal = 'open';
  }

  closeContactUsModal() {
    this.actionContactUsModal = 'none';
    this.formContactUs.reset();
  }
}
