import { Type } from 'class-transformer';
import { Product } from './product.class';
import { TreeviewFileItem } from './treeview-item.class';

export class MergeRequest {
  id: number;

  s3key = '';

  creation_date: any;
  execution_date: any;

  @Type(() => Product)
  product?: Product;

  product_id: number;
  merging = false;

  user_email: string;

  @Type(() => TreeviewFileItem)
  files: TreeviewFileItem[];
}
