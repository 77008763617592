import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const findProductFromId = async (
  dataBookService: DataBookService,
  id: number,
): Promise<Product | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'findProductFromId',
    args: {
      id,
    },
  };
  return await dataBookService.newLambda<Product | undefined>(params);
};
