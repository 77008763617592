import { Product } from './product.class';
import { DataExplorerTestType } from './data-explorer-test-type.class';

export class DataExplorerUploadTest {
  id: number;
  user: string;
  s3_path: string;
  file_name: string;
  created_on: string;
  test_type: DataExplorerTestType;
  product: Product;
  data: string;
}
