import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const removeFeatureFromProductFromFeatureId = async (
  dataBookService: DataBookService,
  productId: number,
  featureId: number,
): Promise<Product> => {
  const params: { functionName: string; args: any } = {
    functionName: 'removeFeatureFromProductFromFeatureId',
    args: {
      productId,
      featureId,
    },
  };
  return await dataBookService.newLambda<Product>(params);
};
