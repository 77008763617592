import { Component } from '@angular/core';
import { CoreService } from '../../core/core.service';
import { ClrCommonFormsModule } from '@clr/angular';

@Component({
  selector: 'databook-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['filter.component.scss'],
  standalone: true,
  imports: [ClrCommonFormsModule],
})
export class FilterComponent {
  constructor(public coreService: CoreService) {}
}
