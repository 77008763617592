import { HttpClient, HttpEventType } from '@angular/common/http';

export async function fetchS3Content({
  preSignedUrl,
  httpClient,
  onDownloadProgress,
}: {
  preSignedUrl: string;
  httpClient: HttpClient;
  onDownloadProgress?: (loadProgress: number) => void;
}) {
  return new Promise<string>(
    (resolve: CallableFunction, reject: CallableFunction) => {
      httpClient
        .get(preSignedUrl, {
          responseType: 'text',
          reportProgress: true,
          observe: 'events',
        })
        // TODO move to rxjs
        .subscribe(
          (event) => {
            if (
              event.type === HttpEventType.DownloadProgress &&
              onDownloadProgress
            ) {
              onDownloadProgress(
                Math.round((100 * event.loaded) / event.total),
              );
            } else if (event.type === HttpEventType.Response) {
              resolve(event.body);
            }
          },
          (error) => {
            const domParser = new DOMParser();
            const dom = domParser.parseFromString(error?.error, 'text/xml');

            error.message =
              dom.getElementsByTagName('Message')[0].childNodes[0].nodeValue;

            reject(error);
          },
        );
    },
  );
}
