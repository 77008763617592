import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from 'src/app/components/pdf/pdf.service';
import { CoreService } from 'src/app/core/core.service';
import { DataBookService } from 'src/app/services/databook.service';
import { DataBookState } from 'src/app/services/databook.state';
import { DataBookBaseComponent } from '../databook-base.component';
import { v1 as uuid } from 'uuid';
import * as _ from 'lodash';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../mobx-stores';
import { MySignaturesComponent } from './component/my-signatures.component';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';
declare let LacunaWebPKI: any;

@Component({
  selector: 'my-signatures-mgmt',
  templateUrl: './my-signatures-mgmt.component.html',
  styleUrls: ['./my-signatures-mgmt.component.scss'],
  standalone: true,
  imports: [BasePageComponent, BreadcrumbComponent, MySignaturesComponent],
})
export class MySignaturesMgmtComponent extends DataBookBaseComponent {
  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    public formBuilder: FormBuilder,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
  }

  configBreadCrumb() {
    this.breadcrumbCurrent = this.coreService.translate('My Signatures', null);
    this.breadcrumb = [
      {
        name: this.coreService.translate('Parameters', null),
        link: '/main/project',
      },
    ];
  }
}
