import { DataBookService } from '../../services/databook.service';
import { TreeviewItemContext } from '../treeview-item.class';
import { featureLabelsMap, featureSlugMap } from '../constants';

export class GlobalSearchResult {
  features: ProductsFeaturesList[];
  databookItems: SearchTreeviewItemReturn[];
}

export class ProductsFeaturesList {
  projectName: string;
  projectSlug: string;
  projectGsiId: string;
  productName: string;
  productSlug: string;
  productGsiId: string;
  feature: string;

  get label(): string {
    return featureLabelsMap.get(this.feature);
  }

  get slug(): string {
    return featureSlugMap.get(this.feature);
  }
}

export class SearchTreeviewItemReturn {
  id: number;
  name: string;
  type: 'folder' | 'file';
  isPdf = false;
  productId?: number;
  productName?: string;
  productSlug?: string;
  projectName?: string;
  projectSlug?: string;
  context?: TreeviewItemContext;
  parents?: string;
}

export const searchItem = async (
  dataBookService: DataBookService,
  productId: number,
  q: string,
  context: TreeviewItemContext,
): Promise<SearchTreeviewItemReturn[]> => {
  const params: { functionName; args: any } = {
    functionName: 'searchTreeviewItem',
    args: {
      q,
      productId,
      context,
    },
  };
  return await dataBookService.newLambda<SearchTreeviewItemReturn[]>(params);
};

export const searchItemGlobal = async (
  dataBookService: DataBookService,
  projectsIds: number[],
  q: string,
): Promise<GlobalSearchResult> => {
  const params: { functionName; args: any } = {
    functionName: 'searchTreeviewItemGlobal',
    args: {
      q,
      projectsIds,
    },
  };
  return await dataBookService.newLambda<GlobalSearchResult>(params);
};
