import { Product } from '../product.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const addProduct = async (
  dataBookService: DataBookService,
  name: string,
  description: string,
  projectId: number,
  weight: number,
  olddataexplorerintegration: boolean,
  merging: boolean,
  active: boolean,
  deleted: boolean,
  supplier: string,
  selectedProduct: string,
  old_id = '',
): Promise<Product | undefined> => {
  const params: { functionName: string; args: any } = {
    functionName: 'addProduct',
    args: {
      name,
      description,
      projectId,
      weight,
      olddataexplorerintegration,
      merging,
      active,
      deleted,
      supplier,
      selectedProduct,
      old_id,
    },
  };
  return await dataBookService.newLambda<Product | undefined>(params);
};
