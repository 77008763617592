import {Component, Input} from '@angular/core';
import {DataBookBaseComponent} from '../databook-base.component';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import * as _ from 'lodash';
import {v1 as uuid} from 'uuid';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {
    ClrCommonFormsModule,
    ClrConditionalModule,
    ClrDatagridModule,
    ClrIconModule,
    ClrModalModule,
    ClrPopoverHostDirective,
    ClrSelectModule,
    ClrStopEscapePropagationDirective,
    ClrTabsModule,
} from '@clr/angular';
import {BreadcrumbComponent} from '@components/breadcrumb/breadcrumb.component';
import {NgFor, NgIf} from '@angular/common';
import {BasePageComponent} from '@core/components/page/base-page.component';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'upload-test',
    templateUrl: './upload-test.component.html',
    styleUrls: ['./upload-test.component.scss'],
    standalone: true,
    imports: [
        BasePageComponent,
        NgIf,
        BreadcrumbComponent,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrTabsModule,
        ClrIconModule,
        ClrConditionalModule,
        NgxDropzoneModule,
        ClrDatagridModule,
        ClrSelectModule,
        FormsModule,
        NgFor,
        ClrCommonFormsModule,
        ClrModalModule,
        TranslateModule,
    ],
})
export class UploadTestComponent extends DataBookBaseComponent {
    @Input()
    sizeText = '5 MB';

    @Input() //image/jpeg,image/jpg,image/png,image/gif
    rules = '*';

    @Input()
    rulesText = 'Excel';
    testTypes;
    fileToDelete;
    confirmDelete = 'none';
    openConfirmRegenerate = 'none';
    selectedFiles = [];
    files = [];
    uploadedFiles = [];
    messageRegenerate = '';
    fullRegenerate = false;

    accept = [
        'application/vnd.ms-excel', // .xls
        'application/vnd.ms-excel', // .xlt
        'application/vnd.ms-excel', // .xla
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // .xltx
        'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm
        'application/vnd.ms-excel.template.macroEnabled.12', // .xltm
        'application/vnd.ms-excel.addin.macroEnabled.12', // .xlam
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12', // .xlsb
    ];

    onPageInit = async () => {
        await super.initProjectProduct();
        const result = await this.dataBookService.dataExplorer({
            listTestsTypes: true,
            project: this.store.selectedProject.gsi_id,
            productId: this.dataBookState.product,
        });
        this.testTypes = result.types;
    };

    executeFullRegenerate = async () => {
        this.openConfirmRegenerate = 'none';
        this.coreService.showLoading();
        try {
            await this.dataBookService.dataExplorer({
                fullRegenerate: true,
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
            });

            for (const file of this.selectedFiles) {
                await this.dataBookService.dataExplorer({
                    regenerateFileId: file.s3_path,
                    project: this.store.selectedProject.gsi_id,
                    productId: this.dataBookState.product,
                    userName: this.coreService.getConfig().currentUser.email,
                    fileName: file.file_name,
                    testType: {description: file.testdescription, name: file.name},
                });
                this.coreService.showAlertSuccessTranslate('upload.test.success', {
                    file: file.name,
                });
            }

            this.dataBookService.dataExplorer({
                storeFinalTest: true,
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
                product: this.store.selectedProduct.gsi_id,
                userName: this.coreService.getConfig().currentUser.email,
            });

            this.uploadedFiles = await this.dataBookService.dataExplorer({
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
                listFiles: true,
            });
        } catch (error) {
            this.coreService.processError(error);
            this.coreService.hideLoading();
        }
        this.files = [];
        this.coreService.hideLoading();
    };

    executeRegenerate = async () => {
        this.openConfirmRegenerate = 'none';
        this.coreService.showLoading();
        try {
            const filesToRegenerate = [];
            for (const file of this.selectedFiles) {
                filesToRegenerate.push(file.id);
            }
            this.dataBookService.dataExplorer({
                regenerate: true,
                regenerateFileIds: filesToRegenerate,
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
                userName: this.coreService.getConfig().currentUser.email,
            });
            // this.store.gotoDashboardPage()

            await this.wait(5);

            this.coreService.showAlertSuccessTranslate(
                'upload.test.success.regenerate'
            );

            this.uploadedFiles = await this.dataBookService.dataExplorer({
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
                listFiles: true,
            });
        } catch (error) {
            this.coreService.processError(error);
            this.coreService.hideLoading();
        }
        this.files = [];
        this.coreService.hideLoading();
    };

    wait = (seconds: number) => {
        return new Promise((resolve) => {
            setTimeout(resolve, seconds * 1000);
        });
    };

    cancelRegenerate = () => {
        this.openConfirmRegenerate = 'none';
    };

    regenerateDataAnalysis = async () => {
        this.coreService.showLoading();
        try {
            await this.dataBookService.dataExplorer({
                regenerateDataAnalysis: true,
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
            });

            this.coreService.showAlertSuccessTranslate(
                'application.commons.success.operation',
            );
        } catch (error) {
            this.coreService.processError(error);
        } finally {
            this.files = [];
            this.coreService.hideLoading();
        }
    };

    regenerate = async (full: boolean) => {
        if (this.selectedFiles && this.selectedFiles.length === 0) {
            this.coreService.showAlertErrorTranslate('databook.email.no.documents');
        } else if (this.selectedFiles?.length === this.uploadedFiles?.length) {
            if (full) {
                await this.executeFullRegenerate();
            } else {
                await this.executeRegenerate();
            }
        } else {
            this.messageRegenerate = this.coreService.translate(
                'confirm.regenerate.file',
                {
                    selected: this.selectedFiles.length,
                    available: this.uploadedFiles.length,
                    difference: this.uploadedFiles?.length - this.selectedFiles?.length,
                },
            );
            this.openConfirmRegenerate = 'open';
        }
    };

    download = async (file) => {
        this.coreService.showLoading();
        try {
            const result = await this.dataBookService.dataExplorer({
                fileName: file.file_name,
                download: file.s3_path,
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
            });
            const anchor = document.getElementById(
                'downloadFiles',
            ) as HTMLAnchorElement;
            anchor.download = file.file_name;
            anchor.href = result.url;
            anchor.click();
        } catch (error) {
            this.coreService.processError(error);
        } finally {
            this.files = [];
            this.coreService.hideLoading();
        }
    };

    handleSelectedDocument = (event) => {
        console.log(event);
    };

    executeUpload = async () => {
        this.coreService.showLoading();

        const tallyFiles = _.filter(
            this.files,
            (item) => item.testType === 'Tally List',
        );
        const otherFiles = _.filter(
            this.files,
            (item) => item.testType !== 'Tally List',
        );

        if (tallyFiles.length > 0 && otherFiles.length > 0) {
            this.coreService.showAlertErrorTranslate('upload.test.error.tally.type');
            this.coreService.hideLoading();
            return;
        }

        for (const file of this.files) {
            if (!file.testType) {
                this.coreService.showAlertErrorTranslate('upload.test.error.type');
                this.coreService.hideLoading();
                return;
            }
        }

        for (const file of this.files) {
            const fileNameUploading = file.name;
            try {
                const id = uuid();
                const preSignedUrl = await this.getUrlFile(id);
                await this.callSimpleHttpPut(preSignedUrl.url, file.content);
                const uploadResult = await this.dataBookService.dataExplorer({
                    fileId: id,
                    project: this.store.selectedProject.gsi_id,
                    productId: this.dataBookState.product,
                    userName: this.coreService.getConfig().currentUser.email,
                    fileName: file.name,
                    testType: file.testType,
                });
                if (uploadResult.uploaded) {
                    this.coreService.showAlertSuccessTranslate('upload.test.success', {
                        file: fileNameUploading,
                    });
                } else {
                    this.coreService.showAlertErrorTranslate(
                        'upload.test.data.explorer.error',
                        {file: fileNameUploading},
                    );
                }
            } catch (error) {
                console.log(error);
                this.coreService.processError(error);
                this.coreService.hideLoading();
                this.coreService.showAlertErrorTranslate(
                    'upload.test.data.explorer.error',
                    {file: fileNameUploading},
                );
            }
        }

        this.dataBookService.dataExplorer({
            storeFinalTest: true,
            project: this.store.selectedProject.gsi_id,
            productId: this.dataBookState.product,
            product: this.store.selectedProduct.gsi_id,
            userName: this.coreService.getConfig().currentUser.email,
        });

        this.files = [];
        this.coreService.hideLoading();
    };

    loadFiles = async () => {
        this.coreService.showLoading();
        try {
            this.uploadedFiles = await this.dataBookService.dataExplorer({
                project: this.store.selectedProject.gsi_id,
                productId: this.dataBookState.product,
                listFiles: true,
            });
        } catch (error) {
            this.coreService.processError(error);
            this.coreService.hideLoading();
        }
        this.coreService.hideLoading();
    };

    canUpload = () => {
        if (this.currentUser?.roles?.UPLOAD_TEST) {
            return true;
        }
        return false;
    };

    getUrlFile = async (id) => {
        console.log(' get url file')
        const params = {
            path: false,
            putObject: 'upload_test',
            bucket: "BUCKET_S3_UPLOADED_FILE",
            fileId: id,
            project: this.store.selectedProject.gsi_id,
            productId: this.dataBookState.product,
        };
        this.cfgVlr(params);
        return await this.dataBookService.documentPreSignedUrl(params);
    };

    addType = (file) => {
        for (const item of this.testTypes) {
            const listExpressionByTestType = item.word_list.split('-');
            for (const eachExpressionByTestType of listExpressionByTestType) {
                const arr = eachExpressionByTestType.split(',');
                const upper = file.name.toUpperCase();
                const contain = arr.every((word) => upper.includes(word));
                if (contain) {
                    file.testType = item;
                }
            }
        }
    };

    onFilesAdded = (files: File[]) => {
        let allFilesAccepted = true;

        for (const file of files) {
            if (this.accept.includes(file.type)) {
                const existingFiles = _.filter(
                    this.files,
                    (item) => item.name === file.name,
                );
                if (existingFiles.length) {
                    continue;
                }

                this.addType(file);
                // file.testType = testTypes.

                this.files.push(file);

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = (e: ProgressEvent) => {
                    const content = 'content';
                    const content64 = 'content64';
                    file[content] = (e.target as FileReader).result;

                    const base64String = this.transformFileToBase64(file[content]);
                    file[content64] = base64String;
                };
            } else {
                allFilesAccepted = false;
            }
        }

        if (!allFilesAccepted) {
            this.coreService.showAlertErrorTranslate('databook.content.type.problem');
        }
    };

    transformFileToBase64 = (content: ArrayBuffer): string => {
        const bytes = new Uint8Array(content);
        const binaryArray = Array.from(bytes);

        const base64 = btoa(String.fromCharCode(...binaryArray));
        return base64;
    };

    onRemove = (event) => {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    };

    executeConfirmDelete = (file) => {
        this.fileToDelete = file;
        this.confirmDelete = 'open';
    };

    cancelDelete = async () => {
        this.confirmDelete = 'none';
        this.fileToDelete = null;
    };

    inactivate = async () => {
        this.coreService.showLoading();
        try {
            await this.dataBookService.dataExplorer({
                inactivate: this.fileToDelete,
            });
            this.coreService.showAlertSuccessTranslate('upload.test.success', {
                file: this.fileToDelete.name,
            });
        } catch (error) {
            this.coreService.processError(error);
        } finally {
            this.coreService.hideLoading();
        }
    };

    delete = (name) => {
        const files = _.filter(this.files, (item) => item.name !== name);
        this.files = files;
    };

    configBreadCrumb = () => {
        this.breadcrumbCurrent = 'Upload Files';
        this.breadcrumb = [
            {
                name: this.dataBookState.projectName,
                link: '/main/project',
                type: this.coreService.translate('databook.project', null),
            },

            {
                name: this.dataBookState.productDsc,
                link: '/main/product',
                type: this.coreService.translate('databook.product', null),
            },

            {
                name: this.coreService.translate('databook.dashboard', null),
                link: '/',
            },
        ];
    };

    getCondition = () =>
        !this.store.selectedProject.gsi_id || !this.dataBookState.product;

    isDev = () => {
        const devProfile = this.userStore.user?.profiles.some((item) =>
            item.includes('MAINTAINER'),
        );
        console.log('isdev==============', devProfile);
        return !!devProfile;
    };
}
