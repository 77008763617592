export const featureSlugMap = new Map<string, string>();
featureSlugMap.set('eDoc', 'e-doc');
featureSlugMap.set('materials', 'materials');
featureSlugMap.set('tpiDigital', 'new-tpi-digital');
featureSlugMap.set('dataAnalysis', 'data-analysis');
featureSlugMap.set('dataExplorer', 'data-explorer');
featureSlugMap.set('databook', 'databook');

export const featureLabelsMap = new Map<string, string>();
featureLabelsMap.set('eDoc', 'E-doc');
featureLabelsMap.set('materials', 'Materials');
featureLabelsMap.set('tpiDigital', 'New tpi digital');
featureLabelsMap.set('dataAnalysis', 'Data analysis');
featureLabelsMap.set('dataExplorer', 'Data explorer');
featureLabelsMap.set('databook', 'Databook');
