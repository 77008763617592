import { TreeviewFolderItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const editFolder = async (
  dataBookService: DataBookService,
  id: number,
  name: number,
  phase?: string,
  fixed?: string,
  description?: string,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'editFolder',
    args: {
      id,
      name,
      phase,
      fixed,
      description,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};
