<div class="filter-column-container">
  <div class="filter-label">
    <label class="clr-col label-small">{{ label }}</label>
    <button
      type="button"
      class="btn btn-icon btn-icon-card button-caret"
      aria-label="editing"
      (click)="toggleShowOptions()"
    >
      <clr-icon
        *ngIf="!showOptions"
        shape="caret down"
      ></clr-icon>
      <clr-icon
        *ngIf="showOptions"
        shape="caret up"
      ></clr-icon>
    </button>
  </div>
  <div *ngIf="showOptions">
    <input
      type="search"
      class="select-search"
      [(ngModel)]="filterValue"
      [placeholder]="'application.commons.buttons.search' | translate"
    />
    <div class="val-card-64 columns-card">
      <div class="buttons-container">
        <span
          class="label-button"
          (click)="onSelectAll()"
          >{{ 'databook.select.all' | translate }}</span
        >
        <span
          class="label-button"
          (click)="onDeselectAll()"
          >{{ 'databook.deselect' | translate }}</span
        >
      </div>
      <div class="columns-list">
        <div
          *ngFor="let column of columns"
          (click)="onClickColumn(column)"
        >
          <div
            class="filter-item"
            *ngIf="passesFilter(column)"
          >
            <input
              type="checkbox"
              [checked]="column.checked"
            />
            <label class="label-small">{{ column.description }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
