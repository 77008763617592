import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { NewTpiDigitalComponent } from '../pages/new-tpi-digital/new-tpi-digital.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { ConfigWorkflowComponent } from '../pages/new-tpi-digital/config-workflow-modal/config-workflow/config-workflow.component';
import { DataBookMgmtFlowComponent } from '../pages/new-tpi-digital/tpi-flow-mgmt/tpi-flow-mgmt.component';
import { pipeNavigatorRouteNames } from '../enums/routes.enum';
import { UsersManagementResolver } from '../pages/users-management/users-management.resolver';
import { UsersManagementComponent } from '../pages/users-management/users-management.component';

export const users = [
  {
    canActivate: [AuthenticateGuard],
    path: 'admin/users-management',
    component: UsersManagementComponent,
    data: { routeName: pipeNavigatorRouteNames.usersManagement },
    resolve: {
      data: UsersManagementResolver,
    },
  },
];
