import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputFormControlComponent } from '../../../../components/form/input-form-control/input-form-control.component';
import { NgIf } from '@angular/common';
import {
  ClrModalModule,
  ClrCommonFormsModule,
  ClrRadioModule,
  ClrIconModule,
} from '@clr/angular';

@Component({
  selector: 'workflow-step-modal',
  templateUrl: './workflow-step-modal.component.html',
  styleUrls: ['./workflow-step-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    NgIf,
    ClrCommonFormsModule,
    FormsModule,
    ReactiveFormsModule,
    InputFormControlComponent,
    ClrRadioModule,
    ClrIconModule,
    TranslateModule,
  ],
})
export class WorkflowStepModalComponent {
  @Input() isModalOpen = false;
  @Input() closeModal: () => void;
  @Input() title: {
    newWorkflow: string;
    editWorkflow: string;
    deleteWorkflow: string;
    newStep: string;
    editStep: string;
  };
  @Input() type: {
    create: boolean;
    update: boolean;
    delete: boolean;
  };

  @Input() form: FormGroup;

  @Input() deleteStepInWorkflowModal: () => void;
  @Input() editStep: () => void;

  @Input() addNewStep: () => void;

  keydownOnlyNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
