import { Project } from '../project.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const updateProject = async (
  dataBookService: DataBookService,
  id: number,
  name: string,
  code: string,
  poNumber: string,
  poDate: string,
  ownerEmail: string,
  ownerName: string,
  customer: string,
  lastModifiedBy: string,
  lastModifiedDate: string,
  description: string,
  urlCode: string,
  olddataexplorerintegration?: boolean,
  deleted?: boolean,
): Promise<Project | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'updateProject',
    args: {
      id,
      name,
      code,
      poNumber,
      poDate,
      ownerEmail,
      ownerName,
      customer,
      lastModifiedBy,
      lastModifiedDate,
      description,
      urlCode,
      olddataexplorerintegration,
      deleted,
    },
  };
  return await dataBookService.newLambda<Project | undefined>(params);
};
