import { Product } from './product.class';
import { Type } from 'class-transformer';
import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';

import 'reflect-metadata';

@Injectable({
  providedIn: 'root',
})
export class Project {
  id: number;
  code: string;
  poNumber: string;
  ownerEmail: string;
  ownerName: string;
  customer: string;
  poDate: string;
  gsi_id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  slug?: string;
  description?: string;
  olddataexplorerintegration = false;
  creation_date?: string;
  updated_date?: string;
  @observable fav = false;
  @Type(() => Product)
  @observable
  products: Product[];

  isFavorite?: boolean;

  @observable visibleInProjectList = true;
}
