export class PermissionDTO {
  id: number;
  name: string;
  featureId: number;
}

export class CreatePermissionDTO {
  name: string;
  featureId: number;
}
