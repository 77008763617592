<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-alpine"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [statusBar]="statusBar"
  [sideBar]="sideBar"
  [rowData]="rowData"
  [context]="context"
  [getContextMenuItems]="getContextMenuItems"
  [getLocaleText]="getLocaleText"
  (columnEverythingChanged)="gridColumnsChanged($event)"
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onSelectionChanged()"
  allowContextMenuWithControlKey="true"
  rowSelection="multiple"
  rowGroupPanelShow="onlyWhenGrouping"
  pivotPanelShow="onlyWhenPivoting"
/>
