import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const removeFile = async (
  dataBookService: DataBookService,
  fileId: number,
): Promise<boolean> => {
  const params: { functionName; args: any } = {
    functionName: 'removeFile',
    args: {
      fileId,
      remover_email: dataBookService.userStore.user.email,
      remover_name: dataBookService.userStore.user.name,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};
