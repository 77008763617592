import { action, computed, observable } from 'mobx-angular';
import { ElementRef, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { makeAutoObservable } from 'mobx';
import { Router, RouterStateSnapshot } from '@angular/router';
import {
  Features,
  Material,
  Product,
  Project,
  TreeviewFileItem,
  TreeviewFolderItem,
} from '../classes';
import { DataAnalysisTypesEnum, TestTypesEnum } from '../enums';
import { findProductsFromProjectId } from '../classes/product-helpers';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export type BreadCrumbItem = {
  name: string;
  link: string;
  type: string;
};

export enum DataExplorerType {
  dataExplorer = 'data-explorer',
  dataExplorerAg = 'data-explorer-ag',
  dataAnalysis = 'data-analysis',
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsStore {
  @observable dataAnalysisselectedTestType: string | null = null;
  @observable dataAnalysisselectedTestTypeProperty: string | null = null;
  @observable testType: TestTypesEnum | null = null;
  @observable dataAnalysisType: DataAnalysisTypesEnum | null = null;
  @observable file: TreeviewFileItem | undefined = undefined;
  @observable files: TreeviewFileItem[] = [];
  @observable folder: TreeviewFolderItem | undefined = undefined;
  @observable materialId: number | null = null;
  @observable material: Material | null = null;
  @observable materials: Material[] = [];
  @observable dataExplorerType: DataExplorerType | null = null;
  @observable projects: Project[] = [];
  @observable products: Product[] = [];
  @observable activePageForBreadcrumb = '';
  @observable selectedProject: Project | undefined = undefined;
  @observable selectedProduct: Product | undefined = undefined;
  @observable newProjectFieldValue = '';
  @observable newTpiSelectedType: string | null = null;
  httpClient: HttpClient;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private trans: TranslateService,
  ) {
    makeAutoObservable(this);
  }

  public translate(key: string | Array<string>, interpolateParams?: any): any {
    return this.trans.instant(key, interpolateParams);
  }

  @action
  async reloadProducts(dataBookService: any): Promise<void> {
    this.products = await findProductsFromProjectId(
      dataBookService,
      this.selectedProject.id,
      true,
    );
  }

  @action
  gotoHome(path: string): void {
    this.router.navigate(['']);
  }

  @action
  goto(path: string): void {
    this.router.navigate([path]);
  }

  @action
  gotoToProject(projectSlug: string): void {
    this.router.navigate([projectSlug]);
  }

  @action
  gotoProduct(projectSlug: string, productSlug: string): void {
    this.router.navigate([[projectSlug, productSlug].join('/')]);
  }

  @action
  gotoPage(path: string): void {
    this.router.navigate([
      [this.selectedProject.slug, this.selectedProduct.slug, path].join('/'),
    ]);
  }

  @action
  gotoDashboardPage(): void {
    this.router.navigate([
      [this.selectedProject.slug, this.selectedProduct.slug].join('/'),
    ]);
  }

  @action
  gotoMaterialChooserWithDataPageType(
    dataExplorerType: DataExplorerType,
  ): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        dataExplorerType,
      ].join('/'),
    ]);
  }

  @action
  gotoMaterialContextPage(path: string): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        this.materialId,
        path,
      ].join('/'),
    ]);
  }

  @action
  gotoDataExplorer(material: Material): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        'data-explorer',
        material.id,
      ].join('/'),
    ]);
  }

  @action
  gotoDataExplorerTestType(testType: TestTypesEnum | string): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        'data-explorer',
        this.materialId,
        testType,
      ].join('/'),
    ]);
  }

  @action
  gotoDataAnalysis(material: Material): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        'data-analysis',
        material.id,
      ].join('/'),
    ]);
  }

  @action
  gotoDataAnalysisType(dataAnalysisType: DataAnalysisTypesEnum | string): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        'data-analysis',
        this.materialId,
        dataAnalysisType,
      ].join('/'),
    ]);
  }

  @action
  gotoDataAnalysisProperty(
    dataAnalysisType: DataAnalysisTypesEnum | string,
    property: string,
  ): void {
    this.router.navigate([
      [
        this.selectedProject.slug,
        this.selectedProduct.slug,
        'data-analysis',
        this.materialId,
        dataAnalysisType,
        property,
      ].join('/'),
    ]);
  }

  @computed
  get breadcrumb(): BreadCrumbItem[] {
    const breadcrumb: BreadCrumbItem[] = [];

    if (this.selectedProject) {
      breadcrumb.push({
        name: this.selectedProject.name,
        link: `/${this.selectedProject.slug}`,
        type: 'project',
      });

      if (this.selectedProduct) {
        breadcrumb.push({
          name: this.selectedProduct.name,
          link: `/${this.selectedProject.slug}/${this.selectedProject.slug}`,
          type: 'product',
        });
      }
    }

    return breadcrumb;
  }

  showAlertSuccessTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertSuccess(translated, optionsToast);
  }

  /**
   * @description
   * Show success monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertSuccess(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.closeButton = true;
    options.positionClass = 'toast-bottom-right';
    return this.toastr.success(message, '', options).onTap;
  }

  /**
   * @description
   * Show info monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertInfoTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertInfo(translated, optionsToast);
  }

  /**
   * @description
   * Show info monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertInfo(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.positionClass = 'toast-bottom-right';
    return this.toastr.info(message, '', options).onTap;
  }

  /**
   * @description
   * Show danger monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertDangerTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertDanger(translated, optionsToast);
  }

  /**
   * @description
   * Show danger monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertDanger(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.positionClass = 'toast-bottom-right';
    return this.toastr.warning(message, '', options).onTap;
  }

  /**
   * @description
   * Show error monitoring
   *
   * @param message: key i18n to translate
   * @param optionsMessage: interpolate param(s) of message
   * @param optionsToast: specific configuration of message
   */
  showAlertErrorTranslate(
    message: string,
    optionsMessage: any = null,
    optionsToast: any = null,
  ): Observable<any> {
    const translated = this.translate(message, optionsMessage);
    return this.showAlertError(translated, optionsToast);
  }

  /**
   * @description
   * Show error monitoring
   *
   * @param message: translated message.
   * @param options: specific configuration of message
   */
  showAlertError(message: string, options: any = null): Observable<any> {
    if (!options) {
      options = {};
    }
    const keys = Object.keys(options);
    options.progressBar = keys.includes('progressBar')
      ? options.progressBar
      : true;
    options.progressAnimation = keys.includes('progressAnimation')
      ? options.progressAnimation
      : 'increasing';
    options.positionClass = 'toast-bottom-right';
    return this.toastr.error(message, '', options).onTap;
  }

  showUserMessage(message: string) {
    this.toastr.show(message, '', {
      toastClass: 'toast-user-message ngx-toastr',
      closeButton: true,
      disableTimeOut: true,
      enableHtml: true,
    });
  }
}

export const PROJECT_STORE = new InjectionToken<ProjectsStore>('ProjectsStore');
