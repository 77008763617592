<vic-base-page page="data-explorer">
  <div class="header-actions container"></div>
  <databook-breadcrumb />
  <div class="page-body-container">
    <databook-breadcrumb />

    <div class="val-card clr-col-12 clr-row-data">
      <data-explorer-header
        (exportEvent)="onClickExportToExcel()"
        (quickFilterEvent)="onUpdateQuickFilter($event)"
        [isAnyFilterPresent]="isAnyFilterPresent()"
        [isLoadingForExcel]="coreService.isLoading()"
      />
      <progress-bar
        [progress]="loadProgress"
        [error]="loadProgressError"
      />
      <div class="full-screen">
        <!-- TODO this is a quick fix but the grid should take all the height remaining on the screen (maybe use flex) -->
        <ag-grid-angular
          style="height: 60vh"
          class="ag-theme-material default-explorer"
          [gridOptions]="gridOptions"
        />
      </div>
    </div>
  </div>
</vic-base-page>
