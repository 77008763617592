import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgIf } from '@angular/common';

@Component({
  selector: 'data-explorer-cell-renderer-highlighted',
  templateUrl: './data-explorer-cell-renderer-highlighted.component.html',
  styleUrls: ['./data-explorer-cell-renderer-highlighted.component.scss'],
  preserveWhitespaces: false,
  standalone: true,
  imports: [NgIf],
})
export class DataExplorerCellRendererHighlightedComponent
  implements ICellRendererAngularComp
{
  public searchedText?: string;

  public highLightedText: string;
  public preText: string;
  public postText: string;

  agInit(params: ICellRendererParams): void {
    this.assignCellTexts(params);
  }

  refresh(params: ICellRendererParams) {
    this.assignCellTexts(params);
    return false;
  }

  private assignCellTexts(params: ICellRendererParams) {
    const quickFilter = params.api.getQuickFilter();
    const cellText = String(params.value || '');

    const isQuickFilterMaching = quickFilter && cellText.includes(quickFilter);

    if (isQuickFilterMaching) {
      const [differenceA, differenceB] = getDifference(cellText, quickFilter);
      this.preText = differenceA;
      this.postText = differenceB;
      this.highLightedText = quickFilter;
      return;
    }

    this.preText = cellText;
    this.postText = '';
    this.highLightedText = '';
  }
}

function getDifference(union: string, intersection: string) {
  const indexOfQuickFisterInValue = union.indexOf(intersection);

  return [
    union.slice(0, indexOfQuickFisterInValue),
    union.slice(indexOfQuickFisterInValue + intersection.length, union.length),
  ];
}
