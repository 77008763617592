import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';
import { ClrCommonFormsModule, ClrIconModule } from '@clr/angular';

@Component({
  selector: 'databook-filter-column',
  templateUrl: './filter-column.component.html',
  styleUrls: ['filter-column.component.scss'],
  standalone: true,
  imports: [
    ClrCommonFormsModule,
    NgIf,
    ClrIconModule,
    FormsModule,
    NgFor,
    TranslateModule,
  ],
})
export class FilterColumnComponent {
  @Input() label = 'Filter Columns';
  @Input() columns = [];
  // tslint:disable-next-line:no-output-native
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  filterValue = '';
  showOptions = true;

  onClickColumn(column) {
    column.checked = !column.checked;
    this.change.emit(true);
  }

  passesFilter(column) {
    if (column.description) {
      return column.description
        .toString()
        .toUpperCase()
        .includes(this.filterValue.toString().toUpperCase());
    } else if (column.name) {
      return column.name
        .toString()
        .toUpperCase()
        .includes(this.filterValue.toString().toUpperCase());
    }
  }

  onSelectAll() {
    this.columns = _.map(this.columns, (column) => {
      column.checked = true;
      console.log('COLUMS', this.columns);
      return column;
    });

    this.change.emit(true);
  }

  onDeselectAll() {
    this.columns = _.map(this.columns, (column) => {
      column.checked = false;
      return column;
    });

    this.change.emit(true);
  }

  toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }
}
