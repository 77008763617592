import { Product } from './product.class';
import { Type } from 'class-transformer';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Material {
  id: number;
  description: string;
  service = false;
  od: number;
  wt: number;
  grade: string;
  end_finishing?: string;
  quantity: number;
  comment: string;
  approved = false;
  unit?: string;
  revision?: string;
  material_type?: string;
  fert?: string;
  material_order?: string;
  ordered_item?: string;
  data_sheet?: string;
  last_date_update?: string;
  last_user_who_update?: string;
  customer_order_item?: string;
  customer_order?: string;
  @Type(() => Product)
  product: Product;
  datasheet?: string;
}
