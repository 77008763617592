import { TreeviewFileItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const getFileByS3key = async (
  dataBookService: DataBookService,
  s3key: string,
): Promise<TreeviewFileItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'getFileByS3key',
    args: {
      s3key,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};
