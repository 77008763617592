<vic-base-page page="new-tpi-digital">
  <div class="header-actions container">
    <a
      class="btn-link"
      id="downloadFiles"
      [hidden]="true"
    ></a>
  </div>

  <div class="page-body-container">
    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card">
      <button
        class="btn btn-primary"
        *ngIf="isWorkFlowVisible()"
        (click)="gotToConfigWorkflow()"
        id="configWfButtonId"
      >
        Config workflow
      </button>

      <button
        type="button"
        class="btn btn-primary"
        (click)="exportToExcelList()"
        id="exportButtonId"
      >
        {{ 'application.commons.buttons.excel' | translate }}
      </button>

      <div class="card-container">
        <div class="val-card clr-col-12">
          <div class="title-container">
            <label class="session-title primary-label">
              {{ 'databook.documents' | translate }}
            </label>
            <div>
              <button
                id="uploadButtonId"
                class="btn blue-color"
                (click)="upload()"
                *ngIf="isReleaseVisible()"
              >
                UPLOAD
              </button>
              <button
                id="downloadButtonId"
                class="btn blue-color"
                (click)="downloadMass()"
                [disabled]="
                  !this.selectedDocument || !this.selectedDocument?.length
                "
              >
                DOWNLOAD
              </button>
              <button
                class="btn grey-color"
                (click)="select('CANCEL', null)"
                *ngIf="isReleaseVisible()"
                [disabled]="disableButton('CANCEL')"
                id="cancelButtonId"
              >
                {{ 'CANCEL' | translate }}
              </button>
              <button
                class="btn grey-color"
                (click)="select('TRANSFER', null)"
                *ngIf="isReleaseVisible()"
                [disabled]="disableButton('TRANSFER')"
                id="transferButtonId"
              >
                {{ 'TRANSFER' | translate }}
              </button>
              <button
                class="btn warning-color"
                (click)="select('STORE', null)"
                *ngIf="isReleaseVisible()"
                [disabled]="disableButton('STORE')"
                id="storeButtonId"
              >
                {{ 'databook.store' | translate }}
              </button>
              <button
                class="btn orange-color"
                (click)="select('ACKNOWLEDGE', null)"
                *ngIf="isReleaseVisible()"
                [disabled]="disableButton('ACKNOWLEDGE')"
                id="acknowledgeButtonId"
              >
                {{ 'ACKNOWLEDGE' | translate }}
              </button>
              <button
                class="btn success-color"
                (click)="select('APPROVE', null)"
                *ngIf="isApprovalVisible()"
                [disabled]="disableButton('APPROVE')"
                id="approveButtonId"
              >
                {{ 'databook.approve' | translate }}
              </button>
              <button
                class="btn danger-color"
                (click)="select('REPROVE', null)"
                *ngIf="isApprovalVisible()"
                [disabled]="disableButton('REPROVE')"
                id="reproveButtonId"
              >
                {{ 'databook.reject' | translate }}
              </button>
              <button
                class="btn blue-color"
                id="dropHistoryIdButton"
                (click)="openHistory(this.selectedDocument[0])"
                [disabled]="
                  !this.selectedDocument || this.selectedDocument?.length !== 1
                "
              >
                {{ 'databook.history' | translate }}
              </button>
            </div>
          </div>

          <div class="search-container">
            <div class="select-search-wrapper">
              <div class="searchbar-label">
                <div class="filter-container">
                  <clr-select-container>
                    <select
                      clrSelect
                      [(ngModel)]="selectedColumn"
                      (change)="createRawDocumentsToSearch()"
                    >
                      <option
                        *ngFor="let head of tableHeads"
                        [ngValue]="head.field"
                      >
                        <label class="label-small">
                          {{ head.title | translate }}
                        </label>
                      </option>
                    </select>
                    <clr-control-helper>{{
                      'databook.select.filter' | translate
                    }}</clr-control-helper>
                  </clr-select-container>
                </div>
                <input
                  [(ngModel)]="searchBarText"
                  (ngModelChange)="handleSearchBar()"
                  clrInput
                  id="search-input"
                  placeholder="{{ 'databook.search.items' | translate }}"
                  type="text"
                  name="search"
                  [size]="25"
                />
              </div>
            </div>
          </div>

          <clr-tabs>
            <clr-tab class="tab">
              <button
                id="tabButtonPendingDocuments"
                clrTabLink
                class="tab-button title-btn"
                (click)="updateDocumentsTable('PENDING_TABLE')"
              >
                <img [src]="getSvg('icon-pending-documents')" />
                {{ 'pending.documents' | translate }}
              </button>
              <clr-tab-content *clrIfActive>
                <new-tpi-digital-table
                  id="dataGridPendingDocuments"
                  actionButtonOverflowId="Pending"
                  [selectedColumn]="selectedColumn"
                  [selectedDocument]="selectedDocument"
                  [selectedDocumentStatus]="selectedDocumentStatus"
                  [handleSelectedDocument]="handleSelectedDocument"
                  [documents]="documents"
                  [viewDocument]="viewDocument"
                  [download]="download"
                  [select]="select"
                  [disableButton]="disableButton"
                  [isReleaseVisible]="isReleaseVisible"
                  [isApprovalVisible]="isApprovalVisible"
                  [sort]="sort"
                  class="overflow-x"
                ></new-tpi-digital-table>
              </clr-tab-content>
            </clr-tab>

            <clr-tab class="tab">
              <button
                id="tabButtonAllDocuments"
                clrTabLink
                class="tab-button title-btn"
                (click)="updateDocumentsTable('ALL_DOCUMENTS')"
              >
                <img [src]="getSvg('icon-all-documents')" />
                {{ 'all.documents' | translate }}
              </button>
              <clr-tab-content *clrIfActive>
                <new-tpi-digital-table
                  id="dataGridAllDocuments"
                  actionButtonOverflowId="Project"
                  [selectedColumn]="selectedColumn"
                  [selectedDocument]="selectedDocument"
                  [selectedDocumentStatus]="selectedDocumentStatus"
                  [handleSelectedDocument]="handleSelectedDocument"
                  [documents]="documents"
                  [viewDocument]="viewDocument"
                  [download]="download"
                  [select]="select"
                  [disableButton]="disableButton"
                  [isReleaseVisible]="isReleaseVisible"
                  [isApprovalVisible]="isApprovalVisible"
                  [sort]="sort"
                  class="overflow-x"
                ></new-tpi-digital-table>
              </clr-tab-content>
            </clr-tab>

            <clr-tab class="tab">
              <button
                id="tabButtonProjectDocuments"
                clrTabLink
                class="tab-button title-btn"
                (click)="updateDocumentsTable('PROJECT_DOCUMENTS')"
              >
                <img [src]="getSvg('icon-signature')" />
                {{ 'project.document' | translate }}
              </button>
              <clr-tab-content *clrIfActive>
                <new-tpi-digital-table
                  id="dataGridProjectDocuments"
                  actionButtonOverflowId="Project"
                  [selectedColumn]="selectedColumn"
                  [selectedDocument]="selectedDocument"
                  [selectedDocumentStatus]="selectedDocumentStatus"
                  [handleSelectedDocument]="handleSelectedDocument"
                  [documents]="documents"
                  [viewDocument]="viewDocument"
                  [download]="download"
                  [select]="select"
                  [disableButton]="disableButton"
                  [isReleaseVisible]="isReleaseVisible"
                  [isApprovalVisible]="isApprovalVisible"
                  [sort]="sort"
                  class="overflow-x"
                ></new-tpi-digital-table>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </div>
      </div>
    </div>
  </div>
</vic-base-page>

<clr-modal
  #modalSesView
  [clrModalSize]="'xl'"
  [clrModalStaticBackdrop]="false"
>
  <div class="modal-body">
    <iframe
      class="responsive-iframe"
      [src]="documentFile | safeUrl"
      (load)="coreService.hideLoading()"
    >
    </iframe>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeViewDocument()"
    >
      Cancel
    </button>
    <button
      class="btn success-color text-whitesmoke-color"
      (click)="select('APPROVE', selectedViewDocument)"
      *ngIf="
        isApprovalVisible() &&
        selectedViewDocument &&
        selectedViewDocument.action === 'Awaiting signature'
      "
      [disabled]="disableButton('APPROVE', selectedViewDocument)"
    >
      {{ 'databook.approve' | translate }}
    </button>
    <button
      class="btn danger-color text-whitesmoke-color"
      (click)="select('REPROVE', selectedViewDocument)"
      *ngIf="
        isApprovalVisible() &&
        selectedViewDocument &&
        selectedViewDocument.action === 'Awaiting signature'
      "
      [disabled]="disableButton('REPROVE', selectedViewDocument)"
    >
      {{ 'databook.reject' | translate }}
    </button>
  </div>
</clr-modal>

<history-modal
  *ngIf="showSteps?.length && historyModal"
  [modalIsOpen]="showSteps?.length && historyModal"
  [closeModal]="closeHistory"
  [downloadAdditional]="downloadAdditional"
  [mergeSteps]="mergeSteps"
  [exportToExcelHistory]="exportToExcelHistory"
  [document_name]="document_name"
  [showSteps]="showSteps"
  [nextSteps]="nextSteps"
  [steps]="steps"
>
  <div class="modal-body">
    <div class="modal-header">
      <clr-icon
        shape="history"
        size="30"
      ></clr-icon>
      <h3 class="modal-title-history">TPI History</h3>
      <hr style="width: 100%" />

      <h6 class="modal-title-subtitle">{{ document_name }}</h6>
    </div>
  </div>
</history-modal>

<config-workflow-modal
  *ngIf="isConfigWorkflowOpen"
  [isConfigWorkflowOpen]="isConfigWorkflowOpen"
  [closeModal]="closeConfigWorkflow"
></config-workflow-modal>
