export const environment = {
  frontVersion: '1.4.2',
  backVersion: '1.3.0',
  infraVersion: '1.3.0',
  production: false,
  stage: 'int',
  endpoints: {
    security: 'https://a1mtqrmcwg.execute-api.eu-west-1.amazonaws.com/int',
    databook:
      'https://int-api-smartengo-pipe-navigator.aws.smartdev.vallourec.com',
    // databook: 'https://encolpij69.execute-api.eu-west-1.amazonaws.com/int',
    planning: 'https://api-qas.vallsmart.com/smart-planning',
    backbone:
      'https://int.smartengo-backbone.aws.smartdev.vallourec.com/plpp-data?orders=',
  },
  links: {
    login: 'https://int-login.aws.smartdev.vallourec.com',
  },
  cookie_domain: 'smartdev.vallourec.com',
  data_explorer_bucket:
    'https://int-pipe-navigator-artifacts.s3.eu-west-1.amazonaws.com',
};
