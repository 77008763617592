import { Material } from '../material.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const updateMaterial = async (
  dataBookService: DataBookService,
  description,
  service = false,
  od,
  wt,
  grade,
  quantity,
  comment,
  approved = false,
  productId,
  end_finishing?,
  unit?,
  revision?,
  material_type?,
  fert?,
  material_order?,
  ordered_item?,
  data_sheet?,
  last_date_update?,
  last_user_who_update?,
  customer_order_item?,
  customer_order?: string,
): Promise<Material> => {
  const params: { functionName; args: any } = {
    functionName: 'updateMaterial',
    args: {
      description,
      service,
      od,
      wt,
      grade,
      quantity,
      comment,
      approved,
      productId,
      end_finishing,
      unit,
      revision,
      material_type,
      fert,
      material_order,
      ordered_item,
      data_sheet,
      last_date_update,
      last_user_who_update,
      customer_order_item,
      customer_order,
    },
  };
  return await dataBookService.newLambda<Material>(params);
};
