import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, Observable, startWith } from 'rxjs';

const TRANSLATED_MESSAGES = new Set([
  'required',
  'default',
  'minlength',
  'pattern',
]);

@Component({
  selector: 'pn-field-error',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './field-error.component.html',
})
export class FieldErrorComponent implements OnInit {
  @Input() control: AbstractControl;

  error$: Observable<string>;

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.error$ = this.control.statusChanges.pipe(
      startWith(this.control.status),
      filter((status) => status === 'INVALID'),
      map(() => Object.keys(this.control.errors || {})[0]),
      distinctUntilChanged(),
      map((key) => (TRANSLATED_MESSAGES.has(key) ? key : 'default')),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
