import { DataBookBaseComponent } from '../databook-base.component';
import { Directive } from '@angular/core';
declare let Plotly: any;

@Directive()
export class DataAnalysisBaseComponent extends DataBookBaseComponent {
  tests;
  properties;
  filters;

  paletteColors = [];
  settings;
  errorTest;
  showTool = false;
  resizeChart = false;
  async onPageInit() {
    this.settings = this.inicializeSettings();

    try {
      this.coreService.showLoading();
      const params = {
        project: this.store.selectedProject.gsi_id,
        product: this.store.selectedProduct.gsi_id,
        productId: this.store.selectedProduct.id,
      };
      this.cfgVlr(params);
      let result;
      if (this.dataBookState.oldDataAnalysis) {
        result = await this.dataBookService.getTests(params);
      } else {
        result = await this.dataBookService.dataAnalysis({
          listTestsAndColumns: true,
          productId: this.store.selectedProduct.id,
        });
      }
      this.tests = result.tests.filter((t) => t.analysis);
      this.paletteColors = await this.dataBookService.paletteColors();
      this.coreService.hideLoading();
    } catch (error) {
      this.coreService.processError(error);
    }
    this.resizeChart = false;
    this.showTool = false;

    this.closeLoading = this.closeLoading.bind(this);

    window.addEventListener('resize', () => {
      Plotly.Plots.resize('chart');
    });
  }

  public cfgVlr(params) {
    const vlr = this.dataBookService.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  }
  getSettings() {
    return {
      responsive: true,
      displaylogo: false,
      locale: this.coreService.getLanguage(),
    };
  }

  closeLoading() {
    this.coreService.hideLoading();
  }

  renderResizeChart() {
    Plotly.Plots.resize('chart');
  }

  toggleShowTool() {
    this.showTool = !this.showTool;
    setTimeout(() => {
      this.renderResizeChart();
    }, 100);
  }

  inicializeSettings() {
    return {
      title: {
        label: '',
        fontSize: 12,
        color: '#000000',
        position: 'right',
      },
      legend: {
        labelX: '',
        labelY: '',
        show: 'true',
        positionX: 1,
        positionY: 1,
        fontSize: 12,
        color: '#000000',
        fill: 'rgba(0,0,0,0)',
      },
      tick: {
        show: 'true',
        rotateX: 30,
        rotateY: 0,
      },
      colors: ['Vallourec', 'smart'],
      fontFamily: 'Arial, sans-serif',
    };
  }

  showError(message) {
    this.coreService.hideLoading();
    this.errorTest = message;
  }

  configBreadCrumb() {
    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },

      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },

      {
        name: this.coreService.translate('databook.dashboard', null),
        link: '/',
      },
      {
        name: this.coreService.translate('databook.data.analysis', null),
        link: '/main/data-analysis',
      },
    ];
  }
  getCondition() {
    return !this.store.selectedProject.gsi_id || !this.dataBookState.product;
  }
}
