import { MenuItemDef } from 'ag-grid-community';
import { TreeviewStore } from '@mobx-stores/treeview.store';

export function createContextMenuItems(
  treeviewStore: TreeviewStore,
): MenuItemDef[] {
  function createFlagImg(src: string) {
    return `<img width="16px" height="16px" style="filter: brightness(0) invert(0);" src="${src}" />`;
  }
  const event = { stopPropagation: () => {} };
  return [
    {
      name: 'View',
      icon: createFlagImg(treeviewStore.viewDocument),
      action() {
        treeviewStore.onMenuItemClick(event, 'View');
      },
    },
    {
      name: 'Download',
      icon: createFlagImg(treeviewStore.download),
      action() {
        treeviewStore.onMenuItemClick(event, 'Download');
      },
    },
    {
      name: 'Versions',
      icon: createFlagImg(treeviewStore.version),
      action() {
        treeviewStore.onMenuItemClick(event, 'Download version');
      },
    },
    {
      name: 'Rename',
      icon: createFlagImg(treeviewStore.edit),
      action() {
        treeviewStore.onMenuItemClick(event, 'Rename');
      },
    },
    {
      name: 'Remove',
      icon: createFlagImg(treeviewStore.delete),
      action() {
        this.treeviewStore.removeFile();
      },
    },
    {
      name: 'Share with link',
      icon: createFlagImg(treeviewStore.share),
      action() {
        treeviewStore.onMenuItemClick(event, 'Share');
      },
    },
    {
      name: 'Send by Email',
      icon: createFlagImg(treeviewStore.email),
      action() {
        treeviewStore.onMenuItemClick(event, 'Email');
      },
    },
  ];
}
