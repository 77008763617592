import { Component, Inject, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';

import { DataBookBaseComponent } from '../../databook-base.component';
import { DataBookState } from '../../../services/databook.state';
import { DataBookService } from '../../../services/databook.service';
import { CoreService } from '../../../core/core.service';
import { PdfService } from '../../../components/pdf/pdf.service';

import { validateEmail } from '../../../components/utils';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import { TranslateModule } from '@ngx-translate/core';
import { ClrModalModule } from '@clr/angular';
import { OpenMailModalComponent } from './open-mail-modal/open-mail-modal.component';
import { WorkflowStepModalComponent } from './workflow-step-modal/workflow-step-modal.component';
import { WorkflowModalComponent } from './workflow-modal/workflow-modal.component';
import { ConfigWorkflowComponent } from './config-workflow/config-workflow.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'config-workflow-modal',
  templateUrl: './config-workflow-modal.component.html',
  styleUrls: ['./config-workflow-modal.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ConfigWorkflowComponent,
    WorkflowModalComponent,
    WorkflowStepModalComponent,
    OpenMailModalComponent,
    ClrModalModule,
    TranslateModule,
  ],
})
export class ConfigWorkflowModalComponent extends DataBookBaseComponent {
  @Input() isConfigWorkflowOpen = false;
  @Input() closeModal: () => void;

  isModalOpen = false;
  showNewWorkflowButton = false;
  openConfirm = false;

  childModal = {
    workflow: false,
    workflowStep: false,
    openEmailConfirm: false,
  };

  workflowType = {
    create: false,
    update: false,
    delete: false,
  };

  workflowStepType = {
    create: false,
    update: false,
    delete: false,
  };

  openEmailType = {
    create: false,
    update: false,
  };

  modalTitle = {
    newWorkflow: 'New Workflow',
    editWorkflow: 'Edit Workflow',
    deleteWorkflow: 'Delete Workflow',
    newStep: 'New Step',
    editStep: 'Edit Step',
  };

  stepData: any[] = [];
  workflowData: any = [];
  emailList: string[] = [];

  currentWorkflow: { id: number; version: number } = { id: 0, version: 0 };

  stepIndex: number;

  workflowStepForm: FormGroup;
  workflowForm: FormGroup;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    public formBuilder: FormBuilder,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );

    this.workflowForm = this.formBuilder.group({
      nameInputWfId: new FormControl('', Validators.required),
    });

    this.workflowStepForm = this.formBuilder.group({
      mail: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ]),
      ),
      delayToSign: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^\d+$/),
      ]),
      mandatory: new FormControl(true, Validators.required),
    });
  }

  onPageInit = async () => {
    try {
      await super.initProjectProduct();

      this.coreService.showLoading();

      const params = {
        productId: this.store.selectedProduct.id,
      };

      this.cfgVlr(params);

      const workflow = await this.dataBookService.workflowManagement(params);

      console.log('workflow.list ==> ', workflow.list);

      this.emailList = _.sortBy(workflow.emailList);

      if (workflow?.list?.length) {
        this.workflowData = workflow.list;
      }

      this.coreService.hideLoading();

      this.showNewWorkflowButton = true;
    } catch (error) {
      await this.coreService.processError(error);
    }
  };

  configBreadCrumb = () => {
    this.breadcrumbCurrent = this.coreService.translate(
      'databook.new.tpi.digital.configure.workflow',
      null,
    );
    this.breadcrumb = [
      {
        name: this.dataBookState.projectName,
        link: '/main/project',
        type: this.coreService.translate('databook.project', null),
      },
      {
        name: this.dataBookState.productDsc,
        link: '/main/product',
        type: this.coreService.translate('databook.product', null),
      },
      {
        name: this.coreService.translate('databook.dashboard', null),
        link: '/',
      },
      {
        name: this.coreService.translate('databook.tpi.digital', null),
        link: '/main/new-tpi-digital',
      },
    ];
  };

  isReleaseVisible = () => {
    if (super.getRoleByProject()?.TPI_DIGITAL?.RELEASE) {
      return true;
    }
    return false;
  };

  canDeactivate = () => {
    return this.unsavedChanges;
  };

  cfgVlr = (params) => {
    const vlr = this.dataBookService.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  };

  getEmailMessage = () => {
    if (this.workflowStepForm?.get('mail')?.value) {
      return (
        this.workflowStepForm.get('mail').value.toLowerCase().trim() +
        this.coreService.translate('confirm.add.wrong.email', null)
      );
    }
    return '';
  };

  validateToSave = () => {
    let valid = true;

    const value = this.workflowForm.get('nameInputWfId').value;
    if (!value || !value.trim().length) {
      this.coreService.showAlertErrorTranslate('required.field.required', {});
      valid = false;
    } else if (validateEmail(value)) {
      this.coreService.showAlertErrorTranslate('databook.email.invalid');
    } else {
      const found = _.find(
        this.workflowData,
        (workflow) => workflow.name.toLowerCase() === value.toLowerCase(),
      );

      if (found && found?.id !== this.currentWorkflow?.id) {
        this.coreService.showAlertErrorTranslate('wf.name.exists', {});
        valid = false;
      }
    }
    return valid;
  };

  formatDate = (date: string) => this.coreService.formatDate(date);

  confirmEmail = () => {
    this.addNewStepConfirm();
    this.workflowStepForm.reset();
    this.openModal('workflow');
  };

  confirmEditEmail = () => {
    this.editValStep();
    this.workflowStepForm.reset();
    this.openModal('workflow');
  };

  newWorkflow = () => {
    this.stepData = [];
    this.setWorkflowType('create');
    this.openModal('workflow');
  };

  newStep = async () => {
    this.workflowStepForm.get('mandatory').setValue('true');
    if (this.stepData?.length >= 12) {
      await this.coreService.showAlertError(
        'The workflow reached the maximum signatures (12).',
      );
    } else {
      this.setWorkflowStepType('create');
      this.openModal('workflowStep');
    }
  };

  addNewStep = () => {
    const mailVal = this.workflowStepForm.get('mail').value;
    if (this.validateToSaveAddNewStep()) {
      if (this.emailList.includes(mailVal.toLowerCase().trim())) {
        this.addNewStepConfirm();
        this.openModal('workflow');
      } else {
        this.setOpenEmailType('create');
        this.openModal('openEmailConfirm');
      }
    }
  };

  validateToSaveAddNewStep = () => {
    let valid = true;
    const mailVal = this.workflowStepForm.get('mail').value;
    const mandatoryVal = this.workflowStepForm.get('mandatory').value;
    if (
      !mailVal ||
      !mailVal.trim().length ||
      !mandatoryVal ||
      !mandatoryVal.trim().length
    ) {
      this.coreService.showAlertErrorTranslate('required.field.required', {});
      valid = false;
    } else if (!validateEmail(mailVal)) {
      this.coreService.showAlertErrorTranslate('databook.email.invalid');
      valid = false;
    }
    return valid;
  };

  addNewStepConfirm = () => {
    this.stepData.push({
      email: this.workflowStepForm.get('mail').value.trim(),
      order: null,
      mandatory: this.workflowStepForm.get('mandatory').value,
      delay_day: this.workflowStepForm.get('delayToSign').value || 0,
      active: null,
    });
    this.reorder();
    this.workflowStepForm.reset();
  };

  editStep = () => {
    const mailVal = this.workflowStepForm.get('mail').value;
    if (!mailVal || !mailVal?.trim().length) {
      this.coreService.showAlertErrorTranslate('required.field.required', {});
    } else if (!validateEmail(mailVal)) {
      this.coreService.showAlertErrorTranslate('databook.email.invalid');
    } else if (this.emailList.includes(mailVal.toLowerCase().trim())) {
      this.editValStep();
      this.workflowStepForm.reset();
      this.openModal('workflow');
    } else {
      this.setOpenEmailType('update');
      this.openModal('openEmailConfirm');
    }
  };

  openEditWorkflowModal = (
    id: number,
    version: number,
    name: string,
    steps,
  ) => {
    this.currentWorkflow.id = id;
    this.currentWorkflow.version = version;
    this.stepData = steps;

    this.workflowForm.get('nameInputWfId').setValue(name);
    this.setWorkflowType('update');
    this.openModal('workflow');
  };

  openDeleteWorkflowModal = (id) => {
    this.currentWorkflow.id = id;
    this.openConfirm = true;
    this.closeChildModal();
  };

  cancelExclusion() {
    this.openConfirm = false;
    this.currentWorkflow.id = null;
  }

  deleteStepInWorkflowModal = async () => {
    try {
      this.coreService.showLoading();

      const params = {
        delete: true,
        id: this.currentWorkflow.id,
        productId: this.store.selectedProduct.id,
      };

      this.cfgVlr(params);

      const workflowData =
        await this.dataBookService.workflowManagement(params);

      this.workflowData = workflowData?.list;

      this.openConfirm = false;
      this.currentWorkflow.id = null;
      this.coreService.showAlertSuccessTranslate(
        'application.commons.success.operation',
        {},
      );
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
      this.closeChildModal();
    }
  };

  editValStep = () => {
    this.stepData[this.stepIndex].email = this.workflowStepForm
      .get('mail')
      .value.trim();
    this.stepData[this.stepIndex].mandatory =
      this.workflowStepForm.get('mandatory').value;
    this.stepData[this.stepIndex].delay_day =
      this.workflowStepForm.get('delayToSign').value || 0;
  };

  save = async () => {
    try {
      if (this.validateToSave()) {
        this.coreService.showLoading();

        const params = {
          createdBy: this.coreService.getConfig().currentUser.email,
          projectName: this.store.selectedProject.gsi_id,
          productId: this.store.selectedProduct.id,
          workflowName: this.workflowForm.get('nameInputWfId').value,
          version: 1,
          productName: this.store.selectedProduct.gsi_id,
          previousWorkflowId: null,
          code: 'xxx',
          active: true,
          steps: this.stepData,
          createWorkflow: true,
        };

        this.cfgVlr(params);

        const workflowData =
          await this.dataBookService.workflowManagement(params);

        this.workflowData = workflowData.list;
        this.closeChildModal();
        this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
          {},
        );
      }
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  update = async () => {
    try {
      if (this.validateToSave()) {
        this.coreService.showLoading();

        const params = {
          createdBy: this.coreService.getConfig().currentUser.email,
          projectName: this.store.selectedProject.gsi_id,
          workflowName: this.workflowForm.get('nameInputWfId').value,
          version: this.currentWorkflow.version + 1,
          productName: this.store.selectedProduct.gsi_id,
          productId: this.store.selectedProduct.id,
          previousWorkflowId: this.currentWorkflow.id,
          code: 'xxx',
          active: true,
          steps: this.stepData,
          update: true,
        };
        this.cfgVlr(params);
        const workflowData =
          await this.dataBookService.workflowManagement(params);

        this.workflowData = workflowData.list;
        this.closeChildModal();
        this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
          {},
        );
      }
    } catch (error) {
      await this.coreService.processError(error);
    } finally {
      this.coreService.hideLoading();
    }
  };

  updateStepForm = (index) => {
    this.stepIndex = index;
    this.workflowStepForm.get('mail').setValue(this.stepData[index].email);
    this.workflowStepForm
      .get('delayToSign')
      .setValue(this.stepData[index].delay_day);
    this.workflowStepForm
      .get('mandatory')
      .setValue(this.stepData[index].mandatory.toString());

    this.setWorkflowStepType('update');
    this.openModal('workflowStep');
  };

  setWorkflowType = (modalName: string) => {
    for (const type of Object.keys(this.workflowType)) {
      if (type === modalName) {
        this.workflowType[modalName] = true;
        continue;
      }

      this.workflowType[type] = false;
    }
  };

  setOpenEmailType = (modalName: string) => {
    for (const type of Object.keys(this.openEmailType)) {
      if (type === modalName) {
        this.openEmailType[modalName] = true;
        continue;
      }

      this.openEmailType[type] = false;
    }
  };

  setWorkflowStepType = (modalName: string) => {
    for (const type of Object.keys(this.workflowStepType)) {
      if (type === modalName) {
        this.workflowStepType[modalName] = true;
        continue;
      }

      this.workflowStepType[type] = false;
    }
  };

  reorder = () => {
    for (const [index, item] of this.stepData?.entries()) {
      item.order = index + 1;
    }
  };

  openModal = (modalName: 'workflow' | 'workflowStep' | 'openEmailConfirm') => {
    for (const modal of Object.keys(this.childModal)) {
      if (modal === modalName) {
        this.childModal[modalName] = true;
        continue;
      }

      this.childModal[modal] = false;
    }

    this.isModalOpen = true;
  };

  closeChildModal = () => {
    for (const modal of Object.keys(this.childModal)) {
      this.childModal[modal] = false;
    }

    this.workflowStepForm.reset();
    this.workflowForm.reset();
    this.isModalOpen = false;
  };

  closeNewWorkflowStepModal = () => {
    this.openModal('workflow');
  };

  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
