import { GridApi } from 'ag-grid-community';
import * as ExcelJS from 'exceljs';

export async function exportToExcel(api: GridApi) {
  const params = {
    fileName: 'data.xlsx', // Nome do arquivo a ser exportado
    skipHeader: false,
    columnGroups: false,
    skipFooters: true,
    skipGroups: true,
    skipPinnedTop: true,
    skipPinnedBottom: true,
    allColumns: false,
    onlySelected: false,
  };

  // Obter dados da grade no formato do Excel
  const excelData = api.getDataAsExcel(params) as any;
  // const arrayBuffer = await this.blobToArrayBuffer(excelData);

  // Converter o Excel para o formato de saída
  const workbook = new ExcelJS.Workbook();
  const wb = await workbook.xlsx.load(excelData);
  const worksheet = wb.worksheets[0];
  // Congelar o cabeçalho na primeira linha
  worksheet.views = [{ state: 'frozen', xSplit: 5, ySplit: 3 }];
  worksheet.eachRow((row, rowNumber) => {
    row.font = {
      name: 'Calibri',
      size: 12,
      bold: rowNumber <= 3, // Make the first 3 rows bold
      color: { argb: '00000000' },
    };
  });

  const columnsToHide = [3, 4, 5, 6, 10, 11, 30, 67, 93, 119, 126, 142];
  for (const item of columnsToHide) {
    // const col = this.columnsApi.getColumn(item);
    // const columnIndex = col.getColId();
    worksheet.getColumn(item).hidden = true;
  }

  // Gerar o arquivo Excel
  const excelBuffer = await workbook.xlsx.writeBuffer();

  // Baixar o arquivo
  saveExcelFile(excelBuffer, params.fileName);
}

function saveExcelFile(buffer: ArrayBuffer, fileName: string) {
  const data: Blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url: string = window.URL.createObjectURL(data);
  const anchor: HTMLAnchorElement = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(url);
}
