import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass } from '@angular/common';
import {
  ClrModalModule,
  ClrIconModule,
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
} from '@clr/angular';

@Component({
  selector: 'history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.scss'],
  standalone: true,
  imports: [
    ClrModalModule,
    ClrIconModule,
    NgIf,
    ClrCommonFormsModule,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgClass,
    TranslateModule,
  ],
})
export class HistoryModalComponent {
  @Input() modalIsOpen;
  @Input() closeModal;
  @Input() showSteps;
  @Input() steps;
  @Input() nextSteps;
  @Input() document_name;
  @Input() downloadAdditional;
  @Input() mergeSteps;
  @Input() exportToExcelHistory;

  simpleFmt = (str) => str.replaceAll('.000Z', '').replace('T', ' ');
}
