import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataBookProjectComponent } from '../pages/project/project.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { DataBookProductComponent } from '../pages/product/product.component';
import { pipeNavigatorRouteNames } from '../enums';
import { MySignaturesMgmtComponent } from '../pages/my-signatures/my-signatures-mgmt.component';

export const projects = [
  {
    canActivate: [AuthenticateGuard],
    path: '',
    component: DataBookProjectComponent,
    data: { routeName: pipeNavigatorRouteNames.projectsPage },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: 'my-signatures',
    component: MySignaturesMgmtComponent,
    data: { routeName: pipeNavigatorRouteNames.mySignatures },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug',
    component: DataBookProductComponent,
    data: { routeName: pipeNavigatorRouteNames.projectPage },
    resolve: {
      data: ProjectListResolver,
    },
  },
];
