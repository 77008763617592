import { AuthenticateGuard } from '../core/guards/authenticate.guard';
import { DataBookComponent } from '../pages/databook/databook.component';
import { ProjectListResolver } from '../pages/project/project.resolver';
import { pipeNavigatorRouteNames } from '../enums/routes.enum';

export const databook = [
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/databook',
    component: DataBookComponent,
    data: { routeName: pipeNavigatorRouteNames.databook },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/databook/:fileId',
    component: DataBookComponent,
    data: { routeName: pipeNavigatorRouteNames.databookFileId },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/databook/folder/:folderId',
    component: DataBookComponent,
    data: { routeName: pipeNavigatorRouteNames.databookFolderId },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/e-doc',
    component: DataBookComponent,
    data: { routeName: pipeNavigatorRouteNames.eDoc },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/e-doc/:fileId',
    component: DataBookComponent,
    data: { routeName: pipeNavigatorRouteNames.eDocFileId },
    resolve: {
      data: ProjectListResolver,
    },
  },
  {
    canActivate: [AuthenticateGuard],
    path: ':projectSlug/:productSlug/e-doc/folder/:folderId',
    component: DataBookComponent,
    data: { routeName: pipeNavigatorRouteNames.eDocFolderId },
    resolve: {
      data: ProjectListResolver,
    },
  },
];
