import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  projects,
  products,
  materials,
  dataExplorer,
  dataAnalysis,
  databook,
  tpi,
  users,
} from './routes';

const routes: Routes = [
  ...users,
  ...projects,
  ...databook,
  ...products,
  ...materials,
  ...dataExplorer,
  ...dataAnalysis,
  ...tpi,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
