<div style="position: relative">
  <clr-input-container [style]="style">
    <label [ngClass]="{ 'asterisk-if-mandatory': required }">
      {{ label | translate }}
    </label>

    <input
      clrInput
      [value]="value"
      (input)="onChange($event.target.value)"
      (blur)="onTouched()"
      [name]="name"
      [attr.id]="id || null"
      [attr.min]="min || null"
      [attr.max]="max || null"
      [attr.attr.placeholder]="placeholder || null"
      [attr.autocomplete]="autocomplete || null"
      [attr.type]="type || null"
      [attr.class]="class || null"
      [attr.style]="style || null"
      [attr.minlength]="minLength || null"
      [attr.maxlength]="maxLength || null"
      [attr.size]="size || null"
      [disabled]="disabled"
      [readonly]="readonly"
      [required]="required"
    />
    <clr-control-error *clrIfError="'required'">
      {{ 'required.field.required' | translate }}
    </clr-control-error>

    <clr-control-error *clrIfError="'minlength'">
      {{ label | translate }} must be at least {{ minLength }} characters
    </clr-control-error>

    <clr-control-error *clrIfError="'maxlength'">
      {{ label | translate }} must not exceed {{ maxLength }} characters
    </clr-control-error>

    <clr-control-error *clrIfError="'email'">
      {{ label | translate }} is invalid
    </clr-control-error>

    <clr-control-error *clrIfError="'mismatch'">
      {{ label | translate }} does not match
    </clr-control-error>
  </clr-input-container>
  <div
    *ngIf="error"
    class="pipenavigator-input-container"
  >
    {{ error | translate }}
  </div>
</div>
