import {
  Component,
  Input,
  EventEmitter,
  Inject,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DataBookBaseComponent } from '../databook-base.component';
import { HttpClient } from '@angular/common/http';
import { DataBookState } from '../../services/databook.state';
import { DataBookService } from '../../services/databook.service';
import { CoreService } from '../../core/core.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../../components/pdf/pdf.service';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  getCookie,
  setCookie,
  showColumnFieldString as _showColumnFieldString,
  showColumnField as showColumnField,
} from '../../components/utils';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import * as _ from 'lodash';

import { ConfigHandler } from '../../core/handlers/config.handler';
import { FavoriteProject, IProjectModal } from './project.types';
import {
  PROJECT_STORE,
  ProjectsStore,
  UserStore,
  USER_STORE,
} from '../../mobx-stores';
import { Project } from '../../classes';
import { findProjects } from '../../classes/project-helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ArchiveProjectModalComponent } from './archive-project-modal/archive-project-modal.component';
import { CreateEditProjectModalComponent } from './create-edit-project-modal/create-edit-project-modal.component';
import { DatabookSearchComponent } from '../../components/search/search.component';
import {
  ClrCommonFormsModule,
  ClrDatagridModule,
  ClrStopEscapePropagationDirective,
  ClrPopoverHostDirective,
  ClrIconModule,
} from '@clr/angular';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { BasePageComponent } from '../../core/components/page/base-page.component';
import { MobxAngularModule } from 'mobx-angular';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'data-flow-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  standalone: true,
  imports: [
    MobxAngularModule,
    BasePageComponent,
    BreadcrumbComponent,
    NgIf,
    ClrCommonFormsModule,
    DatabookSearchComponent,
    ClrDatagridModule,
    ClrStopEscapePropagationDirective,
    ClrPopoverHostDirective,
    NgClass,
    NgFor,
    ClrIconModule,
    CreateEditProjectModalComponent,
    ArchiveProjectModalComponent,
    TranslateModule,
  ],
})
export class DataBookProjectComponent extends DataBookBaseComponent {
  @Input()
  searchProject: EventEmitter<string> = new EventEmitter<string>();

  modalIsOpen = false;
  projectModal: IProjectModal = {
    isEdit: false,
    isSave: false,
    confirmArchive: false,
    archiveByText: false,
  };

  initialized = false;

  projectsCache = [];
  filteredProjectList = [];

  sortedFavoritesSide = 'asc';

  favoriteProject: FavoriteProject = {};
  archiveProjectForm: FormGroup;
  clickedSortNameValue = 'desc';
  selectedColumn = '';
  showError = false;
  mills = ['7501 - VSB - Barreiro', 'VS01 - VSB - Jeceaba'];
  FAVORITE_PROJECT_COOKIE = 'favorite_projects';

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    private config: ConfigHandler,
    public formBuilder: FormBuilder,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
    this.archiveProjectForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
    });
  }

  async updateProjects(): Promise<void> {
    this.store.projects = await findProjects(this.dataBookService, false);
  }

  disable() {
    return false;
  }

  async onSearchChange(searchText) {
    if (searchText.trim().length > 1) {
      this.store.projects = this.store.projects.map((project: Project) => {
        project.visibleInProjectList = false;

        if (project.name?.toLowerCase().includes(searchText.toLowerCase())) {
          project.visibleInProjectList = true;
        }

        if (
          project.ownerName?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          project.visibleInProjectList = true;
        }

        if (
          project.ownerEmail?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          project.visibleInProjectList = true;
        }

        if (
          project.poNumber?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          project.visibleInProjectList = true;
        }

        if (project.poDate?.toLowerCase().includes(searchText.toLowerCase())) {
          project.visibleInProjectList = true;
        }

        if (
          project.customer?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          project.visibleInProjectList = true;
        }

        if (
          project.lastModifiedBy
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          project.visibleInProjectList = true;
        }

        if (
          project.lastModifiedDate
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          project.visibleInProjectList = true;
        }
        return project;
      });
    } else {
      this.store.projects = this.store.projects.map((project: Project) => {
        project.visibleInProjectList = true;
        return project;
      });
    }
  }

  async onPageInit() {
    this.initialized = true;

    const projects = this.userStore?.user?.roles?.PROJECTS;

    const fav = getCookie(this.FAVORITE_PROJECT_COOKIE);
    const favoriteProjectIds: number[] = JSON.parse(fav);
    this.store.projects = this.store.projects.map((project: Project) => {
      project.isFavorite = favoriteProjectIds.includes(project.id);
      return project;
    });

    this.sort('name');

    const filteredProjects = Object.keys(projects).filter(
      (item) => item !== 'id' && item !== 'resources',
    );

    if (filteredProjects.length === 1) {
      const project = this.store.projects.find(
        (item) => item.gsi_id === filteredProjects[0],
      );

      const products = projects[filteredProjects[0]]?.PRODUCT;

      const filteredProducts = Object.keys(products).filter(
        (item) => item !== 'id' && item !== 'resources',
      );

      if (filteredProducts.length === 1) {
        const product = this.store.projects.find(
          (item) => item.gsi_id === filteredProducts[0],
        );

        this.store.gotoProduct(project.slug, product[0].slug);
      }

      this.store.gotoToProject(project.slug);
    }
  }

  removeDuplicates(materials: any[]): any[] {
    return materials.filter(
      (element, index) => materials.indexOf(element) === index,
    );
  }

  configBreadCrumb() {
    this.store.activePageForBreadcrumb = 'My projects';
  }

  populateResult = (result) => {
    this.showError = false;

    if (this.store.projects.length === 0) {
      this.showError = true;
    }

    this.projectsCache = result.projects;
    this.dataBookState.projects = result.projects;
    this.dataBookState.forceShow = result.forceShow;

    const fav = getCookie(this.FAVORITE_PROJECT_COOKIE);

    if (fav) {
      const favoriteProjectIds: number[] = JSON.parse(fav);
      this.store.projects = this.store.projects.map((project: Project) => {
        project.isFavorite = favoriteProjectIds.includes(project.id);
        return project;
      });
    }
    this.sortFavorite();
  };

  canProjectConfig = () => {
    if (this.userStore.user?.roles?.PROJECT_CONFIG) {
      return true;
    }
    return false;
  };

  isFavorite(item: Project) {
    return this.favoriteProject[item.id];
  }

  setFavorite(item: Project) {
    this.store.projects = this.store.projects.map((project: Project) => {
      if (project.id === item.id) {
        project.isFavorite = !item.isFavorite;
      }
      return project;
    });
    this.updateFavoriteProject(this.store.projects);
  }

  updateFavoriteProject(projects: Project[]) {
    setCookie(
      this.FAVORITE_PROJECT_COOKIE,
      JSON.stringify(
        projects
          .filter((project: Project) => {
            return project.isFavorite;
          })
          .map((project: Project) => {
            return project.id;
          }),
      ),
      365,
      this.dataBookService.config.environment.cookie_domain,
    );
  }

  sortFavorite() {
    this.store.projects = this.store.projects.sort((a: Project, b: Project) => {
      if (this.sortedFavoritesSide === 'asc') {
        if (a.isFavorite && !b.isFavorite) return 1;
        if (!a.isFavorite && b.isFavorite) return -1;
      } else {
        if (a.isFavorite && !b.isFavorite) return -1;
        if (!a.isFavorite && b.isFavorite) return 1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  sort(column) {
    this.selectedColumn = column;

    this.clickedSortNameValue =
      this.clickedSortNameValue === 'desc' ? 'asc' : 'desc';

    if (column === 'fav') {
      this.sortedFavoritesSide =
        this.sortedFavoritesSide === 'desc' ? 'asc' : ' desc';

      this.sortFavorite();
    } else {
      const sort = _.orderBy(
        this.store.projects,
        [
          (project) => {
            const value = project[column];
            return value ? value.toLowerCase() : 'zz';
          },
        ],
        [this.clickedSortNameValue],
      );

      this.sortChildren(sort, column);
      this.store.projects = sort;
    }
    // this.sortChildren(sort, column);
  }

  sortChildren(sortTemp, column) {
    if (sortTemp.length === 0) {
      return;
    }
    for (const item of sortTemp) {
      if (item.children && item.children.length) {
        item.children = _.orderBy(
          item.children,
          [column],
          [this.clickedSortNameValue],
        );
        this.sortChildren(item.children, column);
      }
    }
  }
  onSelectClick = async (project: Project) => {
    this.store.gotoToProject(project.slug);
  };

  isProjectVisible() {
    const projects = this.userStore?.user?.roles?.PROJECTS;
    if (!projects) {
      return false;
    }

    return true;
  }

  isEqual() {
    return (
      this.store.selectedProject?.name?.toUpperCase() !==
      this.archiveProjectForm.get('name').value.toString().toUpperCase()
    );
  }

  async modalProject(project: Project | null) {
    if (project?.id) {
      this.store.selectedProject = project;
      this.openModal('isEdit');
    } else {
      this.openModal('isSave');
    }
  }

  confirmArchive() {
    this.archiveProjectForm.get('name').setValue('');
    this.archiveProjectForm.reset();
    this.openModal('archiveByText');
  }

  openArchiveModal(project: Project) {
    this.store.selectedProject = project;
    this.openModal('confirmArchive');
  }

  async archive() {
    this.coreService.showLoading();
    if (
      this.store.selectedProject.name.toUpperCase() ===
      this.archiveProjectForm.get('name').value.toString().toUpperCase()
    ) {
      const params = {
        archive: true,
        id: this.store.selectedProject.id,
      };
      const result = await this.dataBookService.project(params);
      if (result.projects) {
        await this.coreService.showAlertSuccessTranslate(
          'application.commons.success.operation',
        );
        await this.populateResult(result);
      }
    } else {
      this.coreService.showAlertErrorTranslate(
        'confirm.exclusion.error.not.same',
      );
    }
    this.coreService.hideLoading();
    this.closeModal();
  }

  showColumnFieldString(name: string): string {
    return _showColumnFieldString(name, 15);
  }

  showColumnField(name: string): boolean {
    return showColumnField(name, 15);
  }

  openModal = (modalName: string) => {
    for (const modal of Object.keys(this.projectModal)) {
      if (modal === modalName) {
        this.projectModal[modalName] = true;
        continue;
      }

      this.projectModal[modal] = false;
    }

    this.modalIsOpen = true;
  };

  closeModal = () => {
    for (const modal of Object.keys(this.projectModal)) {
      this.projectModal[modal] = false;
    }
    this.modalIsOpen = false;
    this.store.selectedProject = null;
  };

  getImage(icon: string) {
    return `assets/icons/${icon}`;
  }
}
