<clr-modal
  [clrModalOpen]="modalIsOpen"
  [clrModalSize]="'xl'"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="modal-header">
      <h3 class="modal-title-history">
        {{ 'tpi.workflow.already.submitted.title' | translate }}
      </h3>
      <hr style="width: 100%" />
    </div>

    <div class="clr-row">
      <div class="clr-col-12">
        <clr-datagrid>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'databook.document.name' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column [style.max-width.px]="400">
            <span class="tpi-history-column-title">
              {{ 'databook.file.status' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'databook.uploaded.on' | translate }}
            </span>
          </clr-dg-column>
          <clr-dg-column>
            <span class="tpi-history-column-title">
              {{ 'databook.uploaded.by' | translate }}
            </span>
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let file of files">
            <clr-dg-cell class="break-word text-align-center">
              {{ file?.document_name }}
            </clr-dg-cell>
            <clr-dg-cell class="break-word text-align-center">
              {{ setStatus(file?.action) }}
            </clr-dg-cell>
            <clr-dg-cell class="break-word text-align-center">
              {{ formatDate(file?.created_on) }}
            </clr-dg-cell>
            <clr-dg-cell class="break-word text-align-center">
              {{ file?.created_by }}
            </clr-dg-cell>
          </clr-dg-row>
        </clr-datagrid>
      </div>
    </div>
    <p class="modal-title-history">
      {{
        'tpi.workflow.already.submitted.confirm.duplicate.warning' | translate
      }}
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline button-primary"
      (click)="upload()"
      [disabled]="!this.files?.length || coreService.isLoading()"
    >
      {{ 'databook.upload.sign' | translate }}
    </button>

    <button
      id="idCloseHistryButton"
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>
  </div>
</clr-modal>
