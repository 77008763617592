import { TreeviewFileItem } from '../treeview-item.class';
import { CoreService } from '../../core/core.service';
import { DataBookService } from '../../services/databook.service';

export const moveFile = async (
  dataBookService: DataBookService,
  fileId: number,
  newFolderId: number,
): Promise<boolean> => {
  console.log('move-file.ts');

  console.log('fileId ==> ', fileId);
  console.log('newFolderId => ', newFolderId);

  const params: { functionName; args: any } = {
    functionName: 'moveFile',
    args: {
      fileId,
      newFolderId,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};

export const moveFiles = async (
  dataBookService: DataBookService,
  filesIds: number[],
  newFolderId: number,
): Promise<boolean> => {
  console.log('filesIds ==> ', filesIds);

  const params: { functionName; args: any } = {
    functionName: 'moveFiles',
    args: {
      filesIds,
      newFolderId,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};

export const copyFile = async (
  dataBookService: DataBookService,
  fileId: number,
  newFolderId: number,
): Promise<any> => {
  const params: { functionName; args: any } = {
    functionName: 'copyFile',
    args: {
      fileId,
      newFolderId,
    },
  };
  return await dataBookService.newLambda<boolean>(params);
};

export const copyFiles = async (
  dataBookService: DataBookService,
  filesIds: number[],
  newFolderId: number,
): Promise<TreeviewFileItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'copyFiles',
    args: {
      filesIds,
      newFolderId,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};
