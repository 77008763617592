// eslint-disable no-unused-vars
export enum pipeNavigatorRouteNames {
  projectPage = 'Projects page',
  projectsPage = 'Project page',
  productPage = 'Product page',
  materialMgmt = 'Materials',
  dataExplorer = 'Data Explorer',
  material = 'Material',
  newTpiDigital = 'New Tpi Digital',
  usersManagement = 'Users management',
  newTpiDigitalFileId = 'New Tpi Digital File Id',
  configWorkflow = 'Config Workflow',
  databook = 'Databook',
  databookFileId = 'Databook File Id',
  databookSearchName = 'Databook Search Name',
  databookFolderId = 'Databook Folder Id',
  eDoc = 'E Doc',
  eDocFileId = 'Edoc File Id',
  eDocFolderId = 'Edoc Folder Id',
  dashboard = 'Dashboard',
  pipeLoad = 'Pipe Load',
  plp = 'Data Explorer',
  plpChemical = 'Chemical',
  plpCtod = 'Ctod',
  plpHardness = 'Hardness',
  plpHic = 'Hic',
  uploadTest = 'Upload Files',
  plpAll = 'All',
  plpAllSangomar = 'All Sangomar',
  plpSsc = 'Ssc',
  plpImpact = 'Impact',
  plpTensile = 'Tensile',
  plpDimensions = 'Dimensions',
  plpDimensionsSepia = 'Dimensions Sepia',
  plpDoubleJoint = 'Double Joint',
  plpAshDensity = 'Ash Density',
  plpMassRatio = 'Mass Ratio',
  plpCompression = 'Compression',
  plpShoreDTopcoat = 'Shore D Topcoat',
  plpInterlayerAdhesion = 'Interlayer Adhesion',
  plpTensilePropTopcoatInsulating = 'Tensile Prop Topcoat Insulating',
  plpRawMaterials = 'Raw Materials',
  plpModulus = 'Modulus',
  plpPorosity = 'Porosity',
  plpCure = 'Cure',
  plpAdhesive = 'Adhesive',
  plpCathodicDisbondment = 'Cathodic Disbondment',
  plpEpoxy = 'Epoxy',
  plpHotWater = 'Hot Water',
  plpImpactUndercoating = 'Impact Undercoating',
  plpIndentation = 'Indentation',
  plpOit = 'Oit',
  plpPolypropylene = 'Polypropylene',
  plpTensilePropTopcoat = 'Tensile Prop Topcoat',
  plpConductivity = 'Conductivity',
  plpEntrapped = 'Entrapped',
  plpMeltFlowIndex = 'Melt Flow Index',
  plpDensityGspp = 'Density Gspp',
  plpWeldingTest = 'Welding Test',
  plpMlp = 'Mlp',
  plpAdhesion = 'Adhesion',
  plpPullOff = 'Pull Off',
  plpPelm = 'Pelm',
  plpNewTensileTemplate = 'New Tensile Template',
  plpNewImpactTemplate = 'New Impact Template',
  dataAnalysis = 'Data Analysis',
  materialMaterialTypeDataExplorer = 'Data Explorer',
  materialMaterialTypeDataAnalysis = 'Data analysis',
  dataAnalysisCorrelation = 'Correlation',
  dataAnalysisTrend = 'Trend',
  dataAnalysisStatistics = 'Statistics',
  dataAnalysisBoxplot = 'Boxplot',
  dataAnalysisHistogram = 'Histogram',
  newTpiDigitalTpiFlowMgmt = 'New Tpi Digital Tpi Flow Mgmt',
  plpCutback_7Layer = 'Cutback 7 Layer',
  plpFinalInspection_3Layer = 'Final Inspection 3 Layer',
  plpFinalInspection_5Layer = 'Final Inspection 5 Layer',
  plpFinalInspection_7Layer = 'Final Inspection 7 Layer',
  plpIncoming_3Layer = 'Incoming 3 Layer',
  plpIncoming_5Layer = 'Incoming 5 Layer',
  plpIncoming_7Layer = 'Incoming 7 Layer',
  plpIndCoilPreheat_3Layer = 'Ind Coil Preheat 3 Layer',
  plpPostBlasting_3Layer = 'Post Blasting 3 Layer',
  plpSawStation_5Layer = 'Saw Station 5 Layer',
  plpSawStation_7Layer = 'Saw Station 7 Layer',
  plpThickness_3Layer = 'Thickness 3 Layer',
  plpSinter_7Layer = 'Sinter 7 Layer',
  plpFinalInspection = 'Final Inspection',
  plpIInductionCoilPreheat = 'I Induction Coil Preheat',
  plpIncoming = 'Incoming',
  plpPostBlasting = 'Post Blasting',
  plpThickness = 'Thickness',
  plpRoughCoat = 'Rough Coat',
  materialSelection = 'Material Selection',
  ataAnalysisStatistics = 'Ata Analysis Statistics',
  ataAnalysisHistogram = 'Ata Analysis Histogram',
  mySignatures = 'My Signatures',
  // eslint-enable no-unused-vars
}
