import {Inject, Injectable} from '@angular/core';
import {CoreService} from '@core/core.service';
import {ConfigHandler} from '@core/handlers/config.handler';

import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {DataBookState} from './databook.state';
import {PROJECT_STORE, ProjectsStore, USER_STORE, UserStore,} from '../mobx-stores';

@Injectable({
  providedIn: 'root',
})
export class DataBookService {
  public endpoint;
  public endPlanning;

  constructor(
    private sanitizer: DomSanitizer,
    public config: ConfigHandler,
    private httpClient: HttpClient,
    private trans: TranslateService,
    public dataBookState: DataBookState,
    public coreService: CoreService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    this.endpoint = this.config.environment.endpoints.databook;
    this.endPlanning = this.config.environment.endpoints.planning;
  }

  public call(
    endpoint: string,
    params: any = {},
    options: any = {},
  ): Promise<any> {
    const callStart = Date.now();
    return new Promise((resolve, reject) => {
      this.coreService.callHttpPost(endpoint, params, options).then(
        (response: any) => {
          const callFinish = Date.now();
          const data = response;
          const paramSave = {
            email: this.userStore.user.email,
            payload: params,
            project: document.title,
            response: data,
            response_time_second: (callFinish - callStart) / 1000,
            status_code: 200,
            url_feature: window.location.pathname,
            url_endpoint: endpoint,
          };
          setTimeout(() => {
          }, 1000);
          this.saveApiMetrics(paramSave);
          resolve(data);
        },
        (error) => {
          const callFinish = Date.now();
          const paramSave = {
            email: this.userStore.user.email,
            payload: params,
            project: document.title,
            response: error,
            response_time_second: (callFinish - callStart) / 1000,
            status_code: 500,
            url_feature: window.location.pathname,
            url_endpoint: endpoint,
          };
          this.saveApiMetrics(paramSave);
          reject(error);
        },
      );
    });
  }

  public async saveApiMetrics(params): Promise<any> {
    params.email = this.userStore.user.email;
    const response = await this.coreService.callHttpPost(
      this.endPlanning + '/admin/panel/save_api_metrics',
      {api_logs: params},
    );
    return response;
  }

  public async qpScopeOfSupply(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/qp-scope-of-supply',
      params,
      options,
    );
  }

  public async revisions(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/revisions',
      params,
      options,
    );
  }

  /////////// Digital TPI ////////////
  public async signature(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/my-signature',
      params,
      options,
    );
  }

  public async tpiDigitalInitialValues(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-initial-values',
      params,
      options,
    );
  }

  public async tpiFlowInfo(params): Promise<any> {
    const options = {
      decompress: false,
    };

    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-info-flow',
      params,
      options,
    );
  }

  public async getTpiFlowByName(
    workflowName: string,
    documentId: number,
  ): Promise<any> {
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-by-name',
      {workflowName, documentId}
    );
  }

  public async tpiDigitalStartFlow(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-start-flow',
      params,
      options,
    );
  }

  public async tpiDigitalEndFlow(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-end-flow',
      params,
      options,
    );
  }

  public async tpiDigitalCancelTransferFlow(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-cancel-transfer-flow',
      params,
      options,
    );
  }

  public async tpiDigitalProceedFlow(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-proceed-flow',
      params,
      options,
    );
  }

  public async dataAnalysis(params): Promise<any> {
    const options = {
      decompress: true,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/data-analysis',
      params,
      options,
    );
  }

  public async dataExplorer(params, decompress = true): Promise<any> {
    console.log(" dataExplorer params ", params)
    const response = await this.coreService.callHttpPost(
      this.endpoint + '/data-explorer',
      params,
      {decompress},
    );

    return response;
  }

  public async dataExplorerColumnsDef(params, decompress = true): Promise<any> {
    return await this.coreService.callHttpPost(
      this.endpoint + '/data-explorer',
      params,
      {decompress},
    );
  }

  public async dataExplorerYellowTail(): Promise<any> {
    const options = {
      decompress: true,
    };
    const file = await this.coreService.callSimpleHttpGet(
      this.config.environment.data_explorer_bucket +
      '/data_explorer/yellow-tail-data.json',
      options,
    );
    return file;
  }

  public async documentPreSignedUrl(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/document-signed-url',
      params,
      options,
    );
  }

  public async listWorkflows(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-list-flow',
      params,
      options,
    );
  }

  public async workflowManagement(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/workflow-mgmt',
      params,
      options,
    );
  }

  /////////// Digital TPI ////////////

  public async listProjects(params): Promise<any> {
    const options = {
      decompress: false,
    };
    this.cfgVlr(params);
    return await this.coreService.callHttpPost(
      this.endpoint + '/project',
      params,
      options,
    );
  }

  public async newLambda<T>(params): Promise<T> {
    const options = {
      decompress: false,
    };
    this.cfgVlr(params);
    return await this.coreService.typedCallHttpPost<T>(
      this.endpoint + '/new-lambda',
      params,
      options,
    );
  }

  public async documents(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/document',
      params,
      options,
    );
  }

  public async sign(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/sign',
      params,
      options,
    );
  }

  public async checkMerge(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/check-merge',
      params,
      options,
    );
  }

  public async merge(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/merge-documents',
      params,
      options,
    );
  }

  private prepareParams(params: any) {
    const body = params ? params : {};
    if (!this.config.environment.production) {
      console.log(' ----------  REQUEST   ----------   ');
      console.log(body);
      console.log(' --------------------------------   ');
    }
    return JSON.stringify(body);
  }

  public getVlr() {
    if (this.coreService.getConfig().environment.stage === 'development') {
      return btoa(JSON.stringify({id: this.config.applicationId}));
    }
    return null;
  }

  public async getHeader(options: any = {}): Promise<any> {
    options.decompress = options.decompress ? options.decompress : false;
    options.userAuthorization =
      options.userAuthorization !== undefined
        ? options.userAuthorization
        : true;
    options.userAuthorizationType =
      options.userAuthorizationType !== undefined
        ? options.userAuthorizationType
        : 'custom';

    const idToken = await this.coreService.getAuthToken();

    const headers: any = {
      'Content-Type': 'application/json',
      'Accept-Language': this.trans.currentLang
        ? this.trans.currentLang
        : this.trans.getDefaultLang(),
    };

    if (options.userAuthorization && idToken && idToken !== '') {
      headers.Authorization = idToken;
      if (options.userAuthorizationType === 'custom') {
        const application = {
          id: this.config.applicationId,
        };
        headers['VLR-Authorization'] = btoa(JSON.stringify(application));
      }
    }

    return headers;
  }

  public async pipeLoad(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.callHttpPost(
      this.endpoint + '/load-pipe',
      params,
      options,
      'error.timeout.send.email.pipe.load',
      true
    );
  }

  public async callHttpPost(
    endpoint: string,
    params: any = {},
    options: any = {},
    customError,
    danger
  ): Promise<any> {
    const headersValue = await this.getHeader(options);
    return new Promise((resolve, reject) => {
      if (options.prepareParams === undefined || options.prepareParams) {
        params = this.prepareParams(params);
      }

      this.httpClient
        .post(endpoint, params, {headers: headersValue})
        .subscribe(
          (response: any) => {
            const data = response;
            const result = data.body ? data.body : data;
            resolve(result);
          },

          (err) => {
            if (customError) {
              if (danger) {
                this.coreService.showAlertDangerTranslate(customError);
              } else {
                this.coreService.showAlertErrorTranslate(customError);
              }

              this.coreService.hideLoading();
              resolve(err);
            } else {
              reject(err.error);
            }
          },
        );
    });
  }

  public async mergeFinal(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/merge-documents-final',
      params,
      options,
    );
  }

  public async download(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return this.coreService.callHttpPost(
      this.endpoint + '/document',
      params,
      options,
    );
  }

  public async getTestsResultPLP(params): Promise<any> {
    const options = {
      decompress: false,
    };
    const detail = await this.coreService.getUserDetail();
    return await this.coreService.callHttpPost(
      this.endpoint + '/get-tests-data',
      params,
      options,
    );
  }

  public async getTests(params): Promise<any> {
    const options = {
      decompress: false,
    };
    params.project = this.store.selectedProject.gsi_id;
    params.productId = this.store.selectedProduct.id;
    params.projectId = this.store.selectedProject.id;
    return await this.coreService.callHttpPost(
      this.endpoint + '/tests',
      params,
      options,
    );
  }

  public async materials(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/material',
      params,
      options,
    );
  }

  public async product(params): Promise<any> {
    console.log('params', params);
    const options = {
      decompress: false,
    };
    this.cfgVlr(params);
    return await this.coreService.callHttpPost(
      this.endpoint + '/product/' + this.store.selectedProject.id,
      params,
      options,
    );
  }

  public async contactUs(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/contact-us',
      params,
      options,
    );
  }

  public async sendDocsByEmail(params): Promise<any> {
    const options = {
      decompress: false,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/send-docs-email',
      params,
      options,
    );
  }

  public async projectData(params): Promise<any> {
    const options = {
      decompress: true,
    };
    return await this.coreService.callHttpPost(
      this.endpoint + '/project-data',
      params,
      options,
    );
  }

  public async migrate(params): Promise<any> {
    const options = {
      decompress: false,
    };
    this.cfgVlr(params);
    return await this.coreService.callHttpPost(
      this.endpoint + '/migrate',
      params,
      options,
    );
  }

  public async project(params): Promise<any> {
    const options = {
      decompress: false,
    };
    this.cfgVlr(params);
    return await this.coreService.callHttpPost(
      this.endpoint + '/project',
      params,
      options,
    );
  }

  public async paletteColors() {
    return this.coreService.callSimpleHttpGet('./assets/palettes.json');
  }

  public async getAvatarRandom(params): Promise<any> {
    return await this.coreService.callHttpGet(
      'https://ui-avatars.com/api/',
      params,
    );
  }

  public cfgVlr(params) {
    const vlr = this.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  }
}
