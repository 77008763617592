<vic-loading></vic-loading>

<header
  class="header header-vic"
  *ngIf="!hideHeader"
  style="display: sticky"
>
  <div class="header-branding-searchBar">
    <div
      class="branding"
      (click)="home()"
      style="cursor: pointer"
    >
      <img
        alt="Logo"
        *ngIf="image"
        src="{{ image }}"
      />
      <span class="vic-header-project"> Pipe Navigator </span>
    </div>

    <div class="treeview-header-button treeview-search-input">
      <div class="search-box treeview-search-input-field-global">
        <div class="search-icon">
          <clr-icon
            shape="times"
            *ngIf="treeviewStore.searchTextGlobal"
            style="cursor: pointer; flex: 1 0"
            (click)="
              treeviewStore.searchTextGlobal = '';
              treeviewStore.onGlobalSearchButtonClick(store, userStore)
            "
          ></clr-icon>
        </div>
        <input
          class="search-input"
          type="text"
          [placeholder]="'application.commons.buttons.search' | translate"
          [(ngModel)]="treeviewStore.searchTextGlobal"
          (ngModelChange)="
            treeviewStore.onGlobalSearchButtonClick(store, userStore)
          "
        />
      </div>
      <div
        class="autocomplete-options-global"
        #autoCompleteInput
        *ngIf="
          treeviewStore.searchAutocompleteCandidatesGlobal.length > 0 ||
          treeviewStore.searchAutocompleteCandidatesGlobalFeatures.length > 0
        "
        [style.left.px]="autocompletePosition.left"
        [style.top.px]="autocompletePosition.top"
      >
        <div
          *ngIf="treeviewStore.searchAutocompleteCandidatesGlobal.length > 0"
          class="autocomplete-options-databook-items"
        >
          Databook elements
        </div>
        <div
          *ngIf="treeviewStore.searchAutocompleteCandidatesGlobal.length > 0"
          class="autocomplete-options-databook-items"
        >
          Databook elements
        </div>
        <div
          *ngFor="let match of treeviewStore.searchAutocompleteCandidatesGlobal"
          (click)="treeviewStore.onTreeviewGlobalAutocompleteClick(match)"
        >
          <img
            alt="Autocomplete candidate logo"
            class="treeview-autocomplete-candidate"
            src="{{
              match.type === 'file'
                ? match.isPdf
                  ? treeviewStore.pdfIconUri
                  : treeviewStore.fileIconUri
                : treeviewStore.closedFolder
            }}"
          />
          {{ match.name }} ( {{ match.projectName.toUpperCase() }} -
          {{ match.productName.toUpperCase() }} )
        </div>
        <div
          *ngFor="let match of treeviewStore.searchAutocompleteCandidatesGlobal"
          (click)="treeviewStore.onTreeviewGlobalAutocompleteClick(match)"
        >
          <img
            class="treeview-autocomplete-candidate"
            src="{{
              match.type === 'file'
                ? match.isPdf
                  ? treeviewStore.pdfIconUri
                  : treeviewStore.fileIconUri
                : treeviewStore.closedFolder
            }}"
          />
          {{ match.name }} ( {{ match.projectName.toUpperCase() }} -
          {{ match.productName.toUpperCase() }} )
        </div>
        <div
          *ngIf="
            treeviewStore.searchAutocompleteCandidatesGlobalFeatures.length > 0
          "
          class="autocomplete-options-features"
        >
          Features
        </div>
        <div
          *ngFor="
            let feature of treeviewStore.searchAutocompleteCandidatesGlobalFeatures
          "
          (click)="
            treeviewStore.onTreeviewGlobalAutocompleteFeatureClick(feature)
          "
        >
          <img
            class="treeview-autocomplete-candidate"
            src="{{ treeviewStore.featureIcon }}"
          />
          <span style="width: 100px; display: inline-block">{{
            feature.label
          }}</span>
          ( {{ feature.projectName.toUpperCase() }} -
          {{ feature.productName.toUpperCase() }} )
        </div>
        <div
          *ngIf="
            treeviewStore.searchAutocompleteCandidatesGlobalFeatures.length > 0
          "
          class="autocomplete-options-features"
        >
          Features
        </div>
        <div
          *ngFor="
            let feature of treeviewStore.searchAutocompleteCandidatesGlobalFeatures
          "
          (click)="
            treeviewStore.onTreeviewGlobalAutocompleteFeatureClick(feature)
          "
        >
          <img
            alt="Autocomplete treeview logo"
            class="treeview-autocomplete-candidate"
            src="{{ treeviewStore.featureIcon }}"
          />
          <span style="width: 100px; display: inline-block">{{
            feature.label
          }}</span>
          ( {{ feature.projectName.toUpperCase() }} -
          {{ feature.productName.toUpperCase() }} )
        </div>
      </div>
    </div>

    <div
      class="divider"
      *ngIf="showSearch"
    ></div>

    <div
      class="search-bar"
      *ngIf="showSearch"
    >
      <vic-search (search)="onSearchChange($event)"></vic-search>
    </div>
  </div>

  <div class="header-actions">
    <ng-content select=".container"></ng-content>

    <div class="divider"></div>

    <a
      class="nav-link nav-icon"
      (click)="onCloseClick()"
      title="{{ 'application.commons.buttons.back' | translate }}"
      *ngIf="hideReturnButton()"
    >
      <clr-icon shape="undo"></clr-icon>
    </a>

    <databook-contact-us></databook-contact-us>

    <clr-dropdown>
      <button
        class="nav-icon"
        clrDropdownTrigger
      >
        <clr-icon shape="cog"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu
        *clrIfOpen
        clrPosition="bottom-right"
      >
        <label class="dropdown-header">{{
          'application.commons.settings' | translate
        }}</label>
        <a
          (click)="onMySignaturesClick()"
          clrDropdownItem
        >
          {{ 'application.my.signature' | translate }}
        </a>
        <div
          class="dropdown-divider"
          role="separator"
        ></div>
        <label class="dropdown-header">{{
          'application.commons.versions' | translate
        }}</label>
        <label class="dropdown-item"
          ><b>Front:</b>{{ environment.frontVersion }}</label
        >
        <label class="dropdown-item"
          ><b>Back:</b>{{ environment.backVersion }}</label
        >
        <label class="dropdown-item"
          ><b>Infra:</b>{{ environment.infraVersion }}</label
        >
        <div
          class="dropdown-divider"
          role="separator"
        ></div>
        <label class="dropdown-header">{{
          'application.commons.language' | translate
        }}</label>
        <a
          (click)="onLanguageClick('en')"
          clrDropdownItem
        >
          <clr-icon
            shape="check"
            *ngIf="currentLanguage === 'en'"
          ></clr-icon>
          {{ 'application.commons.language.english' | translate }}
        </a>
        <a
          (click)="onLanguageClick('pt-BR')"
          clrDropdownItem
        >
          <clr-icon
            shape="check"
            *ngIf="currentLanguage === 'pt-BR'"
          ></clr-icon>
          {{ 'application.commons.language.portuguese' | translate }}
        </a>
      </clr-dropdown-menu>
    </clr-dropdown>

    <clr-dropdown>
      <button
        class="nav-icon"
        clrDropdownTrigger
      >
        <clr-icon shape="user"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu
        *clrIfOpen
        clrPosition="bottom-right"
      >
        <label class="dropdown-header">{{ currentUser.firstName }}</label>

        <div
          class="dropdown-divider"
          role="separator"
        ></div>
        <a
          (click)="onLoginHistoryClick()"
          *ngIf="
            currentUser.roles.CORE && currentUser.roles.CORE.SHOW_USER_LOGS
          "
          clrDropdownItem
          >{{ 'application.commons.buttons.login.history' | translate }}</a
        >
        <a
          (click)="onLogoutClick()"
          clrDropdownItem
          >{{ 'application.commons.buttons.logout' | translate }}</a
        >
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</header>

<ng-content select=".page-body-container"></ng-content>
