import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { DataBookBaseComponent } from '../../databook-base.component';
import { HttpClient } from '@angular/common/http';
import { DataBookState } from '@services/databook.state';
import { DataBookService } from '@services/databook.service';
import { CoreService } from '@core/core.service';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '@components/pdf/pdf.service';
import {
  PROJECT_STORE,
  ProjectsStore,
  TREEVIEW_STORE,
  TreeviewStore,
  USER_STORE,
  UserStore,
} from '../../../mobx-stores';
import { FolderNameAndId } from '../../../classes/treeview-helpers';
import {
  TreeviewFileItem,
  TreeviewFolderItem,
  TreeviewItemContext,
} from '../../../classes';
import { plainToInstance } from 'class-transformer';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TreeviewItem } from '../treeview-item/treeview-item';
import DatabookViewMode from './DatabookViewMode';
import { TranslateModule } from '@ngx-translate/core';
import { GridviewComponent } from '../gridview/gridview.component';
import { TreeviewOptionsComponent } from '../treeview-options/treeview-options.component';
import { TreeviewDropdownListComponentComponent } from '../../../components/treeview-dropdown-list-component/treeview-dropdown-list-component.component';
import {
  ClrIconModule,
  ClrModalModule,
  ClrCommonFormsModule,
  ClrInputModule,
} from '@clr/angular';
import { NgIf, NgFor } from '@angular/common';
import { MobxAngularModule } from 'mobx-angular';

@Component({
  selector: 'treeview',
  templateUrl: './treeview.html',
  styleUrls: ['./treeview.scss'],
  standalone: true,
  imports: [
    MobxAngularModule,
    NgIf,
    ClrIconModule,
    FormsModule,
    NgFor,
    TreeviewDropdownListComponentComponent,
    TreeviewItem,
    TreeviewOptionsComponent,
    GridviewComponent,
    ClrModalModule,
    ClrCommonFormsModule,
    ReactiveFormsModule,
    ClrInputModule,
    TranslateModule,
  ],
})
export class Treeview
  extends DataBookBaseComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  @Input()
  item: FolderNameAndId;

  @Input()
  selectionFolderOnly = false;

  @Input()
  isRoot: boolean; // todo remove

  @Output()
  updateFile = new EventEmitter<{ file: TreeviewFileItem }>();

  @Output()
  viewDocument = new EventEmitter<{ file: TreeviewFileItem }>();

  @Output()
  openUpload = new EventEmitter<{ folder: TreeviewFolderItem }>();

  @Output()
  download = new EventEmitter<void>();

  @Output()
  downloadVersion = new EventEmitter<{ file: TreeviewFileItem }>();

  @Output()
  downloadMerge = new EventEmitter<void>();

  @Output()
  sendDocsByEmail = new EventEmitter<void>();

  @Output()
  openConfirmMerge = new EventEmitter<void>();

  @ViewChild(TreeviewItem) treeviewItem: TreeviewItem;
  istreeviewItemLoaded = false;

  id: number;
  product_id: number;
  description?: string;
  name?: string;
  oldDynamoId: string;
  phase?: boolean;
  fixed?: boolean;
  parent_id: number | null;
  treeview_context: TreeviewItemContext;
  folders: FolderNameAndId[] = [];
  files: TreeviewFileItem[] = [];
  modalIsOpen = false;
  formCreateItemForm: FormGroup;
  createItemModalIsOpen = false;
  deleteFolderOrFilleMessage = '';

  fileIconUri = '/assets/img/png/file.png';
  pdfIconUri = '/assets/img/png/pdf-file.png';
  closedFolder = '/assets/img/png/closed-folder.png';
  openedFolder = '/assets/img/png/opened-folder.png';

  icon: string = this.closedFolder;

  isOpened = false;
  childrensLoaded = false;

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public httpClient: HttpClient,
    public pdfService: PdfService,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
    @Inject(TREEVIEW_STORE) public treeviewStore: TreeviewStore,
    private formBuilder: FormBuilder,
  ) {
    super(
      dataBookState,
      dataBookService,
      coreService,
      activatedRouter,
      pdfService,
      httpClient,
      store,
      userStore,
    );
    this.formCreateItemForm = this.formBuilder.group({
      itemToAdd: new FormControl('', Validators.required),
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ngOnDestroy(): Promise<void> {
    this.resetSelection();
  }

  async triggerClickById(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      element.dispatchEvent(clickEvent);
    } else {
      console.error(`Element with ID "${elementId}" not found.`);
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  scrollToElementById(elementId, options) {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView(options);
    } else {
      console.error(`Element with ID "${elementId}" not found.`);
    }
  }

  async openTargetFileOrFolder() {
    if (this.store.folder) {
      const recursive = async (folder: TreeviewFolderItem) => {
        if (this.treeviewStore.targetFolder === null) {
          // await this.triggerClickById(`treeview-folder-item-${folder.id}`);
          const childFolders = await this.treeviewStore.openFolder(
            folder,
            this.store,
          );
          for await (const _folder of childFolders) {
            await recursive(_folder);
          }
        } else {
          this.treeviewStore.setIsFocused(this.treeviewStore.targetFolder);
          // this.treeviewStore.modifyFolderPropertyFromTreeRecurcivly(this.treeviewStore.tree, this.treeviewStore.targetFolder, 'isFocus', true);
          const fileDomId = `treeview-folder-item-${this.treeviewStore.targetFolder.id}`;
          setTimeout(() => {
            this.scrollToElementById(fileDomId, {
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
          return;
        }
      };
      for await (const rootFolder of this.treeviewStore.tree) {
        await recursive(rootFolder);
      }
    } else if (this.store.file) {
      const recursive = async (folder: TreeviewFolderItem) => {
        if (this.treeviewStore.targetFile === null) {
          // await this.triggerClickById(`treeview-folder-item-${folder.id}`);
          const childFolders = await this.treeviewStore.openFolder(
            folder,
            this.store,
          );
          for await (const _folder of childFolders) {
            await recursive(_folder);
          }
        } else {
          this.treeviewStore.setIsFocused(this.treeviewStore.targetFile);
          // this.treeviewStore.modifyFilePropertyFromTreeRecurcivly(this.treeviewStore.tree, this.treeviewStore.targetFile, 'isFocus', true);
          const fileDomId = `treeview-file-item-${this.treeviewStore.targetFile.id}`;
          setTimeout(() => {
            this.scrollToElementById(fileDomId, {
              behavior: 'smooth',
              block: 'start',
            });
          }, 100);
          return;
        }
      };
      for await (const rootFolder of this.treeviewStore.tree) {
        await recursive(rootFolder);
      }
    }
  }

  autocompletePosition = { left: 0, top: 0 };

  updateAutocompletePosition() {
    const inputField = document.querySelector('.treeview-search-input-field');
    const rect = inputField.getBoundingClientRect();
    this.autocompletePosition.left = rect.left - 15;
    this.autocompletePosition.top = rect.bottom;
  }

  ngAfterViewInit() {
    this.treeviewStore.projectStore = this.store;
    this.treeviewStore.updateFileHandler = this.updateFile;
    this.treeviewStore.viewDocumentHandler = this.viewDocument;
    this.treeviewStore.openUpload = this.openUpload;
    this.treeviewStore.downloadHandler = this.download;
    this.treeviewStore.sendByEmailHandler = this.sendDocsByEmail;
    this.treeviewStore.openConfirmMerge = this.openConfirmMerge;
    // this.openTargetFileOrFolder();
    this.treeviewStore.downloadVersionHandler = this.downloadVersion;
    this.treeviewStore.coreService = this.coreService;
    this.treeviewStore.selectionFolderOnly = this.selectionFolderOnly;
    this.treeviewStore.treeviewComponent = this;
    this.istreeviewItemLoaded = true;

    // on page scroll call this.updateAutocompletePosition
    window.addEventListener(
      'scroll',
      this.updateAutocompletePosition.bind(this),
      true,
    );
  }

  downloadButton(samePath: number) {
    this.treeviewStore.samePath = samePath;
    this.download.emit();
  }

  downloadMergeButton() {
    this.downloadMerge.emit();
  }

  canAddRootFolder() {
    return this.userStore.user?.roles?.PROJECT_CONFIG || this.canUpload();
  }

  canUpload() {
    return this.canDoOperationOnTreeviewItem('UPLOAD');
  }

  canDownload() {
    return this.canDoOperationOnTreeviewItem('DOWNLOAD');
  }

  canRemove() {
    return this.canDoOperationOnTreeviewItem('REMOVE');
  }

  canRename() {
    return this.canDoOperationOnTreeviewItem('RENAME');
  }

  canDoOperationOnTreeviewItem(
    operation: 'UPLOAD' | 'DOWNLOAD' | 'REMOVE' | 'RENAME',
  ) {
    const role = super.getRoleByProject();

    if (this.treeviewStore.context === TreeviewItemContext.databook) {
      return (
        this.store.selectedProject.gsi_id &&
        this.treeviewStore.product.id &&
        role.DATABOOK[operation]
      );
    }

    if (this.treeviewStore.context === TreeviewItemContext.edoc) {
      return (
        this.store.selectedProject.gsi_id &&
        this.treeviewStore.product.id &&
        role.E_DOC.EXTRA_DOCUMENTS &&
        role.E_DOC.EXTRA_DOCUMENTS[operation]
      );
    }

    return false;
  }

  addItem(): void {
    this.openAddItemModal();
  }

  confirmAddFolder(): void {
    if (!this.formCreateItemForm.controls['itemToAdd'].invalid) {
      const folderName = this.formCreateItemForm.get('itemToAdd').value;
      this.formCreateItemForm.controls['itemToAdd'].markAsTouched();
      const item = this.treeviewStore.onAddButtonClick();
      item.renamingFieldValue = folderName;
      if (this.istreeviewItemLoaded) {
        this.treeviewItem.confirmCreating(item);
        this.treeviewItem.cancelCreating();
      }
    }
    this.closeAddItemModal();
  }

  // FIXME: need to be refactor urgently very very soon
  deleteItem(): void {
    if (this.treeviewStore.focusedItem.folder_id === undefined) {
      const folder = plainToInstance(
        TreeviewFolderItem,
        this.treeviewStore.focusedItem,
      );
      this.deleteFolderOrFilleMessage = `Are you sure you want to delete the folder ${
        folder && folder?.name
      }`;
    } else {
      const file = plainToInstance(
        TreeviewFileItem,
        this.treeviewStore.focusedItem,
      );
      this.deleteFolderOrFilleMessage = `Are you sure you want to delete the file ${file?.description}`;
    }
    this.openModal();
  }

  confirmDelete = async () => {
    if (this.treeviewStore.focusedItem.folder_id === undefined) {
      const folder = plainToInstance(
        TreeviewFolderItem,
        this.treeviewStore.focusedItem,
      );
      const deleteResponse = this.treeviewStore.deleteFolderFromStore(folder);
      if (deleteResponse) {
        // FIXME: need to be refactor and call this function from store
        this.treeviewStore.removeFolderFromTree(folder);
        this.coreService.showAlertSuccess('successfully delete folder ');
        this.treeviewStore.contextMenuIsVisible = false;
      } else {
        this.coreService.showAlertError(
          'An error occurred while deleting the folder',
        );
      }
    } else {
      const file = plainToInstance(
        TreeviewFileItem,
        this.treeviewStore.focusedItem,
      );
      const deleteResponse = this.treeviewStore.deleteFileFromStore(file);
      if (deleteResponse) {
        // FIXME: need to be refactor and call this function from store
        this.treeviewStore.removeFile(file);
        this.coreService.showAlertSuccess('successfully delete file ');
        this.treeviewStore.contextMenuIsVisible = false;
      } else {
        this.coreService.showAlertError(
          'An error occurred while deleting the file',
        );
      }
    }
    this.modalIsOpen = false;
  };

  openModal = () => {
    this.modalIsOpen = true;
  };

  openAddItemModal = () => {
    this.createItemModalIsOpen = true;
  };

  closeAddItemModal = () => {
    this.formCreateItemForm.reset();
    this.createItemModalIsOpen = false;
  };

  closeModal = () => {
    this.modalIsOpen = false;
  };

  changeView() {
    this.resetSelection();
    DatabookViewMode.toggle();
  }

  // reset selection method into treeviewstore ?
  resetSelection() {
    this.selectionFolderOnly = false;
    this.treeviewStore.setSelectedObjects([]);
  }

  isDatabook() {
    return this.treeviewStore.context === 'databook';
  }

  isViewModeTreeview() {
    return DatabookViewMode.isTreeview() || !this.isDatabook();
  }
}
