import { Directive, Input, OnInit, Optional, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControlDirective,
} from '@angular/forms';

@Directive()
export class FormField implements OnInit, ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  @Input() label: string;
  @Input() labelKey: string;
  @Input() formControl: AbstractControl;
  @Input() formControlName: string;

  control: AbstractControl;

  constructor(@Optional() private controlContainer: ControlContainer) {}

  registerOnChange(fn: () => unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  registerOnTouched(fn: () => unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.formControlDirective?.valueAccessor?.setDisabledState) {
      this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    }
  }

  writeValue(obj: unknown): void {
    this.formControlDirective?.valueAccessor.writeValue(obj);
  }

  ngOnInit(): void {
    if (this.formControlName) {
      this.control = this.controlContainer.control?.get(this.formControlName);
    } else {
      this.control = this.formControl || this.controlContainer.control;
    }
  }
}
