import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { formatBytes } from '../tools';
import { DatabookPathCellRendererComponent } from '../components/databook-path-cell-renderer/databook-path-cell-renderer.component';

export const DATABOOK_FILES_COLUMN_DEFS: ColDef<
  DatabookFile & {
    mill_order: string;
    customer_number: string;
    production: string;
    production_line: string;
    production_step: string;
    level_2: string;
    level_3: string;
    signature_type: string;
    created_updated_on: string;
    created_updated_by: string;
    comment: string;
    signed_on: string;
  }
>[] = [
  {
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    pinned: true,
    rowDrag: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    sortable: false,
    resizable: false,
    minWidth: 52,
    width: 52,
    cellStyle: {
      padding: 'revert-layer',
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.name'),
    field: 'name',
    minWidth: 200,
    flex: 1,
    width: NaN,
    wrapText: true,
    autoHeight: true,
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.location'),
    field: 'path',
    minWidth: 200,
    flex: 1,
    autoHeight: true,
    hide: true,
    cellRendererSelector: (params) => ({
      component: DatabookPathCellRendererComponent,
      params,
    }),
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.level_2'),
    field: 'level_2',
    minWidth: 150,
    valueGetter: (params) => {
      try {
        return params.data.path.split('/')[1];
      } catch (error) {
        return '';
      }
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.level_3'),
    field: 'level_3',
    minWidth: 150,
    valueGetter: (params) => {
      try {
        return params.data.path.split('/')[2];
      } catch (error) {
        return '';
      }
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.type'),
    field: 'filetype',
    minWidth: 150,
    valueFormatter: (params) =>
      params.context.translate.instant('databook.mime.' + params.value) ||
      params.value,
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.date'),
    field: 'datetime',
    minWidth: 180,
    valueFormatter: (params: ValueFormatterParams) =>
      formatDate(
        new Date(Number(params.data.datetime)),
        'dd/MM/yyyy hh:mm',
        'en-US',
      ),
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.size'),
    field: 'size',
    type: 'rightAligned',
    minWidth: 100,
    valueFormatter: (params: ValueFormatterParams) =>
      formatBytes(params.data.size),
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.version'),
    field: 'revision',
    type: 'rightAligned',
    minWidth: 130,
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant(
        'databook.dataexplorer.column.title.mill.order',
      ),
    field: 'mill_order',
    type: 'rightAligned',
    minWidth: 130,
    valueGetter: (params) => {
      try {
        return DESCRIPTION_REGEX.exec(params.data.description)[1];
      } catch (error) {
        return '';
      }
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant(
        'databook.dataexplorer.column.title.customer.item',
      ),
    field: 'customer_number',
    type: 'rightAligned',
    minWidth: 150,
    valueGetter: (params) => {
      try {
        return DESCRIPTION_REGEX.exec(params.data.description)[2];
      } catch (error) {
        return '';
      }
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.treeview.production_line'),
    field: 'production_line',
    type: 'rightAligned',
    minWidth: 180,
    valueGetter: (params) => {
      try {
        return PRODUCTION_REGEX.exec(params.data.description)[1];
      } catch (error) {
        return '';
      }
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.treeview.production_step'),
    field: 'production_step',
    type: 'rightAligned',
    minWidth: 180,
    valueGetter: (params) => {
      try {
        return PRODUCTION_REGEX.exec(params.data.description)[2];
      } catch (error) {
        return '';
      }
    },
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.created_updated_on'),
    field: 'created_updated_on',
    type: 'rightAligned',
    minWidth: 200,
    valueFormatter: (params: ValueFormatterParams) =>
      formatDate(
        new Date(Number(params.data.datetime)),
        'dd/MM/yyyy hh:mm',
        'en-US',
      ),
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.created_updated_by'),
    field: 'created_updated_by',
    type: 'rightAligned',
    minWidth: 200,
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.comment'),
    field: 'comment',
    type: 'rightAligned',
    minWidth: 200,
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.signature_type'),
    field: 'signature_type',
    type: 'rightAligned',
    minWidth: 200,
  },
  {
    headerValueGetter: (params) =>
      params.context.translate.instant('databook.columns.singed_on'),
    field: 'signed_on',
    type: 'rightAligned',
    minWidth: 200,
    valueFormatter: (params: ValueFormatterParams) =>
      formatDate(
        new Date(Number(params.data.datetime)),
        'dd/MM/yyyy hh:mm',
        'en-US',
      ),
  },
];

export const DEFAULT_COL_DEF: ColDef<DatabookFile> = {
  minWidth: 100,
  resizable: true,
  sortable: true,
  width: 100,
  filter: 'agTextColumnFilter',
  menuTabs: ['filterMenuTab'],
  cellStyle: {
    lineHeight: '18px',
    padding: '14px',
  },
};

const DESCRIPTION_REGEX =
  /(PO[ ]?[0-9]+?)[-_ ]([0-9]+?)([_\-.]([a-zA-Z]+?))?[. ]/;
const PRODUCTION_REGEX = /(RM|QT|FL)[ \-_](.+?)[ \-_]/;
