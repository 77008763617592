import { CoreService } from '../core/core.service';
import { BaseComponent } from '../core/components/base.component';
import { DataBookService } from '../services/databook.service';
import { DataBookState } from '../services/databook.state';
import { ActivatedRoute } from '@angular/router';
import { PdfService } from '../components/pdf/pdf.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Directive, Inject } from '@angular/core';
import {
  PROJECT_STORE,
  ProjectsStore,
  USER_STORE,
  UserStore,
} from '../mobx-stores';
import { Features, Product, Project } from '../classes';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class DataBookBaseComponent extends BaseComponent {
  breadcrumb;
  breadcrumbCurrent;
  unsavedChanges = false;

  protected ALL_PROJECTS_READ_ONLY = 'ALL_PROJECTS_READ_ONLY';
  protected ALL_PRODUCTS = 'ALL_PRODUCTS';

  constructor(
    public dataBookState: DataBookState,
    public dataBookService: DataBookService,
    public coreService: CoreService,
    public activatedRouter: ActivatedRoute,
    public pdfService: PdfService,
    public httpClient: HttpClient,
    @Inject(PROJECT_STORE) public store: ProjectsStore,
    @Inject(USER_STORE) public userStore: UserStore,
  ) {
    super(coreService);
  }

  async initProjectProduct() {
    try {
      this.coreService.showLoading();

      const project: Project | undefined = this.store.selectedProject;
      const product: Product | undefined = this.store.selectedProduct;

      // const proj = await this.dataBookService.product({project: params.projectId,
      //   product: params.productId, productInfo: true});

      if (project) {
        this.dataBookState.entireProject = project;
        this.dataBookState.projectGsi = project.gsi_id;
        this.dataBookState.project = project.id;
        this.dataBookState.projectName = project.name;
        this.dataBookState.projectCode = project.code;
        this.dataBookState.urlCode = project.slug;
      }

      if (product) {
        this.dataBookState.product = product.id;
        this.dataBookState.productGsi = product.gsi_id.toString().toUpperCase();
        this.dataBookState.productId = product.name.toString().toUpperCase();
        this.dataBookState.productDsc = product.description
          .toString()
          .toUpperCase();
      }
      this.coreService.hideLoading();
    } catch (error) {
      this.coreService.processError(error);
    }

    this.checkAndBack(this.getCondition());
  }

  isAdmin() {
    return this.userStore.user.roles.PROJECT_CONFIG;
  }

  productHasFeature(feature: string) {
    if (!this.store.selectedProduct.features) return false;
    return (
      this.store.selectedProduct.features.filter((_feature: Features) => {
        return _feature.name === feature;
      }).length > 0
    );
  }
  isReadOnly() {
    if (this.userStore.user?.roles?.PROJECTS[this.ALL_PROJECTS_READ_ONLY]) {
      return this.userStore.user.roles.PROJECTS[this.ALL_PROJECTS_READ_ONLY]
        .PRODUCT[this.ALL_PRODUCTS];
    }
    return false;
  }

  getRoleByProject() {
    if (
      this.userStore.user?.roles?.PROJECTS[this.store?.selectedProject?.gsi_id]
    ) {
      return this.userStore.user.roles.PROJECTS[
        this.store?.selectedProject?.gsi_id
      ].PRODUCT[this.store.selectedProduct?.gsi_id?.toUpperCase()];
    } else {
      if (this.userStore.user?.roles?.PROJECTS[this.ALL_PROJECTS_READ_ONLY]) {
        return this.userStore.user.roles.PROJECTS[this.ALL_PROJECTS_READ_ONLY]
          .PRODUCT[this.ALL_PRODUCTS];
      }
    }
  }

  public hasUnsavedData() {
    return this.unsavedChanges;
  }

  public unauthorized() {
    // window.location.replace(this.coreService.getConfig().environment.links.login + '/access-denied');
    // this.coreService.getRouter().navigate(['/security/access-denied']);
  }

  public checkAndBack(condition) {
    if (condition) {
      this.coreService.getRouter().navigate(['/']);
    }
  }

  public getCondition() {
    return false;
  }

  isVsb() {
    if (
      this.getRoleByProject().PARTICIPANTS &&
      this.getRoleByProject().PARTICIPANTS.VSB &&
      this.getRoleByProject().PARTICIPANTS.VSB.id
    ) {
      return true;
    }
    return false;
  }

  public replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  escapeRegExp(str) {
    return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  normalize(originalName) {
    if (originalName) {
      return originalName.normalize('NFD').replace(/[^0-9a-zA-Z\-/.s]/g, ' ');
    }
    return null;
  }

  public async callHttpPutMultPart(endpoint, params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(endpoint, params).subscribe(
        (response: any) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public async callSimpleHttpPut(endpoint, params): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(endpoint, params).subscribe(
        (response: any) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public async callHttpPut(
    endpoint,
    params,
    revision,
    originalName,
    category,
    completePath,
  ): Promise<any> {
    const originalNameBase64 = btoa(
      encodeURIComponent(originalName).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(Number(`0x${p1}`));
        },
      ),
    );
    originalName = this.normalize(originalName);
    category = this.normalize(category);
    let httpOptions = null;

    if (revision) {
      completePath = this.normalize(completePath);
      if (completePath && completePath.length > 256) {
        completePath = completePath.substr(
          completePath.length - 256,
          completePath.length,
        );
      }
      if (category && category.length > 256) {
        category = category.substr(category.length - 256, category.length);
      }
      httpOptions = {
        headers: new HttpHeaders({
          'x-amz-tagging':
            'revision=' +
            revision +
            '&user=' +
            this.userStore.user.email +
            '&originalName=' +
            originalNameBase64 +
            '&fileName=' +
            originalName +
            '&category=' +
            category +
            '&completePath=' +
            completePath,
        }),
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'x-amz-tagging': 'user=' + this.userStore.user.firstName,
        }),
      };
    }

    return new Promise((resolve, reject) => {
      this.httpClient.put(endpoint, params, httpOptions).subscribe(
        (response: any) => {
          resolve(response);
        },
        (err): void => {
          reject(err);
        },
      );
    });
  }
  public cfgVlr(params) {
    const vlr = this.dataBookService.getVlr();
    if (vlr) {
      params.vlrAuth = vlr;
    }
  }
}
