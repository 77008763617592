<vic-base-page page="dataExplorer-hardness">
  <div class="header-actions container">
    <a
      class="nav-link nav-icon-text"
      (click)="exportToExcel()"
    >
      <clr-icon shape="export"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.excel' | translate
      }}</span>
    </a>

    <a
      class="nav-link nav-icon-text"
      (click)="exportToPdf()"
    >
      <clr-icon shape="file-group"></clr-icon>
      <span class="nav-text">{{
        'application.commons.buttons.pdf' | translate
      }}</span>
    </a>
  </div>

  <div class="page-body-container page-body-container-fixed">
    <div *ngIf="!isHardnessVisible()">{{ unauthorized() }}</div>

    <databook-breadcrumb> </databook-breadcrumb>

    <div class="card-row">
      <databook-filter>
        <databook-filter-column [columns]="columnsToggle">
        </databook-filter-column>
        <databook-filter-column-range
          [columns]="columnsRange"
          (onChangeFilter)="onChangeColumnRangeFilter($event)"
        >
        </databook-filter-column-range>
      </databook-filter>

      <div class="val-card auto-card">
        <div *ngIf="!items || !items.length">NO DATA</div>

        <clr-datagrid
          *ngIf="items && items.length"
          class="val-datagrid"
        >
          <clr-dg-column
            *ngIf="shouldShowColumn('Mill_Order')"
            [style.width.px]="130"
            >{{ 'data.book.mill.order' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Mill_Item')"
            [style.width.px]="120"
            >{{ 'data.book.mill.item' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Pipe_Heat')"
            [style.width.px]="80"
            >{{ 'data.book.pipe.heat' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Specimen')"
            [style.width.px]="50"
            >{{ 'data.book.specimen' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('OW1')"
            [style.width.px]="50"
            >{{ 'data.book.ow.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('OW2')"
            [style.width.px]="50"
            >{{ 'data.book.ow.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('OW3')"
            [style.width.px]="50"
            >{{ 'data.book.ow.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('OW4')"
            [style.width.px]="50"
            >{{ 'data.book.ow.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('OW_Avg')"
            [style.width.px]="50"
            >{{ 'data.book.ow.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('MW1')"
            [style.width.px]="50"
            >{{ 'data.book.mw.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('MW2')"
            [style.width.px]="50"
            >{{ 'data.book.mw.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('MW3')"
            [style.width.px]="50"
            >{{ 'data.book.mw.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('MW4')"
            [style.width.px]="50"
            >{{ 'data.book.mw.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('MW_Avg')"
            [style.width.px]="50"
            >{{ 'data.book.mw.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('IW12')"
            [style.width.px]="50"
            >{{ 'data.book.iw.1' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('IW23')"
            [style.width.px]="50"
            >{{ 'data.book.iw.2' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('IW34')"
            [style.width.px]="50"
            >{{ 'data.book.iw.3' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('IW45')"
            [style.width.px]="50"
            >{{ 'data.book.iw.4' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('IW_Avg6')"
            [style.width.px]="50"
            >{{ 'data.book.iw.avg' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Standard')"
            [style.width.px]="50"
            >{{ 'data.book.standard' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Qualification')"
            [style.width.px]="50"
            >{{ 'data.book.qualification' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Manufacturer_Pipe')"
            [style.width.px]="120"
            >{{ 'data.book.manufacturer.pipe' | translate }}</clr-dg-column
          >
          <clr-dg-column
            *ngIf="shouldShowColumn('Condition')"
            [style.width.px]="120"
            >{{ 'data.book.condition' | translate }}</clr-dg-column
          >

          <clr-dg-row *clrDgItems="let data of items">
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Order')">{{
              data['Mill_Order']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Mill_Item')">{{
              data['Mill_Item']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Pipe_Heat')">{{
              data['Pipe_Heat']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Specimen')">{{
              data['Specimen']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OW1')">{{
              data['OW1']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OW2')">{{
              data['OW2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OW3')">{{
              data['OW3']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OW4')">{{
              data['OW4']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('OW_Avg')">{{
              data['OW_Avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('MW1')">{{
              data['MW1']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('MW2')">{{
              data['MW2']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('MW3')">{{
              data['MW3']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('MW4')">{{
              data['MW4']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('MW_Avg')">{{
              data['MW_Avg']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('IW12')">{{
              data['IW12']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('IW23')">{{
              data['IW23']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('IW34')">{{
              data['IW34']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('IW45')">{{
              data['IW45']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('IW_Avg6')">{{
              data['IW_Avg6']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Standard')">{{
              data['Standard']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Qualification')">{{
              data['Qualification']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Manufacturer_Pipe')">{{
              data['Manufacturer_Pipe']
            }}</clr-dg-cell>
            <clr-dg-cell *ngIf="shouldShowColumn('Condition')">{{
              data['Condition']
            }}</clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination
              #paginationTaskDetails
              [clrDgPageSize]="10"
            >
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">
                {{
                  'application.commons.grid.footer.pagination.page.size'
                    | translate
                }}
              </clr-dg-page-size>
              {{ paginationTaskDetails.firstItem + 1 }} -
              {{ paginationTaskDetails.lastItem + 1 }}
              {{ 'application.commons.grid.footer.pagination.of' | translate }}
              {{ paginationTaskDetails.totalItems }}
              {{
                'application.commons.grid.footer.pagination.records' | translate
              }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
    <b style="text-align: center">{{
      'plp.data.hardness.subtitle' | translate
    }}</b>
    <br /><br />
  </div>
</vic-base-page>
