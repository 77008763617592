<clr-modal
  [clrModalOpen]="isModalOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
  class="modal"
>
  <div class="modal-body">
    <div class="title-container">
      <label
        class="session-title"
        *ngIf="type?.create"
      >
        {{ title.newWorkflow }}
      </label>
      <label
        class="session-title"
        *ngIf="type?.update"
      >
        {{ title.editWorkflow }}
      </label>
    </div>
    <div style="padding-left: 0">
      <form
        *ngIf="form"
        class="materials-table"
        [formGroup]="form"
        clrForm
        style="margin: 20px 0 0 0"
      >
        <input-form-control
          label="application.commons.text.name"
          name="nameInputWfId"
          formControlName="nameInputWfId"
          [size]="25"
          [form]="form"
          id="nameInputWfId"
        ></input-form-control>
      </form>
    </div>
    <button
      type="button"
      class="btn btn-outline"
      (click)="newStep()"
      id="addStepWfButtonId"
    >
      {{ 'application.commons.buttons.addStep' | translate }}
    </button>
    <clr-datagrid style="width: 100%; margin-bottom: 0px">
      <clr-dg-column>
        {{ 'databook.new.tpi.digital.workflow.edit.step' | translate }}
      </clr-dg-column>
      <clr-dg-column>
        {{ 'databook.new.tpi.digital.project.edit.userMail' | translate }}
      </clr-dg-column>
      <clr-dg-column>
        {{
          'databook.new.tpi.digital.maximum.duration.for.signature.in.day(s)'
            | translate
        }}
      </clr-dg-column>
      <clr-dg-column>
        {{ 'databook.new.tpi.digital.edit.isMandatory' | translate }}
      </clr-dg-column>
      <clr-dg-column></clr-dg-column>

      <clr-dg-row *ngFor="let item of stepData; let i = index">
        <clr-dg-cell>{{ item.order }}</clr-dg-cell>
        <clr-dg-cell>{{ item.email }}</clr-dg-cell>
        <clr-dg-cell>{{ item.delay_day }}</clr-dg-cell>
        <clr-dg-cell>
          <clr-icon
            shape="check"
            class="is-success"
            size="20"
            *ngIf="item.mandatory === true || item.mandatory === 'true'"
          >
          </clr-icon>
          <clr-icon
            shape="times"
            class="is-error"
            size="20"
            *ngIf="item.mandatory === false || item.mandatory === 'false'"
          >
          </clr-icon>
        </clr-dg-cell>
        <clr-dg-cell>
          <button class="icon-btn">
            <clr-icon
              id="editStepIdIcon"
              shape="pencil"
              title="Edit"
              (click)="updateStepForm(i)"
            ></clr-icon>
          </button>
          <button class="icon-btn">
            <clr-icon
              id="removeStepIdIcon"
              shape="trash"
              class="is-error"
              title="Delete"
              (click)="deleteStep(item.id)"
            ></clr-icon>
          </button>
        </clr-dg-cell>
      </clr-dg-row>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
      id="closeNewWfButtonId"
    >
      CLOSE
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="type.create"
      (click)="save()"
      id="saveWfButtonId"
    >
      {{ 'application.commons.buttons.save' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      *ngIf="type.update"
      (click)="update()"
      id="updateWfButtonId"
    >
      {{ 'application.commons.buttons.update' | translate }}
    </button>
  </div>
</clr-modal>
