<clr-modal
  class="modal-project"
  [clrModalOpen]="modalIsOpen"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="true"
>
  <div class="modal-body">
    <div class="clr-row">
      <div class="clr-col-12">
        <h2
          class="vic-header-title"
          *ngIf="modal.isSave"
        >
          <b>{{ (isEditMode ? 'product.select' : 'product.select.create') | translate }}</b>
        </h2>

        <h2
          class="vic-header-title"
          *ngIf="modal.isEdit"
        >
          <span>
            {{ 'application.commons.buttons.edit' | translate }}
          </span>
          {{ 'qp.project.info.product' | translate }}
        </h2>
      </div>
    </div>

    <form
      clrForm
      [formGroup]="productForm"
    >
      <pn-select
        labelKey="product.selection"
        [options]="productTypesLabelValues"
        formControlName="type"
      ></pn-select>

      <pn-input
        labelKey="application.commons.text.name"
        formControlName="name"
      ></pn-input>

      <pn-input
        labelKey="data.book.supplier"
        formControlName="supplier"
      ></pn-input>

      <pn-input
        labelKey="application.commons.text.description"
        formControlName="description"
      ></pn-input>

      <ng-container *ngIf="isEditMode">
        <pn-input
          labelKey="databook.last.modified.by"
          formControlName="lastModifiedBy"
        ></pn-input>

        <pn-input
          labelKey="databook.last.modification.date"
          formControlName="lastModificationDate"
        ></pn-input>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline"
      (click)="closeModal()"
    >
      {{ 'application.commons.buttons.cancel' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="productForm.invalid"
    >
      {{
        'application.commons.buttons.' + (modal.isSave ? 'confirm' : 'save')
          | translate
      }}
    </button>
  </div>
</clr-modal>
