import { DataBookService } from '../../services/databook.service';
import { TreeviewFileItem, TreeviewFolderItem } from '../treeview-item.class';

export const renameFile = async (
  dataBookService: DataBookService,
  id: number,
  description: string,
): Promise<TreeviewFileItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'renameFile',
    args: {
      id,
      description,
    },
  };
  return await dataBookService.newLambda<TreeviewFileItem | undefined>(params);
};

export const renameFolder = async (
  dataBookService: DataBookService,
  id: number,
  name: string,
): Promise<TreeviewFolderItem | undefined> => {
  const params: { functionName; args: any } = {
    functionName: 'renameFolder',
    args: {
      id,
      name,
    },
  };
  return await dataBookService.newLambda<TreeviewFolderItem | undefined>(
    params,
  );
};
